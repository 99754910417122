import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";

const DesplegableConBusqueda = ({ 
  elementos = [], 
  placeholder, 
  onSelect, 
  selectedElement, 
  displayProperty, 
  idProperty 
}) => {
  const [filtro, setFiltro] = useState("");
  const [seleccionado, setSeleccionado] = useState("");

  useEffect(() => {
    if (selectedElement) {
      const elementoSeleccionado = elementos.find(elemento => elemento[idProperty] === selectedElement);
      if (elementoSeleccionado) {
        setSeleccionado(elementoSeleccionado[displayProperty] || '');
      }
    }
  }, [selectedElement, elementos, displayProperty, idProperty]);

  const elementosFiltrados = elementos.filter((elemento) => 
    elemento[displayProperty].toLowerCase().includes(filtro.toLowerCase())
  );

  const estilos = {
    boton: {
      border: "1px solid var(--color-acento)",
      color: "black",
      backgroundColor: "white",
      width: "180px",
      margin: "0",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis", // Trunca el texto con puntos suspensivos
    },
    menuDesplegable: {
      maxHeight: "300px",
      overflowY: "auto",
      width: "200px",
      backgroundColor: "white",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      padding: "0",
      position: "absolute",
      zIndex: 1050,
    },
    controlFormulario: {
      margin: "5px auto",
      padding: "5px",
      width: "90%",
      display: "block",
      boxSizing: "border-box",
      border: "1px solid #ced4da",
      borderRadius: "4px",
    },
    stickyContainer: {
      position: "sticky",
      top: 0,
      backgroundColor: "white",
      padding: "5px 0",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      display: "flex",
      justifyContent: "center",
      zIndex: 1050,
    },
    dropdownItem: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis", // Trunca el texto con puntos suspensivos
      width: "180px", // Limita el ancho del elemento
      display: "flex", // Permite alinear el texto y el ícono
      justifyContent: "space-between", // Distribuye el texto y el ícono de forma adecuada
    },
    itemSeleccionado: {
      color: "var(--color-acento)", // Color del texto seleccionado
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  };

  return (
    <Dropdown className="dropdown-elemento m-0 p-0">
      <Dropdown.Toggle style={estilos.boton}>
        {seleccionado || placeholder}
      </Dropdown.Toggle>
      <Dropdown.Menu style={estilos.menuDesplegable}>
        <div style={estilos.stickyContainer}>
          <FormControl
            autoFocus
            placeholder={`Buscar ${placeholder.toLowerCase()}`}
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
            style={estilos.controlFormulario}
          />
        </div>
        {elementosFiltrados.map((elemento, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => {
              setSeleccionado(elemento[displayProperty]);
              onSelect(elemento);
            }}
            style={estilos.dropdownItem}
          >
            <span style={elemento[idProperty] === selectedElement ? estilos.itemSeleccionado : {}}>
              {elemento[displayProperty]}
            </span>
            {elemento[idProperty] === selectedElement && (
              <FontAwesomeIcon icon={faCheck} style={{ marginLeft: "5px", color: "var(--color-acento)" }} />
            )}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DesplegableConBusqueda;
