import { useEffect, useState } from 'react';
import { getNotasCursoAsignatura } from '../services/PostsService';

const useGetNotasCursoAsignatura = (cursoId, asignaturaId, periodoEscolarId, unidadId = null) => {
  const [data, setData] = useState({
    confGlobal: {},
    confNotasSum: [],
    listadoNotas: [],
    confNotaFinalSum: null,
    cursoColumna: [],
    cursoPromedio: null,
    escala: null,
    conceptosEscala: [],
    tablaConceptos: [],
    unidades: [] // Agregamos unidades aquí
  });
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotas = async () => {
      if (!cursoId || !asignaturaId || !periodoEscolarId) {
        setError("Faltan parámetros: cursoId, asignaturaId o periodoEscolarId");
        setCargando(false);
        return;
      }

      setCargando(true);
      setError(null);

      try {
        const params = {
          curso_id: cursoId,
          asignatura_id: asignaturaId,
          periodo_escolar: periodoEscolarId,
        };

        // Añadir unidadId si está presente
        if (unidadId) {
          params.unidad_id = unidadId;
          console.log("Llamada a la API con unidadId:", unidadId); 
        } else {
          console.log("Llamada a la API sin unidadId"); 
        }

        // Llamada a la API
        const response = await getNotasCursoAsignatura(params);
        const data = response.data;

        // Configuración global, que incluye ahora unidadId y unidadNombre
        const confGlobal = data.confNotas.confGlobal || {};
        const unidadSeleccionada = {
          unidadId: confGlobal.unidadId,
          unidadNombre: confGlobal.unidadNombre
        };

        const confNotasSum = Array.isArray(data.confNotas.confNotasSum) ? data.confNotas.confNotasSum.map(nota => ({
          confSumId: nota.confSumId,
          confSumCasilla: nota.confSumCasilla,
          confSumTitulo: nota.confSumTitulo || "",
          confSumFecha: nota.confSumFecha || null,
          confSumPonderacion: nota.confSumPonderacion || 0,
          confSumAcumulativa: nota.confSumAcumulativa || false,
          cantidadCasillasAcu: nota.cantidadCasillasAcu || null,
          confNotaAcu: Array.isArray(nota.confNotaAcu) ? nota.confNotaAcu.map(subNota => ({
            confAcuId: subNota.confAcuId,
            confAcuCasilla: subNota.confAcuCasilla,
            confAcuTitulo: subNota.confAcuTitulo || "",
            confAcuFecha: subNota.confAcuFecha || null,
            confAcuPonderacion: subNota.confAcuPonderacion || 0
          })) : []
        })) : [];

        const confNotaFinalSum = data.confNotas.confNotaFinalSum || null;

        // Mapeo de estudiantes y sus notas
        const listadoNotas = Array.isArray(data.listadoNotas) ? data.listadoNotas.map(est => ({
          estudiante: {
            estudianteId: est.estudiante.estudianteId,
            numLista: est.estudiante.numLista,
            numMatricula: est.estudiante.numMatricula,
            rut: est.estudiante.run,
            rutDv: est.estudiante.runDv,
            nombre: `${est.estudiante.nombrePrimario} ${est.estudiante.apellidoPrimario} ${est.estudiante.apellidoSecundario}`,
            imagenPerfil: est.estudiante.imagenPerfil,
            cursoId: est.estudiante.cursoId,
            cursoNombre: est.estudiante.cursoNombre,
            retirado: est.estudiante.retirado,
            fechaRetiro: est.estudiante.fechaRetiro,
            ipe: est.estudiante.runProvisorio,
            ipeDv: est.estudiante.runProvisorioDv,
          },
          notas: est.notasAsignatura[0]?.notas.map(nota => ({
            notaSumId: nota.notaSumId,
            notaSumCasilla: nota.notaSumCasilla,
            notaSum: nota.notaSum,
            notaSumConcepto: nota.notaSumConcepto,
            notaAcuConf: nota.notaAcuConf,
            notasAcu: nota.notasAcu || []
          })) || [],
          notaFinal: est.notasAsignatura[0]?.notaFinal || null
        })) : [];
        
        // Promedio de columnas - priorizar el concepto si está presente
        const cursoColumna = Array.isArray(data.cursoColumna) ? data.cursoColumna.map(columna => ({
          columna: columna.columna,
          promedioColumna: columna.promedioColumnaConcepto && columna.promedioColumnaConcepto !== "" 
            ? columna.promedioColumnaConcepto 
            : columna.promedioColumnaNota
        })) : [];

        // Promedio del curso - priorizar el concepto si está presente
        const cursoPromedio = (data.promedioCurso.cursoPromedioConcepto && data.promedioCurso.cursoPromedioConcepto !== "") 
          ? data.promedioCurso.cursoPromedioConcepto 
          : data.promedioCurso.cursoPromedioNota;
        
        // Mapeo de escala y conceptos
        const escala = data.escala ? {
          escalaId: data.escala.escalaId,
          escalaNombre: data.escala.escalaNombre,
          escalaOrden: data.escala.escalaOrden,
          escalaDetalle: Array.isArray(data.escala.escalaRangos) ? data.escala.escalaRangos.map(detalle => ({
            escalaDetalleId: detalle.escalaDetalleId,
            escalaRangoCalMin: detalle.escalaRangoCalMin,
            escalaRangoCalMax: detalle.escalaRangoCalMax,
            escalaRangoPorcMin: detalle.escalaRangoPorcMin,
            escalaRangoPorcMax: detalle.escalaRangoPorcMax,
            escalaRangoConcepto: detalle.escalaRangoConcepto,
            escalaRangoConceptoDescripcion: detalle.escalaRangoConceptoDescripcion || "Descripción no disponible",
            escalaRangoOrden: detalle.escalaRangoOrden
          })) : []
        } : null;

        const conceptosEscala = data.escala
          ? data.escala.escalaRangos.map(detalle => detalle.escalaRangoConcepto)
          : [];

        const tablaConceptos = data.escala
          ? data.escala.escalaRangos.map(detalle => ({
            concepto: detalle.escalaRangoConcepto,
            descripcion: detalle.escalaRangoConceptoDescripcion || "Descripción no disponible",
            valor: detalle.escalaRangoCalMax
          }))
          : [];

        // Extraemos las unidades
        const unidades = data.unidades?.unidades || [];

        // Actualizar el estado con los datos recibidos, incluyendo la unidad seleccionada
        setData({ 
          confGlobal,
          confNotasSum, 
          listadoNotas, 
          confNotaFinalSum, 
          cursoColumna, 
          cursoPromedio, 
          conceptosEscala, 
          tablaConceptos,
          unidades,
          unidadSeleccionada 
        });

      } catch (error) {
        console.error('Error al cargar las notas:', error);
        setError(error.response ? error.response.data.message : error.message);
      } finally {
        setCargando(false);
      }
    };

    fetchNotas();
  }, [cursoId, asignaturaId, periodoEscolarId, unidadId]);

  return { data, cargando, error };
};

export default useGetNotasCursoAsignatura;
