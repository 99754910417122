import React from 'react';

const TablaConceptos = ({ conceptos }) => {
  
  if (!conceptos || conceptos.length === 0) {
    return null; 
  }

  const conceptosOrdenados = [...conceptos].sort((a, b) => b.valor - a.valor);

  return (
    <div className="tabla-conceptos">
      <table className="table table-bordered ">
        <thead className="thead-white">
          <tr>
            <th>Concepto</th>
            <th>Descripción</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {conceptosOrdenados.map((concepto, index) => (
            <tr key={index}>
              <td>{concepto.concepto}</td>
              <td>{concepto.descripcion || 'Descripción no disponible'}</td> {/* Si no hay descripción, mostrar texto por defecto */}
              <td>{concepto.valor}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablaConceptos;
