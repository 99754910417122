import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { store } from "../../../store/store";
import { changePwd } from "../../../services/PostsService";
import Title from "../../components/titles/Title";
import Avatar from "../../components/avatar/Avatar";
import TextInput from "../../components/inputs/TextInput";
import RunInput from "../../components/inputs/RunInput";
import { formatearRUN } from "../../../util/util";
import PasswordInput from "../../components/inputs/PasswordInput";
import Swal from "sweetalert2";

const Profile = () => {
  const currentSchool = JSON.parse(localStorage.getItem("currentSchool"));
  const estado = store.getState();
  const periodo = currentSchool
    ? currentSchool.periodoActual.anio
    : new Date().getFullYear();
  const persona = estado.auth?.auth?.user?.persona.filter(
    (p) => p.periodo.id === periodo
  )[0];

  const [avatarReadyToUpload, ] = useState(true);
  const [newValoresPerfil, setNewValoresPerfil] = useState({
    username: persona.run,
    password: "",
    newpwd: "",
  });

  const onFinish = (event) => {
    event.preventDefault();
    if (newValoresPerfil.password && newValoresPerfil.newpwd) {
      Swal.fire({
        title: "Confirmar",
        text: "¿Estás seguro de que deseas cambiar la contraseña?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "var(--color-acento)",
        cancelButtonColor: "var(--color-rojo)",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          changePwd(newValoresPerfil)
            .then(() => {
              Swal.fire({
                title: "Contraseña modificada",
                text: "La contraseña ha sido modificada con éxito",
                icon: "success",
                confirmButtonColor: "var(--color-acento)",
              });
              // setAvatarReadyToUpload(true);
            })
            .catch((e) => {
              Swal.fire(
                "Error al modificar contraseña",
                e.response.data.message,
                "error"
              );
            })
            // .finally(() => {
            //   setAvatarReadyToUpload(false);
            // });
        }
      });
    }
    // setAvatarReadyToUpload(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewValoresPerfil((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const passwdValidator = (value) => {
    const exp = /^([a-zA-Z0-9_-]){6,6}$/;
    return value && !exp.test(value) ? "Contraseña no válida" : null;
  };

  return (
    <div style={{ height: "90dvh", overflow: "hidden" }} className="pt-4">
      <center>
        <Title text={"Perfil de Usuario"} />
      </center>
      <div className=" mt-3 d-flex justify-content-center align-items-center">
        <Row className="w-100 d-flex justify-content-center">
          <Col md={3}></Col>
          <Col md={2} className="d-flex  flex-column align-items-center gap-0 h-100">
            <Avatar
              run={persona.run}
              enableUpload
              isReadyToUpload={avatarReadyToUpload}
            />

            <Form
              onSubmit={onFinish}
              style={{ paddingTop: 20, width: "100%" }}
              className="d-flex flex-column gap-3"
              autoComplete="off"
            >
              <TextInput
                label="Usuario"
                name="username"
                value={persona.run}
                disabled
              />
              <RunInput
                label="Run"
                name="run"
                value={formatearRUN(persona.run + persona.runDv)}
                disabled
              />
              <PasswordInput
                label="Contraseña actual"
                name="password"
                placeholder="Contraseña"
                maxLength={6}
                minLength={6}
                required
                value={newValoresPerfil.password}
                onChange={handleInputChange}
                autoComplete="off"
              />
              <PasswordInput
                label="Contraseña nueva"
                name="newpwd"
                placeholder="Contraseña nueva"
                maxLength={6}
                minLength={6}
                required
                value={newValoresPerfil.newpwd}
                onChange={handleInputChange}
                isInvalid={passwdValidator(newValoresPerfil.newpwd)}
                autoComplete="new-password"
              />
              <Button type="submit" variant="color-acento">
                Cambiar Contraseña
              </Button>
            </Form>
          </Col>

          {/* Nueva sección al costado derecho */}
          <Col md={3} className="d-flex justify-content-center align-items-end pb-5">
            <div className="bg-light p-3 border rounded w-100">
              <h4>Requisitos para nueva contraseña</h4>
              <ul>
                <li>● Largo de contraseña de 6 carácteres</li>
                <li>● Mínimo una letra Mayúscula</li>
                <li>● Mínimo una letra Minúscula</li>
                <li>● Mínimo un número</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Profile;
