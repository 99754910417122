import './MatriculaSige.scss'
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import Swal from "sweetalert2";
import {Col, Row, Table, Spinner, Button, Che } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { uploadProcesoMatricula, saveProcesoMatricula, uploadAsignacionMatricula, saveAsignacionMatricula } from '../../../services/MatriculaService';
import { ReportService } from '../../../services';
import apiConfig from '../../../config/apiConfig';

export default function MatriculaSige({assign=false}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [resultMatricula, setResultMatricula] = useState(null);
    const [resultAsignacion, setResultAsignacion] = useState(null);
    const [resultGroupedMatricula, setResultGroupedMatricula] = useState(null);
    const [enableSave, setEnableSave] = useState(false)

    const handleChangeStatus = ({ meta }, status) => {
        console.log(status, meta)
    }
    
    const handleSubmitProceso = (files, allFiles) => {
        setIsLoading(true)
        setEnableSave(false)
        setResultMatricula(null)
        const formData = new FormData()
        formData.append('file', allFiles[0].file)
        uploadProcesoMatricula(formData).then(r => {
            setResultMatricula(r.data)
        })
        .catch(e => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: e.message,
            })
        })
        .finally(() => {
            setIsLoading(false)
        })
        allFiles.forEach(f => f.remove())
    }

    const handleSubmitAsignacion = (files, allFiles) => {
        setIsLoading(true)
        setEnableSave(false)
        setResultMatricula(null)
        const formData = new FormData()
        formData.append('file', allFiles[0].file)
        uploadAsignacionMatricula(formData).then(r => {
            setResultMatricula(r.data)
            setEnableSave(r.data.filter(d => d.status === "OK").length > 0)
        })
        .catch(e => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: e.message,
            })
        })
        .finally(() => {
            setIsLoading(false)
        })
        allFiles.forEach(f => f.remove())
    }

    const cancelProcess = () => {
        setResultMatricula(null)
    }

    const saveProceso = () => {
        setIsSaving(true)
        setResultGroupedMatricula(null)
        if(assign) {
            setResultAsignacion(null)
            saveAsignacionMatricula(resultMatricula.filter(d => d.status === "OK").map(r => r.record)).then(r => {
                setResultAsignacion(r.data)
                Swal.fire({
                    icon: 'info',
                    title: 'Asignación de Nro de Matrículas',
                    text: 'Datos guardados correctamente',
                })
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: e.message,
                })
            })
            .finally(() => {
                setResultMatricula(null)
                setIsSaving(false)
            })
        }else {
            saveProcesoMatricula(resultMatricula.id).then(r => {
                let groups = []
                r.data.details.map(d => {
                    let keyCurso = "NA"
                    if(d.curso) {
                        keyCurso = d.curso.id
                    }
                    let group = groups[keyCurso]
                    if(!group) {
                        group = {curso: d.curso, students:[]}
                        groups[keyCurso] = group
                    }
                    group["students"].push(d)
                })
                setResultGroupedMatricula(groups)
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: e.message,
                })
            })
            .finally(() => {
                setResultMatricula(null)
                setIsSaving(false)
            })
        }
    }

    const changeCheckAutorize = () => {
        setEnableSave(!enableSave)
    }

    const downloadMatricula = () => {
        ReportService.read('matricula/exportListaMatricula', null, null, 'Asigna_Matrícula.xlsx')
    }

    const downloadPlantillaMatricula = () => {
        window.open(apiConfig.url + '/static/Matricula_SIGE.xlsx')
    }

    return (
        <div id="proceso-matricula-sige"
            className="pt-0"> {/* Añade espacio en la parte superior para evitar que la tabla se oculte bajo otros componentes */}

            <div className="container mt-3"> {/* Contenedor para la tabla con margen superior */}
                <center><h2>
                    { assign ? "Asignar números de Matrícula"
                    : "Inscribir Estudiante desde Lista SIGE"
                    }
                </h2></center>
                <Row>
                    <Col>
                    { assign ?
                    <>
                        <Link onClick={downloadMatricula} style={{fontSize:'larger'}}>Descargar Estudiantes sin Número Matrícula</Link>
                        <Dropzone
                            accept=".xlsx"
                            onChangeStatus={handleChangeStatus}
                            onSubmit={handleSubmitAsignacion}
                            maxFiles={1}
                            inputContent="Archivo de matrícula"
                            submitButtonContent="Subir Archivo"
                            disabled={isLoading}
                        />
                    </>
                    :
                    <>
                        <Link onClick={downloadPlantillaMatricula} style={{fontSize:'larger'}}>Descargar plantilla</Link>
                        <Dropzone
                            accept=".xlsx"
                            onChangeStatus={handleChangeStatus}
                            onSubmit={handleSubmitProceso}
                            maxFiles={1}
                            inputContent="Archivo de matrícula"
                            submitButtonContent="Subir Archivo"
                            disabled={isLoading}
                        />
                    </>
                    }
                    </Col>
                </Row>
                
                <Row style={{paddingTop:20}}>
                    { isLoading ? <Col className="text-center" style={{padding:70}}><Spinner /></Col>
                    : resultMatricula ?
                        <Col>
                            <Row>
                                <h3 className="text-center">Previsualización de Datos</h3>
                                <div className="text-right btn-actions">
                                    <Button variant="outline-primary" size="sm"
                                            disabled={isSaving || !enableSave}
                                            onClick={saveProceso}
                                        >{ isSaving ? "Guardando..." : assign ? "Asignar Matrícula" : "Guardar" }
                                    </Button>
                                    <Button variant="outline-secondary" size="sm" onClick={cancelProcess}>Cancelar</Button>
                                </div>
                            </Row>
                            { !assign ?
                            <>
                                { resultMatricula.details.filter(d => d.status === "VALIDATED").length > 0 &&
                                <Row>
                                    <Col>
                                        <Form.Check onChange={changeCheckAutorize}
                                            label="Autorizo utilizar los datos de los estudiantes a ingresar, para el uso exclusivo en la Plataforma LCD Cambar."
                                        />
                                    </Col>
                                </Row>
                                }
                                <Table className="table-striped table-hover table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="col-1">#</th>
                                            <th className="col-2">Curso</th>
                                            <th className="col-1">Rut estudiante</th>
                                            <th className="col-2">Primer Nombre</th>
                                            <th className="col-1">Apellido Paterno</th>
                                            <th className="col-1">Apellido Materno</th>
                                            <th className="col-1 text-center">Estado proceso</th>
                                            <th className="col-3">Mensaje</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {resultMatricula.details.map((e, index) =>
                                        <tr className={"status-proceso-matricula-" + e.status}>
                                            <td className="col-1">
                                                {index+1}
                                            </td>
                                            <td className="col-2">
                                                {e.curso ?
                                                    e.curso?.cursoNombre
                                                    :
                                                    "NA"
                                                }
                                            </td>
                                            <td className="col-1">
                                                {e.rut}-{e.rutDv}
                                            </td>
                                            <td className="col-2">
                                                {e.nombrePrimario}
                                            </td>
                                            <td className="col-1">
                                                {e.apellidoPrimario}
                                            </td>
                                            <td className="col-1">
                                                {e.apellidoSecundario}
                                            </td>
                                            <td className="col-1 text-center line-status">
                                                {e.status === 'VALIDATED' ?
                                                    <i className="la la-check"/>
                                                : e.status === 'ERROR' &&
                                                    <i className="la la-times"/>
                                                }
                                            </td>
                                            <td className="col-3">
                                                {e.message}
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </>
                            :   
                            <>
                                <Table className="table-striped table-hover table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="col-1">#</th>
                                            <th className="col-2">Curso</th>
                                            <th className="col-1">Rut estudiante</th>
                                            <th className="col-2">Primer Nombre</th>
                                            <th className="col-1">Apellido Paterno</th>
                                            <th className="col-1">Apellido Materno</th>
                                            <th className="col-1">Nro Matrícula</th>
                                            <th className="col-1 text-center">Estado proceso</th>
                                            <th className="col-2">Mensaje</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {resultMatricula.map((e, index) =>
                                        <tr className={"status-proceso-matricula-" + e.status}>
                                            <td className="col-1">
                                                {index+1}
                                            </td>
                                            <td className="col-2">
                                                {e.record?.curso ?
                                                    e.record.curso.cursoNombre
                                                    :
                                                    e.data &&
                                                    <>
                                                    { e.data.curso + " " + e.data.letraCurso}
                                                    </>
                                                }
                                            </td>
                                            <td className="col-1">
                                                {e.data?.rut}-{e.data?.dv}
                                            </td>
                                            { e.record?.persona ?
                                            <>
                                                <td className="col-2">
                                                    {e.record.persona.nombrePrimario}
                                                </td>
                                                <td className="col-1">
                                                    {e.record.persona.apellidoPrimario}
                                                </td>
                                                <td className="col-1">
                                                    {e.record.persona.apellidoSecundario}
                                                </td>
                                            </>
                                            : e.data &&
                                            <>
                                                <td className="col-2">
                                                    {e.data.nombre}
                                                </td>
                                                <td className="col-1">
                                                    {e.data.apellidoPrimario}
                                                </td>
                                                <td className="col-1">
                                                    {e.data.apellidoSecundario}
                                                </td>
                                            </>
                                            }
                                            <td className="col-1">
                                                {e.data?.nroMatricula}
                                            </td>
                                            <td className="col-1 text-center line-status">
                                                {e.status === 'OK' ?
                                                    <i className="la la-check"/>
                                                : e.status === 'ERROR' &&
                                                    <i className="la la-times"/>
                                                }
                                            </td>
                                            <td className="col-3">
                                                {e.message}
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </>
                            }
                        </Col>
                    : resultAsignacion ?
                        <Col>
                            <h3 className="text-center">Resultado del Proceso</h3>
                            <Table className="table-striped table-hover table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="col-1">#</th>
                                        <th className="col-2">Curso</th>
                                        <th className="col-1">Rut estudiante</th>
                                        <th className="col-2">Primer Nombre</th>
                                        <th className="col-2">Apellido Paterno</th>
                                        <th className="col-2">Apellido Materno</th>
                                        <th className="col-1">Nro Matrícula</th>
                                        <th className="col-1">Nro Lista</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {resultAsignacion.map((e, index) =>
                                    <tr>
                                        <td className="col-1">
                                            {index+1}
                                        </td>
                                        <td className="col-2">
                                            {e.curso.cursoNombre}
                                        </td>
                                        <td className="col-2">
                                            {e.persona.rut}-{e.persona.rutDv}
                                        </td>
                                        <td className="col-2">
                                            {e.persona.nombrePrimario}
                                        </td>
                                        <td className="col-2">
                                            {e.persona.apellidoPrimario}
                                        </td>
                                        <td className="col-2">
                                            {e.persona.apellidoSecundario}
                                        </td>
                                        <td className="col-1">
                                            {e.estudianteNumMatricula}
                                        </td>
                                        <td className="col-1">
                                            {e.estudianteNumLista}
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    : resultGroupedMatricula && 
                        <Col>
                            <h3 className="text-center">Resultado del Proceso</h3>
                            <Table className="table-striped table-hover table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th className="col-3">Curso</th>
                                        <th className="col-6">Profesor Jefe</th>
                                        <th className="col-3">Estudiantes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {Object.values(resultGroupedMatricula).map(g =>
                                    <tr>
                                        <td className="col-3">
                                            {g.curso ?
                                                g.curso?.cursoNombre
                                                :
                                                "NA"
                                            }
                                        </td>
                                        <td className="col-6">
                                            { g.curso?.docenteJefe?.persona ?
                                                <>
                                                {g.curso?.docenteJefe?.persona?.nombrePrimario}{" "}
                                                {g.curso?.docenteJefe?.persona?.apellidoPrimario}
                                                </>
                                                :
                                                "NA"
                                            }
                                        </td>
                                        <td className="col-3">
                                            Matriculados {g.students.filter(e => e.status === 'SAVED').length} de {g.students.length}
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    }
                </Row>
            </div>
        </div>
    );
};
