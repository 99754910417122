import React, { useEffect, useState } from "react";
import { Button, Col, Container,  Row } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  setAsignaturasMineduc,
  setGrados,
  setNivelesEnsenanza,
  setSectores,
} from "../../../../store/actions/refDataActions";
import { getListadoEnsenanzas } from "../../../../services/MatriculaService";
import CustomTooltip from "../../../components/tooltip/Tooltip";
import { useNavigate } from "react-router-dom";
import {
  cargarListaSectores,
  getAsignaturasMineduc,
  getListadoGrados,
} from "../../../../services/PostsService";
import InConstruction from "../../../components/construction/InConstruction";
import TabGeneral from "./tabs/TabGeneral";
import TabHabilidades from "./tabs/TabHabilidades";
import TabEjes from "./tabs/TabEjes";
import TabUnidades from "./tabs/TabUnidades";
import TabFormato from "./tabs/TabFormato";
import TabNavigator from "../../../components/tabNavigator/TabNavigator";
import TabActitudes from "./tabs/TabActitudes";








// const sectores = [...new Set(asignaturas.map((asig) => asig.sector))].map(
//   (sector, index) => ({ id: index, name: sector })
// );

// const grados = [...new Set(asignaturas.map((asig) => asig.grado))].map(
//   (grado, index) => ({ id: index, name: grado })
// );

const listaTiposAsignatura = [
  { id: 1, name: "Área de Aprendizaje" },
  { id: 2, name: "Asignatura" },
];

function FichaAsignaturas() {
  const navigate = useNavigate();
  const [pestañaActiva, setPestañaActiva] = useState("general");

  const [listaPestanias,] = useState([
    {
      eventKey: "general",
      name: "General",
      component: TabGeneral,
      isSelected: true,
    },
    {
      eventKey: "habilidades",
      name: "Habilidades",
      component: TabHabilidades,
      isSelected: true,
    },
    {
      eventKey: "ejes",
      name: "Ejes",
      component: TabEjes,
      isSelected: true,
    },
    {
      eventKey: "aptitudes",
      name: "Actitudes",
      component: TabActitudes,
      isSelected: true,
    },
    {
      eventKey: "unidades",
      name: "Unidades",
      component: TabUnidades,
      isSelected: true,
    },
    {
      eventKey: "formato",
      name: "Formato",
      component: TabFormato,
      isSelected: true,
    },
  ]);

  const nivelEnsenanzaState = useSelector(
    (state) => state.refDataReducer.nivelesEnsenanza
  );
  const gradosState = useSelector((state) => state.refDataReducer.grados);
  const sectoresState = useSelector((state) => state.refDataReducer.sectores);
  const asignaturasMineducState = useSelector((state) => state.refDataReducer.asignaturasMineduc);
  const dispatch = useDispatch();

  const [listaNivelesEnsenanza, setListaNivelesEnsenanza] = React.useState([]);
  const [listaGrados, setListaGrados] = React.useState([]);
  const [listaSectores, setListaSectores] = React.useState([]);
  const [listaAsignaturasMineduc, setListaAsignaturasMineduc] = React.useState([]);

  // const [codigoAsignatura, setCodigoAsignatura] = React.useState("");
  // const [nombreAsignatura, setNombreAsignatura] = React.useState("");
  // const [selectedNivelEnsenanza, setSelectedNivelEnsenanza] =
  //   React.useState(null); // Asegurarse que sea null inicialmente

  // const [selectedAsignaturaMineduc, setSelectedAsignaturaMineduc] =
  //   React.useState(null);
  // const [selectedSector, setSelectedSector] = React.useState(null);
  // const [selectedGrado, setSelectedGrado] = React.useState(null);
  // const [selectedTipo, setSelectedTipo] = React.useState(null);


  const [asignatura, setAsignatura] = useState(
    {
        tabGeneral:{},
        tabHabilidades:{},
        tabEjes:{},
        tabAptitudes:{},
        tabUnidades:{},
        tabFormato:{}
    }
  );

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const promises = [];
  
        if (nivelEnsenanzaState.length === 0) promises.push(getListadoEnsenanzas());
        if (gradosState.length === 0) promises.push(getListadoGrados());
        if (sectoresState.length === 0) promises.push(cargarListaSectores());
        if (asignaturasMineducState.length === 0) {
          console.log("Cargando asignaturas desde la API porque el estado está vacío.");
          promises.push(getAsignaturasMineduc());
        }
  
        const [ens, grados, sectores, asignaturas] = await Promise.all(promises);
  
        if (ens && nivelEnsenanzaState.length === 0) {
          dispatch(setNivelesEnsenanza(ens.data.curriculumEnsenanzas));
          setListaNivelesEnsenanza(ens.data.curriculumEnsenanzas);
        } else {
          setListaNivelesEnsenanza(nivelEnsenanzaState);
        }
  
        if (grados && gradosState.length === 0) {
          dispatch(setGrados(grados.data.curriculumGrados));
          setListaGrados(grados.data.curriculumGrados);
        } else {
          setListaGrados(gradosState);
        }
  
        if (sectores && sectoresState.length === 0) {
          dispatch(setSectores(sectores));
          setListaSectores(sectores);
        } else {
          setListaSectores(sectoresState);
        }
  
        // Aquí agregamos un log para verificar la estructura de 'asignaturas'
        console.log("Asignaturas desde la API:", asignaturas);
  
        if (asignaturas && asignaturasMineducState.length === 0) {
          dispatch(setAsignaturasMineduc(asignaturas)); // Asegúrate de que 'asignaturas' sea el array correcto
          setListaAsignaturasMineduc(asignaturas);
          console.log("Asignaturas cargadas correctamente desde la API.");
        } else {
          setListaAsignaturasMineduc(asignaturasMineducState);
          console.log("Usando asignaturas almacenadas en Redux.");
        }
  
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };
  
    loadInitialData();
  }, [
    asignaturasMineducState, 
    dispatch,
    gradosState,
    nivelEnsenanzaState,
    sectoresState,
  ]);
  
  // Verificación adicional del estado de asignaturas en Redux
  useEffect(() => {
    if (asignaturasMineducState.length === 0) {
      console.warn("Advertencia: asignaturasMineducState está vacío al regresar a la vista.");
    } else {
      console.log("Asignaturas Mineduc disponibles en Redux:", asignaturasMineducState);
    }
  }, [asignaturasMineducState]);
  
  
  // Opcional: Añadir un useEffect adicional para verificar que el estado se actualiza correctamente
  useEffect(() => {
    if (asignaturasMineducState.length === 0) {
      console.warn("Advertencia: asignaturasMineducState está vacío al regresar a la vista.");
    } else {
      console.log("Asignaturas Mineduc disponibles:", asignaturasMineducState);
    }
  }, [asignaturasMineducState]);
  

  
  // useEffect(() => {
  //   console.log(listaAsignaturasMineduc);
  //   console.log(asignaturasMineducState)
  // }, [listaAsignaturasMineduc, asignaturasMineducState]);

 

  // useEffect(() => {
  //   if (selectedAsignaturaMineduc !== null) {
 
  //     console.log(selectedAsignaturaMineduc);

  //     console.log(
  //       listaAsignaturasMineduc
  //         .filter((asignatura) => {
  //           console.log(asignatura.areaAprendizaje);

  //           return asignatura.areaAprendizaje === selectedAsignaturaMineduc;
  //         })
  //         .filter((asignatura) => asignatura.id === selectedAsignaturaMineduc)
  //     );

  //   }
  // }, [listaAsignaturasMineduc, selectedAsignaturaMineduc]);

  return (
    <Container fluid style={{ height: "90dvh", overflowX: "hidden" }}>
      <TabNavigator
        title="Ficha Asignatura"
        activeTab={pestañaActiva}
        onSelectTab={setPestañaActiva}
        tabList={listaPestanias}
        additionalProps={{
          
        }}
      />
      {/* <InConstruction /> */}

      <Row className="mt-auto w-100 py-2">
        <Col className="d-flex justify-content-center justify-content-md-end align-items-center gap-3">
          <CustomTooltip tooltipMsg={"Ir a la lista"}>
            <Button
              variant="color-gray-outline"
              onClick={() => navigate("/lista-asignaturas")}
            >
              Ir Lista
            </Button>
          </CustomTooltip>
          {/* <CustomTooltip tooltipMsg={false ? "No tienes permisos" : "Guardar"}>
            <div style={{ display: "block", alignItems: "center" }}>
              <Button variant="color-acento" onClick={() => {}} disabled={true}>
                Guardar
              </Button>
            </div>
          </CustomTooltip> */}
        </Col>
      </Row>
    </Container>
  );
}

export default FichaAsignaturas;
