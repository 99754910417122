import React from "react";
import { Form } from "react-bootstrap";
import CustomTooltip from "../tooltip/Tooltip";

function TextAreaInput({
  controlId,
  label,
  name,
  value,
  onChange,
  required,
  disabled,
  placeholder,
  onBlur,
  onInput,
  isInvalid,
  maxLength,
  onPaste,
  type,
  tooltipMessage,
  style,
  hideLabel,
  className,
}) {
  // Función para validar el input con la expresión regular
  const handleInputChange = (e) => {
    const regex = /^[a-zA-ZÀ-ÿ0-9\s.,;:¡!¿?'"()+\-/*\\*]*$/;

    if (regex.test(e.target.value) || e.target.value === "") {
      // Si el valor es válido según la regex, llama a la función onChange
      onChange(e);
    }
  };

  return (
    <Form.Group
      controlId={controlId}
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      {required && !hideLabel ? (
        <CustomTooltip
          tooltipMsg={
            tooltipMessage ? (
              <span>{tooltipMessage}</span>
            ) : (
              <span>Dato Requerido</span>
            )
          }
        >
          <Form.Label>
            {label}{" "}
            {required && tooltipMessage && (
              <span className="text-danger">**</span>
            )}
            {required && !tooltipMessage && (
              <span className="text-danger">*</span>
            )}
          </Form.Label>
        </CustomTooltip>
      ) : !hideLabel ? (
        <Form.Label>{label}</Form.Label>
      ) : null}
      <Form.Control
        as="textarea"
        type={type}
        name={name}
        value={value}
        onChange={handleInputChange} // Usamos la nueva función para validar
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={onBlur}
        onInput={onInput}
        isInvalid={isInvalid}
        maxLength={maxLength}
        onPaste={onPaste}
        style={style}
        autoComplete="off"
        className={className}
      />
    </Form.Group>
  );
}

export default TextAreaInput;
