// import './InscribirEstudianteSige.scss';
import React, { useState } from "react";
import { Button, Modal, Table, Spinner, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone-uploader';
import Swal from "sweetalert2";
import { uploadProcesoMatricula, saveProcesoMatricula } from '../../../services/MatriculaService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons'; 
import apiConfig from '../../../config/apiConfig';

export default function InscribirEstudianteSigeModal({showModal, handleClose}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [resultMatricula, setResultMatricula] = useState(null);
    const [groupDetails, setGroupDetails] = useState(null);
    const [resultGroupedMatricula, setResultGroupedMatricula] = useState(null);
    const [enableSave, setEnableSave] = useState(false);
    
    const navigate = useNavigate();

    // const handleIraMatriculaClick = () => {
    //     navigate('/matricula-vista');
    //   };

    const handleChangeStatus = ({ meta }, status) => {
        console.log(status, meta); 
    }

    const handleSubmitProceso = (files, allFiles) => {
        setIsLoading(true);
        setEnableSave(false);
        setResultMatricula(null);
        setGroupDetails(null)
        const formData = new FormData();
        formData.append('file', allFiles[0].file);

        uploadProcesoMatricula(formData).then(r => {
            setResultMatricula(r.data);
        })
        .catch(e => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: e.message,
            });
        })
        .finally(() => {
            setIsLoading(false);
        });
        allFiles.forEach(f => f.remove());
    }

    const cancelProcess = () => {
        setResultMatricula(null);
    }
    
    const saveProceso = () => {
        setIsSaving(true);
        setResultGroupedMatricula(null);
        setGroupDetails(null);

        saveProcesoMatricula(resultMatricula.id).then(r => {
            let groups = [];
            r.data.details.map(d => {
                let keyCurso = "NA";
                if (d.curso) {
                    keyCurso = d.curso.id;
                }
                let group = groups[keyCurso];
                if (!group) {
                    group = { curso: d.curso, students: [] };
                    groups[keyCurso] = group;
                }
                group["students"].push(d);
            });
            setResultGroupedMatricula(groups);
        })
        .catch(e => {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: e.message,
            });
        })
        .finally(() => {
            setResultMatricula(null);
            setIsSaving(false);
        });
    }

    const changeCheckAutorize = () => {
        setEnableSave(!enableSave);
    }

    const downloadPlantillaMatricula = () => {
        window.open(apiConfig.url + '/static/Matricula_SIGE.xlsx');
    }

    const handleCloseGroupDetails = () => {
        setGroupDetails(null)
    }

    return (
        <>
            <Modal show={showModal} onHide={handleClose} fullscreen={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Inscribir Estudiante desde Lista SIGE</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflowX:"hidden"}}>
                    <div className="pt-0">
                        <div>
                            <Row>
                                <Col>
                                    {!resultMatricula && (
                                        <Dropzone
                                            accept=".xlsx"
                                            onChangeStatus={handleChangeStatus}
                                            onSubmit={handleSubmitProceso}
                                            maxFiles={1}
                                            inputContent={<><FontAwesomeIcon icon={faUpload} className='me-2'/> Arrastra y suelta aquí para subir archivos</>}
                                            submitButtonContent="Subir Archivo"
                                            disabled={isLoading}
                                        />
                                    )}
                                </Col>
                            </Row>

                            <Row style={{ paddingTop: 10 }}>
                                {isLoading ? 
                                    <Col className="text-center" style={{ padding: 70 }}><Spinner /></Col>
                                    : resultMatricula ?
                                    <Col>
                                        <Row className="mb-2 justify-content-center align-items-center">
                                            <p className="text-left" style={{fontSize:16, fontWeight:500}}>Previsualización de Datos</p>
                                            <div className="text-right btn-actions">
                                                <Button variant="primary me-2" size="sm"
                                                    disabled={isSaving || !enableSave}
                                                    onClick={saveProceso}
                                                >{isSaving ? "Guardando..." : "Guardar"}</Button>
                                                <Button variant="outline-primary" size="sm" onClick={cancelProcess}>Cancelar</Button>
                                            </div>
                                        </Row>
                                        {resultMatricula.details.filter(d => d.status === "VALIDATED").length > 0 &&
                                            <Row>
                                                <Col className="mb-3">
                                                    <Form.Check onChange={changeCheckAutorize}
                                                        label="Autorizo utilizar los datos de los estudiantes a ingresar, para el uso exclusivo en la Plataforma LCD Cambar."
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        <Table className="table-striped table-hover table-sm">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="col-1">#</th>
                                                    <th className="col-2">Curso</th>
                                                    <th className="col-1">Rut estudiante</th>
                                                    <th className="col-2">Primer Nombre</th>
                                                    <th className="col-1">Apellido Paterno</th>
                                                    <th className="col-1">Apellido Materno</th>
                                                    <th className="col-1 text-center">Estado proceso</th>
                                                    <th className="col-3">Mensaje</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {resultMatricula.details.map((e, index) =>
                                                    <tr className={"status-proceso-matricula-" + e.status}>
                                                        <td className="col-1">{index + 1}</td>
                                                        <td className="col-2">{e.curso ? e.curso?.cursoNombre : "NA"}</td>
                                                        <td className="col-1">{e.rut}-{e.rutDv}</td>
                                                        <td className="col-2">{e.nombrePrimario}</td>
                                                        <td className="col-1">{e.apellidoPrimario}</td>
                                                        <td className="col-1">{e.apellidoSecundario}</td>
                                                        <td className="col-1 text-center line-status">
                                                            {e.status === 'VALIDATED' ? <i className="la la-check"/> : e.status === 'ERROR' && <i className="la la-times"/>}
                                                        </td>
                                                        <td className="col-3">{e.message}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                    : resultGroupedMatricula &&
                                    <Col>
                                        <h3 className="text-center">Resultado del Proceso</h3>
                                        <Table className="table-striped table-hover table-sm">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="col-3">Curso</th>
                                                    <th className="col-6">Profesor Jefe</th>
                                                    <th className="col-3">Estudiantes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.values(resultGroupedMatricula).map(g =>
                                                    <tr>
                                                        <td className="col-3">{g.curso ? g.curso?.cursoNombre : "NA"}</td>
                                                        <td className="col-6">
                                                            {g.curso?.docenteJefe?.persona ? `${g.curso?.docenteJefe?.persona?.nombrePrimario} ${g.curso?.docenteJefe?.persona?.apellidoPrimario}` : "NA"}
                                                        </td>
                                                        <td className="col-3"><a href="#" onClick={() => setGroupDetails(g)}>Inscritos {g.students.filter(e => e.status === 'SAVED').length} de {g.students.length}</a></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            { showModal && groupDetails &&
                <Modal show={true} onHide={handleCloseGroupDetails} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Detalle de estudiantes inscritos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{overflowX:"hidden"}}>
                        <Row>
                            <Col xs={2}><strong>Curso</strong></Col>
                            <Col xs={4}>{groupDetails.curso?.cursoNombre}</Col>
                            <Col xs={2}><strong>Profesor Jefe</strong></Col>
                            <Col xs={4}>{groupDetails.curso?.docenteJefe?.persona?.nombrePrimario} {groupDetails.curso?.docenteJefe?.persona?.apellidoPrimario}</Col>
                        </Row>
                        <Table className="table-striped table-hover table-sm">
                            <thead className="thead-light">
                                <tr>
                                    <th className="col-2">Rut estudiante</th>
                                    <th className="col-3">Primer Nombre</th>
                                    <th className="col-2">Apellido Paterno</th>
                                    <th className="col-2">Apellido Materno</th>
                                    <th className="col-3">Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {groupDetails.students.map((e, index) =>
                                    <tr>
                                        <td className="col-2">{e.rut}-{e.rutDv}</td>
                                        <td className="col-3">{e.nombrePrimario}</td>
                                        <td className="col-2">{e.apellidoPrimario}</td>
                                        <td className="col-2">{e.apellidoSecundario}</td>
                                        <td className="col-3">{e.status === 'SAVED' ? 'Inscrito' : 'No inscrito'}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseGroupDetails}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}
