import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import estilosEstado from '../../objetoEstilos/estiloEstados';
import ButtonPersonalizado from "../buttonpersonalizado/ButtonPersonalizado";

const ModalCambioEstadoSubvencion = ({
  show,
  handleClose,
  estudianteNomPri,
  estudianteApePri,
  fecha,
  bloque,
  estadoActual,
  handleSave,
  estudianteId,
  claseId,
  asistenciaId
}) => {
  const [nuevoEstado, setNuevoEstado] = useState(
    estadoActual === 'pendiente' ? 'presente' : estadoActual
  );
  const location = useLocation();
  const modalRef = useRef(null);

  useEffect(() => {
    if (estadoActual === 'pendiente') {
      setNuevoEstado('presente');
    } else {
      setNuevoEstado(estadoActual);
    }
  }, [estadoActual]);

  useEffect(() => {
    if (show) {
      modalRef.current.focus();
    }
  }, [show]);

  const handleChange = (estado) => {
    setNuevoEstado(estado);
  };

  const handleGuardar = () => {
    handleSave(nuevoEstado, estudianteId, claseId, asistenciaId);
    handleClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleGuardar();
    } else if (event.key === 'ArrowLeft' || event.key === 'a' || event.key === 'A' || event.key === 'ArrowRight' || event.key === 'd' || event.key === 'D') {
      if (nuevoEstado === 'presente') {
        setNuevoEstado('ausente');
      } else {
        setNuevoEstado('presente');
      }
    }
  };

  const estadoActualEstilo = estilosEstado[estadoActual] || { icon: 'bi bi-question-circle', iconColor: 'grey', description: 'Sin registro' };

  const estiloTitulo = {
    color: "var(--color-texto-oscuro)",
    fontSize: "1rem",
    fontWeight: "500",
    margin: 0,
    padding: 0
  };

  const estiloTexto = {
    color: "var(--color-texto-oscuro)",
    fontSize: "1rem",
    fontWeight: "normal",
    margin: 0,
    padding: 0
  };

  const formatearFechaModal = (fecha) => {
    const [day, month, year] = fecha.split('-');
    return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div ref={modalRef} tabIndex="0" onKeyDown={handleKeyDown}>
        <Modal.Header closeButton>
          <Modal.Title className='w-100 text-center'>Cambiar Estado de Asistencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
            <div style={{ flex: 1, textAlign: 'left', marginRight: '20px' }}>
              <p style={estiloTitulo}>Estudiante:</p>
              <p style={estiloTexto}>{`${estudianteNomPri} ${estudianteApePri}`}</p>
            </div>
            <div style={{ flex: 1, textAlign: 'left', marginRight: '20px' }}>
              <p style={{...estiloTitulo, marginTop: "9px"}}>Estado Actual:</p>
              <p style={estiloTexto}>
                <i className={`${estadoActualEstilo.icon}`} style={{ color: estadoActualEstilo.iconColor, fontSize: "20px", verticalAlign: "middle" }}></i>
                {estadoActualEstilo.description === 'Sin registro' ? ' Pendiente ' : ''}
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
            <div style={{ flex: 1, textAlign: 'left', marginRight: '20px' }}>
              <p style={estiloTitulo}>Fecha:</p>
              <p style={estiloTexto}>{formatearFechaModal(fecha)}</p>
            </div>
            {!location.pathname.includes('/asistencia-mensual-subvenciones') && (
              <div style={{ flex: 1, textAlign: 'left', marginRight: '20px' }}>
                <p style={estiloTitulo}>Bloque:</p>
                <p style={estiloTexto}>{bloque}</p>
              </div>
            )}
          </div>
          <Row className="text-center justify-content-center">
            {['presente', 'ausente'].map((tipoEstado) => (
              <Col key={tipoEstado} className="col-3">
                <div
                  className="status-square d-flex justify-content-center align-items-center"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: 'transparent',
                    border: `2px solid ${nuevoEstado === tipoEstado ? estilosEstado[tipoEstado].iconColor : 'lightgrey'}`,
                    cursor: "pointer",
                    margin: "10px auto",
                  }}
                  onClick={() => handleChange(tipoEstado)}
                >
                  {nuevoEstado === tipoEstado && <i className={`${estilosEstado[tipoEstado].icon}`} style={{ color: estilosEstado[tipoEstado].iconColor, fontSize: "20px" }}></i>}
                </div>
                <p style={{ ...estiloTexto, marginTop: "10px" }}>{estilosEstado[tipoEstado].description}</p>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonPersonalizado variant="color-acento" onClick={handleGuardar}>
            Firmar
          </ButtonPersonalizado>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ModalCambioEstadoSubvencion;
