import React from 'react';

const AgendaPersonalizada = ({ event }) => {
  return (
    <div className="agenda-event">
      <strong>{event.title}</strong>
      <p>{new Date(event.start).toLocaleDateString()} - {new Date(event.end).toLocaleDateString()}</p>
      <p>Descripción: {event.description || 'Sin descripción'}</p>
    </div>
  );
};

export default AgendaPersonalizada;
