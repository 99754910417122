import React from "react";
import { Container } from "react-bootstrap";
import InConstruction from "../../../../components/construction/InConstruction";

const ejes = [
  {
    id: 1,
    name: "Números y Operaciones",
    OA: [
      {
        id: 1,
        name: "OA1",
        description: "Descripción de OA1 sdflkjgsdflgjksdfg aslkdjfhaslkdjfas skaldjfaskljdfasd sakldjfaslkdjfnaskdf sadnfbaskdlfas ",
      },
      {
        id: 2,
        name: "OA2",
        description: "Descripción de OA2 sdflkjgsdflgjksdfg aslkdjfhaslkdjfas skaldjfaskljdfasd sakldjfaslkdjfnaskdf sadnfbaskdlfas ",
      },

    ],
  },
  {
    id: 2,
    name: "Álgebra y Funciones",
    OA: [
      {
        id: 1,
        name: "OA3",
        description: "Descripción de OA3 sobre álgebra y funciones, incluyendo ecuaciones y desigualdades.",
      },
      {
        id: 2,
        name: "OA4",
        description: "Descripción de OA4 sobre funciones lineales y cuadráticas, y sus aplicaciones.",
      },
    ],
  },
  {
    id: 3,
    name: "Geometría",
    OA: [
      {
        id: 1,
        name: "OA5",
        description: "Descripción de OA5 sobre figuras geométricas y sus propiedades.",
      },
      {
        id: 2,
        name: "OA6",
        description: "Descripción de OA6 sobre teoremas de geometría y su demostración.",
      },
    ],
  },
  {
    id: 4,
    name: "Estadística y Probabilidades",
    OA: [
      {
        id: 1,
        name: "OA7",
        description: "Descripción de OA7 sobre análisis de datos y representación gráfica.",
      },
      {
        id: 2,
        name: "OA8",
        description: "Descripción de OA8 sobre conceptos básicos de probabilidad y su cálculo.",
      },
    ],
  }
];

function TabEjes() {
  return (
    // <Container
    //   style={{
    //     height: "75dvh",
    //     display: "flex",
    //     flexDirection: "column",
    //     gap: "1rem",
    //     paddingTop: "1rem",
    //   }}
    // >
    //   {ejes.map((eje) => (
    //     <div key={eje.id}>
    //       <h3>{eje.name}</h3>
    //       {eje.OA.map((oa) => (
    //         <div key={oa.id} className="d-flex gap-3">
    //           <h5>{oa.name}</h5>
    //           <p>{oa.description}</p>
    //         </div>
    //       ))}
    //     </div>
    //   ))}
    // </Container>

    <InConstruction />
  );
}

export default TabEjes;
