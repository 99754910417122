import React from "react";
import { Form } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";
import CustomTooltip from "../tooltip/Tooltip";

function EmailInput({
  label,
  name,
  value,
  onChange,
  required,
  disabled,
  placeholder,
  onBlur,
  onInput,
  maxLength,
  onPaste,
  tooltipMessage,
  controlId,
  hideLabel,
  style
}) {
  // Función para validar el formato del email
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Estado para gestionar la validez del email
  const isValidEmail = validateEmail(value);

  return (
    <Form.Group controlId={controlId} >
      {required && !hideLabel ? (
        <CustomTooltip tooltipMsg={tooltipMessage ? (
          <span>{tooltipMessage}</span>
        ) : (
          <span>Dato Requerido</span>
        )} >
          
          <Form.Label>
            {label}{" "}
            {required && tooltipMessage && (
              <span className="text-danger">**</span>
            )}
            {required && !tooltipMessage && (
              <span className="text-danger">*</span>
            )}
          </Form.Label>
        </CustomTooltip>
      ) : !hideLabel ? (
        <Form.Label>{label}</Form.Label>
      ) : null}

        <Form.Control
          type="email"
          name={name}
          value={value || ""}
          onChange={onChange}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={onBlur}
          onInput={onInput}
          maxLength={maxLength}
          onPaste={onPaste}
          style={style}
        />

        {/* Icono de advertencia solo si el email es inválido */}
        {value && !isValidEmail && (
          <div style={{position: 'absolute', top: 40, right: 30}}>
            <FaExclamationCircle className="text-danger" />
          </div>
        )}

        {/* Mensaje de error si el email es inválido */}
        <Form.Control.Feedback type="invalid">
          Por favor, introduce un email válido.
        </Form.Control.Feedback>
    </Form.Group>
  );
}

export default EmailInput;
