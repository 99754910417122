import React from 'react';
import SchoolTable from '../../components/colegioroltabla/SchoolTable';

export default function ColegioRol() {
    return (
        <div
            className="pt-0"> {/* Añade espacio en la parte superior para evitar que la tabla se oculte bajo otros componentes */}


            <div className="container mt-3"> {/* Contenedor para la tabla con margen superior */}
                <SchoolTable/>
            </div>
        </div>
    );
};
