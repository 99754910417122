import { es } from "date-fns/locale";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Modal, Nav, Row, Tab } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Swal from "sweetalert2";
import useGetListadoCursos from "../../../../hooks/useGetListadoCursos";
import {
  cargarListaComunas,
  cargarListaEtnias,
  cargarListaGenerosRegCivil,
  cargarListaIdentidadGenero,
  cargarListaLocalEscolar,
  cargarListaNacionalidades,
  cargarListaRegiones,
  cargarListaVinculosParentescos,
  getListadoGrados,
  saveFichaEstudiante,
} from "../../../../services/PostsService";
import {
  detectarNumeroAlFinal,
  formatearIPEIPA,
  formatearRUN,
  limpiarPuntosYGuiones,
  removeDots,
  separarRun,
  validarIPE,
  validarRUN,
} from "../../../../util/util";

import Title from "../../../components/titles/Title";
import useCheckRoles from "../../../../hooks/useCheckRoles";
import { useDispatch, useSelector } from "react-redux";
import { parseISO } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTooltip from "../../../components/tooltip/Tooltip";
import useCheckCurrentSchool from "../../../../hooks/useCheckCurrentSchool";
import { getNextNumMatricula } from "../../../../services/MatriculaService";
import { setGrados } from "../../../../store/actions/refDataActions";

import TabPersonal from "./tabs/TabPersonal";
import TabEscolar from "./tabs/TabEscolar";
import TabSocioeconomico from "./tabs/TabSocioeconomico";
import TabSalud from "./tabs/TabSalud";
import TabApoderado from "./tabs/TabApoderado";
import TabAnotaciones from "./tabs/TabAnotaciones";
import TabRegistroActividad from "./tabs/TabRegistroActividad";

registerLocale("es", es);

const periodoActual = "2024";

const FichaEstudiante = () => {
  const [isFichaCargada, setIsFichaCargada] = useState(false);

  const { isDirectivo } = useCheckRoles();
  useCheckCurrentSchool();

  const dispatch = useDispatch();
  const [isReadyToUploadAvatar, setIsReadyToUploadAvatar] = useState();
  const [retryData, setRetryData] = useState(null);
  const currentSchoolState = useSelector((state) => state.auth.currentSchool);
  // const roles = useSelector((state) => state.auth.roles);
  // const isDirectivo = roles.find((item) => item.rol.id === 5) ? true : false;

  const navigate = useNavigate();
  const location = useLocation();
  const ficha = location.state?.ficha;

  const [listaNacionalidades, setListaNacionalidades] = useState([]);
  const [listaEtnias, setListaEtnias] = useState([]);
  const [listaIdentidadesGenero, setListaIdentidadesGenero] = useState([]);
  const [listaGenerosRegCivil, setListaGenerosRegCivil] = useState([]);
  const [listaVinculos, setListaVinculos] = useState([]);
  const [listaRegiones, setListaRegiones] = useState([]);
  const [listaComunas, setListaComunas] = useState([]);
  const [listaLocalEscolar, setListaLocalEscolar] = useState([]);

  const [errors, setErrors] = useState();

  const [tabActiva, setPestañaActiva] = useState("antecedentesPersonales");
  const [tabsHabilitadas] = useState([
    "antecedentesPersonales",
    "antecedentesEscolares",
    "antecedentesSocioeconomicos",
    "antecedentesSalud",
    "datosApoderado",
    "anotaciones",
    "registroActividad",
  ]);

  const [listaPestanias] = useState([
    {
      eventKey: "antecedentesPersonales",
      name: "Personal",
      component: TabPersonal,
      isSelected: true,
    },
    {
      eventKey: "antecedentesEscolares",
      name: "Escolar",
      component: TabEscolar,
      isSelected: true,
    },
    {
      eventKey: "antecedentesSocioeconomicos",
      name: "Socioeconómico",
      component: TabSocioeconomico,
      isSelected: true,
    },
    {
      eventKey: "antecedentesSalud",
      name: "Salud",
      component: TabSalud,
      isSelected: true,
    },
    {
      eventKey: "datosApoderado",
      name: "Apoderado",
      component: TabApoderado,
      isSelected: true,
    },
    {
      eventKey: "anotaciones",
      name: "Anotaciones",
      component: TabAnotaciones,
      isSelected: true,
    },
    {
      eventKey: "registroActividad",
      name: "Actividad",
      component: TabRegistroActividad,
      isSelected: true,
    },
  ]);

  const [lastAvailableMatricula, setLastAvailableMatricula] = useState(null);
  // currentSchoolLocation.comuna.id
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedComuna, setSelectedComuna] = useState();
  // currentSchoolLocation.region.id

  const { cursos } = useGetListadoCursos();

  const [listaCursos, setListaCursos] = useState(cursos);
  const [ensenanzaId, setEnsenanzaId] = useState(null);

  const [listaGrados, setListaGrados] = useState([]);

  const [apoderadoTitular, setApoderadoTitular] = useState({
    run: "",
    runDv: "",
    ipaApoderado: "",
    ipaDv: "",
    apellidoPaternoApo: "",
    apellidoMaternoApo: "",
    nombresApo: "",
    nombresSecundariosApo: "",
    vinculo: "",
    telefonoMovil: "",
    direccion: "",
    tutorLegal: true,
    emailApoderado: "",
  });

  const [apoderadosSuplentes, setApoderadosSuplentes] = useState([]);

  // const [apoderadoSuplenteVacio, setApoderadoSuplenteVacio] = useState()

  const [valoresEstudiante, setValoresEstudiante] = useState({
    estudianteId: null,
    run: "",
    ipe: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombres: "",
    nombresSecundarios: "",
    nombreSocial: "",
    fechaNacimiento: null,
    genero: "",
    direccion: "",
    curso: "",
    grado: "",
    nacionalidad: "",
    etnia: "",
    viveCon: [],
    region: "",
    comuna: "",
    comunaNacimiento: "",
    colegioProcedencia: "",
    localEscolar: listaLocalEscolar[0],
    excedente: false,
    numeroResolucion: "",
    fechaResolucion: null,
    generoIdentidad: "",
    email: "",
    estudianteNumLista: "",
    estudianteFechaMatricula: null,
    estudianteNumMatricula: null,
    motivoRetiro: "",
    retiroFecha: null,
    ensenanza: "",
    esPie: false,
    esPrioritario: false,
    esPreferente: false,
    nee: false,
    observaciones: "",
    autorizaData: true,
  });

  const [valoresApoderado, setValoresApoderado] = useState([]);

  const [autorizadosRetirar, setAutorizadosRetirar] = useState([]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const manejarCambioTexto = useCallback(
    (id, value, setValores, setErrors, index = null) => {
      // Formatear el RUN si es el campo correspondiente
      if (id === "run") {
        value = value.replace(/[^0-9Kk.-]/g, "");
        value = formatearRUN(value);
      }

      // Validación de valores de texto

      if (
        id === "apellidoPaterno" ||
        id === "apellidoMaterno" ||
        id === "nombres" ||
        id === "nombresSecundarios" ||
        id === "apellidoPaternoApo" ||
        id === "apellidoMaternoApo" ||
        id === "nombresApo" ||
        id === "nombresSecundariosApo"
      ) {
        // Elimina cualquier dígito y caracteres especiales no deseados
        value = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s'-]/g, "");
      }

      if (id === "run") {
        // console.log(value)
        const isValid = validarRUN(value);
        if (!isValid) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            run: "RUN inválido",
          }));
        } else {
          const { run, ...rest } = errors;
          setErrors(rest);
        }
      }

      if (id === "ipe") {
        setErrors((prevErrors) => {
          if (value === "") {
            const { ipe, ...rest } = prevErrors;
            return rest;
          } else {
            return prevErrors;
          }
        });

        const isValid = validarIPE(value);
        // console.log(isValid);
        if (!isValid) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ipe: "IPE inválido",
          }));
        } else {
          const { ipe, ...rest } = errors;
          setErrors(rest);
        }
      }

      if (id === "estudianteNumLista") {
        value = value.replace(/[^0-9]/g, "");
      }

      if (id === "estudianteNumMatricula") {
        value = value.replace(/[^0-9]/g, "");
        if (value === "") {
          setValores((prevValues) => ({
            ...prevValues,
            estudianteFechaMatricula: value,
          }));
        }
      }

      // Actualización de estados, revisando si el input es el teléfono móvil
      if (id === "telefonoMovil") {
        setValores((prevValues) => {
          if (index !== null) {
            const updatedValues = [...prevValues];
            updatedValues[index] = {
              ...updatedValues[index],
              [id]: value,
            };
            return updatedValues;
          } else {
            return {
              ...prevValues,
              [id]: value,
            };
          }
        });
      } else {
        setValores((prevValues) => ({
          ...prevValues,
          [id]: value,
        }));
      }
    },
    [errors]
  );

  const manejarCambioFecha = (date, id) => {
    setValoresEstudiante((prevValues) => ({
      ...prevValues,
      [id]: date,
    }));
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate("/matricula-vista");
  };

  function ajustarTutorLegal(datos) {
    const { apoderados } = datos;

    if (!apoderados || apoderados.length === 0) {
      return datos; // No hay apoderados para procesar
    }

    // Si solo hay un apoderado, asegurarse de que sea tutor legal
    if (apoderados.length === 1) {
      apoderados[0].tutorLegal = true;
    } else {
      // Si hay más de un apoderado
      apoderados.forEach((apoderado, index) => {
        if (index === 0) {
          apoderado.tutorLegal = true; // El primer apoderado es el tutor legal
        } else {
          apoderado.tutorLegal = false; // Los demás no son tutores legales
        }
      });
    }

    return datos; // Retornar el objeto modificado
  }

  const handleSaveData = useCallback(
    (overrides = {}) => {
      // Primero, verifica si hay errores
      if (Object.keys(errors).length > 0) {
        // Si hay errores, construye un mensaje que liste todos los errores
        const errorMessages = Object.values(errors)
          .map(
            (error) =>
              `<li style="text-align: left; margin-left: 20px; font-size: 1rem; text-overflow:'ellipsis'; text-wrap: 'nowrap';">● ${error}</li>`
          )
          .join("");
        const errorMessageHtml = `<ul style="list-style: inside; padding-left: 20px;">${errorMessages}</ul>`;

        // Muestra el mensaje con todos los errores listados
        Swal.fire({
          icon: "error",
          title: "Datos obligatorios",
          html: errorMessageHtml, // Usamos `html` para mostrar una lista con los errores
          confirmButtonColor: "var(--color-acento)",
        });

        // Salir de la función si hay errores
        return;
      }

      const transformarEstructura = (objeto, adultosAutorizados) => {
        // Mapeo de los valores del estudiante
        const data = {
          periodoId: Number(objeto.periodoId),
          estudiante: {
            estudianteId: objeto.estudiante.estudianteId,
            run: Number(objeto.estudiante.run) || null, // convertir a número si existe,
            runDv: objeto.estudiante.runDv || null,
            ipe: objeto.estudiante.ipe || null, // convertir a null si está vacío
            ipeDv: objeto.estudiante.ipeDv || null,
            apellidoPaterno: objeto.estudiante.apellidoPaterno || null,
            apellidoMaterno: objeto.estudiante.apellidoMaterno || null,
            nombres: objeto.estudiante.nombres || null,
            nombresSecundarios: objeto.estudiante.nombresSecundarios || null,
            nombreSocial: objeto.estudiante.nombreSocial || null,
            fechaNacimiento: objeto.estudiante.fechaNacimiento || null,
            comunaNacimiento:
              Number(objeto.estudiante.comunaNacimiento) || null,
            genero: Number(objeto.estudiante.genero) || null,
            generoIdentidad: Number(objeto.estudiante.generoIdentidad) || null,
            direccion: objeto.estudiante.direccion || null,
            nacionalidad: Number(objeto.estudiante.nacionalidad) || null,
            etnia: Number(objeto.estudiante.etnia) || null,
            comuna: Number(objeto.estudiante.comuna) || null,
            region: Number(objeto.estudiante.region) || null,
            email: objeto.estudiante.email || null,
            estudianteNumLista: objeto.estudiante.estudianteNumLista || null,
            ensenanza: Number(objeto.estudiante.ensenanza) || null,
            grado: objeto.estudiante.grado || null,
            curso: Number(objeto.estudiante.curso) || null,
            localEscolar: objeto.estudiante.localEscolar || null,
            excedente: objeto.estudiante.excedente || false,
            excedenteNumeroResolucion:
              objeto.estudiante.excedenteNumeroResolucion || null,
            excedenteFechaResolucion:
              objeto.estudiante.excedenteFechaResolucion || null,
            comentario: objeto.estudiante.comentario || "",
            estudianteNumMatricula:
              objeto.estudiante.estudianteNumMatricula || null,
            estudianteFechaMatricula:
              objeto.estudiante.estudianteFechaMatricula || null,
            esPreferente: objeto.estudiante.esPreferente || false,
            esPrioritario: objeto.estudiante.esPrioritario || false,
            esPie: objeto.estudiante.esPie || false,
            colegioProcedencia: objeto.estudiante.colegioProcedencia || null,
            retiroFecha: objeto.estudiante.retiroFecha || null,
            motivoRetiro: objeto.estudiante.motivoRetiro || null,
            nee: objeto.estudiante.nee || false,
            autorizaData: objeto.estudiante.autorizaData || 1,
            viveCon: Array.isArray(objeto.estudiante.viveCon)
              ? objeto.estudiante.viveCon
              : [],
          },
          autorizaRetiro: adultosAutorizados.map((adulto) => {
            console.log("Adulto:", adulto);
            return {
              id: adulto.id || null,
              run:
                typeof adulto.run === "string"
                  ? limpiarPuntosYGuiones(adulto.run?.slice(0, -1))
                  : null,
              runDv:
                typeof adulto.runDv === "string"
                  ? adulto.run?.slice(-1)
                  : null,
              runProvisorio:
                typeof adulto.runProvisorio === "string"
                  ? limpiarPuntosYGuiones(adulto.runProvisorio.slice(0, -1))
                  : null,
              runProvisorioDv:
                typeof adulto.runProvisorio === "string"
                  ? adulto.runProvisorio.slice(-1)
                  : null,
              nombreCompleto: adulto.nombreCompleto || null,
              parentesco: adulto.parentesco || null,
              fonoContacto: adulto.fonoContacto || null,
            };
          }),

          apoderados: Array.isArray(objeto.apoderados)
            ? objeto.apoderados.map((apoderado) => {
                // console.log("Apoderado:", apoderado.run, apoderado.runDv);
                const isValidRun = validarRUN(apoderado.run);
                // console.log("isValidRun:", isValidRun);
                return {
                  apoderadoId: apoderado.apoderadoId || null,
                  run: isValidRun
                    ? Number(apoderado.run.slice(0, -1))
                    : Number(apoderado.run),
                  runDv: apoderado.runDv || apoderado.run.slice(-1),
                  ipa: apoderado.ipa || null,
                  ipaDv: apoderado.ipaDv || null,
                  apellidoPaternoApo: apoderado.apellidoPaternoApo || null,
                  apellidoMaternoApo: apoderado.apellidoMaternoApo || null,
                  nombresApo: apoderado.nombresApo || null,
                  nombresSecundariosApo:
                    apoderado.nombresSecundariosApo || null,
                  telefonoMovil: apoderado.telefonoMovil || null,
                  emailApoderado: apoderado.emailApoderado || null,
                  direccion: apoderado.direccion || null,
                  vinculo: Number(apoderado.vinculo) || null,
                  tipoApoderado: Number(apoderado.tipoApoderado) || 10,
                  tutorLegal: apoderado.tutorLegal || false,
                };
              })
            : [],
        };
        return data;
      };

      const saveEstudianteData = (overrides = {}) => {
        return new Promise((resolve, reject) => {
          const valoresActualizados = { ...valoresEstudiante, ...overrides };
          const estudianteObject = {
            periodoId: 2024,
            estudiante: {
              estudianteId: valoresActualizados.estudianteId || null,
              run:
                removeDots(separarRun(valoresActualizados.run).run) !== ""
                  ? removeDots(separarRun(valoresActualizados.run).run)
                  : null,
              runDv: removeDots(separarRun(valoresActualizados.run).runDv),
              ipe: removeDots(
                separarRun(
                  formatearIPEIPA(
                    limpiarPuntosYGuiones(valoresActualizados.ipe)
                  )
                ).run
              ),
              ipeDv: separarRun(
                formatearIPEIPA(limpiarPuntosYGuiones(valoresActualizados.ipe))
              ).runDv,
              apellidoPaterno: valoresActualizados.apellidoPaterno || null,
              apellidoMaterno: valoresActualizados.apellidoMaterno || null,
              nombres: valoresActualizados.nombres || null,
              nombresSecundarios:
                valoresActualizados.nombresSecundarios || null,
              nombreSocial: valoresActualizados.nombreSocial || null,
              fechaNacimiento: valoresActualizados.fechaNacimiento
                ? valoresActualizados.fechaNacimiento
                    .toISOString()
                    .split("T")[0]
                : null,
              comunaNacimiento: valoresActualizados.comunaNacimiento || null,
              genero: valoresActualizados.genero || null,
              generoIdentidad: valoresActualizados.generoIdentidad || null,
              direccion: valoresActualizados.direccion || null,
              nacionalidad: valoresActualizados.nacionalidad || null,
              etnia: valoresActualizados.etnia || null,
              comuna: valoresActualizados.comuna || null,
              region: valoresActualizados.region || null,
              email: valoresActualizados.email || null,
              ensenanza: ensenanzaId,
              grado: valoresActualizados.grado || null,
              curso: valoresActualizados.curso || null,
              localEscolar: valoresActualizados.localEscolar
                ? String(valoresActualizados.localEscolar)
                : null,
              excedente: valoresActualizados.excedente || null,
              excedenteNumeroResolucion:
                valoresActualizados.numeroResolucion || null,
              excedenteFechaResolucion: valoresActualizados.fechaResolucion
                ? valoresActualizados.fechaResolucion
                    .toISOString()
                    .split("T")[0]
                : null,
              comentario: valoresActualizados.observaciones || null,
              estudianteNumMatricula:
                valoresActualizados.estudianteNumMatricula || null,
              estudianteFechaMatricula:
                valoresActualizados.estudianteFechaMatricula
                  ? valoresActualizados.estudianteFechaMatricula
                      .toISOString()
                      .split("T")[0]
                  : null,
              estudianteNumLista:
                valoresActualizados.estudianteNumLista || null,
              colegioProcedencia:
                valoresActualizados.colegioProcedencia || null,
              autorizaData: valoresActualizados.autorizaData,
              retiroFecha: valoresActualizados.retiroFecha
                ? valoresActualizados.retiroFecha.toISOString().split("T")[0]
                : null,
              motivoRetiro: valoresActualizados.motivoRetiro || null,
              viveCon: valoresActualizados.viveCon || [],
              esPie: valoresActualizados.esPie || null,
              esPrioritario: valoresActualizados.esPrioritario || null,
              esPreferente: valoresActualizados.esPreferente || null,
              nee: valoresActualizados.nee || null,
            },
          };

          if (
            apoderadoTitular.tutorLegal === "" ||
            apoderadoTitular.tutorLegal === null ||
            apoderadoTitular.tutorLegal === undefined ||
            apoderadoTitular.tutorLegal === false
          ) {
            setApoderadoTitular((prevValues) => ({
              ...prevValues,
              tutorLegal: true,
            }));
          }

          // Agregar apoderados si existen
          const apoderados = [apoderadoTitular, ...apoderadosSuplentes].filter(
            (apo) => apo.run || apo.ipaApoderado
          );

          if (apoderados.length > 0) {
            estudianteObject.apoderados = apoderados.map((apo) => ({
              apoderadoId: apo.apoderadoId || null,
              run: removeDots(separarRun(apo.run).run),
              runDv: separarRun(apo.run).runDv,
              apellidoPaternoApo: apo.apellidoPaternoApo || null,
              apellidoMaternoApo: apo.apellidoMaternoApo || null,
              nombresApo: apo.nombresApo || null,
              nombresSecundariosApo: apo.nombresSecundariosApo || null,
              vinculo: apo.vinculo || null,
              telefonoMovil: apo.telefonoMovil || null,
              emailApoderado: apo.emailApoderado || null,
              tutorLegal:
                apoderados.length === 1 ? true : apo.tutorLegal || null,
              ipa: removeDots(
                separarRun(
                  formatearIPEIPA(limpiarPuntosYGuiones(apo.ipaApoderado))
                ).run
              ),
              ipaDv: separarRun(
                formatearIPEIPA(limpiarPuntosYGuiones(apo.ipaApoderado))
              ).runDv,
            }));
          }

          ajustarTutorLegal(estudianteObject);

          // console.log("Apoderados:", estudianteObject.apoderados);
          if (errors && errors.run) {
            Swal.fire({
              icon: "error",
              title: "Campo requerido",
              text: "El campo RUN es requerido. Por favor, ingrese un valor válido.",
              confirmButtonColor: "var(--color-acento)",
            });
            return;
          }
          console.log(estudianteObject, autorizadosRetirar);
          const student = transformarEstructura(
            estudianteObject,
            autorizadosRetirar
          );
          console.log("Student: ", student);

          console.log("Errors:", errors);
          setIsReadyToUploadAvatar(true);
          saveFichaEstudiante(student)
            .then((response) => {
              if (response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "Estudiante guardado",
                  text: "Los datos del estudiante han sido guardados exitosamente.",
                  confirmButtonColor: "var(--color-acento)",
                }).then(() => {
                  navigate("/matricula-vista");
                  resolve(response);
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error al matricular al estudiante",
                  text: "Ha ocurrido un error al intentar matricular al estudiante.",
                  confirmButtonColor: "var(--color-acento)",
                });
                reject(new Error("Error al matricular"));
              }
            })
            .catch((error) => {
              //  console.log(error.response.data.status);
              if (
                error.response.data.status === 400 &&
                error.response.data.message.includes(
                  "El RUN ingresado ya se encuentra registrado por otro estudiante"
                )
              ) {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "El RUN ingresado ya se encuentra registrado por otro estudiante.",
                  confirmButtonColor: "var(--color-acento)",
                });
              }

              if (
                error.response.data.status === 400 &&
                error.response.data.message.includes(
                  "El vínculo del apoderado titular es obligatorio."
                )
              ) {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "El vínculo del apoderado titular es obligatorio.",
                  confirmButtonColor: "var(--color-acento)",
                });
              }

              if (
                error.response.data.status === 400 &&
                error.response.data.message.includes(
                  "El RUN del apoderado suplente es obligatorio."
                )
              ) {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "El RUN del apoderado suplente es obligatorio.",
                  confirmButtonColor: "var(--color-acento)",
                });
              }
              if (
                error.response.data.status === 400 &&
                error.response.data.message.includes(
                  "El vínculo del apoderado suplente es obligatorio."
                )
              ) {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "El vínculo del apoderado suplente es obligatorio.",
                  confirmButtonColor: "var(--color-acento)",
                });
              }
              reject(error);
            })
            .finally(() => {
              setIsReadyToUploadAvatar(false);
            });
        });
      };

      const validarAutorizacionDatos = () => {
        if (
          !valoresEstudiante.autorizaData ||
          valoresEstudiante.autorizaData === 3
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            autorizaData: "Debe autorizar el guardado de datos para continuar.",
          }));
          return false;
        } else {
          setErrors((prevErrors) => {
            const { autorizaData, ...rest } = prevErrors;
            return rest; // Limpia el error si la selección es válida
          });
          return true;
        }
      };

      const handleSaveDataWithRetry = async (
        overrides = { autorizaData: valoresEstudiante.autorizaData },
        retryCount = 0
      ) => {
        try {
          const autorizacionValida = validarAutorizacionDatos(); // Valida la autorización de datos

          if (!autorizacionValida) {
            console.log("Errors:", errors); // Aquí debería reflejar el error de autorización
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Debe autorizar el guardado de datos para continuar.",
              confirmButtonColor: "var(--color-acento)",
            });
            return;
          }

          await saveEstudianteData(overrides);
        } catch (error) {
          if (retryCount >= 3) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "No se pudo completar la operación después de varios intentos.",
              confirmButtonColor: "var(--color-acento)",
            });
            return;
          }
          console.log("Error:", error);
          if (error.response && error.response.status === 400) {
            const errorMessage = error.response.data.message;

            let lastAvailableMatricula = null;
            let lastAvailableLista = null;

            // Primero manejar el error de número de matrícula
            if (errorMessage.includes("número de matrícula")) {
              lastAvailableMatricula = detectarNumeroAlFinal(errorMessage);
              const matriculaResult = await Swal.fire({
                icon: "error",
                title: "Número de matrícula no disponible",
                text: `El número de matrícula ingresado ya está en uso. ¿Desea asignar el siguiente número disponible? (${lastAvailableMatricula})`,
                showCancelButton: true,
                confirmButtonText: `Asignar ${lastAvailableMatricula}`,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "var(--color-acento)",
                cancelButtonColor: "var(--color-eliminar)",
              });

              if (matriculaResult.isConfirmed) {
                overrides.estudianteNumMatricula = lastAvailableMatricula;
                await handleSaveDataWithRetry(overrides, retryCount + 1);
                return;
              } else {
                console.log("Matrícula cancelada");
                return;
              }
            }

            // Luego manejar el error de número de lista
            if (errorMessage.includes("número de lista")) {
              lastAvailableLista = detectarNumeroAlFinal(errorMessage);
              const listaResult = await Swal.fire({
                icon: "error",
                title: "Número de lista no disponible",
                text: `El número de lista ingresado ya está en uso. ¿Desea asignar el siguiente número disponible? (${lastAvailableLista})`,
                showCancelButton: true,
                confirmButtonText: `Asignar ${lastAvailableLista}`,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "var(--color-acento)",
                cancelButtonColor: "var(--color-eliminar)",
              });

              if (listaResult.isConfirmed) {
                overrides.estudianteNumLista = lastAvailableLista;
                await handleSaveDataWithRetry(overrides, retryCount + 1);
              } else {
                console.log("Asignación de lista cancelada");
              }
            }

            if (
              errorMessage.includes("Duplicate entry") &&
              errorMessage.includes("for key 'user_name'")
            ) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "El RUN ingresado ya se encuentra registrado por otro estudiante.",
                confirmButtonColor: "var(--color-acento)",
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Ha ocurrido un error inesperado.",
              confirmButtonColor: "var(--color-acento)",
            });
          }
        }
      };
      handleSaveDataWithRetry(overrides);
    },
    [
      apoderadoTitular,
      apoderadosSuplentes,
      autorizadosRetirar,
      ensenanzaId,
      errors,
      navigate,
      valoresEstudiante,
    ]
  );

  useEffect(() => {
    if (!valoresEstudiante.nacionalidad) {
      setValoresEstudiante((prevValues) => ({
        ...prevValues,
        nacionalidad: listaNacionalidades[0]?.id,
      }));
    }
  }, [listaNacionalidades, valoresEstudiante.nacionalidad]);

  useEffect(() => {
    const cargarListas = async () => {
      try {
        const [
          nacionalidades,
          etnias,
          identidadesGenero,
          generosRegCivil,
          vinculos,
          regiones,
          comunas,
          localesEscolares,
        ] = await Promise.all([
          cargarListaNacionalidades(),
          cargarListaEtnias(),
          cargarListaIdentidadGenero(),
          cargarListaGenerosRegCivil(),
          cargarListaVinculosParentescos(),
          cargarListaRegiones(),
          cargarListaComunas(),
          cargarListaLocalEscolar(),
        ]);

        const mappedNacionalidades = nacionalidades.map((nacionalidad) => ({
          id: nacionalidad.id,
          name: nacionalidad.paisNacionalidad,
          areaCode: nacionalidad.paisCodigoTelefono,
          alpha2Code: nacionalidad.paisCodigoAlpha2,
          countryName: nacionalidad.paisNombre,
        }));

        setListaNacionalidades(mappedNacionalidades);
        setListaEtnias(
          etnias.map((etnia) => {
            return { id: etnia.id, name: etnia.pueblosOriginariosNombre };
          })
        );
        setListaIdentidadesGenero(
          identidadesGenero.map((identidad) => {
            return { id: identidad.id, name: identidad.generoIdentidadNombre };
          })
        );
        setListaGenerosRegCivil(
          generosRegCivil.map((genero) => {
            return { id: genero.id, name: genero.generoNombre };
          })
        );
        setListaVinculos(
          vinculos.map((vinculo) => {
            return { id: vinculo.id, name: vinculo.nombre };
          })
        );
        // console.log("regiones", regiones);
        setListaRegiones(
          regiones.map((region) => ({
            id: region.id,
            name: region.regionNombre,
          }))
        );
        setListaComunas(
          comunas.map((comuna) => ({
            id: comuna.id,
            name: comuna.comunaNombre,
            regionId: comuna.region.id,
          }))
        );
        // console.log(listaLocalEscolar)
        setListaLocalEscolar(
          localesEscolares.map((localEscolar) => ({
            id: localEscolar.id,
            name: localEscolar.sedeNombre,
          }))
        );
      } catch (error) {
        console.error("Error al cargar las listas:", error);
      }
    };

    cargarListas();
  }, [valoresEstudiante.run]);

  useEffect(() => {
    getListadoGrados()
      .then((response) => {
        // console.log("Grados:", response.data.curriculumGrados);
        if (
          response.status === 200 &&
          response.data.curriculumGrados.length > 0
        ) {
          dispatch(setGrados(response.data.curriculumGrados));
          setListaGrados(response.data.curriculumGrados);
        } else {
          setListaGrados([]);
        }
      })
      .catch((error) => {
        console.error("Error al obtener los grados:", error);
        setListaGrados([]);
      });
  }, [dispatch, valoresEstudiante.curso]);

  // Manejo de datos cuando currentSchool está disponible

  useEffect(() => {
    if (valoresApoderado.length > 0) {
      // console.log("ValoresApoderado: ", valoresApoderado);

      setApoderadosSuplentes(
        valoresApoderado
          .filter((apoderado) => {
            return apoderado.tutorLegal !== true;
          })
          .map((apoderado) => {
            return {
              run: apoderado.run ? `${apoderado.run}` : "",
              ipaApoderado: apoderado.ipa ? `${apoderado.ipa}` : "",
              apellidoPaternoApo: apoderado.apellidoPaternoApo
                ? apoderado.apellidoPaternoApo
                : "",
              apellidoMaternoApo: apoderado.apellidoMaternoApo
                ? apoderado.apellidoMaternoApo
                : "",
              nombresApo: apoderado.nombresApo ? apoderado.nombresApo : "",
              nombresSecundariosApo: apoderado.nombresSecundariosApo
                ? apoderado.nombresSecundariosApo
                : "",
              vinculo: apoderado.vinculo ? apoderado.vinculo : "",
              telefonoMovil: apoderado.telefonoMovil
                ? apoderado.telefonoMovil
                : "",
              direccion: apoderado.direccion ? apoderado.direccion : "",
              tutorLegal: false,
              emailApoderado: apoderado.emailApoderado
                ? apoderado.emailApoderado
                : "",
              apoderadoId: apoderado.apoderadoId ? apoderado.apoderadoId : "",
            };
          })
      );

      setApoderadoTitular(
        valoresApoderado
          .filter((apoderado) => apoderado.tutorLegal === true)
          .map((apoderado) => {
            return {
              run: apoderado.run ? `${apoderado.run}` : "",
              ipaApoderado: apoderado.ipa ? `${apoderado.ipa}` : "",
              apellidoPaternoApo: apoderado.apellidoPaternoApo
                ? apoderado.apellidoPaternoApo
                : "",
              apellidoMaternoApo: apoderado.apellidoMaternoApo
                ? apoderado.apellidoMaternoApo
                : "",
              nombresApo: apoderado.nombresApo ? apoderado.nombresApo : "",
              nombresSecundariosApo: apoderado.nombresSecundariosApo
                ? apoderado.nombresSecundariosApo
                : "",
              vinculo: apoderado.vinculo ? apoderado.vinculo : "",
              telefonoMovil: apoderado.telefonoMovil
                ? apoderado.telefonoMovil
                : "",
              direccion: apoderado.direccion ? apoderado.direccion : "",
              tutorLegal: true,
              emailApoderado: apoderado.emailApoderado
                ? apoderado.emailApoderado
                : "",
              apoderadoId: apoderado.apoderadoId ? apoderado.apoderadoId : "",
            };
          })[0] || {}
      );
    }
  }, [listaNacionalidades, valoresApoderado]);

  useEffect(() => {
    // console.log("Estudiante:", valoresEstudiante);
    if (valoresEstudiante.grado !== "") {
      setListaCursos(
        cursos.filter((curso) => curso.gradoIid === valoresEstudiante.grado)
      );
    }
    // console.log("Apoderados suplentes :", apoderadosSuplentes);
  }, [valoresEstudiante.grado, cursos]);

  useEffect(() => {
    if (retryData) {
      handleSaveData(retryData);
      setRetryData(null); // Limpiar el estado para evitar un loop infinito
    }
  }, [handleSaveData, retryData]);

  useEffect(() => {
    const validateRequiredFields = () => {
      const validationErrors = {};

      // Campos requeridos generales
      const requiredFields = {
        // apellidoPaterno: "Apellido Paterno es requerido.",
        // nombres: "Nombre Primario es requerido.",
        // fechaNacimiento: "Fecha de Nacimiento es requerida.",
        // genero: "Género es requerido.",
        // region: "Región es requerida.",
        // comuna: "Comuna es requerida.",
        // direccion: "Dirección es requerida.",
        // curso: "Curso es requerido.",
      };

      // Validar campos requeridos generales
      Object.entries(requiredFields).forEach(([field, errorMessage]) => {
        if (!valoresEstudiante[field]) {
          validationErrors[field] = errorMessage;
        }
      });

      // Validar RUN e IPE del estudiante
      if (!valoresEstudiante.run && !valoresEstudiante.ipe) {
        validationErrors.run = "RUN o IPE es requerido.";
      } else if (valoresEstudiante.run && !validarRUN(valoresEstudiante.run)) {
        validationErrors.run = "RUN inválido.";
      }

      if (!valoresEstudiante.run && valoresEstudiante.ipe) {
        if (!validarIPE(valoresEstudiante.ipe)) {
          validationErrors.ipe = "IPE inválido.";
        }
      }

      // Validar RUN e IPE del apoderado titular
      // if (
      //   (apoderadoTitular.run || apoderadoTitular.ipaApoderado) &&
      //   !apoderadoTitular.run &&
      //   !apoderadoTitular.ipaApoderado
      // ) {
      //   validationErrors.runApoTitular =
      //     "RUN o IPA del Apoderado Titular es requerido.";
      // } else if (apoderadoTitular.run && !validarRUN(apoderadoTitular.run)) {
      //   validationErrors.runApoTitular =
      //     "RUN del Apoderado Titular es inválido.";
      // } else if (
      //   apoderadoTitular.ipaApoderado &&
      //   !validarRUN(apoderadoTitular.ipaApoderado)
      // ) {
      //   validationErrors.ipaApoderado =
      //     "IPA del Apoderado Titular es inválido.";
      // }

      // if (
      //   apoderadoTitular.apellidoPaternoApo &&
      //   !apoderadoTitular.run &&
      //   !apoderadoTitular.ipaApoderado
      // ) {
      //   validationErrors.runApoTitular =
      //     "Debe ingresar RUN o IPA del Apoderado Titular.";
      // }

      // if (
      //   apoderadoTitular.nombresApo &&
      //   !apoderadoTitular.run &&
      //   !apoderadoTitular.ipaApoderado
      // ) {
      //   validationErrors.nombresApo =
      //     "Nombre Primario del Apoderado Titular es requerido.";
      // }

      // if (
      //   apoderadoTitular.vinculo &&
      //   !apoderadoTitular.run &&
      //   !apoderadoTitular.ipaApoderado
      // ) {
      //   validationErrors.vinculo =
      //     "Vínculo del Apoderado Titular es requerido.";
      // }

      // if (
      //   (apoderadoTitular.apellidoPaternoApo ||
      //     apoderadoTitular.nombresApo ||
      //     apoderadoTitular.vinculo) &&
      //   !apoderadoTitular.run &&
      //   !apoderadoTitular.ipaApoderado
      // ) {
      //   validationErrors.runApoTitular =
      //     "Debe ingresar RUN o IPA del Apoderado Titular.";
      // }

      // apoderadosSuplentes.forEach((apoderado, index) => {
      //   if (!apoderado.vinculo && (apoderado.run || apoderado.ipaApoderado)) {
      //     validationErrors[`runApoderadoSuplente${index}`] =
      //       "Vínculo del Apoderado Suplente es requerido.";
      //   }
      // });

      // Validar fecha de matrícula solo si número de matrícula no es una cadena vacía
      // if (
      //   valoresEstudiante.estudianteNumMatricula &&
      //   !valoresEstudiante.estudianteFechaMatricula
      // ) {
      //   validationErrors.fechaMatricula =
      //     "Fecha de Matrícula es requerida si el número de matrícula está presente.";
      // }

      // if (valoresEstudiante.retiroFecha && !valoresEstudiante.motivoRetiro) {
      //   validationErrors.motivoRetiro =
      //     "Motivo de Retiro es requerido si hay una fecha de retiro.";
      // }

      // if (valoresEstudiante.excedente && !valoresEstudiante.numeroResolucion) {
      //   validationErrors.resolucionExcedente =
      //     "Número de Resolución son requeridos si Estudiante excedente está activo.";
      // }
      // if (valoresEstudiante.excedente && !valoresEstudiante.fechaResolucion) {
      //   validationErrors.fechaResolucion =
      //     "Fecha de Resolución son requeridos si Estudiante excedente está activo.";
      // }

      setErrors(validationErrors);
    };

    validateRequiredFields();
  }, [valoresEstudiante, apoderadoTitular, apoderadosSuplentes]);

  ///// Carga de Ficha desde la base de datos

  useEffect(() => {
    if (ficha && !isFichaCargada) {
      console.log("Ficha from DB: ", ficha);

      // Inicializa los valores del estudiante
      setValoresEstudiante({
        estudianteId: ficha.estudiante.estudianteId || null,
        run: ficha.estudiante.run
          ? ficha.estudiante.run + "-" + ficha.estudiante.runDv
          : "",
        ipe: ficha.estudiante.ipe
          ? ficha.estudiante.ipe + "-" + ficha.estudiante.ipeDv
          : "",
        apellidoPaterno: ficha.estudiante.apellidoPaterno || "",
        apellidoMaterno: ficha.estudiante.apellidoMaterno || "",
        nombres: ficha.estudiante.nombres || "",
        nombresSecundarios: ficha.estudiante.nombresSecundarios || "",
        nombreSocial: ficha.estudiante.nombreSocial || "",
        fechaNacimiento: ficha.estudiante.fechaNacimiento
          ? parseISO(ficha.estudiante.fechaNacimiento)
          : null,
        genero: ficha.estudiante.genero || "",
        direccion: ficha.estudiante.direccion || "",
        curso: ficha.estudiante.curso || "",
        grado: ficha.estudiante.grado || "",
        nacionalidad: ficha.estudiante.nacionalidad || "",
        etnia: ficha.estudiante.etnia || "",
        viveCon: ficha.estudiante.viveCon || [],
        region: ficha.estudiante.region || "",
        comuna: ficha.estudiante.comuna,
        comunaNacimiento: ficha.estudiante.comunaNacimiento || "",
        colegioProcedencia: ficha.estudiante.colegioProcedencia || "",
        autorizaData: ficha.estudiante.autorizaData,
        localEscolar: parseInt(ficha.estudiante.localEscolar) || "",
        excedente: ficha.estudiante.excedente || false,
        numeroResolucion: ficha.estudiante.excedenteNumeroResolucion || "",
        fechaResolucion: ficha.estudiante.excedenteFechaResolucion
          ? parseISO(ficha.estudiante.excedenteFechaResolucion)
          : null,
        observaciones: ficha.estudiante.comentario || "",
        motivoRetiro: ficha.estudiante.motivoRetiro || "",
        retiroFecha: ficha.estudiante.retiroFecha
          ? parseISO(ficha.estudiante.retiroFecha)
          : null,
        generoIdentidad: ficha.estudiante.generoIdentidad || "",
        email: ficha.estudiante.email || "",
        estudianteNumLista: ficha.estudiante.estudianteNumLista || "",
        estudianteFechaMatricula: ficha.estudiante.estudianteFechaMatricula
          ? parseISO(ficha.estudiante.estudianteFechaMatricula)
          : null,
        estudianteNumMatricula: ficha.estudiante.estudianteNumMatricula || "",
        esPie: ficha.estudiante.esPie || false,
        esPrioritario: ficha.estudiante.esPrioritario || false,
        esPreferente: ficha.estudiante.esPreferente || false,
        nee: ficha.estudiante.nee,
        // autorizaData: ficha.estudiante.autorizaData,
      });
      setSelectedRegion(ficha.estudiante.region);
      setSelectedComuna(ficha.estudiante.comuna);

      // Inicializa los valores de los apoderados
      // console.log("Apoderados from DB: ", ficha.apoderados[0].runDv);
      setValoresApoderado(
        ficha.apoderados.map((apoderado) => {
          // console.log(apoderado.run + apoderado.runDv);
          // console.log(apoderado.ipa + apoderado.ipaDv);
          return {
            apoderadoId: apoderado.apoderadoId || null,
            run: apoderado.run
              ? `${apoderado.run ?? ""}${apoderado.runDv ?? ""}`
              : "",

            ipa: apoderado.ipa
              ? `${apoderado.ipa ?? ""}${apoderado.ipaDv ?? ""}`
              : "",
            apellidoPaternoApo: apoderado.apellidoPaternoApo || "",
            apellidoMaternoApo: apoderado.apellidoMaternoApo || "",
            nombresApo: apoderado.nombresApo || "",
            nombresSecundariosApo: apoderado.nombresSecundariosApo || "",
            vinculo: apoderado.vinculo || "",
            telefonoMovil: apoderado.telefonoMovil || "",
            direccion: apoderado.direccion || "",
            tutorLegal: apoderado.tutorLegal || false,
            emailApoderado: apoderado.emailApoderado || "",
          };
        })
      );

      setAutorizadosRetirar(
        ficha.autorizaRetiro.map((autorizado) => {
          return {
            id: autorizado.id,
            run: formatearRUN(autorizado.run + autorizado.runDv),
            runDv: autorizado.runDv,
            runProvisorio: formatearIPEIPA(
              autorizado.runProvisorio + autorizado.runProvisorioDv
            ),
            runProvisorioDv: autorizado.runProvisorioDv,
            nombreCompleto: autorizado.nombreCompleto,
            parentesco: autorizado.parentesco,
            fonoContacto: autorizado.fonoContacto,
          };
        }) || []
      );

      setIsFichaCargada(true); // Marca que la ficha ya ha sido cargada
    } else if (!ficha && !isFichaCargada) {
      // setea local escolar si no está disponible y la ficha está en blanco
      if (!valoresEstudiante.localEscolar) {
        setValoresEstudiante((prevValues) => ({
          ...prevValues,
          localEscolar: 1,
        }));
      }
      // setea region y comuna si currentSchoolState está disponible y la ficha está en blanco
      if (
        currentSchoolState &&
        valoresEstudiante.region === "" &&
        valoresEstudiante.comuna === ""
      ) {
        setValoresEstudiante((prevValues) => ({
          ...prevValues,
          region: currentSchoolState.region?.id,
          comuna: currentSchoolState.comuna?.id,
        }));
      }

      console.log(valoresEstudiante.autorizaData);

      if (valoresEstudiante.autorizaData) {
        setValoresEstudiante((prevValues) => ({
          ...prevValues,
          autorizaData: 1,
        }));
      }
      // console.log("Ficha en blanco");

      // Obtiene el número de matrícula si no está disponible y la ficha está en blanco
      getNextNumMatricula()
        .then((response) => {
          // setValoresEstudiante((prevValues) => ({
          //   ...prevValues,
          //   estudianteNumMatricula: response?.data,
          // }));
          setLastAvailableMatricula(response.data);
          setValoresEstudiante((prevValues) => ({
            ...prevValues,
            estudianteFechaMatricula: new Date(),
          }));
        })
        .catch((error) => {
          console.error("Error al obtener el número de matrícula:", error);
        });
    }
  }, [
    currentSchoolState,
    ficha,
    isFichaCargada,
    listaComunas,
    listaLocalEscolar,
    listaRegiones,
    valoresEstudiante.autorizaData,
    valoresEstudiante.comuna,
    valoresEstudiante.localEscolar,
    valoresEstudiante.region,
  ]);

  useEffect(() => {
    if (selectedComuna) {
      // console.log(selectedComuna);
      setValoresEstudiante((prevValues) => ({
        ...prevValues,
        comuna: selectedComuna,
      }));
    }
  }, [selectedComuna]);

  // useEffect(() => {
  //   console.log("autorizados:", autorizadosRetirar)
  // },[autorizadosRetirar])

  return (
    <Container fluid className="px-4" style={estilos.container}>
      <Tab.Container
        activeKey={tabActiva}
        onSelect={(k) => tabsHabilitadas.includes(k) && setPestañaActiva(k)}
      >
        <span style={{ position: "absolute" }}>
          <Title text="Ficha Estudiante" />
        </span>

        <Nav variant="tabs" className="mb-3 custom-tabs justify-content-center">
          {listaPestanias
            .filter((tab) => tab.isSelected)
            .map((tab, index) => (
              <Nav.Item
                key={tab.eventKey + "-" + index}
                style={{ width: "150px", height: "100%" }}
              >
                <Nav.Link
                  eventKey={tab.eventKey}
                  style={{ textOverflow: "ellipsis", textWrap: "nowrap" }}
                >
                  {tab.name}{" "}
                </Nav.Link>
              </Nav.Item>
            ))}

          <Col
            md={2}
            className="d-flex align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <h5>Período matrícula {periodoActual}</h5>
          </Col>
        </Nav>
        <Tab.Content>
          {listaPestanias
            .filter((tab) => tab.isSelected) // Filtrar solo las pestañas seleccionadas
            .map((tab) => (
              <Tab.Pane eventKey={tab.eventKey} key={tab.eventKey}>
                <tab.component
                  isReadyToUploadAvatar={isReadyToUploadAvatar}
                  valoresEstudiante={valoresEstudiante}
                  setValoresEstudiante={setValoresEstudiante}
                  errors={errors}
                  setErrors={setErrors}
                  listaRegiones={listaRegiones}
                  listaComunas={listaComunas}
                  listaNacionalidades={listaNacionalidades}
                  listaVinculos={listaVinculos}
                  listaLocalEscolar={listaLocalEscolar}
                  listaGrados={listaGrados}
                  listaCursos={cursos}
                  listaGenerosRegCivil={listaGenerosRegCivil}
                  listaIdentidadesGenero={listaIdentidadesGenero}
                  listaEtnias={listaEtnias}
                  manejarCambioFecha={manejarCambioFecha}
                  manejarCambioTexto={manejarCambioTexto}
                  apoderadoTitular={apoderadoTitular}
                  setApoderadoTitular={setApoderadoTitular}
                  apoderadosSuplentes={apoderadosSuplentes}
                  setApoderadosSuplentes={setApoderadosSuplentes}
                  lastAvailableMatricula={lastAvailableMatricula}
                  autorizadosRetirar={autorizadosRetirar}
                  setAutorizadosRetirar={setAutorizadosRetirar}
                />
              </Tab.Pane>
            ))}
        </Tab.Content>
      </Tab.Container>
      <Row className="mt-auto w-100 pb-3">
        <Col className="d-flex justify-content-end align-items-center gap-3">
          <CustomTooltip tooltipMsg={"Ir a la lista"}>
            <Button
              variant="color-gray-outline"
              onClick={() => navigate("/matricula-vista")}
            >
              Ir Lista
            </Button>
          </CustomTooltip>
          {isDirectivo && (
            <CustomTooltip
              tooltipMsg={!isDirectivo ? "No tienes permisos" : "Guardar"}
            >
              <div style={{ display: "block", alignItems: "center" }}>
                <Button
                  variant="color-acento"
                  onClick={handleSaveData}
                  // disabled={!isDirectivo}
                >
                  Guardar
                </Button>
              </div>
            </CustomTooltip>
          )}
        </Col>
      </Row>
      <Modal show={showSuccessModal} onHide={handleSuccessModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Matrícula Exitosa</Modal.Title>
        </Modal.Header>
        <Modal.Body>Estudiante guardado con éxito.</Modal.Body>
        <Modal.Footer>
          <Button variant="color-acento" onClick={handleSuccessModalClose}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const estilos = {
  container: {
    backgroundColor: "#FDFFFF",
    height: "90dvh",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  titulo: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "34px",
    color: "var(--color-texto-oscuro)",
    marginBottom: 0,
  },
  asterisco: {
    color: "var(--color-rojo)",
  },
  anio: {
    color: "var(--color-apoyo)",
    fontWeight: "300",
  },
};

export default FichaEstudiante;
