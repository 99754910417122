import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import esEs from 'antd/es/locale/es_ES';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import ThemeContextProvider from "./context/ThemeContext"; // Cambia a ThemeContextProvider



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter basename='/'>
            <ThemeContextProvider> 
                <ConfigProvider locale={esEs}>
                    <App/>
                </ConfigProvider>
            </ThemeContextProvider>
        </BrowserRouter>
    </Provider>
);

reportWebVitals();
