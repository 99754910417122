import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
/// Css
import "./../index.css";
import "./../step.css";

/// Layout
import Nav from "./../layouts/nav";

import { ThemeContext } from "../../context/ThemeContext";
//Scroll To Top
import ScrollToTop from "./../layouts/ScrollToTop";

/// Dashboard
import Home from "./../pages/dashboard/Home";

//holidays
import AddHoliday from "./../pages/holidays/AddHoliday";
import AllHoliday from "./../pages/holidays/AllHoliday";
import EditHoliday from "./../pages/holidays/EditHoliday";
import HolidayEvent from "./../pages/holidays/HolidayEvent";

/// Bootstrap
import UiAccordion from "./../pages/bootstrap/Accordion";
import UiAlert from "./../pages/bootstrap/Alert";
import UiBadge from "./../pages/bootstrap/Badge";
import UiButton from "./../pages/bootstrap/Button";
import UiButtonGroup from "./../pages/bootstrap/ButtonGroup";
import UiCards from "./../pages/bootstrap/Cards";
import UiCarousel from "./../pages/bootstrap/Carousel";
import UiDropDown from "./../pages/bootstrap/DropDown";
import UiGrid from "./../pages/bootstrap/Grid";
import UiListGroup from "./../pages/bootstrap/ListGroup";
import MediaObject from "./../pages/bootstrap/MediaObject";
import UiModal from "./../pages/bootstrap/Modal";
import UiPagination from "./../pages/bootstrap/Pagination";
import UiPopOver from "./../pages/bootstrap/PopOver";
import UiProgressBar from "./../pages/bootstrap/ProgressBar";
import UiTab from "./../pages/bootstrap/Tab";
import UiTypography from "./../pages/bootstrap/Typography";

/// Plugins
import Lightgallery from "./../pages/plugins/Lightgallery";
import Select2 from "./../pages/plugins/Select2/Select2";
import MainSweetAlert from "./../pages/plugins/SweetAlert";
import Toastr from "./../pages/plugins/Toastr";

/// Table
import BootstrapTable from "./../pages/table/BootstrapTable";
import DataTable from "./../pages/table/DataTable";
import FilteringTable from "./../pages/table/FilteringTable/FilteringTable";
import SortingTable from "./../pages/table/SortingTable/SortingTable";

/// Pages
import Error400 from "./../pages/error/Error400";
import Error403 from "./../pages/error/Error403";
import Error404 from "./../pages/error/Error404";
import Error500 from "./../pages/error/Error500";
import Error503 from "./../pages/error/Error503";
import LockScreen from "./../pages/error/LockScreen";

// ColegioRol
import ColegioRol from "../pages/colegiorol/ColegioRol";

import HorarioVista from "../pages/horario/HorarioVista";

import AsistenciaDiariaAsignaturaProfe from "../pages/asistenciaBloqueDiaria/AsistenciaDiariaAsignaturaProfe";
import AsistenciaMensualAsignaturaProfeCopia from "../pages/asistenciaBloqueMensual/AsistenciaMensualAsignaturaProfeCopia";

import AsistenciaMensualSubvencionCursos from "../pages/asistenciaMensualSubvencionCursos/AsistenciaMensualSubvencionCursos";
import AsistenciaMensualSubvencionEstudiantes from "../pages/asistenciaMensualSubvencionEstudiantes/AsistenciaMensualSubvencionEstudiantes";

import PorcentajeAsistenciaAnualPorCurso from "../pages/porcentajeAsistenciaAnualPorCurso/PorcentajeAsistenciaAnualPorCurso";
import PorcentajeAsistenciaAnualPorEstudiante from "../pages/porcentajeAsistenciaAnualPorEstudiante/PorcentajeAsistenciaAnualPorEstudiante";

import CalificacionesCurso from "../pages/calificaciones/CalificacionesCurso";
import InformeCalificacionesEstudiante from "../pages/informeCalificacionEstudianteCurso/InformeCalificacionesEstudiante";
import InformeCalificacionEstudianteCurso from "../pages/informeCalificacionEstudianteCurso/InformeCalificacionEstudianteCurso";

import ColegioAdmin from "../pages/colegio-admin/ColegioAdmin";
import MatriculaSige from "../pages/colegio-admin/MatriculaSige";
import RegistroLog from "../pages/colegio-admin/RegistroLog";
import Profile from "../pages/profile/Profile";

import FichaEstudiante from "../pages/matricula/FichaEstudiante/FichaEstudiante";
import MatriculaVista from "../pages/matricula/listaMatricula/MatriculaVista";

import ImportarCalificaciones from "../pages/colegio-admin/ImportarCalificaciones";

import FichaAsignaturas from "../pages/asignaturas/FichaAsignaturas/FichaAsignaturas";
import FichaFuncionario from "../pages/funcionarios/fichaFuncionario/FichaFuncionario";
import ListaFuncionarios from "../pages/funcionarios/listaFuncionarios/ListaFuncionarios";

import ListaAsignaturas from "../pages/asignaturas/ListaAsignaturas/ListaAsignaturas";
import ConfigHorarioCurso from "../pages/configHorarioCurso/ConfigHorarioCurso";
import FichaCurso from "../pages/Curso/FichaCurso";
import ListadoCursos from "../pages/Curso/listaCursos/ListadoCursos";

// ROLES
// Modificar la función para aceptar un array de roles
const validaComponent = (component, rolIds) => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const hasRole = user.user.roles.some((r) => rolIds.includes(r.rol.id));

  if (hasRole) {
    return component;
  } else {
    return <Error403 />;
  }
};

const Markup = () => {
  const allroutes = [
    /// Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },

    //Holiday
    { url: "all-holiday", component: <AllHoliday /> },
    { url: "add-holiday", component: <AddHoliday /> },
    { url: "edit-holiday", component: <EditHoliday /> },
    { url: "holiday-calendar", component: <HolidayEvent /> },

    /// Bootstrap
    { url: "ui-accordion", component: <UiAccordion /> },
    { url: "ui-alert", component: <UiAlert /> },
    { url: "ui-badge", component: <UiBadge /> },
    { url: "ui-button", component: <UiButton /> },
    { url: "ui-modal", component: <UiModal /> },
    { url: "ui-button-group", component: <UiButtonGroup /> },
    { url: "ui-list-group", component: <UiListGroup /> },
    { url: "ui-media-object", component: <MediaObject /> },
    { url: "ui-card", component: <UiCards /> },
    { url: "ui-carousel", component: <UiCarousel /> },
    { url: "ui-dropdown", component: <UiDropDown /> },
    { url: "ui-popover", component: <UiPopOver /> },
    { url: "ui-progressbar", component: <UiProgressBar /> },
    { url: "ui-tab", component: <UiTab /> },
    { url: "ui-pagination", component: <UiPagination /> },
    { url: "ui-typography", component: <UiTypography /> },
    { url: "ui-grid", component: <UiGrid /> },

    /// Plugin

    { url: "uc-select2", component: <Select2 /> },
    { url: "uc-sweetalert", component: <MainSweetAlert /> },
    { url: "uc-toastr", component: <Toastr /> },
    // { url: "map-jqvmap", component: <JqvMap /> },
    { url: "uc-lightgallery", component: <Lightgallery /> },

    /// table
    { url: "table-datatable-basic", component: <DataTable /> },
    { url: "table-filtering", component: <FilteringTable /> },
    { url: "table-sorting", component: <SortingTable /> },
    { url: "table-bootstrap-basic", component: <BootstrapTable /> },

    /// pages
    // { url: 'todo', component: <Todo/> },

    // New ColegioRol Route
    { url: "colegio-rol", component: <ColegioRol /> },

    // New Asistencia por asignatura diaria profesor
    {
      url: "asistencia-diaria-asignatura-profesor",
      component: <AsistenciaDiariaAsignaturaProfe />,
    },
    {
      url: "asistencia-mes-asignatura-profesor",
      component: <AsistenciaMensualAsignaturaProfeCopia />,
    },

    { url: "profile", component: <Profile /> },
    { url: "colegio-admin", component: validaComponent(<ColegioAdmin />, [5]) },
    {
      url: "matricula-sige",
      component: validaComponent(<MatriculaSige key="noassign" />, [5]),
    },
    {
      url: "nro-matricula",
      component: validaComponent(<MatriculaSige key="assign" assign={true} />, [
        5,
      ]),
    },
    { url: "registro-log", component: validaComponent(<RegistroLog />, [5]) },

    { url: "listado-cursos", component: (<ListadoCursos/>) },
    { url: "ficha-curso", component: (<FichaCurso/>) },

    { url: "horario-vista", component: <HorarioVista /> },
    { url: "configuracion-horario-curso", component: validaComponent(<ConfigHorarioCurso />, [5]) },

    { url: "calificaciones-curso", component: <CalificacionesCurso /> },
    {
      url: "informe-calificaciones-estudiante-curso",
      component: <InformeCalificacionEstudianteCurso />,
    },
    {
      url: "informe-calificaciones-periodo/:estudianteId",
      component: <InformeCalificacionesEstudiante />,
    },

    {
      url: "asistencia-mensual-subvenciones-estudiantes",
      component: <AsistenciaMensualSubvencionEstudiantes />,
    },
    {
      url: "asistencia-mensual-subvenciones-cursos",
      component: <AsistenciaMensualSubvencionCursos />,
    },
    {
      url: "porcentaje-asistencia-estudiantes",
      component: <PorcentajeAsistenciaAnualPorEstudiante />,
    },
    {
      url: "porcentaje-asistencia-cursos",
      component: <PorcentajeAsistenciaAnualPorCurso />,
    },
    { url: "matricula-vista", component: <MatriculaVista /> },
    { url: "funcionarios", component: <ListaFuncionarios /> },
    { url: "ficha-estudiante", component: <FichaEstudiante /> },
    { url: "ficha-funcionario", component: <FichaFuncionario /> },

    // Permitir acceso a "importar-calificaciones" tanto para rolId 1 como 5
    {
      url: "importar-calificaciones",
      component: validaComponent(<ImportarCalificaciones />, [1, 5]),
    },

    { url: "lista-asignaturas", component: <ListaAsignaturas /> },
    { url: "ficha-asignatura", component: <FichaAsignaturas /> },
  ];

  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    if (url.indexOf(path) <= 0) {
      return <Error404 />;
    }
  }

  return (
    <>
      <Routes>
        <Route path="/page-lock-screen" element={<LockScreen />} />
        <Route path="/page-error-400" element={<Error400 />} />
        <Route path="/page-error-403" element={<Error403 />} />
        <Route path="/page-error-404" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/page-error-503" element={<Error503 />} />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { sidebariconHover } = useContext(ThemeContext);
  const sideMenu = useSelector((state) => state.sideMenu);
  return (
    <>
      <div
        id="main-wrapper"
        className={`show  ${sidebariconHover ? "iconhover-toggle" : ""} ${
          sideMenu ? "menu-toggle" : ""
        }`}
        
      >
        <div
          style={{
            marginBottom: '4.5rem'
          }}
        >
          <Nav />
        </div>
        <div
          className="container-fluid"
          style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 0 }}
        >
          <Outlet />
        </div>

        {/* <Footer /> */}
      </div>
    </>
  );
}

export default Markup;
