import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "../../../assets/css/modalCustom.css";
import "../../../assets/css/navAsistencia.css";
import ButtonFlechaAdelante from "../buttonpersonalizado/ButtonFlechaAdelante";
import ButtonFlechaAtras from "../buttonpersonalizado/ButtonFlechaAtras";
import ButtonHorario from "../buttonpersonalizado/ButtonHorario";
import EstadoClaseIcono from "../guiaestados/EstadoClaseIcon";
import HorarioDocente from "../horarioDocente/HorarioDocente";

const NavAsistenciaDiariaAsignaturaProfe = ({
  num_bloque,
  curso,
  asignatura,
  fecha,
  start,
  end,
  estadoClaseId,
  onPrev,
  onNext,
  onEventoSeleccionado,
  showCalendarButton = true,
  dateFormat = "DD/MM/YYYY",
  onHoyClick,
  asignaturaColor,
}) => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [color, setColor] = useState("var(--color-acento)"); // Color por defecto

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    const formatColor = (color) => {
      if (color && !color.startsWith("#")) {
        return `#${color}`;
      }
      return color || "var(--color-acento)";
    };

    // Se asigna el color dependiendo de la ruta y si se pasa `asignaturaColor`
    setColor(formatColor(asignaturaColor));
  }, [asignaturaColor]);

  const obtenerTextoVista = () => {
    if (location.pathname.includes("/asistencia-diaria-asignatura-profesor")) {
      return "Asistencia Bloque Diaria ";
    }
    if (location.pathname.includes("/asistencia-mes-asignatura-profesor")) {
      return "Asistencia Bloque Mensual ";
    }
    return "Vista Semanal";
  };

  const capitalizarPrimeraLetra = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatoMes = (mes) => {
    return capitalizarPrimeraLetra(mes).slice(0, 3);
  };

  const formatDate = (date, format) => {
    return date;
  };

  const estilos = {
    titulo: {
      fontWeight: 500,
      fontSize: "22px",
      lineHeight: "34px",
      color: "var(--color-texto-oscuro)",
      marginBottom: 0,
      minHeight: "34px",
    },
    asignatura: {
      backgroundColor: color, // Usando el color calculado
      color: "white",
      padding: "1px 10px",
      borderRadius: "5px",
      fontWeight: 500,
      fontSize: "18px",
      marginRight: "10px",
      minHeight: "28px",
    },
    fecha: {
      fontSize: "1rem",
      color: "var(--color-texto-oscuro)",
      fontWeight: 500,
      textAlign: "center",
      minHeight: "20px",
    },
    navItem: {
      display: "flex",
      alignItems: "center",
    },
    navContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    bloqueInfo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: "0px",
    },
    bloqueNumero: {
      fontWeight: "bold",
      fontSize: "1rem",
      color: "var(--color-texto-oscuro)",
    },
    bloqueHoras: {
      fontSize: "0.8rem",
      color: "gray",
    },
    linea: {
      width: "2px",
      height: "35px",
      backgroundColor: "var(--color-acento)",
      marginLeft: "20px",
    },
    menuContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginRight: "10px",
    },
    menuText: {
      fontSize: "1rem",
      color: "var(--color-texto-oscuro)",
      marginBottom: location.pathname.includes(
        "/asistencia-mes-asignatura-profesor"
      )
        ? "-3px"
        : "-5px",
      marginTop: location.pathname.includes(
        "/asistencia-mes-asignatura-profesor"
      )
        ? "-18px"
        : "-18px",
    },
    hoyButton: {
      backgroundColor: "var(--color-acento)",
      border: "none",
      fontSize: "16px",
      color: "white",
      padding: "5px 15px",
      borderRadius: "5px",
      cursor: "pointer",
      height: "35px",
    },
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {estadoClaseId === 2 ? "Asistencia guardada" : "Asistencia sin guardar"}
    </Tooltip>
  );

  const renderUltimaClaseTooltip = (props) => (
    <Tooltip id="button-tooltip-ultima-clase" {...props}>
      Última Clase
    </Tooltip>
  );

  return (
    <Container
      fluid
      className="bg-white p-0 p-3"
      style={{ overflow: "hidden" }}
    >
      <Row className="align-items-center nav-container" style={estilos.navContainer}>
        <Col xs="auto" className="nav-item nav-left" style={estilos.navItem}>
          {isLoading ? (
            <div style={{ minHeight: "34px", minWidth: "200px" }}></div>
          ) : (
            <div className="menu-container" style={estilos.menuContainer}>
              <ButtonHorario
                onClick={() => setShowModal(true)}
                showCalendarButton={showCalendarButton}
                title="Ver Calendario"
              />
            </div>
          )}
          <div className="bloque-info" style={estilos.bloqueInfo}>
            <span
              className="h4 d-flex align-items-center ml-2 mb-0 curso-asignatura"
              style={estilos.titulo}
            >
              {isLoading ? (
                <div style={{ minHeight: "34px", minWidth: "200px" }}></div>
              ) : (
                <>
                  <span style={estilos.asignatura}>{asignatura}</span>
                  {curso}
                </>
              )}
            </span>
          </div>
        </Col>
        <Col
          xs="auto"
          className="flex-grow-1 nav-item nav-center"
          style={{ ...estilos.navItem, justifyContent: "center" }}
        >
          <h5 className="h5 text-center titulo" style={estilos.titulo}>
            {isLoading ? (
              <div style={{ minHeight: "34px", minWidth: "200px" }}></div>
            ) : (
              obtenerTextoVista()
            )}
          </h5>
        </Col>
        <Col xs="auto" className="nav-item nav-right" style={estilos.navItem}>
          {isLoading ? (
            <div style={{ minHeight: "34px", minWidth: "200px" }}></div>
          ) : (
            <div
              className="ultima-clase-fecha"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "3rem",
              }}
            >
              <div className="nav-hoy-flechaAtras" style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {location.pathname.includes(
                  "/asistencia-diaria-asignatura-profesor"
                ) && (
                  <OverlayTrigger
                    placement="top"
                    overlay={renderUltimaClaseTooltip}
                  >
                    <button className="hoy-button" style={estilos.hoyButton} onClick={onHoyClick}>
                      <FontAwesomeIcon icon={faClock} />
                    </button>
                  </OverlayTrigger>
                )}
                <ButtonFlechaAtras onClick={onPrev} />
              </div>
              <div
                className="fecha-navegacion"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span className="h4 mx-2 mb-0 fecha" style={estilos.fecha}>
                  {location.pathname.includes(
                    "/asistencia-mes-asignatura-profesor"
                  )
                    ? formatoMes(formatDate(fecha, "MMMM"))
                    : formatDate(fecha, dateFormat)}
                </span>
                {location.pathname.includes(
                  "/asistencia-diaria-asignatura-profesor"
                ) &&
                  num_bloque && (
                    <div className="bloque-horas" style={estilos.bloque}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderTooltip}
                        >
                          <div
                            className="estado-clase"
                            style={{ marginRight: "5px", marginTop: "0px" }}
                          >
                            <EstadoClaseIcono estadoClaseId={estadoClaseId} />
                          </div>
                        </OverlayTrigger>
                        {`Bloque ${num_bloque} | ${moment(start).format(
                          "HH:mm"
                        )} - ${moment(end).format("HH:mm")}`}
                      </div>
                    </div>
                  )}
              </div>
              <ButtonFlechaAdelante className="flecha-adelante" onClick={onNext} />
            </div>
          )}
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="xs"
        centered
        className="custom-modal"
      >
        <Modal.Header closeButton className="p-2 m-0"></Modal.Header>
        <Modal.Body className="p-0 m-0">
          <HorarioDocente
            onEventoSeleccionado={(eventoSeleccionado) => {
              onEventoSeleccionado(eventoSeleccionado);
              setShowModal(false);
            }}
            isModal={true}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default NavAsistenciaDiariaAsignaturaProfe;
