// reducers/avatarReducer.js
import { SET_AVATAR } from "../actions/AvatarActions";

const initialState = {};

const avatarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVATAR:
      return {
        ...state,
        [action.payload.run]: action.payload.avatarUrl ?? "no-avatar", // Si no hay avatar, guarda "no-avatar"
      };
    default:
      return state;
  }
};

export default avatarReducer;
