import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import EventoSemana from '../../components/horarioDocente/EventoSemana';

const localizer = momentLocalizer(moment);
const weekDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

const HorarioCurso = ({ diasAMostrar, events, minTime, maxTime, onClickBloque, bloquesDestacados }) => {
  console.log(bloquesDestacados)

  const memoizedEvents = useMemo(() => {
    return events;
  }, [events]);

  useEffect(() => {
    console.log('Bloques destacados recibidos:', bloquesDestacados);
    
    const headers = document.querySelectorAll('.rbc-header');
    headers.forEach((header, index) => {
      if (diasAMostrar.includes(index + 1)) {
        header.style.display = 'table-cell';
        const span = header.querySelector('span');
        if (span) {
          span.innerText = weekDays[index + 1];
        }
      } else {
        header.style.display = 'none';
      }
    });

    const columns = document.querySelectorAll('.rbc-day-slot');
    columns.forEach((col, index) => {
      if (diasAMostrar.includes(index + 1)) {
        col.style.display = 'table-cell';
        col.style.width = `${100 / diasAMostrar.length}%`;
      } else {
        col.style.display = 'none';
      }
    });
  }, [diasAMostrar, bloquesDestacados]);

  return (
    <div style={{ height: '70dvh' }}>
      <Calendar
        localizer={localizer}
        events={memoizedEvents} 
        defaultView={Views.WEEK}
        views={{ week: true }}
        step={15}
        timeslots={4}
        min={minTime}
        max={maxTime}
        style={{ height: '100%' }}
        components={{
          event: (props) => {
            // Verificar si el bloque actual está en la lista de destacados
            const isBloqueDestacado = bloquesDestacados.includes(props.event.idHorario);
            // console.log(`Evento: ${props.event.title} - ID Horario: ${props.event.idHorario}, Destacado: ${isBloqueDestacado}`);
            return <EventoSemana {...props} hideIcon={true} destacado={isBloqueDestacado} />;
          },
          toolbar: () => null,
        }}
        defaultDate={new Date()}
        onSelectEvent={(event) => {
          console.log('Evento seleccionado:', event);
          onClickBloque(event);
        }}
      />
    </div>
  );
};

export default HorarioCurso;
