import React, { useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import obtenerColorMapaCalor from '../../../util/utilidadesMapaCalor';
import NotificacionToast from '../notificacion/NotificacionToast';

const TablaPorcentajeAsistenciaAnualPorCurso = ({ cursos, meses, isHeatmapActive }) => {
    const [mostrarToast, setMostrarToast] = useState(false);
    const [mensajeToast, setMensajeToast] = useState('');

    const estilos = {
        container: {
            maxHeight: '79dvh',
            overflow: 'auto',
            position: 'relative'
        },
        thSticky: {
            position: 'sticky',
            top: 0,
            backgroundColor: 'var(--color-header)',
            color: 'var(--color-texto-oscuro)',
            borderBottom: '1px solid lightgrey',
            zIndex: 0,
            height: '45px',
        },
        textoCurso: {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            color: 'rgb(136, 136, 136)',
        },
        textoNumero: {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            color: 'rgb(136, 136, 136)',
        },
        colCurso: {
            minWidth: '150px',
            borderRight: '1px solid lightgrey',
            height: '45px',
        },
        colMes: {
            minWidth: '100px',
            maxWidth: '100px'
        },
        colNumero: {
            width: '50px',
        },
        colResumen: (isActive) => ({
            minWidth: '100px',
            borderLeft: '1px solid lightgrey',
        }),
        td: (isActive, value, isResumen) => ({
            borderBottom: '1px solid lightgrey',
            borderTop: 'none',
            borderLeft: isResumen ? (isActive ? '3px solid white' : '1px solid lightgrey') : 'none',
            borderRight: 'none',
            backgroundColor: isActive ? obtenerColorMapaCalor(parseFloat(value)) : 'white',
            color: isActive ? 'black' : 'var(--color-texto-claro)',
            textAlign: 'center',
            padding: '8px',
            fontSize: '0.9em'
        }),
        trSticky: {
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            borderBottom: 'none',
            height: '45px',
        },
        hiddenRow: {
            visibility: 'hidden'
        },
        boldText: {
            fontWeight: 'bold',
            color: 'var(--color-texto-oscuro)',
            fontSize: '1.1em'
        },
        resumenTd: (isActive) => ({
            borderBottom: '1px solid lightgrey',
            borderLeft: '1px solid white',
            backgroundColor: 'white',
            textAlign: 'center',
            padding: '8px',
            fontSize: '0.9em',
            height: '45px',
        }),
        resumenAnualTd: (isActive) => ({
            borderBottom: '1px solid lightgrey',
            borderLeft: isActive ? '3px solid white' : '1px solid lightgrey',
            backgroundColor: 'white',
            textAlign: 'center',
            padding: '8px',
            fontSize: '0.9em'
        })
    };

    const formatearPorcentaje = num => (num || num === 0) ? `${num.toFixed(2).replace('.', ',')}%` : '';

    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1; // Obtiene el índice del mes actual (1 = Enero, 12 = Diciembre)

    const mesMap = {
        'enero': 1,
        'febrero': 2,
        'marzo': 3,
        'abril': 4,
        'mayo': 5,
        'junio': 6,
        'julio': 7,
        'agosto': 8,
        'septiembre': 9,
        'octubre': 10,
        'noviembre': 11,
        'diciembre': 12
    };

    return (
        <div className="container-fluid pt-0 bg-white">
            <div className="table-responsive" style={estilos.container}>
                <Table hover id="tablaAsistencia" className="table" style={{ marginBottom: 0 }}>
                    <thead>
                        <tr>


                        <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-numero-lista">Orden listado</Tooltip>}
                  >
         
                  
                            <th className="text-center" style={{ ...estilos.thSticky, ...estilos.colNumero }}>#</th>


                  </OverlayTrigger>




                            <th className="text-center" style={{ ...estilos.thSticky, ...estilos.colCurso }}>Curso</th>
                            {meses.map((mes, index) => (
                                <th key={index} className="text-center" style={{ ...estilos.thSticky, ...estilos.colMes }}>{mes.charAt(0).toUpperCase() + mes.slice(1)}</th>
                            ))}
                            <th className="text-center" style={{ ...estilos.thSticky, ...estilos.colResumen(isHeatmapActive), ...estilos.boldText }}> % Anual</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cursos.map((curso, index) => (
                            <tr key={curso.id}>
                                <td className="text-center" style={{ ...estilos.td(false, curso.id, false), ...estilos.textoNumero }}>{index + 1}</td>
                                <td className="text-left" style={{ ...estilos.td(false, curso.id, false), ...estilos.colCurso, ...estilos.textoCurso }}>{curso.nombre}</td>
                                {meses.map((mes, index) => {
                                    const valor = curso.asistencias[mes.toLowerCase()];
                                    const mesIndex = mesMap[mes.toLowerCase()];
                                    const esFuturo = mesIndex > mesActual;
                                    const mostrarMapaCalor = isHeatmapActive && !esFuturo;
                                    return (
                                        <td key={index} className="text-center" style={estilos.td(mostrarMapaCalor, valor, false)}>
                                            {esFuturo && valor === 0 ? '' : formatearPorcentaje(valor)}
                                        </td>
                                    );
                                })}
                                <td className="text-center" style={estilos.td(isHeatmapActive, curso.asistencias.resumenAnual, true)}>
                                    {formatearPorcentaje(curso.asistencias.resumenAnual)}
                                </td>
                            </tr>
                        ))}
                        <tr style={estilos.trSticky}>
                            <td colSpan="2" className="text-center" style={{ ...estilos.resumenTd(false), ...estilos.boldText, borderRight: '1px solid lightgrey' }}>Mensual</td>
                            {meses.map((mes, index) => {
                                const mesIndex = mesMap[mes.toLowerCase()];
                                const esFuturo = mesIndex > mesActual;
                                return (
                                    <td key={mes} className="text-center" style={{ ...estilos.resumenTd(false), borderRight: 'none', borderLeft: 'none' }}>
                                        {esFuturo ? '' : formatearPorcentaje(cursos.reduce((sum, curso) => sum + parseFloat(curso.asistencias[mes.toLowerCase()]), 0) / cursos.length)}
                                    </td>
                                );
                            })}
                            <td className="text-center" style={{ ...estilos.resumenAnualTd(false), ...estilos.boldText, borderLeft: '1px solid lightgrey', borderRight: 'none' }}>
                                {formatearPorcentaje(cursos.reduce((sum, curso) => sum + parseFloat(curso.asistencias.resumenAnual), 0) / cursos.length)}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <NotificacionToast mensaje={mensajeToast} mostrar={mostrarToast} setMostrar={setMostrarToast} />
        </div>
    );
};

export default TablaPorcentajeAsistenciaAnualPorCurso;
