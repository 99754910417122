import { Tab, Nav } from "react-bootstrap";
import Title from "../titles/Title";

const TabNavigator = ({
  title,
  activeTab,
  onSelectTab,
  tabList,
  additionalProps,
}) => {

  return (
    <Tab.Container activeKey={activeTab} onSelect={(k) => onSelectTab(k)}>
      <span style={{ position: "absolute" }}>
        <Title text={title} />
      </span>

      <Nav variant="tabs" className="mb-3 custom-tabs justify-content-center">
        {tabList
          .filter((tab) => tab.isSelected)
          .map((tab, index) => (
            <Nav.Item
              key={tab.eventKey + "-" + index}
              style={{ width: "150px", height: "100%" }}
            >
              <Nav.Link
                eventKey={tab.eventKey}
                style={{ textOverflow: "ellipsis", textWrap: "nowrap" }}
              >
                {tab.name}
              </Nav.Link>
            </Nav.Item>
          ))}
      </Nav>

      <Tab.Content>
        {tabList
          .filter((tab) => tab.isSelected)
          .map((tab, index) => (
            <Tab.Pane key={tab.eventKey + "-" + index} eventKey={tab.eventKey}>
              <tab.component {...additionalProps} />
            </Tab.Pane>
          ))}
      </Tab.Content>
    </Tab.Container>
  );
};

export default TabNavigator;
