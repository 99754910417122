let direction = 'ltr';

export const dezThemeSet = [
    { /* Default */
        typography: "poppins",
        version: "light",
        layout: "vertical",
        navheaderBg: "color_3",
        headerBg: "color_1",
        sidebarBg: "color_1",
        sidebarStyle: "full",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_1",
        direction: direction
    },
    { /* Demo Theme 1 */
        typography: "poppins",
        version: "light",
        layout: "vertical",
        navheaderBg: "color_1",
        headerBg: "color_1",
        sidebarBg: "color_1",
        sidebarStyle: "full",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_1",
        direction: direction
    },
    { /* Demo Theme 2 */
        typography: "poppins",
        version: "dark",
        layout: "vertical",
        navheaderBg: "color_1",
        headerBg: "color_1",
        sidebarBg: "color_1",
        sidebarStyle: "full",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_1",
        direction: direction
    },
    {
        /* Demo Theme 3 */
        typography: "roboto",
        version: "light",
        layout: "vertical",
        navheaderBg: "color_14",
        headerBg: "color_14",
        sidebarStyle: "modern",
        sidebarBg: "color_13",
        sidebarPosition: "static",
        headerPosition: "static",
        containerLayout: "wide",
        primary: "color_1",
        direction: direction
    },
    {
        /* Demo Theme 4 */
        typography: "roboto",
        version: "light",
        layout: "vertical",
        navheaderBg: "color_10",
        headerBg: "color_2",
        sidebarStyle: "full",
        sidebarBg: "color_2",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_1",
        direction: direction
    },
    {
        /* Demo Theme 5 */
        typography: "poppins",
        version: "light",
        layout: "vertical",
        navheaderBg: "color_13",
        headerBg: "color_13",
        sidebarBg: "color_13",
        sidebarStyle: "compact",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_1",
        direction: direction
    },
    {
        /* Demo Theme 6 */
        typography: "poppins",
        version: "light",
        layout: "vertical",
        navheaderBg: "color_1",
        headerBg: "color_1",
        sidebarBg: "color_1",
        sidebarStyle: "modern",
        sidebarPosition: "static",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_11",
        direction: direction
    },
    {
        /* Demo Theme 7 */
        typography: "poppins",
        version: "light",
        layout: "horizontal",
        navheaderBg: "color_5",
        headerBg: "color_5",
        sidebarBg: "color_1",
        sidebarStyle: "full",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_5",
        direction: direction
    },
    {
        /* Demo Theme 8 */
        typography: "poppins",
        version: "light",
        layout: "vertical",
        navheaderBg: "color_14",
        headerBg: "color_1",
        sidebarBg: "color_14",
        sidebarStyle: "modern",
        sidebarPosition: "static",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_14",
        direction: direction
    },

];
	