import React from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import TextInput from '../../../../components/inputs/TextInput';
import DatePickerInput from '../../../../components/inputs/DatePickerInput';
import SelectSingle from '../../../../components/inputs/SelectSingle';
import CheckboxInput from '../../../../components/inputs/CheckboxInput';
import TextAreaInput from '../../../../components/inputs/TextAreaInput';

function TabEscolar({
  valoresEstudiante,
  setValoresEstudiante,
  listaLocalEscolar,
  listaGrados,
  listaCursos,
  manejarCambioTexto,
  manejarCambioFecha,
  lastAvailableMatricula,
  setErrors,
}) {

  const manejarSeleccionCurso = (curso) => {
    setValoresEstudiante((prevValues) => ({
      ...prevValues,
      curso: curso ? curso.id : null,
    }));

    // Si el curso no es null, haz algo con el curso seleccionado
    // Por ejemplo, cargar los grados disponibles para el curso seleccionado

    // Si el curso es null, resetea la lista de grados o realiza otras acciones necesarias
  };

  React.useEffect(() => {
    if (valoresEstudiante.estudianteNumMatricula == null && lastAvailableMatricula) {
      setValoresEstudiante((prevValues) => ({
        ...prevValues,
        estudianteNumMatricula: lastAvailableMatricula,
      }));
    }
  }, [lastAvailableMatricula, setValoresEstudiante, valoresEstudiante.estudianteNumMatricula]);
  
  return (
    <Form
    className="d-flex flex-column align-items-center"
    style={{ height: "55dvh", width: "100%" }}
  >
    <section style={{ width: "70%" }}>
      <Row className="mb-3 pt-4 d-flex justify-content-center">
        <Col md={2}>
          <TextInput
            controlId="estudianteNumMatricula"
            type="text"
            value={valoresEstudiante.estudianteNumMatricula || ""}
            onChange={(e) =>
              manejarCambioTexto(
                "estudianteNumMatricula",
                e.target.value,
                setValoresEstudiante,
                setErrors
              )
            }
            maxLength={25}
            label="N° matrícula"
          />
        </Col>

        <Col md={2}>
          <DatePickerInput
            controlId="fechaMatricula"
            selected={
              valoresEstudiante.estudianteFechaMatricula || null
            }
            onChange={(date) =>
              manejarCambioFecha(date, "estudianteFechaMatricula")
            }
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/aaaa"
            locale="es"
            label="Fecha matrícula"
            disabled={
              valoresEstudiante.estudianteNumMatricula !== ""
                ? false
                : true
            }
            required={
              valoresEstudiante.estudianteNumMatricula !== ""
                ? true
                : false
            }
          />
        </Col>

        <Col md={2}>
          <SelectSingle
            controlId="localEscolar"
            placeholder="Local Escolar"
            idKey="id"
            labelKey="name"
            elements={listaLocalEscolar}
            onSelect={(localEscolar) =>
              setValoresEstudiante((prevValues) => ({
                ...prevValues,
                localEscolar: localEscolar ? localEscolar.id : null,
              }))
            }
            selectedElement={valoresEstudiante.localEscolar}
          />
        </Col>
      </Row>

      <Row className="mb-4  d-flex justify-content-center">
        <Col md={2}>
          <SelectSingle
            controlId="grado"
            placeholder="Grado"
            elements={listaGrados.map((item) => {
              return { id: item.gradoId, name: item.gradoNombre };
            })}
            idKey="id"
            labelKey="name"
            selectedElement={valoresEstudiante.grado}
            onSelect={(grado) => {
              // console.log(grado);
              setValoresEstudiante((prevValues) => ({
                ...prevValues,
                grado: grado ? grado.id : null,
              }));
            }}
          />
        </Col>
        <Col md={2}>
          <SelectSingle
            controlId="curso"
            required
            placeholder="Curso"
            elements={
              listaCursos
                .filter(
                  (curso) => curso.gradoId === valoresEstudiante.grado
                )
                .map((item) => ({
                  id: item.cursoId,
                  name: item.cursoNombre,
                })).length > 0
                ? listaCursos
                    .filter(
                      (curso) =>
                        curso.gradoId === valoresEstudiante.grado
                    )
                    .map((item) => ({
                      id: item.cursoId,
                      name: item.cursoNombre,
                    }))
                : listaCursos.map((item) => ({
                    id: item.cursoId,
                    name: item.cursoNombre,
                  }))
            }
            idKey="id"
            labelKey="name"
            selectedElement={valoresEstudiante.curso}
            onSelect={manejarSeleccionCurso}
            tooltipMessage={'Seleccione "Grado" para filtrar cursos'}
          />
        </Col>

        <Col md={2}>
          <TextInput
            controlId="estudianteNumLista"
            type="text"
            value={valoresEstudiante.estudianteNumLista || ""}
            onChange={(e) =>
              manejarCambioTexto(
                "estudianteNumLista",
                e.target.value,
                setValoresEstudiante,
                setErrors
              )
            }
            maxLength={25}
            label="N° Lista"
          />
        </Col>
      </Row>

      <Row className="mb-3 d-flex justify-content-center">
        <Col md={2}>
          <TextInput
            controlId="colegioProcedencia"
            type="text"
            value={valoresEstudiante.colegioProcedencia || ""}
            onChange={(e) =>
              manejarCambioTexto(
                "colegioProcedencia",
                e.target.value,
                setValoresEstudiante,
                setErrors
              )
            }
            maxLength={25}
            label="Colegio procedencia"
          />
        </Col>

        <Col md={2}>
          <DatePickerInput
            controlId="fechaRetiro"
            selected={valoresEstudiante.retiroFecha}
            onChange={(date) =>
              manejarCambioFecha(date, "retiroFecha")
            }
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/aaaa"
            locale="es"
            label="Fecha de Retiro"
          />
        </Col>

        <Col md={2}>
          <TextInput
            controlId="motivoRetiro"
            type="text"
            value={valoresEstudiante.motivoRetiro || ""}
            onChange={(e) =>
              manejarCambioTexto(
                "motivoRetiro",
                e.target.value,
                setValoresEstudiante,
                setErrors
              )
            }
            maxLength={25}
            label="Motivo de Retiro"
            disabled={!valoresEstudiante.retiroFecha}
            required={valoresEstudiante.retiroFecha ? true : false}
          />
        </Col>
      </Row>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col md={2} className="d-flex justify-content-center">
          <CheckboxInput
            controlId="excedente"
            label="Estudiante excedente"
            checked={valoresEstudiante.excedente}
            onChange={(e) =>
              setValoresEstudiante((prevValues) => ({
                ...prevValues,
                excedente: e.target.checked,
              }))
            }
            style={{ color: "var(--color-texto-oscuro)" }}
          />
        </Col>

        <Col md={2}>
          <TextInput
            controlId="numeroResolucion"
            type="text"
            value={valoresEstudiante.numeroResolucion || ""}
            onChange={(e) =>
              manejarCambioTexto(
                "numeroResolucion",
                e.target.value,
                setValoresEstudiante,
                setErrors
              )
            }
            maxLength={25}
            label="Número resolución"
            disabled={!valoresEstudiante.excedente}
            required={valoresEstudiante.excedente ? true : false}
          />
        </Col>
        <Col md={2}>
          <DatePickerInput
            controlId="fechaResolucion"
            selected={valoresEstudiante.fechaResolucion}
            onChange={(date) =>
              manejarCambioFecha(date, "fechaResolucion")
            }
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/aaaa"
            locale="es"
            label="Fecha de Resolución"
            disabled={!valoresEstudiante.excedente}
            required={valoresEstudiante.excedente ? true : false}
          />
        </Col>
      </Row>
      <Row className="d-flex pt-3 justify-content-center">
        <Col md={6}>
          <TextAreaInput
            controlId="observaciones"
            value={valoresEstudiante.observaciones || ""}
            onChange={(e) =>
              manejarCambioTexto(
                "observaciones",
                e.target.value,
                setValoresEstudiante,
                setErrors
              )
            }
            maxLength={500}
            label="Observaciones"
          />
        </Col>
      </Row>
    </section>
  </Form>
  )
}

export default TabEscolar