const estilosEstadoAdicionales = {
    matriculadoSinNumeroLista: {
      icon: "fa-solid fa-circle-exclamation",
      iconColor: "ORANGE",
      description: "Matriculado sin número de lista",
      fontSize: "24px"
    },
    sinNumeroMatriculaYLista: {
      icon: "fa-solid fa-question-circle",
      iconColor: "ORANGE",
      description: "Sin número de matrícula ni de lista",
      fontSize: "24px" 
    }
  };
  
  export { estilosEstadoAdicionales };
  