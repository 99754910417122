import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { handleMouseEnter, handleMouseLeave } from '../../../util/hoverAdaptativo';
import { truncate } from '../../../util/util';
import EstadoClaseIcono from '../guiaestados/EstadoClaseIcon';

const EventoSemana = ({ event, hideIcon, destacado }) => {
  const refEvento = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getColorWithHash = (color) => {
    if (color && !color.startsWith('#')) {
      return `#${color}`;
    }
    return color || 'white';
  };

  const backgroundColor = getColorWithHash(event.asignaturaColorFondo || event.color);
  const textColor = 'white';
  const hoverColor = 'var(--color-secundario)';

  const asignatura = event.asignatura ? truncate(event.asignatura, 20) : '';

  const docenteNombreCompleto = event.docenteNombre && event.docenteApellido
    ? `${event.docenteNombre} ${event.docenteApellido}` : null;
  const docenteNombreTruncado = docenteNombreCompleto ? truncate(docenteNombreCompleto, 10) : ' ';

  const asignaturaStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
    padding: '3px 5px',
    borderRadius: '4px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px',
  };

  const cursoSalaStyle = {
    fontFamily: 'Segoe UI, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    fontWeight: 400,
    fontSize: '1.0em',
    color: '#172b4d',
    marginBottom: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
  };

  const horaStyle = {
    display: 'flex',
    alignItems: 'center',
    color: '#172b4d',
    fontFamily: 'Segoe UI, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    fontWeight: 400,
    fontSize: '0.80em',
    marginTop: '4px'
  };

  const estilosCirculo = {
    width: '2.5vh',
    height: '2.5vh',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: "11.5px",
    justifyContent: 'center',
    borderRadius: '50%',
    border: '1px solid #3F5A73',
    color: 'var(--color-texto-claro)',
    marginRight: '10px',
  };

  

  const estilosEvento = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontSize: windowWidth > 1024 ? '13.5px' : windowWidth > 768 ? '0.85em' : '0.75em',
    border: '0.01em solid lightgray',
    borderRadius: '5px',
    backgroundColor: destacado ? 'rgba(168, 162, 158, 0.3)' : 'white',
    textAlign: 'left',
    transition: 'border-color 0.3s',
    cursor: 'default',
    padding: '10px',
    boxSizing: 'border-box',
  };

  const contenedorTextoStyle = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
  };

  const eventosEspecialesStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontSize: windowWidth > 1024 ? '13.5px' : windowWidth > 768 ? '0.85em' : '0.75em',
    border: '0.01em solid lightgray',
    borderRadius: '5px',
    backgroundColor: hoverColor,
    textAlign: 'left',
    transition: 'background-color 0.3s',
    cursor: 'default',
    padding: '10px',
    boxSizing: 'border-box',
    color: 'var(--color-texto-oscuro)',
  };

  const iconoEstadoStyle = {
    marginLeft: '10px',
    marginRight: '10px',
    alignSelf: 'center',
    flexShrink: 0
  };

  return (
    <div
      ref={refEvento}
      style={event.type === 'Almuerzo' || event.type === 'Recreo' ? eventosEspecialesStyle : estilosEvento}
      onMouseEnter={() => handleMouseEnter(refEvento, backgroundColor)}
      onMouseLeave={() => handleMouseLeave(refEvento, backgroundColor)}
    >
      {event.type === 'Clase' && (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={estilosCirculo}>{`B${event.num_bloque}`}</div>
          <div style={contenedorTextoStyle}>
            <div style={cursoSalaStyle}>
              {!hideIcon && <EstadoClaseIcono estadoClaseId={event.estadoClaseId} style={iconoEstadoStyle} />}
              <span style={{ marginLeft: '5px' }}>{`${event.curso} - ${event.sala}`}</span>
              {hideIcon && docenteNombreCompleto && (
                <span title={docenteNombreCompleto} style={{ marginLeft: '5px' }}>
                  | {docenteNombreTruncado}
                </span>
              )}
            </div>
            <div style={asignaturaStyle}>
              {asignatura}
            </div>
            <div style={horaStyle}>
              <FontAwesomeIcon icon={faClock} style={{ marginRight: '5px', marginTop: '-3px', color: 'var(--color-acento)' }} />
              {`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}
            </div>
          </div>
        </div>
      )}
      {event.type === 'Almuerzo' && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <div style={contenedorTextoStyle}>
            <div style={{ fontWeight: '300', color: 'var(--color-texto-oscuro)', textAlign: 'center' }}>
              {event.title}
            </div>
            <div style={horaStyle}>
              {`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}
            </div>
          </div>
        </div>
      )}
      {event.type === 'Recreo' && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <div style={contenedorTextoStyle}>
            <div style={{ fontWeight: '300', color: 'var(--color-texto-oscuro)', textAlign: 'center' }}>
              {event.title}
            </div>
          </div>
        </div>
      )}
      {!['Clase', 'Almuerzo', 'Recreo'].includes(event.type) && (
        <div style={{ flex: 1, padding: '4px', color: 'var(--color-texto-oscuro)' }}>
          {event.title}
        </div>
      )}
    </div>
  );
};

export default EventoSemana;
