import axios from 'axios';
import {store} from '../store/store';
import apiConfig from '../config/apiConfig';

const axiosInstance = axios.create({
    baseURL: apiConfig.url,
});

axiosInstance.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.auth.token;
    config.headers = config.headers || {};
    config.headers['Authorization'] = 'Bearer ' + token;
    config.headers['deviceType'] = 'desktop';
    const currentSchool = localStorage.getItem('currentSchool')
    if(currentSchool) {
        const school = JSON.parse(currentSchool)
        if(school) {
            config.headers['colegioId'] = school.id
        }
        if(school?.periodoActual) {
            config.headers['periodoId'] = school.periodoActual.id
        }
    }
    return config;
});

// Interceptor para manejar los errores 404 de manera silenciosa
axiosInstance.interceptors.response.use(
    (response) => {
      // Si la respuesta es exitosa (código 2xx), simplemente la retornamos
      return response;
    },
    (error) => {
      // Si es un error 404, lo manejamos silenciosamente
      if (error.response && error.response.status === 404) {
        // Retornamos un valor controlado para que no genere logs
        return Promise.resolve(null);
      }
      // Si es otro tipo de error, puedes manejarlo o rechazarlo
      return Promise.reject(error);
    }
  );

export default axiosInstance;
