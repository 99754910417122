// Definición de los colores en variables
const COLOR_ROJO_FUERTE = { r: 204, g: 0, b: 0, a: 0.9 };  // Rojo fuerte
const COLOR_ROJO = { r: 244, g: 67, b: 54, a: 0.9 };  // Rojo
const COLOR_NARANJA_OSCURO = { r: 255, g: 87, b: 34, a: 0.9 };  // Naranja oscuro
const COLOR_NARANJA = { r: 255, g: 152, b: 0, a: 0.9 };  // Naranja
const COLOR_AMARILLO = { r: 255, g: 193, b: 7, a: 0.9 };  // Amarillo
const COLOR_AMARILLO_CLARO = { r: 255, g: 235, b: 59, a: 0.9 };  // Amarillo claro
const COLOR_VERDE = { r: 76, g: 175, b: 80, a: 0.9 };  // Verde
const COLOR_VERDE_ASISTENCIA = { r: 99, g: 186, b: 60, a: 0.9 };  // Verde específico para asistencia

const obtenerColorMapaCalor = (valor, isCalificacion = false, isAsistencia = false) => {
    if (isNaN(valor) || valor === null) return '#ffffff';

    const interpolarColor = (color1, color2, factor) => {
        return `rgba(${Math.round(color1.r + (color2.r - color1.r) * factor)}, ${Math.round(color1.g + (color2.g - color1.g) * factor)}, ${Math.round(color1.b + (color2.b - color1.b) * factor)}, ${color1.a})`;
    };

    if (isCalificacion) {
        const escalaColoresCalificacion = [
            { pct: 1.0, color: COLOR_ROJO_FUERTE },
            { pct: 2.0, color: COLOR_ROJO },
            { pct: 3.0, color: COLOR_NARANJA_OSCURO },
            { pct: 4.0, color: COLOR_NARANJA },
            { pct: 5.0, color: COLOR_AMARILLO },
            { pct: 5.5, color: COLOR_AMARILLO_CLARO },
            { pct: 7.0, color: COLOR_VERDE }
        ];

        const obtenerColor = (valor) => {
            for (let i = 1; i < escalaColoresCalificacion.length; i++) {
                if (valor <= escalaColoresCalificacion[i].pct) {
                    const lower = escalaColoresCalificacion[i - 1];
                    const upper = escalaColoresCalificacion[i];
                    const rango = upper.pct - lower.pct;
                    const rangoPct = (valor - lower.pct) / rango;
                    return interpolarColor(lower.color, upper.color, rangoPct);
                }
            }
            const ultimoColor = escalaColoresCalificacion[escalaColoresCalificacion.length - 1].color;
            return `rgba(${ultimoColor.r}, ${ultimoColor.g}, ${ultimoColor.b}, ${ultimoColor.a})`;
        };

        return obtenerColor(valor);
    } else if (isAsistencia) {
        const escalaColoresAsistencia = [
            { pct: 0.0, color: COLOR_ROJO_FUERTE },
            { pct: 0.2, color: COLOR_ROJO },
            { pct: 0.4, color: COLOR_NARANJA_OSCURO },
            { pct: 0.5, color: COLOR_NARANJA },
            { pct: 0.6, color: COLOR_AMARILLO },
            { pct: 0.8, color: COLOR_AMARILLO_CLARO },
            { pct: 1.0, color: COLOR_VERDE_ASISTENCIA }
        ];

        const obtenerColor = (pct) => {
            for (let i = 1; i < escalaColoresAsistencia.length; i++) {
                if (pct <= escalaColoresAsistencia[i].pct) {
                    const lower = escalaColoresAsistencia[i - 1];
                    const upper = escalaColoresAsistencia[i];
                    const rango = upper.pct - lower.pct;
                    const rangoPct = (pct - lower.pct) / rango;
                    return interpolarColor(lower.color, upper.color, rangoPct);
                }
            }
            const ultimoColor = escalaColoresAsistencia[escalaColoresAsistencia.length - 1].color;
            return `rgba(${ultimoColor.r}, ${ultimoColor.g}, ${ultimoColor.b}, ${ultimoColor.a})`;
        };

        return obtenerColor(valor / 100);
    } else {
        const escalaColores = [
            { pct: 0.0, color: COLOR_ROJO_FUERTE },
            { pct: 0.2, color: COLOR_ROJO },
            { pct: 0.4, color: COLOR_NARANJA_OSCURO },
            { pct: 0.5, color: COLOR_NARANJA },
            { pct: 0.6, color: COLOR_AMARILLO },
            { pct: 0.8, color: COLOR_AMARILLO_CLARO },
            { pct: 1.0, color: COLOR_VERDE }
        ];

        const obtenerColor = (pct) => {
            for (let i = 1; i < escalaColores.length; i++) {
                if (pct <= escalaColores[i].pct) {
                    const lower = escalaColores[i - 1];
                    const upper = escalaColores[i];
                    const rango = upper.pct - lower.pct;
                    const rangoPct = (pct - lower.pct) / rango;
                    return interpolarColor(lower.color, upper.color, rangoPct);
                }
            }
            const ultimoColor = escalaColores[escalaColores.length - 1].color;
            return `rgba(${ultimoColor.r}, ${ultimoColor.g}, ${ultimoColor.b}, ${ultimoColor.a})`;
        };

        return obtenerColor(valor / 100);
    }
};

export default obtenerColorMapaCalor;
