import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { checkRol } from "../services/PostsService";
import { setRoles } from "../store/actions/AuthActions";

const useCheckRoles = () => {
  const dispatch = useDispatch();
  const [currentRoles, setCurrentRoles] = useState([]);

  useEffect(() => {
    checkRol()
      .then((response) => {
        setCurrentRoles(response);
        dispatch(setRoles(response));
      })
      .catch((error) => {
        console.log("Error al obtener los roles:", error);
      });
  }, [dispatch]);

  // Utilizamos useMemo para evitar recalcular en cada renderizado
  const isDirectivo = useMemo(() => currentRoles.includes(5), [currentRoles]);
  const isDocente = useMemo(() => currentRoles.includes(1), [currentRoles]);

  console.log("es directivo", isDirectivo, "es docente", isDocente);

  return {
    isDirectivo,
    isDocente,
  };
};

export default useCheckRoles;
