import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import useGetListadoCursos from '../../../hooks/useGetListadoCursos';
import useGetSubvencionAnualEstudiantes from '../../../hooks/useGetSubvencionAnualEstudiantes';
import NavPorcentajeAsistenciaAnualPorEstudianteCurso from '../../components/porcentajeAsistenciaAnualPorEstudiante/NavPorcentajeAsistenciaAnualPorEstudianteCurso';
import TablaPorcentajeAsistenciaAnualPorEstudiante from '../../components/porcentajeAsistenciaAnualPorEstudiante/TablaPorcentajeAsistenciaAnualPorEstudiante';
import CustomSpinner from '../../components/spinner/Spinner';

const PorcentajeAsistenciaAnualPorEstudiante = (props) => {
    const storedCursoId = JSON.parse(localStorage.getItem('currentRolData'))?.defaultCursoId;
    const [cursoId, setCursoId] = useState(props.cursoId || storedCursoId);

    const { cursos, cargando: cargandoCursos, error: errorCursos } = useGetListadoCursos();
    const { estudiantes, cursoAsistencias, meses, cargando: cargandoEstudiantes, error: errorEstudiantes } = useGetSubvencionAnualEstudiantes(cursoId);

    const [isHeatmapActive, setIsHeatmapActive] = useState(false);

    const handleCursoSelect = (curso) => {
        console.log("Curso seleccionado:", curso);
        setCursoId(curso.cursoId);
    };

    useEffect(() => {
        if (!cursoId && storedCursoId) {
            setCursoId(storedCursoId);
        }
    }, [storedCursoId, cursoId]);

    return (
        <Container fluid className="bg-white p-0" style={{ height: '92dvh', overflow: 'hidden' }}>
            <NavPorcentajeAsistenciaAnualPorEstudianteCurso
                cursos={cursos || []}
                cursoId={cursoId}
                onCursoSelect={handleCursoSelect}
                cargando={cargandoCursos}
                error={errorCursos}
                isHeatmapActive={isHeatmapActive}
                setIsHeatmapActive={setIsHeatmapActive}
            />
            <div style={{ flex: '1 1 auto', overflowY: 'hidden', height: '85%' }}>
                {cargandoEstudiantes ? (
                   <div className="text-center d-flex justify-content-center align-items-center h-100">
                   <CustomSpinner  />
                 </div>
                ) : errorEstudiantes ? (
                    <div className="alert alert-danger text-center mt-3">
                        Error al cargar datos: {errorEstudiantes}
                    </div>
                ) : !cursoId ? (
                    <div className="alert alert-warning text-center mt-3">
                        Por favor seleccione un curso.
                    </div>
                ) : !estudiantes || estudiantes.length === 0 ? (
                    <div className="alert alert-info text-center mt-3">
                        No hay datos de asistencia disponibles.
                    </div>
                ) : (
                    <TablaPorcentajeAsistenciaAnualPorEstudiante
                        estudiantes={estudiantes || []}
                        cursoAsistencias={cursoAsistencias || {}}
                        meses={meses || []}
                        isHeatmapActive={isHeatmapActive}
                    />
                )}
            </div>
        </Container>
    );
};

export default PorcentajeAsistenciaAnualPorEstudiante;
