import moment from 'moment';
import { useEffect, useState } from 'react';
import { getConfigHorarioCurso } from "../services/PostsService";

const useGetConfigHorarioCurso = (cursoId) => {
    const [horario, setHorario] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [error, setError] = useState(null);
    const [horaInicio, setHoraInicio] = useState(null);
    const [horaFin, setHoraFin] = useState(null);

    useEffect(() => {
        const fetchHorario = async () => {
            try {
                setCargando(true);
                const response = await getConfigHorarioCurso({ curso_id: cursoId });

                setHoraInicio(response.data.horaInicio);
                setHoraFin(response.data.horaFin);

                const confDia = Array.isArray(response.data.confDia) 
                    ? response.data.confDia.map(dia => ({
                        ...dia,
                        confBloques: Array.isArray(dia.confBloques) ? dia.confBloques : [] 
                    }))
                    : [];

                const transformedHorario = confDia.flatMap((dia) => 
                    dia.confBloques.map((bloque) => {
                        const today = moment();
                        const dayOfWeek = dia.dia - 1;
                        const currentDay = moment(today).isoWeekday(dayOfWeek + 1);

                        const start = moment(currentDay)
                            .hour(parseInt(bloque.bloqueIni.split(':')[0], 10))
                            .minute(parseInt(bloque.bloqueIni.split(':')[1], 10))
                            .toDate();
                        
                        const end = moment(currentDay)
                            .hour(parseInt(bloque.bloqueFin.split(':')[0], 10))
                            .minute(parseInt(bloque.bloqueFin.split(':')[1], 10))
                            .toDate();

                        return {
                            title: `${bloque.bloqueTipoNombre}: ${bloque.asignaturaNombre || 'Libre'}`,
                            start,
                            end,
                            asignatura: bloque.asignaturaNombre || "Sin Asignatura",
                            asignaturaColorFondo: bloque.asignaturaColorFondo || "#1E78FF",
                            curso: bloque.cursoNombre || "Curso Desconocido",
                            num_bloque: bloque.bloqueNum || null,
                            sala: bloque.salaNombre || "Sin Sala",
                            estadoClaseId: bloque.estadoClaseId || null,
                            type: bloque.bloqueTipoNombre || "Otro",
                            idHorario: bloque.idHorario,
                            bloqueDia: bloque.bloqueDia,
                            bloqueOrden: bloque.bloqueOrden,
                            bloqueId: bloque.bloqueId,
                            bloqueTipoId: bloque.bloqueTipoId,
                            cursoId: bloque.cursoId,
                            asignaturaId: bloque.asignaturaId,
                            docenteId: bloque.docenteId,
                            docenteNombre: bloque.docenteNombre,
                            docenteApellido: bloque.docenteApellido,
                            salaId: bloque.salaId,
                        };
                    })
                );

                setHorario(transformedHorario); 
            } catch (err) {
                setError(err);
                setHorario([]);
            } finally {
                setCargando(false);
            }
        };

        if (cursoId) {
            fetchHorario();
        }
    }, [cursoId]);

    return { horario, setHorario, cargando, error, horaInicio, horaFin };
};

export default useGetConfigHorarioCurso;
