import React, { useEffect, useState } from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import ButtonMultifuncional from "../buttonpersonalizado/ButtonMultifuncional";
import DesplegableConBusqueda from "../desplegableConBusqueda/DesplegableConBusqueda";
import NotificacionToast from "../notificacion/NotificacionToast";

const NavInformeEstudianteCurso = ({
  cursos,
  periodos,
  cursoId,
  periodoId,
  onCursoSelect,
  onPeriodoSelect,
  isHeatmapActive,
  setIsHeatmapActive,
  filtroEstudiante,
  setFiltroEstudiante,
  dataEstudiantesNotas,
  dataColegio,
  dataCurso
}) => {
  const [mostrarToast, setMostrarToast] = useState(false);
  const [mensajeToast, setMensajeToast] = useState("");

  // const manejarErrorDescarga = (mensaje) => {
  //   setMensajeToast(mensaje);
  // setMostrarToast(true);
  // };

  const estilos = {
    rowContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem",
      backgroundColor: "#fff",
    },
    colCentrado: {
      flex: 1,
      textAlign: "center",
      position: "relative",
    },
    titulo: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 500,
      fontSize: "22px",
      lineHeight: "34px",
      color: "rgb(28, 40, 51)",
      marginBottom: 0,
      textAlign: "center",
    },
  };

  useEffect(() => {
    if (cursoId && cursos.length > 0) {
      const cursoSeleccionado = cursos.find(
        (curso) => curso.cursoId === cursoId
      );
      if (cursoSeleccionado) {
        onCursoSelect(cursoSeleccionado);
      }
    }
  }, [cursoId, cursos, onCursoSelect]);



  return (
    <>
      <Row style={estilos.rowContainer} className="m-0 px-0 py-3">
        <Col xs="auto" className="d-flex align-items-center">
          <DesplegableConBusqueda
            elementos={cursos}
            placeholder="Curso"
            selectedElement={cursoId}
            onSelect={(curso) => onCursoSelect(curso)}
            displayProperty="cursoNombre"
            idProperty="cursoId"
          />
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          <FormControl
            type="text"
            placeholder="Buscar estudiante"
            value={filtroEstudiante}
            onChange={(e) => setFiltroEstudiante(e.target.value)}
          />
        </Col>
        <Col xs="auto" className="ms-auto d-flex align-items-center">
          <DesplegableConBusqueda
            elementos={periodos}
            placeholder="Periodo"
            selectedElement={periodoId}
            onSelect={(periodo) => onPeriodoSelect(periodo)}
            displayProperty="nombre"
            idProperty="id"
          />
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          <ButtonMultifuncional
            tablaId="tablaInformeEstudiante"
            isHeatmapActive={isHeatmapActive}
            setIsHeatmapActive={setIsHeatmapActive}
            setMensajeToast={setMensajeToast}
            setMostrarToast={setMostrarToast}
            vista="informe-calificaciones-estudiante-curso"
            dataEstudiantesNotas={dataEstudiantesNotas}
            dataColegio={dataColegio}
            dataCurso={dataCurso}
            periodoId={periodoId}
            
          />
        </Col>
      </Row>
      <NotificacionToast
        mensaje={mensajeToast}
        mostrar={mostrarToast}
        setMostrar={setMostrarToast}
      />
    </>
  );
};

export default NavInformeEstudianteCurso;
