import { useEffect, useState } from "react";
import { getAsistenciaClase } from "../services/PostsService";

const parseEstado = (estadoId) => {
  switch (estadoId) {
    case 1:
      return "presente";
    case 2:
      return "ausente";
    case 3:
      return "atrasado";
    case 4:
      return "retirado";
    case 5:
      return "fueraDeAula";
    case -3:
      return "lineaRoja";
    default:
      return "";
  }
};

const useGetAsistenciaClase = (curso_id, clase_id) => {
  const [asistencia, setAsistencia] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAsistencia = async () => {
      setCargando(true);
      setError(null);

      try {
        const params = { curso_id, clase_id };
        const response = await getAsistenciaClase(params);
        const data = response.data;
        const parsedData = data.map((item) => {
          const estudiante = item.estudiante;
          const asistenciaInfo = item.asistencia || {};
          return {
            id: estudiante.estudianteId,
            run: estudiante.run,
            runDv: estudiante.runDv,
            ipe: estudiante.ipe,
            ipeDv: estudiante.ipeDv,
            numLista: estudiante.numLista,
            numMatricula: estudiante.numMatricula,
            nombre: `${estudiante.nombrePrimario} ${estudiante.apellidoPrimario} ${estudiante.apellidoSecundario}`,
            estado: parseEstado(asistenciaInfo.asistenciaEstadoClaseId ?? 0),
            asistenciaId: asistenciaInfo.asistenciaId,
            asistenciaEstadoClaseId: asistenciaInfo.asistenciaEstadoClaseId || 0,
            claseFecha: asistenciaInfo.claseFecha,
            claseNumero: asistenciaInfo.claseNumero,
            claseId: asistenciaInfo.claseId,
            retirado: estudiante.retirado,
            fechaRetiro: estudiante.fechaRetiro,
            fechaMatricula: estudiante.fechaMatricula // Incluir la fecha de matrícula
          };
        });
        console.log('vengo desde el hook asistenciaClase:', data)
        setAsistencia(parsedData);
      } catch (error) {
        setError(error.message);
      } finally {
        setCargando(false);
      }
    };

    if (curso_id && clase_id) {
      fetchAsistencia();
    }
  }, [curso_id, clase_id]);

  return { asistencia, cargando, error };
};

export default useGetAsistenciaClase;
