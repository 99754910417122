import React from 'react';

export default function ColegioAdmin() {
    return (
        <div
            className="pt-0"> {/* Añade espacio en la parte superior para evitar que la tabla se oculte bajo otros componentes */}


            <div className="container mt-3"> {/* Contenedor para la tabla con margen superior */}
                <center><h2>Administración de Colegio</h2></center>
            </div>
        </div>
    );
};
