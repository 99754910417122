import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import useGetConfigHorarioCurso from '../../../hooks/useGetConfigHorarioCurso';
import useGetListadoAsignaturas from '../../../hooks/useGetListadoAsignaturas';
import {
  getConflictoHorarioDocente,
  getListadoDocentesAsignatura,
  getSalasSedeColegio,
  saveConfigHorarioCurso
} from '../../../services/PostsService';
import CheckboxInput from '../inputs/CheckboxInput';
import SelectSingle from '../inputs/SelectSingle';
import HorarioCurso from './HorarioCurso';
import ModalConfirmacionCambioBloque from './ModalConfirmacionCambioBloque';

const ContenidoHorarioCurso = ({ cursoSeleccionado, ocultarConfigCurso }) => {
  const [selectedAsignatura, setSelectedAsignatura] = useState(null);
  const [profesores, setProfesores] = useState([]);
  const [selectedProfesor, setSelectedProfesor] = useState(null);
  const [salas, setSalas] = useState([]);
  const [selectedSala, setSelectedSala] = useState(null);
  const [bloquesDestacados, setBloquesDestacados] = useState([]);
  const [bloquesConConflictos, setBloquesConConflictos] = useState([]);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [bloqueExistente, setBloqueExistente] = useState(null);
  const [nuevoBloque, setNuevoBloque] = useState(null);

  const { asignaturas, cargando: cargandoAsignaturas } = useGetListadoAsignaturas(cursoSeleccionado?.id);
  const { horario, setHorario, cargando: cargandoHorario, horaInicio, horaFin } = useGetConfigHorarioCurso(cursoSeleccionado?.id);

  useEffect(() => {
    if (asignaturas.length > 0 && !selectedAsignatura) {
      const primeraAsignatura = asignaturas[0];
      setSelectedAsignatura(primeraAsignatura);
      fetchProfesores(cursoSeleccionado?.id, primeraAsignatura.id);
    }
  }, [asignaturas, selectedAsignatura]);

  useEffect(() => {
    if (selectedProfesor && selectedProfesor.id) {
        obtenerConflictosHorario(selectedProfesor.id);
    } else {
        setBloquesDestacados([]);
        setBloquesConConflictos([]);
    }
}, [selectedProfesor, cursoSeleccionado]);

  const fetchProfesores = async (cursoId, asignaturaId) => {
    if (!cursoId || !asignaturaId) return;

    try {
      const response = await getListadoDocentesAsignatura({ curso_id: cursoId, asignatura_id: asignaturaId });
      const listadoProfesores = response.data.listadoDocentes;

      setProfesores([
        { id: null, name: "Sin docente" },
        ...listadoProfesores.map(docente => ({
          id: docente.docenteId,
          name: `${docente.docenteNombre} ${docente.docenteApellido}`
        }))
      ]);
      setSelectedProfesor({ id: null, name: "Sin docente" });
    } catch (error) {
      console.error("Error al cargar los profesores:", error);
      setProfesores([{ id: null, name: "Sin docente" }]);
    }
  };

  const fetchSalas = async () => {
    try {
      const response = await getSalasSedeColegio();
      const listadoSalas = response.data.colegioSalas;

      setSalas([
        { id: null, salaNombre: "Sin Sala" },
        ...listadoSalas
      ]);
      setSelectedSala({ id: null, salaNombre: "Sin Sala" });
    } catch (error) {
      console.error("Error al cargar las salas:", error);
      setSalas([{ id: null, salaNombre: "No hay salas disponibles" }]);
    }
  };

  useEffect(() => {
    if (selectedAsignatura) {
      fetchSalas();
    } else {
      setSalas([]);
      setSelectedSala(null);
    }
  }, [selectedAsignatura]);
  

  const obtenerConflictosHorario = async (docenteId) => {
    try {
        console.log("Enviando solicitud con parámetros:", { curso_id: cursoSeleccionado.id, docente_id: docenteId });
        const response = await getConflictoHorarioDocente({ curso_id: cursoSeleccionado.id, docente_id: docenteId });

        if (!response || !response.data) {
            console.warn("La respuesta de la API no contiene datos.");
            return;
        }

        // Extraemos los conflictos de la respuesta
        const conflictos = response.data.confDia.flatMap(dia =>
            dia.confBloques.map(bloque => ({
                dia: dia.dia,
                num_bloque: bloque.bloqueNum
            }))
        );

        console.log("Conflictos detectados:", conflictos);

        // Filtramos los bloques del horario y obtenemos el idHorario correcto
        const bloquesConConflictosDetectados = horario.filter(evento =>
            conflictos.some(conflicto =>
                evento.bloqueDia === conflicto.dia && evento.num_bloque === conflicto.num_bloque
            )
        ).map(bloque => ({
            idHorario: bloque.idHorario, // Aseguramos que se pase el idHorario correcto
            dia: bloque.bloqueDia,
            num_bloque: bloque.num_bloque
        }));

        console.log("Bloques con conflictos detectados (con idHorario):", bloquesConConflictosDetectados);

        // Actualizamos el estado para destacar los bloques con conflictos usando los idHorario correctos
        setBloquesDestacados(bloquesConConflictosDetectados.map(b => b.idHorario));
    } catch (error) {
        console.error("Error al obtener conflictos de horario:", error.response ? error.response.data : error.message);
        setBloquesConConflictos([]);
    }
};




  const handleClickBloque = (bloque) => {

    if (ocultarConfigCurso) {
      console.log("Acción no permitida: los docentes no pueden hacer clic en los eventos.");
      return; 
    }
    console.log("Bloque clickeado:", bloque);
    const tipoBloque = bloque.bloqueTipoNombre || bloque.type || bloque.title || '';
    
    if (tipoBloque === "Recreo" || tipoBloque === "Almuerzo") {
      console.log("Este bloque es de tipo Recreo o Almuerzo y no se puede modificar.");
      return;
    }
  
    const tieneDatosExistentes = bloque.asignaturaId || bloque.salaId || bloque.docenteId;
  
    if (!tieneDatosExistentes) {
      guardarBloque(bloque);
    } else {
      const bloqueExistenteConDatos = {
        ...bloque,
        asignaturaNombre: bloque.asignatura || 'Sin Asignatura',
        docenteNombre: (bloque.docenteNombre && bloque.docenteApellido)
          ? `${bloque.docenteNombre} ${bloque.docenteApellido}`
          : 'Sin Docente',
        salaNombre: bloque.sala || 'Sin Sala'
      };
  
      const nuevoBloqueConDatos = {
        idHorario: bloque.idHorario,
        bloqueDia: bloque.bloqueDia,
        bloqueOrden: bloque.bloqueOrden,
        num_bloque: bloque.num_bloque,
        start: bloque.start,
        end: bloque.end,
        asignaturaId: selectedAsignatura?.id || null,
        asignaturaNombre: selectedAsignatura?.nombre || selectedAsignatura?.name || 'Sin Asignatura',
        docenteId: selectedProfesor?.id || null,
        docenteNombre: selectedProfesor?.name || 'Sin Docente',
        salaId: selectedSala?.id || null,
        salaNombre: selectedSala?.salaNombre || 'Sin Sala'
      };
  
      console.log('Asignatura en nuevo bloque al hacer clic:', selectedAsignatura);
  
      setBloqueExistente(bloqueExistenteConDatos);
      setNuevoBloque(nuevoBloqueConDatos);
      setMostrarModal(true);
    }
  };

  const guardarBloque = async (bloque) => {
    const tipoBloque = bloque.bloqueTipoNombre || bloque.type || bloque.title || '';
    
    if (tipoBloque === "Recreo" || tipoBloque === "Almuerzo") {
      console.log("Este bloque es de tipo Recreo o Almuerzo y no se guardará.");
      return;
    }
  
    const bloqueData = {
      idHorario: bloque.idHorario || null,
      bloqueDia: bloque.bloqueDia || null,
      bloqueOrden: bloque.bloqueOrden || null,
      bloqueNum: bloque.num_bloque || null,
      bloqueIni: bloque.start ? moment(bloque.start).format('HH:mm') : null,
      bloqueFin: bloque.end ? moment(bloque.end).format('HH:mm') : null,
      bloqueId: bloque.bloqueId || null,
      bloqueTipoId: bloque.bloqueTipoId || null,
      bloqueTipoNombre: bloque.title || null,
      cursoId: cursoSeleccionado.id,
      cursoNombre: cursoSeleccionado.name,
      asignaturaId: selectedAsignatura?.id || null,
      asignaturaNombre: selectedAsignatura?.nombre || null,
      asignaturaColorFondo: selectedAsignatura?.colorFondo || null,
      docenteId: selectedProfesor?.id,
      docenteNombre: selectedProfesor?.name,
      salaId: selectedSala ? selectedSala.id : null,
      salaNombre: selectedSala ? selectedSala.salaNombre : null,
    };
  
    try {
      const response = await saveConfigHorarioCurso(bloqueData);
  
      const updatedBloque = {
        ...response.data,
        title: `${response.data.bloqueTipoNombre}: ${response.data.asignaturaNombre || 'Libre'}`,
        start: moment(bloque.start).toDate(),
        end: moment(bloque.end).toDate(),
        asignatura: response.data.asignaturaNombre || "Sin Asignatura",
        asignaturaColorFondo: response.data.asignaturaColorFondo || "#1E78FF",
        curso: response.data.cursoNombre || "Curso Desconocido",
        num_bloque: response.data.bloqueNum || null,
        sala: response.data.salaNombre || "Sin Sala",
        type: response.data.bloqueTipoNombre || "Otro",
      };
  
      setHorario((prevHorario) =>
        prevHorario.map((event) =>
          event.idHorario === response.data.idHorario ? updatedBloque : event
        )
      );
    } catch (error) {
      console.error('Error al guardar el horario:', error);
    }
  };

  const confirmarCambioBloque = () => {
    guardarBloque(nuevoBloque);
    setMostrarModal(false);
  };

  const diasAMostrar = horario.length > 0
    ? [...new Set(horario.map(event => moment(event.start).isoWeekday()))].sort()
    : [];

  const today = new Date();
  const minTime = horaInicio
    ? new Date(today.getFullYear(), today.getMonth(), today.getDate(), ...horaInicio.split(':').map(Number))
    : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 7, 0);

  const maxTime = horaFin
    ? new Date(today.getFullYear(), today.getMonth(), today.getDate(), ...horaFin.split(':').map(Number))
    : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 18, 0);

  useEffect(() => {
    console.log('Asignatura seleccionada ha cambiado:', selectedAsignatura);
  }, [selectedAsignatura]);

  return (
    <div style={{ padding: '0rem' }}>
      <Row>
        {!ocultarConfigCurso && (
          <Col 
            md={2} 
            style={{ 
              backgroundColor: '#f8f9fa', 
              padding: '1rem 1rem 1rem 2.5rem', 
              borderRight: '1px solid #dee2e6', 
              height: '100%', 
            }}
          >
            {cursoSeleccionado && (
              <>
                <h5 style={{ marginBottom: '1rem' }}>Asignatura</h5>
                <SelectSingle
                  hideLabel
                  placeholder="Selecciona una asignatura"
                  elements={asignaturas.map((asign) => ({ id: asign.id, name: asign.nombre }))}
                  selectedElement={selectedAsignatura?.id}
                  onSelect={(asignatura) => {
                    setSelectedAsignatura(asignatura || null);
                    if (asignatura) {
                      fetchProfesores(cursoSeleccionado.id, asignatura.id);
                    } else {
                      setProfesores([]);
                      setSelectedProfesor(null);
                    }
                  }}
                  labelKey="name"
                  idKey="id"
                  style={{ border: '1px solid var(--color-acento)', marginBottom: '1rem' }}
                  disabled={cargandoAsignaturas || asignaturas.length === 0}
                />

                {selectedAsignatura && (
                  <>
                    <Card style={{ marginTop: '1rem' }}>
                      <Card.Body className='p-3'>
                        <h6 style={{ marginBottom: '0.5rem' }}>Docentes</h6>
                        <div style={{ maxHeight: '150px', overflowY: 'auto', marginTop: '10px' }}>
                          {profesores.length > 0 ? (
                            profesores.map((profesor) => (
                              <CheckboxInput
                                key={profesor.id}
                                label={profesor.name}
                                name="profesor"
                                checked={selectedProfesor?.id === profesor.id}
                                onChange={() => setSelectedProfesor(profesor)}
                                controlId={`profesor-${profesor.id}`}
                              />
                            ))
                          ) : (
                            <CheckboxInput
                              label="No hay docente asociado"
                              name="profesor"
                              checked={true}
                              controlId="profesor-ninguno"
                            />
                          )}
                        </div>
                      </Card.Body>
                    </Card>

                    <Card style={{ marginTop: '1rem' }}>
                      <Card.Body className='p-3'>
                        <h6 style={{ marginBottom: '0.5rem' }}>Salas</h6>
                        <div style={{ maxHeight: '150px', overflowY: 'auto', marginTop: '10px' }}>
                          {salas.length > 0 ? (
                            salas.map((sala) => (
                              <CheckboxInput
                                key={sala.id}
                                label={sala.salaNombre}
                                name="sala"
                                checked={selectedSala?.id === sala.id}
                                onChange={() => setSelectedSala(sala)}
                                controlId={`sala-${sala.id}`}
                              />
                            ))
                          ) : (
                            <CheckboxInput
                              label="No hay salas disponibles"
                              name="sala"
                              checked={true}
                              controlId="sala-ninguna"
                            />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </>
                )}
              </>
            )}
          </Col>
        )}

        <Col md={ocultarConfigCurso ? 12 : 10} style={{ padding: '1rem', cursor: ocultarConfigCurso ? 'not-allowed' : 'default'}}>
          {cursoSeleccionado && selectedAsignatura && !cargandoHorario && (
            <HorarioCurso
              diasAMostrar={diasAMostrar}
              events={horario}
              minTime={minTime}
              maxTime={maxTime}
              bloquesDestacados={bloquesDestacados} 
              onClickBloque={handleClickBloque} 
            />
          )}
          {cargandoHorario && <p>Cargando horario...</p>}
        </Col>
      </Row>

      <ModalConfirmacionCambioBloque
        mostrarModal={mostrarModal}
        setMostrarModal={setMostrarModal}
        confirmarCambioBloque={confirmarCambioBloque}
        bloqueExistente={bloqueExistente}
        nuevoBloque={nuevoBloque}
      />
    </div>
  );
};

export default ContenidoHorarioCurso;
