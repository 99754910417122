import React, {Fragment} from 'react'

/// Page Title
import PageTitle from '../../layouts/PageTitle'

/// Images
import img1 from '../../../assets/images/big/img1.jpg'
import img2 from '../../../assets/images/big/img2.jpg'
import img3 from '../../../assets/images/big/img3.jpg'
import img4 from '../../../assets/images/big/img4.jpg'
import img5 from '../../../assets/images/big/img5.jpg'
import img6 from '../../../assets/images/big/img6.jpg'
import img7 from '../../../assets/images/big/img7.jpg'

/// Bootstrap
import {Card, Carousel, Col, Row} from 'react-bootstrap'

/// carousel data
const carousel1 = [img1, img2, img3]
const carousel2 = [
    {img: img2, text: 'First'},
    {img: img3, text: 'Second'},
    {img: img4, text: 'Third'},
]
const carousel3 = [img3, img4, img5]
const carousel5 = [
    {img: img5, text: 'First'},
    {img: img6, text: 'Second'},
    {img: img7, text: 'Third'},
]

const UiCarousel = () => {
    return (
        <Fragment>
            <PageTitle motherMenu='Bootstrap' activeMenu='Carousel'/>
            <Row>
                <Col xl={6}>
                    <Card>
                        <Card.Body className='p-4'>
                            <h4 className='card-title'>Slides only</h4>
                            <Carousel>
                                {carousel1.map((carousel, i) => (
                                    <Carousel.Item key={i}>
                                        <img
                                            src={carousel}
                                            className='d-block w-100'
                                            alt={`Slide ${i + 1}`}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={6}>
                    <Card>
                        <Card.Body className='p-4'>
                            <h4 className='card-title'>With Captions</h4>
                            <Carousel controls={false} indicators={false}>
                                {carousel2.map((carousel, i) => (
                                    <Carousel.Item key={i}>
                                        <img
                                            className='d-block w-100'
                                            src={carousel.img}
                                            alt={`${carousel.text} slide`}
                                        />
                                        <Carousel.Caption className=' d-none d-md-block'>
                                            <h5>{carousel.text} slide label</h5>
                                            <p>
                                                Nulla vitae elit libero, a pharetra augue mollis
                                                interdum.
                                            </p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={6}>
                    <Card>
                        <Card.Body className='p-4'>
                            <h4 className='card-title'>Slides only</h4>
                            <Carousel controls={false} indicators={false}>
                                {carousel3.map((carousel, i) => (
                                    <Carousel.Item key={i}>
                                        <img
                                            src={carousel}
                                            className='d-block w-100'
                                            alt={`Slide ${i + 1}`}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={6}>
                    <Card>
                        <Card.Body className='p-4'>
                            <h4 className='card-title '>Slides With indicators</h4>
                            <Carousel>
                                {carousel1.map((carousel, i) => (
                                    <Carousel.Item key={i}>
                                        <img
                                            src={carousel}
                                            className='d-block w-100'
                                            alt={`Slide ${i + 1}`}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={6}>
                    <Card>
                        <Card.Body className='p-4'>
                            <h4 className='card-title'>Slides With captions</h4>
                            <Carousel>
                                {carousel5.map((carousel, i) => (
                                    <Carousel.Item key={i}>
                                        <img
                                            className='d-block w-100'
                                            src={carousel.img}
                                            alt={`${carousel.text} slide`}
                                        />
                                        <Carousel.Caption className=' d-none d-md-block'>
                                            <h5>{carousel.text} slide label</h5>
                                            <p>
                                                Nulla vitae elit libero, a pharetra augue mollis
                                                interdum.
                                            </p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default UiCarousel
