import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from "react";
import { Container, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import estilosEstado from "../../objetoEstilos/estiloEstados";
import { estilosEstadoAdicionales } from "../../objetoEstilos/estiloEstudiante";
import Avatar from "../avatar/Avatar";

const estilosEncabezado = {
  default: {
    backgroundColor: "var(--color-header)",
    color: "var(--color-texto-oscuro)",
    fontWeight: "500",
    position: "sticky",
    top: 0,
    zIndex: 10,
  },
  estudiante: {
    backgroundColor: "var(--color-header)",
    color: "rgb(28, 40, 51)",
    fontWeight: "500",
    borderRight: "1px solid lightgrey",
    position: "sticky",
    top: 0,
    zIndex: 10,
    textAlign: "center",
    width: "150px",
  },
};

const iconoEstilo = {
  marginRight: "10px",
};

const estilosFila = {
  default: {},
  hover: {
    backgroundColor: "var(--color-doble-hover)",
  },
};

const TablaAsistenciaDiariaAsignaturaProfe = ({
  estudiantes = [],
  actualizarEstadoEstudiante,
}) => {
  const [datos, setDatos] = useState([]);
  const [tieneMas, setTieneMas] = useState(true);
  const [indiceSiguiente, setIndiceSiguiente] = useState(0);
  const containerRef = useRef(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  useEffect(() => {
    console.log("Datos recibidos en TablaAsistenciaDiariaAsignaturaProfe:", estudiantes);
  }, [estudiantes]);

  useEffect(() => {
    if (estudiantes && estudiantes.length > 0) {
      const estudiantesOrdenados = [...estudiantes].sort((a, b) => {
        if (a.numLista !== null && b.numLista === null) return -1;
        if (a.numLista === null && b.numLista !== null) return 1;
        return a.numLista - b.numLista;
      });
      setDatos(estudiantesOrdenados.slice(0, 50));
      setIndiceSiguiente(50);
      setTieneMas(estudiantesOrdenados.length > 50);
    }
    console.log("vengo de la tabla bloque diaria:", estudiantes);
  }, [estudiantes]);

  const cargarMasDatos = () => {
    if (indiceSiguiente < estudiantes.length) {
      const nuevosEstudiantes = estudiantes.slice(
        indiceSiguiente,
        indiceSiguiente + 50
      );
      setDatos((prevDatos) => [...prevDatos, ...nuevosEstudiantes]);
      setIndiceSiguiente(indiceSiguiente + 50);
      setTieneMas(indiceSiguiente + 50 < estudiantes.length);
    }
  };

  const cambiarEstadoEstudiante = (id, nuevoEstado) => {
    const estadoNumerico = {
      presente: 1,
      ausente: 2,
      atrasado: 3,
      retirado: 4,
      fueraDeAula: 5,
    };

    const datosActualizados = datos.map((est) => {
      if (est.id === id) {
        return {
          ...est,
          estado: nuevoEstado,
          asistenciaEstadoClaseId: estadoNumerico[nuevoEstado],
          asistenciaId: est.asistenciaId,
          estudianteId: est.id,
        };
      }
      return est;
    });
    setDatos(datosActualizados);
    actualizarEstadoEstudiante(datosActualizados);
  };

  const esIgualOPosteriorAFechaRetiro = (claseFecha, fechaRetiro) => {
    if (!fechaRetiro || !claseFecha) return false;
    const fechaClase = new Date(claseFecha);
    const fechaRetiroObj = new Date(fechaRetiro);
    return fechaClase >= fechaRetiroObj;
  };

  const esAntesDeFechaMatricula = (claseFecha, fechaMatricula) => {
    if (!fechaMatricula || !claseFecha) return false;
    const fechaClaseObj = new Date(claseFecha);
    const fechaMatriculaObj = new Date(fechaMatricula);
    return fechaClaseObj < fechaMatriculaObj;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && containerRef.current.scrollTop + containerRef.current.clientHeight >= containerRef.current.scrollHeight - 20) {
        cargarMasDatos();
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [indiceSiguiente, datos]);

  return (
    <Container fluid className="bg-white px-3 pt-0">
      <div className="card mb-0">
        <div className="card-body p-0">
          <div className="table-responsive" style={{ maxHeight: '70dvh', overflow: 'auto' }} ref={containerRef}>
            <Table className="table mb-0">
              <thead>
                <tr>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-numero-lista">Número de lista</Tooltip>}
                  >
                    <th style={{ ...estilosEncabezado.default, width: "50px" }} className="text-center">N°</th>
                  </OverlayTrigger>
                  <th style={estilosEncabezado.estudiante} className="text-center">Estudiante</th>
                  {Object.keys(estilosEstado).filter(tipoEstado => tipoEstado !== 'pendiente').map((tipoEstado) => (
                    <th key={tipoEstado} className="text-center" style={estilosEncabezado.default}>
                      <i className={`${estilosEstado[tipoEstado].icon}`} style={{ color: estilosEstado[tipoEstado].iconColor, ...iconoEstilo }}></i>
                      {estilosEstado[tipoEstado].description}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {datos.map((estudiante, indice) => {
                  const esAntesDeMatricula = esAntesDeFechaMatricula(estudiante.claseFecha, estudiante.fechaMatricula);
                  const mostrarLineaRetiro = estudiante.asistenciaEstadoClaseId === -3; // Verificación para mostrar la línea roja
                  
                  return (
                    <tr 
                      key={estudiante.id} 
                      style={{ 
                        cursor: mostrarLineaRetiro || esAntesDeMatricula ? 'not-allowed' : 'default',
                        ...(hoveredRow === indice ? estilosFila.hover : estilosFila.default),
                      }}
                      onMouseEnter={() => setHoveredRow(indice)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      <td className="text-center" style={{ width: "50px", position: 'relative' }}>
                        {estudiante.numLista !== null ? (
                          estudiante.numLista
                        ) : estudiante.numMatricula !== null ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id={`tooltip-${estudiante.id}`}>{estilosEstadoAdicionales.matriculadoSinNumeroLista.description}</Tooltip>}
                          >
                            <i className={`${estilosEstadoAdicionales.matriculadoSinNumeroLista.icon}`} style={{ color: estilosEstadoAdicionales.matriculadoSinNumeroLista.iconColor, fontSize: estilosEstadoAdicionales.matriculadoSinNumeroLista.fontSize }}></i>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id={`tooltip-${estudiante.id}`}>{estilosEstadoAdicionales.sinNumeroMatriculaYLista.description}</Tooltip>}
                          >
                            <i className={`${estilosEstadoAdicionales.sinNumeroMatriculaYLista.icon}`} style={{ color: estilosEstadoAdicionales.sinNumeroMatriculaYLista.iconColor, fontSize: estilosEstadoAdicionales.sinNumeroMatriculaYLista.fontSize }}></i>
                          </OverlayTrigger>
                        )}
                        {mostrarLineaRetiro && (
                          <div style={{ position: 'absolute', top: '50%', left: 0, width: '100%', borderBottom: '2px solid red', zIndex: 2 }}></div>
                        )}
                      </td>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          estudiante.retirado ? (
                            <Tooltip id={`tooltip-retirado-${estudiante.id}`}>
                              Estudiante retirado
                            </Tooltip>
                          ) : <></>
                        }
                      >
                        <td className="text-left" style={{ borderRight: '1px solid lightgrey', width: "350px", position: 'relative', display: 'flex', alignItems: 'center', gap:5 }}>
                          <Avatar width={'30px'} height={'30px'} run={estudiante.run || estudiante.ipe} allowPreview />
                          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            {estudiante.nombre}
                          </span>
                          {mostrarLineaRetiro && (
                            <div style={{ position: 'absolute', top: '50%', left: 0, width: '100%', borderBottom: '2px solid red', zIndex: 9 }}></div>
                          )}
                        </td>
                      </OverlayTrigger>
                      {Object.keys(estilosEstado).filter(tipoEstado => tipoEstado !== 'pendiente').map((tipoEstado) => (
                        <td key={tipoEstado} className="text-center" style={{ position: 'relative', backgroundColor: esAntesDeMatricula ? 'var(--color-doble-hover)' : 'transparent' }}>
                          <div
                            className="status-square d-flex justify-content-center align-items-center"
                            style={{
                              width: "30px",
                              height: "30px",
                              border: `2px solid ${esAntesDeMatricula ? 'var(--color-doble-hover)' : 'lightgrey'}`,
                              cursor: mostrarLineaRetiro || esAntesDeMatricula ? "not-allowed" : "pointer",
                              margin: 'auto',
                              backgroundColor: esAntesDeMatricula ? 'var(--color-doble-hover)' : 'transparent',
                            }}
                            onClick={!mostrarLineaRetiro && !esAntesDeMatricula ? () => cambiarEstadoEstudiante(estudiante.id, tipoEstado) : undefined}
                          >
                            {estudiante.estado === tipoEstado && (
                              <i className={`${estilosEstado[tipoEstado].icon}`} style={{ color: estilosEstado[tipoEstado].iconColor, fontSize: "20px" }}></i>
                            )}
                            {mostrarLineaRetiro && <div style={{ position: 'absolute', top: '50%', left: 0, width: '100%', borderBottom: '2px solid red', zIndex: 1 }}></div>}
                          </div>
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TablaAsistenciaDiariaAsignaturaProfe;
