import React, { useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

function CustomTooltip({ children, tooltipMsg }) {

  const popover = (
    <Popover
      id="popover-basic"
      className="custom-popover"
      style={{ border: 'none', padding: 0, maxWidth: 'none',}}
    >
      <Popover.Body
        as={'div'}
        style={{
          backgroundColor: 'rgba(0,0,0,0.65)',
          color: 'white',
          padding: '5px 10px',  // Ajusta el padding para que se vea mejor
          whiteSpace: 'nowrap',
          borderRadius: 0 
        }}
      >
        {tooltipMsg}
      </Popover.Body>
    </Popover>
  );


  useEffect(() => {
    const handleScroll = () => {
      window.dispatchEvent(new Event("resize")); // Esto fuerza a Popper.js a recalcular la posición
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 150 }}
        overlay={popover}
      >
        {children}
      </OverlayTrigger>
    </>
  );
}

export default CustomTooltip;
