import { useEffect, useState } from 'react';
import { getSubvencionAnualEstudiantes } from '../services/PostsService';

const useGetSubvencionAnualEstudiantes = (cursoId) => {
    const [estudiantes, setEstudiantes] = useState([]);
    const [cursoAsistencias, setCursoAsistencias] = useState({});
    const [meses, setMeses] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEstudiantes = async () => {
            setCargando(true);
            setError(null);

            try {
                const params = { curso_id: cursoId };
                const response = await getSubvencionAnualEstudiantes(params);
                const data = response.data;

                if (!data || !data.asistenciasEstudiantes || !data.asistenciaCurso || !data.asistenciaCurso.asistenciaCurso) {
                    throw new Error('Estructura de datos inválida');
                }

                const mesesDisponibles = data.asistenciaCurso.asistenciaCurso.map(asistencia => asistencia.mesNombre).filter(mes => mes !== null);
                setMeses(mesesDisponibles);

                const cursoAsistencias = data.asistenciaCurso.asistenciaCurso.reduce((acc, asistencia) => {
                    if (asistencia.mesNombre) {
                        acc[asistencia.mesNombre] = asistencia.asistenciaPorcentaje;
                    }
                    return acc;
                }, {});

                cursoAsistencias['resumenAnual'] = data.asistenciaCurso.asistenciaPorcentajeCursoAnual || 0;

                const estudiantesFormateados = data.asistenciasEstudiantes.map(est => {
                    if (!est.asistenciaMensual || !est.asistenciaAnual) {
                        return null;
                    }

                    const asistencias = est.asistenciaMensual.reduce((acc, asistencia) => {
                        const mesNombre = data.asistenciaCurso.asistenciaCurso.find(mesObj => mesObj.mes === asistencia.mes)?.mesNombre;
                        if (mesNombre) {
                            acc[mesNombre] = asistencia.asistenciaPorcentaje;
                        }
                        return acc;
                    }, {});

                    asistencias['resumenAnual'] = est.asistenciaAnual.asistenciaPorcentajeAnual || 0;

                    return {
                        id: est.estudiante.estudianteId,
                        run: est.estudiante.run,
                        runDv: est.estudiante.runDv,
                        ipe: est.estudiante.ipe,
                        ipeDv: est.estudiante.ipeDv,
                        numLista: est.estudiante.numLista,
                        numMatricula: est.estudiante.numMatricula, // Añadir número de matrícula
                        fechaMatricula: est.estudiante.fechaMatricula, // Añadir fecha de matrícula
                        nombre: `${est.estudiante.nombrePrimario} ${est.estudiante.apellidoPrimario} ${est.estudiante.apellidoSecundario}`,
                        asistencias,
                        retirado: est.estudiante.retirado // Asegúrate de incluir el estado retirado
                    };
                }).filter(est => est !== null);

                setEstudiantes(estudiantesFormateados);
                setCursoAsistencias(cursoAsistencias);
            } catch (error) {
                console.error('Error al cargar los estudiantes:', error);
                setError(error.response ? error.response.data.message : error.message);
            } finally {
                setCargando(false);
            }
        };

        if (cursoId) {
            fetchEstudiantes();
        }
    }, [cursoId]);

    return { estudiantes, cursoAsistencias, meses, cargando, error };
};

export default useGetSubvencionAnualEstudiantes;
