import React, { useCallback, useEffect, useState } from "react";
import Title from "../../../../components/titles/Title";
import { Col, Row } from "react-bootstrap";
import SelectMultiple from "../../../../components/inputs/SelectMultiple";
import SelectSingle from "../../../../components/inputs/SelectSingle";
import TextInput from "../../../../components/inputs/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { getFuncionesDocente } from "../../../../../services/PostsService";
import { setFuncionesDocente } from "../../../../../store/actions/refDataActions";

function TabDocente({
  ensenanzas,
  sectores,
  asignaturas,
  tabProfesor,
  setTabProfesor,
  funcionario,
}) {
  const funcionesDocenteState = useSelector(
    (state) => state.refDataReducer.funcionesDocente
  );
  const dispatch = useDispatch();

  const [listaFuncionesProfesor, setListaFuncionesProfesor] = useState(
    funcionesDocenteState
  );

  // Inicializa estados para los selectores múltiples
  const [selectedEnsenanza, setSelectedEnsenanza] = useState(
    tabProfesor.ensenanzaListado || []
  );

  const handleSelectEnsenanza = useCallback(
    (updatedSelection) => {
      setSelectedEnsenanza(updatedSelection);

      setTabProfesor((prev) => ({
        ...prev,
        ensenanzaListado: ensenanzas.map((ens) => {
          const isSelected = updatedSelection.some((selected) => selected.id === ens.ensenanzaId);
          return {
            id: ens.id,
            ensenanzaId: ens.ensenanzaId,
            ensenanzaNombre: ens.ensenanzaNombre,
            profesorEnsenanzaEnabled: isSelected, // true si está seleccionado, false si no
          };
        }),
      }));
    },
    [ensenanzas, setTabProfesor]
  );
  

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const promises = [];
        if (funcionesDocenteState.length === 0)
          promises.push(getFuncionesDocente());
        const responses = await Promise.all(promises);
        if (funcionesDocenteState.length === 0 && responses[0]) {
          dispatch(setFuncionesDocente(responses[0]));
          setListaFuncionesProfesor(responses[0]);
        } else {
          setListaFuncionesProfesor(funcionesDocenteState);
        }
      } catch (error) {
        console.log("Error al cargar datos iniciales:", error);
      }
    };
    loadInitialData();
  }, [dispatch, funcionesDocenteState]);

  // Sincronización inicial: solo se ejecuta una vez
  useEffect(() => {
    if (tabProfesor.ensenanzaListado && tabProfesor.ensenanzaListado.length > 0) {
      const initialSelection = tabProfesor.ensenanzaListado
        .filter((ens) => ens.profesorEnsenanzaEnabled) // Solo los elementos habilitados
        .map((ens) => ({
          id: ens.ensenanzaId, 
          name: ens.ensenanzaNombre,
        }));
      setSelectedEnsenanza(initialSelection);
    }
  }, [tabProfesor.ensenanzaListado]);


  
  return (
    <article
      className="d-flex flex-column align-items-center py-2"
      style={{ height: "75dvh" }}
    >
      <div className="d-flex w-100  ">
        <div className="w-50  d-flex flex-column align-items-end gap-1">
          <Col className="col-12 col-md-6">
          <SelectMultiple
              placeholder="Enseñanza"
              idKey="id"
              labelKey="name"
              elements={ensenanzas.map((ens) => ({
                id: ens.ensenanzaId, // Cambiamos aquí para que coincida con el `ensenanzaId`
                name: ens.ensenanzaNombre,
              }))}
              onSelect={handleSelectEnsenanza}
              selectedElements={selectedEnsenanza}
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
                width: "100%",
              }}
            />
          </Col>

          {/* Función Primaria */}
          <Col className="col-12 col-md-6">
            <SelectSingle
              controlId="funcionPrimaria"
              placeholder="Función Primaria"
              elements={listaFuncionesProfesor.map((funcion) => ({
                id: funcion.id,
                name: funcion.funcionNombre,
              }))}
              idKey="id"
              labelKey="name"
              selectedElement={tabProfesor.funcionPrimariaId}
              onSelect={(funcion) => {
                setTabProfesor((prev) => ({
                  ...prev,
                  funcionPrimariaId: funcion?.id,
                }));
              }}
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
            />
          </Col>

          {/* Función Secundaria */}
          <Col className="col-12 col-md-6">
            <SelectSingle
              controlId="funcionSecundaria"
              placeholder="Función Secundaria"
              elements={listaFuncionesProfesor.map((funcion) => ({
                id: funcion.id,
                name: funcion.funcionNombre,
              }))}
              idKey="id"
              labelKey="name"
              selectedElement={tabProfesor.funcionSecundariaId}
              onSelect={(funcion) => {
                setTabProfesor((prev) => ({
                  ...prev,
                  funcionSecundariaId: funcion?.id,
                }));
              }}
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
            />
          </Col>

          {/* Sector Función 1 */}
          <Col className="col-12 col-md-6">
      
            <SelectSingle
              controlId="sectorFuncion1"
              placeholder="Sector Función 1"
              elements={sectores.map((sector) => ({
                id: sector.sectorId,
                name: sector.sectorNombre,
              }))}
              idKey="id"
              labelKey="name"
              selectedElement={tabProfesor.funcionUnoSector}
              onSelect={(sector) => {
                setTabProfesor((prev) => ({
                  ...prev,
                  funcionUnoSector: sector?.id,
                }));
              }}
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
            />
          </Col>

          {/* Sector Función 2 */}
          <Col className="col-12 col-md-6">
          

            <SelectSingle
              controlId="sectorFuncion2"
              placeholder="Sector Función 2"
              elements={sectores.map((sector) => ({
                id: sector.sectorId,
                name: sector.sectorNombre,
              }))}
              idKey="id"
              labelKey="name"
              selectedElement={tabProfesor.funcionDosSector}
              onSelect={(sector) => {
                setTabProfesor((prev) => ({
                  ...prev,
                  funcionDosSector: sector?.id,
                }));
              }}
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
            />
          </Col>

          {/* Otros campos de texto */}
          <Col className="col-12 col-md-6">
            <TextInput
              value={tabProfesor.horasIngles}
              label={"Horas Inglés"}
              placeholder="Horas Inglés"
              onChange={(e) =>
                setTabProfesor((prev) => ({
                  ...prev,
                  horasIngles: e.target.value,
                }))
              }
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
              type="numeric"
              minValue={0}
              maxValue={44}
            />
          </Col>
        </div>
        <div className="w-50 d-flex flex-column gap-1">
          <Col className="col-12 col-md-6">
            <TextInput
              value={tabProfesor.horasAula}
              label={"Horas Aula"}
              placeholder="Horas Aula"
              onChange={(e) =>
                setTabProfesor((prev) => ({
                  ...prev,
                  horasAula: e.target.value,
                }))
              }
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
              type="numeric"
              minValue={0}
              maxValue={44}
            />
          </Col>
          <Col className="col-12 col-md-6">
            <TextInput
              value={tabProfesor.horasTecPedaFuncion1}
              label={"Horas Técnico-Pedagógicas Función 1"}
              placeholder="Horas Técnico-Pedagógicas Función 1"
              onChange={(e) =>
                setTabProfesor((prev) => ({
                  ...prev,
                  horasTecPedaFuncion1: e.target.value,
                }))
              }
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
              type="numeric"
              minValue={0}
              maxValue={44}
            />
          </Col>
          <Col className="col-12 col-md-6">
            <TextInput
              value={tabProfesor.horasTecPedaFuncion2}
              label={"Horas Técnico-Pedagógicas Función 2"}
              placeholder="Horas Técnico-Pedagógicas Función 2"
              onChange={(e) =>
                setTabProfesor((prev) => ({
                  ...prev,
                  horasTecPedaFuncion2: e.target.value,
                }))
              }
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
              type="numeric"
              minValue={0}
              maxValue={44}
            />
          </Col>
          {/* Sub-Sector Función 1 */}
          <Col className="col-12 col-md-6">
        

            <SelectSingle
              controlId="subSectorFuncion1"
              placeholder="Sub-Sector Función 1"
              elements={asignaturas.map((asignatura) => ({
                id: asignatura.id,
                name:
                  asignatura.asignaturaNombre + " - " + asignatura.gradoNombre,
              }))}
              idKey="id"
              labelKey="name"
              selectedElement={tabProfesor.funcionUnoSubsector}
              onSelect={(subsector) => {
                setTabProfesor((prev) => ({
                  ...prev,
                  funcionUnoSubsector: subsector?.id,
                }));
              }}
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
            />
          </Col>

          {/* Sub-Sector Función 2 */}
          <Col className="col-12 col-md-6">
       
            <SelectSingle
              controlId="subSectorFuncion2"
              placeholder="Sub-Sector Función 2"
              elements={asignaturas.map((asignatura) => ({
                id: asignatura.id,
                name:
                  asignatura.asignaturaNombre + " - " + asignatura.gradoNombre,
              }))}
              idKey="id"
              labelKey="name"
              selectedElement={tabProfesor.funcionDosSubsector}
              onSelect={(subsector) => {
                setTabProfesor((prev) => ({
                  ...prev,
                  funcionDosSubsector: subsector?.id,
                }));
              }}
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
            />
          </Col>
        </div>
      </div>
    </article>
  );
}

export default TabDocente;
