import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getListadoAsignaturas } from '../services/PostsService';

const useGetAsignaturaPorCodigo = (cursoId) => {
    const [asignaturas, setAsignaturas] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (!cursoId) {
            return;
        }

        const obtenerAsignaturas = async () => {
            setCargando(true);
            try {
                let params = { curso_id: cursoId };
                const isImportarCalificacionesView = location.pathname.includes('/importar-calificaciones');

                if (isImportarCalificacionesView) {
                    const isSeguro = JSON.parse(localStorage.getItem('isSeguro') || 'false');
                    if (isSeguro) {
                        // console.log('El usuario es docente seguro, añadiendo parámetro forDocente');
                        params.forDocente = true;
                    }
                }

                // console.log('Parámetros enviados a getListadoAsignaturas:', params);
                // console.log('Es vista de importar calificaciones:', isImportarCalificacionesView);

                const response = await getListadoAsignaturas(params);
                // console.log('Asignaturas obtenidas:', response.data);
                setAsignaturas(response.data);
            } catch (err) {
                // console.error("Error al obtener las asignaturas:", err);
                setError('Error al obtener las asignaturas: ' + err.message);
            } finally {
                setCargando(false);
            }
        };

        obtenerAsignaturas();
    }, [cursoId, location]);

    return { asignaturas, cargando, error };
};

export default useGetAsignaturaPorCodigo;