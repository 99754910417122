import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ButtonMultifuncional from '../buttonpersonalizado/ButtonMultifuncional';
import DesplegableConBusquedaMultiple from '../desplegableConBusqueda/DesplegableBusquedaMultiple';
import NotificacionToast from '../notificacion/NotificacionToast';

const NavPorcentajeAsistenciaAnualPorCurso = ({ cursos, cursosSeleccionados, onCursoSelect, cargando, error, isHeatmapActive, setIsHeatmapActive }) => {
  const [mostrarToast, setMostrarToast] = useState(false);
  const [mensajeToast, setMensajeToast] = useState('');

  const handleDownloadError = (message) => {
    setMensajeToast(message);
    setMostrarToast(true);
  };

  const estilos = {
    rowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
      backgroundColor: '#fff',
    },
    colCentrado: {
      flex: 1,
      textAlign: 'center',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    titulo: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '34px',
      color: 'rgb(28, 40, 51)',
      marginBottom: 0,
    },
  };

  if (cargando) {
    return <div>Cargando cursos...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
 
      <Row style={estilos.rowContainer} className='m-0 px-0 py-3'>
        <Col xs="auto" className="d-flex align-items-center">
          <DesplegableConBusquedaMultiple
            elementos={cursos}
            placeholder="Curso"
            selectedElements={cursosSeleccionados}
            onSelect={onCursoSelect}
          />
        </Col>
        <Col xs="auto" style={estilos.colCentrado}>
          <h5 style={estilos.titulo}>% Asistencia Subvención Anual por Curso</h5>
        </Col>
        <Col xs="auto" className="ms-auto d-flex align-items-center">
          <ButtonMultifuncional
            tablaId="tablaAsistencia"
            isHeatmapActive={isHeatmapActive}
            setIsHeatmapActive={setIsHeatmapActive}
            setMensajeToast={setMensajeToast}
            setMostrarToast={setMostrarToast}
            vista="porcentaje-asistencia-cursos"  // Vista actual
          />
        </Col>
      </Row>
      <NotificacionToast mensaje={mensajeToast} mostrar={mostrarToast} setMostrar={setMostrarToast} />
    </>
  );
};

export default NavPorcentajeAsistenciaAnualPorCurso;
