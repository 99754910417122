import React from 'react'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import { Form, Row } from 'react-bootstrap'

function TabSocioeconomico({
  valoresEstudiante,
  setValoresEstudiante
}) {
  return (
    <section style={{ display: "flex", justifyContent: "center" }}>
              <Form style={{ height: "55dvh", width: "100%" }}>
                <Row style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      marginTop: "1rem",
                    }}
                  >
                    <CheckboxInput
                      controlId="PIE"
                      label="Estudiante PIE"
                      checked={valoresEstudiante.esPie}
                      onChange={(e) =>
                        setValoresEstudiante((prevValues) => ({
                          ...prevValues,
                          esPie: e.target.checked,
                        }))
                      }
                      style={{ color: "var(--color-texto-oscuro)" }}
                    />

                    <CheckboxInput
                      controlId="estudiantePrioritario"
                      label="Estudiante Prioritario"
                      checked={valoresEstudiante.esPrioritario}
                      onChange={(e) =>
                        setValoresEstudiante((prevValues) => ({
                          ...prevValues,
                          esPrioritario: e.target.checked,
                        }))
                      }
                      style={{ color: "var(--color-texto-oscuro)" }}
                    />
                    <CheckboxInput
                      controlId="estudiantePreferente"
                      label="Estudiante Preferente"
                      checked={valoresEstudiante.esPreferente}
                      onChange={(e) =>
                        setValoresEstudiante((prevValues) => ({
                          ...prevValues,
                          esPreferente: e.target.checked,
                        }))
                      }
                      style={{ color: "var(--color-texto-oscuro)" }}
                    />
                    <CheckboxInput
                      controlId="estudianteNEE"
                      label="Estudiante NEE"
                      // checked={valoresEstudiante.nee}
                      checked={false}
                      onChange={(e) =>
                        setValoresEstudiante((prevValues) => ({
                          ...prevValues,
                          nee: e.target.checked,
                        }))
                      }
                      style={{ color: "var(--color-texto-oscuro)" }}
                      disabled
                    />
                  </div>
                </Row>
              </Form>
            </section>
  )
}

export default TabSocioeconomico