import { useEffect, useState } from 'react';
import { getHorarioDocente } from '../services/PostsService';

const useGetHorarioDocente = () => {
    const [eventos, setEventos] = useState([]);
    const [horaIniMin, setHoraIniMin] = useState("");
    const [horaFinMax, setHoraFinMax] = useState("");
    const [cargando, setCargando] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
            setCargando(true);
            setError(null);

            try {
                const response = await getHorarioDocente();

                if (response.data && typeof response.data === 'object') {
                    const eventosFormateados = [];

                    const agregarEventos = (eventos, tipo) => {
                        if (Array.isArray(eventos)) {
                            eventos.forEach(event => {
                                eventosFormateados.push({
                                    id: event.claseId,
                                    cursoId: event.cursoId,
                                    asignaturaId: event.asignaturaId,
                                    asignatura: event.asignaturaNombre,
                                    curso: event.cursoNombre,
                                    title: tipo === 'Clase' ? `${event.asignaturaNombre} (${event.cursoNombre})` : event.bloqueTipoNombre,
                                    start: new Date(`${event.bloqueFecha}T${event.bloqueIni}`),
                                    end: new Date(`${event.bloqueFecha}T${event.bloqueFin}`),
                                    sala: event.sala,
                                    num_bloque: event.bloqueNum,
                                    type: tipo,
                                    allDay: false,
                                    color: event.color,
                                    asignaturaColorFondo: event.asignaturaColorFondo || '#1E78FF', // Usar el color acento específico
                                    estadoClaseId: event.estadoClaseId
                                });
                            });
                        }
                    };

                    agregarEventos(response.data.clases, 'Clase');
                    agregarEventos(response.data.almuerzos, 'Almuerzo');
                    agregarEventos(response.data.recreos, 'Recreo');

                    setEventos(eventosFormateados);
                    setHoraIniMin(response.data.horaIniMin);
                    setHoraFinMax(response.data.horaFinMax);
                } else {
                    throw new Error('La respuesta de la API no contiene los eventos esperados');
                }
            } catch (error) {
                console.error('Error al cargar los eventos:', error);
                setError(error.toString());
            } finally {
                setCargando(false);
            }
        };

        fetchEvents();
    }, []);

    return { eventos, horaIniMin, horaFinMax, cargando, error };
};

export default useGetHorarioDocente;
