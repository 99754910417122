import React, { useState } from "react";
import { Dropdown, FormGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt, FaTimes } from "react-icons/fa";

const styles = {
  boton: {
    border: "1px solid lightgray",
    color: "gray",
    backgroundColor: "white",
    width: "55%",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "5px",
  },
  input: {
    border: "none",
    outline: "none",
    width: "100%",
    height: "100%",
    padding: "0",
  },
  iconoCalendario: {
    marginLeft: "10px",
    cursor: "pointer",
    fontSize: "1.2rem",
    color: "#007bff",
  },
  menuDesplegable: {
    maxHeight: "350px",
    overflowY: "auto",
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "1rem",
    borderRadius: "0.25rem",
    width: "fit-content",
    zIndex: 25,
  },
};

function RangoFechaSelector({
  placeholder = "Selecciona un rango de fechas",
  onSelect,
  dropDirection = "down",
  style = {},
  disabled = false,
}) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [manualInput, setManualInput] = useState("");
  const [hoveringIcon, setHoveringIcon] = useState(false);

  // Manejar la selección de fechas
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    // Actualizar el input manualmente
    setManualInput(
      `${start ? start.toLocaleDateString("en-GB") : ""} - ${
        end ? end.toLocaleDateString("en-GB") : ""
      }`
    );

    // Aplicar automáticamente cuando ambas fechas estén seleccionadas
    if (start && end) {
      onSelect({ start, end });
      setMenuVisible(false); // Cerrar el menú después de seleccionar
    }
  };

  // Limpiar las fechas seleccionadas
  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    setManualInput("");
    onSelect({ start: null, end: null }); // Notificar el cambio para resetear la tabla
  };

  return (
    <FormGroup>
      <Dropdown
        className="dropdown-elemento m-0 p-0"
        show={menuVisible}
        onToggle={() => setMenuVisible(!menuVisible)}
        drop={dropDirection}
      >
        <Dropdown.Toggle
          style={{
            ...styles.boton,
            ...style,
          }}
          disabled={disabled}
        >
          <input
            type="text"
            value={manualInput}
            readOnly // Evitar que el usuario escriba manualmente
            placeholder={placeholder}
            style={styles.input}
          />
          {/* Cambiar el ícono solo si hay fechas seleccionadas y está en hover */}
          {hoveringIcon && manualInput ? (
            <FaTimes
              style={styles.iconoCalendario}
              onClick={clearDates}
              onMouseEnter={() => setHoveringIcon(true)}
              onMouseLeave={() => setHoveringIcon(false)}
            />
          ) : (
            <FaCalendarAlt
              style={styles.iconoCalendario}
              onClick={() => setMenuVisible(!menuVisible)} // Para abrir el calendario al hacer clic en el ícono
              onMouseEnter={() => setHoveringIcon(true)}
              onMouseLeave={() => setHoveringIcon(false)}
            />
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={styles.menuDesplegable}
          align="start"
          show={menuVisible}
        >
          <div>
            {/* DatePicker para seleccionar el rango de fechas */}
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              monthsShown={2}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </FormGroup>
  );
}

export default RangoFechaSelector;
