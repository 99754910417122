import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  OverlayTrigger,
  Table,
  Tooltip
} from "react-bootstrap";
import { createRoot } from "react-dom/client";
import useGetInformeUnidadesGlobal from '../../../hooks/useGetInformeUnidadesGlobal';

import { esRunOIpe, formatearIPEIPA, formatearNotaConComa, formatearRUN, validarRUN } from "../../../util/util";
import estilosEstado from "../../objetoEstilos/estiloEstados";
import Avatar from "../avatar/Avatar";
import CustomTooltip from "../tooltip/Tooltip";
import InformeCalificacionesGaspar from "./InformeCalificacionesGaspar";

const estilos = {
  container: {
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start", 
    alignItems: "center",
  },
  tableContainer: {
    overflowX: "auto",
    flexGrow: 0,
    width: "100%",
  },
  table: {
    width: "100%",
    tableLayout: "auto",
  },
  thead: {
    backgroundColor: "var(--color-header)",
  },
  th: {
    color: "var(--color-texto-oscuro)",
    textAlign: "center",
    verticalAlign: "middle",
    height: "45px",
    padding: "8px",
    whiteSpace: "nowrap",
    borderBottom: "1px solid #dee2e6",
    top: '10px',
    position: 'sticky',
    zIndex: 1,
  },
  td: {
    borderBottom: "1px solid #dee2e6",
    textAlign: "center",
    padding: "8px",
    fontSize: "0.9em",
    verticalAlign: "middle",
    height: "45px",
    whiteSpace: "nowrap",
  },
  tdRed: {
    color: "red",
    textAlign: "center",
    whiteSpace: "nowrap",
    height: "100%",
    verticalAlign: "middle",
  },
  tdImage: {
    width: "30px",
    height: "30px",
    marginRight: "10px",
    borderRadius: "50%",
    verticalAlign: "middle",
    color: "gray",
  },
  thAsignatura: {
    textAlign: "center",
    padding: "8px",
    color: "var(--color-texto-oscuro)",
    verticalAlign: "middle",
    width: "150px",
    fontSize: "0.85em",
    position: "sticky",
    top: 0,
    backgroundColor: "var(--color-header)",
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  tdAcciones: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
  button: {
    margin: "0 5px",
  },
  stickyColumn: {
    position: "sticky",
    left: 0,
    backgroundColor: "white", 
  },
  lineaRoja: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    borderBottom: '2px solid red',
    transform: 'translateY(-50%)', 
    zIndex: 8, 
  },
};

const TablaInformeEstudianteCurso = ({
  estudiantes = [],
  periodoEscolar,
  semestre,
  cursoSeleccionado,
  dataEstudiantesNotas, 
  dataColegio,
  dataCurso,
  cantidadDecimales
}) => {
  const [datos, setDatos] = useState([]);
  const [indiceSiguiente, setIndiceSiguiente] = useState(0);
  const [tieneMas, setTieneMas] = useState(true);
  const [loading, setLoading] = useState(true);
  const [listaAsignaturas, setListaAsignaturas] = useState([]);
  const [notasPorEstudiante, setNotasPorEstudiante] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [notasFiltradas, setNotasFiltradas] = useState([]);
  const [selectedEstudiante, setSelectedEstudiante] = useState(null);

  const informeRef = useRef();

  const {
    dataColegio: colegioData,
    dataDirector,
    dataCurso: cursoData,
    notasEstudiantes,
    listadoEscalas,
    columnaUnidades, 
    notasAsignaturaNoInfluye,
  } = useGetInformeUnidadesGlobal(cursoSeleccionado?.cursoId, selectedEstudiante?.estudianteId);

  const generarPDF = async (estudiante) => {
    setSelectedEstudiante(estudiante);
  
    if (!notasEstudiantes?.length) {
      console.warn("No hay datos disponibles para generar el PDF.");
      return;
    }
  
    // Crear un div temporal fuera de la vista del usuario para renderizar el contenido
    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
  
    // Estilos del div temporal
    tempDiv.style.position = "fixed";
    tempDiv.style.left = "-9999px";
    tempDiv.style.top = "-9999px";
    tempDiv.style.width = "100%";
    tempDiv.style.padding = "10px"; // Ajuste de padding para maximizar el espacio
    tempDiv.style.backgroundColor = "white";
  
    // Renderizar el componente en el div temporal
    const root = createRoot(tempDiv);
    root.render(
      <InformeCalificacionesGaspar
        columnasUnidades={columnaUnidades}
        dataColegio={colegioData}
        dataDirector={dataDirector}
        dataCurso={cursoData}
        listadoNotas={notasEstudiantes}
        listadoEscalas={listadoEscalas}
        notasAsignaturaNoInfluye={notasAsignaturaNoInfluye}
        cantidadDecimales={cantidadDecimales}
        
      />
    );
  
    // Esperar a que los estilos y el contenido se carguen completamente
    await new Promise((resolve) => setTimeout(resolve, 1000));
  
    // Generar el canvas a partir del contenido del div temporal
    const canvas = await html2canvas(tempDiv, { scale: 2, useCORS: true, backgroundColor: null });
    const imgData = canvas.toDataURL('image/png');
  
    // Configurar el PDF en orientación landscape para aprovechar el espacio horizontal
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: 'a4',
      compress: true,
    });
  
    // Obtener dimensiones del PDF y escalar la imagen para ocupar todo el espacio posible
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = pdfWidth - 20; // Margen reducido a 10pt a cada lado
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
    // Ajustar la imagen para que ocupe el máximo espacio posible en la página
    const finalImgWidth = imgWidth;
    const finalImgHeight = Math.min(imgHeight, pdfHeight - 20); // Margen vertical de 10pt
  
    // Centrar la imagen en la página del PDF
    const positionX = (pdfWidth - finalImgWidth) / 2;
    const positionY = (pdfHeight - finalImgHeight) / 2;
  
    // Agregar la imagen al PDF ocupando casi toda la página
    pdf.addImage(imgData, 'PNG', positionX, positionY, finalImgWidth, finalImgHeight, 'FAST');
  
    // Guardar el PDF con el nombre del estudiante
    const fileName = `InformeCalificaciones_${estudiante.nombreCompleto}.pdf`;
    pdf.save(fileName);
  
    // Eliminar el div temporal después de la generación
    document.body.removeChild(tempDiv);
  };
  

  const handleMouseEnter = (rowIndex, colIndex) => {
    setHoveredRow(rowIndex);
    setHoveredColumn(colIndex);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
    setHoveredColumn(null);
  };

  // const cargarMasDatos = () => {
  //   if (indiceSiguiente < estudiantes.length) {
  //     const nuevosEstudiantes = estudiantes.slice(
  //       indiceSiguiente,
  //       indiceSiguiente + 50
  //     );
  //     setDatos((prevDatos) => [...prevDatos, ...nuevosEstudiantes]);
  //     setIndiceSiguiente(indiceSiguiente + 50);
  //     setTieneMas(indiceSiguiente + 50 < estudiantes.length);
  //   }
  // };

 
const abrirInformeEnNuevaVentana = (estudiante) => {
  const datosInforme = {
      periodoEscolar,
      semestre,
      estudianteId: estudiante.estudianteId,
      cursoId: cursoSeleccionado?.cursoId // Verifica que cursoSeleccionado contiene el cursoId
  };

  console.log('Datos que se están guardando en localStorage:', datosInforme); // Para verificar que el cursoId está presente

  localStorage.setItem("datosInforme", JSON.stringify(datosInforme));
  window.open(
      `/informe-calificaciones-periodo/${estudiante.estudianteId}`,
      "_blank"
  );
};



  // const formatearNotaConComa = (nota) => {
  //   if (nota === 0) return "-";
  //   return nota.toFixed(2).replace(".", ",");
  // };


  // useEffect(() => {
  //   console.log(notasFiltradas)
  // },[notasFiltradas]);



  useEffect(() => {
    if (estudiantes.length > 0) {
      const estudiantesOrdenados = estudiantes.sort(
        (a, b) => a.numLista - b.numLista
      );
      setDatos(estudiantesOrdenados.slice(0, 50));
      setIndiceSiguiente(50);
      setTieneMas(estudiantesOrdenados.length > 50);
    }
  }, [estudiantes]);

  useEffect(() => {
    const cargarNotas = async () => {
      setLoading(true);
      try {
        const asignaturas = dataEstudiantesNotas.flatMap((estudianteData) =>
          estudianteData.notasAsignatura.map((asignaturaData) => ({
            asignaturaId: asignaturaData.asignatura.asignaturaId,
            asignaturaNombre: asignaturaData.asignatura.asignaturaNombre,
          }))
        );
        const asignaturasUnicas = Array.from(
          new Map(asignaturas.map((item) => [item.asignaturaId, item])).values()
        );
  
        setListaAsignaturas(asignaturasUnicas);
  
        // Mapeamos las notas por estudiante, asegurándonos de incluir tanto notaFinalSum como notaFinalSumConcepto
        const notasPorEstudiante = dataEstudiantesNotas.map((estudianteData) => {
          const notasAsignadas = {};
          estudianteData.notasAsignatura.forEach((asignaturaData) => {
            notasAsignadas[asignaturaData.asignatura.asignaturaId] = {
              // Guardamos el objeto completo de notaFinal con sus propiedades
              notaFinal: {
                notaFinalSum: asignaturaData.notaFinal.notaFinalSum,
                notaFinalSumConcepto: asignaturaData.notaFinal.notaFinalSumConcepto,
              },
            };
          });
          return {
            estudianteId: estudianteData.estudiante.estudianteId,
            numMatricula: estudianteData.estudiante.numMatricula,
            numLista: estudianteData.estudiante.numLista,
            nombreCompleto: `${estudianteData.estudiante.nombrePrimario} ${estudianteData.estudiante.apellidoPrimario} ${estudianteData.estudiante.apellidoSecundario}`,
            run: `${estudianteData.estudiante.run}-${estudianteData.estudiante.runDv}`,
            retirado: estudianteData.estudiante.retirado,
            notasAsignadas,
            ipe: `${estudianteData.estudiante.runProvisorio}-${estudianteData.estudiante.runProvisorioDv}`,
          };
        });

  setNotasPorEstudiante(notasPorEstudiante);
      } finally {
        setLoading(false);
      }
    };
  
    if (cursoSeleccionado && periodoEscolar) {
      cargarNotas();
    }
  }, [cursoSeleccionado, periodoEscolar, dataEstudiantesNotas]);
  

  const obtenerIdentificadorFormateado = (estudiante) => {
    return validarRUN(estudiante.run)
      ? formatearRUN(estudiante.run)
      : formatearIPEIPA(estudiante.ipe);
  };

  useEffect(() => {
    if (estudiantes.length > 0 && notasPorEstudiante.length > 0) {
      const nuevasNotasFiltradas = notasPorEstudiante.filter((notaEstudiante) =>
        estudiantes.some(
          (estudiante) => estudiante.id === notaEstudiante.estudianteId
        )
      );
      setNotasFiltradas(nuevasNotasFiltradas);
    } else {
      setNotasFiltradas(notasPorEstudiante); // Mostrar todo si no hay filtro aplicado
    }
  }, [estudiantes, notasPorEstudiante]);

  return (
    <Container fluid className="bg-white pt-0" style={estilos.container}>
      <div
        style={{
          ...estilos.tableContainer,
          overflowX: "auto",
          overflowY: "auto",
          width: "100%",
          maxHeight: "80vh",
        }}
      >
        <Table
          className="table"
          style={{
            ...estilos.table,
            tableLayout: "fixed",
            minWidth: "100%",
            borderCollapse: "separate",
            borderSpacing: 0,
          }}
        >
          <thead style={{ ...estilos.thead }}>
            <tr>
              <th
                style={{
                  ...estilos.th,
                  top: 0,
                  left: 0,
                  width: "120px",
                  zIndex: 10,
                  backgroundColor: "white",
                }}
              >
                N° Matrícula
              </th>
              <th
                style={{
                  ...estilos.th,
                  top: 0,
                  left: "120px",
                  width: "110px",
                  zIndex: 10,
                  backgroundColor: "white",
                }}
              >
                RUN
              </th>
              <th
                style={{
                  ...estilos.th,
                  top: 0,
                  left: "230px",
                  width: "80px",
                  zIndex: 10,
                  backgroundColor: "white",
                }}
              >
                N° Lista
              </th>
              <th
                style={{
                  ...estilos.th,
                  top: 0,
                  left: "310px",
                  width: "200px",
                  zIndex: 10,
                  backgroundColor: "white",
                  textAlign: "center",
                }}
              >
                Estudiante
              </th>
              {listaAsignaturas.map((asignatura, colIndex) => (
                <th
                  key={colIndex}
                  style={{
                    ...estilos.thAsignatura,
                    zIndex: 9, // Ajuste de z-index para mantener las asignaturas por debajo
                    width: "120px",
                    padding: "8px 4px",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    fontSize: "0.85rem",
                    borderBottom: "1px solid #dee2e6",
                    lineHeight: "1.2",
                  }}
                >
                  {asignatura.asignaturaNombre}
                </th>
              ))}
          <th
            style={{
              ...estilos.th,
              width: "120px",
              position: "sticky",
              right: 0,
              top: "0px",
              backgroundColor: "white",
              zIndex: 11, // Ajusta el z-index
            }}
          >
            Acciones
          </th>
            </tr>
          </thead>
          <tbody>
            {notasFiltradas.map((estudiante, rowIndex) => {
              const mostrarLineaRoja = estudiante.retirado;

              return (
                <tr
                  key={estudiante.estudianteId}
                  onMouseEnter={() => handleMouseEnter(rowIndex, null)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    backgroundColor:
                      hoveredRow === rowIndex
                        ? "var(--color-doble-hover)"
                        : "transparent",
                    borderBottom: "1px solid #dee2e6",
                  }}
                >
                  {/* Número de Matrícula */}
                  <td
                    style={{
                      ...estilos.td,
                      position: "sticky",
                      left: 0,
                      zIndex: 9,
                      backgroundColor: hoveredRow === rowIndex ? "var(--color-doble-hover)" : "white",
                    }}
                  >
                    {estudiante.numMatricula}
                    {mostrarLineaRoja && (
                      <span style={{ ...estilos.lineaRoja }}></span>
                    )}
                  </td>
  
                  {/* RUN */}
                  <td
                    style={{
                      ...estilos.td,
                      position: "sticky",
                      left: "120px",
                      zIndex: 9,
                      backgroundColor: hoveredRow === rowIndex ? "var(--color-doble-hover)" : "white",
                    }}
                  >
                    {/* Mostrar el RUN o IPE formateado */}
                    {/* {estudiante.run && estudiante.run !== 'null-null'
                      ? formatearRUN(estudiante.run) 
                      : formatearRUN(estudiante.ipe) 
                    } */}

                    {/* {validarRUN(estudiante.run) ? estudiante.run : estudiante.ipe} */}
                    {obtenerIdentificadorFormateado(estudiante)}

                    {mostrarLineaRoja && (
                      <span style={{ ...estilos.lineaRoja }}></span>
                    )}
                  </td>
  
                  {/* Número de Lista */}
                  <td
                    style={{
                      ...estilos.td,
                      position: "sticky",
                      left: "230px",
                      zIndex: 9,
                      backgroundColor: hoveredRow === rowIndex ? "var(--color-doble-hover)" : "white",
                    }}
                  >
                    {estudiante.numLista}
                    {mostrarLineaRoja && (
                      <span style={{ ...estilos.lineaRoja }}></span>
                    )}
                  </td>
  
                  {/* Nombre del Estudiante */}
                  <td
                    style={{
                      ...estilos.td,
                      position: "sticky",
                      left: "310px",
                      zIndex: 9,
                      backgroundColor: hoveredRow === rowIndex ? "var(--color-doble-hover)" : "white",
                    }}
                  >
                    <div className="d-flex gap-2 justify-content-start align-items-center">
                      <Avatar
                        width={"30px"}
                        height={"30px"}
                        run={
                          esRunOIpe(estudiante.run)
                            ? estudiante.run.split("-")[0]
                            : esRunOIpe(estudiante.ipe)
                            ? estudiante.ipe.split("-")[0]
                            : null
                        }
                      />
                      <CustomTooltip
                        tooltipMsg={
                          estudiante.retirado
                            ? `Estudiante retirado: ${estudiante.nombreCompleto}`
                            : estudiante.nombreCompleto
                        }
                      >
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            maxWidth: "150px",
                            verticalAlign: "middle",
                            lineHeight: "30px",
                          }}
                        >
                          {estudiante.nombreCompleto}
                        </span>
                      </CustomTooltip>
                    </div>
                    {mostrarLineaRoja && (
                      <span style={{ ...estilos.lineaRoja }}></span>
                    )}
                  </td>
  
                  {/* Notas por Asignatura */}
                  {listaAsignaturas.map((asignatura, colIndex) => {
                    const nota = estudiante.notasAsignadas[asignatura.asignaturaId]?.notaFinal;

                    // Función auxiliar para verificar si la nota está en estado pendiente
                    const esPendiente = (valor) => valor === -1 || valor === -1.0 || valor === "-1";
                  
                    // Determinar qué mostrar basándose en las condiciones:
                    const mostrarNota = esPendiente(nota?.notaFinalSum) ? (
                      // Mostrar el icono de pendiente si `notaFinalSum` es -1
                      <i
                          className={estilosEstado.pendiente.icon}
                          style={{ color: estilosEstado.pendiente.iconColor }}
                      ></i>
                    ) : (
                      // Mostrar el concepto si está presente; de lo contrario, mostrar la nota numérica o "-"
                      nota?.notaFinalSumConcepto || 
                      (nota?.notaFinalSum > 0 
                        ? formatearNotaConComa(nota.notaFinalSum, cantidadDecimales) 
                        : "-")
                    );

                    return (
                      <td
                        key={colIndex}
                        style={{
                          ...estilos.td,
                          position: "relative",
                          backgroundColor:
                            (hoveredRow === rowIndex && hoveredColumn === colIndex + 4) ||
                            hoveredColumn === colIndex + 4
                              ? "var(--color-doble-hover)"
                              : "transparent",
                        }}
                        onMouseEnter={() => handleMouseEnter(rowIndex, colIndex + 4)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {mostrarNota}
                        {mostrarLineaRoja && <span style={{ ...estilos.lineaRoja }}></span>}
                      </td>
             
                    );
                  })}

                  {/* Acciones */}
                  <td
                    style={{
                      ...estilos.tdAcciones,
                      position: "sticky",
                      right: 0,
                      backgroundColor:
                        hoveredRow === rowIndex
                          ? "var(--color-doble-hover)"
                          : "white",
                      zIndex: 10,
                    }}
                  >
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Ver Informe</Tooltip>}
                    >
                      <Button
                        variant="outline-primary"
                        size="sm"
                        style={estilos.button}
                        onClick={() => abrirInformeEnNuevaVentana(estudiante)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </OverlayTrigger>
                    {/* <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Descargar Informe</Tooltip>}
                    >
                      <Button
                        variant="outline-primary"
                        size="sm"
                        style={estilos.button}
                        onClick={() =>
                          generarPDF(
                            estudiante,
                            dataColegio,
                            dataCurso,
                            semestre,
                            dataEstudiantesNotas
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faFilePdf} />
                      </Button>
                    </OverlayTrigger> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Container>
  );
}  

export default TablaInformeEstudianteCurso;
