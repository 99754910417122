import React, { useEffect, useState, useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Avatar from "../../../../components/avatar/Avatar";
import RunInput from "../../../../components/inputs/RunInput";
import {
  capitalize,
  filtrarPuntos,
  formatearIPEIPA,
  formatearRUN,
  validarIPE,
} from "../../../../../util/util";
import TextInput from "../../../../components/inputs/TextInput";
import DatePickerInput from "../../../../components/inputs/DatePickerInput";
import SelectSingle from "../../../../components/inputs/SelectSingle";
import EmailInput from "../../../../components/inputs/EmailInput";
import SelectMultiple from "../../../../components/inputs/SelectMultiple";
// import CheckboxInput from "../../../../components/inputs/CheckboxInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import "./styles.css";
import IpeInput from "../../../../components/inputs/IpeInput";

function TabPersonal({
  valoresEstudiante,
  setValoresEstudiante,
  errors,
  setErrors,
  isReadyToUploadAvatar,
  listaComunas,
  listaGenerosRegCivil,
  listaIdentidadesGenero,
  listaNacionalidades,
  listaEtnias,
  listaVinculos,
  listaRegiones,
  manejarCambioTexto,
  manejarCambioFecha,
}) {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const comunasFiltradas = useMemo(() => {
    return selectedRegion
      ? listaComunas.filter((comuna) => comuna.regionId === selectedRegion)
      : listaComunas;
  }, [listaComunas, selectedRegion]);

  // useEffect(() => {
  //   console.log("valoresEstudiante; ", valoresEstudiante);
  // }, [valoresEstudiante]);

  const DataPolicyInfoPopup = () => {
    Swal.fire({
      title:
        "Autorización para el Tratamiento y Compartición de Datos Personales",
      html: `
      <div style="display: flex; width: 100%; flex-direction: column; align-items: flex-start; text-align: left; line-height: 1.6; font-size: 16px;"> 
        <p><strong>Estimado(a) apoderado(a)</strong>,</p>
        <p>Para el correcto funcionamiento de nuestra plataforma educacional, necesitamos su consentimiento para procesar la información personal de cada estudiante.</p>
        <p>Estos datos serán utilizados exclusivamente para facilitar la experiencia educativa, administrar el aprendizaje y ofrecer herramientas pedagógicas dentro de nuestra plataforma.</p>
        <p>Con su autorización, podremos compartir la información del estudiante con otros establecimientos en caso que decida cambiarse de colegio a uno que también utilice esta plataforma, siempre que sea en el contexto educativo y con el objetivo de mejorar la continuidad y calidad del aprendizaje del alumno.</p>
        <p><Strong>No venderemos, compartiremos, ni utilizaremos </strong> esta información para fines comerciales externos.</p>
        <h3 style="margin-top: 20px;">Sus Derechos:</h3>
        <ul style="margin-left: 20px;">
          <li><strong>● Acceso y Rectificación:</strong> Puede solicitar acceso y corrección de los datos personales.</li>
          <li><strong>● Eliminación y Revocación:</strong> Puede pedir la eliminación de los datos o retirar este consentimiento en cualquier momento escribiendo a <a href="mailto:email@example.com">[email@example.com]</a>.</li>
          <li><strong>● Portabilidad:</strong> Podrá solicitar la portabilidad de los datos a otra institución si es necesario.</li>
        </ul>
      </div>
      `,
      confirmButtonText: "Entendido",
      confirmButtonColor: "var(--color-acento)",
      customClass: {
        popup: "data-policy-popup",
        title: "custom-title-class",
      },
    });
  };

  return (
    <Form
      noValidate
      className="d-flex flex-column align-items-center"
      style={{ height: "55dvh", width: "100%" }}
    >
      <section
        style={{
          width: "90%",
          display: "flex",
        }}
      >
        <section
          className="d-flex flex-column justify-content-center align-items-center width-20 gap-2"
          style={{
            padding: "1rem",
            height: "fit-content",
          }}
        >
          <div
            className="w-100  d-flex justify-content-center align-items-center "
            style={{ height: "180px" }}
          >
            <Avatar
              isReadyToUpload={isReadyToUploadAvatar}
              enableUpload
              run={
                valoresEstudiante.run && valoresEstudiante.run !== ""
                  ? filtrarPuntos(valoresEstudiante.run.split("-")[0])
                  : filtrarPuntos(valoresEstudiante.ipe)
                      .split("-")
                      .join("")
                      .slice(0, -1)
              }
            />
          </div>

          <RunInput
            // ref={runInputRef}
            controlId="run"
            value={formatearRUN(valoresEstudiante.run) || ""}
            placeholder={"XX.XXX.XXX-X"}
            errors={errors}
            setErrors={setErrors}
            setValores={setValoresEstudiante}
            maxLength={12}
            style={{ width: "100%" }}
            required
            label={"RUN"}
            statePath="run" // Actualiza directamente el objeto anidado
          />
          {/* <TextInput
            controlId="ipe"
            type="text"
            placeholder="100.XXX.XXX-X"
            value={formatearIPEIPA(valoresEstudiante.ipe) || ""}
            onChange={(e) =>
              manejarCambioTexto(
                "ipe",
                e.target.value,
                setValoresEstudiante,
                setErrors
              )
            }
            maxLength={13}
            label="IPE"
            tooltipMessage="IPE puede estar vacío si tiene RUN"
            required
            isInvalid={
              valoresEstudiante.ipe !== "" && !validarIPE(valoresEstudiante.ipe)
            }
            disabled={valoresEstudiante.run.length > 0}
          /> */}

          <IpeInput
            controlId="ipe"
            value={formatearIPEIPA(valoresEstudiante.ipe) || ""}
            placeholder="100.XXX.XXX-X"
            errors={errors}
            setErrors={setErrors}
            setValores={setValoresEstudiante}
            maxLength={13}
            required
            label="IPE"
            statePath="ipe"
            disabled={valoresEstudiante.run.length > 0}
            // index={index}
          />
        </section>

        <section className="w-100 d-flex flex-column gap-2 justify-content-end ">
          <Row className="">
            <Col md={2}>
              <TextInput
                controlId="apellidoPaterno"
                type="text"
                value={valoresEstudiante.apellidoPaterno || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "apellidoPaterno",
                    e.target.value,
                    setValoresEstudiante,
                    setErrors
                  )
                }
                maxLength={25}
                label="Apellido Paterno"
                required
              />
            </Col>
            <Col md={2}>
              <TextInput
                controlId="apellidoMaterno"
                type="text"
                value={valoresEstudiante.apellidoMaterno || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "apellidoMaterno",
                    e.target.value,
                    setValoresEstudiante,
                    setErrors
                  )
                }
                maxLength={25}
                label="Apellido Materno"
              />
            </Col>
            <Col md={2}>
              <TextInput
                controlId="nombres"
                type="text"
                value={valoresEstudiante.nombres || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "nombres",
                    e.target.value,
                    setValoresEstudiante,
                    setErrors
                  )
                }
                maxLength={25}
                label="Nombre Primario"
                required
              />
            </Col>
            <Col md={2}>
              <TextInput
                controlId="nombresSecundarios"
                type="text"
                value={valoresEstudiante.nombresSecundarios || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "nombresSecundarios",
                    e.target.value,
                    setValoresEstudiante,
                    setErrors
                  )
                }
                maxLength={25}
                label="Nombre Secundario"
              />
            </Col>

            <Col md={2}>
              <TextInput
                controlId="nombreSocial"
                type="text"
                value={valoresEstudiante.nombreSocial || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "nombreSocial",
                    e.target.value,
                    setValoresEstudiante,
                    setErrors
                  )
                }
                maxLength={25}
                label="Nombre Social"
              />
            </Col>
            <Col md={2}>
              <EmailInput
                controlId="email"
                value={valoresEstudiante.email || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "email",
                    e.target.value,
                    setValoresEstudiante,
                    setErrors
                  )
                }
                maxLength={50}
                label="Email"
                placeholder="ejemplo@dominio.cl"
              />
            </Col>
          </Row>

          <Row>
            <Col md={2}>
              <DatePickerInput
                controlId="fechaNacimiento"
                selected={valoresEstudiante.fechaNacimiento || null}
                onChange={(date) => manejarCambioFecha(date, "fechaNacimiento")}
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/mm/aaaa"
                locale="es"
                required
                label="Fecha de Nacimiento"
              />
            </Col>
            <Col md={2}>
              <SelectSingle
                controlId="comunaNacimiento"
                placeholder="Comuna Nacimiento"
                elements={listaComunas}
                idKey="id"
                labelKey="name"
                selectedElement={valoresEstudiante.comunaNacimiento}
                onSelect={(comunaNacimiento) => {
                  const id = comunaNacimiento.id;
                  return setValoresEstudiante((prevValues) => ({
                    ...prevValues,
                    comunaNacimiento: id,
                  }));
                }}
              />
            </Col>
            <Col md={2}>
              <SelectSingle
                controlId="genero"
                required
                placeholder="Género Reg.Civil"
                elements={listaGenerosRegCivil}
                idKey="id"
                labelKey="name"
                selectedElement={valoresEstudiante.genero}
                onSelect={(genero) => {
                  setValoresEstudiante((prevValues) => ({
                    ...prevValues,
                    genero: genero ? genero.id : null,
                  }));
                }}
              />
            </Col>
            <Col md={2}>
              <SelectSingle
                controlId="generoIdentidad"
                placeholder="Identidad de Género"
                elements={listaIdentidadesGenero}
                idKey="id"
                labelKey="name"
                selectedElement={valoresEstudiante.generoIdentidad}
                onSelect={(generoIdentidad) => {
                  return setValoresEstudiante((prevValues) => ({
                    ...prevValues,
                    generoIdentidad: generoIdentidad
                      ? generoIdentidad.id
                      : null,
                  }));
                }}
              />
            </Col>
            <Col md={2}>
              <SelectSingle
                controlId="nacionalidad"
                placeholder="Nacionalidad"
                elements={listaNacionalidades}
                selectedElement={valoresEstudiante.nacionalidad}
                onSelect={(nacionalidad) => {
                  return setValoresEstudiante((prevValues) => ({
                    ...prevValues,
                    nacionalidad: nacionalidad ? nacionalidad.id : null,
                  }));
                }}
              />
            </Col>

            <Col md={2}>
              <SelectSingle
                controlId="etnia"
                placeholder="Etnia"
                elements={listaEtnias}
                idKey="id"
                labelKey="name"
                selectedElement={valoresEstudiante.etnia}
                onSelect={(etnia) => {
                  return setValoresEstudiante((prevValues) => ({
                    ...prevValues,
                    etnia: etnia ? etnia.id : null,
                  }));
                }}
                disabled={listaEtnias.length === 0}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={2}>
              <SelectMultiple
                controlId="viveCon"
                placeholder="Vive con"
                elements={listaVinculos.map((item) => ({
                  id: item.id,
                  name: capitalize(item.name),
                }))}
                selectedElements={valoresEstudiante.viveCon?.map((element) => ({
                  id: element.apoderadoTipoId,
                  name: capitalize(element.otroDescripcion),
                }))}
                onSelect={(viveCon) => {
                  return setValoresEstudiante((prevValues) => ({
                    ...prevValues,
                    viveCon,
                  }));
                }}
                labelKey="name"
                idKey="id"
                disabled
              />
            </Col>
            <Col md={2}>
              <SelectSingle
                controlId="region"
                placeholder="Región"
                elements={listaRegiones}
                idKey="id"
                labelKey="name"
                onSelect={(region) => {
                  // handleRegionSelect(region);
                  // console.log(region);
                  setSelectedRegion(region?.id);
                  setValoresEstudiante((prevValues) => ({
                    ...prevValues,
                    region: region?.id,
                    comuna: "",
                  }));
                }}
                // selectedElement={valoresEstudiante.region}
                selectedElement={selectedRegion || valoresEstudiante.region}
                required
              />
            </Col>
            <Col md={2}>
              <SelectSingle
                controlId="comuna"
                placeholder="Comuna"
                elements={comunasFiltradas} // Comunas filtradas según la región seleccionada
                idKey="id"
                labelKey="name"
                selectedElement={valoresEstudiante.comuna} // Comuna seleccionada
                onSelect={(comuna) => {
                  setValoresEstudiante((prevValues) => ({
                    ...prevValues,
                    comuna: comuna?.id, // Establece la comuna seleccionada o un valor vacío si es null
                  }));
                }}
                required
              />
            </Col>
            <Col md={4}>
              <TextInput
                controlId="direccion"
                type="text"
                value={valoresEstudiante.direccion || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "direccion",
                    e.target.value,
                    setValoresEstudiante,
                    setErrors
                  )
                }
                maxLength={40}
                label="Dirección"
                required
              />
            </Col>
          </Row>
        </section>
      </section>
      <div className="w-100" style={{ padding: "1rem 8rem" }}>
        <div
          style={{
            width: "20%",
            display: "flex",
            gap: "1rem",
            alignItems: "flex-end",
          }}
        >
          <SelectSingle
            controlId={"autorizacionDatos"}
            placeholder={"Autorización de datos"}
            elements={[
              {
                id: 1,
                name: "Sí autorizo, y consiento que estos sean compartidos con otro colegio, en caso de cambiar de establecimiento.",
              },
              {
                id: 2,
                name: "Sí autorizo, pero NO autorizo a que estos sean compartidos con otro colegio en caso de cambiarlos de establecimiento.",
              },
              { id: 3, name: "No, no autorizo." },
            ]}
            required
            onSelect={(option) => {
              const updatedValues = {
                ...valoresEstudiante,
                autorizaData: option?.id,
              };
              setValoresEstudiante(updatedValues);

              if (!option || option.id === 3) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  autorizaData:
                    "Debe autorizar el guardado de datos para continuar.",
                }));
              } else {
                setErrors((prevErrors) => {
                  const { autorizaData, ...rest } = prevErrors;
                  return rest; // Remueve el error si se selecciona una opción válida
                });
              }
            }}
            style={{ width: "300px" }}
            selectedElement={valoresEstudiante.autorizaData}
            isInvalid={!!errors?.autorizaData} // Muestra el campo como inválido si hay un error
          />

          <Button
            style={{
              height: "42px",
            }}
            variant="color-gray-outline"
            onClick={DataPolicyInfoPopup}
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default TabPersonal;
