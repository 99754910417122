import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';

const ModalAutenticacion = ({ show, onHide, onConfirm }) => {
  const [codigo, setCodigo] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (show) {
      setCodigo(['', '', '', '', '', '']);
      setTimeout(() => {
        inputRefs.current[0]?.focus();
      }, 300);
    }
  }, [show]);

  const manejarCambioCodigo = (value, index) => {
    if (!/^\d$/.test(value)) return;

    const nuevoCodigo = [...codigo];
    nuevoCodigo[index] = value;
    setCodigo(nuevoCodigo);

    if (index < 5 && value !== '') {
      inputRefs.current[index + 1]?.focus();
    } else if (index === 5 && nuevoCodigo.every((digit) => digit !== '')) {
      // Llama a onConfirm cuando todos los dígitos están completos
      onConfirm(nuevoCodigo.join(''));
    }
  };

  const manejarKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      const nuevoCodigo = [...codigo];
      if (codigo[index] !== '') {
        nuevoCodigo[index] = '';
      } else if (index > 0) {
        nuevoCodigo[index - 1] = '';
        inputRefs.current[index - 1]?.focus();
      }
      setCodigo(nuevoCodigo);
    }
  };

  return (
    <Modal centered show={show} onHide={onHide} size="md">
      <Modal.Header closeButton>
        <Modal.Title className='w-100 text-center'>Doble Autenticación Requerida</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">Por favor, ingrese el código de Doble Autenticación para completar la acción.</p>
        <div className="d-flex justify-content-center">
          {codigo.map((digit, index) => (
            <input
              key={index}
              ref={(el) => inputRefs.current[index] = el}
              type="text"
              inputMode="numeric"
              maxLength="1"
              value={digit}
              onChange={(e) => manejarCambioCodigo(e.target.value, index)}
              onKeyDown={(e) => manejarKeyDown(e, index)}
              className="form-control mx-1"
              autoComplete="off"
              style={{ 
                width: '50px',
                height: '50px',
                textAlign: 'center', 
                fontSize: '24px',
                borderRadius: '8px',
                border: '2px solid #ced4da',
                caretColor: 'transparent',
                outline: 'none',
              }}
              onFocus={(e) => e.target.style.border = '2px solid #0c66e4'}
              onBlur={(e) => e.target.style.border = '2px solid #ced4da'}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        {/* <ButtonPersonalizado variant="outline-primary" onClick={onHide}>
          Cancelar
        </ButtonPersonalizado>
        <ButtonPersonalizado variant="color-acento" onClick={() => onConfirm(codigo.join(''))}>
          Firmar
        </ButtonPersonalizado> */}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAutenticacion;
