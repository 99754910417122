import React from "react";
import InConstruction from "../../../components/construction/InConstruction";

const TabEstudiantes = () => {
  return (
    <div>
      <h3>Estudiantes</h3>
      <p>Aquí se mostrará la lista de estudiantes inscritos en el curso.</p>
      <InConstruction />
    </div>
  );
};

export default TabEstudiantes;
