import { lazy, Suspense, useEffect } from 'react';
/// Components
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import Index from './jsx/router/index';
// action
import { checkAutoLogin } from './services/AuthService';
import { navigateHomePage } from './store/actions/AuthActions';
import { isAuthenticated } from './store/selectors/AuthSelectors';

/// Style
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icons.min.css';
import 'rsuite/dist/rsuite-no-reset.min.css';
import './assets/css/bigcalendar.css';
import "./assets/css/estandar.css";
import "./assets/css/estilosCambar.css";
import "./assets/css/popOver.css";
import "./assets/css/style.css";


const SignUp = lazy(() => import('./jsx/pages/authentication/Registration'));
const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/authentication/Login')), 500);
    });
});

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (
            <Component
                {...props}
                router={{location, navigate, params}}
            />
        );
    }

    return ComponentWithRouterProp;
}

function App(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        const user = checkAutoLogin(dispatch, navigate);
        if(user) {
            if(location.pathname === "/") {
                navigateHomePage(user.user, navigate)
            }
        }
    }, []);

    let routeblog = (
        <Routes>
            <Route path='/login' element={<Login/>}/>
            <Route path='/page-register' element={<SignUp/>}/>
        </Routes>
    );
    if (props.isAuthenticated) {
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index/>
                </Suspense>
            </>
        );

    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {routeblog}
                </Suspense>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 

