import React from "react";
import { Form } from "react-bootstrap";
import CustomTooltip from "../tooltip/Tooltip";

function TextInput({
  label,
  name,
  value,
  onChange,
  required,
  disabled,
  placeholder,
  onBlur,
  onInput,
  isInvalid,
  maxLength,
  onPaste,
  type = "text", // Valor predeterminado
  tooltipMessage,
  controlId,
  style,
  hideLabel,
  minValue, // Prop para valor mínimo
  maxValue, // Prop para valor máximo
  className,
}) {
  // Función para limitar entrada a solo números si type es "numeric"
  const handleKeyPress = (e) => {
    if (type === "numeric" && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  // Validación de rango en el evento onChange
  const handleChange = (e) => {
    let newValue = e.target.value;

    // Si el tipo es numérico, aplica restricciones de mínimo y máximo
    if (type === "numeric") {
      if (minValue !== undefined && newValue < minValue) {
        newValue = minValue;
      } else if (maxValue !== undefined && newValue > maxValue) {
        newValue = maxValue;
      }
    }

    onChange({ ...e, target: { ...e.target, value: newValue } });
  };

  return (
    <Form.Group
      controlId={controlId}
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      {required && !hideLabel ? (
        <CustomTooltip tooltipMsg={tooltipMessage ? (
          <span>{tooltipMessage}</span>
        ) : (
          <span>Dato Requerido</span>
        )} >
          
          <Form.Label>
            {label}{" "}
            {required && tooltipMessage && (
              <span className="text-danger">**</span>
            )}
            {required && !tooltipMessage && (
              <span className="text-danger">*</span>
            )}
          </Form.Label>
        </CustomTooltip>
      ) : !hideLabel ? (
        <Form.Label>{label}</Form.Label>
      ) : null}
      <Form.Control
        type={type === "numeric" ? "text" : type} // Mantener type como "text" para numericos
        name={name}
        value={value}
        onChange={handleChange}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={onBlur}
        onInput={onInput}
        isInvalid={isInvalid}
        maxLength={maxLength}
        onPaste={onPaste}
        onKeyPress={handleKeyPress} // Restringir entrada para tipo numérico
        style={style}
        autoComplete="off"
        className={className}
      />
    </Form.Group>
  );
}

export default TextInput;
