import React from "react";
import InConstruction from "../../../components/construction/InConstruction";

const TabFormato = () => {
  return (
    <div>
      <h3>Formato</h3>
      <p>Aquí se mostrará el formato de evaluación y estructura del curso.</p>
      <InConstruction />
    </div>
  );
};

export default TabFormato;
