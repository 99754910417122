import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const EncabezadoTablaCalificaciones = React.memo(({
  data,
  mostrarAcumulativas,
  columnasExpandidas,
  estilos,
  hasPermission,
  manejarClickEncabezado,
  blockInputs,
  setModalMensaje,
  setMostrarModalAviso
}) => {
  const generarEncabezado = () => {
    const encabezado = [];
    const subEncabezado = [];

    const mostrarSubEncabezado = mostrarAcumulativas || Object.values(columnasExpandidas).some(val => val);

    // Encabezado de número de lista
    encabezado.push(
      <OverlayTrigger
        key="encabezadoNumeroLista"
        placement="top"
        overlay={<Tooltip id="tooltip-estudiante">Número de lista</Tooltip>}
      >
        <th
          className="text-center"
          rowSpan={mostrarSubEncabezado ? "2" : "1"}
          style={{ ...estilos.thSticky(false), ...estilos.colNumero }}
        >
          Nº
        </th>
      </OverlayTrigger>
    );

    // Encabezado de estudiante
    encabezado.push(
      <th
        className="text-center"
        rowSpan={mostrarSubEncabezado ? "2" : "1"}
        style={{ ...estilos.thSticky(false), ...estilos.colEstudiante }}
      >
        Estudiante
      </th>
    );

    data.confNotasSum.forEach((calificacion, colIndex) => {
      const expandirOColapsar = () => {
        if (hasPermission) {
          manejarClickEncabezado(calificacion.confSumCasilla);
        } else {
          setModalMensaje("No tienes permiso para expandir las calificaciones.");
          setMostrarModalAviso(true);
        }
      };
    
      if (calificacion.confSumAcumulativa) {
        if (mostrarAcumulativas || columnasExpandidas[calificacion.confSumCasilla]) {
          // Encabezado expandido de notas acumulativas
          encabezado.push(
            <th
              key={calificacion.confSumCasilla}
              className="text-center"
              colSpan={calificacion.confNotaAcu.length + 1}
              style={{
                ...estilos.thSticky(calificacion.confSumAcumulativa),
                ...estilos.colNotas,
                cursor: 'pointer',
                borderTop: '0px solid transparent',
                borderRight: "1px solid #ccc", // Borde derecho cuando está expandido
                borderLeft: colIndex === 0 ? "none" : "1px solid #ccc", // Sin borde izquierdo en la primera sumativa expandida
                paddingBottom: '5px'
              }}
              onClick={expandirOColapsar}
            >
              {calificacion.confSumTitulo || `NS${calificacion.confSumCasilla}`}
            </th>
          );
    
          // Subencabezado de notas acumulativas
          subEncabezado.push(
            ...calificacion.confNotaAcu.map((nota, acuIndex) => (
              <OverlayTrigger
                key={nota.confAcuCasilla}
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${nota.confAcuCasilla}`}>
                    <section style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                      {calificacion.confSumTitulo && calificacion.confSumTitulo !== '0' && <span>Título: {calificacion.confSumTitulo}</span>}
                      {nota.confAcuTitulo && <span>Título: {nota.confAcuTitulo}</span>}
                      {nota.confAcuFecha && <span>Fecha: {nota.confAcuFecha}</span>}
                      {nota.confAcuPonderacion > 0 && <span>Ponderación: {nota.confAcuPonderacion}%</span>}
                      <span>Nota tipo: Acumulativa</span>
                    </section>
                  </Tooltip>
                }
              >
                <th
                  key={nota.confAcuCasilla}
                  className="text-center"
                  style={{
                    ...estilos.thSticky(calificacion.confSumAcumulativa),
                    ...estilos.colNotas,
                    ...estilos.thStickySubheader,
                    borderLeft: acuIndex === 0 && colIndex !== 0 ? "1px solid #ccc" : "none", // Borde izquierdo solo para primeras acumulativas de sumativas después de la primera
                  }}
                >
                  {nota.confAcuTitulo || `NA${nota.confAcuCasilla}`}
                </th>
              </OverlayTrigger>
            )),
            <OverlayTrigger
              key={`NF-${calificacion.confSumCasilla}`}
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${calificacion.confSumCasilla}`}>
                  <section style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    {calificacion.confSumTitulo && <span>Título: {calificacion.confSumTitulo}</span>}
                    <span>Nota tipo: Sumativa</span>
                    <span>compuesta por notas Acumulativas</span>
                  </section>
                </Tooltip>
              }
            >
              <th
                key={`NF-${calificacion.confSumCasilla}`}
                className="text-center"
                style={{
                  ...estilos.thSticky(calificacion.confSumAcumulativa),
                  ...estilos.colNotasSinBorde,
                  verticalAlign: "middle",
                  textDecoration: "underline",
                  ...estilos.thStickySubheader,
                  borderRight: "1px solid #ccc", 
                }}
                onClick={expandirOColapsar}
              >
                {calificacion.confSumTitulo || `NS${calificacion.confSumCasilla}`}
              </th>
            </OverlayTrigger>
          );
        } else {
          // Encabezado no expandido de notas sumativas
          encabezado.push(
            <OverlayTrigger
              key={calificacion.confSumCasilla}
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${calificacion.confSumCasilla}`}>
                  <section style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    {calificacion.confSumTitulo && <span>Título: {calificacion.confSumTitulo}</span>}
                    <span>Nota tipo: Sumativa</span>
                  </section>
                </Tooltip>
              }
            >
              <th
                key={`NF-${calificacion.confSumCasilla}`}
                className="text-center"
                rowSpan={mostrarSubEncabezado ? "2" : "1"}
                style={{
                  ...estilos.thSticky(calificacion.confSumAcumulativa),
                  ...estilos.colNotasSinBorde,
                  verticalAlign: "middle",
                  textDecoration: "underline",
                  cursor: 'pointer'
                }}
                onClick={expandirOColapsar}
              >
                {calificacion.confSumTitulo || `NS${calificacion.confSumCasilla}`}
              </th>
            </OverlayTrigger>
          );
        }
      } else {
        // Encabezado de notas sumativas normales
        encabezado.push(
          <OverlayTrigger
            key={calificacion.confSumCasilla}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-${calificacion.confSumCasilla}`}>
                <section style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                  {calificacion.confSumTitulo && <span>Título: {calificacion.confSumTitulo}</span>}
                  <span>Nota tipo: Sumativa</span>
                </section>
              </Tooltip>
            }
          >
            <th
              key={calificacion.confSumCasilla}
              className="text-center"
              rowSpan={mostrarSubEncabezado ? "2" : "1"}
              style={{
                ...estilos.thSticky(calificacion.confSumAcumulativa),
                ...estilos.colNotas,
                verticalAlign: "middle",
              }}
            >
              {calificacion.confSumTitulo || `NS${calificacion.confSumCasilla}`}
            </th>
          </OverlayTrigger>
        );
      }
    });

    // Agregar columna de promedio
    encabezado.push(
      <th
        className="text-center"
        rowSpan={mostrarSubEncabezado ? "2" : "1"}
        style={{ ...estilos.thSticky(false), ...estilos.colResumen, verticalAlign: "middle" }}
      >
        Promedio
      </th>
    );

    return { encabezado, subEncabezado };
  };

  const { encabezado, subEncabezado } = generarEncabezado();

  return (
    <>
      <tr>{encabezado}</tr>
      {subEncabezado.length > 0 && <tr>{subEncabezado}</tr>}
    </>
  );
});

export default React.memo(EncabezadoTablaCalificaciones);