// ConfigHorarioCurso.jsx
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import useCheckRoles from '../../../hooks/useCheckRoles';
import useGetListadoCursos from '../../../hooks/useGetListadoCursos';
import ContenidoHorarioCurso from '../../components/configHorarioCurso/ContenidoHorarioCurso';

const ConfigHorarioCurso = ({ cursoId }) => {
  const [curso, setCurso] = useState(null);
  const { cursos, cargando, error } = useGetListadoCursos();

  // Verificar roles usando useCheckRoles
  const { isDocente, isDirectivo } = useCheckRoles();

  // Determinar si debe ocultar la columna de configuración del curso
  const ocultarConfigCurso = isDocente && !isDirectivo;

  useEffect(() => {
    if (cursoId) {
      const cursoPreseleccionado = cursos?.find(curso => curso?.cursoId === cursoId);
      if (cursoPreseleccionado) {
        setCurso({ id: cursoPreseleccionado.cursoId, name: cursoPreseleccionado.cursoNombre });
      }
    } else if (cursos?.length > 0 && !curso) {
      const { cursoId, cursoNombre } = cursos[0];
      if (cursoId && cursoNombre) {
        setCurso({ id: cursoId, name: cursoNombre });
      }
    }
  }, [cursos, cursoId]);

  const handleCursoSelect = (selectedCurso) => {
    if (selectedCurso) {
      setCurso(selectedCurso);
    }
  };

  if (error) {
    return <div>Error al cargar los cursos.</div>;
  }

  return (
    <Container
      fluid
      className="bg-white p-0 pt-0 position-relative"
      style={{ height: '73dvh', display: 'flex', flexDirection: 'column', overflow: "hidden" }}
    >
      <ContenidoHorarioCurso
        cursos={cursos}
        onCursoSelect={handleCursoSelect}
        cursoSeleccionado={curso}
        ocultarConfigCurso={ocultarConfigCurso}
        mostrarSelectorCurso={!cursoId}
      />
    </Container>
  );
};

export default ConfigHorarioCurso;
