import { useEffect, useState } from 'react';
import { getInformeUnidadesGlobal } from '../services/PostsService';

const useGetInformeUnidadesGlobal = (cursoId, estudianteId = null) => {
    const [dataColegio, setDataColegio] = useState({});
    const [dataDirector, setDataDirector] = useState({});
    const [dataCurso, setDataCurso] = useState({});
    const [columnaUnidades, setColumnaUnidades] = useState([]);
    const [notasEstudiantes, setNotasEstudiantes] = useState([]);
    const [notasAsignaturaNoInfluye, setNotasAsignaturaNoInfluye] = useState([]);
    const [listadoEscalas, setListadoEscalas] = useState([]);
    const [asignaturasConConcepto, setAsignaturasConConcepto] = useState([]);
    const [cantidadDecimales, setCantidadDecimales] = useState(); // Estado sin valor por defecto
    const [cargando, setCargando] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInformeUnidadesGlobal = async () => {
            setCargando(true);
            setError(null);

            try {
                const params = { curso_id: cursoId };
                if (estudianteId) {
                    params.estudiante_id = estudianteId;
                }

                const response = await getInformeUnidadesGlobal(params);
                const data = response.data;

                // Asignar los datos obtenidos de la respuesta
                setColumnaUnidades(Array.isArray(data.columnaUnidades) ? data.columnaUnidades : []);
                setDataColegio(data.dataColegio || {});
                setDataDirector(data.dataDirector || {});
                setDataCurso(data.dataCurso || {});
                setListadoEscalas(data.listadoEscalas || []);
                setCantidadDecimales(data.cantidadDecimales); // Asignar directamente cantidadDecimales

                // Establecer las notas de los estudiantes
                setNotasEstudiantes(data.notasEstudiantes || []);

                // Extraer `notasAsignaturaNoInfluye` de cada estudiante
                const asignaturasNoInfluyen = (data.notasEstudiantes || []).flatMap(estudiante => estudiante.notasAsignaturaNoInfluye || []);
                setNotasAsignaturaNoInfluye(asignaturasNoInfluyen);

                // Procesar asignaturas con concepto
                const asignaturasConConceptoTemp = [];
                (data.notasEstudiantes || []).forEach(estudiante => {
                    (estudiante.notasAsignatura || []).forEach(asignatura => {
                        if (asignatura.asignatura.usaConcepto) {
                            asignaturasConConceptoTemp.push(asignatura);
                        }
                    });
                });

                setAsignaturasConConcepto(asignaturasConConceptoTemp);

            } catch (error) {
                setError(error.response ? error.response.data.message : error.message);
            } finally {
                setCargando(false);
            }
        };

        if (cursoId) {
            fetchInformeUnidadesGlobal();
        }
    }, [cursoId, estudianteId]);

    // Función para obtener la escala con el formato adecuado
    const obtenerEscalasFormateadas = () => {
        if (!listadoEscalas || listadoEscalas.length === 0) return null;

        const escalasFormateadas = listadoEscalas.map(escala => ({
            cntEscalas: escala.cntEscalas,
            detalle: escala.escala[0]?.escalaRangos.map(rango => ({
                concepto: rango.escalaRangoConcepto,
                descripcion: rango.escalaRangoConceptoDescripcion
            }))
        }));

        return escalasFormateadas;
    };

    return { 
        dataColegio, 
        dataDirector,  
        dataCurso, 
        columnaUnidades, 
        notasEstudiantes, 
        notasAsignaturaNoInfluye, 
        listadoEscalas: obtenerEscalasFormateadas(), 
        asignaturasConConcepto, 
        cantidadDecimales, 
        cargando, 
        error 
    };
};

export default useGetInformeUnidadesGlobal;
