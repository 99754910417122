// FichaCurso.jsx
import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import TabNavigator from "../../components/tabNavigator/TabNavigator";
import CustomTooltip from "../../components/tooltip/Tooltip";
import TabAsignaturas from "./tabs/TabAsignaturas";
import TabEstudiantes from "./tabs/TabEstudiantes";
import TabFormato from "./tabs/TabFormato";
import TabGeneral from "./tabs/TabGeneral";
import TabTalleres from "./tabs/TabTalleres";

const FichaCurso = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const curso = location.state?.curso;
  // console.log("curso:", curso);
  const [activeTab, setActiveTab] = useState("general");
  const [cursoState, setCursoState] = useState(curso);

  // Lista de pestañas con sus componentes
  const listaPestanias = [
    { eventKey: "general", name: "General", component: TabGeneral, isSelected: true },
    { eventKey: "asignaturas", name: "Asignaturas", component: TabAsignaturas, isSelected: true },
    { eventKey: "talleres", name: "Talleres", component: TabTalleres, isSelected: true },
    { eventKey: "estudiantes", name: "Estudiantes", component: TabEstudiantes, isSelected: true },
    { eventKey: "formato", name: "Formato", component: TabFormato, isSelected: true },
  ];


  return (
    <Container fluid className="px-4" style={{ overflow: "hidden", height: "90vh" }}>
      <TabNavigator
        title={`Ficha Curso - ${cursoState?.cursoNombre || " "}`} 
        activeTab={activeTab}
        onSelectTab={setActiveTab}
        tabList={listaPestanias}
        additionalProps={{
          cursoState 
        }}
      />
      <Row className="mt-auto w-100 py-2">
        <Col className="d-flex justify-content-center justify-content-md-end align-items-center gap-3">
          <CustomTooltip tooltipMsg={"Ir a la lista"}>
            <Button
              variant="color-gray-outline"
              onClick={() => navigate("/listado-cursos")}
            >
              Ir Lista
            </Button>
          </CustomTooltip>
          <CustomTooltip tooltipMsg={false ? "No tienes permisos" : "Guardar"}>
            <div style={{ display: "block", alignItems: "center" }}>
              {/* <Button
                variant="color-acento"
                onClick={handleSaveFicha}
                disabled={false}
              >
                Guardar
              </Button> */}
            </div>
          </CustomTooltip>
        </Col>
      </Row>
    </Container>
  );
};

export default FichaCurso;
