import moment from 'moment';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import useGetListadoCursos from '../../../hooks/useGetListadoCursos';
import useGetSubvencionMesCurso from '../../../hooks/useGetSubvencionMesCursos';
import NavAsistenciaMensualSubvencionCursos from '../../components/asistenciaMensualSubvencionesCursos/NavAsistenciaMensualSubvencionCursos';
import TablaAsistenciaMensualSubvencionCursos from '../../components/asistenciaMensualSubvencionesCursos/TablaAsistenciaMensualSubvencionCursos';
import GuiaEstados from '../../components/guiaestados/GuiaEstados';
import NotificacionError from '../../components/notificacion/NotificacionError';
import NotificacionToast from '../../components/notificacion/NotificacionToast';
import CustomSpinner from '../../components/spinner/Spinner';

const AsistenciaMensualSubvencionesCursos = () => {
  const [fecha, setFecha] = useState(new Date());
  const mes = fecha.getMonth() + 1;
  const { data: datosAsistencia, loading: cargandoDatos, error: errorDatos } = useGetSubvencionMesCurso(mes);
  const { cursos, cargando: cargandoCursos, error: errorCursos } = useGetListadoCursos();
  const [cursosSeleccionados, setCursosSeleccionados] = useState([]);
  const [mensajeToast, setMensajeToast] = useState('');
  const [mostrarToast, setMostrarToast] = useState(false);
  const [mostrarError, setMostrarError] = useState(false);
  const [page, setPage] = useState(1);

  // Obtener los roles del usuario desde userDetails
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  console.log("Contenido completo de userDetails:", userDetails);

  // Acceso específico a los roles del usuario
  const roles = userDetails?.user?.roles || [];
  console.log("Roles obtenidos del usuario:", roles);

  // Verificar si el usuario tiene exclusivamente el rol de Docente
  const isDocente = roles.some((rol) => rol?.rol?.rolNombre === "Docente");
  const isSoloDocente = roles.length === 1 && isDocente;

  console.log("¿Usuario tiene rol de Docente?", isDocente);
  console.log("¿Es Solo Docente?", isSoloDocente);

  const handleCursoSelect = (cursoIds) => {
    setCursosSeleccionados(cursoIds);
  };

  const handlePrev = () => {
    const nuevaFecha = new Date(fecha.getFullYear(), fecha.getMonth() - 1);
    setFecha(nuevaFecha);
    setMostrarError(false);
  };

  const handleNext = () => {
    const nuevaFecha = new Date(fecha.getFullYear(), fecha.getMonth() + 1);
    if (moment(nuevaFecha).isAfter(moment(), 'month')) {
      setMostrarError(true);
    } else {
      setFecha(nuevaFecha);
      setMostrarError(false);
    }
  };

  const cerrarError = () => setMostrarError(false);

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  if (cargandoCursos || cargandoDatos) return (
    <Container fluid className="bg-white p-3" style={{ display: 'flex', flexDirection: 'column', height: '85%' }}>
      <div className="text-center d-flex justify-content-center align-items-center h-100">
        <CustomSpinner />
      </div>
    </Container>
  );

  if (errorCursos) return (
    <Container fluid className="bg-white p-3" style={{ display: 'flex', flexDirection: 'column', height: '70vh' }}>
      <div className="alert alert-danger text-center mt-3">
        Error al cargar los datos de cursos: {errorCursos.message}
      </div>
    </Container>
  );

  if (errorDatos) return (
    <Container fluid className="bg-white p-3" style={{ display: 'flex', flexDirection: 'column', height: '70vh' }}>
      <div className="alert alert-danger text-center mt-3">
        Error al cargar los datos de asistencia: {errorDatos.message}
      </div>
    </Container>
  );

  const cursosFiltrados = cursosSeleccionados.length > 0
    ? datosAsistencia.filter(curso => cursosSeleccionados.includes(curso.cursoId))
    : datosAsistencia;

  return (
    <Container fluid className="bg-white p-3" style={{ height: '92dvh', display: 'flex', flexDirection: 'column' }}>
      <NotificacionError visible={mostrarError} onClose={cerrarError} />
      <NavAsistenciaMensualSubvencionCursos
        fecha={fecha}
        onPrev={handlePrev}
        onNext={handleNext}
        cursosSeleccionados={cursosSeleccionados}
        onCursoSelect={handleCursoSelect}
        mes={mes}
        setMensajeToast={setMensajeToast}
        setMostrarToast={setMostrarToast}
      />
      <div style={{ flex: '1', overflowY: 'auto' }}>
        {cursosFiltrados.length === 0 ? (
          <div className="alert alert-info text-center mt-3">
            No hay datos de asistencia disponibles.
          </div>
        ) : (
          <TablaAsistenciaMensualSubvencionCursos
            datosAsistencia={cursosFiltrados}
            mes={mes}
            fetchMoreData={fetchMoreData}
            isSoloDocente={isSoloDocente}
          />
        )}
      </div>
      <Container fluid className="bg-white p-3 mt-0 mt-md-0 pt-2" style={{ overflow: 'hidden' }}>
        <Row className="align-items-center justify-content-between flex-wrap">
          <Col
            xs={12}
            md={{ span: 6, offset: 3 }}
            className="d-flex justify-content-center mt-0 mt-md-0 order-md-1"
          >
            <GuiaEstados />
          </Col>
        </Row>
      </Container>
      {mostrarToast && (
        <div style={{ flex: '0 1 auto' }}>
          <NotificacionToast
            mensaje={mensajeToast}
            mostrar={mostrarToast}
            setMostrar={setMostrarToast}
          />
        </div>
      )}
    </Container>
  );
};

export default AsistenciaMensualSubvencionesCursos;
