import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import TextInput from "../../../../components/inputs/TextInput";
import { Button, Col, Row } from "react-bootstrap";
import EmailInput from "../../../../components/inputs/EmailInput";
import PhoneNumberInput from "../../../../components/inputs/PhoneNumberInput";
import SelectSingle from "../../../../components/inputs/SelectSingle";
import Avatar from "../../../../components/avatar/Avatar";
import "../../../../../assets/css/estilosCambar.css";
import RunInput from "../../../../components/inputs/RunInput";
import DatePickerInput from "../../../../components/inputs/DatePickerInput";
import CheckboxInput from "../../../../components/inputs/CheckboxInput";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPwd,
  getSchoolInfo,
  resetPwd,
} from "../../../../../services/PostsService";
import {
  setCurrentSchool,
  setRoles,
} from "../../../../../store/actions/AuthActions";
import { limpiarPuntosYGuiones } from "../../../../../util/util";
import SwitchInput from "../../../../components/inputs/SwitchInput";
import Swal from "sweetalert2";
import CustomTooltip from "../../../../components/tooltip/Tooltip";


function TabPersonal({
  isFichaCargada,
  isReadyToUploadAvatar,
  funcionario,
  setFuncionario,
  etnias,
  generos,
  identidadesGenero,
  regiones,
  comunas,
  nacionalidades,
  errors,
  setErrors,
  rolesSeleccionados,
  setRolesSeleccionados,
  isDirectivo
}) {
  const [filteredComunas, setFilteredComunas] = useState(comunas);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedComuna, setSelectedComuna] = useState("");
  const dispatch = useDispatch();
  const currentSchoolState = useSelector((state) => state.auth.currentSchool);

  const initialLoad = useRef(true); // Variable para controlar la primera carga

  const runInputRef = useRef(null); // Referencia para el input de RUN

  // console.log("TabPersonal");
  const handleResetPassword = (run) => {
    const runToChange = limpiarPuntosYGuiones(run.split("-")[0]);

    resetPwd(runToChange)
      .then((response) => {
        // console.log("Contraseña reestablecida", response);
        if (response) {
          Swal.fire({
            icon: "warning",
            title: "¿Estás seguro?",
            html: `<p>¿Deseas restablecer la contraseña de <br /> <strong>${funcionario.personal.nombre} ${funcionario.personal.apellidoPaterno}</strong>?</p>`,
            showCancelButton: true,
            confirmButtonText: "Restablecer",
            cancelButtonText: "Cancelar",
            confirmButtonColor: "var(--color-acento)",
            cancelButtonColor: "var(--color-rojo)",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              // Si el usuario confirma, muestra el mensaje de éxito
              Swal.fire({
                icon: "success",
                title: "Contraseña reestablecida",
                html: `<p>La contraseña de <strong>${funcionario.personal.nombre} ${funcionario.personal.apellidoPaterno}</strong> ha sido restablecida correctamente.</p>`,
                confirmButtonColor: "var(--color-acento)",
              });
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error al reestablecer contraseña",
            text: `Ocurrió un error al reestablecer la contraseña del funcionario con RUN ${run}. Por favor, intenta nuevamente.`,
            confirmButtonColor: "var(--color-acento)",
          });
        }
      })
      .catch((error) => {
        console.error("Error al reestablecer contraseña", error);
        Swal.fire({
          icon: "error",
          title: "Error al reestablecer contraseña",
          text: `Ocurrió un error al reestablecer la contraseña del funcionario con RUN ${run}. Por favor, intenta nuevamente.`,
          confirmButtonColor: "var(--color-acento)",
        });
      });
  };

  const manejarCambioCheckbox = (role) => {
    setRolesSeleccionados((prevRoles) => {
      const updatedRoles = { ...prevRoles, [role]: !prevRoles[role] };

      setFuncionario((prevFuncionario) => ({
        ...prevFuncionario,
        [role]: {
          ...prevFuncionario[role],
          [`${role}Enabled`]: updatedRoles[role],
        },
      }));

      return updatedRoles;
    });
  };

  // Función para actualizar `estadoIdoneidad`
  const handleSwitchToggle = (newValue) => {
    console.log("Switch value", newValue);
    // setSwitchValue(newValue);
    setFuncionario((prev) => ({
      ...prev,
      estadoIdoneidad: newValue,
    }));
  };

  const manejarCambioFecha = (date, id) => {
    if (date) {
      const formattedDate = new Date(date).toISOString(); // Asegúrate de formatear correctamente la fecha
      setFuncionario((prevValues) => ({
        ...prevValues,
        personal: {
          ...prevValues.personal,
          [id]: formattedDate, // Guardar la fecha en formato ISO para evitar inconsistencias
        },
      }));
    }
  };




  useEffect(() => {
    if (runInputRef.current) {
      runInputRef.current.focus(); // Enfoca el input del RUN
    }
  }, []);
  // Filtra las comunas según la región seleccionada
  useEffect(() => {
    if (funcionario.personal.region) {
      const regionId = funcionario.personal.region; // ID de la región seleccionada
      const comunasFiltradas = comunas.filter(
        (comuna) => comuna.region.id === regionId
      );
      setFilteredComunas(comunasFiltradas);

      // Solo reinicia la comuna si la comuna actual no pertenece a la nueva región
      if (
        funcionario.personal.comuna &&
        !comunasFiltradas.some((c) => c.id === funcionario.personal.comuna)
      ) {
        setFuncionario((prev) => ({
          ...prev,
          personal: {
            ...prev.personal,
            comuna: "", // Reinicia la comuna si la comuna actual no pertenece a la nueva región
          },
        }));
        setSelectedComuna(""); // También reinicia la comuna seleccionada en el selector
      }
    } else {
      setFilteredComunas(comunas); // Muestra todas las comunas si no hay región seleccionada
    }
  }, [
    funcionario.personal.region,
    comunas,
    setFuncionario,
    funcionario.personal.comuna,
  ]);

  // Actualiza selectedRegion y selectedComuna cuando cambian los datos
  useEffect(() => {
    // console.log(selectedComuna)
    if (regiones.length > 0 && comunas.length > 0) {
      if (!currentSchoolState && initialLoad.current) {
        getSchoolInfo(JSON.parse(localStorage.getItem("currentSchool")).rbd)
          .then((response) => {
            if (response) {
              const { region, comuna } = response.data;

              // Setear la región primero
              setFuncionario((prevFuncionario) => ({
                ...prevFuncionario,
                personal: {
                  ...prevFuncionario.personal,
                  region: region?.id,
                },
              }));

              setSelectedRegion(region?.id);

              // Filtrar las comunas correspondientes a la región antes de setear la comuna

              // Solo setear la comuna si está en las comunas filtradas
              if (
                filteredComunas.length > 0 &&
                filteredComunas.some((c) => c.id === comuna.id)
              ) {
                setSelectedComuna(comuna.id);
                setFuncionario((prevFuncionario) => ({
                  ...prevFuncionario,
                  personal: {
                    ...prevFuncionario.personal,
                    comuna: comuna?.id,
                  },
                }));
              }
              dispatch(setCurrentSchool(response.data));
            }
          })
          .catch((error) => {
            console.error("Error al cargar la escuela:", error);
          })
          .finally(() => {
            initialLoad.current = false; // Una vez inicializado, no se vuelve a setear
          });
      } else if (currentSchoolState && initialLoad.current && !isFichaCargada) {
        const { region, comuna } = currentSchoolState;
        // Setear la región primero
        setFuncionario((prevFuncionario) => ({
          ...prevFuncionario,
          personal: {
            ...prevFuncionario.personal,
            region: region?.id,
          },
        }));

        setSelectedRegion(region?.id);

        // Filtrar las comunas correspondientes a la región antes de setear la comuna
        if (filteredComunas.length === 0) {
          setFilteredComunas(
            comunas.filter((comuna) => {
              // console.log(comuna);
              return comuna.region?.id === region?.id;
            })
          );
        }

        let initialComuna = comunas
          .filter((comuna) => {
            // console.log(comuna);
            return comuna.region.id === region.id;
          })
          .find((c) => c.id === comuna.id);

        // console.log("initialComuna", initialComuna);

        setSelectedComuna(initialComuna.id);
        setFuncionario((prevFuncionario) => ({
          ...prevFuncionario,
          personal: {
            ...prevFuncionario.personal,
            comuna: initialComuna.id,
          },
        }));

        initialLoad.current = false; // Se desactiva para evitar que se setee nuevamente
      }
    }
  }, [
    currentSchoolState,
    comunas,
    dispatch,
    setFuncionario,
    filteredComunas,
    regiones,
    isFichaCargada,
  ]);

  // Este efecto se asegura de que solo se resetee la comuna cuando cambie la región de manera manual
  useEffect(() => {
    if (selectedRegion) {
      const comunasFiltradas = comunas.filter(
        (comuna) => comuna.region.id === selectedRegion
      );
      setFilteredComunas(comunasFiltradas);

      if (
        selectedComuna &&
        !comunasFiltradas.some((c) => c.id === selectedComuna)
      ) {
        setSelectedComuna(""); // Reinicia la comuna seleccionada si no pertenece a la nueva región
        setFuncionario((prev) => ({
          ...prev,
          personal: {
            ...prev.personal,
            comuna: "", // Reinicia la comuna en el estado del funcionario
          },
        }));
      }
    } else {
      setFilteredComunas(comunas); // Muestra todas las comunas si no hay región seleccionada
    }
  }, [selectedRegion, selectedComuna, comunas, setFuncionario]);

  // Este efecto asegura que selectedRegion y selectedComuna se sincronicen con los valores de funcionario cuando se carga la ficha
useEffect(() => {
  if (funcionario.personal.region) {
    setSelectedRegion(funcionario.personal.region);
  }
  if (funcionario.personal.comuna) {
    setSelectedComuna(funcionario.personal.comuna);
  }
}, [funcionario.personal.region, funcionario.personal.comuna]);

// Este efecto actualiza filteredComunas cuando cambia selectedRegion
useEffect(() => {
  if (selectedRegion) {
    const comunasFiltradas = comunas.filter(
      (comuna) => comuna.region.id === selectedRegion
    );
    setFilteredComunas(comunasFiltradas);
  } else {
    setFilteredComunas(comunas); // Muestra todas las comunas si no hay región seleccionada
  }
}, [selectedRegion, comunas]);



  useEffect(() => {
        console.log("Funcionario", funcionario);
  }, [funcionario]);


  
  useEffect(() => {
    console.log("roles");
  }, []);

  return (
    <div style={{ height: "75dvh" }}>
      {/* SECCIÓN DE DATOS PERSONALES */}
      <article className="d-flex flex-column flex-md-row flex-md-wrap justify-content-start align-items-center w-100 py-2 ">
        {/* Section avatar + RUN */}
        <section
          className="d-flex flex-column justify-content-center align-items-center width-20 gap-2"
          style={{
            padding: "1rem",
            height: "fit-content",
            // backgroundColor: "red",
          }}
        >
          <Avatar
            enableUpload
            run={limpiarPuntosYGuiones(funcionario.personal.run.split("-")[0])}
            isReadyToUpload={isReadyToUploadAvatar}
          />
          <RunInput
            ref={runInputRef}
            controlId="run"
            value={funcionario.personal.run || ""}
            // placeholder={"RUN"}
            errors={errors}
            setErrors={setErrors}
            setValores={setFuncionario}
            maxLength={12}
            style={{ width: "100%" }}
            required
            label={"RUN"}
            statePath="personal.run" // Actualiza directamente el objeto anidado
          />
          <CustomTooltip tooltipMsg={!isDirectivo ? 'No tienes permisos para hacer esto' : 'Reestablece la contraseña del funcionario en el sistema'}>
            <div>
              <Button
                variant="color-amarillo-outline"
                onClick={() => handleResetPassword(funcionario.personal.run)}
                disabled={!isDirectivo}
                style={{ display: isFichaCargada && isDirectivo ? "block" : "none" }}
              >
                Reestablecer Contraseña
              </Button>
            </div>
          </CustomTooltip>
        </section>
        <section
          className="gap-3 d-flex flex-column justify-content-center align-items-center width-40"
          style={{
            height: "100%",
            padding: "0.5rem 0",
          }}
        >
          <Row className="w-100">
            <Col className="col-12 col-md-6">
              <TextInput
                onChange={(e) =>
                  setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      apellidoPaterno: e.target.value,
                    },
                  }))
                }
                value={funcionario.personal.apellidoPaterno}
                label={"Apellido Paterno"}
                required
              />
            </Col>
            <Col className="col-12 col-md-6">
              <TextInput
                onChange={(e) =>
                  setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      apellidoMaterno: e.target.value,
                    },
                  }))
                }
                value={funcionario.personal.apellidoMaterno}
                label={"Apellido Materno"}
              />
            </Col>
          </Row>
          <Row className="w-100">
            <Col className="col-12 col-md-6">
              <TextInput
                onChange={(e) =>
                  setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      nombre: e.target.value,
                    },
                  }))
                }
                value={funcionario.personal.nombre}
                label={"Nombre Primario"}
                required
              />
            </Col>
            <Col className="col-12 col-md-6">
              <TextInput
                onChange={(e) =>
                  setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      nombreSecundario: e.target.value,
                    },
                  }))
                }
                value={funcionario.personal.nombreSecundario}
                label={"Nombre Secundario"}
              />
            </Col>
          </Row>

          <Row className="w-100">
            <Col className="col-12 col-md-6">
              <EmailInput
                onChange={(e) =>
                  setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      email: e.target.value,
                    },
                  }))
                }
                value={funcionario.personal.email}
                label={"Email Personal"}
              />
            </Col>
            <Col className="col-12 col-md-6">
              <EmailInput
                onChange={(e) =>
                  setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      emailInstitucional: e.target.value,
                    },
                  }))
                }
                value={funcionario.personal.emailInstitucional}
                label={"Email Institucional"}
              />
            </Col>
          </Row>
        </section>
        <section
          className="gap-3 d-flex flex-column justify-content-center align-items-center width-40"
          style={{
            padding: "0.55rem 0",
            // backgroundColor: 'red'
          }}
        >
          <Row className="w-100">
            <Col className="col-12 col-md-4">
              <PhoneNumberInput
                controlId="telefonoMovil"
                onChange={(value) =>
                  setFuncionario((prevValues) => ({
                    ...prevValues,
                    personal: {
                      ...prevValues.personal,
                      telefono: value,
                    },
                  }))
                }
                maxLength={15}
                countries={nacionalidades}
                disabled={false}
                value={funcionario.personal.telefono || ""}
              />
            </Col>
            <Col className="col-12 col-md-4">
              <DatePickerInput
                controlId="fechaNacimiento"
                selected={
                  funcionario.personal.fechaNacimiento
                    ? new Date(funcionario.personal.fechaNacimiento)
                    : null
                }
                onChange={(date) => manejarCambioFecha(date, "fechaNacimiento")}
                dateFormat={"dd/MM/yyyy"}
                placeholderText={"dd/mm/aaaa"}
                locale={"es"}
                label="Fecha de Nacimiento"
              />
            </Col>
            <Col className="col-12 col-md-4">
              <SelectSingle
                controlId="genero"
                placeholder="Género Registral"
                elements={generos.map((genero) => ({
                  id: genero.id,
                  name: genero.generoNombre,
                }))}
                idKey="id"
                labelKey="name"
                selectedElement={funcionario.personal.genero}
                onSelect={(genero) => {
                  return setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      genero: genero.id,
                    },
                  }));
                }}
              />
            </Col>
          </Row>
          <Row className="w-100">
            <Col className="col-12 col-md-4">
              <SelectSingle
                controlId="identidadGenero"
                placeholder="Identidad de Género"
                elements={identidadesGenero.map((genero) => ({
                  id: genero.id,
                  name: genero.generoIdentidadNombre,
                }))}
                idKey="id"
                labelKey="name"
                selectedElement={funcionario.personal.identidadGenero}
                onSelect={(genero) => {
                  return setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      identidadGenero: genero.id,
                    },
                  }));
                }}
              />
            </Col>
            <Col className="col-12 col-md-4">
              <SelectSingle
                controlId="nacionalidad"
                placeholder="Nacionalidad"
                elements={nacionalidades.map((nacionalidad) => ({
                  id: nacionalidad.id,
                  name: nacionalidad.name,
                }))}
                idKey="id"
                labelKey="name"
                selectedElement={funcionario.personal.nacionalidad}
                onSelect={(nacionalidad) => {
                  return setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      nacionalidad: nacionalidad.id,
                    },
                  }));
                }}
              />
            </Col>
            <Col className="col-12 col-md-4">
              <SelectSingle
                controlId="etnia"
                placeholder="Etnia"
                elements={etnias.map((etnia) => ({
                  id: etnia.id,
                  name: etnia.pueblosOriginariosNombre,
                }))}
                idKey="id"
                labelKey="name"
                selectedElement={funcionario.personal.etnia}
                onSelect={(etnia) => {
                  return setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      etnia: etnia.id,
                    },
                  }));
                }}
              />
            </Col>
          </Row>
          <Row className="w-100">
            <Col className="col-12 col-md-4">
              <SelectSingle
                controlId="region"
                placeholder="Región"
                elements={regiones.map((region) => ({
                  id: region.id,
                  name: region.regionNombre,
                }))}
                idKey="id"
                labelKey="name"
                onSelect={(region) => {
                  // console.log(region);
                  setSelectedRegion(region?.id); // Resetea la comuna seleccionada
                  setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      region: region?.id, // Guardar solo el ID de la región
                      comuna: "", // Reinicia la comuna si cambias de región
                    },
                  }));
                }}
                selectedElement={selectedRegion}
              />
            </Col>
            <Col className="col-12 col-md-4">
              <SelectSingle
                controlId="comuna"
                placeholder="Comuna"
                elements={filteredComunas.map((comuna) => ({
                  id: comuna?.id,
                  name: comuna?.comunaNombre,
                }))}
                idKey="id"
                labelKey="name"
                onSelect={(comuna) => {
                  // console.log(comuna);
                  setSelectedComuna(comuna?.id);
                  setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      comuna: comuna?.id, // Guardar solo el ID de la comuna
                    },
                  }));
                }}
                selectedElement={selectedComuna}
              />
            </Col>
            <Col className="col-12 col-md-4">
              <TextInput
                value={funcionario.personal.direccion}
                label={"Dirección"}
                onChange={(e) =>
                  setFuncionario((prev) => ({
                    ...prev,
                    personal: {
                      ...prev.personal,
                      direccion: e.target.value,
                    },
                  }))
                }
              />
            </Col>
          </Row>
        </section>
        <section className="w-100" style={{ paddingLeft: "24.2rem" }}>
          <Row style={{}}>
            <Col className="col-12 col-md-2">
              <TextInput
                value={funcionario.contratoHoras || ""}
                label={"Horas Contrato"}
                placeholder="Horas Contrato"
                onChange={(e) =>
                  setFuncionario((prev) => ({
                    ...prev,
                    contratoHoras: e.target.value,
                  }))
                }
                style={{
                  // border: "1px solid var(--color-acento)",
                  color: "var(--color-texto-oscuro)",
                }}
                type="numeric"
                minValue={0}
                maxValue={44}
              />
            </Col>
            <Col className="col-12 col-md-2">
              <SwitchInput
                value={funcionario.estadoIdoneidad}
                onToggle={handleSwitchToggle}
                label={"Estado Idoneidad"}
                option1={"NO OK"}
                option2={"OK"}
              />
            </Col>
          </Row>
        </section>
      </article>

      {/* SECCIÓN DE ROLES */}
      <article className="d-flex flex-row justify-content-start align-items-center w-100">
        <section
          className="py-2"
          style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
        >
          <section className="d-flex flex-row justify-content-center align-items-center width-20">
            <h5>Roles funcionario</h5>
          </section>
          <Row style={{ width: "80%", gap: "10rem" }}>
            <Col className="col-12 col-md-auto">
              <CheckboxInput
                controlId="directivo"
                label={"Directivo"}
                checked={rolesSeleccionados.directivo}
                onChange={() => manejarCambioCheckbox("directivo")}
                // onChange={() => {}}
                style={{ color: "var(--color-texto-oscuro)" }}
              />
            </Col>
            <Col className="col-12 col-md-auto">
              <CheckboxInput
                controlId="profesor"
                label={"Docente"}
                checked={rolesSeleccionados.profesor}
                onChange={() => manejarCambioCheckbox("profesor")}
                // onChange={() =>{}}
                style={{ color: "var(--color-texto-oscuro)" }}
              />
            </Col>

            <Col className="col-12 col-md-auto">
              <CheckboxInput
                controlId="administrativo"
                label={"Administrativo"}
                checked={rolesSeleccionados.administrativo}
                onChange={() => manejarCambioCheckbox("administrativo")}
                // onChange={() =>{}}
                style={{ color: "var(--color-texto-oscuro)" }}
              />
            </Col>

            <Col className="col-12 col-md-auto">
              <CheckboxInput
                controlId="especialista"
                label={"Especialista"}
                checked={rolesSeleccionados.especialista}
                onChange={() => manejarCambioCheckbox("especialista")}
                // onChange={() => {}}
                style={{ color: "var(--color-texto-oscuro)" }}
              />
            </Col>

            <Col className="col-12 col-md-auto">
              <CheckboxInput
                controlId="auxiliar"
                label={"Auxiliar"}
                checked={rolesSeleccionados.auxiliar}
                onChange={() => manejarCambioCheckbox("auxiliar")}
                // onChange={() =>{}}
                style={{ color: "var(--color-texto-oscuro)" }}
              />
            </Col>
          </Row>
        </section>
      </article>
    </div>
  );
}

TabPersonal.propTypes = {
  funcionario: PropTypes.shape({
    personal: PropTypes.shape({
      run: PropTypes.string,
      nombre: PropTypes.string,
      nombreSecundario: PropTypes.string,
      apellidoPaterno: PropTypes.string,
      apellidoMaterno: PropTypes.string,
      email: PropTypes.string,
      telefono: PropTypes.string,
      region: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      comuna: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      direccion: PropTypes.string,
    }),
    laboral: PropTypes.arrayOf(
      PropTypes.shape({
        cargo: PropTypes.string,
        fechaIngreso: PropTypes.string,
        fechaTermino: PropTypes.string,
      })
    ),
  }),
  regiones: PropTypes.array.isRequired,
  comunas: PropTypes.array.isRequired,
};

export default TabPersonal;
