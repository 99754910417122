import React from "react";
import InConstruction from "../../../components/construction/InConstruction";



const TabAsignaturas = () => {
  return (
    <div style={{overflow: "hidden"}}>
      <h3>Asignaturas</h3>
      <p>Aquí se mostrará la información de las asignaturas del curso.</p>
      <InConstruction/>
      {/* <ListaAsignaturas /> */}

    </div>
  );
};

export default TabAsignaturas;
