import React from 'react';
import { Toast } from 'react-bootstrap';

const NotificacionToast = ({ mensaje, mostrar, setMostrar, estiloPersonalizado = {} }) => {
  const estilosToast = {
    position: 'fixed',
    bottom: '7dvh', 
    left: '50%',  
    transform: 'translateX(-50%)', 
    backgroundColor: 'green',
    color: 'white',
    zIndex: 1051,
    padding: '0.2rem', 
    fontSize: '1rem', 
    textAlign: "center",
    ...estiloPersonalizado, 
  };

  return (
    <Toast
      onClose={() => setMostrar(false)}
      show={mostrar}
      delay={3250}
      autohide
      style={estilosToast}
    >
      <Toast.Body>{mensaje}</Toast.Body>
    </Toast>
  );
};

export default React.memo(NotificacionToast);
