import React, { forwardRef, useState } from "react";
import { Form } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";
import CustomTooltip from "../tooltip/Tooltip";
import { formatearIPEIPA, validarIPE } from "../../../util/util";

const IpeInput = forwardRef(({
  controlId,
  required,
  tooltipMessage,
  label,
  name,
  value,
  disabled,
  placeholder,
  onBlur,
  onInput,
  maxLength,
  onPaste,
  hideLabel,
  errors,
  setErrors,
  setValores,
  index,
  statePath,
  style,
  onBlurParent,
  noValidate, // Nueva prop para deshabilitar la validación
}, ref) => {
  const [isValid, setIsValid] = useState(true);

  const manejarCambioTexto = (value) => {
    value = value.replace(/[^0-9Kk.-]/g, "");
    value = formatearIPEIPA(value);

    setValores((prevValues) => {
      if (typeof index === "number") {
        const updatedValues = [...prevValues];
        updatedValues[index] = {
          ...updatedValues[index],
          [statePath]: value,
        };
        return updatedValues;
      } else {
        const pathParts = statePath.split(".");
        const newValues = { ...prevValues };
        let current = newValues;

        pathParts.slice(0, -1).forEach((part) => {
          current[part] = current[part] || {};
          current = current[part];
        });

        current[pathParts[pathParts.length - 1]] = value;
        return newValues;
      }
    });

    if (!noValidate) {
      const isValid = validarIPE(value);
      if (!isValid) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [controlId]: "IPE inválido",
        }));
        setIsValid(false);
      } else {
        setErrors((prevErrors) => {
          const { [controlId]: _, ...rest } = prevErrors;
          return rest;
        });
        setIsValid(true);
      }
    }
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
    if (onBlurParent) {
      onBlurParent(event);
    }

    if (!noValidate) {
      const isValid = validarIPE(value);
      if (isValid) {
        setErrors((prevErrors) => {
          const { [controlId]: _, ...rest } = prevErrors;
          return rest;
        });
        setIsValid(true);
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [controlId]: "IPE inválido",
        }));
        setIsValid(false);
      }
    }
  };

  return (
    <Form.Group
      controlId={controlId}
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      {tooltipMessage && required && !hideLabel ? (
        <CustomTooltip
          tooltipMsg={
            tooltipMessage ? (
              <span>{tooltipMessage}</span>
            ) : (
              <span>Dato Requerido</span>
            )
          }
        >
          <Form.Label>
            {label} <span className="text-danger">**</span>
          </Form.Label>
        </CustomTooltip>
      ) : !hideLabel ? (
        <CustomTooltip tooltipMsg={<span>Dato Requerido</span>}>
          <Form.Label>
            {label}{" "}
            {required && (
              <span className="text-danger">{tooltipMessage ? "**" : "*"}</span>
            )}
          </Form.Label>
        </CustomTooltip>
      ) : null}

      <div className="position-relative w-100">
        <Form.Control
          ref={ref}
          type="text"
          name={name}
          value={value || ""}
          onChange={(event) => {
            manejarCambioTexto(
              event.target.value,
              setValores,
              setErrors,
              index
            );
          }}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onBlur={handleBlur}
          onInput={onInput}
          maxLength={maxLength}
          style={style}
          onPaste={onPaste}
        />

        {value && !isValid && !noValidate && (
          <div style={{ position: "absolute", top: 10, right: 10 }}>
            <FaExclamationCircle className="text-danger" />
          </div>
        )}

        {!noValidate && (
          <Form.Control.Feedback type="invalid">
            Por favor, introduce un IPE/IPA válido.
          </Form.Control.Feedback>
        )}
      </div>
    </Form.Group>
  );
});

export default IpeInput;
