import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Dropdown, Form, FormControl, FormGroup } from "react-bootstrap";
import { normalizeString } from "../../../util/util";
import CustomTooltip from "../tooltip/Tooltip";

const styles = {
  boton: {
    border: "1px solid lightgray",
    color: "gray",
    backgroundColor: "white",
    width: "100%",
    margin: "0",
  },
  menuDesplegable: {
    maxHeight: "250px",
    overflowY: "auto",
    width: "fit-content",
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "0",
    zIndex: 30,
  },
  stickyContainer: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    padding: "5px 0",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1050,
  },
  enlaceDesmarcarTodo: {
    fontSize: "0.9em",
    color: "#007bff",
    cursor: "pointer",
    textDecoration: "underline",
    margin: "5px 0",
  },
  itemSeleccionado: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  controlFormulario: {
    margin: "5px auto",
    padding: "5px",
    width: "80%",
    display: "block",
    boxSizing: "border-box",
    border: "1px solid #ced4da",
    borderRadius: "4px",
  },
  truncateText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

function SelectSingle({
  placeholder,
  elements = [],
  selectedElement = null,
  onSelect,
  labelKey = "name",
  idKey = "id",
  dropDirection = "down",
  required = false,
  controlId,
  tooltipMessage,
  disabled = false,
  style = {},
  hideLabel = false,
  truncateText = true, //Propiedad para manejar truncamiento de texto
  optionRenderer, // Nueva prop para permitir renderizado personalizado de opciones
}) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [selected, setSelected] = useState(selectedElement);

  
  const getTextColor = (backgroundColor) => {
    return backgroundColor === "#DDDDDD" ? "black" : "white";
  };

  // Lógica de selección
  const handleSelect = (elemento) => {
    if (selected && selected[idKey] === elemento[idKey]) {
      setSelected(null);
      onSelect(null);
    } else {
      setSelected(elemento);
      onSelect(elemento);
    }
    setMenuVisible(false);
  };

  const handleSearch = (value) => {
    setFiltro(value);
  };

  const getMenuStyle = () => {
    let style = { ...styles.menuDesplegable };

    if (dropDirection === "up") {
      style.bottom = "100%";
      style.top = "auto";
    } else if (dropDirection === "left") {
      style.right = "100%";
      style.left = "auto";
    } else if (dropDirection === "right") {
      style.left = "100%";
      style.right = "auto";
    }

    return style;
  };

  const filteredElements = elements
    ? elements.filter((elemento) => {
        return normalizeString(elemento[labelKey]).includes(normalizeString(filtro));
      })
    : [];

  // Efecto para manejar cuando selectedElement cambia
  useEffect(() => {
    if (selectedElement) {
      const selectedObj = elements.find((el) => el[idKey] === selectedElement);
      setSelected(selectedObj);
    } else {
      setSelected(null); // Limpiar la selección cuando selectedElement es null
    }
  }, [selectedElement, elements, idKey]);


  return (
    <FormGroup controlId={controlId}>
      {required && !hideLabel ? (
        <CustomTooltip
          tooltipMsg={
            tooltipMessage ? (
              <span>{tooltipMessage}</span>
            ) : (
              <span>Dato Requerido</span>
            )
          }
        >
          <Form.Label>
            {placeholder}{" "}
            {required && tooltipMessage && (
              <span className="text-danger">**</span>
            )}
            {required && !tooltipMessage && (
              <span className="text-danger">*</span>
            )}
          </Form.Label>
        </CustomTooltip>
      ) : !hideLabel ? (
        <Form.Label>{placeholder}</Form.Label>
      ) : null}
      <Dropdown
        className="dropdown-elemento m-0 p-0"
        show={menuVisible}
        onToggle={() => setMenuVisible(!menuVisible)}
        drop={dropDirection}
      >
        <CustomTooltip tooltipMsg={selected && selected[labelKey] ? selected[labelKey] : placeholder}>
        <Dropdown.Toggle
            style={{
              ...styles.boton,
              ...style,
              ...(truncateText && styles.truncateText), // Aplica truncamiento si `truncateText` es true
              ...(selected && optionRenderer && {
                backgroundColor: optionRenderer(selected).props.style.backgroundColor, // Aplica el color del optionRenderer si existe
                color: getTextColor(optionRenderer(selected).props.style.backgroundColor), // Aplica el color del texto basado en el color de fondo
              }),
            }}
            onClick={() => setMenuVisible(!menuVisible)}
            disabled={disabled}
          >
            {selected && selected[labelKey] ? selected[labelKey] : placeholder}
          </Dropdown.Toggle>
        </CustomTooltip>
        <Dropdown.Menu style={getMenuStyle()} align="start" show={menuVisible}>
          <div style={styles.stickyContainer}>
            <FormControl
              autoFocus
              placeholder={`Buscar...`}
              value={filtro}
              onChange={(e) => handleSearch(e.target.value)}
              style={styles.controlFormulario}
              autoComplete="off"
            />
          </div>
          {filteredElements.map((elemento, index) => (
            <div
              key={index}
              className="w-100"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              // style={optionRenderer ? { backgroundColor: "var(--color-gris-claro)" } : {backgroundColor: "white"}}
            >
              <Dropdown.Item
                active={selected && selected[idKey] === elemento[idKey]}
                style={
                  selected && selected[idKey] === elemento[idKey]
                    ? { color: "var(--color-acento)" }
                    : {}
                }
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect(elemento);
                  }}
                  style={{
                    ...styles.itemSeleccionado,
                    ...(truncateText ? styles.truncateText : {}),
                  }}
                >
                  {optionRenderer
                    ? optionRenderer(elemento) // Si `optionRenderer` está definido, se usa para renderizar la opción
                    : elemento[labelKey]}       
                  {selected && selected[idKey] === elemento[idKey] && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "currentColor", marginLeft: "5px" }}
                    />
                  )}
                </div>
              </Dropdown.Item>
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </FormGroup>
  );
}

export default SelectSingle;
