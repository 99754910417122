import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getPeriodosByColegio } from "../../../services/MatriculaService";
import { getRecordsLog, getUsuariosByPeriodo } from "../../../services/PostsService";
import { formatearRUNSinDv } from "../../../util/util";
import RangoFechaSelector from "../../components/inputs/RangoFechaSelector";
import SelectSingle from "../../components/inputs/SelectSingle";
import DynamicTable from "../../components/tables/DynamicTable/Table";
import Title from "../../components/titles/Title";

export default function RegistroLog() {
    const pageSize = 1000;
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsLog, setRecordsLog] = useState(null);
    const [periodos, setPeriodos] = useState([]);
    const [periodo, setPeriodo] = useState(null);
    const [usuarios, setUsuarios] = useState([]);
    const [usuario, setUsuario] = useState(null);
    const [dateRange, setDateRange] = useState(null);
    const [device, setDevice] = useState(null);

    // Obtener los periodos
    useEffect(() => {
        getPeriodosByColegio().then((r) => {
            let lista = [];
            r.data.forEach(element => {
                const existe = lista.filter(l => l.id === element.id).length > 0;
                if (!existe) {
                    lista.push({ id: element.id, name: element.anio + '' });
                }
            });
            setPeriodos(lista);
            if (lista.length > 0) {
                setPeriodo(lista[0].id);
            }
        });
    }, []);

    // Obtener usuarios por periodo seleccionado
    useEffect(() => {
        if (periodo) {
            getUsuariosByPeriodo(periodo).then((r) => {
                setUsuarios(r.data);
            });
        }
    }, [periodo]);

    // Manejo de filtros y solicitud de registros
    useEffect(() => {
        if (periodo) {
            setRecordsLog(null); // Limpiar registros anteriores
            let filters = {};
            if (usuario) {
                filters['createdBy'] = usuario;
            }
            if (dateRange && dateRange.start && dateRange.end) {
                filters['dateRange'] = [dateRange.start.valueOf(), dateRange.end.valueOf()];
            }
            if (device) {
                filters['device'] = device;
            }

            const params = { page: currentPage, size: pageSize, periodoId: periodo, filters };
            getRecordsLog(params).then((r) => {
                setRecordsLog(r.data);
            });
        }
    }, [currentPage, periodo, usuario, dateRange, device]);

    // Función auxiliar para mapear la acción
    const getStrAction = (action) => {
        if (action === "LOGIN" || action === "LOGIN_SCHOOL") {
            return "Login";
        } else if (action === "LOGIN_ERROR") {
            return "Error de acceso";
        } else if (action === "LOGOUT") {
            return "Logout";
        } else if (action === "GET") {
            return "Lectura";
        } else if (action === "SEARCH") {
            return "Búsqueda";
        } else if (action === "ADD") {
            return "Añadir";
        } else if (action === "UPDATE") {
            return "Modificar";
        } else if (action === "DELETE") {
            return "Borrar";
        }
    };

    // Mapear los datos de recordsLog para DynamicTable
    const formattedData = useMemo(() => 
        recordsLog?.records.map(record => ({
            id: record.id, // Asegúrate de que haya un id único
            value: [
                record.ip,
                moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss'), // Formatear la fecha
                record.createdBy,
                getStrAction(record.action),
                record.entity,
                record.value,
                record.detail,
                record.device,
            ]
        })) || [], [recordsLog]);

    // Definir los encabezados para DynamicTable
    const headers = [
        { name: 'IP', width: '120px', sortable: true },
        { name: 'Fecha', width: '170px', sortable: true },
        { name: 'Usuario', width: '100px', sortable: true },
        { name: 'Acción', width: '100px', sortable: true },
        { name: 'Entidad', width: '100px', sortable: true },
        { name: 'Valor', width: '280px', sortable: true },
        { name: 'Detalle', width: 'auto', sortable: false, textAlign: 'left' },
        { name: 'Dispositivo', width: '130px', sortable: true },
    ];

    // Cambiar el usuario seleccionado
    const changeUsuario = (selectedUsuario) => {
        setUsuario(selectedUsuario ? selectedUsuario.id : null);
        setCurrentPage(1);
    };

    // Seleccionar rango de fechas
    const handleDateRangeSelect = (range) => {
        setDateRange(range);
    };

    // Seleccionar período
    const handlePeriodoSelect = (selectedPeriodo) => {
        setPeriodo(selectedPeriodo ? selectedPeriodo.id : null);
    };

    // Seleccionar dispositivo
    const handleDeviceSelect = (selectedDevice) => {
        setDevice(selectedDevice ? selectedDevice.id : null);
    };

    return (
        <Container fluid className="bg-white p-0 pt-0" style={{ height: "90dvh", overflowX: "hidden" }}>
            <Title text="Registro de Actividad" />
            <Row className="mb-0">
                <Col xs={12} md={2}>
                    <SelectSingle
                        hideLabel
                        placeholder="Período"
                        elements={periodos}
                        selectedElement={periodo}
                        onSelect={handlePeriodoSelect}
                        labelKey="name"
                        idKey="id"
                        style={{ border: "1px solid var(--color-acento)", color: "var(--color-texto-oscuro)" }}
                    />
                </Col>
                <Col xs={12} md={2}>
                    <SelectSingle
                        hideLabel
                        placeholder="Usuario"
                        elements={usuarios.map(u => ({ id: u.userName, name: formatearRUNSinDv(u.userName) }))}
                        selectedElement={usuario}
                        onSelect={changeUsuario}
                        labelKey="name"
                        idKey="id"
                        style={{ border: "1px solid var(--color-acento)", color: "var(--color-texto-oscuro)" }}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <RangoFechaSelector
                        placeholder="Selecciona un rango de fechas"
                        onSelect={handleDateRangeSelect}
                        style={{ border: "1px solid var(--color-acento)", color: "var(--color-texto-oscuro)" }}
                    />
                </Col>
                <Col xs={12} md={2}>
                    <SelectSingle
                        hideLabel
                        placeholder="Tipo Dispositivo"
                        elements={[
                            { id: "desktop", name: "Escritorio" },
                            { id: "mobile", name: "App" }
                        ]}
                        selectedElement={device}
                        onSelect={handleDeviceSelect}
                        labelKey="name"
                        idKey="id"
                        style={{ border: "1px solid var(--color-acento)", color: "var(--color-texto-oscuro)" }}
                    />
                </Col>
            </Row>

            <Row>
                <DynamicTable
                    data={formattedData} // Datos formateados
                    headers={headers} // Encabezados de la tabla
                    options={{
                        showPagination: true,
                        stickyHeaders: true,
                        stickyOffset: '0px',
                    }}
                />

                {/* {recordsLog?.total > 0 && (
                    <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
                        <CustomPagination
                            itemsCount={recordsLog.total}
                            itemsPerPage={pageSize}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={true}
                        />
                    </div>
                )} */}
            </Row>
        </Container>
    );
}
