import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const EstadoClaseIcono = ({ estadoClaseId }) => {
  const isGuardada = estadoClaseId === 2;
  const color = isGuardada ? 'var(--color-verde)' : 'var(--color-icon-tooltip)';
  const mensajeTooltip = isGuardada ? 'Asistencia guardada' : 'Asistencia sin guardar';

  const estilo = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '2px',
    marginRight: '2px',
    position: 'relative', // Añadir posicionamiento relativo
    color: color // Usar color de CSS
  };

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{mensajeTooltip}</Tooltip>}
    >
      <div style={estilo}>
        <svg  viewBox="0 0 384 512" style={{ width: '8px', height: '0.9rem', fill: 'currentColor' }}>
          <path d="M384 0l0 512L0 512 0 0 384 0z"/>
        </svg>
      </div>
    </OverlayTrigger>
  );
};

export default EstadoClaseIcono;
