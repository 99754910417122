import React from "react";

function CustomDivider({width = '0.5px', type = 'solid', color = 'lightgray', margin = '2rem 0'}) {
  return (
    <div
      style={{
        border: `${width} ${type} ${color}`,
        margin: `${margin}`,
      }}
    ></div>
  );
}

export default CustomDivider;
