import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import useGetAsistenciaClaseMensual from "../../../hooks/useGetAsistenciaClaseMensual";
import useGetClaseActual from "../../../hooks/useGetClaseActual";
import useSaveAsistenciaClase from "../../../hooks/useSaveAsistenciaClase";
import NavAsistenciaDiariaAsignaturaProfe from "../../components/asistenciaBloqueDiaria/NavAsistenciaDiariaAsignaturaProfe";
import TablaAsistenciaMensualAsignaturaProfesorCopia from "../../components/asistenciaBloqueMensual/TablaAsistenciaMensualAsignaturaProfesorCopia";
import ModalAutenticacion from "../../components/confirmarFirma/ModalAutenticacion";
import GuiaEstados from "../../components/guiaestados/GuiaEstados";
import NotificacionError from "../../components/notificacion/NotificacionError";
import CustomSpinner from "../../components/spinner/Spinner";

const AsistenciaMensualAsignaturaProfeCopia = () => {


  const storedData = JSON.parse(localStorage.getItem('currentRolData')) || {};
  const tieneClases = storedData.tieneClases;

  const [fecha, setFecha] = useState(() => new Date());
  const [estadoInicial, setEstadoInicial] = useState({
    cursoId: null,
    asignaturaId: null,
    claseId: null,
    cursoNombre: "Curso",
    asignatura: "Asignatura",
    asignaturaColor: null,
  });

  const [mostrarError, setMostrarError] = useState(false);
  const [, setMostrarModal] = useState(false);
  const [height, setHeight] = useState("90dvh");
  const [showModalAutenticacion, setShowModalAutenticacion] = useState(false);
  const [currentEstado, setCurrentEstado] = useState("");
  const [currentEstudiante, setCurrentEstudiante] = useState({});
  const [currentClaseId, setCurrentClaseId] = useState(null);
  const [, setCurrentAsistenciaId] = useState(null);
  const [, setMostrarToast] = useState(false);
  const [, setDatosActualizados] = useState([]);

  const { data: dataActual, loading: loadingActual, error: errorActual } = useGetClaseActual(!estadoInicial.claseId);
  const mesSeleccionado = fecha.getMonth() + 1;
  const { datosAsistencia, loading: loadingMensual, error: errorMensual } = useGetAsistenciaClaseMensual(estadoInicial.cursoId, estadoInicial.asignaturaId, mesSeleccionado);
  const { guardarAsistencia } = useSaveAsistenciaClase();

  useEffect(() => {
    const updateHeight = () => {
      const width = window.innerWidth;
      if (width <= 576) {
        setHeight("100dvh");
      } else {
        setHeight(height);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    if (dataActual && !estadoInicial.claseId) {
      setEstadoInicial({
        cursoId: dataActual.cursoId,
        asignatura: dataActual.asignaturaNombre,
        asignaturaId: dataActual.asignaturaId,
        claseId: dataActual.claseId,
        cursoNombre: dataActual.cursoNombre,
        asignaturaColor: dataActual.asignaturaColorFondo,
      });

      if (!fecha) {
        setFecha(new Date(dataActual.bloqueFecha));
      }
    }
  }, [dataActual, fecha, estadoInicial.claseId]);

  useEffect(() => {
    setDatosActualizados(datosAsistencia);
  }, [datosAsistencia]);

  const formatearFecha = (fecha) => {
    const mes = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(fecha);
    return mes.charAt(0).toUpperCase() + mes.slice(1);
  };

  const manejarMesAnterior = () => {
    setFecha(new Date(fecha.getFullYear(), fecha.getMonth() - 1));
  };

  const manejarMesSiguiente = () => {
    const nuevaFecha = new Date(fecha.getFullYear(), fecha.getMonth() + 1);
    if (moment(nuevaFecha).isAfter(moment(), "month")) {
      setMostrarError(true);
    } else {
      setFecha(nuevaFecha);
      setMostrarError(false);
    }
  };

  const handleSaveEstado = (nuevoEstado, estudianteId, claseId, asistenciaId) => {
    if (!nuevoEstado || !estudianteId || !claseId || !asistenciaId) return;

    setCurrentEstado(nuevoEstado);
    setCurrentEstudiante({ estudianteId });
    setCurrentClaseId(claseId);
    setCurrentAsistenciaId(asistenciaId);
    setShowModalAutenticacion(true);
  };

  const handleCloseModal = () => {
    setMostrarModal(false);
    setCurrentEstado("");
    setCurrentEstudiante({});
    setCurrentClaseId(null);
    setCurrentAsistenciaId(null);
  };

  const handleConfirmAutenticacion = async (codigoAutenticacion) => {
    if (!currentClaseId || !currentEstudiante.estudianteId || !currentEstado) return;

    try {
      const response = await guardarAsistencia(currentClaseId, currentEstudiante.estudianteId, currentEstado);

      setDatosActualizados((prevDatos) => {
        const nuevosDatos = prevDatos.estudiantes.map((estudiante) => {
          if (estudiante.estudianteId === currentEstudiante.estudianteId) {
            return {
              ...estudiante,
              clases: estudiante.clases.map((clase) =>
                clase.claseId === currentClaseId ? { ...clase, asistenciaEstadoClase: currentEstado } : clase
              ),
              porcAsistenciaMes: response.asistenciaAsignatura,
            };
          }
          return estudiante;
        });
        return { ...prevDatos, estudiantes: nuevosDatos };
      });

      setMostrarToast(true);
      setShowModalAutenticacion(false);
      handleCloseModal();
    } catch (error) {
      console.error("Error al guardar la asistencia:", error);
    }
  };

  const manejarEventoSeleccionado = (event) => {
    setEstadoInicial({
      cursoId: event.cursoId,
      asignatura: event.asignatura,
      asignaturaId: event.asignaturaId,
      claseId: event.id,
      cursoNombre: event.curso,
      asignaturaColor: event.asignaturaColor,
    });
    setFecha(new Date(event.start));
    setMostrarModal(false);
  };

  const cerrarError = () => setMostrarError(false);

  if (!tieneClases) {
    return (
      <Container fluid className="bg-white d-flex flex-column p-0" style={{ height: '80dvh', overflow: 'hidden' }}>
        <div className="alert alert-warning text-center mt-3">
          No tienes clases asignadas.
        </div>
      </Container>
    );
  }


  const renderContent = () => {
    if (loadingActual || loadingMensual) {
      return (
        <div className="text-center d-flex justify-content-center align-items-center h-100">
          <CustomSpinner  />
        </div>
      );
    }

    if (errorActual || errorMensual) {
      return (
        <Alert variant="danger" className="text-center mt-3">
          Error al cargar los datos: {errorActual ? errorActual.message : errorMensual.message}
        </Alert>
      );
    }

    if (!estadoInicial.cursoId) {
      return (
        <Alert variant="warning" className="text-center mt-3">
          Los parámetros necesarios para la correcta visualización de esta vista no están presentes.
        </Alert>
      );
    }

    if (!loadingMensual && (!datosAsistencia || datosAsistencia.length === 0)) {
      return null; // No mostrar nada hasta que se confirme que no hay datos
    }

    return (
      <TablaAsistenciaMensualAsignaturaProfesorCopia
        datosAsistencia={datosAsistencia}
        curso={estadoInicial.cursoNombre}
        curso_id={estadoInicial.cursoId}
        asignatura={estadoInicial.asignatura}
        asignatura_id={estadoInicial.asignaturaId}
        asignaturaColor={estadoInicial.asignaturaColor}
        handleSaveEstado={handleSaveEstado}
      />
    );
  };

  return (
    <Container fluid className="bg-white p-0" style={{ height: height, overflowY: "hidden" }}>
      <NotificacionError visible={mostrarError} onClose={cerrarError} />
      <NavAsistenciaDiariaAsignaturaProfe
        curso={estadoInicial.cursoNombre}
        asignatura={estadoInicial.asignatura}
        fecha={formatearFecha(fecha)}
        onPrev={manejarMesAnterior}
        onNext={manejarMesSiguiente}
        onEventoSeleccionado={manejarEventoSeleccionado}
        tipoNavegacion="mensual"
        asignaturaColor={estadoInicial.asignaturaColor}
      />
      <div style={{ flex: "1 1 auto", overflowY: "hidden", height: "72dvh" }}>
        {!loadingMensual && (!datosAsistencia || datosAsistencia.length === 0) ? (
          <Alert variant="info" className="text-center mt-3">
            No hay datos de asistencia disponibles.
          </Alert>
        ): renderContent()}
      </div>
      <Container fluid className="bg-white p-3 mt-0 mt-md-0 pt-2" style={{ overflow: "hidden" }}>
        <Row className="align-items-center justify-content-between flex-wrap">
          <Col xs={12} md={{ span: 6, offset: 3 }} className="d-flex justify-content-center mt-0 mt-md-0 order-md-1">
            <GuiaEstados />
          </Col>
        </Row>
      </Container>
      <ModalAutenticacion
        show={showModalAutenticacion}
        onHide={() => setShowModalAutenticacion(false)}
        onConfirm={handleConfirmAutenticacion}
      />
    </Container>
  );
};

export default AsistenciaMensualAsignaturaProfeCopia;
