import React, { useState } from "react";
import { Container } from "react-bootstrap";
import useGetListadoCursos from "../../../hooks/useGetListadoCursos";
import useGetSubvencionAnualCursos from "../../../hooks/useGetSubvencionAnualCursos";
import NavPorcentajeAsistenciaAnualPorCurso from "../../components/porcentajeAsistenciaAnualPorCurso/NavPorcentajeAsistenciaPorCurso";
import TablaPorcentajeAsistenciaAnualPorCurso from "../../components/porcentajeAsistenciaAnualPorCurso/TablaPorcentajeAsistenciaAnualPoCurso";
import CustomSpinner from "../../components/spinner/Spinner";

const PorcentajeAsistenciaAnualPorCurso = () => {
  const {
    cursos: cursosListado = [],
    cargando: cargandoCursos,
    error: errorCursos,
  } = useGetListadoCursos();
  const {
    cursos: cursosSubvencion = [],
    meses,
    cargando: cargandoSubvencion,
    error: errorSubvencion,
  } = useGetSubvencionAnualCursos();
  const [cursosSeleccionados, setCursosSeleccionados] = useState([]);
  const [isHeatmapActive, setIsHeatmapActive] = useState(false);

  const handleCursoSelect = (cursoIds) => {
    setCursosSeleccionados(cursoIds);
  };

  const cursosFiltrados =
    cursosSeleccionados.length > 0
      ? cursosSubvencion.filter((curso) =>
          cursosSeleccionados.includes(curso.id)
        )
      : cursosSubvencion;

  return (
    <Container
      fluid
      className="bg-white p-0"
      style={{ height: "92dvh", overflow: "hidden" }}
    >
      <NavPorcentajeAsistenciaAnualPorCurso
        cursos={cursosListado}
        cursosSeleccionados={cursosSeleccionados}
        onCursoSelect={handleCursoSelect}
        cargando={cargandoCursos}
        error={errorCursos}
        isHeatmapActive={isHeatmapActive}
        setIsHeatmapActive={setIsHeatmapActive}
      />
      <div style={{ flex: "1 1 auto", overflowY: "hidden", height: '85%' }}>
        {cargandoCursos || cargandoSubvencion ? (
          <div className="text-center d-flex justify-content-center align-items-center h-100">
            <CustomSpinner  />
          </div>
        ) : errorCursos || errorSubvencion ? (
          <div className="alert alert-danger text-center mt-3">
            Error al cargar los datos:{" "}
            {errorCursos ? errorCursos.message : errorSubvencion.message}
          </div>
        ) : cursosFiltrados.length === 0 ? (
          <div className="alert alert-info text-center mt-3">
            No hay datos de asistencia disponibles.
          </div>
        ) : (
          <TablaPorcentajeAsistenciaAnualPorCurso
            cursos={cursosFiltrados}
            meses={meses}
            isHeatmapActive={isHeatmapActive}
          />
        )}
      </div>
    </Container>
  );
};

export default PorcentajeAsistenciaAnualPorCurso;
