import React, { useState, forwardRef } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { es } from "date-fns/locale";
import { parse, isValid } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import CustomTooltip from "../tooltip/Tooltip";

const formControlStyle = {
  display: "block",
  width: "100%",
  height: "100%",
  padding: "0.5rem 0.75rem",
  fontSize: "1rem",
  lineHeight: "1.5",
  color: "#495057",
  backgroundColor: "#fff",
  backgroundClip: "padding-box",
  border: "1px solid #ced4da",
  borderRadius: "0.25rem",
  transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  boxSizing: "border-box",
};

const wrapperStyle = {
  width: "100%",
  boxSizing: "border-box",
};

const CustomInput = forwardRef(
  ({ value, onClick, onChange, onFocus, onBlur, disabled }, ref) => {
    
    const handleChange = (e) => {
      let inputValue = e.target.value.replace(/\D/g, ""); // Elimina cualquier carácter que no sea un número

      // Inserta las barras '/' en las posiciones correctas
      if (inputValue.length > 2 && inputValue.length <= 4) {
        inputValue = inputValue.replace(/(\d{2})(\d+)/, "$1/$2"); // Agrega la barra después del día
      } else if (inputValue.length > 4) {
        inputValue = inputValue.replace(/(\d{2})(\d{2})(\d+)/, "$1/$2/$3"); // Agrega barras después del día y mes
      }

      // Actualiza el estado del valor del input
      if (onChange) {
        onChange({
          target: {
            value: inputValue,
          },
        });
      }
    };

    const handleKeyDown = (e) => {
      const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"];

      if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
        e.preventDefault(); // Previene la entrada de caracteres no numéricos
      }
    };

    return (
      <input
        style={{ ...formControlStyle, backgroundColor: disabled ? "#e9ecef" : "#fff" }}
        value={value}
        onClick={disabled ? undefined : onClick}
        onChange={disabled ? undefined : handleChange} // Usa handleChange aquí
        onFocus={disabled ? undefined : onFocus}
        onBlur={disabled ? undefined : onBlur}
        onKeyDown={handleKeyDown} // Restringe la entrada a números y teclas específicas
        placeholder="dd/mm/aaaa"
        ref={ref}
        disabled={disabled}
      />
    );
  }
);


function DatePickerInput({
  controlId,
  selected,
  onChange,
  dateFormat,
  placeholderText,
  locale,
  required,
  label,
  disabled, // Nueva prop para manejar el estado deshabilitado
}) {
  registerLocale("es", es);

  const [inputValue, setInputValue] = useState("");
  const [isManualEntry, setIsManualEntry] = useState(false);


  const handleInputChange = (e) => {
    console.log(e)
    const value = e.target.value;
    setInputValue(value);

    const parsedDate = parse(value, "dd/MM/yyyy", new Date());
    if (isValid(parsedDate)) {
      onChange(parsedDate);
    }
  };



  

  const handleDateChange = (date) => {
    setIsManualEntry(false);
    setInputValue(date ? date.toLocaleDateString("es-ES") : "");
    onChange(date);
  };

  const handleInputFocus = () => {
    if (!isManualEntry) {
      setInputValue(""); // Limpia el campo al enfocarlo para entrada manual
      setIsManualEntry(true);
    }
  };

  const handleInputBlur = () => {
    if (!inputValue) {
      setInputValue(selected ? selected.toLocaleDateString("es-ES") : "");
    }
  };

  return (
    <Form.Group style={{ width: "100%", height: "100%" }} controlId={controlId}>
      {required ? <CustomTooltip tooltipMsg="Dato obligatorio">
        <Form.Label>
          {label}
           <span className="text-danger"> *</span>
        </Form.Label>
      </CustomTooltip> : <Form.Label>{label}</Form.Label>}
      <div style={wrapperStyle}>
        <DatePicker
          selected={selected}
          onChange={handleDateChange}
          dateFormat={dateFormat}
          placeholderText={placeholderText}
          locale={locale}
          required={required}
          customInput={
            <CustomInput
              value={inputValue}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              disabled={disabled} // Pasamos la prop disabled al input personalizado
            />
          }
          wrapperClassName="custom-datepicker-wrapper"
          disabled={disabled} // Deshabilitamos el DatePicker cuando disabled es true
        />
      </div>
    </Form.Group>
  );
}

export default DatePickerInput;
