import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// TRUNCADO DE TEXTO
//

export function truncate(text, length) {
  return text.length > length ? text.substring(0, length) + "..." : text;
}

export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function filtrarPuntos(inputString) {
  return inputString.split('.').join('');
}

export function capitalize(str) {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function normalizeString(str) {
  if (typeof str !== "string") {
    // console.error("El valor no es una cadena: ", str);
    return "";
  }
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export function validarRUN(run) {
  // Eliminar puntos y guiones del RUN
  if (typeof run !== "string") return false;
  if (run === "") return false;
  const cleanRun = run.replace(/\./g, "").replace("-", "");

  // Verificar que el RUN limpio tenga la estructura correcta: 7 u 8 dígitos seguidos de un dígito verificador (0-9 o K)
  const regex = /^\d{7,8}[0-9Kk]$/;

  if (!regex.test(cleanRun)) {
    return false;
  }

  const number = cleanRun.slice(0, -1);
  const dv = cleanRun.slice(-1).toUpperCase();

  let suma = 0;
  let factor = 2;

  for (let i = number.length - 1; i >= 0; i--) {
    suma += parseInt(number[i]) * factor;
    factor = factor === 7 ? 2 : factor + 1;
  }

  const resto = 11 - (suma % 11);
  let dvCalculado;

  if (resto === 11) {
    dvCalculado = "0";
  } else if (resto === 10) {
    dvCalculado = "K";
  } else {
    dvCalculado = resto.toString();
  }

  return dv === dvCalculado;
}

export function removeDots(text) {
  if (typeof text !== "string") return "";
  if (text === "") return "";
  return text.replace(/\./g, "");
}

export const limpiarPuntosYGuiones = (str) => {
  if (typeof str !== "string") return str; // Verifica que sea un string
  return str.replace(/[.-]/g, ""); // Reemplaza todos los puntos y guiones por una cadena vacía
};

export const formatearRUN = (run) => {
  if (typeof run !== "string") return run;
  if (run === "") return run;
  const cleanRun = run.replace(/\./g, "").replace("-", "");
  if (cleanRun.length > 1) {
    let formattedRun =
      cleanRun.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      "-" +
      cleanRun.slice(-1);
    return formattedRun.toUpperCase();
  }
  return run;
};

export const formatearIPEIPA = (identificador) => {
  if (typeof identificador !== "string") return identificador;
  if (identificador === "") return identificador;

  // Limpia el identificador de puntos y guiones
  const cleanIdentificador = identificador.replace(/\./g, "").replace("-", "");

  // Verifica que el IPE empiece con 100 o el IPA con 200
  if (
    !cleanIdentificador.startsWith("100") &&
    !cleanIdentificador.startsWith("200")
  ) {
    return identificador; // No es un IPE o IPA, devolver tal cual
  }

  // Formatear el IPE o IPA
  if (cleanIdentificador.length > 1) {
    let formattedIdentificador =
      cleanIdentificador.slice(0, -1).replace(/(\d{3})(?=\d)/g, "$1.") +
      "-" +
      cleanIdentificador.slice(-1);
    return formattedIdentificador.toUpperCase();
  }

  return identificador; // Si no hay suficiente longitud, devolver tal cual
};

export function validarFormatoRUN(run) {
  let newRun = run.toString().replace(/\./g, "").replace("-", "");
  
  // Verificar que el RUN tenga entre 7 y 8 dígitos + dígito verificador
  if (newRun.length < 8 || newRun.length > 9) {
    console.log("Longitud incorrecta para un RUN");
    return false;
  }

  // Extraer el cuerpo del RUN (sin el dígito verificador)
  const cuerpo = newRun.slice(0, -1);
  if (!/^\d+$/.test(cuerpo)) {
    console.log("El cuerpo del RUN debe contener solo dígitos");
    return false;
  }

  // Verificar que el último carácter sea un dígito o la letra "K"
  const dv = newRun.slice(-1).toUpperCase();
  if (!/^[0-9K]$/.test(dv)) {
    console.log("El dígito verificador debe ser un número o la letra 'K'");
    return false;
  }

  // Si todas las validaciones pasan, el formato es correcto
  return true;
}


export function validarFormatoIPE(ipe) {
  let newIpe = ipe.toString();
  // Primero, asegurarse de que el IPE tenga una longitud de 10 caracteres
  if (newIpe.length !== 10) {
    // console.log("Longitud incorrecta");
    return false;
  }
  // Verificar que los primeros 9 caracteres (después de "100") sean dígitos
  const numero = newIpe.slice(3, 9);
  if (!/^\d{6}$/.test(numero)) {
    console.log("Los caracteres después de '100' deben ser 6 dígitos");
    return false;
  }

  // Verificar que el último carácter sea un dígito o la letra "K"
  const dv = newIpe.slice(-1).toUpperCase();
  if (!/^[0-9K]$/.test(dv)) {
    console.log("El dígito verificador debe ser un número o la letra 'K'");
    return false;
  }

  // Si todas las validaciones pasan, el formato es correcto
  return true;
}
// Validar IPE
export function validarIPE(ipe) {
  // Limpia los puntos y guiones antes de validar
  const ipeLimpio = limpiarPuntosYGuiones(ipe);

  // Validar el formato manualmente
  if (!validarFormatoIPE(ipeLimpio)) {
    return false;
  }
  // console.log('pase formato: ', ipeLimpio)
  // Continuar con la validación del dígito verificador (DV) usando el algoritmo Módulo 11
  const number = ipeLimpio.slice(0, -1);
  const dv = ipeLimpio.slice(-1).toUpperCase();

  let suma = 0;
  let factor = 2;

  for (let i = number.length - 1; i >= 0; i--) {
    suma += parseInt(number[i], 10) * factor;
    factor = factor === 7 ? 2 : factor + 1;
  }

  const modulo = suma % 11;
  const resto = 11 - modulo;
  let dvCalculado;

  if (resto === 11) {
    dvCalculado = "0";
  } else if (resto === 10) {
    dvCalculado = "K";
  } else {
    dvCalculado = resto.toString();
  }
  // console.log
  return dv === dvCalculado;
}

export const calcularDV = (numero) => {
  let suma = 0;
  let multiplicador = 2;

  for (let i = numero.length - 1; i >= 0; i--) {
    suma += parseInt(numero.charAt(i)) * multiplicador;
    multiplicador = multiplicador < 7 ? multiplicador + 1 : 2;
  }

  const resto = 11 - (suma % 11);
  if (resto === 11) {
    return "0";
  } else if (resto === 10) {
    return "K";
  } else {
    return resto.toString();
  }
};

export const validateTextOnly = (value) => {
  const textOnlyPattern = /^[a-zA-Z\s]+$/;
  return textOnlyPattern.test(value);
};

export const validarEmail = (email) => {
  const patron = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return patron.test(email);
};

export const separarRun = (run) => {
  if (!run) return { run: null, runDv: null };
  const [numero, dv] = typeof run === 'string' ? run.split("-") : [null, null];
  return { run: numero, runDv: dv };
};

// Función para separar ipe en número y dígito verificador
export const separarIpe = (ipe) => {
  if (!ipe) return { ipe: null, ipeDv: null };
  const [numero, dv] = ipe.split("-");
  return { ipe: numero, ipeDv: dv };
};

// export const formatearRut = (rutCompleto) => {
//   const rutSinDv = rutCompleto.slice(0, -2); // Extrae el RUT sin el dígito verificador
//   const dv = rutCompleto.slice(-1); // Extrae el dígito verificador
//   return rutSinDv.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "-" + dv; // Agrega los puntos y el guion
// };

// export const getPermisosDocente = async (cursoId, asignaturaId, periodoId, colegioId) => {
//   console.log("Parametros enviados:", { cursoId, asignaturaId, periodoId, colegioId });

//   try {
//     const response = axiosInstance.get(`${api.url}/docente/getPermisosDocente?curso_id=${cursoId}&asignatura_id=${asignaturaId}`, {
//       headers: { Authorization: `Bearer ${token}`, PeriodoId: periodoId, ColegioId: colegioId },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error al obtener los permisos del docente:", error);
//     return [];
//   }
// };

export function formatearRutIpe(rutIpe) {
  // Validar entrada para asegurar que no esté vacía o sea null
  if (!rutIpe) {
    return "";
  }

  // Convertir el valor a string por si viene como número
  let rutIpeString = rutIpe.toString().trim().replace(/\./g, ""); // Eliminar puntos existentes

  // Verificar que el valor contiene solo números y letras (para el DV)
  if (!/^[0-9]+[kK0-9]{1}$/.test(rutIpeString)) {
    return "";
  }

  // Separar la parte del número del DV
  let numero = rutIpeString.slice(0, -1);
  let dv = rutIpeString.slice(-1).toUpperCase(); // Convertir DV a mayúscula por consistencia

  // Formatear con puntos cada 3 dígitos
  let numeroFormateado = numero.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Retornar el RUT/IPE formateado
  return `${numeroFormateado}-${dv}`;
}

export function removeAreaCode(phoneNumber, countries) {
  if (!phoneNumber || phoneNumber === "") return "";
  if (countries?.length === 0) return "";
  if (!phoneNumber.startsWith("+")) return phoneNumber;

  // Iterar sobre cada país en el array de countries
  for (const country of countries) {
    // Verificar si el número de teléfono comienza con el código de área del país
    if (phoneNumber.startsWith(country.areaCode)) {
      // Remover el código de área del número de teléfono y retornarlo
      return phoneNumber.slice(country.areaCode.length);
    }
  }
  // Si no se encuentra un código de área coincidente, retornar el número de teléfono original
  return phoneNumber;
}

export const formatearFecha = (fecha) => {
  if (!fecha) return "";
  const [año, mes, dia] = fecha.split("-");
  return `${dia}-${mes}-${año}`;
};


export const contarPorCampo = (lista, campo, valor) => {
  const count = lista.reduce((acc, item) => {
    if (item.value[campo]?.props?.tooltipMsg === valor) {
      acc++;
    }
    return acc;
  }, 0);
  return count;
};

export function detectarNumeroAlFinal(string) {
  const regex = /(\d+)$/;
  const match = string.match(regex);

  if (match) {
    return match[0]; // Devuelve el número encontrado al final
  } else {
    return null; // Devuelve null si no se encuentra un número al final
  }
}

export function esRunOIpe(cadena) {
  // Expresión regular para validar RUN
  const regexRun = /^[1-9]\d{6,7}-[0-9kK]$/;
  // Expresión regular para validar IPE (100 o 200 seguido de 6 dígitos y el dígito verificador)
  const regexIpe = /^(100|200)\d{6}-[0-9kK]$/;

  return regexRun.test(cadena) || regexIpe.test(cadena);
}

export function formatearRUNSinDv(usuario) {
  if (!usuario) return "";
  // Eliminar cualquier carácter que no sea dígito
  const numeroLimpio = usuario.replace(/\D/g, '');
  
  // Formatear el número con puntos para los miles
  const numero = numeroLimpio.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return numero;
}

//GENERAR PDF
export const generarPDF = async (elementoRef, fileName = 'document', options = { scale: 2 }) => {
  if (!elementoRef.current) return;

  // Crear un div temporal fuera de la vista del usuario para renderizar el contenido
  const tempDiv = document.createElement("div");
  document.body.appendChild(tempDiv);

  // Estilos del div temporal
  tempDiv.style.position = "fixed";
  tempDiv.style.left = "-9999px";
  tempDiv.style.top = "-9999px";
  tempDiv.style.width = "100%";
  tempDiv.style.padding = "10px"; // Ajuste de padding para maximizar el espacio
  tempDiv.style.backgroundColor = "white";

  // Clonar el contenido del elemento de referencia en el div temporal
  const clone = elementoRef.current.cloneNode(true);
  tempDiv.appendChild(clone);

  // Verificar si el contenido se desborda y ajustar el ancho si es necesario
  const isOverflowing = clone.scrollWidth > clone.clientWidth;
  if (isOverflowing) {
    tempDiv.style.width = "180%";
  }

  // Esperar a que los estilos y el contenido se carguen completamente
  await new Promise((resolve) => setTimeout(resolve, 1000));

  // Generar la imagen del canvas a partir del contenido
  const canvas = await html2canvas(tempDiv, { scale: options.scale });
  const imgData = canvas.toDataURL('image/png');

  // Configurar el PDF en orientación landscape para aprovechar el espacio horizontal
  const pdf = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: 'a4',
    compress: true,
  });

  // Obtener dimensiones del PDF y escalar la imagen para ocupar todo el espacio posible
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const imgWidth = pdfWidth - 20; // Margen reducido a 10pt a cada lado
  const imgHeight = (canvas.height * imgWidth) / canvas.width;

  // Ajustar la imagen para que ocupe el máximo espacio posible en la página
  const finalImgWidth = imgWidth;
  const finalImgHeight = Math.min(imgHeight, pdfHeight - 20); // Margen vertical de 10pt

  // Centrar la imagen en la página del PDF
  const positionX = (pdfWidth - finalImgWidth) / 2;
  const positionY = (pdfHeight - finalImgHeight) / 2;

  // Agregar la imagen al PDF ocupando casi toda la página
  pdf.addImage(imgData, 'PNG', positionX, positionY, finalImgWidth, finalImgHeight, 'FAST');

  // Guardar el PDF con el nombre proporcionado
  pdf.save(`${fileName}.pdf`);
  document.body.removeChild(tempDiv); // Eliminar el div temporal después de la generación
};

export const formatearNotaConComa = (nota, cantidadDecimales) => {
  if (nota === 0) return "-";
  return nota.toFixed(cantidadDecimales).replace(".", ",");
};
