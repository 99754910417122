import { useEffect, useState } from "react";
import { getAsistenciaSubvencion } from "../services/PostsService";


const useGetAsistenciaSubvencion = (curso_id, mes) => {
  const [asistencia, setAsistencia] = useState([]);
  const [conteoDiario, setConteoDiario] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAsistencia = async () => {
      setCargando(true);
      setError(null);

      try {
        const params = { curso_id, mes };
        const response = await getAsistenciaSubvencion(params);
        const data = response.data;

        // Procesar la asistencia de los estudiantes
        const parsedAsistencia = data.asistenciaEstudiantes.map((item) => {
          const estudiante = item.estudiante;
          const asistencias = item.asistencias || {};
          const porcentajeTotalMensual = item.porcentajeTotalMensual;
          const conteoTotalMensual = item.conteoTotalMensual; 
          console.log(estudiante)
          return {
            id: estudiante.estudianteId,
            run: estudiante.run,
            runDv: estudiante.runDv,
            ipe: estudiante.runProvisorio,            
            ipeDv: estudiante.runProvisorioDv,
            numLista: estudiante.numLista,
            numMatricula: estudiante.numMatricula,
            fechaMatricula: estudiante.fechaMatricula,
            nombre: `${estudiante.nombrePrimario} ${estudiante.apellidoPrimario} ${estudiante.apellidoSecundario}`,
            cursoId: estudiante.cursoId,
            asistencias: asistencias,
            porcentajeTotalMensual: porcentajeTotalMensual,
            fechaRetiro: estudiante.fechaRetiro,
            retirado: estudiante.retirado,
            conteoTotalMensual: conteoTotalMensual,
          };
        });

        // Procesar el conteo diario de asistencias
        const parsedConteoDiario = data.asistenciaConteoDiario.map((item) => {
          // console.log(`VENGO DEL HOOK Día: ${item.dia}, Conteo Diario: ${item.conteoDiario}`);
          return {
            mes: item.mes,
            dia: item.dia,
            conteoDiario: item.conteoDiario,
          };
        });

        setAsistencia(parsedAsistencia);
        setConteoDiario(parsedConteoDiario);

      } catch (error) {
        setError(error.message);
      } finally {
        setCargando(false);
      }
    };

    if (curso_id && mes) {
      fetchAsistencia();
    }
  }, [curso_id, mes]);

  return { asistencia, conteoDiario, cargando, error };
};

export default useGetAsistenciaSubvencion;
