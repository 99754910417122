import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import useGetAsistenciaSubvencion from '../../../hooks/useGetAsistenciaSubvencion';
import NavAsistenciaSubvencionesEstudiantes from '../../components/asistenciaMensualSubvencionesEstudiantes/NavAsistenciaSubvencionesEstudiantes';
import TablaAsistenciaMensualSubvencionEstudiantes from '../../components/asistenciaMensualSubvencionesEstudiantes/TablaAsistenciaMensualSubvencionEstudiantes';
import GuiaEstados from '../../components/guiaestados/GuiaEstados';
import NotificacionError from '../../components/notificacion/NotificacionError';
import CustomSpinner from '../../components/spinner/Spinner';

const AsistenciaMensualSubvencion = () => {
  const location = useLocation();
  const storedCursoId = JSON.parse(localStorage.getItem('currentRolData'))?.defaultCursoId;
  const [fecha, setFecha] = useState(new Date());
  const [mes, setMes] = useState(fecha.getMonth() + 1);
  const [cursoId, setCursoId] = useState(location.state?.cursoId || storedCursoId);
  const [cursoNombre, setCursoNombre] = useState(location.state?.cursoNombre || '');
  const [mostrarError, setMostrarError] = useState(false);

  // Obtener los roles del usuario desde userDetails
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  console.log("Contenido completo de userDetails:", userDetails);

  // Acceso específico a los roles del usuario
  const roles = userDetails?.user?.roles || [];
  console.log("Roles obtenidos del usuario:", roles);

  // Verificar si el usuario tiene exclusivamente el rol de Docente
  const isDocente = roles.some((rol) => rol?.rol?.rolNombre === "Docente");
  const isSoloDocente = roles.length === 1 && isDocente;

  console.log("¿Usuario tiene rol de Docente?", isDocente);
  console.log("¿Es Solo Docente?", isSoloDocente);

  // Hook para obtener la asistencia subvención
  const { asistencia, conteoDiario, cargando, error } = useGetAsistenciaSubvencion(cursoId, mes);

  useEffect(() => {
    console.log('Curso ID:', cursoId, 'Mes:', mes);
  }, [cursoId, mes]);

  useEffect(() => {
    if (!cursoId && storedCursoId) {
      setCursoId(storedCursoId);
    }
  }, [storedCursoId, cursoId]);

  const manejarMesAnterior = () => {
    const nuevaFecha = new Date(fecha.getFullYear(), fecha.getMonth() - 1);
    setFecha(nuevaFecha);
    setMes(nuevaFecha.getMonth() + 1);
    setMostrarError(false);
  };

  const manejarMesSiguiente = () => {
    const nuevaFecha = new Date(fecha.getFullYear(), fecha.getMonth() + 1);
    if (moment(nuevaFecha).isAfter(moment(), 'month')) {
      setMostrarError(true);
    } else {
      setFecha(nuevaFecha);
      setMes(nuevaFecha.getMonth() + 1);
      setMostrarError(false);
    }
  };

  const cerrarError = () => setMostrarError(false);

  return (
    <Container fluid className="bg-white p-3" style={{ height: '92dvh', overflow: 'hidden' }}>
      <NotificacionError visible={mostrarError} onClose={cerrarError} />
      <NavAsistenciaSubvencionesEstudiantes
        fecha={new Date(new Date().getFullYear(), mes - 1, 1)}
        onPrev={manejarMesAnterior}
        onNext={manejarMesSiguiente}
        onCursoSelect={(curso) => setCursoId(curso)}
        cursoId={cursoId}
        cursoNombre={cursoNombre}
      />
      <div style={{ flex: '1 1 auto', overflowY: 'hidden', height: '85%' }}>
        {cargando ? (
          <div className="text-center d-flex justify-content-center align-items-center h-100">
            <CustomSpinner />
          </div>
        ) : error ? (
          <div className="alert alert-danger text-center mt-3">
            Error al cargar los datos: {error.message}
          </div>
        ) : !cursoId ? (
          <div className="alert alert-warning text-center mt-3">
            Parámetros necesarios no están presentes.
          </div>
        ) : !asistencia || asistencia.length === 0 ? (
          <div className="alert alert-info text-center mt-3">
            No hay datos de asistencia disponibles.
          </div>
        ) : (
          <TablaAsistenciaMensualSubvencionEstudiantes
            datosAsistencia={asistencia}
            conteoDiario={conteoDiario}
            mes={mes}
            isSoloDocente={isSoloDocente}
          />
        )}
      </div>
      <Container fluid className="bg-white p-3 mt-0 mt-md-0 pt-2" style={{ overflow: 'hidden' }}>
        <Row className="align-items-center justify-content-between flex-wrap">
          <Col
            xs={12}
            md={{ span: 6, offset: 3 }}
            className="d-flex justify-content-center mt-0 mt-md-0 order-md-1"
          >
            <GuiaEstados />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default AsistenciaMensualSubvencion;