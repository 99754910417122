
import axiosInstance from '../services/AxiosInstance';

export function uploadCalificaciones(formData, periodoAcademicoId) {
    return axiosInstance.post(`/notas/uploadCalificaciones`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'periodoEscolarId': periodoAcademicoId
        }
    });
}


export function saveProcesoCalificaciones(data ) {
    return axiosInstance.post(`/notas/saveCalificaciones`, data );
}
