import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import CustomTooltip from "../../../components/tooltip/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../assets/css/estilosCambar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  cargarListaAnios,
  cargarListaComunas,
  cargarListaEtnias,
  cargarListaGenerosRegCivil,
  cargarListaIdentidadGenero,
  cargarListaNacionalidades,
  cargarListaNivelesAcademicos,
  cargarListaRegiones,
  cargarListaSectores,
  getAsignaturasCurriculum,
  saveFuncionario,
} from "../../../../services/PostsService";
import {
  setAnios,
  setAsignaturas,
  setComunas,
  setEtnias,
  setGeneros,
  setIdentidadesGenero,
  setNacionalidades,
  setNivelesAcademicos,
  setNivelesEnsenanza,
  setRegiones,
  setSectores,
} from "../../../../store/actions/refDataActions";
import TabPersonal from "./tabs/TabPersonal";
// import TabLaboral from "./tabs/TabLaboral";
import TabAcademico from "./tabs/TabAcademico";
import TabInstitucion from "./tabs/TabInstitucion";
import TabDocente from "./tabs/TabDocente";
import TabDirectivo from "./tabs/TabDirectivo";
import TabAdministrativo from "./tabs/TabAdministrativo";
import TabAsistente from "./tabs/TabAsistente";
import { getListadoEnsenanzas } from "../../../../services/MatriculaService";
import { formatearRUN, limpiarPuntosYGuiones } from "../../../../util/util";
import Swal from "sweetalert2";
import TabEspecialista from "./tabs/TabEspecialista";
import TabNavigator from "../../../components/tabNavigator/TabNavigator";
import useCheckRoles from "../../../../hooks/useCheckRoles";

function FichaFuncionario() {
  const [isReadyToUploadAvatar, setIsReadyToUploadAvatar] = useState();

  const [errors, setErrors] = useState({});
  const [isFichaCargada, setIsFichaCargada] = useState(false);

  const [rolesSeleccionados, setRolesSeleccionados] = useState({
    profesor: false,
    directivo: false,
    administrativo: false,
    especialista: false,
    auxiliar: false,
  });

  const location = useLocation();
  const ficha = location.state?.ficha;

  const {isDirectivo} = useCheckRoles();
  // const isDirectivo = true;
  console.log("aa")

  const [listaPestanias, setListaPestanias] = useState([
    {
      eventKey: "antecedentesPersonales",
      name: "Personal",
      component: TabPersonal,
      isSelected: true,
    },

    {
      eventKey: "antecedentesAcademicos",
      name: "Antecedentes",
      component: TabAcademico,
      isSelected: true,
    },
    {
      eventKey: "antecedentesInstitucion",
      name: "Institución",
      component: TabInstitucion,
      isSelected: true,
    },

    {
      eventKey: "tabDirectivo",
      name: "Directivo",
      component: TabDirectivo,
      isSelected: false,
    },
    {
      eventKey: "tabProfesor",
      name: "Docente",
      component: TabDocente,
      isSelected: false,
    },
    {
      eventKey: "tabAdministrativo",
      name: "Administrativo",
      component: TabAdministrativo,
      isSelected: false,
    },
    {
      eventKey: "tabEspecialista",
      name: "Especialista",
      component: TabEspecialista,
      isSelected: false,
    },
    {
      eventKey: "tabAuxiliar",
      name: "Auxiliar",
      component: TabAsistente,
      isSelected: false,
    },
  ]);

  const regionState = useSelector((state) => state.refDataReducer.regiones);
  const comunasState = useSelector((state) => state.refDataReducer.comunas);
  const nacionalidadesState = useSelector(
    (state) => state.refDataReducer.nacionalidades
  );
  const etniasState = useSelector((state) => state.refDataReducer.etnias);
  const generosState = useSelector((state) => state.refDataReducer.generos);
  const nivelesEnsenanzaState = useSelector(
    (state) => state.refDataReducer.nivelesEnsenanza
  );

  const identidadesGeneroState = useSelector(
    (state) => state.refDataReducer.identidadesGenero
  );
  const aniosState = useSelector((state) => state.refDataReducer.anios);
  const sectoresState = useSelector((state) => state.refDataReducer.sectores);
  const asignaturasState = useSelector(
    (state) => state.refDataReducer.asignaturas
  );
  const nivelesAcademicosState = useSelector(
    (state) => state.refDataReducer.nivelesAcademicos
  );

  // const sectoresState = useSelector((state) => state.refDataReducer.sectores);

  const navigate = useNavigate();
  const [listaRegiones, setListaRegiones] = useState([]);
  const [listaComunas, setListaComunas] = useState([]);
  const [listaNacionalidades, setListaNacionalidades] = useState([]);
  const [listaGeneros, setListaGeneros] = useState([]);
  const [listaIdentidadesGenero, setListaIdentidadesGenero] = useState([]);
  const [listaEtnias, setListaEtnias] = useState([]);
  const [listaNivelesEnsenanza, setListaNivelesEnsenanza] = useState([]);
  const [listaSectores, setListaSectores] = useState([]);
  const [listaAsignaturas, setListaAsignaturas] = useState([]);
  const [listaNivelesAcademicos, setListaNivelesAcademicos] = useState([]);

  const [listaAnios, setListaAnios] = useState([]);
  const dispatch = useDispatch();

  const [pestañaActiva, setPestañaActiva] = useState("antecedentesPersonales");

  const [funcionario, setFuncionario] = useState({
    personaId: null,
    personal: {
      run: "",
      nombre: "",
      nombreSecundario: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      email: "",
      emailInstitucional: "",
      telefono: "",
      fechaNacimiento: null,
      genero: "",
      identidadGenero: "",
      nacionalidad: 1,
      etnia: "",
      region: "",
      comuna: "",
      direccion: "",
      contratoHoras:"",
      estadoIdoneidad: true,
    },
    antecedentesAcademicos: [],
    profesor: {},
    directivo: {},
    administrativo: {},
    especialista: {},
    auxiliar: {},
  });

  const [tabProfesor, setTabProfesor] = useState({
    profesorId: null,
    funcionPrimariaId: "",
    funcionSecundariaId: "",
    profesorFechaIngreso: null,
    horasIngles: "",
    horasTecPedaFuncion1: "",
    horasTecPedaFuncion2: "",
    horasAula: "",
    funcionUnoHorasPeda: "",
    funcionDosHorasPeda: "",
    profesorContratoSep: "",
    profesorContratoPie: "",
    profesorContratoTipo: "",
    profesorEnabled: false,
    ensenanzaListado: [],
    sectorFuncion1: "",
    subSectorFuncion1: "",
    sectorFuncion2: "",
    subSectorFuncion2: "",
    // sectorListadoFuncion1: [],
    // subSectorListadoFuncion1: [],
    // sectorListadoFuncion2: [],
    // subSectorListadoFuncion2: [],
  });

  const [tabDirectivo, setTabDirectivo] = useState({
    directivoId: null,
    funcionPrimariaId: "",
    funcionSecundariaId: "",
    horasDirectivas: "",
    horasTecnicoPedagogicas: "",
    horasAula: "",
  });

  const transformarFuncionario = (funcionario) => {
    console.log("Funcionario:", funcionario);
    console.log("Tab Profesor:", tabProfesor);

    // Verificar cada listado; si todos sus elementos tienen propiedades en null o false, asignamos un array vacío

    // Extraemos el run y su dígito verificador
    const runCompleto = limpiarPuntosYGuiones(funcionario.personal.run);
    const runSinDv = runCompleto.slice(0, -1);
    const runDv = funcionario.personal.run.split("-")[1];

    return {
      personaId: funcionario.personaId,
      run: runSinDv, // RUN sin el dígito verificador
      runDv: runDv, // Dígito verificador extraído
      apellidoPaterno: funcionario.personal.apellidoPaterno,
      apellidoMaterno: funcionario.personal.apellidoMaterno,
      telefono: funcionario.personal.telefono,
      fechaNacimiento:
        funcionario.personal.fechaNacimiento?.split("T")[0] || null, // Se convierte a "YYYY-MM-DD"
      genero: funcionario.personal.genero,
      generoIdentidad: funcionario.personal.identidadGenero,
      nombres: funcionario.personal.nombre,
      nombresSecundarios: funcionario.personal.nombreSecundario,
      nacionalidad: funcionario.personal.nacionalidad,
      etnia: funcionario.personal.etnia,
      email: funcionario.personal.email,
      emailInstitucional: funcionario.personal.emailInstitucional,
      region: funcionario.personal.region,
      comuna: funcionario.personal.comuna,
      direccion: funcionario.personal.direccion,
      contratoHoras: funcionario.contratoHoras,
      estadoIdoneidad: funcionario.estadoIdoneidad,
      antecedentesAcademicos: funcionario.antecedentesAcademicos.map(
        (antecedente) => ({
          id: antecedente.id || null, // Respetamos el ID si ya existe, o lo dejamos en null si es nuevo
          idNivelAcademico: antecedente.idNivelAcademico,
          institucion: antecedente.institucion,
          titulo: antecedente.titulo,
          duracion: antecedente.duracion,
          anioEgreso: antecedente.anioEgreso,
        })
      ),
      profesor: {
        ...tabProfesor,
        profesorEnabled: rolesSeleccionados.profesor,
      },
      directivo: {
        directivoId: funcionario.directivo?.directivoId,
        directivoFechaIngreso: funcionario.directivo?.directivoFechaIngreso,
        directivoContratoHh: funcionario.directivo?.directivoContratoHh,
        directivoContratoSep: funcionario.directivo?.directivoContratoSep,
        diretivoContratoTipo: funcionario.directivo?.diretivoContratoTipo,
        funcionPrimariaId: funcionario.directivo?.funcionPrimariaId,
        funcionaSecundaria: funcionario.directivo?.funcionaSecundaria,
        directivoEnabled: funcionario.directivo?.directivoEnabled,
      },
    };
  };

  const handleSaveFicha = () => {
    
    if (
      !rolesSeleccionados.profesor &&
      !rolesSeleccionados.directivo &&
      !rolesSeleccionados.administrativo &&
      !rolesSeleccionados.especialista &&
      !rolesSeleccionados.auxiliar
    ) {
      Swal.fire({
        icon: "warning",
        title: "Advertencia",
        text: "Debe seleccionar al menos un rol.",
        confirmButtonColor: "var(--color-acento)",
      });
      return;
    } else {
      const funcionarioToSave = transformarFuncionario(funcionario);
      console.log("Guardando ficha:", funcionarioToSave);
      setIsReadyToUploadAvatar(true);

      // Llamar a la API para guardar la ficha

      saveFuncionario(funcionarioToSave)
        .then((response) => {
          console.log("Respuesta de la API:", response);

          Swal.fire({
            icon: "success",
            title: "Guardado",
            text: "La ficha del funcionario ha sido guardada correctamente.",
            confirmButtonColor: "var(--color-acento)",
          });
          navigate("/funcionarios");
        })
        .catch((error) => {
          console.error("Error: ", error.response.data.message);
          Swal.fire({
            icon: "error",
            title: "Error",
            confirmButtonColor: "var(--color-acento)",
            text: error.response.data.message,
          });
        })
        .finally(() => {
          setIsReadyToUploadAvatar(false);
        });
    }
  };

  const actualizarPestanias = useCallback(() => {
    setListaPestanias((prevPestanias) =>
      prevPestanias.map((tab) => {
        switch (tab.eventKey) {
          case "tabDirectivo":
            return {
              ...tab,
              isSelected: funcionario.directivo?.directivoEnabled || false,
            };
          case "tabProfesor":
            return {
              ...tab,
              isSelected: funcionario.profesor?.profesorEnabled,
            };
          case "tabAdministrativo":
            return {
              ...tab,
              isSelected:
                funcionario.administrativo?.administrativoEnabled || false,
            };
          case "tabEspecialista":
            return {
              ...tab,
              isSelected:
                funcionario.especialista?.especialistaEnabled || false,
            };
          case "tabAuxiliar":
            return {
              ...tab,
              isSelected: funcionario.auxiliar?.auxiliarEnabled || false,
            };
          default:
            return tab;
        }
      })
    );
  }, [
    funcionario.directivo,
    funcionario.administrativo,
    funcionario.especialista,
    funcionario.auxiliar,
    funcionario.profesor,
  ]);

  // console.log("algo")

  useEffect(() => {
    // Usamos Promise.all para asegurar que todas las listas se carguen antes de actualizar el estado
    const loadInitialData = async () => {
      try {
        const promises = [];
        // Agrega solo las llamadas a la API necesarias cuando los estados en Redux estén vacíos
        if (regionState.length === 0) promises.push(cargarListaRegiones());
        if (comunasState.length === 0) promises.push(cargarListaComunas());
        if (nacionalidadesState.length === 0)
          promises.push(cargarListaNacionalidades());
        if (etniasState.length === 0) promises.push(cargarListaEtnias());
        if (generosState.length === 0)
          promises.push(cargarListaGenerosRegCivil());
        if (nivelesEnsenanzaState.length === 0)
          promises.push(getListadoEnsenanzas());
        if (identidadesGeneroState.length === 0)
          promises.push(cargarListaIdentidadGenero());
        if (aniosState.length === 0) promises.push(cargarListaAnios());
        if (sectoresState.length === 0) promises.push(cargarListaSectores());
        if (asignaturasState.length === 0)
          promises.push(getAsignaturasCurriculum());
        if (nivelesAcademicosState.length === 0)
          promises.push(cargarListaNivelesAcademicos());

        const responses = await Promise.all(promises);

        // Verifica los resultados y despacha solo si es necesario
        if (regionState.length === 0 && responses[0]) {
          dispatch(setRegiones(responses[0]));
          setListaRegiones(responses[0]);
        } else {
          setListaRegiones(regionState);
        }

        if (comunasState.length === 0 && responses[1]) {
          dispatch(setComunas(responses[1]));
          setListaComunas(responses[1]);
        } else {
          setListaComunas(comunasState);
        }

        if (nacionalidadesState.length === 0 && responses[2]) {
          const nacionalidadesMapeadas = responses[2].map((nacionalidad) => ({
            id: nacionalidad.id,
            name: nacionalidad.paisNacionalidad,
            areaCode: nacionalidad.paisCodigoTelefono,
            alpha2Code: nacionalidad.paisCodigoAlpha2,
            countryName: nacionalidad.paisNombre,
          }));
          dispatch(setNacionalidades(nacionalidadesMapeadas));
          setListaNacionalidades(nacionalidadesMapeadas);
        } else {
          setListaNacionalidades(nacionalidadesState);
        }

        if (etniasState.length === 0 && responses[3]) {
          dispatch(setEtnias(responses[3]));
          setListaEtnias(responses[3]);
        } else {
          setListaEtnias(etniasState);
        }

        if (generosState.length === 0 && responses[4]) {
          dispatch(setGeneros(responses[4]));
          setListaGeneros(responses[4]);
        } else {
          setListaGeneros(generosState);
        }

        if (nivelesEnsenanzaState.length === 0 && responses[5]) {
          // console.log("Niveles de enseñanza:", responses[5].data.curriculumEnsenanzas);
          dispatch(setNivelesEnsenanza(responses[5].data.curriculumEnsenanzas));
          setListaNivelesEnsenanza(responses[5].data.curriculumEnsenanzas);
        } else {
          setListaNivelesEnsenanza(nivelesEnsenanzaState);
        }

        if (identidadesGeneroState.length === 0 && responses[6]) {
          dispatch(setIdentidadesGenero(responses[6]));
          setListaIdentidadesGenero(responses[6]);
        } else {
          setListaIdentidadesGenero(identidadesGeneroState);
        }

        if (aniosState.length === 0 && responses[7]) {
          dispatch(setAnios(responses[7]));
          setListaAnios(responses[7]);
        } else {
          setListaAnios(aniosState);
        }

        if (sectoresState.length === 0 && responses[8]) {
          dispatch(setSectores(responses[8]));
          setListaSectores(responses[8]);
        } else {
          setListaSectores(sectoresState);
        }

        if (asignaturasState.length === 0 && responses[9]) {
          // console.log(responses[1]);
          dispatch(setAsignaturas(responses[9]));
          setListaAsignaturas(responses[9]);
        } else {
          setListaAsignaturas(asignaturasState);
        }

        if (nivelesAcademicosState.length === 0 && responses[10]) {
          dispatch(setNivelesAcademicos(responses[10]));
          setListaNivelesAcademicos(responses[10]);
        } else {
          setListaNivelesAcademicos(nivelesAcademicosState);
        }
      } catch (error) {
        console.error("Error al cargar las listas:", error);
      }
    };

    loadInitialData();
  }, [
    comunasState,
    dispatch,
    etniasState,
    generosState,
    nacionalidadesState,
    nivelesEnsenanzaState,
    regionState,
    identidadesGeneroState,
    aniosState,
    sectoresState,
    nivelesAcademicosState,
    asignaturasState,
  ]);

  useEffect(() => {
    if (ficha && !isFichaCargada) {
      setFuncionario({
        personaId: ficha.personaId,
        personal: {
          run: formatearRUN(ficha.run + "-" + ficha.runDv),
          nombre: ficha.nombres,
          nombreSecundario: ficha.nombresSecundarios,
          apellidoPaterno: ficha.apellidoPaterno,
          apellidoMaterno: ficha.apellidoMaterno,
          email: ficha.email,
          emailInstitucional: ficha.emailInstitucional,
          telefono: ficha.telefono,
          fechaNacimiento: ficha.fechaNacimiento,
          genero: ficha.genero,
          identidadGenero: ficha.generoIdentidad,
          nacionalidad: ficha.nacionalidad,
          etnia: ficha.etnia,
          region: ficha.region,
          comuna: ficha.comuna,
          direccion: ficha.direccion,
        },
        antecedentesAcademicos: ficha.antecedentesAcademicos,
        profesor: ficha.profesor || {}, // Verifica si ficha.profesor existe y usa un objeto vacío si no
        directivo: ficha.directivo || {},
        administrativo: ficha.administrativo || {},
        especialista: ficha.especialista || {},
        auxiliar: ficha.auxiliar || {},
        contratoHoras: ficha.contratoHoras,
        estadoIdoneidad: ficha.estadoIdoneidad,
      });

      // Configura los roles seleccionados en base a los datos recibidos
      setRolesSeleccionados({
        directivo: ficha.directivo?.directivoEnabled || false,
        profesor: ficha.profesor?.profesorEnabled || false,
        administrativo: ficha.administrativo?.administrativoEnabled || false,
        especialista: ficha.especialista?.especialistaEnabled || false,
        auxiliar: ficha.auxiliar?.auxiliarEnabled || false,
      });

      setTabProfesor(ficha.profesor || {});

      setIsFichaCargada(true);

      console.log("Ficha cargada:", ficha);
    }
  }, [ficha, isFichaCargada]);

  useEffect(() => {
    if (isFichaCargada) {
      actualizarPestanias(); // Actualiza las pestañas una vez que `funcionario` está cargado
    }
  }, [actualizarPestanias, funcionario, isFichaCargada]);

  useEffect(() => {
    actualizarPestanias();
  }, [actualizarPestanias]);

  useEffect(() => {
    setListaPestanias((prevPestanias) =>
      prevPestanias.map((tab) => {
        switch (tab.eventKey) {
          case "tabDirectivo":
            return {
              ...tab,
              isSelected: rolesSeleccionados.directivo,
            };
          case "tabProfesor":
            return {
              ...tab,
              isSelected: rolesSeleccionados.profesor,
            };
          case "tabAdministrativo":
            return {
              ...tab,
              isSelected: rolesSeleccionados.administrativo,
            };
          case "tabEspecialista":
            return {
              ...tab,
              isSelected: rolesSeleccionados.especialista,
            };
          case "tabAuxiliar":
            return {
              ...tab,
              isSelected: rolesSeleccionados.auxiliar,
            };
          default:
            return tab;
        }
      })
    );
  }, [rolesSeleccionados]);
  

  return (
    <Container fluid style={{ height: "90dvh", overflowX: "hidden" }}>
      <Row style={{ position: "relative" }}></Row>
      <TabNavigator
        title="Ficha Funcionario"
        activeTab={pestañaActiva}
        onSelectTab={setPestañaActiva}
        tabList={listaPestanias}
        additionalProps={{
          isFichaCargada,
          isReadyToUploadAvatar,
          nacionalidades: listaNacionalidades,
          regiones: listaRegiones,
          comunas: listaComunas,
          funcionario,
          setFuncionario,
          errors,
          setErrors,
          etnias: listaEtnias,
          generos: listaGeneros,
          identidadesGenero: listaIdentidadesGenero,
          cursos: [],
          ensenanzas: listaNivelesEnsenanza,
          sectores: listaSectores,
          asignaturas: listaAsignaturas,
          anios: listaAnios,
          nivelesAcademicos: listaNivelesAcademicos,
          rolesSeleccionados,
          setRolesSeleccionados,
          tabProfesor,
          setTabProfesor,
          tabDirectivo,
          setTabDirectivo,
          isDirectivo,
        }}
      />

      <Row className="mt-auto w-100 py-2">
        <Col className="d-flex justify-content-center justify-content-md-end align-items-center gap-3">
          <CustomTooltip tooltipMsg={"Ir a la lista"}>
            <Button
              variant="color-gray-outline"
              onClick={() => navigate("/funcionarios")}
            >
              Ir Lista
            </Button>
          </CustomTooltip>
          <CustomTooltip tooltipMsg={false ? "No tienes permisos" : "Guardar"}>
            <div style={{ display: "block", alignItems: "center" }}>
              <Button
                variant="color-acento"
                onClick={handleSaveFicha}
                disabled={false}
                style={{display: isDirectivo ? "block" : "none"}}
              >
                Guardar
              </Button>
            </div>
          </CustomTooltip>
        </Col>
      </Row>
    </Container>
  );
}

export default FichaFuncionario;
