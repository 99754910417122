import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Alert, Container, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useSaveAsistenciaClase from '../../../hooks/useSaveAsistenciaClase';
import obtenerColorMapaCalor from '../../../util/utilidadesMapaCalor';
import estilosEstado from '../../objetoEstilos/estiloEstados';
import { estilosEstadoAdicionales } from "../../objetoEstilos/estiloEstudiante";
import Avatar from '../avatar/Avatar';
import ModalAutenticacion from '../confirmarFirma/ModalAutenticacion';
import NotificacionToast from '../notificacion/NotificacionToast';
import ModalCambioEstado from './ModalCambioEstado';

const EstadoClaseIcono = ({ estadoClaseId }) => {
  const isGuardada = estadoClaseId === 2;
  const color = isGuardada ? 'var(--color-verde)' : 'var(--color-icon-tooltip)';
  const mensajeTooltip = isGuardada ? 'Asistencia guardada' : 'Asistencia sin guardar';

  const estilo = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '2px',
    marginRight: '5px',
    marginTop: '-2px',
    position: 'relative',
    color: color
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{mensajeTooltip}</Tooltip>}
    >
      <div style={estilo}>
        <svg viewBox="0 0 384 512" style={{ width: '8px', height: '0.9rem', fill: 'currentColor' }}>
          <path d="M384 0l0 512L0 512 0 0 384 0z" />
        </svg>
      </div>
    </OverlayTrigger>
  );
};

const estilos = {
  encabezado: {
    backgroundColor: "var(--color-header)",
    color: "var(--color-texto-oscuro)",
    position: 'sticky',
    borderBottom: '1px solid lightgrey',
    top: 0,
    zIndex:8,
    padding: 0,
    margin: 0,
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  encabezadoPrincipal: {
    borderTop: '2px solid white',
    borderBottom: '1px solid var(--color-header)',
    borderLeft: 'none',
    borderRight: 'none',
    backgroundColor: "var(--color-header)",
    height: '30px',
    lineHeight: '30px',
    top: '-1px'
  },
  encabezadoSecundario: {
    borderTop: 'none',
    borderBottom: '1px solid var(--color-header)',
    borderLeft: 'none',
    borderRight: 'none',
    backgroundColor: "var(--color-header)",
    height: '30px',
    lineHeight: '30px',
    top: '28px',
    zIndex: 8,
  },
  texto: {
    color: "var(--color-texto-oscuro)",
  },
  sinBordeLateral: {
    borderLeft: 'none',
    borderRight: 'none',
  },
  bordeDerecha: {
    borderRight: '1px solid var(--border)',
  },
  bordeIzquierda: {
    borderLeft: '1px solid var(--border)',
  },
  contenedorTabla: {
    maxHeight: '73dvh',
    overflowY: 'auto',
    backgroundColor: 'white',
  },
  celda: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    border: "none",
  },
  celdaEstado: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    border: "none",
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  icono: {
    fontSize: '1.4rem',
  },
  bloqueFecha: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  filaHover: {
    backgroundColor: "var(--color-doble-hover)",
  },
  columnaHover: {
    backgroundColor: "var(--color-doble-hover)",
  },
  avatar: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  textoEstudiante: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: 'rgb(136, 136, 136)',
    whiteSpace: 'nowrap',         
    overflow: 'hidden',           
    textOverflow: 'ellipsis',     
    maxWidth: '280px', 
  },
  lineaRoja: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    borderBottom: '2px solid red',
    zIndex:7,
  },
  celdaInvalida: {
    backgroundColor: 'var(--color-doble-hover)',
    cursor: 'not-allowed',
  },
  stickyColumna: {
    position: 'sticky',
    backgroundColor: 'white',
    zIndex: 3,
  },
  stickyColumnaDerecha: {
    position: 'sticky',
    backgroundColor: 'white',
    zIndex: 3,
  },
  bordeDerechoUltimaColumna: {
    borderRight: '1px solid var(--border)',
  },
};

const formatearFecha = (fecha) => {
  const dia = new Date(fecha).toLocaleDateString("es-ES", {
    weekday: "short",
    timeZone: "UTC",
  }).slice(0, 3).toUpperCase();
  const numero = new Date(fecha).getUTCDate();
  return `${dia} ${numero}`;
};

const esAntesDeFechaMatricula = (claseFecha, fechaMatricula) => {
  return new Date(claseFecha) < new Date(fechaMatricula);
};

const TablaAsistenciaMensualAsignaturaProfesorCopia = ({ datosAsistencia, curso, curso_id, asignatura, asignatura_id, asignaturaColor}) => {
  const navigate = useNavigate();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [hoveredBlock, setHoveredBlock] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalErrores, setShowModalErrores] = useState(false);
  const [currentEstudiante, setCurrentEstudiante] = useState({});
  const [currentClaseId, setCurrentClaseId] = useState(null);
  const [currentFecha, setCurrentFecha] = useState('');
  const [currentBloque, setCurrentBloque] = useState('');
  const [currentEstado, setCurrentEstado] = useState('sinRegistro');
  const [currentAsistenciaId, setCurrentAsistenciaId] = useState(null);
  const [mostrarToast, setMostrarToast] = useState(false);
  const [datosActualizados, setDatosActualizados] = useState(datosAsistencia || {});
  const [estudiantesInvalidos, setEstudiantesInvalidos] = useState([]);
  const [showModalAutenticacion, setShowModalAutenticacion] = useState(false);
  const [estadoTemporal, setEstadoTemporal] = useState(null);
  const [countdown, setCountdown] = useState(10);
  const [nombreTemporal, setNombreTemporal] = useState(''); //debo encontrar una solucion mas efectiva recordar 
  

  const { guardarAsistencia, cargando, error } = useSaveAsistenciaClase();

  useEffect(() => {
    setDatosActualizados(datosAsistencia);
  }, [datosAsistencia]);

  const estadosMap = {
    presente: 1,
    ausente: 2,
    atrasado: 3,
    retirado: 4,
    fueraDeAula: 5,
    sinRegistro: 0
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentEstudiante({});
    setCurrentClaseId(null);
    setCurrentFecha('');
    setCurrentBloque('');
    setCurrentEstado('sinRegistro');
    setCurrentAsistenciaId(null);
    setHoveredRow(null);
    setClickedRow(null);
    setHoveredColumn(null);
    setHoveredBlock(null);
  };

  const handleCloseModalErrores = () => {
    setShowModalErrores(false);
    setEstudiantesInvalidos([]);
  };

  const handleOpenModal = (estudiante, fecha, bloque, estadoActual, asistenciaId, rowIndex, claseId) => {
    setCurrentEstudiante({
      ...estudiante,
      numLista: estudiante.numLista,
      nombre: estudiante.nombre,
    });
    setCurrentFecha(fecha);
    setCurrentBloque(bloque);
    setCurrentEstado(estadoActual || 'sinRegistro');
    setCurrentAsistenciaId(asistenciaId);
    setCurrentClaseId(claseId);
    setShowModal(true);
    setClickedRow(rowIndex);
    setHoveredRow(null);
    setHoveredColumn(null);
    setHoveredBlock(null);
  };

  const handleSaveEstado = (nuevoEstado, estudianteId, claseId, asistenciaId) => {
    setEstadoTemporal({ nuevoEstado, estudianteId, claseId, asistenciaId });
    setShowModal(false);
  
    setTimeout(() => {
      const razones = validarEstudiante(currentEstudiante);
  
      // Guardar temporalmente el nombre antes de abrir el modal de errores
      setNombreTemporal(currentEstudiante.nombre || 'Nombre desconocido');
  
      console.log("Nombre antes del modal de errores:", currentEstudiante.nombre);
      console.log("Nombre temporal antes del modal de errores:", nombreTemporal);
  
      if (razones.length > 0) {
        setEstudiantesInvalidos(razones);
        setShowModalErrores(true);
      } else {
        setShowModalAutenticacion(true);
      }
    }, 300);
  };
  
  
  

  // Nueva función para manejar el click en el botón de "Firmar" del modal de aviso
  const handleFirmarClick = () => {
    setShowModalErrores(false);
    setShowModalAutenticacion(true);
  };
  

useEffect(() => {
  if (countdown > 0 && showModalErrores) {
    const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    return () => clearTimeout(timer);
  } else if (countdown === 0) {
    // Al llegar la cuenta regresiva a 0, mostrar el modal de autenticación
    setShowModalErrores(false);
    setShowModalAutenticacion(true);
  }
}, [countdown, showModalErrores]);

const handleConfirmAutenticacion = async (codigoAutenticacion) => {
  if (estadoTemporal) {
    const { nuevoEstado, estudianteId, claseId, asistenciaId } = estadoTemporal;
    const nuevoEstadoId = estadosMap[nuevoEstado];
    try {
      const response = await guardarAsistencia(claseId, estudianteId, nuevoEstadoId);

      setDatosActualizados(prevDatos => {
        const nuevosDatos = prevDatos.estudiantes.map(estudiante => {
          if (estudiante.estudianteId === estudianteId) {
            return {
              ...estudiante,
              clases: estudiante.clases.map(clase => {
                if (clase.claseId === claseId) {
                  return { ...clase, asistenciaEstadoClase: nuevoEstado };
                }
                return clase;
              }),
              porcAsistenciaMes: response.asistenciaAsignatura
            };
          }
          return estudiante;
        });
        return { ...prevDatos, estudiantes: nuevosDatos };
      });

      setMostrarToast(true);
    } catch (error) {
      console.error("Error al guardar la asistencia:", error);
    }
  }
  setShowModalAutenticacion(false);
  setEstadoTemporal(null);
  setNombreTemporal('');  // Limpiamos el nombre temporal
};

  const manejarRedireccionVistaDiaria = (fecha, curso_id, asignatura_id, bloque_numero, clase_id, bloqueIni, bloqueFin, estadoClaseId, asignaturaColor) => {
    navigate('/asistencia-diaria-asignatura-profesor', {
      state: {
        fecha: moment(fecha).format('YYYY-MM-DD'), 
        curso_id,
        asignatura_id,
        curso, 
        asignatura, 
        bloque_numero,
        clase_id,
        start: moment(`${fecha} ${bloqueIni}`, 'YYYY-MM-DD HH:mm').toDate(),
        end: moment(`${fecha} ${bloqueFin}`, 'YYYY-MM-DD HH:mm').toDate(),
        estadoClaseId,
        asignaturaColor,
        from: '/asistencia-mes-asignatura-profesor' 
      }
    });
  };

  const manejarClickCelda = (clase) => {
    manejarRedireccionVistaDiaria(
      clase.bloqueFecha,
      curso_id,
      asignatura_id,
      clase.bloqueNum,
      clase.claseId,
      clase.bloqueIni,
      clase.bloqueFin,
      clase.estadoClaseId,
      asignaturaColor
    );
  };

  const validarEstudiante = (estudiante) => {
    let razones = [];
    if (!estudiante.numLista) razones.push("Sin número de lista");
    if (!estudiante.numMatricula) razones.push("Sin número de matrícula");
    if (!estudiante.fechaMatricula || estudiante.fechaMatricula === 'null') razones.push("Sin fecha de matrícula");
    return razones;
  };
  

  const handleCeldaClick = (estudiante, clase, fecha, bloque, estadoActual, asistenciaId, rowIndex, claseId) => {
    if (validarEstudiante(estudiante)) {
      // Si el estudiante es válido, abrimos el modal de cambio de estado
      handleOpenModal(estudiante, fecha, bloque, estadoActual, asistenciaId, rowIndex, claseId);
    } else {
      // Si el estudiante no es válido, abrimos el modal de cambio de estado primero
      handleOpenModal(estudiante, fecha, bloque, estadoActual, asistenciaId, rowIndex, claseId);
  
      // Luego, al guardar el estado, mostramos el modal de errores
      // setTimeout(() => {
      //   setEstudiantesInvalidos([estudiante]);
      //   setShowModalErrores(true); // Mostrar modal de errores después de cerrar el modal de cambio de estado
      // }, 500);  // Aseguramos que se muestre después de un pequeño delay
    }
  };

  if (!datosActualizados || !datosActualizados.clases || !datosActualizados.estudiantes) {
    return (
      <Container fluid className="bg-white p-4">
        <Alert variant="info">No hay datos de asistencia disponibles.</Alert>
      </Container>
    );
  }

  const { clases, estudiantes } = datosActualizados;
  const datosAgrupados = estudiantes.map((estudiante) => ({
    ...estudiante,
    asistencia: estudiante.clases,
  }));

  

  const fechasUnicas = [
    ...new Set(clases.map((clase) => clase.bloqueFecha)),
  ].sort((a, b) => new Date(a) - new Date(b));

  const fechasYBloquesUnicos = fechasUnicas.flatMap((fecha) => {
    const bloquesUnicos = [...new Set(clases.filter(clase => clase.bloqueFecha === fecha).map(clase => clase.bloqueNum))].sort((a, b) => a - b);
    return bloquesUnicos.map((bloque, index) => ({ fecha, bloque, esUltimo: index === bloquesUnicos.length - 1 }));
  });

  const fechaActual = new Date();

  

  return (
    <Container fluid className="bg-white pt-0 px-3">
      <div className="table-responsive pb-0" style={estilos.contenedorTabla}>
       <Table bordered className="pb-0 mb-0" style={estilos.sinBordeLateral}>
  <thead>
    <tr className="m-0">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-numero-lista">Número de lista</Tooltip>}
      >
        <th className="text-center col-0 m-0 align-middle" style={{ ...estilos.encabezado, ...estilos.encabezadoPrincipal, width: '50px', position: 'sticky', left: 0, zIndex: 9}} rowSpan={2}>
          N°
        </th>
      </OverlayTrigger>
      <th className="text-center p-1 m-0 align-middle" style={{ ...estilos.encabezado, ...estilos.encabezadoPrincipal, ...estilos.bordeDerecha, width: '350px', position: 'sticky', left: '50px', zIndex: 9}} rowSpan={2}>
        Estudiante
      </th>
      {fechasUnicas.map((fecha, colIdx) => {
        const bloquesUnicos = [...new Set(clases.filter(clase => clase.bloqueFecha === fecha).map(clase => clase.bloqueNum))].sort((a, b) => a - b);
        return (
          <th
            key={fecha}
            colSpan={bloquesUnicos.length}
            className="text-center p-0 m-0 align-middle"
            style={{
              ...estilos.encabezado,
              ...estilos.encabezadoPrincipal,
              ...(colIdx === fechasUnicas.length - 1 ? estilos.bordeDerechoUltimaColumna : {}),
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div>{formatearFecha(fecha)}</div>
            </div>
          </th>
        );
      })}
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-presente">
          <math display="block" className="math-font">
            <mrow>
              <mfrac>
                <mn>Total bloques presente</mn>
                <ms>Total bloques del mes a la fecha</ms>
              </mfrac>
            </mrow>
          </math>
        </Tooltip>}
      >
        <th className="text-center p-1 m-0 align-middle" style={{ ...estilos.encabezado, ...estilos.encabezadoPrincipal, ...estilos.bordeIzquierda, width: '105px', position: 'sticky', right: 0, zIndex: 9 }} rowSpan={2}>
          % Asistencia
        </th>
      </OverlayTrigger>
    </tr>
    <tr className="m-0">
      {fechasYBloquesUnicos.map(({ fecha, bloque, esUltimo }, index) => {
        const clase = clases.find(c => c.bloqueFecha === fecha && c.bloqueNum === bloque);
        const isFutureDate = new Date(fecha) > fechaActual;
        return (
          <OverlayTrigger
            key={`${fecha}-B${bloque}`}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-${fecha}-B${bloque}`}>
                Bloque {bloque} <br />
                {clase.bloqueIni} - {clase.bloqueFin}
              </Tooltip>
            }
          >
            <th
              className="text-center p-0 m-0 align-middle"
              style={{
                ...estilos.encabezado,
                ...estilos.encabezadoSecundario,
                cursor: isFutureDate ? 'not-allowed' : 'pointer',
                ...(esUltimo ? estilos.bordeDerechoUltimaColumna : {}),
              }}
              onClick={() => {
                if (clase.estadoClaseId !== 2 && !isFutureDate) {
                  manejarClickCelda(clase);
                }
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <EstadoClaseIcono estadoClaseId={clase.estadoClaseId} />
                <div style={{ ...estilos.bloqueTexto }}>{`B${bloque}`}</div>
              </div>
            </th>
          </OverlayTrigger>
        );
      })}
    </tr>
  </thead>
  <tbody>
    {datosAgrupados.map((est, idx) => {
      const fechaPrimerClase = new Date(fechasUnicas[0]);
      const colorMapaCalor = !est.retirado && fechaPrimerClase <= fechaActual ? obtenerColorMapaCalor(est.porcAsistenciaMes, false, true) : 'transparent';
      const fechaRetiro = est.fechaRetiro ? new Date(est.fechaRetiro) : null;

      return (
        <tr
          key={est.estudianteId}
          style={(hoveredRow === idx || clickedRow === idx) ? estilos.filaHover : {}}
          onMouseEnter={() => setHoveredRow(idx)}
          onMouseLeave={() => setHoveredRow(null)}
        >
          <td className="text-center align-middle" style={{ ...estilos.textoEstudiante, ...estilos.stickyColumna, borderBottom: '1px solid var(--border)', borderLeft: 'none', borderRight: 'none', height: '30px', position: 'sticky', left: 0, zIndex: 1 }}>
            {est.numLista !== null ? (
              est.numLista
            ) : est.numMatricula !== null ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-${est.estudianteId}`}>{estilosEstadoAdicionales.matriculadoSinNumeroLista.description}</Tooltip>}
              >
                <i className={`${estilosEstadoAdicionales.matriculadoSinNumeroLista.icon}`} style={{ color: estilosEstadoAdicionales.matriculadoSinNumeroLista.iconColor, fontSize: "20px" }}></i>
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-${est.estudianteId}`}>{estilosEstadoAdicionales.sinNumeroMatriculaYLista.description}</Tooltip>}
              >
                <i className={`${estilosEstadoAdicionales.sinNumeroMatriculaYLista.icon}`} style={{ color: estilosEstadoAdicionales.sinNumeroMatriculaYLista.iconColor, fontSize: "20px" }}></i>
              </OverlayTrigger>
            )}
            {est.retirado && fechaRetiro && new Date(fechasUnicas[0]) >= fechaRetiro && (
              <div style={estilos.lineaRoja}></div>
            )}
          </td>
          <OverlayTrigger
            placement="top"
            overlay={
              est.retirado ? (
                <Tooltip id={`tooltip-retirado-${est.estudianteId}`}>
                  Estudiante retirado
                </Tooltip>
              ) : <></>
            }
          >
            <td className="text-center align-middle" style={{ ...estilos.textoEstudiante, ...estilos.stickyColumna, borderBottom: '1px solid var(--border)', ...estilos.bordeDerecha, borderLeft: 'none', height: '30px', position: 'sticky', left: '50px', zIndex: 1 }}>
              <div style={{ display: "flex", alignItems: "center", height: '100%', gap: 5 }}>
                {/* <img src={defaultAvatar} alt="Avatar" style={estilos.avatar} /> */}
                <Avatar run={est.run || est.ipe} width={"30px"} height={'30px'}  />
                <span style={estilos.textoEstudiante}>{`${est.nombre}`}</span>
              </div>
              {est.retirado && fechaRetiro && new Date(fechasUnicas[0]) >= fechaRetiro && (
                <div style={estilos.lineaRoja}></div>
              )}
            </td>
          </OverlayTrigger>
          {fechasYBloquesUnicos.map(({ fecha, bloque, esUltimo }, colIdx) => {
            const bloqueDia = est.asistencia.find(a => a.claseFecha === fecha && a.claseNumero === bloque);
            const estado = bloqueDia ? (estilosEstado[bloqueDia.asistenciaEstadoClase] || null) : null;
            const claseFecha = bloqueDia ? bloqueDia.claseFecha : null;
            const esFechaInvalida = claseFecha && esAntesDeFechaMatricula(claseFecha, est.fechaMatricula);
            const clase = clases.find(c => c.bloqueFecha === fecha && c.bloqueNum === bloque);
            const isFutureDate = new Date(fecha) > fechaActual;
            const mostrarLineaRoja = fechaRetiro && new Date(fecha) >= fechaRetiro;

            return (
              <td
                key={`${fecha}-B${bloque}`}
                className="text-center align-middle"
                style={{
                  padding: 0,
                  textAlign: "center",
                  borderBottom: '1px solid var(--border)',
                  height: "40px",
                  position: 'relative',
                  ...(hoveredColumn === colIdx ? estilos.columnaHover : {}),
                  ...(esFechaInvalida ? estilos.celdaInvalida : {}),
                  ...(esUltimo ? estilos.bordeDerechoUltimaColumna : {}),
                }}
                onClick={() => {
                  if (!mostrarLineaRoja && clase.estadoClaseId !== 2 && !isFutureDate) {
                    manejarClickCelda(clase);
                  }
                }}
              >
                <div
                  style={{
                    ...estilos.celdaEstado,
                    backgroundColor: 'transparent',
                    height: '100%',
                    transition: 'background-color 0.3s ease',
                    cursor: mostrarLineaRoja ? 'not-allowed' : 'pointer',
                  }}
                  onMouseEnter={() => { setHoveredRow(idx); setHoveredColumn(colIdx); setHoveredBlock(`${idx}-${colIdx}`); }}
                  onMouseLeave={() => { setHoveredRow(null); setHoveredColumn(null); setHoveredBlock(null); }}
                  onClick={!mostrarLineaRoja && bloqueDia?.asistenciaEstadoClaseId !== -1 && bloqueDia?.asistenciaEstadoClaseId !== undefined && !esFechaInvalida && !isFutureDate ? () => handleCeldaClick(est, clase, fecha, bloque, bloqueDia ? bloqueDia.asistenciaEstadoClase : 'sinRegistro', bloqueDia ? bloqueDia.asistenciaId : null, idx, bloqueDia ? bloqueDia.claseId : null) : undefined}
                >
                  {estado && estado.icon && <i className={estado.icon} style={{ ...estilos.icono, color: estado.iconColor }}></i>}
                  {mostrarLineaRoja && <div style={estilos.lineaRoja}></div>}
                </div>
              </td>
            );
          })}
 <td className="text-center align-middle" style={{
  ...estilos.texto,
  ...estilos.bordeIzquierda,
  ...estilos.stickyColumnaDerecha,
  borderRight: "none",
  borderBottom: '1px solid var(--border)',
  backgroundColor: colorMapaCalor,
  height: '30px',
  color: '#1c2833',
  position: 'sticky',
  right: 0,
  zIndex: 1,
}}>
  <span>{est.porcAsistenciaMes?.toFixed(2)}%</span>
  {est.retirado && new Date(fechasUnicas[fechasUnicas.length - 1]) >= new Date(est.fechaRetiro) && (
    <div style={estilos.lineaRoja}></div>
  )}
</td>
        </tr>
      );
    })}
  </tbody>
</Table>

      </div>
      <ModalCambioEstado
        show={showModal}
        handleClose={handleCloseModal}
        estudianteNomPri={currentEstudiante.nombre}
        numLista={currentEstudiante.numLista}
        fecha={currentFecha}
        bloque={currentBloque}
        estadoActual={currentEstado}
        handleSave={handleSaveEstado}
        estudianteId={currentEstudiante.estudianteId}
        claseId={currentClaseId}
        asistenciaId={currentAsistenciaId}
      />
      <ModalAutenticacion
        show={showModalAutenticacion}
        onHide={() => setShowModalAutenticacion(false)}
        onConfirm={handleConfirmAutenticacion}
      />
      <NotificacionToast
        mensaje="Asistencia guardada correctamente"
        mostrar={mostrarToast}
        setMostrar={setMostrarToast}
        // estiloPersonalizado={{ top: '20px', right: '20px' }}
      />
   <Modal centered show={showModalErrores} onHide={handleCloseModalErrores} size="lg">
      <Modal.Header closeButton className="p-4">
        <Modal.Title style={{ width: "100%", textAlign: "center" }}>
          AVISO
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-1">
        <p className="text-center p-3">
          El/La estudiante, {nombreTemporal},, no cuenta con toda la información necesaria
          para el correcto funcionamiento en la plataforma.
          No obstante, su asistencia será registrada.<br />
          Le recomendamos comunicarse con UTP<br/> para corregir esta situación.
          <br />
          <b>Requerimientos no cumplidos:</b> {estudiantesInvalidos.join(', ')}
          <br />
          El proceso continuará en {countdown} segundos.
        </p>
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary" onClick={handleFirmarClick}>Firmar</button>
        </div>
      </Modal.Body>
    </Modal>
    </Container>
  );
};

export default TablaAsistenciaMensualAsignaturaProfesorCopia;
