import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BiCalendarCheck } from 'react-icons/bi';
import { FaCaretDown } from 'react-icons/fa'; // Usamos FaCaretDown para una flecha pequeña

const ButtonHorario = ({ onClick, showCalendarButton }) => {
  const renderHorarioTooltip = (props) => (
    <Tooltip id="button-tooltip-horario" {...props}>
      Menú Horario
    </Tooltip>
  );

  return (
    showCalendarButton && (
      <OverlayTrigger placement="top" overlay={renderHorarioTooltip}>
        <Button 
          style={{ 
            height: "35px",
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            padding: '0 30px' // Ajustamos el padding para que no ocupe mucho espacio
          }}  
          variant="color-acento" 
          onClick={onClick} 
          className="calendar-button"
        >
          <BiCalendarCheck style={{ fontSize: '1.5em' }} />
          <FaCaretDown style={{ fontSize: '0.7em', marginLeft: '2px', color: 'white' }} />
        </Button>
      </OverlayTrigger>
    )
  );
};



export default ButtonHorario;
