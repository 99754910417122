import React from "react";
import InConstruction from "../../../components/construction/InConstruction";

const TabTalleres = () => {
  return (
    <div>
      <h3>Talleres</h3>
      <p>Aquí se mostrará la información de los talleres asociados al curso.</p>
      <InConstruction />
    </div>
  );
};

export default TabTalleres;
