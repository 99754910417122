import { useEffect, useState } from 'react';
import { getPeriodosAcademicos } from '../services/PostsService';

const useGetPeriodosAcademicos = () => {
    const [periodos, setPeriodos] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPeriodos = async () => {
            setCargando(true);
            setError(null);

            try {
                const response = await getPeriodosAcademicos();
                const data = response.data;

                setPeriodos(data);
                localStorage.setItem('periodos', JSON.stringify(data));
                console.log('Periodos obtenidos y guardados:', data);

                // Aquí seleccionamos el periodo actual basado en la fecha
                const fechaActual = new Date();
                const periodoActual = data.find(periodo => {
                    const fechaInicio = new Date(periodo.periodoAcademicoFechaIni);
                    const fechaFin = new Date(periodo.periodoAcademicoFechaFin);
                    return fechaActual >= fechaInicio && fechaActual <= fechaFin;
                });

                if (periodoActual) {
                    localStorage.setItem('currentPeriodo', JSON.stringify(periodoActual));
                    console.log('Periodo actual guardado:', periodoActual);
                }

            } catch (error) {
                console.error('Error al cargar los periodos:', error);
                setError(error.response ? error.response.data.message : error.message);
            } finally {
                setCargando(false);
            }
        };

        fetchPeriodos();
    }, []);

    return { periodos, cargando, error };
};

export default useGetPeriodosAcademicos;
