import {
  faCog,
  faCopy,
  faDownload,
  faEllipsisVertical,
  faMap,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import JSZip from "jszip";
import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import Swal from "sweetalert2";
import useGetInformeUnidadesGlobal from "../../../hooks/useGetInformeUnidadesGlobal";
import { descargarBoletinAsistencia } from "../../../services/PostsService";
import { copiarAlPortapapeles } from "../../../util/copiarPortapapeles";
import ConfigTablaNotas from "../calificaciones/ConfigTablaNotas";
import TablaConceptos from "../calificaciones/TablaConceptos";
import InformeCalificacionesGaspar from "../informeCalificacionEstudianteCurso/InformeCalificacionesGaspar";

const ButtonMultifuncional = ({
  tablaId,
  isHeatmapActive,
  setIsHeatmapActive,
  setMensajeToast,
  setMostrarToast,
  vista,
  mes,
  confNotasSum,
  confGlobal,
  dataEstudiantesNotas,
  dataColegio,
  dataCurso,
  periodoId,
  hasPermission,
  tablaConceptos,
}) => {
  const [mostrarMenu, setMostrarMenu] = useState(false);
  const [mostrarConfigNotas, setMostrarConfigNotas] = useState(false);

  const manejarToggleMenu = () => {
    setMostrarMenu(!mostrarMenu);
  };

  const semestre = periodoId === 1 ? "Primer Semestre" : "Segundo Semestre";

  const cursoId = vista === "informe-calificaciones-estudiante-curso" ? dataCurso?.cursoId : null;

  // Llama al hook solo una vez con cursoId
  const { dataDirector, notasEstudiantes, columnaUnidades, listadoEscalas,  notasAsignaturaNoInfluye, cantidadDecimales } = useGetInformeUnidadesGlobal(cursoId);

  const generarZIP = async () => {
    const zip = new JSZip();
  
    Swal.fire({
      title: "Procesando la descarga",
      text: "Este proceso puede tardar unos minutos.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    for (const estudiante of notasEstudiantes) {
      // Crear un div temporal fuera de la vista del usuario para renderizar el contenido
      const tempDiv = document.createElement("div");
      document.body.appendChild(tempDiv);
  
      // Estilos del div temporal
      tempDiv.style.position = "fixed";
      tempDiv.style.left = "-9999px";
      tempDiv.style.top = "-9999px";
      tempDiv.style.width = "100%";
      tempDiv.style.padding = "10px"; // Ajuste de padding para maximizar el espacio
      tempDiv.style.backgroundColor = "white";
  
      // Renderizar el componente en el div temporal
      const root = createRoot(tempDiv);
      root.render(
        <InformeCalificacionesGaspar
          dataColegio={dataColegio}
          dataCurso={dataCurso}
          listadoNotas={[estudiante]}
          semestre={semestre}
          columnasUnidades={columnaUnidades}
          listadoEscalas={listadoEscalas}
          dataDirector={dataDirector}
          notasAsignaturaNoInfluye={notasAsignaturaNoInfluye}
          cantidadDecimales={cantidadDecimales}
        />
      );
  
      // Esperar a que los estilos y el contenido se carguen completamente
      await new Promise((resolve) => setTimeout(resolve, 1000));
  
      // Generar el canvas a partir del contenido del div temporal
      const canvas = await html2canvas(tempDiv, {
        scale: 2,
        useCORS: true,
        backgroundColor: null,
      });
  
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: "a4",
        compress: true,
      });
  
      // Obtener dimensiones del PDF y ajustar la imagen para ocupar el máximo espacio sin cortar
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth - 20; // Margen reducido a 10pt a cada lado
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      // Ajustar la imagen para que ocupe el máximo espacio posible en la página
      const finalImgWidth = imgWidth;
      const finalImgHeight = Math.min(imgHeight, pdfHeight - 20);
  
      // Centrar la imagen en la página del PDF
      const positionX = (pdfWidth - finalImgWidth) / 2;
      const positionY = (pdfHeight - finalImgHeight) / 2;
  
      // Agregar la imagen al PDF ocupando una sola página
      pdf.addImage(imgData, "PNG", positionX, positionY, finalImgWidth, finalImgHeight, 'FAST');
  
      // Convertir el PDF en un blob y agregarlo al archivo ZIP
      const pdfBlob = pdf.output("blob");
      const fileName = `InformeCalificaciones_${estudiante.estudiante.nombrePrimario}_${estudiante.estudiante.apellidoPrimario}.pdf`;
      zip.file(fileName, pdfBlob);
  
      // Eliminar el div temporal después de la generación
      document.body.removeChild(tempDiv);
    }
  
    zip.generateAsync({ type: "blob" }).then((content) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.setAttribute("download", `Informes_Curso_${dataCurso.cursoNombre}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      Swal.fire({
        icon: "success",
        title: "Descarga Completa",
        text: "Los informes han sido generados y descargados con éxito.",
        confirmButtonColor: "var(--color-acento)",
      });
    });
  
    console.log("Todos los PDFs se han generado y descargado en un ZIP");
  };
  

  // Función para mostrar el modal con la tabla de conceptos
  const mostrarModalConceptos = () => {
    Swal.fire({
      title: "Escala de Conceptos",
      html: `<div id="tabla-conceptos"></div>`,
      showCloseButton: true,
      showConfirmButton: false,
      didOpen: () => {
        const tablaContenedor = document.getElementById("tabla-conceptos");
        const root = createRoot(tablaContenedor);
        root.render(<TablaConceptos conceptos={tablaConceptos} />);
      },
    });
  };

  const manejarSeleccion = async (key) => {
    const tabla = document.getElementById(tablaId);
    switch (key) {
      case "copiar":
        copiarAlPortapapeles(tabla, setMensajeToast, setMostrarToast);
        break;
      case "mapaCalor":
        setIsHeatmapActive(!isHeatmapActive);
        break;
      case "descargarBoletin":
        try {
          const response = await descargarBoletinAsistencia(mes);
          if (response.status === 204) {
            setMensajeToast("No hay datos de asistencia para el mes seleccionado.");
            setMostrarToast(true);
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "boletin_asistencia.xlsx");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
        } catch (error) {
          console.error("Error al descargar el boletín de asistencia:", error);
          setMensajeToast("El mes no tiene asistencias");
          setMostrarToast(true);
        }
        break;
      case "configuracionNotas":
        setMostrarConfigNotas(true);
        break;
      case "descargarCalificacionesCurso":
        generarZIP();
        break;
      case "mostrarConceptos": 
        mostrarModalConceptos(); 
        break;
      default:
        break;
    }
    setMostrarMenu(false);
  };

  const opciones = [
    { key: "configuracionNotas", icono: faCog, texto: "Configuración Tabla Notas" },
    { key: "copiar", icono: faCopy, texto: "Copiar" },
    { key: "mapaCalor", icono: faMap, texto: "Mapa de Calor" },
    { key: "descargarBoletin", icono: faDownload, texto: "Descargar Boletín" },
    { key: "descargarCalificacionesCurso", icono: faDownload, texto: "Descargar Informes Curso" },
  ];
  
  // Añadir la opción "Escala de Conceptos" si hay conceptos
  if (tablaConceptos && tablaConceptos.length > 0) {
    opciones.splice(1, 0, {
      key: "mostrarConceptos",
      icono: faTable, 
      texto: "Escala de Conceptos",
    });
  }

  const opcionesPorVista = {
    "calificaciones-curso": ["copiar", "configuracionNotas", "mostrarConceptos"],
    "porcentaje-asistencia-cursos": ["copiar", "mapaCalor"],
    "porcentaje-asistencia-estudiantes": ["copiar", "mapaCalor"],
    "asistencia-mensual-subvenciones-estudiantes": ["copiar"],
    "asistencia-mensual-subvenciones-cursos": ["descargarBoletin"],
    "informe-calificaciones-estudiante-curso": ["descargarCalificacionesCurso"],
  };
  // Filtrar las opciones según el permiso y la vista
  const opcionesFiltradas = opciones.filter(
    (opcion) =>
      opcionesPorVista[vista]?.includes(opcion.key) &&
      (opcion.key !== "configuracionNotas" || hasPermission)
  );

  const estilos = {
    contenedorBoton: {
      position: "relative",
      display: "inline-block",
    },
    boton: {
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      padding: "5px",
      fontSize: "24px",
    },
    menu: {
      display: mostrarMenu ? "block" : "none",
      position: "absolute",
      right: 0,
      top: "100%",
      backgroundColor: "#fff",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      zIndex: 51,
      minWidth: "150px",
      whiteSpace: "nowrap",
    },
    itemMenu: {
      padding: "10px 20px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
    },
    icono: {
      marginRight: "10px",
      fontSize: "20px",
    },
  };

  return (
    <div style={estilos.contenedorBoton}>
      <button style={estilos.boton} onClick={manejarToggleMenu}>
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </button>
      <div style={estilos.menu}>
        {opcionesFiltradas.map((opcion) => (
          <div key={opcion.key} style={estilos.itemMenu} onClick={() => manejarSeleccion(opcion.key)}>
            <FontAwesomeIcon icon={opcion.icono} style={estilos.icono} />
            {opcion.texto}
          </div>
        ))}
      </div>
      <ConfigTablaNotas
        mostrar={mostrarConfigNotas}
        cerrar={() => setMostrarConfigNotas(false)}
        confNotasSum={confNotasSum}
        confGlobal={confGlobal}
      />
    </div>
  );
};

export default ButtonMultifuncional;
