import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import useGetListadoCursos from '../../../hooks/useGetListadoCursos';
import ButtonFlechaAdelante from "../buttonpersonalizado/ButtonFlechaAdelante";
import ButtonFlechaAtras from "../buttonpersonalizado/ButtonFlechaAtras";
import ButtonMultifuncional from '../buttonpersonalizado/ButtonMultifuncional';
import DesplegableConBusqueda from '../desplegableConBusqueda/DesplegableConBusqueda';
import NotificacionToast from '../notificacion/NotificacionToast';

const NavAsistenciaSubvenciones = ({ fecha, onPrev, onNext, onCursoSelect, cursoId, cursoNombre }) => {
  const { cursos, loading, error } = useGetListadoCursos();
  const [mostrarToast, setMostrarToast] = useState(false);
  const [mensajeToast, setMensajeToast] = useState('');

  const formatDate = (date) => {
    const options = { month: 'long' };
    const formattedDate = new Date(date).toLocaleDateString('es-ES', options);
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1, 3).toLowerCase(); // Formato de tres letras y primera letra en mayúscula
  };

  const estilos = {
    rowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
      backgroundColor: '#fff',
    },
    fecha: {
      width: '50px', // Mantener un tamaño constante
      textAlign: 'center',
      fontSize: '1.1rem',
      color: '#1c2833',
      fontFamily: 'poppins, sans-serif',
      fontWeight: 500,
    },
    titulo: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '34px',
      color: 'rgb(28, 40, 51)',
      marginBottom: 0,
    },
  };

  useEffect(() => {
    if (cursoId && cursos.length > 0) {
      const cursoSeleccionado = cursos.find(curso => curso.cursoId === cursoId);
      if (cursoSeleccionado) {
        onCursoSelect(cursoSeleccionado.cursoId);
      }
    }
  }, [cursoId, cursos, onCursoSelect]);

  if (loading) {
    return <div>Cargando cursos...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Row style={estilos.rowContainer} className='m-0 p-0'>
        <Col xs="" className="d-flex align-items-center justify-content-start p-0">
          <DesplegableConBusqueda
            elementos={cursos}
            placeholder="Curso"
            selectedElement={cursoId} 
            onSelect={(curso) => {
              console.log("Curso seleccionado:", curso);
              onCursoSelect(curso.cursoId);
            }}
            displayProperty="cursoNombre"
            idProperty="cursoId"
          />
        </Col>
        <Col xs="auto" style={estilos.colCentrado}>
          <h5 style={estilos.titulo}>Asistencia Subvención Mensual Estudiante</h5> {/* Mostrar el nombre del curso */}
        </Col>
        <Col xs="" className="d-flex align-items-center justify-content-end p-0">
          <ButtonFlechaAtras onClick={onPrev} />
          <span className="h4 mx-2 mb-0" style={estilos.fecha}>
            {formatDate(fecha)}
          </span>
          <ButtonFlechaAdelante onClick={onNext} />
          <ButtonMultifuncional
            tablaId="tablaAsistencia"
            setMensajeToast={setMensajeToast}
            setMostrarToast={setMostrarToast}
            vista="asistencia-mensual-subvenciones-estudiantes"
          />
        </Col>
      </Row>
      <NotificacionToast mensaje={mensajeToast} mostrar={mostrarToast} setMostrar={setMostrarToast} />
    </>
  );
};

export default NavAsistenciaSubvenciones;