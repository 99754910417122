import React from "react";
import InConstruction from "../../../../components/construction/InConstruction";

function TabRegistroActividad() {
  return (
   <InConstruction />
  );
}

export default TabRegistroActividad;
