const getThStickyStyles = (isAcumulativa, isSubheader) => ({
    position: "sticky",
    top: isSubheader ? "30px" : "0",
    backgroundColor: "var(--color-header)",
    color: "var(--color-texto-oscuro)",
    borderBottom: "1px solid lightgrey",
    zIndex: 10,
    cursor: "pointer",
    height: "30px",
    padding: "0 10px",
  });
  
  const obtenerColorMapaCalor = (value, isAcumulativa) => {
    // Implementación de obtenerColorMapaCalor, la función específica de tu aplicación
    return value < 4 ? 'lightcoral' : 'lightgreen'; // Ejemplo simple
  };
  
  const estilostabla = (blockInputs, isHeatmapActive) => ({
    container: {
      maxHeight: "70vh",
      overflow: "auto",
      position: "relative",
    },
    thSticky: (isAcumulativa) => getThStickyStyles(isAcumulativa, false),
    thStickySubheader: {
      ...getThStickyStyles(false, true),
    },
    thHover: {
      backgroundColor: "lightgrey",
      display: "inline-block",
      padding: "2px 5px",
    },
    colNumero: {
      width: "50px",
      verticalAlign: "middle",
    },
    colEstudiante: {
      width: "350px",
      borderRight: "1px solid lightgrey",
      verticalAlign: "middle",
    },
    colNotas: {
      minWidth: "80px",
    },
    colNotasSinBorde: {
      minWidth: "80px",
      borderRight: "none",
    },
    colResumen: {
      minWidth: "100px",
      borderLeft: isHeatmapActive ? "3px solid white" : "1px solid lightgrey",
    },
    td: (isResumen, nota, isHoveredRow, isHoveredColumn) => ({
      borderBottom: "1px solid lightgrey",
      borderTop: "none",
      borderLeft: isResumen
        ? isHeatmapActive
          ? "3px solid white"
          : "1px solid lightgrey"
        : "none",
      backgroundColor:
        (isHoveredRow || isHoveredColumn) && !isHeatmapActive
          ? "var(--color-doble-hover)"
          : "white",
      textAlign: "center",
      padding: "8px",
      fontSize: "0.9em",
      height: "45px",
      color:
        (typeof nota === "number" && nota < 4) ||
        (typeof nota === "string" && parseFloat(nota.replace(",", ".")) < 4)
          ? "red"
          : "black",
    }),
    tdHeatmap: (isHeatmapActive, value, isResumen, isHoveredRow, isHoveredColumn) => ({
      borderBottom: "1px solid lightgrey",
      borderTop: "none",
      borderLeft: isResumen
        ? isHeatmapActive
          ? "3px solid white"
          : "1px solid lightgrey"
        : "none",
      backgroundColor: isHeatmapActive
        ? obtenerColorMapaCalor(parseFloat(String(value).replace(",", ".")), true)
        : isHoveredRow || isHoveredColumn
        ? "var(--color-doble-hover)"
        : "white",
      color: isHeatmapActive
        ? "black"
        : (typeof value === "number" && value < 4) ||
          (typeof value === "string" && parseFloat(value.replace(",", ".")) < 4)
        ? "red"
        : "var(--color-texto-claro)",
      textAlign: "center",
      padding: "8px",
      fontSize: "0.9em",
      height: "45px",
    }),
    tdHeatmapSinBordeDerecho: (
      isHeatmapActive,
      value,
      isResumen,
      isHoveredRow,
      isHoveredColumn
    ) => ({
      borderBottom: "1px solid lightgrey",
      borderTop: "none",
      borderLeft: isResumen
        ? isHeatmapActive
          ? "3px solid white"
          : "1px solid lightgrey"
        : "none",
      borderRight: "none",
      backgroundColor: isHeatmapActive
        ? obtenerColorMapaCalor(parseFloat(String(value).replace(",", ".")), true)
        : isHoveredRow || isHoveredColumn
        ? "var(--color-doble-hover)"
        : "white",
      color: isHeatmapActive
        ? "black"
        : (typeof value === "number" && value < 4) ||
          (typeof value === "string" && parseFloat(value.replace(",", ".")) < 4)
        ? "red"
        : "var(--color-texto-claro)",
      textAlign: "center",
      padding: "8px",
      fontSize: "0.9em",
      height: "45px",
    }),
    resumenTd: (
      isHeatmapActive,
      value,
      withRightBorder = false,
      withLeftBorder = false
    ) => ({
      borderBottom: "1px solid lightgrey",
      backgroundColor: "white",
      textAlign: "center",
      padding: "8px",
      fontSize: "0.9em",
      height: "45px",
      borderRight: withRightBorder ? "1px solid lightgrey" : undefined,
      borderLeft: withLeftBorder ? "1px solid lightgrey" : undefined,
      color:
        (typeof value === "number" && value < 4) ||
        (typeof value === "string" && parseFloat(value.replace(",", ".")) < 4)
          ? "red"
          : "",
    }),
    resumenAnualTd: (isHeatmapActive) => ({
      borderBottom: "1px solid lightgrey",
      borderLeft: isHeatmapActive ? "3px solid white" : "1px solid lightgrey",
      backgroundColor: "white",
      textAlign: "center",
      padding: "8px",
      fontSize: "0.9em",
    }),
    boldText: {
      fontWeight: "bold",
      color: "var(--color-texto-oscuro)",
      fontSize: "1.1em",
    },
    trSticky: {
      position: "sticky",
      bottom: 0,
      backgroundColor: "white",
      borderBottom: "none",
      height: "45px",
    },
    inputInactive: {
      width: "50px",
      textAlign: "center",
      border: "1px solid lightgrey",
      backgroundColor: "white",
      color: "var(--color-texto-claro)",
      cursor: blockInputs ? "default" : "auto",
    },
    inputActive: {
      width: "50px",
      textAlign: "center",
      border: "1px solid #007bff",
      backgroundColor: "white",
      color: "black",
      cursor: blockInputs ? "default" : "auto",
    },
    inputEmpty: {
      width: "50px",
      textAlign: "center",
      border: "1px solid lightgrey",
      backgroundColor: "white",
      color: "transparent",
      cursor: blockInputs ? "default" : "auto",
    },
    inputVisible: {
      width: "50px",
      textAlign: "center",
      border: "1px solid lightgrey",
      backgroundColor: "white",
      color: "black",
      cursor: blockInputs ? "default" : "auto",
    },
    avatar: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      marginRight: "10px",
    },
    textoTabla: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "400",
      color: "rgb(136, 136, 136)",
      fontSize: "14px",
      lineHeight: "21px",
    },
    lineaRoja: {
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      borderBottom: '2px solid red',
      zIndex: 9,
    },
    celdaRetirado: {
      cursor: 'not-allowed',
      backgroundColor: '#f5f5f5',
      position: 'relative', // Necesario para que la línea roja se alinee correctamente
    },
  });
  
  export { estilostabla, getThStickyStyles };
  