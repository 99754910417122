import React from "react";
import { Link } from "react-router-dom";

const Error403 = () => {
    return (
        <div className="fix-wrapper">
            <div className="container">
                {" "}
                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <div className="form-input-content text-center error-page">
                            <h1 className="error-text fw-bold">403</h1>
                            <h4>
                                <i className="fa fa-times-circle text-danger"/>{" "}
                                Acceso Prohibido!
                            </h4>
                            <p>No tienes permiso para ver este recurso.</p>
                            <div>
                                <Link className="btn btn-primary" to="/dashboard">
                                    Volver
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Error403;
