import React from 'react'
import Img from '../../../assets/images/construccion.png'

function InConstruction({children}) {
  return (
    <div
      style={{ height: "70dvh" }}
      className="w-100 d-flex justify-content-center align-items-center flex-column"
    >
      Sitio en construcción
      <img src={Img} alt="" />
      <br />
      {children}
    </div>
  )
}

export default InConstruction