import React, { useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import useGetListadoAsignaturas from '../../../hooks/useGetListadoAsignaturas';
import useGetListadoCursos from '../../../hooks/useGetListadoCursos';
import useGetNotasCursoAsignatura from '../../../hooks/useGetNotasCursoAsignatura';
import useGetPeriodosAcademicos from '../../../hooks/useGetPeriodosAcademicos';
import { getPermisosDocente } from '../../../services/PostsService';
import NavCalificaciones from '../../components/calificaciones/NavCalificaciones';
import TablaCalificaciones from '../../components/calificaciones/TablaCalificaciones';
import ModalAutenticacion from '../../components/confirmarFirma/ModalAutenticacion';
import CustomSpinner from '../../components/spinner/Spinner';

const CalificacionesCurso = () => {
  const [isHeatmapActive, setIsHeatmapActive] = useState(false);
  const [mostrarAcumulativas, setMostrarAcumulativas] = useState(false);
  const [confSumAcumulativa, setConfSumAcumulativa] = useState(false);
  const [filtroBusqueda, setFiltroBusqueda] = useState('');
  const [mostrarModalAutenticacion, setMostrarModalAutenticacion] = useState(false);
  const [mostrarDosDecimalesNotas, setMostrarDosDecimalesNotas] = useState(false);
  const [mostrarDosDecimalesPromedios, setMostrarDosDecimalesPromedios] = useState(false);
  const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
  const [asignaturaSeleccionada, setAsignaturaSeleccionada] = useState(null);
  const [periodoSeleccionado, setPeriodoSeleccionado] = useState(null);
  const [blockInputs, setBlockInputs] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);
  const [tablaRenderizada, setTablaRenderizada] = useState(false);
  const [unidadSeleccionada, setUnidadSeleccionada] = useState(null); 

  const { cursos, cargando: cargandoCursos, error: errorCursos } = useGetListadoCursos();
  const { asignaturas, cargando: cargandoAsignaturas, error: errorAsignaturas } = useGetListadoAsignaturas(cursoSeleccionado?.cursoId);
  const { periodos, cargando: cargandoPeriodos, error: errorPeriodos } = useGetPeriodosAcademicos();

  const cursoId = cursoSeleccionado ? cursoSeleccionado.cursoId : null;
  const asignaturaId = asignaturaSeleccionada ? asignaturaSeleccionada.id : null;
  const periodoEscolarId = periodoSeleccionado ? periodoSeleccionado.id : null;

  const { data, cargando: cargandoNotas, error: errorNotas } = useGetNotasCursoAsignatura(
    cursoId, 
    asignaturaId, 
    periodoEscolarId, 
    unidadSeleccionada 
  );
  
  const handleCursoSeleccionado = (curso) => {
    setCursoSeleccionado(curso);
    setAsignaturaSeleccionada(null);
    setUnidadSeleccionada(null);
    setTablaRenderizada(false);
  };
  
  const handleAsignaturaSeleccionada = (asignatura) => {
    setAsignaturaSeleccionada(asignatura);
    setUnidadSeleccionada(null); 
    setTablaRenderizada(false);
  };

  const handlePeriodoSeleccionado = (periodo) => {
    setPeriodoSeleccionado(periodo);
    setUnidadSeleccionada(null); 
    setTablaRenderizada(false);
  };

  const convertirAbreviatura = (nombre) => {
    switch (nombre) {
      case "Primer Semestre":
        return "1er Semestre";
      case "Segundo Semestre":
        return "2do Semestre";
      default:
        return nombre;
    }
  };

  const abrirModalAutenticacion = () => {
    setMostrarModalAutenticacion(true);
  };

  const manejarCancelarAutenticacion = () => {
    setMostrarModalAutenticacion(false);
  };

  const manejarConfirmarAutenticacion = () => {
    setBlockInputs(false);
    setMostrarModalAutenticacion(false);
  };

  useEffect(() => {
    if (!cargandoCursos && !cargandoPeriodos) {
      const configGuardada = localStorage.getItem('configTablaNotas');
      if (configGuardada) {
        const { cursoId, cursoNombre, asignaturaId, asignaturaNombre, periodoEscolarId, periodoEscolarNombre, unidadId, unidadNombre } = JSON.parse(configGuardada);
        
        setCursoSeleccionado({ cursoId, cursoNombre });
        setAsignaturaSeleccionada({ id: asignaturaId, nombre: asignaturaNombre });
        setPeriodoSeleccionado({ id: periodoEscolarId, nombre: periodoEscolarNombre });
        
        if (unidadId) {
          setUnidadSeleccionada(unidadId); // Guardar solo el número
        }
  
        setTimeout(() => localStorage.removeItem('configTablaNotas'), 5000);
      } else {
        const currentRolData = JSON.parse(localStorage.getItem('currentRolData'));
        const currentPeriodo = JSON.parse(localStorage.getItem('currentPeriodo'));
        if (currentRolData && currentPeriodo) {
          setCursoSeleccionado({
            cursoId: currentRolData.defaultCursoId,
            cursoNombre: currentRolData.defaultCursoNombre,
          });
          setAsignaturaSeleccionada({
            id: currentRolData.defaultAsignaturaId,
            nombre: currentRolData.defaultAsignaturaNombre,
          });
          setPeriodoSeleccionado({
            id: currentPeriodo.periodoAcademicoId,
            nombre: currentPeriodo.periodoAcademicoNombre,
          });
        }
      }
    }
  }, [cargandoCursos, cargandoPeriodos]);
  

  useEffect(() => {
    const configGuardada = localStorage.getItem('configTablaNotas');
    if (!configGuardada && asignaturas && asignaturas.length > 0) {
      const currentRolData = JSON.parse(localStorage.getItem('currentRolData'));
      if (currentRolData && currentRolData.defaultAsignaturaId) {
        const asignaturaGuardada = asignaturas.find(a => a.id === currentRolData.defaultAsignaturaId);
        if (asignaturaGuardada) {
          setAsignaturaSeleccionada(asignaturaGuardada);
        } else {
          setAsignaturaSeleccionada(asignaturas[0]);
        }
      } else {
        setAsignaturaSeleccionada(asignaturas[0]);
      }
    }
  }, [asignaturas]);

  useEffect(() => {
    if (cursoId && asignaturaId && periodoEscolarId) {
      setTablaRenderizada(false);
    }
  }, [cursoId, asignaturaId, periodoEscolarId]);

  const unidadesFiltradas = data?.unidades?.map(unidad => ({
    unidadId: unidad.unidadId,
    unidadNombre: unidad.unidadNombre
  })) || [];

  useEffect(() => {
    if (data && data.confNotasSum) {
      const hasAcumulativas = data.confNotasSum.some(item => item.confSumAcumulativa === true);
      setConfSumAcumulativa(hasAcumulativas);
      setTablaRenderizada(true);
    }
  }, [data]);

  const fetchNotasPorUnidad = (unidadId) => {
    setUnidadSeleccionada(unidadId); 
    setTablaRenderizada(false);
  };

  useEffect(() => {
    const currentSchool = JSON.parse(localStorage.getItem("currentSchool"));
    if (currentSchool && cursoSeleccionado && asignaturaSeleccionada) {
      const periodoId = currentSchool.periodoActual.id;
      const colegioId = currentSchool.id;

      getPermisosDocente(
        cursoSeleccionado.cursoId,
        asignaturaSeleccionada.id,
        periodoId,
        colegioId
      )
        .then((permiso) => {
          setHasPermission(permiso);
        })
        .catch((error) => {
          console.error("Error al obtener permisos del docente:", error);
        });
    }
  }, [asignaturaSeleccionada, cursoSeleccionado]);

  const mostrarNotas = cursoId && asignaturaId && periodoEscolarId;

  useEffect(() => {
    if (data) {
      console.log("Datos recibidos del hook useGetNotasCursoAsignatura:", data);
      console.log("Escala:", data.escala);
      console.log("Conceptos permitidos desde padre:", data.conceptosEscala);
    }
  }, [data]);

  const renderContenido = () => {
    if (cargandoCursos || cargandoAsignaturas || cargandoPeriodos || (mostrarNotas && cargandoNotas)) {
      return (
        <div className="text-center d-flex justify-content-center align-items-center h-100">
          <CustomSpinner />
        </div>
      );
    }

    if (errorCursos || errorAsignaturas || errorPeriodos) {
      return (
        <Alert variant="danger" className="text-center mt-3">
          Error al cargar los datos iniciales: {errorCursos || errorAsignaturas || errorPeriodos}
        </Alert>
      );
    }

    if (!mostrarNotas) {
      return null;
    }

    if (errorNotas) {
      return (
        <Alert variant="danger" className="text-center mt-3">
          Error al cargar las notas: {errorNotas}
        </Alert>
      );
    }

    

    return (
      <TablaCalificaciones
        data={data}
        tablaId="tablaCalificaciones"
        isHeatmapActive={isHeatmapActive}
        mostrarAcumulativas={mostrarAcumulativas}
        cursoId={cursoId}
        asignaturaId={asignaturaId}
        periodoEscolarId={periodoEscolarId}
        filtroBusqueda={filtroBusqueda}
        mostrarDosDecimalesNotas={mostrarDosDecimalesNotas}
        mostrarDosDecimalesPromedios={mostrarDosDecimalesPromedios}
        blockInputs={blockInputs}
        setBlockInputs={setBlockInputs}
        abrirModalAutenticacion={abrirModalAutenticacion}
        hasPermission={hasPermission}
        mostrarBotonBloquear={tablaRenderizada}
        conceptosPermitidos={data.conceptosEscala ? data.conceptosEscala : []}
        promedioGeneralCurso={data.cursoPromedio}
      />
    );
  };

  useEffect(() => {
    if (data && data.confGlobal) {
      const decimales = data.confGlobal.cantidadDecimales;
      setMostrarDosDecimalesNotas(decimales === 2);
      setMostrarDosDecimalesPromedios(decimales === 2);
    }
  }, [data]);

  return (
    <Container fluid className="bg-white p-0" style={{ height: '90vh', overflow: 'hidden' }}>
      <NavCalificaciones
        cursosFiltrados={cursos}
        asignaturasFiltradas={asignaturas.filter(asignatura => {
          // console.log(asignatura);
          return !asignatura.nombre.includes('JEC')
        })}
        periodosFiltrados={periodos.map(periodo => ({
          id: periodo.periodoAcademicoId,
          nombre: convertirAbreviatura(periodo.periodoAcademicoNombre)
        }))}
        unidadesFiltradas={unidadesFiltradas} 
        setCursoSeleccionado={handleCursoSeleccionado}
        setAsignaturaSeleccionada={handleAsignaturaSeleccionada}
        setPeriodoSeleccionado={handlePeriodoSeleccionado}
        cursoSeleccionado={cursoSeleccionado}
        asignaturaSeleccionada={asignaturaSeleccionada}
        periodoSeleccionado={periodoSeleccionado}
        tablaId="tablaCalificaciones"
        isHeatmapActive={isHeatmapActive}
        setIsHeatmapActive={setIsHeatmapActive}
        mostrarAcumulativas={mostrarAcumulativas}
        setMostrarAcumulativas={setMostrarAcumulativas}
        confSumAcumulativa={confSumAcumulativa}
        filtroBusqueda={filtroBusqueda}
        setFiltroBusqueda={setFiltroBusqueda}
        confNotasSum={data ? data.confNotasSum : []}
        confGlobal={data ? data.confGlobal : {}}
        blockInputs={blockInputs}
        setBlockInputs={setBlockInputs}
        mostrarModalAutenticacion={mostrarModalAutenticacion}
        setMostrarModalAutenticacion={setMostrarModalAutenticacion}
        hasPermission={hasPermission}
        mostrarBotonBloquear={tablaRenderizada}
        mostrarTabs={unidadesFiltradas.length > 0} 
        tablaConceptos={data?.tablaConceptos}
        fetchNotasPorUnidad={fetchNotasPorUnidad}  
      />
      <div style={{ flex: '1 1 auto', overflowY: 'auto', height: '100%' }}>
        {renderContenido()}
      </div>

      <ModalAutenticacion
        show={mostrarModalAutenticacion}
        onHide={manejarCancelarAutenticacion}
        onConfirm={manejarConfirmarAutenticacion}
      />
    </Container>
  );
};

export default CalificacionesCurso;
