import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { normalizeString } from "../../../util/util";
import CustomTooltip from "../tooltip/Tooltip";

const styles = {
  boton: {
    border: "1px solid lightgray",
    color: "gray",
    backgroundColor: "white",
    width: "100%",
    margin: "0",
  },
  menuDesplegable: {
    maxHeight: "250px",
    overflowY: "auto",
    width: "fit-content",
    backgroundColor: "white",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "0",
    zIndex: 30,
  },
  stickyContainer: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    padding: "5px 0",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 2050,
  },
  enlaceDesmarcarTodo: {
    fontSize: "0.9em",
    color: "#007bff",
    cursor: "pointer",
    textDecoration: "underline",
    margin: "5px 0",
  },
  itemSeleccionado: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  controlFormulario: {
    margin: "5px auto",
    padding: "5px",
    width: "90%",
    display: "block",
    boxSizing: "border-box",
    border: "1px solid #ced4da",
    borderRadius: "4px",
  },
  cleanBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  truncateText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

function SelectMultiple({
  label,
  placeholder,
  elements = [],
  selectedElements = [],
  onSelect,
  labelKey = "name",
  idKey = "id",
  dropDirection = "down",
  required = false,
  controlId,
  disabled,
  truncateText = false,
  style = {},
  hideLabel = false,
  tooltipMessage = "",
}) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [filtro, setFiltro] = useState("");

  // Maneja la selección de elementos
  const handleSelect = (elemento) => {
    const updatedSelection = selectedElements.some(
      (el) => el[idKey] === elemento[idKey]
    )
      ? selectedElements.filter((el) => el[idKey] !== elemento[idKey])
      : [...selectedElements, elemento];

    onSelect(updatedSelection);
  };

  // Maneja el filtro de búsqueda
  const handleSearch = (value) => {
    setFiltro(value);
  };

  // Desmarca todos los elementos seleccionados
  const handleDeselectAll = () => {
    onSelect([]);
  };

  // Estilo del menú desplegable basado en la dirección
  const getMenuStyle = () => {
    let style = { ...styles.menuDesplegable };

    if (dropDirection === "up") {
      style.bottom = "100%";
      style.top = "auto";
    } else if (dropDirection === "left") {
      style.right = "100%";
      style.left = "auto";
    } else if (dropDirection === "right") {
      style.left = "100%";
      style.right = "auto";
    }

    return style;
  };

  // Filtra los elementos basándose en el filtro de búsqueda
  const filteredElements = elements.filter((elemento) =>
    normalizeString(elemento[labelKey]).includes(normalizeString(filtro))
  );

  return (
    <FormGroup controlId={controlId}>
      {!hideLabel && (
        <CustomTooltip
          tooltipMsg={
            tooltipMessage ? (
              <span>{tooltipMessage}</span>
            ) : (
              <span>Dato Requerido</span>
            )
          }
        >
          <FormLabel>
            {placeholder}{" "}
            {required && <span style={{ color: "var(--color-rojo)" }}>*</span>}
          </FormLabel>
        </CustomTooltip>
      )}
      <Dropdown
        className="dropdown-elemento m-0 p-0"
        show={menuVisible}
        onToggle={() => setMenuVisible(!menuVisible)}
        drop={dropDirection}
      >
       <CustomTooltip
          tooltipMsg={
            selectedElements?.length ? (
              <ul style={{ margin: 0, paddingLeft: "1em" }}>
                {selectedElements.map((el, index) => (
                  <li key={`${el[idKey]}-${index}`}>{el[labelKey]}</li>
                ))}
              </ul>
            ) : (
              "Seleccionar..."
            )
          }
          >
            
         
          <Dropdown.Toggle
            style={{
              ...styles.boton,
              ...style,
              ...(truncateText && styles.truncateText),
            }}
            onClick={() => setMenuVisible(!menuVisible)}
            disabled={disabled}
          >
            {selectedElements?.length
              ? `${placeholder} (${selectedElements.length})`
              : placeholder}
          </Dropdown.Toggle>
          </CustomTooltip>
        <Dropdown.Menu style={getMenuStyle()} align="start" show={menuVisible}>
          <div style={styles.stickyContainer}>
            <FormControl
              autoFocus
              placeholder="Buscar..."
              value={filtro}
              onChange={(e) => handleSearch(e.target.value)}
              style={styles.controlFormulario}
              autoComplete="off"
            />
            <div style={styles.cleanBox}>
              {selectedElements?.length > 0 && (
                <span
                  style={styles.enlaceDesmarcarTodo}
                  onClick={handleDeselectAll}
                >
                  Limpiar
                </span>
              )}
            </div>
          </div>
          {filteredElements.length > 0 ? (
            filteredElements.map((elemento, index) => (
              <div
                key={index}
                className="w-100"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Dropdown.Item
                  active={selectedElements?.some(
                    (el) => el[idKey] === elemento[idKey]
                  )}
                  style={
                    selectedElements?.some((el) => el[idKey] === elemento[idKey])
                      ? { color: "var(--color-acento)" }
                      : {}
                  }
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelect(elemento);
                    }}
                    style={{
                      ...styles.itemSeleccionado,
                      ...(truncateText && styles.truncateText),
                    }}
                  >
                    {elemento[labelKey]}
                    {selectedElements?.some(
                      (el) => el[idKey] === elemento[idKey]
                    ) && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ color: "currentColor", marginLeft: "5px" }}
                      />
                    )}
                  </div>
                </Dropdown.Item>
              </div>
            ))
          ) : (
            <div style={{ padding: "10px", textAlign: "center" }}>
              No se encontraron resultados.
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </FormGroup>
  );
}

export default SelectMultiple;
