import './SchoolTable.scss'
import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import RoleButton from './RoleButton';
import SchoolCard from './SchoolCard';
import { enterSchool } from '../../../services/PostsService';
// Definir datos de ejemplo para las escuelas
import { useNavigate } from 'react-router-dom';

const SchoolTable = () => {
    const navigate = useNavigate();

    const roles = JSON.parse(localStorage.getItem('userDetails')).user.roles
    // Obtener los datos de las escuelas (simulados por ahora)
    const schools = JSON.parse(localStorage.getItem('activeSchools'));
    // Verificar si schools es válido antes de mapearlo
    if (!schools || !Array.isArray(schools) || schools.length === 0) {
        return <p>No hay escuelas disponibles.</p>;
    }

    const hasRole = (roles, rolId) => {
        return roles.filter(r => r.rol.id === rolId).length
    }

    const selectSchool = (school) => {
        const permitidos = roles.filter(r => (r.rol.id === 1 || r.rol.id === 5) && r.colegio.id === school.id)
        localStorage.setItem('currentSchool', JSON.stringify(school))
        localStorage.setItem('currentRol', JSON.stringify(permitidos[0]))
        let page = "/"
        if(hasRole(permitidos, 1)) {
            page = '/dashboard'
        }else if(hasRole(permitidos, 5)) {
            page = '/colegio-admin'
        }
        enterSchool();

        navigate(page)
    } 

    return (
        <Container className="mt-0 " id="school-table">
            {/* Row para centrar el contenido */}
            <Row className="justify-content-center">
                {/* Columna de ancho completo en extra-large (xl) */}
                <Col xl={12}>
                    {/* Tabla responsive y con bordes */}
                    <Table bordered className="w-100 table-white">
                        {/* Encabezado de la tabla */}
                        <thead className="text-center bg-primary text-white">
                        <tr>
                            <th className="text-white fs-4">
                                <Row>
                                    <Col xs={8}>COLEGIO</Col>
                                    <Col xs={4}>ROLES</Col>
                                </Row>
                            </th>
                        </tr>
                        </thead>
                        {/* Cuerpo de la tabla */}
                        <tbody>
                        {/* Iterar sobre cada escuela */}
                        {schools.map((school) => (
                            <tr key={school.id}>
                                {/* Columna de Colegio */}
                                <td className="align-middle">
                                    <Row className="bg-white school-row" onClick={() => selectSchool(school)}>
                                        <Col xs={8}>
                                            {/* Renderizar la tarjeta del colegio */}
                                            <SchoolCard school={school} />
                                        </Col>
                                        <Col xs={4}>
                                            {/* Mapear cada rol y mostrar un botón */}
                                                {roles?.map((role, index) => (
                                                    role.colegio.id === school.id &&
                                                        <div style={{fontSize:'larger', textAlign:'center'}}>{role.rol.rolNombre}</div>
                                                ))}
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default SchoolTable;
