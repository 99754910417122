import { useEffect, useState } from "react";
import { getAsistenciaClaseMensual } from "../services/PostsService";

const parseEstado = (estadoId) => {
  switch (estadoId) {
    case 1:
      return "presente";
    case 2:
      return "ausente";
    case 3:
      return "atrasado";
    case 4:
      return "retirado";
    case 5:
      return "fueraDeAula";
    case 0:
      return "pendiente";
    case -1:
      return "futuro";
    default:
      return "";
  }
};

const useGetAsistenciaClaseMensual = (curso_id, asignatura_id, mes) => {
  const [datosAsistencia, setDatosAsistencia] = useState(null);
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAsistenciaMensual = async () => {
      setCargando(true);
      setError(null);

      try {
        const params = { 
          curso_id: parseInt(curso_id, 10), 
          asignatura_id: parseInt(asignatura_id, 10), 
          mes: parseInt(mes, 10) 
        };
        const response = await getAsistenciaClaseMensual(params);
        const data = response.data;

        const parsedClases = data.clases.map((clase) => ({
          claseId: clase.claseId,
          bloqueFecha: clase.bloqueFecha,
          bloqueOrden: clase.bloqueOrden,
          bloqueNum: clase.bloqueNum,
          bloqueIni: clase.bloqueIni,
          bloqueFin: clase.bloqueFin,
          bloqueTipoId: clase.bloqueTipoId,
          bloqueTipoNombre: clase.bloqueTipoNombre,
          estadoClaseId: clase.estadoClaseId,
          estadoClaseNombre: clase.estadoClaseNombre,
        }));

        const parsedEstudiantes = data.estudiantes.map((estudiante) => ({
          estudianteId: estudiante.estudiante.estudianteId,
          run: estudiante.estudiante.run,
          runDv: estudiante.estudiante.runDv,
          ipe: estudiante.estudiante.ipe,
          ipeDv: estudiante.estudiante.ipeDv,
          numLista: estudiante.estudiante.numLista,
          numMatricula: estudiante.estudiante.numMatricula,
          nombre: `${estudiante.estudiante.nombrePrimario} ${estudiante.estudiante.apellidoPrimario} ${estudiante.estudiante.apellidoSecundario}`,
          retirado: estudiante.estudiante.retirado,
          fechaRetiro: estudiante.estudiante.fechaRetiro,
          fechaMatricula: estudiante.estudiante.fechaMatricula,
          clases: estudiante.clases.map(clase => ({
            asistenciaId: clase.asistenciaId,
            asistenciaEstadoClaseId: clase.asistenciaEstadoClaseId,
            asistenciaEstadoClase: parseEstado(clase.asistenciaEstadoClaseId),
            claseId: clase.claseId,
            claseFecha: clase.claseFecha,
            claseNumero: clase.claseNumero
          })),
          porcAsistenciaMes: estudiante.porcAsistenciaMes
        }));
        console.log("esto es lo que traigo:", parsedEstudiantes)

        setDatosAsistencia({ clases: parsedClases, estudiantes: parsedEstudiantes });
      } catch (error) {
        setError(error.message);
      } finally {
        setCargando(false);
      }
    };

    if (curso_id && asignatura_id && mes) {
      fetchAsistenciaMensual();
    }
  }, [curso_id, asignatura_id, mes]);

  return { datosAsistencia, cargando, error };
};

export default useGetAsistenciaClaseMensual;
