const api = {};

if (process.env.REACT_APP_ENV === 'prod') {
    api.url = 'https://api.lcdcambar.cl';
} else if (process.env.REACT_APP_ENV === 'qa') {
    api.url = 'https://apiqa.lcdcambar.cl';
} else {
    api.url = process.env.REACT_APP_API_URL
}

export default api;
