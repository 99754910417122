import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux"; // Add this line
import { useNavigate } from "react-router-dom";
import "../../../../assets/css/estilosCambar.css";
import apiConfig from "../../../../config/apiConfig";
import { ReportService } from "../../../../services";
import {
  getListadoEnsenanzas,
  getListadoEstudiante,
  getPeriodosByColegio,
} from "../../../../services/MatriculaService";

import {
  faCheck,
  faDownload,
  faEye,
  faFilter,
  faMars,
  faPenToSquare,
  faPlusCircle,
  faTrash,
  faUpload,
  faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import useCheckRoles from "../../../../hooks/useCheckRoles";
import useGetListadoCursos from "../../../../hooks/useGetListadoCursos";
import {
  deleteEstudiante,
  getEstadosEstudiantes,
  getFichaEstudiante,
} from "../../../../services/PostsService";
import {
  contarPorCampo,
  formatearFecha,
  formatearIPEIPA,
  formatearRUN,
  formatearRutIpe,
} from "../../../../util/util";
import CheckboxInput from "../../../components/inputs/CheckboxInput";
import SelectSingle from "../../../components/inputs/SelectSingle";
import InscribirEstudianteSigeModal from "../../../components/matricula/InscribirEstudianteSigeModal";
import MatriculaSigeModal from "../../../components/matricula/MatriculaSigeModal";
import DynamicTable from "../../../components/tables/DynamicTable/Table";
import Title from "../../../components/titles/Title";
import {
  default as CustomTooltip,
  default as Tooltip,
} from "../../../components/tooltip/Tooltip";
import SelectMultiple from "../../../components/inputs/SelectMultiple";
import Avatar from "../../../components/avatar/Avatar";
import CustomSpinner from "../../../components/spinner/Spinner";

const descargarPlantillaMatricula = () => {
  window.open(apiConfig.url + "/static/Matricula_SIGE.xlsx");
};

const MatriculaVista = () => {
  const {isDirectivo} = useCheckRoles();
  // const roles = useSelector((state) => state.auth.roles);
  // const isDirectivo = roles.find((item) => item.rol.id === 5) ? true : false;

  const [showFilters, setShowfilters] = useState(false);
  const isMobile = window.innerWidth < 768;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [periodos, setPeriodos] = useState([]);
  const [periodo, setPeriodo] = useState(null);

  // const [curso, setCurso] = useState(null);
  const [selectedCursos, setSelectedCursos] = useState([]);

  // const [estado, setEstado] = useState(null);
  const [selectedEstados, setSelectedEstados] = useState([]);

  const [ensenanza, setEnsenanza] = useState(null);
  const [selectedEnsenanzas, setSelectedEnsenanzas] = useState([]);

  const [mostrarModal, setMostrarModal] = useState(false);
  const { cursos } = useGetListadoCursos();
  const [listaEstudiantes, setListaEstudiantes] = useState([]);
  const [listadoEnsenanzas, setListadoEnsenanzas] = useState([]);
  const [listaEstadosEstudiantes, setListaEstadosEstudiantes] = useState([]);
  const [cursosFiltrados, setCursosFiltrados] = useState([]);
  const [filtroPIE, setFiltroPIE] = useState(false);
  const [filtroPrioritario, setFiltroPrioritario] = useState(false);
  const [filtroPreferente, setFiltroPreferente] = useState(false);
  const [filtroNEE, setFiltroNEE] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const headers = [
    {
      name: "Estado",
      width: "110px",
      textAlign: "center",
      sortable: true,
      sortType: "string",
    },
    {
      name: "Matrícula",
      width: "110px",
      textAlign: "center",
      sortable: true,
      sortType: "number",
      sortByDefault: true,
    },
    {
      name: "Fecha Mat.",
      width: "125px",
      textAlign: "center",
      sortable: true,
      sortType: "date",
    },
    {
      name: "Curso",
      width: "90px",
      textAlign: "center",
      sortable: true,
      sortType: "string",
    },
    {
      name: "Lista",
      width: "90px",
      textAlign: "center",
      sortable: true,
      sortType: "number",
    },
    {
      name: "RUN",
      width: "120px",
      textAlign: "center",
      sortable: true,
      sortType: "number",
    },
    {
      name: "Nombre Reg.",
      width: "195px",
      textAlign: "center",
      sortable: true,
      sortType: "object",
    },
    {
      name: "Apellidos",
      width: "230px",
      textAlign: "center",
      sortable: true,
      sortType: "string",
    },
    {
      name: "Género Reg.",
      width: "140px",
      textAlign: "center",
      sortable: true,
      sortType: "gender",
    },
    { name: "PIE", width: "90px", textAlign: "center", sortable: false },
    {
      name: "Prioritario",
      width: "140px",
      textAlign: "center",
      sortable: false,
    },
    {
      name: "Preferente",
      width: "130px",
      textAlign: "center",
      sortable: false,
    },
    { name: "Acciones", width: "140px", textAlign: "center", sortable: false },
  ];

  const data = listaEstudiantes.map((e, index) => {
    // console.log("Before mapping:", e);

    // Crear un nuevo objeto para asegurarse de que no estamos mutando el original
    const mappedData = {
      id: index,
      value: [
        e.numMatricula && !e.retirado
          ? "Matriculado"
          : e.numMatricula && e.retirado
          ? "Retirado"
          : "Inscrito",
        e.numMatricula, // Posición 1: Matrícula
        e.fechaMatricula, // Posición 2: Fecha Matrícula
        e.cursoNombre,
        e.numLista, // Posición 3: Curso
        formatearRutIpe(e.run + e.runDv) === ""
          ? formatearIPEIPA(e.runProvisorio + e.runProvisorioDv)
          : formatearRutIpe(e.run + e.runDv), // Posición 4: RUN
        <div className="d-flex gap-3 justify-content-start align-items-center ml-4">
          <Avatar
            width={"30px"}
            height={"30px"}
            run={e.run ? e.run : e.runProvisorio}
            allowPreview
          />{" "}
          {e.nombrePrimario}
        </div>,
        ` ${e.apellidoPrimario} ${
          e.apellidoSecundario ? e.apellidoSecundario : ""
        }`, // Posición 6: Apellidos
        e.genero === 1 ? ( // Posición 7: Género Registral
          <FontAwesomeIcon
            icon={faMars}
            size="2x"
            style={{ color: "var(--color-masculino)" }}
          />
        ) : e.genero === 2 ? (
          <FontAwesomeIcon
            icon={faVenus}
            size="2x"
            style={{ color: "var(--color-femenino)" }}
          />
        ) : (
          ""
        ),
        e.esPie ? (
          <FontAwesomeIcon
            icon={faCheck}
            size="2x"
            style={{ color: "var(--color-acento)" }}
          />
        ) : (
          ""
        ), // Posición 8: PIE
        e.esPrioritario ? ( // Posición 9: Prioritario
          <FontAwesomeIcon
            icon={faCheck}
            size="2x"
            style={{ color: "var(--color-acento)" }}
          />
        ) : (
          ""
        ),
        e.esPreferente ? (
          <FontAwesomeIcon
            icon={faCheck}
            size="2x"
            style={{ color: "var(--color-acento)" }}
          />
        ) : (
          ""
        ), // Posición 10: Preferente
        // !e.nee ? (
        //   <FontAwesomeIcon
        //     icon={faCheck}
        //     size="2x"
        //     style={{ color: "var(--color-acento)" }}
        //   />
        // ) : (
        //   ""
        // ), // Posición 11: NEE
        <div // Posición 12: Acciones
          style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}
        >
          <CustomTooltip tooltipMsg="Editar ficha">
            <Button
              variant="color-amarillo-outline"
              onClick={() => handleGetFicha(e)}
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </Button>
          </CustomTooltip>
          <CustomTooltip
            tooltipMsg={
              !isDirectivo ? "No tienes permisos" : "Eliminar estudiante"
            }
          >
            <div
              className="w-100"
              style={{ position: "relative", display: "inline-block" }}
            >
              <Button variant="color-acento-outline" disabled={!isDirectivo}>
                <i className="fa-solid fa-trash"></i>
              </Button>
            </div>
          </CustomTooltip>
        </div>,
      ],
    };

    // console.log("After mapping:", mappedData);
    return mappedData;
  });
  const [filteredData, setFilteredData] = useState(data);

  const resumeTable = [
    {
      label: "Estudiantes",
      value: filteredData.length,
    },
    {
      label: "Hombres",
      value: contarPorCampo(filteredData, 8, "Masculino"),
    },
    {
      label: "Mujeres",
      value: contarPorCampo(filteredData, 8, "Femenino"),
    },
    {
      label: "PIE",
      value: contarPorCampo(filteredData, 9, "PIE"),
    },
    {
      label: "Prioritarios",
      value: contarPorCampo(filteredData, 10, "Prioritario"),
    },
    {
      label: "Preferentes",
      value: contarPorCampo(filteredData, 11, "Preferente"),
    },
    // {
    //   label: "NEE",
    //   value: contarPorCampo(filteredData, 12, "NEE"),
    // },
  ];

  const manejarMostrarModal = () => setMostrarModal(true);
  const manejarCerrarModal = () => setMostrarModal(false);

  const handleMatricularClick = () => {
    navigate("/ficha-estudiante");
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const downloadMatricula = () => {
    Swal.fire({
      title: "Procesando...",
      text: "Por favor espera mientras se completa la descarga",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    ReportService.read(
      "matricula/exportListaMatricula",
      null,
      null,
      "Asigna_Matrícula.xlsx"
    )
      .then((res) => {
        Swal.fire({
          icon: "info",
          title: "Descarga completa",
          text: "La descarga se ha realizado correctamente",
          customClass: {
            confirmButton: "custom-swal-button",
          },
          toast: true,
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Ocurrió un error: ${error.message}`,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
      });
  };

  const handleGetFicha = useCallback(
    (element) => {
      getFichaEstudiante(element.estudianteId)
        .then((r) => {
          navigate("/ficha-estudiante", { state: { ficha: r.data } });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [navigate]
  );

  const handleDeleteEstudiante = (e) => {
    Swal.fire({
      title: "¿Estás seguro?",
      html: `Estás a punto de eliminar a <br /><strong>${formatearRUN(
        e.run + "-" + e.runDv
      )}<br /> ${e.nombrePrimario} ${e.apellidoPrimario} ${
        e.apellidoSecundario
      }</strong>.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--color-eliminar)",
      cancelButtonColor: "var(--color-acento)",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEstudiante(e.estudianteId)
          .then(() => {
            Swal.fire({
              title: "Estudiante eliminado",
              icon: "success",
              // confirmButtonColor: "var(--color-acento)",
              showConfirmButton: false,
              toast: true,
              timer: 1500,
              timerProgressBar: true,
            }).then(() => {
              getListadoEstudiante().then((r) => {
                setListaEstudiantes(r.data.listaEstudiantes);
              });
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: `Ocurrió un error: ${error.message}`,
              icon: "error",
              confirmButtonColor: "var(--color-acento)",
            });
          });
      }
    });
  };

  // const filtrarCursosPorEnsenanza = (ensenanzaId, cursos) => {
  //   return cursos.filter((curso) => {
  //     console.log("curso", curso);

  //     return true;
  //   });
  // };

  useEffect(() => {
    setIsLoading(true);
    getPeriodosByColegio().then((r) => {
      setPeriodos(r?.data);
      if (r.data.length > 0) {
        setPeriodo(r?.data[0].id);
      }
    });
    getListadoEstudiante().then((r) => {
      setListaEstudiantes(r?.data.listaEstudiantes);
    });
    getListadoEnsenanzas().then((r) => {
      // console.log("Enseñanzas:", r.data.curriculumEnsenanzas);
      setListadoEnsenanzas(r?.data.curriculumEnsenanzas);
    });

    getEstadosEstudiantes().then((r) => {
      setListaEstadosEstudiantes(r?.data);
    });
  }, []);

  useEffect(() => {
    const filteredData = listaEstudiantes
      .filter((estudiante) => {
        // Filtrar por enseñanzas seleccionadas (múltiples)
        if (selectedEnsenanzas.length > 0) {
          // Revisar si el estudiante pertenece a alguna de las enseñanzas seleccionadas
          const perteneceEnsenanza = selectedEnsenanzas.some((ensenanza) =>
            cursosFiltrados.some(
              (curso) =>
                curso.cursoId === estudiante.cursoId &&
                curso.ensenanzaId === ensenanza.id
            )
          );
          if (!perteneceEnsenanza) return false; // Si no pertenece a ninguna enseñanza, filtrar
        }

        // Filtrar por cursos seleccionados (múltiples)
        if (selectedCursos.length > 0) {
          const perteneceCurso = selectedCursos.some(
            (curso) => estudiante.cursoId === curso.id
          );
          if (!perteneceCurso) return false; // Si no pertenece al curso, filtrar
        }

        // Filtrar por estados seleccionados (múltiples)
        if (selectedEstados.length > 0) {
          const currentEstado =
            estudiante.numMatricula && !estudiante.retirado
              ? 2
              : estudiante.numMatricula && estudiante.retirado
              ? 3
              : 1;
          const perteneceEstado = selectedEstados.some(
            (estado) => currentEstado === estado.id
          );
          if (!perteneceEstado) return false; // Si no pertenece al estado, filtrar
        }

        // Filtrar por PIE
        if (filtroPIE && !estudiante.esPie) return false;

        // Filtrar por Prioritario
        if (filtroPrioritario && !estudiante.esPrioritario) return false;

        // Filtrar por Preferente
        if (filtroPreferente && !estudiante.esPreferente) return false;

        // Filtrar por NEE (aunque esté deshabilitado en la UI)
        if (filtroNEE && !estudiante.nee) return false;

        return true; // Mantener estudiante si pasa todos los filtros
      })
      .map((e, index) => {
        // Tu lógica de mapeo de datos para la tabla
        return {
          id: index,
          value: [
            e.numMatricula && !e.retirado
              ? "Matriculado"
              : e.numMatricula && e.retirado
              ? "Retirado"
              : "Inscrito",
            e.numMatricula,
            formatearFecha(e.fechaMatricula),
            e.cursoNombre,
            e.numLista,
            formatearRutIpe(e.run + e.runDv) === ""
              ? formatearIPEIPA(e.runProvisorio + e.runProvisorioDv)
              : formatearRutIpe(e.run + e.runDv), // Posición 4: RUN
            <div className="d-flex gap-3 justify-content-start align-items-center ml-4">
              <Avatar
                width={"30px"}
                height={"30px"}
                run={e.run ? e.run : e.runProvisorio}
                allowPreview
              />{" "}
              {e.nombrePrimario}
            </div>,
            ` ${e.apellidoPrimario ? e.apellidoPrimario : "-"} ${
              e.apellidoSecundario ? e.apellidoSecundario : ""
            }`,
            e.genero === 1 ? (
              <CustomTooltip tooltipMsg="Masculino">
                <FontAwesomeIcon
                  icon={faMars}
                  size="2x"
                  style={{ color: "var(--color-masculino)" }}
                />
              </CustomTooltip>
            ) : e.genero === 2 ? (
              <CustomTooltip tooltipMsg="Femenino">
                <FontAwesomeIcon
                  icon={faVenus}
                  size="2x"
                  style={{ color: "var(--color-femenino)" }}
                />
              </CustomTooltip>
            ) : (
              ""
            ),
            e.esPie ? (
              <CustomTooltip tooltipMsg="PIE">
                <FontAwesomeIcon
                  icon={faCheck}
                  size="2x"
                  style={{ color: "var(--color-acento)" }}
                />
              </CustomTooltip>
            ) : (
              ""
            ),
            e.esPrioritario ? (
              <CustomTooltip tooltipMsg="Prioritario">
                <FontAwesomeIcon
                  icon={faCheck}
                  size="2x"
                  style={{ color: "var(--color-acento)" }}
                />
              </CustomTooltip>
            ) : (
              ""
            ),
            e.esPreferente ? (
              <CustomTooltip tooltipMsg="Preferente">
                <FontAwesomeIcon
                  icon={faCheck}
                  size="2x"
                  style={{ color: "var(--color-acento)" }}
                />
              </CustomTooltip>
            ) : (
              ""
            ),
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <CustomTooltip tooltipMsg={isDirectivo ? "Editar" : "Ver"}>
                <Button
                  variant={
                    !isDirectivo
                      ? "color-acento-outline"
                      : "color-amarillo-outline"
                  }
                  onClick={() => handleGetFicha(e)}
                >
                  <FontAwesomeIcon icon={isDirectivo ? faPenToSquare : faEye} />
                </Button>
              </CustomTooltip>
              {isDirectivo && (
                <CustomTooltip tooltipMsg={"Eliminar estudiante"}>
                  <div
                    className="w-100"
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <Button
                      variant="color-eliminar-outline"
                      disabled={!isDirectivo}
                      onClick={() => handleDeleteEstudiante(e)}
                      style={{ display: !isDirectivo ? "none" : "block" }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                </CustomTooltip>
              )}
            </div>,
          ],
        };
      });

    setFilteredData([...filteredData]);
    setIsLoading(false);
  }, [
    selectedEnsenanzas,
    selectedCursos,
    selectedEstados,
    filtroPIE,
    filtroPrioritario,
    filtroPreferente,
    filtroNEE,
    listaEstudiantes,
    cursosFiltrados,
    handleGetFicha,
    isDirectivo,
  ]);

  useEffect(() => {
    if (selectedEnsenanzas.length === 0) {
      setCursosFiltrados(cursos);
    } else {
      const filteredCursos = cursos.filter((curso) => {
        return selectedEnsenanzas.some(
          (ensenanza) => ensenanza.id === curso.ensenanzaId
        );
      });
      setCursosFiltrados(filteredCursos);
    }
  }, [cursos, selectedEnsenanzas]);

  return (
    <Container fluid className="px-4" style={{ height: "91.5dvh" }}>
      <Row style={{ position: "relative", padding: ".6rem" }}>
        <Title text={"Lista Estudiantes"} />

        {!isMobile && !isLoading && isDirectivo && (
          <Button
            variant="color-acento"
            className="w-auto w-sm-100"
            onClick={handleMatricularClick}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              position: "absolute",
              right: 15,
              top: 10,
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Agregar
          </Button>
        )}

        {!isLoading && !isLoading && (
          <Button
            variant={!showFilters ? "color-acento-outline" : "color-acento"}
            className="w-auto w-sm-100"
            onClick={() => setShowfilters(!showFilters)}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              position: "absolute",
              left: 15,
              top: 10,
            }}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtros
          </Button>
        )}
      </Row>
      <div style={{ position: "relative" }}>
        <Form>
          <Row
            className="px-2"
            style={{ display: showFilters ? "flex" : "none" }}
          >
            {isMobile && !isLoading && isDirectivo && (
              <Col xs={12} md={1} className="p-1">
                <Button
                  variant="color-acento"
                  className="w-100 "
                  onClick={handleMatricularClick}
                >
                  <FontAwesomeIcon icon={faPlusCircle} /> Agregar
                </Button>
              </Col>
            )}
            <Col xs={12} md={1} className="p-1">
              <SelectSingle
                placeholder="Año"
                idKey="id"
                labelKey="name"
                elements={periodos.map((p) => ({
                  id: p.id,
                  name: p.anio,
                }))}
                onSelect={(e) => setPeriodo(e?.id)}
                style={{
                  // fontSize: "0.8rem",
                  border: "1px solid var(--color-acento)",
                  color: "var(--color-texto-oscuro)",
                }}
                selectedElement={periodo}
                hideLabel
                disabled
              />
            </Col>
            <Col className="p-1">
              <SelectMultiple
                placeholder="Enseñanza"
                idKey="id"
                labelKey="name"
                elements={
                  listadoEnsenanzas?.map((element) => ({
                    id: element.ensenanzaId,
                    name: element.ensenanzaNombre,
                  })) || []
                }
                onSelect={(e) => setSelectedEnsenanzas(e || [])} // Asegúrate de que `e` siempre sea un array o un array vacío
                selectedElements={selectedEnsenanzas}
                hideLabel
                style={{
                  border: "1px solid var(--color-acento)",
                  color: "var(--color-texto-oscuro)",
                  width: isMobile ? "100%" : "230px",
                }}
              />
            </Col>
            <Col xs={12} md={1} className="p-1">
              <SelectMultiple
                placeholder="Curso"
                idKey="id"
                labelKey="name"
                elements={cursosFiltrados?.map((element) => ({
                  id: element.cursoId,
                  name: element.cursoNombre,
                }))}
                onSelect={(e) => setSelectedCursos(e || [])}
                hideLabel
                style={{
                  // fontSize: "0.8rem",
                  border: "1px solid var(--color-acento)",
                  color: "var(--color-texto-oscuro)",
                }}
                selectedElements={selectedCursos}
              />
            </Col>
            <Col xs={12} md={1} className="p-1">
              <SelectMultiple
                placeholder="Estado"
                idKey="id"
                labelKey="name"
                elements={listaEstadosEstudiantes.map((element) => ({
                  id: element.id,
                  name: element.estadoEstudiante,
                }))}
                onSelect={(e) => setSelectedEstados(e || [])}
                hideLabel
                style={{
                  // fontSize: "0.8rem",
                  border: "1px solid var(--color-acento)",
                  color: "var(--color-texto-oscuro)",
                }}
                selectedElements={selectedEstados}
              />
            </Col>
            <Col
              md={1}
              className="d-flex p-2 justify-content-center"
              style={{ width: "fit-content", fontSize: "0.8rem" }}
            >
              <CheckboxInput
                label="PIE"
                onChange={(e) => setFiltroPIE(e.target.checked)}
              />
            </Col>
            <Col
              md={1}
              className="d-flex p-2 justify-content-center"
              style={{ width: "fit-content", fontSize: "0.8rem" }}
            >
              <CheckboxInput
                label="Prioritario"
                onChange={(e) => setFiltroPrioritario(e.target.checked)}
              />
            </Col>
            <Col
              md={1}
              className="d-flex p-2 justify-content-center"
              style={{ width: "fit-content", fontSize: "0.8rem" }}
            >
              <CheckboxInput
                label="Preferente"
                onChange={(e) => setFiltroPreferente(e.target.checked)}
              />
            </Col>
            <Col
              md={1}
              className="d-flex p-2 justify-content-center"
              style={{ width: "fit-content" }}
            >
              <CheckboxInput
                label="NEE"
                onChange={(e) => setFiltroNEE(e.target.checked)}
                disabled
              />
            </Col>

            <Col className="p-1">
              <Tooltip tooltipMsg="Descargar plantilla para inscripción Estudiantes formato SIGE">
                <Button
                  variant="color-acento-outline"
                  className="w-100"
                  onClick={descargarPlantillaMatricula}
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* <i className="fa-solid fa-download"  /> Estudiantes */}
                  <FontAwesomeIcon icon={faDownload} /> Plantilla
                </Button>
              </Tooltip>
            </Col>
            <Col className="p-1">
              <Tooltip
                tooltipMsg={
                  isDirectivo
                    ? "Subir archivo para inscripción de Estudiantes"
                    : "No tienes permisos"
                }
              >
                <div
                  className="w-100"
                  style={{ position: "relative", display: "inline-block" }}
                >
                  <Button
                    variant="color-gray-outline"
                    className="w-100"
                    onClick={manejarMostrarModal}
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      border: "1px solid var(--color-icon) !important",
                    }}
                    disabled={!isDirectivo}
                  >
                    <FontAwesomeIcon icon={faUpload} /> Inscribir
                  </Button>
                </div>
              </Tooltip>
            </Col>

            <Col className="p-1">
              <Tooltip tooltipMsg="Descargar listado de matrícula">
                <Button
                  variant="color-acento-outline"
                  className="w-100"
                  onClick={downloadMatricula}
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} /> Matrícula
                </Button>
              </Tooltip>
            </Col>
            <Col className="p-1">
              <Tooltip
                tooltipMsg={
                  isDirectivo
                    ? "Subir archivo listado de matrícula"
                    : "No tienes permisos"
                }
              >
                <div
                  className="w-100"
                  style={{ position: "relative", display: "inline-block" }}
                >
                  <Button
                    variant="color-gray-outline"
                    className="w-100"
                    onClick={handleShowModal}
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      border: "1px solid var(--color-icon) !important",
                    }}
                    disabled={!isDirectivo}
                  >
                    <FontAwesomeIcon icon={faUpload} /> Matrícula
                  </Button>
                </div>
              </Tooltip>
            </Col>
          </Row>
        </Form>

        {listaEstudiantes.length === 0 && !isLoading && (
          <div
            className="text-center d-flex justify-content-center align-items-center"
            style={{ height: "65dvh" }}
          >
            <CustomSpinner />
          </div>
        )}

        {filteredData.length === 0 && !isLoading ? (
          <div
            className="text-center d-flex justify-content-center align-items-center"
            style={{ height: "65dvh" }}
          >
            <p>No se encontraron resultados para los filtros aplicados.</p>
          </div>
        ) : (
          <>
            {isLoading ? (
              <div
                className="text-center d-flex justify-content-center align-items-center"
                style={{ height: "70dvh" }}
              >
                <CustomSpinner />
              </div>
            ) : (
              <DynamicTable
                data={filteredData || data}
                headers={headers}
                options={{
                  stickyHeaders: true,
                  stickyOffset: "-10px",
                  checkboxSelection: false,
                  searchInput: true,
                  searchInputPlaceholder: "Buscar...",
                  resumeTable: resumeTable,
                  showItemsPerPageSelector: true,
                  showPagination: true,
                  copyClipboard: true,
                  downloadXLS: true,
                  xlsURL: "estudiante/exportListaEstudiante", //endpoint para exportar a excel
                  xlsFileName: "Lista_Matricula.xlsx", //nombre del archivo
                }}
                // style={{ height: "calc(100% - 60px)", overflowY: "hidden" }}
              />
            )}
          </>
        )}
      </div>

      <MatriculaSigeModal show={showModal} onHide={handleCloseModal} />
      <InscribirEstudianteSigeModal
        showModal={mostrarModal}
        handleClose={manejarCerrarModal}
      />
    </Container>
  );
};

export default MatriculaVista;
