import { faCopy, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { Button, Dropdown, Pagination, Table } from "react-bootstrap";
import { usePagination, useSortBy, useTable } from "react-table";
import { copiarAlPortapapeles } from "../../../../util/copiarPortapapeles";
import { normalizeString } from "../../../../util/util";
import TextInput from "../../inputs/TextInput";
import NotificacionToast from "../../notificacion/NotificacionToast";
import CustomTooltip from "../../tooltip/Tooltip";
import { ReportService } from "../../../../services";
import Swal from "sweetalert2";
import "./styles.css";

function DynamicTable({
  data = [],
  title,
  headers = [],
  options = {
    pageSize: 43,
    showPagination: true,
    showItemsPerPageSelector: true,
    resumeTable: [],
    copyClipboard: true,
    searchInput: true,
    downloadXLS: false,
    stickyHeaders: false,
    stickyOffset: "0px",
    xlsURL: "",
    xlsFileName: "",
    customSort: null,
    colorDict: {},
  },
}) {
  const isMobile = window.innerWidth < 768;
  const { stickyOffset } = options;

  const itemsPerPageList = [5, 10, 15, 20, 25, 43, 50, 100, 200, "Todos"];
  const [searchTerm, setSearchTerm] = useState("");
  const [mostrarToast, setMostrarToast] = useState(false);
  const [, setMensajeToast] = useState("");

  const columns = useMemo(() => {
    const collator = new Intl.Collator("es", { sensitivity: "base" });

    // Función de ordenación personalizada usando el collator
    const stringSort = (rowA, rowB, columnId) => {
      const valueA = rowA.values[columnId];
      const valueB = rowB.values[columnId];

      // Extraer el texto si el valor es JSX
      const textA =
        typeof valueA === "string" ? valueA : valueA?.props?.children[1];
      const textB =
        typeof valueB === "string" ? valueB : valueB?.props?.children[1];

      return collator.compare(textA, textB);
    };

    const genderSort = (rowA, rowB, columnId) => {
      const valueA =
        rowA.values[columnId] !== ""
          ? rowA.values[columnId].props.tooltipMsg
          : "";
      const valueB =
        rowB.values[columnId] !== ""
          ? rowB.values[columnId].props.tooltipMsg
          : "";

      if (valueA === valueB) {
        return 0;
      }
      if (valueA === "Masculino" && valueB === "Femenino") {
        return -1;
      }
      if (valueA === "Femenino" && valueB === "Masculino") {
        return 1;
      }
      return 0;
    };
    function limpiarArray(array) {
      return array
        .map((item) => {
          // Si el item es un array, limpiamos sus elementos de manera recursiva
          if (Array.isArray(item)) {
            return limpiarArray(item);
          }

          // Si es un objeto con propiedades, eliminamos objetos vacíos
          if (typeof item === "object" && item !== null) {
            // Eliminamos objetos vacíos, pero mantenemos los que tienen componentes JSX u otras propiedades válidas
            if (Object.keys(item).length === 0 && item.constructor === Object) {
              return null;
            }
            return item;
          }

          // Filtramos valores vacíos, null o undefined
          if (
            item === "" ||
            item === " " ||
            item === null ||
            item === undefined
          ) {
            return null;
          }

          // Retornamos el valor si es válido
          return item;
        })
        .filter((item) => item !== null); // Filtramos cualquier null resultante de las verificaciones anteriores
    }

    const objectSort = (rowA, rowB, columnId) => {
      const valueA = rowA.values[columnId];
      const valueB = rowB.values[columnId];

      // console.log("Valor A: ", limpiarArray(valueA.props.children));
      // console.log("Valor B: ", limpiarArray(valueB.props.children));

      // Verifica si el valor tiene `props` antes de acceder a él
      const textA = React.isValidElement(valueA)
        ? limpiarArray(valueA.props.children)[1]
        : valueA;
      const textB = React.isValidElement(valueB)
        ? limpiarArray(valueB.props.children)[1]
        : valueB;

      if (typeof textA === "string" && typeof textB === "string") {
        return textA.localeCompare(textB);
      }
      return 0;
    };

    const numericSort = (rowA, rowB, columnId) => {
      const valueA = parseFloat(rowA.values[columnId]) || 0;
      const valueB = parseFloat(rowB.values[columnId]) || 0;
      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    };

    const dateSort = (rowA, rowB, columnId) => {
      const valueA = new Date(rowA.values[columnId]);
      const valueB = new Date(rowB.values[columnId]);
      return valueA - valueB;
    };

    return headers.map((col, index) => ({
      Header: col.name,
      accessor: (row) => row.value[index],
      width: col.width,
      id: col.name || index,
      sortType:
        col.sortType === "string"
          ? stringSort
          : col.sortType === "number"
          ? numericSort
          : col.sortType === "date"
          ? dateSort
          : col.sortType === "gender"
          ? genderSort
          : col.sortType === "object"
          ? objectSort
          : options.customSort, // If not specified, use default sort
      disableSortBy: !col.sortable, // Disable sorting if `sortable` is false
    }));
  }, [headers, options.customSort]);

  const sanitizeChildren = (children) => {
    // Filtrar solo los elementos válidos (no vacíos o nulos)
    const sanitized = React.Children.toArray(children).filter((child) => {
      return child !== null && child !== undefined && child !== "" && child !== " ";
    });
  
    // Retornar solo los primeros dos hijos si hay más de dos
    return sanitized.slice(0, 2);
  };
  

  const filteredData = useMemo(
    () =>
      data.filter((row) => {
        // Para columnas con JSX, extraemos el texto manualmente
        const normalizedRow = row.value
          .map((cell) => {
            // Convertimos el valor a texto si es numérico
            const cellValue = typeof cell === "number" ? cell.toString() : cell;
            // Extraemos el nombre si es JSX, luego sanitizamos los children
            if (React.isValidElement(cellValue)) {
              const sanitizedChildren = sanitizeChildren(cellValue.props.children);
              return sanitizedChildren[1]; // Extraemos el segundo hijo que es el nombre
            }
            return cellValue;
          })
          .join(" ");
  
        return normalizeString(normalizedRow).includes(
          normalizeString(searchTerm)
        );
      }),
    [data, searchTerm]
  );
  const tableInstance = useTable(
    {
      columns,
      data: filteredData,
      initialState: useMemo(() => {
        const defaultSortColumn = headers.find((col) => col.sortByDefault);
        return {
          pageSize: options.pageSize || 43,
          sortBy: defaultSortColumn
            ? [
                {
                  id: defaultSortColumn.name, // El id será el nombre de la columna
                  desc: false, // Si quieres que la ordenación por defecto sea ascendente
                },
              ]
            : [],
        };
      }, [headers, options.pageSize]), // Dependencia en headers
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Current page rows after filtering
    prepareRow,
    state: { pageIndex, pageSize },
    pageOptions,
    gotoPage,
    setPageSize,
  } = tableInstance;

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    gotoPage(0); // Reset to first page after search
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    const size =
      newItemsPerPage === "Todos"
        ? filteredData.length
        : Number(newItemsPerPage);
    setPageSize(size);
    gotoPage(0); // Reset to first page after changing page size
  };

  const renderCellContent = (cell) => {
    if (
      cell.props.cell.value === null ||
      cell.props.cell.value === "" ||
      cell.props.cell.value === undefined ||
      cell.props.cell.value === "null"
    ) {
      return "-";
    }
    return React.isValidElement(cell) ? cell : cell;
  };
  const renderPagination = () => {
    let items = [];

    const maxPagesToShow = 5;
    const halfPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, pageIndex - halfPagesToShow + 1);
    let endPage = Math.min(pageOptions.length, pageIndex + halfPagesToShow + 1);

    if (pageIndex <= halfPagesToShow) {
      endPage = Math.min(pageOptions.length, maxPagesToShow);
    } else if (pageIndex + halfPagesToShow >= pageOptions.length) {
      startPage = Math.max(1, pageOptions.length - maxPagesToShow + 1);
    }

    const paginationItemStyle = {
      width: "40px",
      textAlign: "center",
      display: "inline-block",
      backgroundColor: "var(--color-acento)", // Aquí defines el color personalizado
      color: "#fff", // Cambia el color del texto si es necesario
      borderColor: "var(--color-acento)", // Cambia el color del borde
      borderRadius: "5px",
    };

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === pageIndex + 1}
          onClick={() => gotoPage(number - 1)}
          style={paginationItemStyle}
        >
          {number}
        </Pagination.Item>
      );
    }

    const isMobile = window.innerWidth < 768; // Detecta si el dispositivo es móvil

    return (
      <Pagination
        className={isMobile ? "pagination-fixed-mobile" : ""} // Aplica la clase para móviles
      >
        <Pagination.First
          onClick={() => gotoPage(0)}
          disabled={pageIndex === 0}
          style={paginationItemStyle}
        />
        <Pagination.Prev
          onClick={() => gotoPage(pageIndex > 0 ? pageIndex - 1 : 0)}
          disabled={pageIndex === 0}
          style={paginationItemStyle}
        />
        {items}
        <Pagination.Next
          onClick={() =>
            gotoPage(
              pageIndex < pageOptions.length - 1 ? pageIndex + 1 : pageIndex
            )
          }
          disabled={pageIndex === pageOptions.length - 1}
          style={paginationItemStyle}
        />
        <Pagination.Last
          onClick={() => gotoPage(pageOptions.length - 1)}
          disabled={pageIndex === pageOptions.length - 1}
          style={paginationItemStyle}
        />
      </Pagination>
    );
  };

  const resume = useCallback(() => {
    return (
      <section className="w-100 d-flex justify-content-center gap-5 mt-3">
        {options.resumeTable &&
          options.resumeTable.map((item, index) => (
            <span
              key={index}
              style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}
            >
              {item.label}: <strong>{item.value}</strong>
            </span>
          ))}
      </section>
    );
  }, [options.resumeTable]);

  const isAccionesColumn = (column) => {
    return column.Header === "Acciones";
  };

  return (
    <section>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <div
          style={{
            alignItems: "center",
            width: "fit-content",
            display: options.searchInput ? "flex" : "none",
          }}
        >
          <TextInput
            placeholder={options.searchInputPlaceholder || "Buscar..."}
            value={searchTerm}
            onChange={handleSearch}
            style={{
              width: isMobile ? "200px" : "280px",
              border: "1px solid var(--color-acento)",
            }}
          />
          <section
            style={{
              alignSelf: "center",
              height: "3rem",
              marginTop: "0rem",
              marginLeft: "1rem",
            }}
          >
            {title && (
              <h3 style={{ textOverflow: "ellipsis", textWrap: "nowrap" }}>
                {title}
              </h3>
            )}
          </section>
        </div>

        <div
          className="d-none d-md-flex"
          style={{ display: options.resumeTable ? "inline-block" : "none" }}
        >
          {resume(filteredData)}
        </div>

        <section style={{ display: "flex", gap: "1rem" }}>
          <div
            style={{
              display:
                options.downloadXLS &&
                options.xlsFileName !== "" &&
                options.xlsURL !== ""
                  ? "inline-block"
                  : "none",
            }}
          >
            <CustomTooltip tooltipMsg={"Descargar tabla en formato XLS"}>
              <div
                className="w-100"
                style={{ position: "relative", display: "inline-block" }}
              >
                <Button
                  variant="color-black-acento-outline"
                  onClick={() => {
                    ReportService.read(
                      options.xlsURL,
                      null,
                      null,
                      options.xlsFileName
                    )
                      .then((res) => {
                        Swal.fire({
                          icon: "info",
                          title: "Descarga completa",
                          text: "La descarga se ha realizado correctamente",
                          customClass: {
                            confirmButton: "custom-swal-button",
                          },
                        });
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Error",
                          text: `Ocurrió un error: ${error.message}`,
                          customClass: {
                            confirmButton: "custom-swal-buttonRed",
                          },
                        });
                      });
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              </div>
            </CustomTooltip>
          </div>
          <div
            style={{
              display:
                options.copyClipboard && !isMobile ? "inline-block" : "none",
            }}
          >
            <CustomTooltip tooltipMsg="Copiar tabla al portapapeles">
              <Button
                variant="color-black-acento-outline"
                onClick={() =>
                  copiarAlPortapapeles(
                    document.getElementById("dynamic-table"),
                    setMensajeToast,
                    setMostrarToast
                  )
                }
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </CustomTooltip>
          </div>

          <div
            style={{
              display: options.showItemsPerPageSelector
                ? "inline-block"
                : "none",
            }}
          >
            <Dropdown
              onSelect={(e) =>
                handleItemsPerPageChange(e === "Todos" ? "Todos" : parseInt(e))
              }
            >
              <CustomTooltip tooltipMsg="Elementos por página">
                <Dropdown.Toggle
                  variant="color-black-acento-outline"
                  id="dropdown-basic"
                  style={{ width: "120px" }}
                >
                  {pageSize === filteredData.length ? "Todos" : pageSize}
                </Dropdown.Toggle>
              </CustomTooltip>
              <Dropdown.Menu style={{ minWidth: "120px", zIndex: 25 }}>
                {itemsPerPageList.map((value, index) => (
                  <Dropdown.Item
                    eventKey={value}
                    key={index}
                    style={{ position: "relative", textAlign: "center" }}
                  >
                    {(pageSize === value ||
                      (value === "Todos" &&
                        pageSize === filteredData.length)) && (
                      <i
                        className="fas fa-check"
                        style={{
                          position: "absolute",
                          left: "20px",
                          top: "10px",
                          color: "var(--color-acento)",
                        }}
                      />
                    )}
                    {value}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </section>
      </div>

      <div className="table-container">
        <div
          className="table-container"
          style={{ maxHeight: "60dvh", overflowY: "auto", overflowX: "auto" }}
        >
          <Table
            id="dynamic-table"
            {...getTableProps()}
            bordered
            hover
            style={{
              backgroundColor: "transparent",
              minWidth: "100%",
              tableLayout: isMobile ? "auto" : "fixed",
            }}
          >
            <thead style={{ zIndex: 2 }}>
              {headerGroups.map((headerGroup) => {
                const { key: headerGroupKey, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps();
                return (
                  <tr key={headerGroupKey} {...headerGroupProps}>
                    {headerGroup.headers.map((column) => {
                      const { key: columnKey, ...restColumnProps } =
                        column.getHeaderProps(column.getSortByToggleProps());
                      return (
                        <th
                          key={columnKey}
                          {...restColumnProps}
                          className="text-center"
                          style={{
                            width: column.width,
                            textAlign: column.textAlign,
                            cursor: column.disableSortBy
                              ? "default"
                              : "pointer",
                            position: options.stickyHeaders
                              ? "sticky"
                              : "static",
                            top: options.stickyHeaders ? stickyOffset : "auto",
                            backgroundColor: options.stickyHeaders
                              ? "#fff"
                              : "inherit",
                            zIndex: options.stickyHeaders ? 20 : "auto",
                          }}
                        >
                          {column.render("Header")}
                          {!column.disableSortBy && (
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " ↓"
                                  : " ↑"
                                : " ⇅"}
                            </span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);

                // Obtener el nombre de la asignatura desde el valor correspondiente
               
                const { key: rowKey, ...rowProps } = row.getRowProps();
                return (
                  <tr style={{ backgroundColor: 'transparent' }} key={rowKey} {...rowProps}>
                    {row.cells.map((cell, cellIndex) => {
                      const isAcciones = isAccionesColumn(cell.column); // Detecta la columna de "Acciones"

                      const { key: cellKey, ...restCellProps } =
                        cell.getCellProps();
                 
                      return (
                        <td
                          key={cellKey}
                          {...restCellProps}
                          className={`text-center ${
                            row.values["Estado"] === "Retirado" && !isAcciones
                              ? "linea-roja-celda"
                              : ""
                          }`}
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            position: "relative", // Necesario para que ::before funcione correctamente
                            color:'#888' ,
                            backgroundColor:  "white" ,
                            // fontWeight:  600 ,
                          }}
                        >
                          {renderCellContent(cell.render("Cell"))}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* El paginador se mantiene siempre visible y centrado */}
        {options.showPagination && (
          <div className="table-pagination d-flex justify-content-center">
            {renderPagination()}
          </div>
        )}
      </div>

      <NotificacionToast
        mensaje="Tabla copiada al portapapeles"
        mostrar={mostrarToast}
        setMostrar={setMostrarToast}
      />
    </section>
  );
}

DynamicTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.array.isRequired,
      isChecked: PropTypes.bool,
    })
  ).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      width: PropTypes.string, // Optional: specify the width of the column
      sortable: PropTypes.bool, // Specify if the column should be sortable
      sortType: PropTypes.string, // Specify the type of sorting (string, numeric, date)
    })
  ).isRequired,
  options: PropTypes.shape({
    checkboxSelection: PropTypes.bool,
    searchInput: PropTypes.bool,
    searchInputPlaceholder: PropTypes.string,
    showPagination: PropTypes.bool, // Prop to control the visibility of the paginator
    showItemsPerPageSelector: PropTypes.bool, // Prop to control the visibility of items per page selector
  }),
};

export default DynamicTable;
