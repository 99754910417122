import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import estilosEstado from '../../objetoEstilos/estiloEstados';
import NotificacionToast from '../notificacion/NotificacionToast';

const estilos = {
  encabezado: {
    backgroundColor: "var(--color-header)",
    color: "var(--color-texto-oscuro)",
    position: 'sticky',
    top: 0,
    zIndex: 0,
    textAlign: 'center',
    verticalAlign: 'middle',
    borderBottom: '1px solid #e6e6e6',
    borderTop: '1px solid #e6e6e6',
    padding: 0,
    margin: 0,
  },
  encabezadoFinDeSemana: {
    color: 'red',
    position: 'sticky',
    top: 0,
    zIndex: 0,
    textAlign: 'center',
    verticalAlign: 'middle',
    borderBottom: '1px solid #e6e6e6',
    borderTop: '1px solid #e6e6e6',
    padding: "0px",
    margin: "0px",
  },
  encabezadoFinDeSemanaIzquierdo: {
    borderLeft: '1px solid var(--color-secundario)', // Color del fondo
  },
  encabezadoFinDeSemanaDerecho: {
    borderRight: '1px solid #e6e6e6',
  },
  encabezadoFinDeSemanaCentro: {
    borderRight: '1px solid var(--color-secundario)', // Color del fondo
  },
  contenedorTabla: {
    maxHeight: '73vh',
    overflowY: 'auto',
    backgroundColor: 'white',
    marginTop: '10px',
  },
  celda: {
    height: '40px',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: 0,
    margin: 0,
    borderTop: '1px solid #e6e6e6',
    borderBottom: '1px solid #e6e6e6',
    borderLeft: '1px solid #e6e6e6',
    borderRight: '1px solid #e6e6e6',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '12px',
    cursor: 'default',
  },
  celdaSinBordeDerecho: {
    borderRight: 'none',
  },
  celdaSinBordeIzquierdo: {
    borderLeft: 'none',
  },
  celdaFinDeSemana: {
    backgroundColor: 'var(--color-secundario)',
    borderTop: '1px solid var(--color-secundario)',
    borderBottom: '1px solid var(--color-secundario)',
    cursor: 'default', // No clickeable
  },
  celdaFinDeSemanaIzquierdo: {
    borderLeft: '1px solid var(--color-secundario)', // Color del fondo
  },
  celdaFinDeSemanaDerecho: {
    borderRight: '1px solid #e6e6e6',
  },
  celdaFinDeSemanaCentro: {
    borderRight: '1px solid var(--color-secundario)', // Color del fondo
  },
  textoCurso: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: 'rgb(136, 136, 136)',
  },
  bordeIzquierdoBlanco: {
    borderLeft: '1px solid white',
  },
  bordeDerechoBlanco: {
    borderRight: '1px solid white',
  },
  filaHover: {
    backgroundColor: "var(--color-doble-hover)",
  },
  columnaHover: {
    backgroundColor: "var(--color-doble-hover)",
  },
  iconoGrande: {
    fontSize: '1.65rem', 
  },
  celdaNoClickeable: {
    cursor: 'not-allowed', 
  },
};

const formatearFecha = (dia, mes, year) => {
  const fecha = new Date(year, mes - 1, dia);
  const opcionesDia = { day: '2-digit' };
  const opcionesDiaSemana = { weekday: 'short' };
  const formattedDay = fecha.toLocaleDateString('es-ES', opcionesDia).replace('.', '').toUpperCase();
  let formattedDayOfWeek = fecha.toLocaleDateString('es-ES', opcionesDiaSemana).replace('.', '').toUpperCase().slice(0, 2);
  if (formattedDayOfWeek === 'SÁ') {
    formattedDayOfWeek = 'SA';
  }
  return {
    day: formattedDay,
    dayOfWeek: formattedDayOfWeek
  };
};

const esFinDeSemana = (dia, mes, year) => {
  const fecha = new Date(year, mes - 1, dia);
  const diaSemana = fecha.getDay();
  return diaSemana === 0 || diaSemana === 6;
};

const TablaAsistenciaMensualSubvencionCursos = ({ datosAsistencia, mes, fetchMoreData, isSoloDocente }) => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [notificacion, setNotificacion] = useState({ mostrar: false, mensaje: '' });
  const contenedorTablaRef = useRef(null);
  const navigate = useNavigate(); // Hook de navegación

  const year = new Date().getFullYear(); // Año actual
  const diasEnElMes = new Date(year, mes, 0).getDate();
  const diasDelMes = Array.from({ length: diasEnElMes }, (_, i) => i + 1);

  const handleMouseEnter = (rowIndex, colIndex) => {
    setHoveredRow(rowIndex);
    setHoveredColumn(colIndex);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
    setHoveredColumn(null);
  };

  const handleScroll = () => {
    if (contenedorTablaRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contenedorTablaRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        fetchMoreData();
      }
    }
  };

  useEffect(() => {
    const contenedorTabla = contenedorTablaRef.current;
    contenedorTabla.addEventListener('scroll', handleScroll);
    return () => {
      contenedorTabla.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const columnasFinDeSemana = diasDelMes.map(dia => {
    return datosAsistencia.some(curso => curso[`asistenciaConteo${String(dia).padStart(2, '0')}`] === -2);
  });

  const handleCellClick = (asistencia, cursoId, cursoNombre) => {
    if (!isSoloDocente && asistencia === -4) {
      navigate('/asistencia-mensual-subvenciones-estudiantes', {
        state: { cursoId, cursoNombre }
      });
    }
  };

  return (
    <div className="table-responsive pb-0" style={estilos.contenedorTabla} ref={contenedorTablaRef}>
      <Table bordered className="pb-0 mb-0">
        <thead>
          <tr>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-numero-lista">Orden listado</Tooltip>}
            >
              <th style={{ ...estilos.encabezado, ...estilos.bordeIzquierdoBlanco, borderRight: 'none', width: '30px' }}>#</th>
            </OverlayTrigger>
            <th style={{ ...estilos.encabezado, borderLeft: 'none', width: '100px' }}>Curso</th>
            {diasDelMes.map((dia, colIndex) => {
              const { day, dayOfWeek } = formatearFecha(dia, mes, year);
              const isWeekend = esFinDeSemana(dia, mes, year) || columnasFinDeSemana[colIndex];
              const isSaturday = new Date(year, mes - 1, dia).getDay() === 6;
              const isSunday = new Date(year, mes - 1, dia).getDay() === 0;
              return (
                <th
                  key={dia}
                  style={
                    isWeekend
                      ? isSaturday
                        ? { ...estilos.encabezado, ...estilos.encabezadoFinDeSemana, ...estilos.encabezadoFinDeSemanaIzquierdo, ...estilos.encabezadoFinDeSemanaCentro, width: '20px' }
                        : isSunday
                          ? { ...estilos.encabezado, ...estilos.encabezadoFinDeSemana, ...estilos.encabezadoFinDeSemanaDerecho, width: '20px' }
                          : { ...estilos.encabezado, ...estilos.encabezadoFinDeSemana, width: '20px' }
                      : { ...estilos.encabezado, width: '30px' }
                  }
                >
                  <div>{dayOfWeek}</div>
                  <div>{day}</div>
                </th>
              );
            })}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-porcentaje">
                  <math display="block" className='math-font'>
                    <mrow>
                      <mfrac>
                        <mn>Suma<br/>
                          [(# de Estudiantes
                            Presentes)</mn>
                        <ms>(# de Estudiantes Totales)<br/>de c/día incurrido del mes]</ms>
                      </mfrac>
                    </mrow>
                  </math>
                </Tooltip>
              }
            >
              <th style={{ ...estilos.encabezado, width: '75px' }}>% Asistencia</th>
            </OverlayTrigger>
          </tr>
        </thead>
        <tbody>
          {datosAsistencia.map((curso, rowIndex) => (
            <tr
              key={curso.cursoId}
              onMouseEnter={() => handleMouseEnter(rowIndex, null)}
              onMouseLeave={handleMouseLeave}
            >
              <td style={{ ...estilos.celda, ...estilos.bordeIzquierdoBlanco, borderRight: 'none', ...(hoveredRow === rowIndex ? estilos.filaHover : {}) }}>{rowIndex + 1}</td>
              <td style={{ ...estilos.celda, borderLeft: 'none', ...(hoveredRow === rowIndex ? estilos.filaHover : {}) }}>
                <div className="d-flex align-items-center" style={estilos.textoCurso}>
                  <span>{curso.cursoNombre}</span>
                </div>
              </td>
              {diasDelMes.map((dia, colIndex) => {
                const asistenciaKey = `asistenciaConteo${String(dia).padStart(2, '0')}`;
                const asistencia = curso[asistenciaKey]; // Asistencia del día específico
                const isWeekend = esFinDeSemana(dia, mes, year) || columnasFinDeSemana[colIndex];
                const isSaturday = new Date(year, mes - 1, dia).getDay() === 6;
                const isSunday = new Date(year, mes - 1, dia).getDay() === 0;
                const isHovered = hoveredRow === rowIndex || hoveredColumn === colIndex;
                const isExactHover = hoveredRow === rowIndex && hoveredColumn === colIndex;

                return (
                  <td
                  key={dia}
                  style={
                    isWeekend
                      ? isSaturday
                        ? { ...estilos.celda, ...estilos.celdaFinDeSemana, ...estilos.celdaFinDeSemanaIzquierdo, ...estilos.celdaFinDeSemanaCentro }
                        : isSunday
                          ? { ...estilos.celda, ...estilos.celdaFinDeSemana, ...estilos.celdaFinDeSemanaDerecho }
                          : { ...estilos.celda, ...estilos.celdaFinDeSemana }
                      : { 
                          ...estilos.celda, 
                          ...(isHovered ? estilos.filaHover : {}),
                          ...(isExactHover ? { backgroundColor: 'var(--color-secundario)' } : {}),
                          ...(isSoloDocente? estilos.celdaNoClickeable : {}), // Aplicar estilo de celda no clickeable si es docente
                        }
                  }
                  onMouseEnter={() => handleMouseEnter(rowIndex, colIndex)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => !isSoloDocente && handleCellClick(asistencia, curso.cursoId, curso.cursoNombre)} // Solo permitir el click si no es docente
                >
                    {!isWeekend && asistencia !== -1 && (
                      <span>
                        {asistencia === -4 ? (
                          <i className={estilosEstado.pendiente.icon} style={{ ...estilos.icono, ...estilos.iconoGrande, color: estilosEstado.pendiente.iconColor }}></i>
                        ) : (
                          asistencia
                        )}
                      </span>
                    )}
                  </td>
                );
              })}
              <td style={{ ...estilos.celda, ...estilos.bordeDerechoBlanco, ...(hoveredRow === rowIndex ? estilos.filaHover : {}) }}>{curso.asistenciaMensual}%</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <NotificacionToast mensaje="Datos actualizados correctamente" mostrar={notificacion.mostrar} setMostrar={(estado) => setNotificacion({ ...notificacion, mostrar: estado })} />
    </div>
  );
};

export default TablaAsistenciaMensualSubvencionCursos;
