import estilosEstado from '../jsx/objetoEstilos/estiloEstados';

export const obtenerHtmlDeElemento = (elemento) => {
  if (!elemento) return ''; // Retornar vacío si no se encuentra el elemento

  const elementoClonado = elemento.cloneNode(true); // Clonar el elemento
  const filas = elementoClonado.querySelectorAll('tr'); // Obtener todas las filas

  filas.forEach(fila => {
    const celdas = fila.querySelectorAll('td, th'); // Obtener todas las celdas, incluyendo encabezados (th)
    
    celdas.forEach((celda, index) => {
      // Eliminar símbolos de ordenamiento en los headers
      if (celda.tagName.toLowerCase() === 'th') {
        celda.textContent = celda.textContent.replace(/[\u2191\u2193\u21C5]/g, '').trim(); // Eliminar ↑ ↓ ⇅
      }

      // Identificar si la columna es 'Acciones' basándonos en el encabezado
      const esColumnaAcciones = celda.textContent.trim().toLowerCase() === 'acciones';

      if (esColumnaAcciones) {
        // Si es la columna de acciones, la ocultamos o la eliminamos
        celda.style.display = 'none'; // Ocultar en lugar de eliminar para mantener la estructura de la tabla
        return;
      }

      // Eliminar cualquier imagen dentro de la celda
      const imagenes = celda.querySelectorAll('img');
      imagenes.forEach(imagen => {
        if (imagen && imagen.parentNode) {
          imagen.parentNode.removeChild(imagen);
        }
      });

      // Eliminar cualquier input dentro de la celda
      const inputs = celda.querySelectorAll('input');
      inputs.forEach(input => {
        if (input && input.parentNode) {
          input.parentNode.removeChild(input);
        }
      });

      // Reemplazar iconos específicos
      const maleIcons = celda.querySelectorAll('#icon-male');
      maleIcons.forEach(icono => {
        const descripcion = document.createTextNode('H');
        if (icono.parentNode) {
          icono.parentNode.replaceChild(descripcion, icono);
        }
      });

      const femaleIcons = celda.querySelectorAll('#icon-female');
      femaleIcons.forEach(icono => {
        const descripcion = document.createTextNode('M');
        if (icono.parentNode) {
          icono.parentNode.replaceChild(descripcion, icono);
        }
      });

      const checkIcons = celda.querySelectorAll('#icon-check');
      checkIcons.forEach(icono => {
        const descripcion = document.createTextNode('✔'); // Puedes cambiar esto por el texto que desees
        if (icono.parentNode) {
          icono.parentNode.replaceChild(descripcion, icono);
        }
      });

      // Reemplazar otros iconos por valores numéricos o 'P'
      const iconos = celda.querySelectorAll('i');
      iconos.forEach(icono => {
        if (icono.id === 'icon-male' || icono.id === 'icon-female' || icono.id === 'icon-check') return; // Ya los hemos manejado arriba

        const estado = Object.keys(estilosEstado).find(key => estilosEstado[key].icon === icono.className);
        let valor;
        if (estado) {
          switch (estado) {
            case 'presente':
            case 'atrasado':
            case 'fueraDeSala':
            case 'retirado':
              valor = '1';
              break;
            case 'ausente':
              valor = '0';
              break;
            case 'pendiente':
              valor = 'P';
              break;
            default:
              valor = ''; // Valor por defecto en caso de no encontrar un estado válido
          }
          const descripcion = document.createTextNode(valor);
          if (icono.parentNode) {
            icono.parentNode.replaceChild(descripcion, icono);
          }
        } else if (icono.parentNode) {
          icono.parentNode.removeChild(icono); // Remover el icono si no hay descripción
        }
      });
    });
  });

  const contenedor = document.createElement('div'); // Crear un contenedor temporal
  contenedor.appendChild(elementoClonado); // Añadir el elemento clonado al contenedor
  return contenedor.innerHTML; // Retornar el HTML del contenedor
};


export const copiarHtmlAlPortapapeles = (html) => {
  const divTemporal = document.createElement('div'); // Crear un div temporal
  divTemporal.style.position = 'fixed'; // Posición fija para no afectar el layout
  divTemporal.style.pointerEvents = 'none'; // Deshabilitar eventos de puntero
  divTemporal.style.opacity = '0'; // Hacer el div invisible
  divTemporal.innerHTML = html; // Establecer el HTML del div temporal
  document.body.appendChild(divTemporal); // Añadir el div temporal al cuerpo del documento

  const rango = document.createRange(); // Crear un rango para seleccionar el contenido
  rango.selectNodeContents(divTemporal); // Seleccionar el contenido del div temporal

  const seleccion = window.getSelection(); // Obtener la selección actual
  seleccion.removeAllRanges(); // Remover cualquier selección existente
  seleccion.addRange(rango); // Añadir el nuevo rango a la selección

  try {
    document.execCommand('copy'); // Ejecutar el comando de copiar
    seleccion.removeAllRanges(); // Remover el rango de la selección
    document.body.removeChild(divTemporal); // Remover el div temporal del cuerpo del documento
    // console.log('HTML copiado exitosamente'); // Log de éxito
  } catch (err) {
    console.error('Error al copiar el HTML: ', err); // Log de error
    document.body.removeChild(divTemporal); // Remover el div temporal del cuerpo del documento
    throw new Error('Error al copiar al portapapeles'); // Lanzar un error
  }
};

export const copiarAlPortapapeles = (elemento, setMensajeToast, setMostrarToast) => {
  try {
    const html = obtenerHtmlDeElemento(elemento); // Obtener el HTML del elemento
    if (!html) {
      throw new Error('No hay texto para copiar'); // Lanzar error si no hay HTML
    }
    copiarHtmlAlPortapapeles(html); // Copiar el HTML al portapapeles
    setMensajeToast('¡Contenido copiado al portapapeles!'); // Establecer mensaje de éxito
    setMostrarToast(true); // Mostrar el toast
  } catch (error) {
    setMensajeToast(`Falla al copiar: ${error.message}`); // Establecer mensaje de error
    setMostrarToast(true); // Mostrar el toast
  }
};
