import React, { useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import obtenerColorMapaCalor from '../../../util/utilidadesMapaCalor';
import { estilosEstadoAdicionales } from "../../objetoEstilos/estiloEstudiante";
import Avatar from '../avatar/Avatar';
import NotificacionToast from '../notificacion/NotificacionToast';


const TablaPorcentajeAsistenciaAnualPorEstudiante = ({ estudiantes, cursoAsistencias, meses, isHeatmapActive }) => {
    const [mostrarToast, setMostrarToast] = useState(false);
    const [mensajeToast, setMensajeToast] = useState('');

    const estilos = {
        container: {
            maxHeight: '79dvh',
            overflow: 'auto',
            position: 'relative'
        },
        thSticky: {
            position: 'sticky',
            top: "0px",
            backgroundColor: 'var(--color-header)',
            color: 'var(--color-texto-oscuro)',
            borderBottom: '1px solid lightgrey',
            zIndex: 9,
            height: '45px',
        },
        tdImage: {
            width: '30px',
            height: '30px',
            marginRight: '10px',
            borderRadius: '50%'
        },
        textoEstudiante: {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            color: 'rgb(136, 136, 136)',
        },
        textoNumero: {
            fontFamily: 'Poppins, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            color: 'rgb(136, 136, 136)',
        },
        colEstudiante: {
            minWidth: '180px',
            borderRight: '1px solid lightgrey',
            height: '45px',
        },
        colMes: {
            minWidth: '100px',
            maxWidth: '100px'
        },
        colNumero: {
            width: '50px',
        },
        colResumen: (isActive) => ({
            minWidth: '100px',
            borderLeft: '1px solid lightgrey',
        }),
        td: (isActive, value, isResumen, retirado) => ({
            borderBottom: '1px solid lightgrey',
            borderTop: 'none',
            borderLeft: isResumen ? (isActive ? '3px solid white' : '1px solid lightgrey') : 'none',
            borderRight: 'none',
            backgroundColor: isActive ? obtenerColorMapaCalor(parseFloat(value)) : 'white',
            color: isActive ? 'black' : 'var(--color-texto-claro)',
            textAlign: 'center',
            padding: '8px',
            fontSize: '0.9em',
            cursor: retirado ? 'not-allowed' : 'pointer', // Añadir cursor not-allowed para estudiantes retirados
            position: 'relative' // Para la línea roja
        }),
        trSticky: {
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            borderBottom: 'none',
            height: '45px',
            zIndex: 20,
        },
        boldText: {
            fontWeight: 'bold',
            color: 'var(--color-texto-oscuro)',
            fontSize: '1.1em'
        },
        resumenTd: (isActive) => ({
            borderBottom: '1px solid lightgrey',
            borderLeft: '1px solid white',
            backgroundColor: 'white',
            textAlign: 'center',
            padding: '8px',
            fontSize: '0.9em',
            height: '45px',
        }),
        resumenAnualTd: (isActive) => ({
            borderBottom: '1px solid lightgrey',
            borderLeft: isActive ? '3px solid white' : '1px solid lightgrey',
            backgroundColor: 'white',
            textAlign: 'center',
            padding: '8px',
            fontSize: '0.9em'
        }),
        lineaRoja: {
            position: 'absolute',
            top: '50%',
            left: 0,
            width: '100%',
            borderBottom: '2px solid red',
            zIndex: 7,
        },
        celdaInvalida: {
            backgroundColor: 'var(--color-doble-hover)',
            cursor: 'not-allowed',
        },
    };

    const formatearPorcentaje = num => (num || num === 0) ? `${num.toFixed(2).replace('.', ',')}%` : '';

    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1; // Obtiene el índice del mes actual (1 = Enero, 12 = Diciembre)

    const mesMap = {
        'enero': 1,
        'febrero': 2,
        'marzo': 3,
        'abril': 4,
        'mayo': 5,
        'junio': 6,
        'julio': 7,
        'agosto': 8,
        'septiembre': 9,
        'octubre': 10,
        'noviembre': 11,
        'diciembre': 12
    };

    const estudiantesOrdenados = [...estudiantes].sort((a, b) => {
        if (a.numLista !== null && b.numLista === null) return -1;
        if (a.numLista === null && b.numLista !== null) return 1;
        return a.numLista - b.numLista;
    });

    return (
        <div className="container-fluid pt-0 bg-white">
            <div className="table-responsive" style={estilos.container}>
                <Table hover id="tablaAsistencia" className="table" style={{ marginBottom: 0, overflowX: 'hidden' }}>
                    <thead>
                        <tr>
                        <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-numero-lista">Número de lista</Tooltip>}
                  >
                            <th className="text-center" style={{ ...estilos.thSticky, ...estilos.colNumero }}>N°</th>
              
                  </OverlayTrigger>





                            <th className="text-center" style={{ ...estilos.thSticky, ...estilos.colEstudiante }}>Estudiante</th>
                            {meses.map((mes, index) => (
                                <th key={index} className="text-center" style={{ ...estilos.thSticky, ...estilos.colMes }}>{mes.charAt(0).toUpperCase() + mes.slice(1)}</th>
                            ))}
                            <th className="text-center" style={{ ...estilos.thSticky, ...estilos.colResumen(isHeatmapActive), ...estilos.boldText }}> % Anual</th>
                        </tr>
                    </thead>
                    <tbody>
                        {estudiantesOrdenados.map(estudiante => (
                            <tr key={estudiante.id}>
                                <td className="text-center" style={{ ...estilos.td(false, estudiante.id, false, estudiante.retirado), ...estilos.textoNumero }}>
                                    {estudiante.numLista !== null ? (
                                        estudiante.numLista
                                    ) : estudiante.numMatricula !== null ? (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-${estudiante.id}`}>{estilosEstadoAdicionales.matriculadoSinNumeroLista.description}</Tooltip>}
                                        >
                                            <i className={`${estilosEstadoAdicionales.matriculadoSinNumeroLista.icon}`} style={{ color: estilosEstadoAdicionales.matriculadoSinNumeroLista.iconColor, fontSize: "20px" }}></i>
                                        </OverlayTrigger>
                                    ) : (
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id={`tooltip-${estudiante.id}`}>{estilosEstadoAdicionales.sinNumeroMatriculaYLista.description}</Tooltip>}
                                        >
                                            <i className={`${estilosEstadoAdicionales.sinNumeroMatriculaYLista.icon}`} style={{ color: estilosEstadoAdicionales.sinNumeroMatriculaYLista.iconColor, fontSize: "20px" }}></i>
                                        </OverlayTrigger>
                                    )}
                                    {estudiante.retirado && <div style={estilos.lineaRoja}></div>}
                                </td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        estudiante.retirado ? (
                                            <Tooltip id={`tooltip-retirado-${estudiante.id}`}>
                                                Estudiante retirado
                                            </Tooltip>
                                        ) : <></>
                                    }
                                >
                                    <td className="d-flex align-items-center gap-3" style={{ ...estilos.td(false, estudiante.id, false, estudiante.retirado), ...estilos.colEstudiante, ...estilos.textoEstudiante }}>
                                        {/* <img src={defaultAvatar} alt="Avatar" style={estilos.tdImage} /> */}
                                        <Avatar run={estudiante.run || estudiante.ipe} width={'30px'} height={'30px'} allowPreview />
                                        {estudiante.nombre}
                                        {estudiante.retirado && <div style={estilos.lineaRoja}></div>}
                                    </td>
                                </OverlayTrigger>
                                {meses.map((mes, index) => {
                                    const valor = estudiante.asistencias[mes] || 0;
                                    const mesIndex = mesMap[mes.toLowerCase()];
                                    const esFuturo = mesIndex > mesActual;
                                    const mostrarMapaCalor = isHeatmapActive && !esFuturo;
                                    const esAntesDeFechaMatricula = mesIndex < new Date(estudiante.fechaMatricula).getMonth() + 1;
                                    return (
                                   
                                            <td className="text-center" style={{ 
                                                ...estilos.td(mostrarMapaCalor, valor, false, estudiante.retirado), 
                                                ...(esAntesDeFechaMatricula ? estilos.celdaInvalida : {}) 
                                            }}>
                                                {esFuturo && valor === 0 ? '' : formatearPorcentaje(valor)}
                                                {estudiante.retirado && <div style={estilos.lineaRoja}></div>}
                                            </td>
    
                                    );
                                })}
                                <td className="text-center" style={estilos.td(isHeatmapActive, estudiante.asistencias.resumenAnual, true, estudiante.retirado)}>
                                    {formatearPorcentaje(estudiante.asistencias.resumenAnual)}
                                    {estudiante.retirado && <div style={estilos.lineaRoja}></div>}
                                </td>
                            </tr>
                        ))}
                        <tr style={estilos.trSticky}>
                            <td colSpan="2" className="text-center" style={{ ...estilos.resumenTd(false), ...estilos.boldText, borderRight: '1px solid lightgrey' }}>Mensual</td>
                            {meses.map((mes, index) => {
                                const mesIndex = mesMap[mes.toLowerCase()];
                                const esFuturo = mesIndex > mesActual;
                                return (
                                    <td key={mes} className="text-center" style={{ ...estilos.resumenTd(false), borderRight: 'none', borderLeft: 'none' }}>
                                        {esFuturo ? '' : formatearPorcentaje(cursoAsistencias[mes])}
                                    </td>
                                );
                            })}
                            <td className="text-center" style={{ ...estilos.resumenAnualTd(false), ...estilos.boldText, borderLeft: '1px solid lightgrey', borderRight: 'none' }}>
                                {formatearPorcentaje(cursoAsistencias.resumenAnual)}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <NotificacionToast mensaje={mensajeToast} mostrar={mostrarToast} setMostrar={setMostrarToast} />
        </div>
    );
};

export default TablaPorcentajeAsistenciaAnualPorEstudiante;
