import {applyMiddleware, combineReducers, compose, createStore,} from 'redux';
import {PostsReducer, toggleMenu} from './reducers/PostsReducer';
import {thunk} from 'redux-thunk';
import {AuthReducer} from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import { refDataReducer } from './reducers/refDataReducer';
import { FuncionariosReducer } from './reducers/FuncionariosReducer';
import AvatarReducer from './reducers/AvatarReducer';

const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({
    sideMenu: toggleMenu,
    posts: PostsReducer,
    auth: AuthReducer,
    todoReducers,
    refDataReducer: refDataReducer,
    funcionariosReducer: FuncionariosReducer,
    avatar: AvatarReducer,

});

export const store = createStore(reducers, composeEnhancers(middleware));
