import React from "react";
import { useSelector } from "react-redux";
import ConfigHorarioCurso from "../../configHorarioCurso/ConfigHorarioCurso";

const TabGeneral = ({ cursoState }) => {

const rolesState = useSelector((state)=>state.auth.auth.user.roles) 

  const estilos = {
    container: {
      fontFamily: "'Poppins', sans-serif",
      color: "var(--color-texto-oscuro)",
    },
    infoContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "2rem",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    infoItem: {
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      fontSize: "1rem",
    },
    label: {
      fontWeight: "500",
    },
  };
// useEffect(()=>{
// console.log("redux",rolesState )
// },[rolesState])

  return (
    <div style={estilos.container}>
      <div style={estilos.infoContainer}>
        <div style={estilos.infoItem}>
          <span style={estilos.label}>Nivel de Enseñanza:</span> {cursoState?.gradoNombre || "No disponible"}
        </div>
        <div style={estilos.infoItem}>
          <span style={estilos.label}>Curso:</span> {cursoState?.cursoNombre || "No disponible"}
        </div>
        <div style={estilos.infoItem}>
          <span style={estilos.label}>Profesor Jefe:</span> {cursoState?.profJefeNombre || "No disponible"}
        </div>
        <div style={estilos.infoItem}>
          <span style={estilos.label}>Co Docentes:</span> {cursoState?.educDifNombre || "No disponible"}
        </div>
      </div>
      <ConfigHorarioCurso cursoId={cursoState?.cursoId} />
    </div>
  );
};

export default TabGeneral;
