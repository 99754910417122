import { formatError, login, runLogoutTimer, saveTokenInLocalStorage, signUp, } from '../../services/AuthService';
import { enterSchool } from '../../services/PostsService';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';
export const SET_ROLES = 'SET_ROLES';
export const SET_CURRENT_SCHOOL = 'SET_CURRENT_SCHOOL';


export function signupAction(email, password, navigate) {

    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.timeout * 1000,
                    //history,
                );
                dispatch(confirmedSignupAction(response.data));
                navigate('/dashboard');
                //history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function Logout(navigate) {
    // Eliminar todos los elementos relevantes del localStorage
    localStorage.removeItem('userDetails');
    localStorage.removeItem('currentRol');
    localStorage.removeItem('currentSchool');
    localStorage.removeItem('currentRolData'); 
    localStorage.removeItem('configTablaNotas'); 
    localStorage.removeItem('activeSchools');
    localStorage.removeItem('isSeguro');
    localStorage.removeItem('currentPeriodo');
    localStorage.removeItem('periodos');

    
    // Redirigir al usuario a la página de login
    navigate('/login');

    // Retornar la acción de logout para el reducer
    return {
        type: LOGOUT_ACTION,
    };
}


// export function loginAction(username, password, navigate) {
//     return (dispatch) => {
//         login(username, password)
//             .then((response) => {
//                 saveTokenInLocalStorage(response.data);
//                 runLogoutTimer(
//                     dispatch,
//                     response.data.timeout * 1000,
//                     navigate,
//                 );
//                  // Obtener el nombrePrimario de la respuesta
//                 const nombrePrimario = response.data.user.persona[0].nombrePrimario;
//                 // Guardar el nombrePrimario en el localStorage para usarlo en el header
//                 localStorage.setItem("nombrePrimario", nombrePrimario);
//                 dispatch(loginConfirmedAction(response.data));
//                 navigate(response.data);
//             })
//             .catch((error) => {
//                 const errorMessage = formatError(error.response?.data ?? error);
//                 dispatch(loginFailedAction(errorMessage));
//             });
//     };
// }





export function loginAction(username, password, navigate) {
    return (dispatch) => {
        login(username, password)
        .then(async (response) => {
            // console.log("Response data completa:", response.data);

            saveTokenInLocalStorage(response.data); // Guardar el token
            runLogoutTimer(dispatch, response.data.timeout * 1000, navigate);

            dispatch(loginConfirmedAction(response.data));
            navigate(response.data);
        })
        .catch((error) => {
            const errorMessage = formatError(error.response?.data ?? error);
            dispatch(loginFailedAction(errorMessage));
            navigate(null)
        });
    };
}

export function loadPageAction(data) {
    return loginConfirmedAction(data)
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export const navtoggle = () => {
    return {
        type: 'NAVTOGGLE',
    };
};

const hasRole = (roles, rolId) => {
    return roles.filter(r => r.rol.id === rolId).length
}

export function navigateHomePage(user, navigate) {
    if (user?.roles?.length > 0) {
        const permitidos = user.roles.filter(r => r.rol.id === 1 || r.rol.id === 5)
        
        
        
        //  console.log('Permitidos: ',permitidos)
        // localStorage.setItem('userRoles', JSON.stringify(permitidos))


        if(permitidos.length > 0) {
            let page = ''
            let colegios = []
            permitidos.forEach(r => {
                if(!colegios[r.colegio.id]) {
                    colegios[r.colegio.id] = r.colegio
                }
            })
            colegios = Object.values(colegios)
            if(colegios.length > 1) {
                localStorage.setItem('activeSchools', JSON.stringify(colegios))
                page = '/colegio-rol'
            }else {
                localStorage.setItem('currentSchool', JSON.stringify(colegios[0]))
                localStorage.setItem('currentRol', JSON.stringify(permitidos[0]))

                enterSchool();
                if(hasRole(permitidos, 1)) {
                    // page = '/dashboard'
                    page = '/horario-vista'
                }else if(hasRole(permitidos, 5)) {
                    page = '/colegio-admin'
                }
            }
            navigate(page)
            return permitidos
        }else {
            return -1;
        }
    }else {
        return 0;
    }
}


export const setRoles = (roles) => {
    return {
        type: 'SET_ROLES',
        payload: roles,
    };
};

export const setCurrentSchool = (school) => {
    return {
        type: 'SET_CURRENT_SCHOOL',
        payload: school,
    };
}