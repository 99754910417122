import React from "react";
import InConstruction from "../../../../components/construction/InConstruction";
import { Col } from "react-bootstrap";
import SelectSingle from "../../../../components/inputs/SelectSingle";
import { useDispatch } from "react-redux";
import TextInput from "../../../../components/inputs/TextInput";

function TabDirectivo({ tabDirectivo, setTabDirectivo }) {
  const dispatch = useDispatch();

  const [listaFuncionesDirectivo, setListaFuncionesDirectivo] = React.useState(
    []
  );

  return (
    // <InConstruction children={'Tab Directivo'} />

    <article
      className="d-flex flex-column align-items-center py-2 flex-wrap"
      style={{ height: "75dvh" }}
    >
      <div className="d-flex w-100 flex-row flex-wrap  ">
        <Col className="col-12 col-md-2">
          <SelectSingle
            controlId="funcionPrimaria"
            placeholder="Función Primaria"
            elements={listaFuncionesDirectivo.map((funcion) => ({
              id: funcion.id,
              name: funcion.funcionNombre,
            }))}
            idKey="id"
            labelKey="name"
            selectedElement={tabDirectivo.funcionPrimaria}
            onSelect={(funcion) => {
              setTabDirectivo((prev) => ({
                ...prev,
                funcionPrimaria: funcion?.id,
              }));
            }}
            style={{
              border: "1px solid var(--color-acento)",
              color: "var(--color-texto-oscuro)",
            }}
          />
        </Col>

        {/* Función Secundaria */}
        <Col className="col-12 col-md-2">
          <SelectSingle
            controlId="funcionSecundaria"
            placeholder="Función Secundaria"
            elements={listaFuncionesDirectivo.map((funcion) => ({
              id: funcion.id,
              name: funcion.funcionNombre,
            }))}
            idKey="id"
            labelKey="name"
            selectedElement={tabDirectivo.funcionSecundaria}
            onSelect={(funcion) => {
              setTabDirectivo((prev) => ({
                ...prev,
                funcionSecundaria: funcion?.id,
              }));
            }}
            style={{
              border: "1px solid var(--color-acento)",
              color: "var(--color-texto-oscuro)",
            }}
          />
        </Col>
        <Col className="col-12 col-md-2">
          <TextInput
            value={tabDirectivo.horasDirectivas}
            label={"Horas Directivas"}
            placeholder="Horas Directivas"
            onChange={(e) =>
              setTabDirectivo((prev) => ({
                ...prev,
                horasDirectivas: e.target.value,
              }))
            }
            style={{
              border: "1px solid var(--color-acento)",
              color: "var(--color-texto-oscuro)",
            }}
            type="numeric"
            minValue={0}
            maxValue={44}
          />
        </Col>
        <Col className="col-12 col-md-2">
          <TextInput
            value={tabDirectivo.horasTecnicoPedagogicas}
            label={"Horas Técnico Pedagógicas"}
            placeholder="Horas Técnico Pedagógicas"
            onChange={(e) =>
              setTabDirectivo((prev) => ({
                ...prev,
                horasTecnicoPedagogicas: e.target.value,
              }))
            }
            style={{
              border: "1px solid var(--color-acento)",
              color: "var(--color-texto-oscuro)",
            }}
            type="numeric"
            minValue={0}
            maxValue={44}
          />
        </Col>
        <Col className="col-12 col-md-2">
          <TextInput
            value={tabDirectivo.horasAula}
            label={"Horas Aula"}
            placeholder="Horas Aula"
            onChange={(e) =>
              setTabDirectivo((prev) => ({
                ...prev,
                horasAula: e.target.value,
              }))
            }
            style={{
              border: "1px solid var(--color-acento)",
              color: "var(--color-texto-oscuro)",
            }}
            type="numeric"
            minValue={0}
            maxValue={44}
          />
        </Col>
      </div>
    </article>
  );
}

export default TabDirectivo;
