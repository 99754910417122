import React from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

const ModalConfirmacionCambioBloque = ({
  mostrarModal,
  setMostrarModal,
  confirmarCambioBloque,
  bloqueExistente,
  nuevoBloque
}) => {

  // console.log("Modal - mostrarModal:", mostrarModal);
  // console.log("Modal - bloqueExistente:", bloqueExistente);
  // console.log("Modal - nuevoBloque:", nuevoBloque);

  const estilosModal = {
    fontFamily: 'Poppins',
    color: 'var(--color-texto-oscuro)'
  };

  const estiloNegrita = {
    fontWeight: '500'
  };

  const estiloTabla = {
    color: 'var(--color-texto-oscuro)',
    borderColor: 'var(--color-texto-oscuro)',
    marginBottom: "0",
  };

  const formatText = (text) => {
    if (!text) return 'Sin Docente';
    return text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  };

  return (
    <Modal centered show={mostrarModal} onHide={() => setMostrarModal(false)} style={estilosModal}>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center">
          Confirmar Cambios
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div style={{ marginBottom: '1rem' }}>
          El bloque seleccionado ya tiene datos asignados: <br />
          ¿Desea reemplazar estos datos con los nuevos valores?
        </div>
        <Table bordered style={estiloTabla}>
          <thead>
            <tr>
              <th style={estiloTabla}>Datos</th>
              <th style={estiloTabla}>Actual</th>
              <th style={estiloTabla}>Nuevo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ ...estiloNegrita, ...estiloTabla }}>Asignatura</td>
              <td style={estiloTabla}>{bloqueExistente?.asignaturaNombre || 'Sin Asignatura'}</td>
              <td style={estiloTabla}>{nuevoBloque?.asignaturaNombre || 'Sin Asignatura'}</td>
            </tr>
            <tr>
              <td style={{ ...estiloNegrita, ...estiloTabla }}>Docente</td>
              <td style={estiloTabla}>{bloqueExistente?.docenteNombre || 'Sin Docente'}</td>
              <td style={estiloTabla}>{formatText(nuevoBloque?.docenteNombre) || 'Sin Docente'}</td>
            </tr>
            <tr>
              <td style={{ ...estiloNegrita, ...estiloTabla }}>Sala</td>
              <td style={estiloTabla}>{bloqueExistente?.salaNombre || 'Sin Sala'}</td>
              <td style={estiloTabla}>{nuevoBloque?.salaNombre || 'Sin Sala'}</td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button variant="outline-primary" onClick={() => setMostrarModal(false)}>
          Cancelar
        </Button>
        <Button variant="outline-primary" onClick={() => {
          console.log("Modal - Confirmar cambio de bloque");
          confirmarCambioBloque();
          setMostrarModal(false);
        }}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmacionCambioBloque;
