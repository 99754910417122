import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, FormControl, Nav, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FaBook } from 'react-icons/fa';
import ButtonMultifuncional from "../buttonpersonalizado/ButtonMultifuncional";
import ButtonSwitch from "../buttonpersonalizado/ButtonSwitch";
import ModalAutenticacion from "../confirmarFirma/ModalAutenticacion";
import DesplegableConBusqueda from "../desplegableConBusqueda/DesplegableConBusqueda";
import NotificacionToast from "../notificacion/NotificacionToast";
import Title from "../titles/Title";

const NavCalificaciones = ({
  cursosFiltrados = [],
  asignaturasFiltradas = [],
  periodosFiltrados = [],
  unidadesFiltradas = [], 
  setCursoSeleccionado,
  setAsignaturaSeleccionada,
  setPeriodoSeleccionado,
  cursoSeleccionado,
  asignaturaSeleccionada,
  periodoSeleccionado,
  tablaId,
  isHeatmapActive,
  setIsHeatmapActive,
  mostrarAcumulativas,
  setMostrarAcumulativas,
  mes,
  confSumAcumulativa = false,
  setFiltroBusqueda,
  confNotasSum,
  confGlobal,
  blockInputs,
  setBlockInputs,
  onConfigurationUpdate,
  hasPermission,
  mostrarBotonBloquear,
  mostrarTabs,
  tablaConceptos,
  fetchNotasPorUnidad // Nueva función para manejar el cambio de unidad
}) => {
  const [mostrarToast, setMostrarToast] = useState(false);
  const [mensajeToast, setMensajeToast] = useState("");
  const [mostrarModalAutenticacion, setMostrarModalAutenticacion] = useState(false);
  const [codigoAutenticacion, setCodigoAutenticacion] = useState(new Array(6).fill(""));
  const [configGlobalLocal, setConfigGlobalLocal] = useState(confGlobal);
  const [actualizacionDesdeConfigTabla, setActualizacionDesdeConfigTabla] = useState(false);

  // Estado para controlar la pestaña activa
  const [pestañaActiva, setPestañaActiva] = useState(unidadesFiltradas.length > 0 ? unidadesFiltradas[0].unidadNombre : "");

  

  const actualizarSeleccionesDesdeConfig = useCallback(() => {
    const cursoEncontrado = cursosFiltrados.find((c) => c.cursoId === configGlobalLocal.cursoId);
    const asignaturaEncontrada = asignaturasFiltradas.find((a) => a.id === configGlobalLocal.asignaturaId);
    const periodoEncontrado = periodosFiltrados.find((p) => p.id === configGlobalLocal.periodoEscolarId);

    if (cursoEncontrado) setCursoSeleccionado(cursoEncontrado);
    if (asignaturaEncontrada) setAsignaturaSeleccionada(asignaturaEncontrada);
    if (periodoEncontrado) setPeriodoSeleccionado(periodoEncontrado);
  }, [configGlobalLocal, cursosFiltrados, asignaturasFiltradas, periodosFiltrados, setCursoSeleccionado, setAsignaturaSeleccionada, setPeriodoSeleccionado]);

  const handleConfigurationUpdate = (newConfig) => {
    setConfigGlobalLocal(newConfig.confGlobal);
    setActualizacionDesdeConfigTabla(true);
    if (onConfigurationUpdate) {
      onConfigurationUpdate(newConfig);
    }
  };

  const manejarSeleccionCurso = (curso) => {
    if (cursoSeleccionado?.cursoId === curso.cursoId) return;
    
    setBlockInputs(true);
    setCursoSeleccionado(curso);
    setAsignaturaSeleccionada(null);
    setConfigGlobalLocal((prev) => ({
      ...prev,
      cursoId: curso.cursoId,
      cursoNombre: curso.cursoNombre,
      asignaturaId: null,
      asignaturaNombre: null,
    }));
  };

  const manejarSeleccionAsignatura = (asignatura) => {
    if (asignaturaSeleccionada?.id === asignatura.id) return;
    
    setBlockInputs(true);
    setAsignaturaSeleccionada(asignatura);
    setConfigGlobalLocal((prev) => ({
      ...prev,
      asignaturaId: asignatura.id,
      asignaturaNombre: asignatura.nombre,
    }));
  };

  const manejarSeleccionPeriodo = (periodo) => {
    if (periodoSeleccionado?.id === periodo.id) return;
    
    setBlockInputs(true);
    setPeriodoSeleccionado(periodo);
    setConfigGlobalLocal((prev) => ({
      ...prev,
      periodoEscolarId: periodo.id,
      periodoEscolarNombre: periodo.nombre,
    }));
  };

  const estilos = {
    rowContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem",
      backgroundColor: "#fff",
      position: "relative",
    },
    tabActive: {
      backgroundColor: "var(--color-acento)",
      color: "#fff",
      border: "1px solid var(--color-gris)",
      borderBottom: "none",
      borderRadius: "0.25rem 0.25rem 0 0",
    },
    tabInactive: {
      backgroundColor: "var(--color-gris-claro)",  
      color: "var(--color-texto-oscuro)",
      border: "1px solid var(--color-gris)", 
      borderBottom: "none",
      borderRadius: "0.25rem 0.25rem 0 0",
    },
    navTabs: {
      borderBottom: "1px solid #dee2e6",
    },
    hoverStyle: {
      color: "var(--color-acento)", 
    },
  };

  const manejarCancelarAutenticacion = () => {
    setMostrarModalAutenticacion(false);
  };

  const manejarFirmar = () => {
    setBlockInputs(false);
    setMostrarModalAutenticacion(false);
  };

  const manejarEditar = () => {
    if (blockInputs) {
      setMostrarModalAutenticacion(true);
    } else {
      setBlockInputs(true);
    }
  };

  const manejarCambioCodigo = (valor, index) => {
    const nuevoCodigo = [...codigoAutenticacion];
    nuevoCodigo[index] = valor;
    setCodigoAutenticacion(nuevoCodigo);

    if (valor.length === 1 && index < 5) {
      document.getElementById(`codigo-${index + 1}`).focus();
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Ver acumulativas
    </Tooltip>
  );

  useEffect(() => {
    setConfigGlobalLocal(confGlobal);
  }, [confGlobal]);

  useEffect(() => {
    if (actualizacionDesdeConfigTabla) {
      actualizarSeleccionesDesdeConfig();
      setActualizacionDesdeConfigTabla(false);
    }
  }, [configGlobalLocal, actualizacionDesdeConfigTabla, actualizarSeleccionesDesdeConfig]);

  const manejarCambioTab = (unidadId, unidadNombre) => {
    if (pestañaActiva === unidadNombre) {
      return;
    }
    setPestañaActiva(unidadNombre);
    fetchNotasPorUnidad(unidadId);
  }

  // Si las unidades cambian, actualizamos la pestaña activa a la primera unidad
  // useEffect(() => {
  //   if (unidadesFiltradas.length > 0) {
  //     setPestañaActiva(unidadesFiltradas[0].unidadNombre);
  //   }
  // }, [unidadesFiltradas]);

  useEffect(() => {
    // Establecer la unidad seleccionada en función de confGlobal cuando los datos cambian
    if (confGlobal.unidadId) {
        const unidadEncontrada = unidadesFiltradas.find(unidad => unidad.unidadId === confGlobal.unidadId);
        if (unidadEncontrada) {
            setPestañaActiva(unidadEncontrada.unidadNombre);  // Establecemos la pestaña activa con el nombre de la unidad seleccionada
        }
    }
}, [confGlobal, unidadesFiltradas]);

  return (
    <>
      {/* Primera fila: Desplegables y botones */}
      <Row style={estilos.rowContainer} className="m-0 px-2 pt-5 pb-0">
        <Col xs="auto" className="d-flex align-items-center me-1">
          <DesplegableConBusqueda
            elementos={cursosFiltrados}
            placeholder="Curso"
            onSelect={manejarSeleccionCurso}
            selectedElement={configGlobalLocal.cursoId}
            displayProperty="cursoNombre"
            idProperty="cursoId"
          />
        </Col>
        <Col xs="auto" className="d-flex align-items-center me-1">
          <DesplegableConBusqueda
            elementos={asignaturasFiltradas}
            placeholder="Asignatura"
            onSelect={manejarSeleccionAsignatura}
            selectedElement={configGlobalLocal.asignaturaId}
            displayProperty="nombre"
            idProperty="id"
            key={cursoSeleccionado?.cursoId}
          />
        </Col>
        <Col xs="auto" className="d-flex align-items-center me-1">
          <DesplegableConBusqueda
            elementos={periodosFiltrados}
            placeholder="Periodo"
            onSelect={manejarSeleccionPeriodo}
            selectedElement={configGlobalLocal.periodoEscolarId}
            displayProperty="nombre"
            idProperty="id"
          />
        </Col>

        <Col className="">
          <Title text="Hoja de Calificaciones" />
        </Col>

        {mostrarBotonBloquear && (
          <Col xs="auto" className="ms-auto d-flex align-items-center">
            {confSumAcumulativa  && (
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <div className="d-flex align-items-center">
                  <ButtonSwitch id="mostrar-acumulativas" label="" checked={mostrarAcumulativas} onChange={() => setMostrarAcumulativas(!mostrarAcumulativas)} />
                </div>
              </OverlayTrigger>
            )}
            <Col xs="auto" className="d-flex align-items-center me-1">
              <Button
                variant={blockInputs ? "color-acento" : "color-rojo"}
                onClick={manejarEditar}
                style={{
                  width: "150px",
                  display: hasPermission ? "block" : "none",
                }}
              >
                {blockInputs ? "Desbloquear" : "Bloquear"}
              </Button>
            </Col>
            <ButtonMultifuncional
              tablaId={tablaId}
              isHeatmapActive={isHeatmapActive}
              setIsHeatmapActive={setIsHeatmapActive}
              setMensajeToast={setMensajeToast}
              setMostrarToast={setMostrarToast}
              vista="calificaciones-curso"
              mes={mes}
              cursoId={configGlobalLocal.cursoId}
              asignaturaId={configGlobalLocal.asignaturaId}
              periodoEscolarId={configGlobalLocal.periodoEscolarId}
              confNotasSum={confNotasSum}
              confGlobal={configGlobalLocal}
              onConfigurationUpdate={handleConfigurationUpdate}
              hasPermission={hasPermission}
              tablaConceptos={tablaConceptos}
            />
          </Col>
        )}
      </Row>

      {/* Segunda fila: Búsqueda, Tabs e indicador de concepto */}
      {mostrarBotonBloquear && (
        <Row style={estilos.rowContainer} className="m-0 px-2 mt-0 py-1 ">
          <Col xs="6" className="d-flex align-items-center me-1">
            {/* Campo de búsqueda y asignatura por concepto juntos en la misma columna */}
            <FormControl 
              type="text" 
              placeholder="Buscar estudiante" 
              onChange={(e) => setFiltroBusqueda(e.target.value)} 
              className="me-3"
              style={{ width: "55%" }}
            />
            {tablaConceptos.length > 0 && (
              <div className="d-flex align-items-center">
                <FaBook style={{ color: "var(--color-acento)", marginRight: "8px" }} />
                <span>Calificación por concepto</span>
              </div>
            )}
          </Col>

          {mostrarTabs && unidadesFiltradas.length > 0 && (
            <Col xs="auto" className="d-flex align-items-center">
              <Nav variant="tabs" style={estilos.navTabs}>
                {unidadesFiltradas.map((unidad) => (
                  <Nav.Item key={unidad.unidadId}>
                    <Nav.Link
                      eventKey={unidad.unidadNombre}
                      style={pestañaActiva === unidad.unidadNombre ? estilos.tabActive : estilos.tabInactive}
                      onClick={() => manejarCambioTab(unidad.unidadId, unidad.unidadNombre)} // Llamar al manejarCambioTab al hacer clic
                      onMouseEnter={(e) => pestañaActiva !== unidad.unidadNombre && (e.target.style.color = estilos.hoverStyle.color)}
                      onMouseLeave={(e) => pestañaActiva !== unidad.unidadNombre && (e.target.style.color = estilos.tabInactive.color)}
                    >
                      {unidad.unidadNombre}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
          )}
        </Row>
      )}

      <NotificacionToast mensaje={mensajeToast} mostrar={mostrarToast} setMostrar={setMostrarToast} />

      <ModalAutenticacion
        show={mostrarModalAutenticacion}
        onHide={manejarCancelarAutenticacion}
        onConfirm={manejarFirmar}
        codigoAutenticacion={codigoAutenticacion}
        manejarCambioCodigo={manejarCambioCodigo}
      />
    </>
  );
};

export default NavCalificaciones;
