import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getListadoCursos } from '../services/PostsService';

const useGetListadoCursos = (forceCheckIsSeguro = false) => {
    const [cursos, setCursos] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();

    const fetchCursos = useCallback(async () => {
        setCargando(true);
        try {
            const isImportarCalificacionesView = location.pathname.includes('/importar-calificaciones');
            const shouldCheckIsSeguro = forceCheckIsSeguro || isImportarCalificacionesView;
            
            let params = {};
            if (shouldCheckIsSeguro) {
                const isSeguro = JSON.parse(localStorage.getItem('isSeguro') || 'false');
                if (isSeguro) {
                    params.forDocente = true;
                }
            }
            const response = await getListadoCursos(params);
            setCursos(response.data);
        } catch (err) {
            console.error("Error al obtener los cursos:", err);
            setError('Error al obtener los cursos: ' + err.message);
        } finally {
            setCargando(false);
        }
    }, [location, forceCheckIsSeguro]);

    useEffect(() => {
        fetchCursos();
    }, [fetchCursos]);

    return { cursos, cargando, error };
};

export default useGetListadoCursos;