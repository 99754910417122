import React, { useState } from 'react';
import CustomTooltip from '../tooltip/Tooltip';
import { Form, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function PasswordInput({
    controlId,
    label,
    name,
    value,
    onChange,
    required,
    disabled,
    placeholder,
    onBlur,
    onInput,
    isInvalid,
    maxLength,
    onPaste,
    tooltipMessage,
    style,
    hideLabel,
    autoComplete,
}) {
    const [showPassword, setShowPassword] = useState(false);

    // Función para alternar la visibilidad de la contraseña
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Form.Group  controlId={controlId}>
            {required && !hideLabel ? (
                <CustomTooltip
                    tooltipMsg={
                        tooltipMessage ? (
                            <span>{tooltipMessage}</span>
                        ) : (
                            <span>Dato Requerido</span>
                        )
                    }
                >
                    <Form.Label>
                        {label}{" "}
                        {required && tooltipMessage && (
                            <span className="text-danger">**</span>
                        )}
                        {required && !tooltipMessage && (
                            <span className="text-danger">*</span>
                        )}
                    </Form.Label>
                </CustomTooltip>
            ) : (
                <Form.Label>{label}</Form.Label>
            )}

            <InputGroup>
                <Form.Control
                    type={showPassword ? "text" : "password"} // Alterna entre 'text' y 'password'
                    name={name}
                    value={value}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onInput={onInput}
                    isInvalid={isInvalid}
                    maxLength={maxLength}
                    onPaste={onPaste}
                    style={style}
                    autoComplete={autoComplete}
                />
                <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                    {!showPassword ? <FaEyeSlash /> : <FaEye />}
                </InputGroup.Text>
            </InputGroup>

            {isInvalid && (
                <Form.Control.Feedback type="invalid">
                    {isInvalid}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
}

export default PasswordInput;
