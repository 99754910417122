import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

const Guardando = ({ visible }) => (
    <Modal show={visible} backdrop="static" keyboard={false} centered>
        <Modal.Body style={styles.modalBackground}>
            <div style={styles.activityIndicatorWrapper}>
                <Spinner animation="border" variant="primary" />
                <span style={styles.textoGuardando}>Guardando...</span>
            </div>
        </Modal.Body>
    </Modal>
);

const styles = {
    modalBackground: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width:'auto'
        // backgroundColor: '#00000040',
    },
    activityIndicatorWrapper: {
        backgroundColor: '#FFFFFF',
        height: '100px',
        // width: 'auto',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center ',
        gap: '15px',
        // padding: '15px',
    },
    textoGuardando: {
        fontSize: '14px',
    },
};

export default Guardando;
