import React from 'react';
import { Button } from 'react-bootstrap';
import { IoIosArrowBack } from 'react-icons/io';
import "../../../assets/css/buttonsFlechas.css";

const ButtonFlechaAtras = ({ onClick, className, variant, ...props }) => {
  return (
    <Button
      onClick={onClick}
      className={`boton-flecha btn-color-acento ${className}`}
      {...props}
    >
      <IoIosArrowBack />
    </Button>
  );
};

export default ButtonFlechaAtras;
