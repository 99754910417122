import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchoolInfo } from "../services/PostsService";
import { setCurrentSchool } from "../store/actions/AuthActions";

const useCheckCurrentSchool = () => {
  const currentSchool = useSelector((state) => state.auth.currentSchool);
  const dispatch = useDispatch();
  const rbd = JSON.parse(localStorage.getItem("currentSchool")).rbd;

  useEffect(() => {
    if (!currentSchool) {
      //   dispatch(fetchRolesFromAPI());
      //   console.log('Acción para llamar a la API y obtener los roles')
      getSchoolInfo(rbd)
        .then((response) => {
          dispatch(setCurrentSchool(response.data));
        })
        .catch((error) => {
          // console.log("Error al obtener los roles:", error);
        });
    }
  }, [currentSchool, dispatch, rbd]);
};

export default useCheckCurrentSchool;
