import React, { useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { getLogo } from '../../../services/PostsService';

const SchoolCard = ({school}) => {
    const [logo, setLogo] = useState(null);

    useEffect(() => {
        getLogo(school.id).then(r => {
            if(r?.data) {
                const blob = new Blob([r.data], { type: "image/png" });
                const url = URL.createObjectURL( blob );

                setLogo(url)
            }
        })
    }, [])

    return (
        <div className="mb-3">
            <div className="row g-0">
                {/* Columna para la imagen del colegio */}
                <div className="col-md-4" style={{textAlign:'center'}}>
                    { logo ?
                        <img
                            src={logo}
                            width={100}
                            className="img-fluid rounded-start" // Clase para hacer la imagen responsive y con bordes redondeados
                            alt="School" // Texto alternativo de la imagen
                        />
                        :
                        <Spinner style={{margin:20}}/>
                    }
                </div>
                {/* Columna para los detalles del colegio */}
                <div className="col-md-8">
                    <div
                        className="card-body text-center"> {/* Contenedor del cuerpo de la tarjeta, centrado en texto */}
                        <h3 className="card-title">{school.colegioNombre}</h3> {/* Título con el nombre del colegio */}
                        <p className="card-text" style={{fontSize:'larger'}}>Ciclo escolar {school.periodoActual.anio}</p> {/* Párrafo con el ciclo escolar */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SchoolCard;
