import { faDownload, faSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "qrcode.react";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ReactDOMServer from "react-dom/server";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "../../../../src/assets/css/HeaderNav.css";
import { ThemeContext } from "../../../context/ThemeContext";
import { store } from "../../../store/store";
import Avatar from "../../components/avatar/Avatar";
import Title from "../../components/titles/Title";
import Logout from "../nav/Logout";
import MegaMenu from "./MegaMenu";

const reducer = (estadoAnterior, estadoActualizado) => ({
  ...estadoAnterior,
  ...estadoActualizado,
});

const estadoInicial = {
  activo: "",
  submenuActivo: "",
};

const HeaderNavSidebar = () => {
  const {
    background,
    changeBackground,
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    openMenuToggle,
  } = useContext(ThemeContext);

  const [fechaHoraActual, setFechaHoraActual] = useState("");
  const [esTabletOMovil, setEsTabletOMovil] = useState(false);
  const estado = store.getState();
  const [listaMenu, setListaMenu] = useState([]);
  const [estadoSidebar, setEstadoSidebar] = useReducer(reducer, estadoInicial);
  const ubicacion = useLocation();
  const currentSchool = JSON.parse(localStorage.getItem("currentSchool"));
  const periodo = currentSchool
    ? currentSchool.periodoActual.anio
    : new Date().getFullYear();
  const persona = estado.auth?.auth?.user?.persona.filter(
    (p) => p.periodo.id === periodo
  )[0];
  const nombrePrimario = persona?.nombrePrimario;

  const submenuTimeoutRef = useRef(null);

  const [showQR, setShowQR] = useState(false);
  const handleShowQR = () => setShowQR(true);
  const handleCloseQR = () => setShowQR(false);

  const ModalFDContent = () => (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        gap: "1rem",
        backgroundColor: "var(--color-gris-claro)",
        padding: "1rem 1rem",
        borderRadius: "10px",
        borderColor: "var(--color-gris)",
        borderWidth: "1px",
        borderStyle: "solid",
      }}
    >
      <section style={{ width: "100%" }}>
        <Title text={"Paso 1"} fontSize="18px" />
        <iframe
          width={"500"}
          height="400"
          src="https://www.youtube.com/embed/GBbPjE6FgVw?si=xCUeHNRYZWBhfz9H"
          title="Paso 1 - Activa tu Clave Única"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          style={{ borderRadius: "10px" }}
        ></iframe>
        <p>Obtén tu Clave Única</p>
        <p style={{ fontSize: "14px" }}>
          (Si ya tiene Clave Única, puede continuar con el Paso 2)
        </p>
      </section>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Title text={"Paso 2"} fontSize="18px" />
        <div>
          <iframe
            width={"500"}
            height="400"
            src="https://www.youtube.com/embed/avXI3UNIMmo?si=ARflOHlNqJArvXD4"
            title="Paso 2 - Descarga una App Autenticador"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{ borderRadius: "10px" }}
          ></iframe>

          <p>Instala una app Autenticador</p>
          <p>Ve a PlayStore (Android) o App Store (iOS)</p>
        </div>
      </section>
      <section style={{ width: "100%" }}>
        <Title text={"Paso 3"} fontSize="18px" />
        <div>
          <iframe
            width={"500"}
            height="400"
            src="https://www.youtube.com/embed/7oF0Nogqin0?si=OOhi9Ug4ZlUAh2iC"
            title="Paso 3 - Vincula la App"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{ borderRadius: "10px" }}
          ></iframe>

          <p>Vincula tu Clave Dinámica</p>
          <Button
            variant="color-acento-outline"
            target="_blank"
            href="https://tramites.mineduc.cl/"
          >
            Ir al trámite
          </Button>
        </div>
      </section>
    </div>
  );

  const ModalFDContentToString = ReactDOMServer.renderToString(
    <ModalFDContent />
  );

  useEffect(() => {
    const obtenerFechaHoraActual = () => {
      const ahora = new Date();
    
      const diaDelMes = String(ahora.getDate()).padStart(2, '0');
      const mes = String(ahora.getMonth() + 1).padStart(2, '0'); // Los meses son 0-based, por eso sumamos 1
      const año = ahora.getFullYear();
    
      // Formato: dd/MM/yyyy
      return `${diaDelMes}/${mes}/${año}`;
    };
    

    setFechaHoraActual(obtenerFechaHoraActual());

    const manejarRedimensionamiento = () => {
      setEsTabletOMovil(window.innerWidth <= 1400);
    };

    manejarRedimensionamiento();
    window.addEventListener("resize", manejarRedimensionamiento);

    return () => {
      window.removeEventListener("resize", manejarRedimensionamiento);
    };
  }, []);

  const manejarMenuActivo = (estado) => {
    if (submenuTimeoutRef.current) {
      clearTimeout(submenuTimeoutRef.current);
    }
    setEstadoSidebar({ activo: estado });
  };

  const manejarSubmenuActivo = (estado) => {
    setEstadoSidebar({ submenuActivo: estado });
  };

  const cerrarSubmenuConRetraso = () => {
    submenuTimeoutRef.current = setTimeout(() => {
      setEstadoSidebar({ activo: "", submenuActivo: "" });
    }, 225);
  };

  const cancelarCierreSubmenu = () => {
    if (submenuTimeoutRef.current) {
      clearTimeout(submenuTimeoutRef.current);
    }
  };

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  useEffect(() => {
    const menu = MegaMenu(estado.auth?.auth?.user);
    if (menu && Array.isArray(menu)) {
      menu.forEach((data) => {
        if (data.content) {
          data.content.forEach((item) => {
            if (path === item.to) {
              setEstadoSidebar({ activo: data.title });
            }
            if (item.content) {
              item.content.forEach((ele) => {
                if (path === ele.to) {
                  setEstadoSidebar({ submenuActivo: item.title });
                }
              });
            }
          });
        }
      });
      setListaMenu(menu);
    }
  }, [path, estado.auth]);

  const handleLinkClick = () => {
    cerrarSubmenuConRetraso();
  };

  const toggleHamburgerMenu = () => {
    const navbar = document.querySelector(".navbar-nav1");
    navbar.classList.toggle("hamburger-active");
  };

  const renderDropdownMenuHorizontal = (content) => {
    if (!content) return null;
    const isSectioned = content.some((submenu) => submenu.sectionTitle);
    return isSectioned ? (
      <div className="horizontal-dropdown">
        {content.map((submenu, subIndex) =>
          submenu.sectionTitle ? (
            <div key={subIndex} className="dropdown-section">
              <div className="dropdown-section-title">
                {submenu.sectionTitle}
              </div>
              <div className="dropdown-section-content">
                {submenu.items.map((item, itemIndex) => (
                  <Link
                    key={itemIndex}
                    to={item.to}
                    className={`dropdown-item ${
                      path === item.to ? "active" : ""
                    }`}
                    onClick={handleLinkClick}
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
          ) : (
            <Link
              key={subIndex}
              to={submenu.to}
              className={`dropdown-item ${path === submenu.to ? "active" : ""}`}
              onClick={handleLinkClick}
            >
              {submenu.title}
            </Link>
          )
        )}
      </div>
    ) : (
      content.map((submenu, subIndex) => (
        <Link
          key={subIndex}
          to={submenu.to}
          className={`dropdown-item ${path === submenu.to ? "active" : ""}`}
          onClick={handleLinkClick}
        >
          {submenu.title}
        </Link>
      ))
    );
  };

  const renderDropdownMenuVertical = (content) => {
    if (!content) return null;
    return (
      <div>
        {content.map((submenu, subIndex) => (
          <div key={subIndex}>
            {submenu.sectionTitle && (
              <div className="dropdown-section-title">
                {submenu.sectionTitle}
              </div>
            )}
            {submenu.items ? (
              submenu.items.map((item, itemIndex) => (
                <Link
                  key={itemIndex}
                  to={item.to}
                  className={`dropdown-item ${
                    path === item.to ? "active" : ""
                  }`}
                  onClick={handleLinkClick}
                >
                  {item.title}
                </Link>
              ))
            ) : (
              <Link
                to={submenu.to}
                className={`dropdown-item ${
                  path === submenu.to ? "active" : ""
                }`}
                onClick={handleLinkClick}
              >
                {submenu.title}
              </Link>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderLogo = () => {
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Sitio de prueba
      </Tooltip>
    );

    const renderDevTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Sitio en desarrollo
      </Tooltip>
    );

    const baseSvg = (additionalElements = null) => (
      <svg
        className="brand-title1"
        xmlns="http://www.w3.org/2000/svg"
        width="180"
        height="50"
      >
        <text
          x="20"
          y="35"
          fontFamily="Poppins, sans-serif"
          fontWeight="700"
          fontSize="35px"
          fill="var(--color-acento)"
          lineheight="27px"
        >
          LCD
        </text>
        <text
          x="33"
          y="45"
          fontFamily="Poppins, sans-serif"
          fontWeight="400"
          fontSize="10px"
          fill="var(--color-acento)"
          lineheight="18px"
        >
          Cambar
        </text>
        {additionalElements}
      </svg>
    );

    if (process.env.REACT_APP_ENV === "prod") {
      return baseSvg();
    } else if (process.env.REACT_APP_ENV === "qa") {
      return (
        <OverlayTrigger placement="bottom" overlay={renderTooltip}>
          {baseSvg(
            <>
              <line
                x1="22"
                y1="23"
                x2="87"
                y2="23"
                stroke="red"
                strokeWidth="10"
              />
              <text
                x="53"
                y="24"
                fontFamily="Poppins, sans-serif"
                fontWeight="700"
                fontSize="12px"
                fill="white"
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                DEMO
              </text>
            </>
          )}
        </OverlayTrigger>
      );
    } else {
      return (
        <OverlayTrigger placement="bottom" overlay={renderDevTooltip}>
          {baseSvg(
            // <>
            //   <line x1="22" y1="23" x2="87" y2="23" stroke="red" strokeWidth="10" />
            //   <text x="53" y="24" fontFamily="Poppins, sans-serif" fontWeight="700" fontSize="12px" fill="white" textAnchor="middle" alignmentBaseline="middle">
            //     DEMO
            //   </text>
            // </>

            <>
              <line
                x1="22"
                y1="23"
                x2="87"
                y2="23"
                stroke="red"
                strokeWidth="10"
              />
              <text
                x="53"
                y="24"
                fontFamily="Poppins, sans-serif"
                fontWeight="700"
                fontSize="12px"
                fill="white"
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                DEV
              </text>
            </>
          )}
        </OverlayTrigger>
      );
    }
  };

  return (
    <>
      <div className="combined-header-sidebar1">
        <div className="header1 sticky-header">
          <div className="header-content1">
            <Link to="/horario-vista" className="brand-logo1">
              <svg
                className="logo-abbr1"
                width="57"
                height="57"
                viewBox="0 0 57 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4_96)">
                  <path
                    d="M28.5 38.727L10.0549 29.7293V41.6065L28.501 49.7984L46.9469 41.6065V29.7283L28.5 38.727Z"
                    fill="var(--color-acento)"
                  />
                  <path
                    d="M57 21.1042L28.5001 7.20154L0 21.1042L28.5001 35.0069L50.2909 24.3771V36.7516H53.6344V22.746L57 21.1042Z"
                    fill="var(--color-acento)"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4_96">
                    <rect width="57" height="57" fill="var(--color-acento)" />
                  </clipPath>
                </defs>
              </svg>
              {renderLogo()}
            </Link>
            {esTabletOMovil && (
              <div className="hamburger-menu" onClick={toggleHamburgerMenu}>
                <div className="hamburger-line"></div>
                <div className="hamburger-line"></div>
                <div className="hamburger-line"></div>
              </div>
            )}
            <div className="header-middle1">
              <ul className="navbar-nav1">
                {Array.isArray(listaMenu) &&
                  listaMenu.map((data, index) => {
                    let menuClass = data.classsChange;
                    if (menuClass === "menu-title") {
                      return (
                        <li
                          className={`nav-label1 ${menuClass} ${data.extraclass}`}
                          key={index}
                        >
                          {data.title}
                        </li>
                      );
                    } else {
                      return (
                        <li
                          key={index}
                          className={`nav-item1 ${
                            estadoSidebar.submenuActivo === data.title
                              ? "active"
                              : ""
                          }`}
                          onMouseEnter={() => manejarSubmenuActivo(data.title)} // Mostrar el submenú al pasar el mouse
                          onMouseLeave={cerrarSubmenuConRetraso} // Retraso de 350ms al cerrar el submenú
                        >
                          <Link
                            to={data.to}
                            className={`nav-link1 ${
                              ubicacion.pathname === data.to ? "active" : ""
                            }`}
                            onMouseEnter={cancelarCierreSubmenu} // Cancelar el cierre del submenú si se vuelve a entrar
                          >
                            <div className="icon1">{data.iconStyle}</div>
                            <div>{data.title}</div>
                          </Link>
                          {Array.isArray(data.content) && (
                            <div
                              className={`dropdown-menu1 ${
                                estadoSidebar.submenuActivo === data.title
                                  ? "show"
                                  : ""
                              } ${
                                data.content.some(
                                  (submenu) => submenu.sectionTitle
                                )
                                  ? "dropdown-has-sections"
                                  : "dropdown-no-sections"
                              }`}
                            >
                              {esTabletOMovil
                                ? renderDropdownMenuVertical(data.content)
                                : renderDropdownMenuHorizontal(data.content)}
                            </div>
                          )}
                        </li>
                      );
                    }
                  })}
              </ul>
            </div>
            <div className="header-right1 d-flex align-items-center justify-content-end">
              <div className="d-flex align-items-center">
                <div className="nav-item1 d-flex flex-column align-items-end me-3">
                  <p className="m-0 text-dark fs-4">{nombrePrimario}</p>
                  <p className="text-dark m-0">{fechaHoraActual}</p>
                </div>
                <Dropdown as="li" className="nav-item1 header-profile1">
                  <Dropdown.Toggle
                    to={"#"}
                    className="nav-link i-false"
                    as="div"
                  >
                    {/* <img src={profile} width="35" alt="#" /> */}
                    <Avatar
                      width={"40px"}
                      height={"40px"}
                      run={persona.run || persona.ipe}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="end"
                    className="mt-3 dropdown-menu dropdown-menu-right"
                  >
                    <Link className="dropdown-item" to="profile">
                      <i className="feather icon-user"></i> Perfil
                    </Link>
                    <Link
                      className="dropdown-item"
                      onClick={(e) => {
                        e.preventDefault();
                        Swal.fire({
                          title: "Descarga Firma Digital",
                          html: ModalFDContentToString,
                          confirmButtonColor: "var(--color-acento)",
                          showConfirmButton: true,
                          confirmButtonText: "Entendido",
                          customClass: {
                            popup: "download-FD",
                            title: "custom-title-class",
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faSignature} /> Fima Digital
                    </Link>
                    {/* {process.env.REACT_APP_ENV === "prod" && ( */}
                      <span className="dropdown-item" onClick={handleShowQR}>
                        <FontAwesomeIcon icon={faDownload} /> Descargar App
                      </span>
                    {/* )} */}
                    <Logout />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        {esTabletOMovil && (
          <div className="navbar-nav1">
            <svg
              className="brand-title1"
              xmlns="http://www.w3.org/2000/svg"
              width="180"
              height="50"
            >
              <text
                x="20"
                y="35"
                fontFamily="Poppins, sans-serif"
                fontWeight="700"
                fontSize="35px"
                fill="#1e78ff"
                lineheight="27px"
              >
                LCD
              </text>
              <text
                x="33"
                y="45"
                fontFamily="Poppins, sans-serif"
                fontWeight="400"
                fontSize="10px"
                fill="#1e78ff"
                lineheight="18px"
              >
                Cambar
              </text>
            </svg>
          </div>
        )}
      </div>
      {/* {process.env.REACT_APP_ENV === "qa" && showQR && ( */}
      <Modal
        centered
        show={showQR}
        onHide={handleCloseQR}
        style={{ zIndex: "99999" }}
        backdropClassName="custom-backdrop"
    >
        <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center">
                Escanear para Descargar App
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
            {process.env.REACT_APP_ENV === "prod" ? (
                <>
                    <QRCode value="https://api.lcdcambar.cl/static/demoapp.apk" size={256} />
                    <p className="mt-3">
                        Escanea el código QR para descargar la aplicación
                    </p>
                </>
            ) : (
                <p className="mt-3">
                    En este ambiente no se muestra el QR. Si quieres acceder a la app, contacta al equipo.
                </p>
            )}
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
    </Modal>
       {/* )}   */}
       
    </>
  );
};

export default HeaderNavSidebar;
