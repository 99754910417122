// import moment from 'moment';
// import React, { useEffect, useRef, useState } from 'react';
// import { handleMouseEnter, handleMouseLeave } from '../../../util/util';

// const EventoDiario = ({ event }) => {
//   const refEvento = useRef(null);
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const esClase = event.type === 'Clases';
//   const esAlmuerzo = event.type === 'Almuerzo';
//   const esRecreo = event.type === 'Recreo';
//   const colorXDefectoRecreo = 'white';
//   const colorXDefectoAlmuerzo = 'white';

//   const backgroundColor =
//     event.color ||
//     (esRecreo ? colorXDefectoRecreo : esAlmuerzo ? colorXDefectoAlmuerzo : 'white');

//   const obtenerEstiloAsignatura = (asignatura) => {
//     asignatura = asignatura || '';
//     let style = { display: 'inline', color: 'grey', fontWeight: 'lighter' };
//     if (asignatura.length > 15) {
//       style.whiteSpace = 'nowrap';
//       style.overflow = 'hidden';
//       style.textOverflow = 'ellipsis';
//     }
//     return style;
//   };

//   const asignatura = event.asignatura || '';
//   const asignaturaStyle = obtenerEstiloAsignatura(asignatura);

//   const estilosCirculoDesktop = {
//     width: '2.5vh',
//     height: '2.5vh',
//     backgroundColor: 'var(--primary)',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     borderRadius: '50%',
//     border: '1px solid var(--primary)',
//     color: 'white',
//     // fontWeight: 'bold',
//     marginRight: '15px',
//     marginLeft: '10px',
//   };

//   const estilosCirculoTablet = {
//     ...estilosCirculoDesktop,
//     width: '2vh',
//     height: '2vh',
//     marginRight: '10px',
//     marginLeft: '5px',
//   };

//   const estilosCirculoMobile = {
//     ...estilosCirculoDesktop,
//     width: '1.8vh',
//     height: '1.8vh',
//     marginRight: '8px',
//     marginLeft: '4px',
//   };

//   const estilosEventoDesktop = {
//     display: 'flex',
//     alignItems: 'center',
//     height: '100%',
//     fontSize: '1em',
//     border: '0.01em solid lightgray',
//     borderRadius: '8px',
//     backgroundColor: backgroundColor,
//     textAlign: 'left',
//     transition: 'background-color 0.3s',
//     cursor: 'default',
//     padding: '10px',
//   };

//   const estilosEventoTablet = {
//     ...estilosEventoDesktop,
//     fontSize: '0.85em',
//     padding: '8px',
//   };

//   const estilosEventoMobile = {
//     ...estilosEventoDesktop,
//     fontSize: '0.75em',
//     padding: '5px',
//   };

//   const horaStyle = { color: 'grey', fontWeight: 'lighter', fontSize: '0.75em', marginLeft: '10px' };

//   const renderDesktopView = () => (
//     <div
//       ref={refEvento}
//       style={estilosEventoDesktop}
//       onMouseEnter={() => handleMouseEnter(refEvento, backgroundColor)}
//       onMouseLeave={() => handleMouseLeave(refEvento, backgroundColor)}
//     >
//       {esClase && (
//         <>
//           <div style={estilosCirculoDesktop}>{`B${event.num_bloque}`}</div>
//           <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
//             <div style={{ fontWeight: 'bold', color: 'black', marginRight: '10px' }}>
//               {event.curso}
//             </div>
//             <div style={{asignaturaStyle, fontWeight: "bold", color: "black"}} >
//               {asignatura}
//             </div>
//             <div style={{ marginLeft: '10px', color: "black" }}>
//               {`Sala: ${event.sala}`}
//             </div>
//             <div style={horaStyle}>
//               {`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}
//             </div>
//           </div>
//         </>
//       )}
//       {esAlmuerzo && (
//         <div className='text-center' style={{ flex: 1, padding: '4px', color: 'black' }}>
//           {event.title}
//           <div className='text-center' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//             <small>{`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}</small>
//           </div>
//         </div>
//       )}
//       {!esClase && !esAlmuerzo && (
//         <div className='text-center' style={{ flex: 1, padding: '4px', color: 'black' }}>
//           {event.title}
//         </div>
//       )}
//     </div>
//   );

//   const renderTabletView = () => (
//     <div
//       ref={refEvento}
//       style={estilosEventoTablet}
//       onMouseEnter={() => handleMouseEnter(refEvento, backgroundColor)}
//       onMouseLeave={() => handleMouseLeave(refEvento, backgroundColor)}
//     >
//       {esClase && (
//         <>
//           <div style={estilosCirculoTablet}>{`B${event.num_bloque}`}</div>
//           <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
//             <div style={{ fontWeight: 'bold', color: 'black', marginRight: '10px' }}>
//               {event.curso}
//             </div>
//             <div style={asignaturaStyle}>
//               {asignatura}
//             </div>
//             <div style={{ fontWeight: 'bold', marginLeft: '10px' }}>
//               {`Sala: ${event.sala}`}
//             </div>
//             <div style={horaStyle}>
//               {`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}
//             </div>
//           </div>
//         </>
//       )}
//       {esAlmuerzo && (
//         <div className='text-center' style={{ flex: 1, padding: '4px', color: 'black' }}>
//           {event.title}
//           <div className='text-center' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//             <small>{`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}</small>
//           </div>
//         </div>
//       )}
//       {!esClase && !esAlmuerzo && (
//         <div className='text-center' style={{ flex: 1, padding: '4px', color: 'black' }}>
//           {event.title}
//         </div>
//       )}
//     </div>
//   );

//   const renderMobileView = () => (
//     <div
//       ref={refEvento}
//       style={estilosEventoMobile}
//       onMouseEnter={() => handleMouseEnter(refEvento, backgroundColor)}
//       onMouseLeave={() => handleMouseLeave(refEvento, backgroundColor)}
//     >
//       {esClase && (
//         <>
//           <div style={estilosCirculoMobile}>{`B${event.num_bloque}`}</div>
//           <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
//             <div style={{ fontWeight: 'bold', color: 'black', marginRight: '10px' }}>
//               {event.curso}
//             </div>
//             <div style={asignaturaStyle}>
//               {asignatura}
//             </div>
//             <div style={{ fontWeight: 'bold', marginLeft: '10px' }}>
//               {`Sala: ${event.sala}`}
//             </div>
//             <div style={horaStyle}>
//               {`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}
//             </div>
//           </div>
//         </>
//       )}
//       {esAlmuerzo && (
//         <div className='text-center' style={{ flex: 1, padding: '4px', color: 'black' }}>
//           {event.title}
//           <div className='text-center' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//             <small>{`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}</small>
//           </div>
//         </div>
//       )}
//       {!esClase && !esAlmuerzo && (
//         <div className='text-center' style={{ flex: 1, padding: '4px', color: 'black' }}>
//           {event.title}
//         </div>
//       )}
//     </div>
//   );

//   const renderViewBasedOnWidth = () => {
//     if (windowWidth > 1024) {
//       return renderDesktopView();
//     } else if (windowWidth > 768) {
//       return renderTabletView();
//     } else {
//       return renderMobileView();
//     }
//   };

//   return renderViewBasedOnWidth();
// };

// export default EventoDiario;


//LOCAL 



import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { handleMouseEnter, handleMouseLeave } from '../../../util/hoverAdaptativo';
import EstadoClaseIcono from '../guiaestados/EstadoClaseIcon';

const EventoDiario = ({ event }) => {
  const refEvento = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getColorWithHash = (color) => {
    if (color && !color.startsWith('#')) {
      return `#${color}`;
    }
    return color || 'white';
  };

  const backgroundColor = getColorWithHash(event.asignaturaColorFondo || event.color);
  const textColor = 'white';
  const hoverColor = 'var(--color-secundario)';

  const asignatura = event.asignatura || '';
  const asignaturaStyle = {
    backgroundColor: backgroundColor,
    color: textColor,
    padding: '2px 5px',
    borderRadius: '4px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px',
  };

  const cursoSalaStyle = {
    fontFamily: 'Segoe UI, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    fontWeight: 400,
    fontSize: '1.0em',
    color: '#172b4d',
    marginBottom: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
  };

  const horaStyle = {
    display: 'flex',
    alignItems: 'center',
    color: '#172b4d',
    fontFamily: 'Segoe UI, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif',
    fontWeight: 400,
    fontSize: '0.85em',
    marginLeft: '10px',
    marginRight: '5px',
    marginTop: '3px'
  };

  const estilosCirculo = {
    width: '2.5vh',
    height: '2.5vh',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: "1px solid var(--color-texto-claro)",
    color: "var(--color-texto-claro)",
    marginRight: '10px',
  };

  const estilosEvento = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontSize: windowWidth > 1024 ? '1em' : windowWidth > 768 ? '0.85em' : '0.75em',
    border: '0.01em solid lightgray',
    borderRadius: '8px',
    backgroundColor: event.type === 'Almuerzo' || event.type === 'Recreo' ? hoverColor : 'white',
    textAlign: 'left',
    transition: 'background-color 0.3s',
    cursor: 'default',
    padding: '10px',
    boxSizing: 'border-box',
  };

  const renderEventoClase = () => (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div style={estilosCirculo}>{`B${event.num_bloque}`}</div>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <div style={asignaturaStyle}>
          {asignatura}
        </div>
        <div style={cursoSalaStyle}>
          {`${event.curso} - Sala: ${event.sala}`}
        </div>
        <div style={horaStyle}>
          <FontAwesomeIcon icon={faClock} style={{ marginRight: '5px', color: 'var(--color-acento)' }} />
          {`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}
        </div>
        <EstadoClaseIcono estadoClaseId={event.estadoClaseId} style={{ marginLeft: '5px' }} />
      </div>
    </div>
  );

  const renderEventoAlmuerzo = () => (
    <div className='text-center' style={{ flex: 1, padding: '4px', color: 'var(--color-texto-oscuro)' }}>
      {event.title}
      <div className='text-center' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <small>{`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}</small>
      </div>
    </div>
  );

  const renderEventoRecreo = () => (
    <div className='text-center' style={{ flex: 1, padding: '4px', color: 'var(--color-texto-oscuro)' }}>
      {event.title}
    </div>
  );

  const renderDesktopView = () => (
    <div
      ref={refEvento}
      style={estilosEvento}
      onMouseEnter={() => handleMouseEnter(refEvento, backgroundColor)}
      onMouseLeave={() => handleMouseLeave(refEvento, backgroundColor)}
    >
      {event.type === 'Clase' && renderEventoClase()}
      {event.type === 'Almuerzo' && renderEventoAlmuerzo()}
      {event.type === 'Recreo' && renderEventoRecreo()}
      {!['Clase', 'Almuerzo', 'Recreo'].includes(event.type) && (
        <div className='text-center' style={{ flex: 1, padding: '4px', color: 'var(--color-texto-oscuro)' }}>
          {event.title}
        </div>
      )}
    </div>
  );

  return renderDesktopView();
};

export default EventoDiario;
