import React from 'react';

const ButtonSwitch = ({ label, checked, onChange, id, disabled = false }) => {
  const styles = {
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '20px', // Añadir margen al lado derecho del contenedor
    },
    switchCheckbox: {
      display: 'none',
    },
    switchLabel: {
      display: 'flex',
      alignItems: 'center',
      cursor: disabled ? 'not-allowed' : 'pointer',
      position: 'relative',
      opacity: disabled ? 0.6 : 1,
      marginLeft: '10px', // Añadir margen al lado derecho del texto
    },
    switchButton: {
      width: '40px',
      height: '20px',
      marginTop:'10px',
      backgroundColor: checked ? 'var(--color-acento)' : 'var(--color-icon)',
      borderRadius: '20px',
      position: 'relative',
      transition: 'background-color 0.3s',
      marginRight: '-25px', // Añadir margen al lado derecho del botón
    },
    switchButtonBefore: {
      content: '""',
      width: '16px',
      height: '16px',
      backgroundColor: 'white',
      borderRadius: '50%',
      position: 'absolute',
      top: '2px',
      left: checked ? '22px' : '2px',
      transition: 'transform 0.3s',
    }
  };

  return (
    <div style={styles.switchContainer} className={disabled ? 'switch-disabled' : ''}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        style={styles.switchCheckbox}
      />
      <label style={styles.switchLabel} htmlFor={id}>
        <span style={styles.switchButton}>
          <span style={styles.switchButtonBefore} />
        </span>
        {label}
      </label>
    </div>
  );
};

export default ButtonSwitch;
