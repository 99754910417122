import React, { Fragment, useState } from "react";
// import Header from "./Header";
// import NavHader from "./NavHader";
// import SideBar from "./SideBar";
import HeaderNav from "./HeaderNav.jsx";

const JobieNav = ({title, onClick: ClickToAddEvent, onClick2, onClick3}) => {
    const [toggle, setToggle] = useState("");
    const onClick = (name) => setToggle(toggle === name ? "" : name);
    return (
        <Fragment>
            {/* <NavHader/> */}
            <HeaderNav/>
            {/* <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} /> */}
             {/* <Header/>  */}
            {/* <SideBar/> */}
        </Fragment>
    );
};

export default JobieNav;
