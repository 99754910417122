import moment from 'moment';
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../assets/css/navAsistencia.css";
import useGetAsistenciaClase from "../../../hooks/useGetAsistenciaClase";
import useGetClaseActual from "../../../hooks/useGetClaseActual";
import useGetHorarioAnteriorSiguiente from "../../../hooks/useGetHorarioAnteriorSiguiente";
import useSaveAsistenciaClaseCurso from '../../../hooks/useSaveAsistenciaClaseCurso';
import NavAsistenciaDiariaAsignaturaProfe from "../../components/asistenciaBloqueDiaria/NavAsistenciaDiariaAsignaturaProfe";
import ResumenAsistencia from "../../components/asistenciaBloqueDiaria/ResumenAsistenciaDiaria";
import TablaAsistenciaDiariaAsignaturaProfe from "../../components/asistenciaBloqueDiaria/TablaAsistenciaDiariaAsignaturaProfe";
import ButtonPersonalizado from "../../components/buttonpersonalizado/ButtonPersonalizado";
import ModalAutenticacion from '../../components/confirmarFirma/ModalAutenticacion';
import GuiaEstados from '../../components/guiaestados/GuiaEstados';
import Guardando from '../../components/notificacion/Guardando';
import NotificacionError from '../../components/notificacion/NotificacionError';
import NotificacionToast from '../../components/notificacion/NotificacionToast';
import CustomSpinner from '../../components/spinner/Spinner';

const AsistenciaDiariaAsignaturaProfesor = () => {
  const ubicacion = useLocation();
  const navigate = useNavigate();

  const storedData = JSON.parse(localStorage.getItem('currentRolData')) || {};
  const tieneClases = storedData.tieneClases;

  const [estadoInicial, setEstadoInicial] = useState({
    fecha: moment().format('YYYY-MM-DD'),
    curso: "Curso",
    asignatura: "Asignatura",
    asignatura_id: null,
    clase_id: null,
    curso_id: null,
    bloque_numero: null,
    start: null,
    end: null,
    estadoClaseId: null,
    from: null,
    asignaturaColor: null
  });

  const [usarAsistenciaActual, setUsarAsistenciaActual] = useState(true);
  const { data, loading, error, refetch } = useGetClaseActual(usarAsistenciaActual);
  const { asistencia, cargando: cargandoAsistencia, error: errorAsistencia } = useGetAsistenciaClase(estadoInicial.curso_id, estadoInicial.clase_id);
  const { guardarAsistenciaCurso, cargando: cargandoGuardar, error: errorGuardar } = useSaveAsistenciaClaseCurso();
  const { horario, cargando: cargandoHorario, error: errorHorario } = useGetHorarioAnteriorSiguiente({
    asignatura_id: estadoInicial.asignatura_id,
    clase_id: estadoInicial.clase_id,
  });

  const [fecha, setFecha] = useState(new Date());
  const [curso, setCurso] = useState(estadoInicial.curso);
  const [asignatura, setAsignatura] = useState(estadoInicial.asignatura);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [mostrarModalErrores, setMostrarModalErrores] = useState(false);
  const [mostrarModalAutenticacion, setMostrarModalAutenticacion] = useState(false);
  const [estudiantes, setEstudiantes] = useState([]);
  const [mostrarToast, setMostrarToast] = useState(false);
  const [mensajeToast, setMensajeToast] = useState('');
  const [mostrarError, setMostrarError] = useState(false);
  const [guardando, setGuardando] = useState(false);
  const [estudiantesInvalidos, setEstudiantesInvalidos] = useState([]);
  const [debeNavegar, setDebeNavegar] = useState(false);
  const [codigoAutenticacion, setCodigoAutenticacion] = useState(new Array(6).fill(""));
  const [countdown, setCountdown] = useState(15); 

  useEffect(() => {
    if (ubicacion.state) {
      console.log('Color recibido en AsistenciaDiariaAsignaturaProfesor:', ubicacion.state.asignaturaColor);
  
      setEstadoInicial({
        ...ubicacion.state,
        start: new Date(ubicacion.state.start),
        end: new Date(ubicacion.state.end),
        estadoClaseId: ubicacion.state.estadoClaseId,
        from: ubicacion.state.from,
        asignaturaColor: ubicacion.state.asignaturaColor // Recibe y aplica el color de la asignatura
      });
      setUsarAsistenciaActual(false);
    }
  }, [ubicacion.state]);

  useEffect(() => {
    if (usarAsistenciaActual && data) {
      console.log('Datos de getClaseActual:', data); // Log para verificar los datos recibidos en la vista

      if (data.claseId !== estadoInicial.clase_id) {
        setEstadoInicial(prevState => ({
          ...prevState,
          fecha: data.bloqueFecha,
          curso: data.cursoNombre,
          asignatura: data.asignaturaNombre,
          asignatura_id: data.asignaturaId,
          clase_id: data.claseId,
          curso_id: data.cursoId,
          bloque_numero: data.bloqueNum,
          start: new Date(`${data.bloqueFecha}T${data.bloqueIni}`),
          end: new Date(`${data.bloqueFecha}T${data.bloqueFin}`),
          estadoClaseId: data.estadoClaseId,
          asignaturaColor: data.asignaturaColorFondo // Asegúrate de pasar este valor
        }));
        setFecha(moment(data.bloqueFecha, 'YYYY-MM-DD').toDate());
        setEstudiantes(data.estudiantes || []);
      }
    }
  }, [data, usarAsistenciaActual]);

  useEffect(() => {
    if (asistencia && asistencia.length > 0) {
      setEstudiantes(asistencia);
    }
  }, [asistencia]);

  useEffect(() => {
    if (estadoInicial.fecha) {
      setFecha(moment(estadoInicial.fecha, 'YYYY-MM-DD').toDate());
    }
    if (estadoInicial.curso) {
      setCurso(estadoInicial.curso);
    }
    if (estadoInicial.asignatura) {
      setAsignatura(estadoInicial.asignatura);
    }
  }, [estadoInicial]);

  useEffect(() => {
    if (horario && (horario.claseAnterior || horario.claseSiguiente)) {
      console.log('Datos de getHorarioAnteriorSiguiente:', horario); // Log para verificar los datos recibidos en la vista
    }
  }, [horario]);

  const actualizarEstadoEstudiante = (nuevosDatos) => {
    setEstudiantes(nuevosDatos);
  };

  const formatearFecha = (fecha) => {
    if (isNaN(fecha.getTime())) {
      return "Fecha inválida";
    }
    const opciones = { weekday: "long", day: "2-digit", month: "long" };
    const fechaFormateada = fecha.toLocaleDateString("es-ES", opciones);
    return fechaFormateada.charAt(0).toUpperCase() + fechaFormateada.slice(1).toLowerCase();
  };

  const manejarEventoSeleccionado = (event) => {
    console.log('Evento seleccionado en AsistenciaDiariaAsignaturaProfesor:', event);
    console.log('Color de la asignatura recibido:', event.asignaturaColor);  // Verifica que este valor no sea undefined

    setEstadoInicial({
        fecha: event.start,
        curso: event.curso,
        asignatura: event.asignatura,
        asignatura_id: event.asignaturaId,
        clase_id: event.id,
        curso_id: event.cursoId,
        bloque_numero: event.num_bloque,
        start: new Date(event.start),
        end: new Date(event.end),
        estadoClaseId: event.estadoClaseId,
        asignaturaColor: event.asignaturaColor  // Asegúrate de que se está estableciendo correctamente
    });
    setMostrarModal(false);
  };

  const manejarDiaAnterior = () => {
    if (horario && horario.claseAnterior) {
      setEstadoInicial(prevState => ({
        ...prevState,
        clase_id: horario.claseAnterior.claseId,
        curso_id: horario.claseAnterior.cursoId,
        fecha: horario.claseAnterior.bloqueFecha,
        curso: horario.claseAnterior.cursoNombre,
        asignatura: horario.claseAnterior.asignaturaNombre,
        asignatura_id: horario.claseAnterior.asignaturaId,
        bloque_numero: horario.claseAnterior.bloqueNum,
        start: new Date(`${horario.claseAnterior.bloqueFecha}T${horario.claseAnterior.bloqueIni}`),
        end: new Date(`${horario.claseAnterior.bloqueFecha}T${horario.claseAnterior.bloqueFin}`),
        estadoClaseId: horario.claseAnterior.estadoClaseId,
        asignaturaColor: horario.claseAnterior.asignaturaColorFondo // Asegúrate de pasar este valor
      }));
      setFecha(moment(horario.claseAnterior.bloqueFecha, 'YYYY-MM-DD').toDate());
    }
  };

  const manejarDiaSiguiente = () => {
    if (horario && horario.claseSiguiente) {
      const now = moment().tz('America/Santiago').toDate();
      const start = new Date(`${horario.claseSiguiente.bloqueFecha}T${horario.claseSiguiente.bloqueIni}`);
      if (start > now) {
        setMostrarError(true);
        return;
      }

      setEstadoInicial(prevState => ({
        ...prevState,
        clase_id: horario.claseSiguiente.claseId,
        curso_id: horario.claseSiguiente.cursoId,
        fecha: horario.claseSiguiente.bloqueFecha,
        curso: horario.claseSiguiente.cursoNombre,
        asignatura: horario.claseSiguiente.asignaturaNombre,
        asignatura_id: horario.claseSiguiente.asignaturaId,
        bloque_numero: horario.claseSiguiente.bloqueNum,
        start: new Date(`${horario.claseSiguiente.bloqueFecha}T${horario.claseSiguiente.bloqueIni}`),
        end: new Date(`${horario.claseSiguiente.bloqueFecha}T${horario.claseSiguiente.bloqueFin}`),
        estadoClaseId: horario.claseSiguiente.estadoClaseId,
        asignaturaColor: horario.claseSiguiente.asignaturaColorFondo // Asegúrate de pasar este valor
      }));
      setFecha(moment(horario.claseSiguiente.bloqueFecha, 'YYYY-MM-DD').toDate());
    }
  };

  const abrirModal = () => setMostrarModal(true);
  const cerrarModal = () => setMostrarModal(false);

  const abrirModalErrores = () => {
    setMostrarModalErrores(true);
    setCountdown(7); // Reinicia la cuenta regresiva cada vez que el modal se abre
  };
  const cerrarModalErrores = () => {
    setMostrarModalErrores(false);
  };

  // Efecto para manejar la cuenta regresiva
  useEffect(() => {
    if (countdown > 0 && mostrarModalErrores) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer); // Limpiar el timeout
    } else if (countdown === 0) {
      manejarSeguir(); // Automáticamente confirmar cuando llegue a 0
    }
  }, [countdown, mostrarModalErrores]);

  const manejarSeguir = () => {
    setMostrarModalErrores(false);
    setMostrarModalAutenticacion(true);
  };

  const guardarAsistencia = async () => {
    setGuardando(true);

    const datosParaGuardar = {
      claseId: estadoInicial.clase_id,
      asistencias: estudiantes.map(est => ({
          estudianteId: est.id,
          estadoAsistenciaId: est.asistenciaEstadoClaseId
      }))
    };

    try {
      const resultado = await guardarAsistenciaCurso(datosParaGuardar);
      setEstadoInicial(prevState => ({
          ...prevState,
          estadoClaseId: resultado.estadoClaseId
      }));
      setMensajeToast("Asistencia guardada correctamente");
      setMostrarToast(true);
      setTimeout(() => setDebeNavegar(true), 3000);
    } catch (error) {
      console.error('Error al guardar la asistencia del curso:', error);
      setMostrarError(true);
    } finally {
      setGuardando(false);
    }
  };

  const manejarFirmar = () => {
    guardarAsistencia();
    setMostrarModalAutenticacion(false);
  };

  const manejarCancelarAutenticacion = () => {
    setMostrarModalAutenticacion(false);
  };

  const manejarGuardar = async () => {
    const ahora = new Date();
    if (estadoInicial.start > ahora) {
      setMostrarError(true);
      return;
    }
    setGuardando(true);

    const estudiantesInvalidosTemp = estudiantes.map(est => {
      const razones = [];
      if (!est.numLista) razones.push('Sin número de lista');
      if (!est.numMatricula) razones.push('Sin número de matrícula');
      if (!est.fechaMatricula || est.fechaMatricula === 'null') razones.push('Sin fecha de matrícula');
      
      return razones.length > 0 ? { ...est, razones } : null;
    }).filter(est => est !== null);

    setEstudiantesInvalidos(estudiantesInvalidosTemp);

    if (estudiantesInvalidosTemp.length > 0) {
      setMostrarModalErrores(true);
    } else {
      setMostrarModalAutenticacion(true);
    }
    setGuardando(false);
  };

  useEffect(() => {
    if (debeNavegar && estadoInicial.from) {
      navigate(estadoInicial.from);
    }
  }, [debeNavegar, estadoInicial.from, navigate]);

  const manejarHoyClick = () => {
    setUsarAsistenciaActual(true);
    refetch();
  };

  const manejarCambioCodigo = (valor, index) => {
    const nuevoCodigo = [...codigoAutenticacion];
    nuevoCodigo[index] = valor;
    setCodigoAutenticacion(nuevoCodigo);

    if (valor.length === 1 && index < 5) {
      document.getElementById(`codigo-${index + 1}`).focus();
    }
  };

  if (!tieneClases) {
    return (
      <Container fluid className="bg-white d-flex flex-column p-0" style={{ height: '80vh', overflow: 'hidden' }}>
        <div className="alert alert-warning text-center mt-3">
          No tienes clases asignadas.
        </div>
      </Container>
    );
  }

  return (
    <Container fluid className="bg-white d-flex flex-column p-0" style={{ height: '90dvh', overflow: 'hidden' }}>
      <Guardando visible={guardando} />
      <NotificacionError visible={mostrarError} onClose={() => setMostrarError(false)} />
      <NavAsistenciaDiariaAsignaturaProfe
        curso={curso}
        asignatura={asignatura}
        fecha={formatearFecha(fecha)}
        onEventoSeleccionado={manejarEventoSeleccionado}
        onPrev={manejarDiaAnterior}
        onNext={manejarDiaSiguiente}
        num_bloque={estadoInicial.bloque_numero}
        start={estadoInicial.start}
        end={estadoInicial.end}
        estadoClaseId={estadoInicial.estadoClaseId}
        onHoyClick={manejarHoyClick}
        asignaturaColor={estadoInicial.asignaturaColor}  // Asegúrate de pasar el color de la asignatura
      />
      <div style={{ flex: '1 1 auto', overflowY: 'hidden', }}>
        {loading || cargandoAsistencia ? (
          <div className="text-center d-flex justify-content-center align-items-center h-100">
            <CustomSpinner  />
          </div>
        ) : error || errorAsistencia ? (
          <div className="alert alert-danger text-center mt-3">
            Error al cargar los datos: {error ? error.message : errorAsistencia.message}
          </div>
        ) : !estadoInicial.curso_id || !estadoInicial.clase_id ? (
          <div className="alert alert-warning text-center mt-3">
            Faltan parámetros necesarios para mostrar los datos.
          </div>
        ) : estudiantes.length === 0 ? (
          <div className="alert alert-info text-center mt-3">
            No hay datos de asistencia disponibles.
          </div>
        ) : (
          <TablaAsistenciaDiariaAsignaturaProfe
            estudiantes={estudiantes}
            actualizarEstadoEstudiante={actualizarEstadoEstudiante}
          />
        )}
      </div>

      <div className="p-2 mt-0 pt-0">
        <Row className="align-items-center botones-resumen-firmar">
          <Col xs={12} md={3} className="d-flex"></Col>  {/* Espacio vacío para compensar el centrado */}
          <Col xs={12} md={6} className="d-flex justify-content-center">
            <GuiaEstados />
          </Col>
          <Col xs={12} md={3} className="d-flex justify-content-end">
            <ButtonPersonalizado className="me-2" variant="outline-primary" onClick={abrirModal}>
              Resumen
            </ButtonPersonalizado>
            <ButtonPersonalizado variant="color-acento" className="mx-2" onClick={manejarGuardar} disabled={cargandoGuardar}>
              Firmar
            </ButtonPersonalizado>
          </Col>
        </Row>
    </div>

      <Modal centered show={mostrarModal} onHide={cerrarModal} size="lg">
        <Modal.Header closeButton className="p-4">
          <Modal.Title style={{ width: "100%", textAlign: "center" }}>
            Resumen de Asistencia por Asignatura Diaria
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1">
          <ResumenAsistencia datos={estudiantes} className="p-4" />
        </Modal.Body>
        <Modal.Footer className="justify-content-end p-4">
          <ButtonPersonalizado variant="color-acento" onClick={() => {
            manejarGuardar();
            cerrarModal();
          }}>
            Firmar
          </ButtonPersonalizado>
        </Modal.Footer>
      </Modal>
      <NotificacionToast
        mensaje={mensajeToast}
        mostrar={mostrarToast}
        setMostrar={setMostrarToast}
        // estiloPersonalizado={{ top: '20px', right: '20px' }}
      />
      <Modal centered show={mostrarModalErrores} onHide={cerrarModalErrores} size="lg">
        <Modal.Header closeButton className="p-4">
          <Modal.Title style={{ width: "100%", textAlign: "center" }}>
            AVISO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1">
        <p className="text-center p-3">
          Algunos estudiantes no cuentan con toda la información necesaria
          <br />
           para el correcto funcionamiento en la plataforma.
          <br />
          No obstante, su asistencia será registrada.
          <br />
           Le recomendamos comunicarse con UTP para corregir esta situación.
          <br />
          El proceso continuará en {countdown} segundos.
        </p>
          <div className="list-group" style={{ maxHeight: '350px', overflowY: 'auto' }}>
            {estudiantesInvalidos.map(est => (
              <div key={est.id} className="list-group-item">
                <strong>{est.numLista ? `N° ${est.numLista}:` : ""}</strong> {est.nombre}: {est.razones ? est.razones.join(', ') : ''}
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-between mt-3 p-2">
            <ButtonPersonalizado variant="outline-primary" onClick={cerrarModalErrores}>
              Cancelar
            </ButtonPersonalizado>
            <ButtonPersonalizado variant="color-acento" onClick={manejarSeguir}>
              Seguir 
            </ButtonPersonalizado>
          </div>
        </Modal.Body>
      </Modal>
      <ModalAutenticacion
        show={mostrarModalAutenticacion}
        onHide={manejarCancelarAutenticacion}
        onConfirm={manejarFirmar}
        codigoAutenticacion={codigoAutenticacion}
        manejarCambioCodigo={manejarCambioCodigo}
      />
    </Container>
  );
};

export default AsistenciaDiariaAsignaturaProfesor;
