import React from 'react';
import './../../../assets/css/barraHerramientasPersonalizadaBigCalendar.css';

const BarraHerramientasPersonalizada = ({ onNavigate, label, onView, view, isModal }) => {
  const estilos = {
    estiloBoton: {
      background: 'var(--color-acento)',
      color: '#fff',
      border: 'none',
      opacity: 1,
      textTransform: 'capitalize',
      width: "50px",  /* Ancho del botón */
      height: "35px",   /* Altura del botón */
      padding: 0,    /* Eliminar padding para ajustar tamaño exacto */
      margin: "0px 5px",
      cursor: 'pointer',
      fontSize: '1rem',
      transition: 'background-color 0.3s ease, color 0.3s ease',
    },
    estiloActivo: {
      backgroundColor: 'var(--color-acento)',
      color: 'white',
      padding: "0 10px",
    },
    estiloInactivo: {
      backgroundColor: 'transparent',
      color: 'black',
      border: '0px solid gray',
    },
    estiloPestana: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 15px', // Ajusta el padding para que el texto se vea completo
      height: '35px',
      margin: "0px 5px",
      cursor: 'pointer',
      fontSize: '1rem',
      transition: 'background-color 0.3s ease, color 0.3s ease',
    },
    estiloPestanaActivo: {
      backgroundColor: 'var(--color-acento)',
      color: 'white',
    },
    estiloPestanaInactivo: {
      backgroundColor: 'transparent',
      color: 'black',
      border: '0px solid gray',
    },
    titulo: {
      fontSize: '1.4rem',
      textAlign: 'center',
      flexGrow: 1,
      margin: '5px 0',
      color: "#1c2833",
      fontFamily: 'poppins, sans-serif',
      fontWeight: 500,
    },
    fecha: {
      fontSize: '1.1rem',
      color: '#1c2833',
      fontFamily: 'poppins, sans-serif',
      fontWeight: 500,
      textAlign: 'center',
      width: view === 'month' ? '50px' : view === 'week' ? '150px' : '215px', // Proporcionar un tamaño fijo para la fecha según la vista
    },
  };

  const manejarMouseEntrada = (e) => {
    e.currentTarget.style.backgroundColor = 'var(--color-acento-hover)';
    e.currentTarget.style.color = 'white';
  };

  const manejarMouseSalida = (e) => {
    const esActivo = e.currentTarget.dataset.active === 'true';
    e.currentTarget.style.backgroundColor = esActivo ? 'var(--color-acento)' : 'transparent';
    e.currentTarget.style.color = esActivo ? 'white' : 'black';
  };

  const capitalizarMesesEnLabel = (label, view) => {
    if (view === 'week' || view === 'month') {
      return label.split(' ').map(palabra => {
        if (palabra.match(/^\d+$/)) return palabra; // Si es un número, no cambiar
        return capitalizarPrimeraLetra(palabra).slice(0, 3); // Tomar solo las primeras 3 letras
      }).join(' ');
    }
    return label;
  };

  const capitalizarPrimeraLetra = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const labelCapitalizado = view === 'week' || view === 'month'
    ? capitalizarMesesEnLabel(label, view)
    : label.split(' ')
      .map((palabra, index) => {
        if (index === 0) return capitalizarPrimeraLetra(palabra);
        if (palabra.toLowerCase() === 'de') return palabra.toLowerCase();
        if (palabra.match(/^\d+$/)) return palabra;
        return palabra.toLowerCase();
      })
      .join(' ');

  const mesCapitalizado = label.split(' ')[0];

  return (
    <>
      {/* Vista de escritorio */}
      <div className="rbc-toolbar toolbar desktop">
        <div className="view-buttons">
          <button
            type="button"
            onClick={() => onView('month')}
            style={view === 'month' ? { ...estilos.estiloPestana, ...estilos.estiloPestanaActivo } : { ...estilos.estiloPestana, ...estilos.estiloPestanaInactivo }}
            onMouseEnter={manejarMouseEntrada}
            onMouseLeave={manejarMouseSalida}
            data-active={view === 'month'}
          >
            Mes
          </button>
          <button
            type="button"
            onClick={() => onView('week')}
            style={view === 'week' ? { ...estilos.estiloPestana, ...estilos.estiloPestanaActivo } : { ...estilos.estiloPestana, ...estilos.estiloPestanaInactivo }}
            onMouseEnter={manejarMouseEntrada}
            onMouseLeave={manejarMouseSalida}
            data-active={view === 'week'}
          >
            Semana
          </button>
          <button
            type="button"
            onClick={() => onView('day')}
            style={view === 'day' ? { ...estilos.estiloPestana, ...estilos.estiloPestanaActivo } : { ...estilos.estiloPestana, ...estilos.estiloPestanaInactivo }}
            onMouseEnter={manejarMouseEntrada}
            onMouseLeave={manejarMouseSalida}
            data-active={view === 'day'}
          >
            Día
          </button>
        </div>
        <div className="titulo" style={estilos.titulo}>
          {isModal ? 'Menú Horario Docente' : 'Agenda Docente'}
        </div>

        <div className="nav-buttons">
          <button
            type="button"
            onClick={() => onNavigate('TODAY')}
            style={estilos.estiloBoton}
            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento-hover)'; e.currentTarget.style.color = 'white'; }}
            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento)'; e.currentTarget.style.color = 'white'; }}
          >
            Hoy
          </button>
          <button
            type="button"
            onClick={() => onNavigate('PREV')}
            style={estilos.estiloBoton}
            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento-hover)'; e.currentTarget.style.color = 'white'; }}
            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento)'; e.currentTarget.style.color = 'white'; }}
          >
            <i className="bi bi-chevron-left"></i>
          </button>
          <span style={estilos.fecha}>{view === 'month' ? capitalizarPrimeraLetra(mesCapitalizado).slice(0, 3) : labelCapitalizado}</span>
          <button
            type="button"
            onClick={() => onNavigate('NEXT')}
            style={estilos.estiloBoton}
            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento-hover)'; e.currentTarget.style.color = 'white'; }}
            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento)'; e.currentTarget.style.color = 'white'; }}
          >
            <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </div>

      {/* Vista de tablet */}
      <div className="rbc-toolbar toolbar tablet">
        <div className="titulo" style={estilos.titulo}>
        {isModal ? 'Menú Horario Docente' : 'Agenda Docente'}
        </div>
        <div className="container">
          <div className="view-buttons">
            <button
              type="button"
              onClick={() => onView('month')}
              style={view === 'month' ? { ...estilos.estiloPestana, ...estilos.estiloPestanaActivo } : { ...estilos.estiloPestana, ...estilos.estiloPestanaInactivo }}
              onMouseEnter={manejarMouseEntrada}
              onMouseLeave={manejarMouseSalida}
              data-active={view === 'month'}
            >
              Mes
            </button>
            <button
              type="button"
              onClick={() => onView('week')}
              style={view === 'week' ? { ...estilos.estiloPestana, ...estilos.estiloPestanaActivo } : { ...estilos.estiloPestana, ...estilos.estiloPestanaInactivo }}
              onMouseEnter={manejarMouseEntrada}
              onMouseLeave={manejarMouseSalida}
              data-active={view === 'week'}
            >
              Semana
            </button>
            <button
              type="button"
              onClick={() => onView('day')}
              style={view === 'day' ? { ...estilos.estiloPestana, ...estilos.estiloPestanaActivo } : { ...estilos.estiloPestana, ...estilos.estiloPestanaInactivo }}
              onMouseEnter={manejarMouseEntrada}
              onMouseLeave={manejarMouseSalida}
              data-active={view === 'day'}
            >
              Día
            </button>
          </div>

          <div className="nav-buttons">
            <button
              type="button"
              onClick={() => onNavigate('TODAY')}
              style={estilos.estiloBoton}
              onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento-hover)'; e.currentTarget.style.color = 'white'; }}
              onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento)'; e.currentTarget.style.color = 'white'; }}
            >
              Hoy
            </button>
            <button
              type="button"
              onClick={() => onNavigate('PREV')}
              style={estilos.estiloBoton}
              onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento-hover)'; e.currentTarget.style.color = 'white'; }}
              onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento)'; e.currentTarget.style.color = 'white'; }}
            >
              <i className="bi bi-chevron-left"></i>
            </button>
            <span style={estilos.fecha}>{view === 'month' ? capitalizarPrimeraLetra(mesCapitalizado).slice(0, 3) : labelCapitalizado}</span>
            <button
              type="button"
              onClick={() => onNavigate('NEXT')}
              style={estilos.estiloBoton}
              onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento-hover)'; e.currentTarget.style.color = 'white'; }}
              onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento)'; e.currentTarget.style.color = 'white'; }}
            >
              <i className="bi bi-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>

      {/* Vista móvil */}
      <div className="rbc-toolbar toolbar mobile">
        <div className="titulo" style={estilos.titulo}>
        {isModal ? 'Menú Horario Docente' : 'Agenda Docente'}
        </div>

        <div className="nav-buttons">
          <button
            type="button"
            onClick={() => onNavigate('PREV')}
            style={estilos.estiloBoton}
            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento-hover)'; e.currentTarget.style.color = 'white'; }}
            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento)'; e.currentTarget.style.color = 'white'; }}
          >
            <i className="bi bi-chevron-left"></i>
          </button>
          <span style={estilos.fecha}>{view === 'month' ? capitalizarPrimeraLetra(mesCapitalizado).slice(0, 3) : labelCapitalizado}</span>
          <button
            type="button"
            onClick={() => onNavigate('NEXT')}
            style={estilos.estiloBoton}
            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento-hover)'; e.currentTarget.style.color = 'white'; }}
            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento)'; e.currentTarget.style.color = 'white'; }}
          >
            <i className="bi bi-chevron-right"></i>
          </button>
        </div>

        <div className="view-buttons">
          <button
            type="button"
            onClick={() => onView('month')}
            style={view === 'month' ? { ...estilos.estiloPestana, ...estilos.estiloPestanaActivo, flexGrow: 1 } : { ...estilos.estiloPestana, ...estilos.estiloPestanaInactivo, flexGrow: 1 }}
            onMouseEnter={manejarMouseEntrada}
            onMouseLeave={manejarMouseSalida}
            data-active={view === 'month'}
          >
            Mes
          </button>
          <button
            type="button"
            onClick={() => onView('week')}
            style={view === 'week' ? { ...estilos.estiloPestana, ...estilos.estiloPestanaActivo, flexGrow: 1 } : { ...estilos.estiloPestana, ...estilos.estiloPestanaInactivo, flexGrow: 1 }}
            onMouseEnter={manejarMouseEntrada}
            onMouseLeave={manejarMouseSalida}
            data-active={view === 'week'}
          >
            Semana
          </button>
          <button
            type="button"
            onClick={() => onView('day')}
            style={view === 'day' ? { ...estilos.estiloPestana, ...estilos.estiloPestanaActivo, flexGrow: 1 } : { ...estilos.estiloPestana, ...estilos.estiloPestanaInactivo, flexGrow: 1 }}
            onMouseEnter={manejarMouseEntrada}
            onMouseLeave={manejarMouseSalida}
            data-active={view === 'day'}
          >
            Día
          </button>
          <button
            type="button"
            onClick={() => onNavigate('TODAY')}
            style={estilos.estiloBoton}
            onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento-hover)'; e.currentTarget.style.color = 'white'; }}
            onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-acento)'; e.currentTarget.style.color = 'white'; }}
          >
            Hoy
          </button>
        </div>
      </div>
    </>
  );
};

export default BarraHerramientasPersonalizada;
