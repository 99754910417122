
import axiosInstance from '../services/AxiosInstance';
import { limpiarPuntosYGuiones, validarFormatoIPE, validarFormatoRUN } from '../util/util';

export function getPosts() {
  return axiosInstance.get(`posts.json`);
}

export function createPost(postData) {
  return axiosInstance.post(`posts.json`, postData);
}

export function updatePost(post, postId) {
  return axiosInstance.put(`posts/${postId}.json`, post);
}

export function deletePost(postId) {
  return axiosInstance.delete(`posts/${postId}.json`);
}

export function getDocenteDataRol() {
  return axiosInstance.get(`/usuario/getDocenteDataRol`);
}

export function getDataDocente(params) {
  return axiosInstance.get(`/docente/getData`, { params });
}

export function getHorarioDocente() {
  return axiosInstance.get(`/horario/getHorarioDocente`);
}

export function getListadoDocentesAsignatura(params) {
  return axiosInstance.get(`/docente/getListadoDocentesAsignatura`, { params });
}

export function getConfigHorarioCurso(params) {
  return axiosInstance.get(`/horario/getConfigHorarioCurso`, { params });
}

export function getConflictoHorarioDocente(params) {
  return axiosInstance.get(`/horario/getConfictoHorarioDocente`, { params });
}

export function saveConfigHorarioCurso(data) {
  return axiosInstance.post(`/horario/saveConfigHorarioCurso`, data);
}

export function getAsistenciaClase(params) {
  return axiosInstance.get("/asistencia/getAsistenciaClase", { params });
}

export function getHorarioAnteriorSiguiente(params) {
  console.log("Parámetros enviados a la API:", params); // Agregar log para verificar los parámetros
  return axiosInstance.get("/horario/getHorarioAnteriorSiguiente", { params });
}

export function getClaseActual() {
  return axiosInstance.get(`/horario/getClaseActual`);
}

export function getAsistenciaClaseMensual(params) {
  return axiosInstance.get("/asistencia/getAsistenciaClaseMensual", { params });
}

export function getAsistenciaClaseEstudiante(params) {
  return axiosInstance.get("/asistencia/getAsistenciaClaseEstudiante", {
    params,
  });
}

export function getAsistenciaSubvencion(params) {
  return axiosInstance.get(`/asistencia/getAsistenciaSubvencion`, { params });
}

export function getSubvencionMesCurso(params) {
  return axiosInstance.get(`/asistencia/getSubvencionMesCurso`, { params });
}

export function getSubvencionAnualEstudiantes(params) {
  return axiosInstance.get("/asistencia/getSubvencionAnualEstudiantes", {
    params,
  });
}

export function getSubvencionAnualCursos() {
  return axiosInstance.get("/asistencia/getSubvencionAnualCursos");
}

export function saveAsistenciaClaseCurso(data) {
  return axiosInstance.post("/asistencia/saveAsistenciaClaseCurso", data);
}

export function saveAsistenciaClase(claseId, estudianteId, estadoAsistenciaId) {
  return axiosInstance.post(
    `/asistencia/saveAsistenciaClase?clase_id=${claseId}&estudiante_id=${estudianteId}&estado_asistencia_id=${estadoAsistenciaId}`
  );
}

export const saveAsistenciaSubvencion = (
  asistenciaEstadoClaseId,
  cursoId,
  estudianteId,
  mes,
  dia
) => {
  const url = `/asistencia/saveAsistenciaSubvencion?curso_id=${cursoId}&estudiante_id=${estudianteId}&mes=${mes}&dia=${dia}&estado_asistencia_id=${asistenciaEstadoClaseId}`;
  console.log(`Datos enviados a la API: ${url}`);
  return axiosInstance.post(url);
};

export const descargarBoletinAsistencia = (mes) => {
  return axiosInstance.post(
    "/descargarBoletinAsistencia",
    { mes },
    {
      responseType: "blob",
    }
  );
};

export function getFichaEstudiante(estudianteId) {
  return axiosInstance.get(`/estudiante/getFicha?estudianteId=${estudianteId}`);
}

export function deleteApoderado(apoderadoId) {
  return axiosInstance.post(`/apoderado/delApoderado?apoderadoId=${apoderadoId}`);
};

export function getListadoEstudiantes(params) {
  return axiosInstance.get(`/curso/getListadoEstudiantes`, { params });
}

export function getListadoCursos(params) {
  return axiosInstance.get("/curso/getListadoCursos", { params });
}
export function getListadoGrados() {
  // console.log('Llamada a la API grados SERVICE:');
  return axiosInstance.get(`/curriculum/getCurriculumGrados`);
}

export function getListadoGradosConId(ensenanzaId) {
  console.log('Llamada a la API con ensenanzaId SERVICE:', ensenanzaId); 
  return axiosInstance.get(`/curriculum/getCurriculumGrados?ensenanzaId=${ensenanzaId}`);
}

export function getListadoAsignaturas(params) {
  return axiosInstance.get("/curso/getListadoAsignaturas", { params });
}

export function getPeriodosAcademicos() {
  return axiosInstance.get(`/colegio/getPeriodosAcademicos`);
}

export function getNotasCursoAsignatura(params) {
  return axiosInstance.get(`/notas/getNotasCursoAsignatura`, { params });
}

export function getSalasSedeColegio() {
  return axiosInstance.get('/colegio/getSalasSedeColegio');
}

let solicitudEnCurso = false;

export function saveNotaSumEstudiante(
  cursoId,
  asignaturaId,
  periodoEscolarId,
  estudianteId,
  casilla,
  nota
) {
  if (solicitudEnCurso) {
    return Promise.resolve(); // Retornar una promesa resuelta sin hacer nada
  }
  solicitudEnCurso = true;
  
  const url = `/notas/saveNotaSumEstudiante?curso_id=${cursoId}&asignatura_id=${asignaturaId}&periodo_escolar=${periodoEscolarId}&estudiante_id=${estudianteId}&casilla=${casilla}&nota=${nota}`;
  return axiosInstance.post(url).finally(() => {
    solicitudEnCurso = false;
  });
}

export function saveNotaAcuEstudiante(
  cursoId,
  asignaturaId,
  periodoEscolarId,
  estudianteId,
  casilla,
  casillaSumativa,
  nota
) {
  if (solicitudEnCurso) {
    return Promise.resolve(); // Retornar una promesa resuelta sin hacer nada
  }
  solicitudEnCurso = true;

  const params = {
    curso_id: cursoId,
    asignatura_id: asignaturaId,
    periodo_escolar: periodoEscolarId,
    estudiante_id: estudianteId,
    casilla: casilla,
    casilla_sumativa: casillaSumativa,
    nota: nota,
  };

  return axiosInstance.post("/notas/saveNotaAcuEstudiante", null, { params }).finally(() => {
    solicitudEnCurso = false;
  });
}

export function saveConceptoSumEstudiante(
  cursoId,
  asignaturaId,
  periodoEscolarId,
  estudianteId,
  casilla,
  concepto
) {
  if (solicitudEnCurso) {
    return Promise.resolve(); // Retornar una promesa resuelta sin hacer nada
  }
  solicitudEnCurso = true;

  const url = `/notas/saveConceptoSumEstudiante?curso_id=${cursoId}&asignatura_id=${asignaturaId}&periodo_escolar=${periodoEscolarId}&estudiante_id=${estudianteId}&casilla=${casilla}&concepto=${concepto}`;
  return axiosInstance.post(url).finally(() => {
    solicitudEnCurso = false;
  });
}

export function saveConceptoAcuEstudiante(
  cursoId,
  asignaturaId,
  periodoEscolarId,
  estudianteId,
  casilla,
  casillaSumativa,
  concepto
) {
  if (solicitudEnCurso) {
    return Promise.resolve(); // Retornar una promesa resuelta sin hacer nada
  }
  solicitudEnCurso = true;

  const params = {
    curso_id: cursoId,
    asignatura_id: asignaturaId,
    periodo_escolar: periodoEscolarId,
    estudiante_id: estudianteId,
    casilla: casilla,
    casilla_sumativa: casillaSumativa,
    concepto: concepto,
  };

  return axiosInstance.post('/notas/saveConceptoAcuEstudiante', null, { params }).finally(() => {
    solicitudEnCurso = false;
  });
}


export function saveConfNotas(data) {
  return axiosInstance.post("/notas/saveConfNotas", data);
}

export const getInformeNotasEstudiante = (params) => {
  return axiosInstance.get(`/notas/getInformeNotasEstudiante`, { params });
};

export const getInformeUnidadesGlobal = (params) => {
  return axiosInstance.get(`/notas/getInformeNotasUnidadesGlobal`, { params });
}




export function formatPosts(postsData) {
  let posts = [];
  for (let key in postsData) {
    posts.push({ ...postsData[key], id: key });
  }

  return posts;
}

export function getLogo(colegioId = "") {
  return axiosInstance.get("/colegio/getLogo?colegioId=" + colegioId, {
    responseType: "arraybuffer",
  });
}

export function getListadoComunas() {
  console.log("Llamando a la API para obtener listado de comunas");
  return axiosInstance
    .get("/ref/getListadoComunas")
    .then((response) => {
      // console.log('Respuesta de la API:', response.data);
      return response;
    })
    .catch((error) => {
      console.error("Error al obtener listado de comunas:", error);
      throw error;
    });
}

export const getPermisosDocente = async (
  cursoId,
  asignaturaId,
  periodoId,
  colegioId
) => {
  // console.log("Parámetros enviados:", { cursoId, asignaturaId, periodoId, colegioId });

  try {
    const response = await axiosInstance.get(`/docente/getPermisosDocente`, {
      params: {
        curso_id: cursoId,
        asignatura_id: asignaturaId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener los permisos del docente:", error);
    return [];
  }
};

export const cargarListaNacionalidades = async () => {
  try {
    const response = await axiosInstance.get(`/ref/getListadoPais`);
    return response.data.pais;
  } catch (error) {
    console.error("Error al cargar la lista de nacionalidades:", error);
    return [];
  }
};

export const cargarListaEtnias = async () => {
  try {
    const response = await axiosInstance.get(`/ref/getListadoEtnias`);
    return response.data.etnias;
  } catch (error) {
    console.error("Error al cargar la lista de etnias:", error);
    return [];
  }
};

export const cargarListaVinculosParentescos = async () => {
  try {
    const response = await axiosInstance.get(`/ref/getListadoApoderadoTipo`);
    return response.data.apoderadotipo;
  } catch (error) {
    console.error("Error al cargar la lista de vínculos de parentesco:", error);
    return [];
  }
};

export const cargarListaIdentidadGenero = async () => {
  try {
    const response = await axiosInstance.get(`/ref/getListadoGenerosIdentidad`);
    return response.data.generosIdentidad;
  } catch (error) {
    console.error("Error al cargar la lista de identidad de género:", error);
    return [];
  }
};

export const cargarListaGenerosRegCivil = async () => {
  try {
    const response = await axiosInstance.get(`/ref/getListadoGeneros`);
    return response.data.generos;
  } catch (error) {
    console.error(
      "Error al cargar la lista de géneros de registro civil:",
      error
    );
    return [];
  }
};

export const cargarListaRegiones = async () => {
  try {
    const response = await axiosInstance.get(`/ref/getListadoRegion`);
    return response.data.region;
  } catch (error) {
    console.error("Error al cargar la lista de regiones:", error);
    return [];
  }
};

export const cargarListaComunas = async (regionId) => {
  if (regionId) {
    try {
      const response = await axiosInstance.get(
        `/ref/getListadoComunas?regionId=${regionId}`
      );
      return response.data.comunas;
    } catch (error) {
      console.error("Error al cargar la lista de comunas:", error);
      return [];
    }
  }

  try {
    const response = await axiosInstance.get(`/ref/getListadoComunas`);
    return response.data.comunas;
  } catch (error) {
    console.error("Error al cargar la lista de comunas:", error);
    return [];
  }
};

export const cargarListaSectores = async () => {
  try {
    const response = await axiosInstance.get(`/curriculum/getCurriculumSectores`);
    return response.data.curriculumSectores;
  } catch (error) {
    console.error("Error al cargar la lista de sectores:", error);
    return [];
  }
};

export const cargarListaNivelesAcademicos = async () => {
  try {
    const response = await axiosInstance.get(`/ref/getListadoNivelesEstudio`);
    return response.data.nivelesAcademicos;
  } catch (error) {
    console.error("Error al cargar la lista de niveles de enseñanza:", error);
    return [];
  }
};

export const cargarListaAnios = async () => {
  const response = await axiosInstance.get(`/dataList/years`);
  return response.data;
}; 

export const cargarListaCargos = async () => {
  const response = await axiosInstance.get(`/ref/getListadoCargos`);
  return response.data.tipoCargos;
};

export const cargarListafuncionarios = async () => {
  const response = await axiosInstance.get(`/funcionario/getListaFuncionario`);
  return response.data;
}

export const saveFuncionario = async (data) => {
  const response = await axiosInstance.post(`/funcionario/saveFichaFuncionario`, data);
  return response.data;
};

export const getFichaFuncionario = async (funcionarioId) => {
  const response = await axiosInstance.get(`/funcionario/getFichaFuncionario?personaId=${funcionarioId}`);
  return response.data;
};

export const deleteAntecedenteAcademico = async (antecedenteId) => {
  const response = await axiosInstance.post(`/funcionario/delAntecedente?antecedenteId=${antecedenteId}`);
  return response.data;
};

export const deleteFuncionario = async (id) => {
  const response = await axiosInstance.post(`/funcionario/delFuncionario?personaId=${id}`);
  return response.data;
};
export const cargarListaLocalEscolar = async () => {
  const response = await axiosInstance.get(`/colegio/getSedesColegio`);
  return response.data.colegioSedes;
};

export async function getNotasAlumnos(idCurso, periodoEscolar) {
  try {
    // Hacemos la solicitud a la API
    const response = await axiosInstance.get(
      `/notas/getInformeNotasParcial?curso_id=${idCurso}&periodo_escolar=${periodoEscolar}`
    );

    // Extraemos los datos de la respuesta
    const listadoNotas = response.data.listadoNotas;
    const dataColegio = response.data.dataColegio;
    const dataCurso = response.data.dataCurso;
    const cantidadDecimales = response.data.cantidadDecimales;

    // console.log("Datos obtenidos:", data);
    return { dataColegio, dataCurso, listadoNotas, cantidadDecimales }; // Devolvemos los datos
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Propagamos el error si la solicitud falla
  }
}

export function enterSchool() {
  return axiosInstance.post("/auth/enterSchool");
}

export function getRecordsLog(params) {
  return axiosInstance.post(`directivo/getRecordsLog`, params);
}

export function saveFichaEstudiante(data) {
  return axiosInstance.post(`/estudiante/saveFichaEstudiante`, data);
}
export function getUsuariosByPeriodo(periodoId) {
  return axiosInstance.get(
    `/usuario/getUsuariosByPeriodo?periodoId=${periodoId}`
  );
}

export function getUser() {
  return axiosInstance.post(`/auth/user`);
}

export function deleteEstudiante(id) {
  return axiosInstance.post(`/estudiante/eliminarEstudiante?id=${id}`);
}

export function getEstadosEstudiantes() {
  return axiosInstance.get(`/estudiante/estadoEstudiantes`);
}

export function getSchoolInfo(colegioRBD) {
  return axiosInstance.get(`/colegio/data?colegio_rbd=${colegioRBD}`);
}
export function changePwd(data) {
  return axiosInstance.post(`/auth/changePwd`, data);
}
export function forgotPwd(data) {
  return axiosInstance.post(`/auth/forgotPwd`, data);
}
export function resetPwd(run) {
  return axiosInstance.post(`/auth/resetPwd`, {run: run});
};
export function getCaptchaPublicKey() {
  return axiosInstance.get(`/auth/captchaPublicKey`);
}
export async function getAvatar(run) {
  const response = await axiosInstance.get(`/avatars?run=${run}`, {
    responseType: "arraybuffer",
  });
  return response; // Retorna la respuesta o null
}
export function uploadAvatar(formData){
  return axiosInstance.post(`/upload`,formData,{
    headers: {
      'Content-Type': 'multipart/form-data' // Esto asegura que se use multipart
    }
  });
}

export async function getFirma(run) {
  try {
    const response = await axiosInstance.get(`/firmas/getFirma?run=${run}`, {
      responseType: "arraybuffer",
    });
    return response; 
  } catch (error) {
    console.error("Error al obtener la firma:", error);
    return null;
  }
}

export async function getAsignaturasCurriculum(sectorId = null, nivelId = null) {
  if(sectorId && nivelId) {
  const response = await axiosInstance.get(`/curriculum/getCurriculumAsignaturas?sector_id=${sectorId}&grado_id=${nivelId}`);
  return response.data.curriculumAsignaturas;
  }

  const response = await axiosInstance.get(`/curriculum/getCurriculumAsignaturas`);
  return response.data.curriculumAsignaturas;
}

export async function getAsignaturasMineduc(sectorId = null, nivelId = null) {
  if(sectorId && nivelId) {
  const response = await axiosInstance.get(`/mineduc/getMineducAsignaturas?mineduc_sector_id=${sectorId}&mineduc_grado_id=${nivelId}`);
  return response.data.mineducAsignaturas;
  }

  const response = await axiosInstance.get(`/mineduc/getMineducAsignaturas`);
  return response.data.mineducAsignaturas;
}


export async function getCursoAsignatura(cursoId = null) {
  if(cursoId) {
  const response = await axiosInstance.get(`/curso/getCursoAsignatura?curso_id=${cursoId}`);
  return response.data;
  }

  const response = await axiosInstance.get(`/curso/getCursoAsignatura`);
  return response.data;
};


// export async function getAsignaturasMineduc(sectorId = null, nivelId = null) { 
//   if(sectorId && nivelId) {
//   const response = await axiosInstance.get(`/mineduc/getMineducAsignaturas?mineduc_sector_id=${sectorId}&mineduc_grado_id=${nivelId}`);
//   return response.data.mineducAsignaturas;
//   }

//   const response = await axiosInstance.get(`/mineduc/getMineducAsignaturas`);
//   return response.data.mineducAsignaturas;

// }

export async function getFuncionesDocente() {
  const response = await axiosInstance.get(`/refFuncion/getFuncionDocente`);
  return response.data.funciones;
}


export async function verificarApoderado(identificacion) {
  // console.log("Identificación:", identificacion);
  const identificacionLimpia = limpiarPuntosYGuiones(identificacion);
  const isRun = validarFormatoRUN(identificacionLimpia);
  const isIpa = validarFormatoIPE(identificacionLimpia);
  if(isRun) {
    const response = await axiosInstance.get(`/apoderado/verificaApoderado?run=${limpiarPuntosYGuiones(identificacionLimpia).slice(0,-1)}&ipa=`);
    return response.data;
  } else if(isIpa) {
    const response = await axiosInstance.get(`/apoderado/verificaApoderado?run=&ipa=${limpiarPuntosYGuiones(identificacionLimpia).slice(0,-1)}`);
    return response.data;
  } 
}

export async function checkRol () {
  const response = await axiosInstance.post(`/auth/checkRol`);
  return response.data;
}

export async function deleteAutorizadoRetiro(id) {
   const response = await axiosInstance.post(`/estudiante/eliminarAdultoAutorizado?id=${id}`);
  return response;

};