import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DynamicTable from "../../../components/tables/DynamicTable/Table";
import Title from "../../../components/titles/Title";
import {
  faEye,
  faFilter,
  faPenToSquare,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  cargarListaSectores,
  getAsignaturasCurriculum,
  getCursoAsignatura,
  getListadoCursos,
  getListadoGrados,
} from "../../../../services/PostsService";
import { useDispatch, useSelector } from "react-redux";
import {
  setAsignaturas,
  setCursos,
  setGrados,
  setSectores,
} from "../../../../store/actions/refDataActions";
import SelectMultiple from "../../../components/inputs/SelectMultiple";
import CustomSpinner from "../../../components/spinner/Spinner";
import useCheckRoles from "../../../../hooks/useCheckRoles";

const multiSortAsignaturas = (rowA, rowB) => {
  const collator = new Intl.Collator("es", { sensitivity: "base" });

  const sectorA = rowA.sector || "";
  const sectorB = rowB.sector || "";
  const gradoA = rowA.grado || "";
  const gradoB = rowB.grado || "";
  const cursoA = rowA.curso || "";
  const cursoB = rowB.curso || "";
  const asignaturaA = rowA.asignatura || "";
  const asignaturaB = rowB.asignatura || "";

  // Ordenar por Sector
  const sectorComparison = collator.compare(sectorA, sectorB);
  if (sectorComparison !== 0) return sectorComparison;

  // Ordenar por Grado
  const gradoComparison = collator.compare(gradoA, gradoB);
  if (gradoComparison !== 0) return gradoComparison;

  // Ordenar por Curso
  const cursoComparison = collator.compare(cursoA, cursoB);
  if (cursoComparison !== 0) return cursoComparison;

  // Ordenar por Asignatura
  return collator.compare(asignaturaA, asignaturaB);
};

function ListaAsignaturas() {
  const isSpecialSortingRequired = true;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const { isDirectivo } = useCheckRoles();

  const listaAsignaturasState = useSelector(
    (state) => state.refDataReducer.asignaturas
  );
  const listaSectoresState = useSelector(
    (state) => state.refDataReducer.sectores
  );

  const listaGradosState = useSelector((state) => state.refDataReducer.grados);

  const listadoCursosState = useSelector(
    (state) => state.refDataReducer.cursos
  );

  const diccionarioColoresAsignaturasState = useSelector(
    (state) => state.refDataReducer.diccionarioColoresAsignaturas
  );

  const [listaAsignaturas, setListaAsignaturas] = useState([]);
  const [listaSectores, setListaSectores] = useState([]);
  const [listaGrados, setListaGrados] = useState([]);
  const [listadoCursos, setListadoCursos] = useState([]);

  const [colorDict, setColorDict] = useState({});
  const isMobile = window.innerWidth < 768;

  const [showFilters, setShowFilters] = useState(false);

  const [sectorFilter, setSectorFilter] = useState([]);
  const [gradoFilter, setGradoFilter] = useState([]);
  const [cursoFilter, setCursoFilter] = useState([]);

  const headers = [
    { name: "N°", width: "80px", sortable: true, sortType: "string" },
    {
      name: "Sector",
      width: "260px",
      sortable: true,
      sortType: "string",
      sortByDefault: true,
    },
    { name: "Grado", width: "100px", sortable: true, sortType: "string" },
    // { name: "Curso", width: "100px", sortable: true, sortType: "string" },
    { name: "Código", width: "80px", sortable: true, sortType: "number" },
    { name: "Asignatura", width: "200px", sortable: true, sortType: "string" },
    { name: "Acciones", width: "80px" },
  ];

  const navigate = useNavigate();

  const handleGetAsignatura = useCallback((asignatura) => {
    console.log(asignatura);
    getAsignaturasCurriculum(asignatura.sectorId, asignatura.gradoId).then(
      (response) => {
        console.log(response);
      }
    );
  }, []);

  const filterAsignaturas = useCallback(() => {
    return listaAsignaturas.filter((asignatura) => {
      return (
        (sectorFilter.length === 0 ||
          sectorFilter.map((s) => s.name).includes(asignatura.sectorNombre)) &&
        (gradoFilter.length === 0 ||
          gradoFilter.map((g) => g.name).includes(asignatura.gradoNombre)) &&
        (cursoFilter.length === 0 ||
          cursoFilter.map((c) => c.name).includes(asignatura.cursoNombre))
      );
    });
  }, [listaAsignaturas, sectorFilter, gradoFilter, cursoFilter]);

  const [filteredAsignaturas, setFilteredAsignaturas] =
    useState(listaAsignaturas);

  const filteredData = filteredAsignaturas.map((asignatura, index) => {
    return {
      id: index,
      value: [
        // asignatura.asignaturaId,
        index + 1,
        asignatura.sectorNombre,
        asignatura.gradoNombre,
        // asignatura.cursoNombre,
        asignatura.asignaturaCodigoMineduc,
        <div
          style={{
            backgroundColor: colorDict[asignatura.asignaturaNombre],
            color: "white",
            padding: "3px 5px",
            borderRadius: "4px",
            display: "inline-block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginRight: "10px",
            minWidth: "250px",
          }}
        >
          {asignatura.asignaturaNombre}
        </div>,
        <div style={{ display: "flex", justifyContent: "center", gap: 5 }}>
          <Button
            disabled
            variant={
              isDirectivo ? "color-amarillo-outline" : "color-acento-outline"
            }
            className="me-2"
            onClick={() => handleGetAsignatura(asignatura)}
          >
            <span style={{ textAlign: "center" }}>
              <FontAwesomeIcon icon={isDirectivo ? faPenToSquare : faEye} />
            </span>
          </Button>
          <Button
            style={{ display: isDirectivo ? "block" : "none" }}
            disabled
            variant="color-eliminar-outline"
          >
            <span style={{ textAlign: "center" }}>
              <FaTrash />
            </span>
          </Button>
        </div>,
      ],
    };
  });

  const customSort = useCallback(
    (rowA, rowB) => {
      if (isSpecialSortingRequired) {
        return multiSortAsignaturas(rowA.original, rowB.original);
      }
      return 0;
    },
    [isSpecialSortingRequired]
  );

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      try {
        const promises = [];

        if (listaAsignaturasState.length === 0)
          promises.push(getCursoAsignatura());
        if (listaSectoresState.length === 0)
          promises.push(cargarListaSectores());
        if (listaGradosState.length === 0) promises.push(getListadoGrados());
        if (listadoCursosState.length === 0) promises.push(getListadoCursos());

        const responses = await Promise.all(promises);

        let asignaturas = listaAsignaturasState;
        if (listaAsignaturasState.length === 0) {
          asignaturas = responses[0];
          dispatch(setAsignaturas(asignaturas));
          setListaAsignaturas(asignaturas);
        } else {
          setListaAsignaturas(listaAsignaturasState);
        }

        if (listaSectoresState.length === 0 && responses[1]) {
          dispatch(setSectores(responses[1]));
          setListaSectores(responses[1]);
        } else {
          setListaSectores(listaSectoresState);
        }

        if (listaGradosState.length === 0 && responses[2]) {
          dispatch(setGrados(responses[2].data.curriculumGrados));
          setListaGrados(responses[2].data.curriculumGrados);
        } else {
          setListaGrados(listaGradosState);
        }

        if (listadoCursosState.length === 0 && responses[3]) {
          dispatch(setCursos(responses[3].data));
          setListadoCursos(responses[3].data);
        } else {
          setListadoCursos(listadoCursosState);
        }
        if (asignaturas.length > 0) {
          const newColorDict = asignaturas.reduce((acc, asignatura) => {
            acc[
              asignatura.asignaturaNombre
            ] = `#${asignatura.asignaturaColorFondo}`;
            return acc;
          }, {});
          setColorDict(newColorDict);
        }
      } catch (error) {
        console.error("Error al obtener las asignaturas:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, [
    diccionarioColoresAsignaturasState,
    dispatch,
    listaAsignaturasState,
    listaGradosState,
    listaSectoresState,
    listadoCursosState,
  ]);

  useEffect(() => {
    setFilteredAsignaturas(filterAsignaturas());
  }, [sectorFilter, gradoFilter, cursoFilter, filterAsignaturas]);

  useEffect(() => {
    console.log(showFilters);
  }, [showFilters]);

  return (
    <Container
      fluid
      className="bg-white p-3"
      style={{ height: "90dvh", overflow: "hidden" }}
    >
      {isMobile && <Title text={"Lista Funcionarios"} />}
      <Row style={{ position: "relative", padding: ".6rem" }}>
        {!isMobile && (
          <Button
            variant={!showFilters ? "color-acento-outline" : "color-acento"}
            className="w-auto w-sm-100"
            onClick={() => setShowFilters(!showFilters)}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              position: "absolute",
              left: 15,
              top: 10,
            }}
          >
            <FontAwesomeIcon icon={faFilter} /> Filtros
          </Button>
        )}

        {!isMobile && <Title text={"Lista Asignaturas"} />}

        {!isMobile && isDirectivo && (
          <Button
            variant="color-acento"
            className="w-auto w-sm-100"
            onClick={() => navigate("/ficha-asignatura")}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              position: "absolute",
              right: 15,
              top: 10,
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Agregar
          </Button>
        )}

        {isMobile && (
          <Col xs={12} md={1} className="py-1 mb-5">
            <Button
              variant={!showFilters ? "color-acento-outline" : "color-acento"}
              className="w-auto w-sm-100"
              onClick={() => setShowFilters(!showFilters)}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                position: "absolute",
                left: 15,
                top: 10,
              }}
            >
              <FontAwesomeIcon icon={faFilter} /> Filtros
            </Button>
          </Col>
        )}
       {isDirectivo && <Button
          variant="color-acento"
          className="w-auto w-sm-100"
          onClick={() => navigate("/ficha-asignatura")}
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            position: "absolute",
            right: 15,
            top: 10,
            // display: isDirectivo ? "block" : "none",
            
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Agregar
        </Button>}
      </Row>
      {showFilters && (
        <Row className="w-100 gap-2 py-2 d-flex justify-content-start align-content-center">
          <Col className="col-12 col-md-2">
            <SelectMultiple
              placeholder="Sector"
              elements={
                listaSectores.map((s) => ({
                  id: s.sectorId,
                  name: s.sectorNombre,
                })) || []
              }
              selectedElements={sectorFilter}
              onSelect={setSectorFilter}
              labelKey="name"
              idKey="id"
              dropDirection="down"
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
              hideLabel
            />
          </Col>

          <Col className="col-12 col-md-2">
            <SelectMultiple
              placeholder="Grado"
              elements={
                listaGrados.map((g) => ({
                  id: g.gradoId,
                  name: g.gradoNombre,
                })) || []
              }
              selectedElements={gradoFilter}
              onSelect={setGradoFilter}
              labelKey="name"
              idKey="id"
              dropDirection="down"
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
              hideLabel
            />
          </Col>
          <Col className="col-12 col-md-2">
            <SelectMultiple
              placeholder="Curso"
              elements={
                listadoCursos
                  .filter((curso) => curso.gradoId === gradoFilter[0]?.id)
                  .map((item) => ({
                    id: item.cursoId,
                    name: item.cursoNombre,
                  })).length > 0
                  ? listadoCursos
                      .filter((curso) => curso.gradoId === gradoFilter[0]?.id)
                      .map((item) => ({
                        id: item.cursoId,
                        name: item.cursoNombre,
                      }))
                  : listadoCursos.map((item) => ({
                      id: item.cursoId,
                      name: item.cursoNombre,
                    })) || []
              }
              selectedElements={cursoFilter}
              onSelect={setCursoFilter}
              labelKey="name"
              idKey="id"
              dropDirection="down"
              style={{
                border: "1px solid var(--color-acento)",
                color: "var(--color-texto-oscuro)",
              }}
              hideLabel
            />
          </Col>
        </Row>
      )}
      {filteredData.length === 0 && !isLoading ? (
        <div
          className="text-center d-flex justify-content-center align-items-center"
          style={{ height: "70dvh" }}
        >
          <p>No se encontraron resultados para los filtros aplicados.</p>
        </div>
      ) : (
        <>
          {isLoading ? (
            <div
              className="text-center d-flex justify-content-center align-items-center"
              style={{ height: "70dvh" }}
            >
              <CustomSpinner />
            </div>
          ) : (
            <DynamicTable
              headers={headers}
              data={filteredData}
              options={{
                pageSize: 50,
                checkboxSelection: false,
                searchInput: true,
                searchInputPlaceholder: "Buscar...",
                showItemsPerPageSelector: true,
                showPagination: true,
                copyClipboard: true,
                stickyHeaders: true,
                stickyOffset: "-2px",
                customSort: customSort,
              }}
            />
          )}
        </>
      )}
    </Container>
  );
}

export default ListaAsignaturas;
