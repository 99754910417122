import { useState } from 'react';
import { saveAsistenciaClaseCurso } from '../services/PostsService';

const useSaveAsistenciaClaseCurso = () => {
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(null);

  const guardarAsistenciaCurso = async (data) => {
    console.log('Datos recibidos para guardar:', data);  // Para ver los datos recibidos

    setCargando(true);
    setError(null);
    try {
      const response = await saveAsistenciaClaseCurso(data);
      console.log('Respuesta del servidor:', response.data);  // Para ver la respuesta del servidor
      return response.data;
    } catch (error) {
      console.error('Error al guardar la asistencia del curso:', error);  // Para ver errores
      setError(error.message || 'Error al guardar la asistencia del curso');
      throw error;
    } finally {
      setCargando(false);
    }
  };

  return { guardarAsistenciaCurso, cargando, error };
};

export default useSaveAsistenciaClaseCurso;
