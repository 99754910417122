                          const estilosEstado = {
    // presente: { backgroundColor: "#EFFCE8", icon: "bi bi-check2-square", iconColor: "var(--color-verde)", description: "Presente" },
    presente: { backgroundColor: "var(--color-nav-hover)", icon: "fa-regular fa-circle-check", iconColor: "var(--color-acento)", description: "En aula" },
    atrasado: { backgroundColor: "#E1D9F2", icon: "fa-regular fa-clock", iconColor: "var(--color-morado)", description: "Atrasado" },
    retirado: { backgroundColor: "#FFF6E8", icon: "bi bi-person-walking", iconColor: "var(--color-amarillo)", description: "Retirado" },
    pendiente: { backgroundColor: "#F0F8FF", icon: "fa-brands fa-product-hunt", iconColor: "var(--color-icon-tooltip)", description: "Pendiente" },
    fueraDeAula: { backgroundColor: "#E8F4FF", icon: "fa-solid fa-door-open", iconColor: "var(--color-apoyo)", description: "En otro espacio " },
    ausente: { backgroundColor: "#FFDCDB", icon: "fa-regular fa-circle-xmark", iconColor: "var(--color-eliminar)", description: "Ausente" },
  };
  
  export default estilosEstado;
  