import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import TextInputMultiple from '../../../../components/inputs/TextInputMultiple'
import CheckboxInput from '../../../../components/inputs/CheckboxInput'
import TextInput from '../../../../components/inputs/TextInput'
import PhoneNumberInput from '../../../../components/inputs/PhoneNumberInput'
import SelectSingle from '../../../../components/inputs/SelectSingle'

const bloodGroups = [
  { id: 1, name: "A+" },
  { id: 2, name: "A-" },
  { id: 3, name: "B+" },
  { id: 4, name: "B-" },
  { id: 5, name: "AB+" },
  { id: 6, name: "AB-" },
  { id: 7, name: "O+" },
  { id: 8, name: "O-" },
];

const listaEnfermedades = [
  "Asma",
  "Diabetes",
  "Epilepsia",
  "Hipertensión",
  "VIH",
];

const listaMedicamentos = [
  "Paracetamol",
  "Ibuprofeno",
  "Amoxicilina",
  "Omeprazol",
  "Dipirona",
];

const listaAlergias = ["Polen", "Ácaros", "Picaduras", "Polvo", "Pasto"];

function TabSalud({
  valoresEstudiante,
  setValoresEstudiante,
  listaNacionalidades,
  listaVinculos
}) {


  const [checkedState, setCheckedState] = useState(
    new Array(listaMedicamentos.length).fill(false)
  );

  const handleCheckboxChange = (index) => {
    const updatedCheckedState = checkedState.map((item, i) =>
      i === index ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };


  return (
    <section style={{ display: "flex", justifyContent: "center" }}>
    <Form style={{ width: "100%", height: "55dvh" }}>
      <Row style={{ height: "100%" }}>
        <Col md={3} style={{ height: "100%", padding: "0" }}>
          <section style={{ height: "100%", overflowY: "auto" }}>
            <div
              style={{
                display: "flex",
                width: "80%",
                margin: "0 auto",
                flexDirection: "column",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h5>Enfermedades</h5>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {listaEnfermedades.map((enfermedad, index) => (
                    <CheckboxInput
                      key={index}
                      controlId={`enfermedad-${index}`}
                      label={enfermedad}
                      onChange={(e) => {}}
                      disabled
                    />
                  ))}
                </div>
              </div>
              <TextInputMultiple
                controlId="medicamentos"
                label="Otras enfermedades"
                value={valoresEstudiante.medicamentos}
                onChange={(e) => {}}
                maxLength={50}
                disabled
              />
            </div>
          </section>
        </Col>

        <Col md={3} style={{ height: "100%", padding: "0" }}>
          <section style={{ height: "100%", overflowY: "auto" }}>
            <div
              style={{
                display: "flex",
                width: "80%",
                margin: "0 auto",
                flexDirection: "column",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h5>Medicamentos</h5>
                <div style={{ width: "100%" }}>
                  {listaMedicamentos.map((enfermedad, index) => (
                    <div key={index} style={{ marginBottom: "1rem" }}>
                      <CheckboxInput
                        controlId={`enfermedad-${index}`}
                        label={enfermedad}
                        checked={checkedState[index]}
                        onChange={() => handleCheckboxChange(index)}
                        disabled
                      />

                      <div
                        style={{
                          display: checkedState[index]
                            ? "flex"
                            : "none",
                          height: "35px",
                          marginTop: "10px",
                          gap: "2px",
                          justifyContent: "center",
                        }}
                      >
                        {[...Array(4)].map((_, i) => (
                          <Form.Control
                            key={i}
                            type="time"
                            placeholder={`Hora ${i + 1}`}
                            style={{
                              borderRadius: "5px",
                              height: "25px",
                              width: "95px",
                              fontSize: "12px",
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <TextInputMultiple
                controlId="medicamentos"
                label="Otros medicamentos"
                value={valoresEstudiante.medicamentos}
                onChange={(e) => {}}
                maxLength={50}
                withHours
                disabled
              />
            </div>
          </section>
        </Col>
        <Col md={3} style={{ height: "100%", padding: "0" }}>
          <section style={{ height: "100%", overflowY: "auto" }}>
            <div
              style={{
                display: "flex",
                width: "80%",
                margin: "0 auto",
                flexDirection: "column",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h5>Alergias</h5>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {listaAlergias.map((enfermedad, index) => (
                    <CheckboxInput
                      key={index}
                      controlId={`enfermedad-${index}`}
                      label={enfermedad}
                      onChange={(e) => {}}
                      disabled
                    />
                  ))}
                </div>
              </div>
              <TextInputMultiple
                controlId="medicamentos"
                label="Otras alergias"
                value={valoresEstudiante.medicamentos}
                onChange={(e) => {}}
                maxLength={50}
                disabled
              />
            </div>
          </section>
        </Col>
        <Col md={3} style={{ height: "100%", padding: "0 " }}>
          <section style={{ height: "100%", overflowY: "auto" }}>
            <div
              style={{
                display: "flex",
                width: "80%",
                margin: "0 auto",
                flexDirection: "column",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h5>Emergencias</h5>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "1rem",
                  }}
                >
                  <Row style={{ width: "92%" }}>
                    <TextInput
                      controlId="nombreContactoEmergencia"
                      type="text"
                      maxLength={25}
                      label="Contacto Emergencia"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <PhoneNumberInput
                      onChange={(event) => console.log(event)}
                      value={""}
                      maxLength={15}
                      countries={listaNacionalidades}
                      disabled
                    />
                  </Row>
                  <Row style={{ width: "92%" }}>
                    <SelectSingle
                      controlId="parentesco"
                      placeholder="Parentesco"
                      elements={listaVinculos.map((item) => ({
                        id: item.id,
                        name: item.name,
                      }))}
                      idKey="id"
                      labelKey="name"
                      disabled
                    />
                  </Row>
                  <Row style={{ width: "92%" }}>
                    <SelectSingle
                      controlId="grupoSanguineoEstudiante"
                      placeholder="Grupo Sanguíneo"
                      elements={bloodGroups.map((item) => ({
                        id: item.id,
                        name: item.name,
                      }))}
                      idKey="id"
                      labelKey="name"
                      disabled
                    />
                  </Row>
                </section>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Form>
  </section>
  )
}

export default TabSalud