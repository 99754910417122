import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import estilosEstado from "../../objetoEstilos/estiloEstados";

const estilosEncabezado = {
  default: {
    color: "rgb(28, 40, 51)",
    fontWeight: "500",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
};

const ResumenAsistencia = ({ datos }) => {
  const [resumen, setResumen] = useState({
    presente: 0,
    atrasado: 0,
    retirado: 0,
    fueraDeAula: 0,
    ausente: 0,
    estudiantes: {
      presente: [],
      ausente: [],
      atrasado: [],
      retirado: [],
      fueraDeAula: [],
    },
  });

  const formatearNumero = (numero) => {
    return numero > 0 ? numero.toString().padStart(2, '0') : numero.toString();
  };

  useEffect(() => {
    const nuevoResumen = {
      presente: 0,
      atrasado: 0,
      retirado: 0,
      fueraDeAula: 0,
      ausente: 0,
      estudiantes: {
        presente: [],
        ausente: [],
        atrasado: [],
        retirado: [],
        fueraDeAula: [],
      },
    };

    datos.forEach((estudiante) => {
      if (nuevoResumen.estudiantes.hasOwnProperty(estudiante.estado)) {
        nuevoResumen[estudiante.estado]++;
        nuevoResumen.estudiantes[estudiante.estado].push(estudiante.nombre);
      }
    });

    setResumen(nuevoResumen);
  }, [datos]);

  return (
    <Container className="mx-1 px-1">
      <Table
        bordered
        hover
        style={{
          borderCollapse: "collapse",
          pointerEvents: "none",
          borderLeft: "none",
          borderRight: "none",
        }}
      >
        <tbody>
          {Object.keys(resumen)
            .filter((key) => key !== "estudiantes")
            .map((estado) => (
              <React.Fragment key={estado}>
                <tr style={{ borderTop: "2px solid white" }}>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "none",
                      padding: "4px 8px",
                      paddingLeft: "20px",
                      fontWeight: "bold",
                      borderTop: "1px solid white",
                      ...estilosEncabezado.default,
                    }}
                  >
                    <div
                      style={{
                        color: estilosEstado[estado]?.iconColor || "black",
                        width: "25px",
                        height: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "8px",
                      }}
                    >
                      <i
                        className={`${estilosEstado[estado]?.icon}`}
                        style={{ fontSize: "1.2em" }}
                      ></i>
                    </div>
                    {estado === 'fueraDeAula' ? `${estilosEstado[estado]?.description}:` : `${estilosEstado[estado]?.description}:`}
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      textAlign: "right",
                      fontWeight: "bold",
                      border: "none",
                      padding: "4px 8px",
                      paddingRight: "50px",
                      borderTop: "1px solid white",
                      ...estilosEncabezado.default,
                    }}
                  >
                    {formatearNumero(resumen[estado])}
                  </td>
                </tr>
                {estado !== "presente" &&
                  resumen.estudiantes[estado].length > 0 && (
                    <tr
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      <td
                        colSpan="2"
                        style={{
                          textAlign: "right",
                          border: "none",
                          cursor: "default",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          paddingRight: "40px",
                          ...estilosEncabezado.default,
                        }}
                      >
                        {resumen.estudiantes[estado].map((nombreEstudiante) => (
                          <div
                            key={nombreEstudiante}
                            style={{ paddingBottom: "0.3rem" }}
                          >
                            {nombreEstudiante}
                          </div>
                        ))}
                      </td>
                    </tr>
                  )}
              </React.Fragment>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ResumenAsistencia;
