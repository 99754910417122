import React from 'react';
import { useLocation } from 'react-router-dom';
import estilosEstado from '../../../jsx/objetoEstilos/estiloEstados';

const GuiaEstados = () => {
  const location = useLocation();
  const isAsistenciaDiariaAsignaturaProfesor = location.pathname.includes('asistencia-diaria-asignatura-profesor');
  const isAsistenciaMensualEstudiantes = location.pathname.includes('asistencia-mensual-subvenciones-estudiantes');
  const isAsistenciaMensualCursos = location.pathname.includes('asistencia-mensual-subvenciones-cursos');

  return (
    <div className="d-flex justify-content-end p-1 guia-estados">
      {!isAsistenciaMensualCursos && (
        <>
          <div
            className="estado presente"
            style={{
              padding: '5px 10px',
              border: '1px solid var(--color-acento)',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              marginRight: '10px'
            }}
          >
            Presente:
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
              <div
                style={{
                  backgroundColor: estilosEstado.presente.backgroundColor,
                  width: '25px',
                  height: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '3px',
                  border: `1px solid ${estilosEstado.presente.iconColor}`,
                }}
              >
                <i
                  className={`fa ${estilosEstado.presente.icon}`}
                  style={{ color: estilosEstado.presente.iconColor, fontSize: '15px' }}
                ></i>
              </div>
              {!isAsistenciaMensualEstudiantes && (
                <>
                  +
                  <div
                    style={{
                      backgroundColor: estilosEstado.atrasado.backgroundColor,
                      width: '25px',
                      height: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: '0 3px',
                      border: `1px solid ${estilosEstado.atrasado.iconColor}`,
                    }}
                  >
                    <i
                      className={`fa ${estilosEstado.atrasado.icon}`}
                      style={{ color: estilosEstado.atrasado.iconColor, fontSize: '15px' }}
                    ></i>
                  </div>
                  +
                  <div
                    style={{
                      backgroundColor: estilosEstado.retirado.backgroundColor,
                      width: '25px',
                      height: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      border: `1px solid ${estilosEstado.retirado.iconColor}`,
                    }}
                  >
                    <i
                      className={`bi ${estilosEstado.retirado.icon}`}
                      style={{ color: estilosEstado.retirado.iconColor, fontSize: '15px' }}
                    ></i>
                  </div>
                  +
                  <div
                    style={{
                      backgroundColor: estilosEstado.fueraDeAula.backgroundColor,
                      width: '25px',
                      height: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '3px',
                      border: `1px solid ${estilosEstado.fueraDeAula.iconColor}`,
                    }}
                  >
                    <i
                      className={`fa ${estilosEstado.fueraDeAula.icon}`}
                      style={{ color: estilosEstado.fueraDeAula.iconColor, fontSize: '15px' }}
                    ></i>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className="estado ausente"
            style={{
              padding: '5px 10px',
              border: '1px solid #E32B1D',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              marginRight: '10px'
            }}
          >
            Ausente:
            <div
              style={{
                backgroundColor: estilosEstado.ausente.backgroundColor,
                width: '25px',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '5px',
                border: `1px solid ${estilosEstado.ausente.iconColor}`,
              }}
            >
              <i
                className={`fa ${estilosEstado.ausente.icon}`}
                style={{ color: estilosEstado.ausente.iconColor, fontSize: '15px' }}
              ></i>
            </div>
          </div>
        </>
      )}
      {!isAsistenciaDiariaAsignaturaProfesor && (
        <div
          className="estado pendiente"
          style={{
            padding: '5px 10px',
            border: '1px solid var(--color-icon-tooltip)',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          Pendiente:
          <div
            style={{
              backgroundColor: estilosEstado.pendiente.backgroundColor,
              width: '25px',
              height: '25px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '5px',
              border: `1px solid ${estilosEstado.pendiente.iconColor}`,
            }}
          >
            <i
              className={`fa ${estilosEstado.pendiente.icon}`}
              style={{ color: estilosEstado.pendiente.iconColor, fontSize: '15px' }}
            ></i>
          </div>
        </div>
      )}
    </div>
  );
};

export default GuiaEstados;
