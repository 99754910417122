import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import TextInput from "../../components/inputs/TextInput";

const ModalCrearEvento = ({ visible, onClose, evento, onGuardar }) => {
  const [titulo, setTitulo] = useState(evento?.title || "");
  const [descripcion, setDescripcion] = useState(evento?.description || "");
  const [showAlert, setShowAlert] = useState(false); 
  const [errorTitulo, setErrorTitulo] = useState(""); 

  const handleGuardarEvento = () => {
    if (titulo.trim() === "") {
      setErrorTitulo("El título del evento es obligatorio.");
      return;
    }

    setErrorTitulo(""); 
    // Mostrar alerta en lugar de guardar el evento (ya que está en desarrollo)
    setShowAlert(true);

    // Aquí podrías implementar la lógica para guardar el evento
    /*
    const nuevoEvento = {
      ...evento,
      title: titulo,
      description: descripcion,
    };
    onGuardar(nuevoEvento);
    onClose();
    */
  };

  return (
    <Modal centered show={visible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Crear nuevo evento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          {/* Campo para el Título */}
          <TextInput
            controlId="formTituloEvento"
            label="Título del evento"
            name="titulo"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            required={true}
            isInvalid={!!errorTitulo} 
            placeholder="Ingresa el título del evento"
            tooltipMessage={errorTitulo} 
          />

          {/* Campo para la Descripción */}
          <TextAreaInput
            controlId="formDescripcionEvento"
            label="Descripción del evento"
            name="descripcion"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            placeholder="Ingresa la descripción del evento"
            rows={3}
          />

          {/* Campo para Fecha y Hora */}
          <div className="pt-2">
            <label>Fecha y hora</label>
            <p>
              Desde: {new Date(evento?.start).toLocaleString()} <br />
              Hasta: {new Date(evento?.end).toLocaleString()}
            </p>
          </div>
        </form>

        {/* Mostrar alerta si la funcionalidad está en desarrollo */}
        {showAlert && (
          <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
            La funcionalidad de guardar eventos aún está en desarrollo.
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer className="flex justify-content-between">
        <Button variant="outline-primary" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="outline-primary" onClick={handleGuardarEvento}>
          Guardar evento
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCrearEvento;
