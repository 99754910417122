import { useEffect, useState } from 'react';
import { getHorarioAnteriorSiguiente } from '../services/PostsService';

const useGetHorarioAnteriorSiguiente = ({ asignatura_id, clase_id }) => {
  const [horario, setHorario] = useState({ claseAnterior: null, claseSiguiente: null });
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHorario = async () => {
      setCargando(true);
      setError(null);
      try {
        const params = {
          clase_id
        };
        // console.log('Fetching horario con params:', params);  // Log para verificar la solicitud
        const response = await getHorarioAnteriorSiguiente(params);
        // console.log('Datos recibidos de la API:', response.data);  // Log para verificar los datos recibidos
        setHorario(response.data);
      } catch (error) {
        // console.error('Error fetching horario:', error.response?.data || error.message);  // Log para verificar el error
        setError(error);
      } finally {
        setCargando(false);
      }
    };

    if (asignatura_id && clase_id) {
      fetchHorario();
    }
  }, [asignatura_id, clase_id]);

  return { horario, cargando, error };
};

export default useGetHorarioAnteriorSiguiente;