import moment from "moment";
import "moment-timezone";
import "moment/locale/es";
import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../assets/css/bigcalendar.css";
import useGetHorarioDocente from "../../../hooks/useGetHorarioDocente";
import NotificacionError from "../notificacion/NotificacionError";
import CustomSpinner from "../spinner/Spinner";
import AgendaPersonalizada from "./AgendaPersonalizada";
import BarraHerramientasPersonalizada from "./BarraHerramientasPerzonalizada";
import EventoDiario from "./EventoDiario";
import EventoSemana from "./EventoSemana";
import ModalCrearEvento from "./ModalCrearEvento"; // Nuevo modal para crear eventos

moment.locale("es");
const localizer = momentLocalizer(moment);

const ReactBigCalendar = ({ onEventoSeleccionado, isModal = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const calendarRef = useRef(null);
  const observerRef = useRef(null);
  const { eventos, horaIniMin, horaFinMax, cargando, error } = useGetHorarioDocente();
  const [events, setEvents] = useState([]);
  const [fecha, setFecha] = useState(new Date());
  const [vista, setVista] = useState("week");
  const [showModalClaseFutura, setShowModalClaseFutura] = useState(false);
  const [showModalCrearEvento, setShowModalCrearEvento] = useState(false);
  const [newEvent, setNewEvent] = useState(null); // Para almacenar el evento nuevo seleccionado

  // Mensajes en español
  const messages = {
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    week: 'Semana',
    work_week: 'Semana laboral',
    day: 'Día',
    month: 'Mes',
    previous: 'Anterior',
    next: 'Siguiente',
    today: 'Hoy',
    agenda: 'Agenda',
    showMore: (total) => `+${total} eventos`,
  };

  useEffect(() => {
    if (!cargando && !error) {
      setEvents(eventos);
    }
    const fechaSeleccionada = localStorage.getItem("fechaSeleccionada");
    if (fechaSeleccionada) {
      setFecha(new Date(fechaSeleccionada));
      localStorage.removeItem("fechaSeleccionada");
    }
  }, [eventos, cargando, error]);

  // Modificar el texto "more" a "eventos"
  useEffect(() => {
    const modifyMoreElements = () => {
      const moreElements = document.querySelectorAll(".rbc-show-more");
      moreElements.forEach((el) => {
        el.style.color = "var(--color-acento)";
        el.style.fontSize = "18px";
        el.style.textAlign = "center";
        el.style.fontFamily = "Poppins";
        el.style.fontWeight = "600";
        el.textContent = el.textContent.replace("more", "eventos");
      });
    };

    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    const observer = new MutationObserver(modifyMoreElements);
    observerRef.current = observer;

    if (calendarRef.current) {
      observer.observe(calendarRef.current, { childList: true, subtree: true });
    }

    modifyMoreElements();

    return () => observer.disconnect();
  }, [events, vista]);
  useEffect(() => {
    if (vista === "month") {
      const moreElements = document.querySelectorAll(".rbc-show-more");
      moreElements.forEach((el) => {
        el.style.color = "var(--color-acento)";
        el.style.fontSize = "18px";
        el.style.textAlign = "center";
        el.style.fontFamily = "Poppins";
        el.style.fontWeight = "600";
        el.textContent = el.textContent.replace("more", "eventos");
      });
    }
  }, [vista]);

  if (cargando)
    return (
      <div className="text-center d-flex justify-content-center align-items-center " style={{ height: "90dvh" }}>
        <CustomSpinner />
      </div>
    );

  if (error) {
    return (
      <div className="myCalendarContainer" style={{ height: "110vh" }}>
        <Alert variant="danger">
          <p>
            Error al cargar los eventos <br />
            Usted no tiene un horario asignado.
          </p>
        </Alert>
      </div>
    );
  }

  const cambiarAVistaDiaria = (fecha) => {
    setFecha(fecha);
    setVista("day");
  };

  // Manejar selección de evento existente (clase futura)
  const manejarSeleccionEvento = (event) => {
    const start = new Date(event.start);
    const end = new Date(event.end);
    const now = moment().tz("America/Santiago").toDate();

    if (event.type === "Clase" && start > now) {
      setShowModalClaseFutura(true); // Abrir el modal de clase futura
      return;
    }

    if (vista === "month") {
      cambiarAVistaDiaria(event.start);
    } else if (event.type === "Clase") {
      if (isModal) {
        onEventoSeleccionado({
          ...event,
          asignaturaColor: event.asignaturaColorFondo || "#defaultColor", 
        });
      } else {
        localStorage.setItem("fechaSeleccionada", start.toISOString());
        navigate("/asistencia-diaria-asignatura-profesor", {
          state: {
            fecha: event.start,
            curso: event.curso,
            bloque_numero: event.num_bloque,
            asignatura: event.asignatura,
            clase_id: event.id,
            curso_id: event.cursoId,
            asignatura_id: event.asignaturaId,
            start: start,
            end: end,
            estadoClaseId: event.estadoClaseId,
            asignaturaColor: event.asignaturaColorFondo || "#1E78FF",
            from: location.pathname,
          },
        });
      }
    }
  };

  // Selección de un rango (slot) en el calendario para crear evento
  const manejarSeleccionSlot = (slotInfo) => {
    setNewEvent({
      title: "",
      start: slotInfo.start,
      end: slotInfo.end,
    });
    setShowModalCrearEvento(true); // Mostrar modal para crear evento
  };

  const horaMinima = new Date();
  const [horaIniHoras, horaIniMinutos] = horaIniMin.split(":");
  horaMinima.setHours(parseInt(horaIniHoras), parseInt(horaIniMinutos), 0);

  const horaMaxima = new Date();
  const [horaFinHoras, horaFinMinutos] = horaFinMax.split(":");
  horaMaxima.setHours(parseInt(horaFinHoras) + 1, parseInt(horaFinMinutos), 0);

  const formats = {
    dayFormat: (date, culture, localizer) =>
      `${localizer.format(date, "dddd", culture)} ${localizer.format(
        date,
        "DD",
        culture
      )}`,
    monthHeaderFormat: (date, culture, localizer) =>
      `${localizer.format(date, "MMMM YYYY", culture)}`,
    dayHeaderFormat: (date, culture, localizer) =>
      `${localizer.format(date, "dddd", culture)}, ${localizer.format(
        date,
        "DD",
        culture
      )} de ${localizer.format(date, "MMMM", culture)}`,
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
      const startDate = moment(start).startOf("isoWeek").add(0, "days"); // Lunes
      const endDate = moment(start).startOf("isoWeek").add(4, "days"); // Viernes
      return `${localizer.format(
        startDate.toDate(),
        "DD",
        culture
      )} ${localizer.format(
        startDate.toDate(),
        "MMMM",
        culture
      )} - ${localizer.format(
        endDate.toDate(),
        "DD",
        culture
      )} ${localizer.format(endDate.toDate(), "MMMM", culture)}`;
    },
  };

  const eventPropGetter = (event, start, end, isSelected) => ({
    style: {
      color: "var(--color-acento)",
    },
  });

  const monthPropGetter = (date) => ({
    style: {
      color: "var(--color-acento)",
    },
  });

  return (
    <div className="myCalendarContainer" style={{ height: "110vh", position: "relative" }} ref={calendarRef}>
      {/* Modal para clase futura */}
      <NotificacionError
        visible={showModalClaseFutura}
        onClose={() => setShowModalClaseFutura(false)}
        mensaje="Clase futura seleccionada."
      />

      {/* Modal para crear un nuevo evento */}
      <ModalCrearEvento
        visible={showModalCrearEvento}
        onClose={() => setShowModalCrearEvento(false)}
        evento={newEvent} // Pasar los detalles del nuevo evento
      />

      <Calendar
        localizer={localizer}
        messages={messages} // Aquí agregamos el objeto de mensajes para cambiar los textos
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "calc(100% - 50px)" }} // Ajustamos el tamaño para dejar espacio para la barra sticky
        views={{ month: true, week: true, day: true, agenda: true }}
        defaultView={vista}
        min={horaMinima}
        max={horaMaxima}
        date={fecha}
        formats={formats}
        onSelectEvent={manejarSeleccionEvento}
        onSelectSlot={manejarSeleccionSlot} // Manejador para seleccionar un slot
        onView={setVista}
        onNavigate={setFecha}
        step={20}
        timeslots={3}
        components={{
          event: (props) => {
            if (vista === 'week') {
              return <EventoSemana {...props} />;
            } else if (vista === 'day') {
              return <EventoDiario {...props} />;
            } else if (vista === 'agenda') {
              return <AgendaPersonalizada {...props} events={events} />; // Usamos la vista personalizada para la agenda
            }
          },
          toolbar: (props) => <BarraHerramientasPersonalizada {...props} isModal={isModal} />,
        }}
        selectable
        eventPropGetter={eventPropGetter}
        monthPropGetter={monthPropGetter}
      />

      {/* Zona sticky para agregar eventos solo en la vista de semana y agenda */}
      {( vista === "agenda") && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            padding: "10px",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <button
            onClick={() => setShowModalCrearEvento(true)}
            style={{
              backgroundColor: "var(--color-acento)",
              color: "#fff",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            + Agregar Evento
          </button>
        </div>
      )}
    </div>
  );
};

export default ReactBigCalendar;
