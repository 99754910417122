import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import React, { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import useGetDataRol from "../../../hooks/useGetDataRol";
import HorarioDocente from "../../components/horarioDocente/HorarioDocente";

const University = loadable(() =>
    pMinDelay(import("../../elements/University"), 500)
);

const Home = (props) => {
    const { data, loading, error } = useGetDataRol();
    const storedCursoId = JSON.parse(localStorage.getItem('currentRolData'))?.defaultCursoId;
    const [cursoId, setCursoId] = useState(props.cursoId || storedCursoId);

    useEffect(() => {
        if (props.cursoId) {
            setCursoId(props.cursoId);
        } else if (storedCursoId) {
            setCursoId(storedCursoId);
        }
        console.log("Datos recuperados de currentRolData:", JSON.parse(localStorage.getItem('currentRolData')));
        console.log("Curso ID seleccionado:", cursoId);
    }, [props.cursoId, storedCursoId]);

    return (
        <>
            <Row>
                <Col xl={'8'} xxl={'8'} lg={'12'} sm={'12'}>
                    <div className="card text-center" style={{ margin: 0, padding: 0, border: 'none' }}>
                        <div className="card-body p-0" style={{ position: 'relative', overflow: 'hidden' }}>
                            {loading ? (
                               <></>
                            ) : error ? (
                                <Alert variant="danger">
                                    <p>Error al cargar datos:<br/> Faltan parámetros requeridos para el correcto funcionamiento del calendario.</p>
                                </Alert>
                            ) : (
                                <HorarioDocente className="col-12" cursoId={cursoId} />
                            )}
                        </div>
                    </div>
                </Col>
                <Col xl={"4"} xxl={"4"} sm={"4"}>
                    <div className="card" style={{ margin: 0, padding: 0, border: 'none', height: 500 }}>
                        <div className="card-header">
                            <h3 className="card-title">Estadísticas Escolares</h3>
                        </div>
                        <div className="card-body">
                            <University />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Home;
