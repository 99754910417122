import React from "react";

const Title =(({text, fontSize = '22px', padding = "0"}) => {
  const estilos = {
    titulo: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 500,
      fontSize: fontSize,
      lineHeight: "34px",
      color: "var(--color-texto-oscuro)",
      marginBottom: 0,
      textOverflow: "ellipsis",
      textWrap: "nowrap",
      padding
    },
  };
  return (
    <center  style={estilos.titulo}>
      {text}
    </center>
  );
});

export default Title;
