import React from "react";
import InConstruction from "../../../../components/construction/InConstruction";

function TabHabilidades() {
  return (
    <InConstruction />
    // <div style={{ height: "75dvh" }}>
    //   <article className="d-flex flex-column flex-md-row justify-content-start align-items-center w-100 py-2 ">
        
    //   </article>
    // </div>
  );
}

export default TabHabilidades;
