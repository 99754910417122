import React from 'react';
import { Button } from 'react-bootstrap';

const ButtonPersonalizado = ({ variant, className, onClick, children }) => {
  const buttonStyle = {
    width: '120px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10px'
  };

  return (
    <Button
      variant={variant}
      className={className}
      style={buttonStyle}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ButtonPersonalizado;