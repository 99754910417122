// eslint-disable-next-line no-console
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenSquare,
  faPlusCircle,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CustomTooltip from "../../../../components/tooltip/Tooltip";
import TextInput from "../../../../components/inputs/TextInput";
import SelectSingle from "../../../../components/inputs/SelectSingle";
import DynamicTable from "../../../../components/tables/DynamicTable/Table";
import Swal from "sweetalert2";
import { deleteAntecedenteAcademico } from "../../../../../services/PostsService";
import InConstruction from "../../../../components/construction/InConstruction";

function TabAcademico({
  anios,
  nivelesAcademicos,
  funcionario,
  setFuncionario,
}) {
  const years = anios.map((year) => ({ id: year.nombre, name: year.nombre }));

  // Aquí usamos directamente el estado de funcionario.antecedentesAcademicos para asegurarnos de que cualquier edición se refleje inmediatamente en el estado principal.
  const antecedentesAcademicos = React.useMemo(
    () => funcionario.antecedentesAcademicos || [],
    [funcionario.antecedentesAcademicos]
  );

  const [data, setData] = useState(antecedentesAcademicos);

  const [nivelAcademico, setNivelAcademico] = useState(null);
  const [institucion, setInstitucion] = useState("");
  const [titulo, setTitulo] = useState("");
  const [duracion, setDuracion] = useState("");
  const [anioEgreso, setAnioEgreso] = useState(null); // Iniciar en null para manejar correctamente la selección
  const [editIndex, setEditIndex] = useState(null);

  const handleAddOrEdit = () => {
    if (
      !nivelAcademico ||
      !institucion ||
      !titulo ||
      !duracion ||
      !anioEgreso
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        toast: true,
        timer: 2000,
        showConfirmButton: false,
        text: "Todos los campos son obligatorios.",
      });
      return;
    }

    const updatedAntecedentes = [...data];

    const newItem = {
      id: updatedAntecedentes[editIndex]?.id || null,
      idNivelAcademico: nivelAcademico?.id,
      nivelNombre: nivelAcademico?.nivelNombre,
      institucion,
      titulo,
      duracion,
      anioEgreso: anioEgreso?.name,
      antecedenteOrden: updatedAntecedentes.length + 1, // Asignamos el orden correcto aquí
    };

    if (editIndex !== null) {
      updatedAntecedentes[editIndex] = newItem;
      setEditIndex(null);
    } else {
      updatedAntecedentes.push(newItem);
    }

    setFuncionario((prevFuncionario) => ({
      ...prevFuncionario,
      antecedentesAcademicos: updatedAntecedentes.map((item, index) => {
        return {
          ...item,
          antecedenteOrden: index + 1, // Aseguramos que el orden sea correlativo
        };
      }),
    }));

    // Limpiar los campos
    setNivelAcademico(null);
    setInstitucion("");
    setTitulo("");
    setDuracion("");
    setAnioEgreso(null);
  };

  const handleEdit = (index) => {
    const item = data[index];
    console.log("Item:", item);
    setNivelAcademico(
      nivelesAcademicos.find((nivel) => {
        // console.log("Nivel:", nivel.id);
        return nivel.id === item.idNivelAcademico;
      })
    );
    setInstitucion(item.institucion);
    setTitulo(item.titulo);
    setDuracion(item.duracion);
    setAnioEgreso(years.find((year) => year.name === item.anioEgreso));
    setEditIndex(index);
    console.log(
      nivelesAcademicos.find((nivel) => {
        return nivel.id === item.idNivelAcademico;
      })
    );
  };

  const handleDelete = useCallback(
    (index) => {
      const updatedAntecedentes = antecedentesAcademicos.filter(
        (_, i) => i !== index
      );

      console.log("Antecedentes:", antecedentesAcademicos);

      deleteAntecedenteAcademico(antecedentesAcademicos[index].id)
        .then((response) => {
          Swal.fire({
            title: "¿Estás seguro?",
            text: "No podrás revertir esto.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "var(--color-eliminar)",
            cancelButtonColor: "var(--color-acento)",
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              setFuncionario((prevFuncionario) => ({
                ...prevFuncionario,
                antecedentesAcademicos: updatedAntecedentes,
              }));
            }
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.message,
            confirmButtonColor: "var(--color-acento)",
          });
        });
    },
    [antecedentesAcademicos, setFuncionario]
  );
  const headers = [
    {
      name: "Nro.",
      width: "90px",
      textAlign: "center",
      sortable: true,
      sortType: "number",
      sortByDefault: true,
    },
    {
      name: "Nivel Académico",
      width: "200px",
      textAlign: "center",
      sortable: true,
      sortType: "string",
    },
    {
      name: "Institución",
      width: "auto",
      textAlign: "center",
      sortable: true,
      sortType: "string",
    },
    {
      name: "Título",
      width: "auto",
      textAlign: "center",
      sortable: true,
      sortType: "string",
    },
    {
      name: "Duración",
      width: "150px",
      textAlign: "center",
      sortable: true,
      sortType: "string",
    },
    {
      name: "Año de Egreso",
      width: "200px",
      textAlign: "center",
      sortable: true,
      sortType: "date",
    },
    { name: "Acciones", width: "150px", textAlign: "center", sortable: false },
  ];

  const filteredData = data
    .filter(
      (item) =>
        item.idNivelAcademico ||
        item.institucion ||
        item.titulo ||
        item.duracion ||
        item.anioEgreso
    )
    .map((item, index) => ({
      id: index,
      value: [
        item.antecedenteOrden || data.length,
        item.nivelNombre ||
          nivelesAcademicos.find((nivel) => nivel.id === item.idNivelAcademico)
            ?.nivelNombre,
        item.institucion,
        item.titulo,
        item.duracion,
        item.anioEgreso,
        <div
          style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}
        >
          <CustomTooltip tooltipMsg={"Editar"}>
            <Button
              variant="color-amarillo-outline"
              onClick={() => handleEdit(index)}
            >
              <FontAwesomeIcon icon={faPenSquare} />
            </Button>
          </CustomTooltip>
          <CustomTooltip tooltipMsg={"Eliminar"}>
            <Button
              variant="color-eliminar-outline"
              onClick={() => handleDelete(index)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </CustomTooltip>
        </div>,
      ],
    }));

  // useEffect(() => {
  //   console.log("Data:", data); // Verifica si el estado cambia correctamente
  // }, [data]);

  useEffect(() => {
    // Mantén el estado de antecedentes sincronizado con el de funcionario
    // console.log("Funcionario:", funcionario.antecedentesAcademicos);
    setData(funcionario.antecedentesAcademicos || []);
  }, [funcionario]);

  return (
    <div style={{ height: "75dvh" }}>
      <article className="w-100 d-flex justify-content-center align-items-center flex-column gap-3">
        <section style={{ flexWrap: "wrap", width: "100%" }} className="d-flex">
          <Row className="w-100">
            <Col className="col-12 col-md-2">
              <SelectSingle
                placeholder="Nivel Académico"
                idKey="id"
                labelKey="nivelNombre"
                elements={nivelesAcademicos}
                onSelect={(nivel) => setNivelAcademico(nivel)}
                selectedElement={nivelAcademico?.id || null}
                style={{
                  border: "1px solid var(--color-acento)",
                  color: "var(--color-texto-oscuro)",
                  width: "100%",
                }}
                hideLabel
              />
            </Col>
            <Col className="col-12 col-md-3">
              <TextInput
                placeholder="Institución"
                value={institucion}
                onChange={(e) => setInstitucion(e.target.value)}
                style={{
                  border: "1px solid var(--color-acento)",
                  color: "var(--color-texto-oscuro)",
                  fontWeight: 500,
                }}
                hideLabel
              />
            </Col>
            <Col className="col-12 col-md-2">
              <TextInput
                placeholder="Título"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                style={{
                  border: "1px solid var(--color-acento)",
                  color: "var(--color-texto-oscuro)",
                  fontWeight: 500,
                }}
                hideLabel
              />
            </Col>
            <Col className="col-12 col-md-2">
              <TextInput
                placeholder="Duración"
                value={duracion}
                onChange={(e) => setDuracion(e.target.value)}
                style={{
                  border: "1px solid var(--color-acento)",
                  color: "var(--color-texto-oscuro)",
                  fontWeight: 500,
                }}
                hideLabel
              />
            </Col>
            <Col className="col-12 col-md-2">
              <SelectSingle
                placeholder="Año de Egreso"
                idKey="id"
                labelKey="name"
                elements={years}
                onSelect={setAnioEgreso}
                selectedElement={anioEgreso?.id || null}
                style={{
                  border: "1px solid var(--color-acento)",
                  color: "var(--color-texto-oscuro)",
                }}
                hideLabel
              />
            </Col>
            <Col className="col-12 col-md-1 d-flex align-items-end">
              <Button
                className="w-100"
                variant="color-acento"
                onClick={handleAddOrEdit}
                style={{ textOverflow: "ellipsis", textWrap: "nowrap" }}
              >
                <FontAwesomeIcon
                  icon={editIndex !== null ? faSave : faPlusCircle}
                />
                {editIndex !== null ? " Guardar" : " Agregar"}
              </Button>
            </Col>
          </Row>
        </section>
        <section className="w-100">
          {filteredData.length > 0 && (
            <DynamicTable
              headers={headers}
              data={filteredData}
              options={{
                searchInput: true,
                searchInputPlaceholder: "Buscar...",
                showItemsPerPageSelector: true,
                showPagination: true,
                copyClipboard: true,
              }}
            />
          )}
        </section>
      </article>
    </div>
    // <InConstruction children="Tab Antecedentes" />
  );
}

export default TabAcademico;
