import React, { useCallback, useEffect, useRef, useState } from "react";
import useGetListadoCursos from "../../../hooks/useGetListadoCursos";
import useGetPeriodosAcademicos from "../../../hooks/useGetPeriodosAcademicos";
import { getNotasAlumnos } from "../../../services/PostsService";
import NavInformeEstudianteCurso from "../../components/informeCalificacionEstudianteCurso/NavInformeEstudianteCurso";
import TablaInformeEstudianteCurso from "../../components/informeCalificacionEstudianteCurso/TablaInformeEstudianteCurso";
import CustomSpinner from "../../components/spinner/Spinner";

const convertirAbreviatura = (nombre) => {
  switch (nombre) {
    case "Primer Semestre":
      return "1er Semestre";
    case "Segundo Semestre":
      return "2do Semestre";
    default:
      return nombre;
  }
};

const InformeCalificacionEstudianteCurso = () => {
  const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
  const [periodoSeleccionado, setPeriodoSeleccionado] = useState(null);
  const [isHeatmapActive, setIsHeatmapActive] = useState(false);
  const [filtroEstudiante, setFiltroEstudiante] = useState("");
  const [dataEstudiantesNotas, setDataEstudiantesNotas] = useState([]);
  const [dataColegio, setDataColegio] = useState([]);
  const [dataCurso, setDataCurso] = useState([]);
  const [cargandoNotas, setCargandoNotas] = useState(false);
  const [cantidadDecimales, setCantidadDecimales] = useState(null); 
  

  
  const initialLoadRef = useRef(false);
  const apiCallInProgressRef = useRef(false);

  const { cursos, cargando: cargandoCursos, error: errorCursos } = useGetListadoCursos();
  const { periodos, cargando: cargandoPeriodos, error: errorPeriodos } = useGetPeriodosAcademicos();

  const fetchNotasAlumnos = useCallback(async (cursoId, periodoId) => {
    if (!cursoId || !periodoId || apiCallInProgressRef.current) return;
    
    apiCallInProgressRef.current = true;
    setCargandoNotas(true);
    try {
        console.log("Llamando a getNotasAlumnos con:", cursoId, periodoId);
        const result = await getNotasAlumnos(cursoId, periodoId);
        
        console.log("Respuesta completa de getNotasAlumnos:", result);

        setDataEstudiantesNotas(result.listadoNotas);
        setDataColegio(result.dataColegio);
        setDataCurso(result.dataCurso);

        // Confirmamos si cantidadDecimales realmente está en el resultado antes de asignarlo
        if (result.cantidadDecimales !== undefined) {
            setCantidadDecimales(result.cantidadDecimales);
            console.log("Cantidad de decimales asignada correctamente:", result.cantidadDecimales);
        } else {
            console.warn("cantidadDecimales no está definido en la respuesta.");
        }
    } catch (error) {
        console.error("Error al obtener notas de alumnos:", error);
    } finally {
        setCargandoNotas(false);
        apiCallInProgressRef.current = false;
    }
}, []);


  useEffect(() => {
    if (!cargandoCursos && !cargandoPeriodos && cursos.length > 0 && periodos.length > 0 && !initialLoadRef.current) {
      const currentRolData = JSON.parse(localStorage.getItem("currentRolData"));
      const currentPeriodo = JSON.parse(localStorage.getItem("currentPeriodo"));
      
      if (currentRolData && currentPeriodo) {
        const newCurso = cursos.find(curso => curso.cursoId === currentRolData.defaultCursoId) || cursos[0];
        const newPeriodo = periodos.find(periodo => periodo.periodoAcademicoId === currentPeriodo.periodoAcademicoId) || periodos[0];
        
        console.log("Configuración inicial:", newCurso, newPeriodo);
        
        setCursoSeleccionado(newCurso);
        setPeriodoSeleccionado({
          id: newPeriodo.periodoAcademicoId,
          nombre: convertirAbreviatura(newPeriodo.periodoAcademicoNombre),
        });
        
        fetchNotasAlumnos(newCurso.cursoId, newPeriodo.periodoAcademicoId);
        initialLoadRef.current = true;
      }
    }
  }, [cursos, periodos, cargandoCursos, cargandoPeriodos, fetchNotasAlumnos]);

  const handleCursoSelect = useCallback((curso) => {
    if (curso.cursoId !== cursoSeleccionado?.cursoId) {
      console.log("Nuevo curso seleccionado:", curso);
      setCursoSeleccionado(curso);
      if (periodoSeleccionado) {
        fetchNotasAlumnos(curso.cursoId, periodoSeleccionado.id);
      }
    }
  }, [cursoSeleccionado, periodoSeleccionado, fetchNotasAlumnos]);

  const handlePeriodoSelect = useCallback((periodo) => {
    if (periodo.id !== periodoSeleccionado?.id) {
      console.log("Nuevo periodo seleccionado:", periodo);
      setPeriodoSeleccionado(periodo);
      if (cursoSeleccionado) {
        fetchNotasAlumnos(cursoSeleccionado.cursoId, periodo.id);
      }
    }
  }, [cursoSeleccionado, periodoSeleccionado, fetchNotasAlumnos]);

  // Extrae la lista de estudiantes de dataEstudiantesNotas
  const estudiantes = dataEstudiantesNotas.map(nota => {
    const estudiante = nota.estudiante;
//     if (estudiante.numMatricula === 255 ){
// console.log("run: ", estudiante.run )
//    console.log("runDv: ", estudiante.runDv )
//    console.log("runPro: ", estudiante.runProvisorio )
//    console.log("runProDv: ", estudiante.runProvisorioDv )
//     }
   


    return {
      id: estudiante.estudianteId,
      nombre: `${estudiante.nombrePrimario} ${estudiante.apellidoPrimario}`,
      run: estudiante.run ? `${estudiante.run}-${estudiante.runDv}` : null,
      ipe: estudiante.runProvisorio ? `${estudiante.runProvisorio}-${estudiante.runProvisorioDv}` : null,
      numLista: estudiante.numLista,
      numMatricula: estudiante.numMatricula,
    };
  });


  const estudiantesFiltrados = estudiantes.filter((estudiante) =>
    estudiante.nombre.toLowerCase().includes(filtroEstudiante.toLowerCase())
  );

  const cargandoDatos = cargandoCursos || cargandoPeriodos || cargandoNotas;

  if (errorCursos || errorPeriodos) {
    return <p>Error: {errorCursos || errorPeriodos}</p>;
  }

  return (
    <div className="px-0 container-fluid p-0" style={{ height: "90vh", overflowY: "hidden" }}>
      <NavInformeEstudianteCurso
        cursos={cursos}
        periodos={periodos.map((periodo) => ({
          id: periodo.periodoAcademicoId,
          nombre: convertirAbreviatura(periodo.periodoAcademicoNombre),
        }))}
        cursoId={cursoSeleccionado?.cursoId}
        periodoId={periodoSeleccionado?.id}
        onCursoSelect={handleCursoSelect}
        onPeriodoSelect={handlePeriodoSelect}
        isHeatmapActive={isHeatmapActive}
        setIsHeatmapActive={setIsHeatmapActive}
        filtroEstudiante={filtroEstudiante}
        setFiltroEstudiante={setFiltroEstudiante}
        dataEstudiantesNotas={dataEstudiantesNotas}
        dataColegio={dataColegio}
        dataCurso={dataCurso}
      />
      {cargandoDatos ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
          <CustomSpinner animation="border" role="status" >
            <span className="visually-hidden">Cargando...</span>
          </CustomSpinner>
        </div>
      ) : (
        <TablaInformeEstudianteCurso
          estudiantes={estudiantesFiltrados}
          periodoEscolar={periodoSeleccionado?.id}
          semestre={periodoSeleccionado?.nombre}
          cursoSeleccionado={cursoSeleccionado}
          key={`${cursoSeleccionado?.cursoId}-${periodoSeleccionado?.id}`}
          dataEstudiantesNotas={dataEstudiantesNotas}
          dataColegio={dataColegio}
          dataCurso={dataCurso}
          cantidadDecimales={cantidadDecimales} 
        />
      )}
    </div>
  );
};

export default InformeCalificacionEstudianteCurso;
