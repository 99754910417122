import React, { useEffect, useState } from 'react';
import useGetDataRol from "../../../hooks/useGetDataRol";
import HorarioDocente from '../../components/horarioDocente/HorarioDocente';


const HorarioVista = () => {
  const { data, loading, error } = useGetDataRol();
  
  const storedCursoId = JSON.parse(localStorage.getItem('currentRolData'))?.defaultCursoId;
  const [cursoId, setCursoId] = useState(storedCursoId);

  useEffect(() => {
    if (data && data.defaultCursoId) {
      setCursoId(data.defaultCursoId);
    } else if (storedCursoId) {
      setCursoId(storedCursoId);
    }

    // Validación para determinar si el usuario es solo docente
    const user = JSON.parse(localStorage.getItem('userDetails')) || {};
    const currentSchool = JSON.parse(localStorage.getItem("currentSchool")) || {};
    const docenteRole = user.user?.roles?.filter(r => r.rol.id === 1 && r.colegio.id === currentSchool?.id);
    const isOnlyDocente = docenteRole && docenteRole.length === 1 && user.user?.roles?.length === 1;

    // Guardar isSeguro en localStorage
    localStorage.setItem('isSeguro', JSON.stringify(isOnlyDocente));

    console.log("Datos recuperados de currentRolData:", JSON.parse(localStorage.getItem('currentRolData')));
    console.log("Curso ID seleccionado:", cursoId);
    console.log('Roles:', user.user?.roles);
     console.log('isSeguro guardado en localStorage:', isOnlyDocente);
    console.log('Current School:', currentSchool);
  }, [data, storedCursoId]);

  return (
    <div className="card text-center">
      <div className="card-body p-0 px-3">
        {loading ? (
          <p></p>
        ) : error ? (
          <p>Error al cargar datos.</p>
        ) : (
          <HorarioDocente className="col-12" cursoId={cursoId} />
        )}
      </div>
    </div>
  );
};

export default HorarioVista;