import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Swal from "sweetalert2";
import useGetAsignaturaPorCodigo from '../../../hooks/useGetAsignaturaPorCodigo';
import useGetListadoCursos from '../../../hooks/useGetListadoCursos';
import useGetPeriodosAcademicos from '../../../hooks/useGetPeriodosAcademicos';
import { ReportService } from '../../../services';
import { saveProcesoCalificaciones, uploadCalificaciones } from '../../../services/ImportarEstudiantesService';
import { formatearRUN } from '../../../util/util';
import DesplegableConBusqueda from '../../components/desplegableConBusqueda/DesplegableConBusqueda';
import DynamicTable from '../../components/tables/DynamicTable/Table'; // Asegúrate de importar DynamicTable aquí
import './MatriculaSige.scss';

export default function ImportarCalificaciones() {
    const [isDocente, setIsDocente] = useState(false);
    const [selectedCurso, setSelectedCurso] = useState(null);
    const [selectedCursoId, setSelectedCursoId] = useState(null);
    const [selectedPeriodo, setSelectedPeriodo] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [resultNotas, setResultNotas] = useState(null);
    const [resultSaved, setResultSaved] = useState(null);
    const [enableSave, setEnableSave] = useState(false);
    const [selectedAsignatura, setSelectedAsignatura] = useState(null);
    
    const { cursos, cargando: cargandoCursos, error: errorCursos } = useGetListadoCursos(isDocente);
    const { periodos, cargando: cargandoPeriodos, error: errorPeriodos } = useGetPeriodosAcademicos();
    const { asignaturas, cargando: cargandoAsignaturas, error: errorAsignaturas } = useGetAsignaturaPorCodigo(selectedCursoId, isDocente);

    const handleChangeStatus = useCallback(({ meta }, status) => {
        console.log('Archivo cargado:', status, meta);
    }, []);

    const handleSubmitProceso = useCallback((files, allFiles) => {
        setIsLoading(true);
        setEnableSave(false);
        setResultNotas(null);
        const formData = new FormData();
        formData.append('file', allFiles[0].file);
        formData.append('cursoId', selectedCursoId);
        formData.append('asignaturaId', selectedAsignatura.asignaturaId);
        formData.append('periodoEscolarId', selectedPeriodo);

        console.log('Datos enviados en la subida de calificaciones:', {
            cursoId: selectedCursoId,
            asignaturaId: selectedAsignatura.asignaturaId,
            periodoEscolarId: selectedPeriodo,
        });

        uploadCalificaciones(formData, selectedPeriodo).then(r => {
            console.log('Respuesta del backend:', r.data);
            if (Array.isArray(r.data)) {
                setResultNotas(r.data);
                setEnableSave(r.data.some(d => d.status === "OK"));
            } else {
                console.error("Datos de respuesta no válidos", r.data);
                throw new Error("Datos de respuesta no válidos");
            }
        }).catch(e => {
            console.error("Error al subir calificaciones:", e);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Error al subir calificaciones: ${e.message}`,
            });
            
        }).finally(() => {
            setIsLoading(false);
        });
        allFiles.forEach(f => f.remove());
    }, [selectedCursoId, selectedAsignatura, selectedPeriodo]);

    const cancelProcess = useCallback(() => {
        console.log('Proceso cancelado');
        setResultNotas(null);
        setSelectedCurso(null);
        setSelectedCursoId(null);
        setSelectedAsignatura(null);
        setSelectedPeriodo('');
    }, []);

    const changeCurso = useCallback((cursoId) => {
        const curso = cursos.find(c => c.cursoId === parseInt(cursoId));
        console.log('Curso seleccionado:', curso);
        setSelectedCurso(curso);
        setSelectedCursoId(cursoId);
    }, [cursos]);

    const changeAsignatura = useCallback((asignaturaId) => {
        const asignatura = asignaturas.find(c => c.asignaturaId === parseInt(asignaturaId));
        console.log('Asignatura seleccionada:', asignatura);
        setSelectedAsignatura(asignatura);
    }, [asignaturas]);

    const saveProceso = useCallback(() => {
        if (!resultNotas || resultNotas.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No hay datos para guardar',
            });
            return;
        }

        console.log('Guardando proceso con las siguientes notas:', resultNotas);

        setIsSaving(true);
        setResultSaved(null);
        saveProcesoCalificaciones({ notas: resultNotas.filter(nota => nota.status === "OK").map(nota => nota.record) }).then(r => { 
            console.log('Resultado del guardado:', r.data);
            setResultSaved(r.data);

            // Mostrar el modal de éxito después de guardar
            Swal.fire({
                icon: "success",
                title: "Calificaciones guardadas",
                text: "Las calificaciones han sido guardadas exitosamente.",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
            });
        })
        .catch(e => {
            console.error("Error al guardar proceso:", e);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Error al guardar proceso: ${e.message}`,
            });
        })
        .finally(() => {
            setResultNotas(null);
            setIsSaving(false);
        });
    }, [resultNotas]);

    const changeCheckAutorize = useCallback(() => {
        console.log('Autorización cambiada:', !enableSave);
        setEnableSave(!enableSave);
    }, [enableSave]);

    const downloadPlantillaCalificaciones = useCallback(() => {
        if (!selectedCurso) {
            console.error("No se ha seleccionado un curso.");
            return;
        }

        const nombreArchivo = `Plantilla_Calificaciones_${selectedCurso.cursoNombre.replace(/\s+/g, '_')}_${selectedAsignatura.asignaturaNombre.replace(/\s+/g, '_')}.xlsx`;

        console.log('Descargando plantilla con los parámetros:', {
            periodoEscolarId: selectedPeriodo,
            cursoId: selectedCursoId,
            asignaturaId: selectedAsignatura.asignaturaId,
        });

        ReportService.read('notas/exportListaCurso', { 
            periodoEscolarId: selectedPeriodo, 
            cursoId: selectedCursoId, 
            asignaturaId: selectedAsignatura.asignaturaId
        }, null, nombreArchivo);
    }, [selectedPeriodo, selectedCursoId, selectedAsignatura, selectedCurso]);

    const headers = [
        { name: "N°", width: "50px", sortable: true, sortType: "number", sortByDefault: true },
        { name: "RUN", width: "150px", sortable: true, sortType: "string" },
        { name: "Nombre", width: "130px", sortable: true, sortType: "string" },
        { name: "Apellido Paterno", width: "200px", sortable: true, sortType: "string" },
        { name: "Apellido Materno", width: "200px", sortable: true, sortType: "string" },
        { name: "N1", width: "55px", sortable: false, sortType: "number" },
        { name: "N2", width: "55px", sortable: false, sortType: "number" },
        { name: "N3", width: "55px", sortable: false, sortType: "number" },
        { name: "N4", width: "55px", sortable: false, sortType: "number" },
        { name: "N5", width: "55px", sortable: false, sortType: "number" },
        { name: "N6", width: "55px", sortable: false, sortType: "number" },
        { name: "N7", width: "55px", sortable: false, sortType: "number" },
        { name: "N8", width: "55px", sortable: false, sortType: "number" },
        { name: "N9", width: "55px", sortable: false, sortType: "number" },
        { name: "N10", width: "55px", sortable: false, sortType: "number" },
        { name: "Estado proceso", width: "200px", sortable: true, sortType: "string" },
        { name: "Mensaje", width: "auto", sortable: false },
    ];

    const formattedData = resultNotas ? resultNotas.map((e, index) => ({
        id: index,
        value: [
            index + 1, // #
            formatearRUN(`${e.data.run}-${e.data.dv}`), // RUN
            e.data.nombrePrimaro, // Nombre
            e.data.apellidoPrimario, // Apellido Paterno
            e.data.apellidoSecundario, // Apellido Materno
            e.data.nota1, // N1
            e.data.nota2, // N2
            e.data.nota3, // N3
            e.data.nota4, // N4
            e.data.nota5, // N5
            e.data.nota6, // N6
            e.data.nota7, // N7
            e.data.nota8, // N8
            e.data.nota9, // N9
            e.data.nota10, // N10
            e.status === 'OK' ? 'Correcto' : 'Error', // Estado proceso
            e.message, // Mensaje
        ],
    })) : [];

    const resumeTable = [
        {
          label: "Estudiantes",
          value: formattedData.length,
        },
    ];

    useEffect(() => {
        console.log('Cursos obtenidos:', cursos);
        console.log('Periodos obtenidos:', periodos);
        console.log('Asignaturas obtenidas:', asignaturas);
    }, [cursos, periodos, asignaturas]);

    return (
        <div
        className="d-flex flex-column"
        style={{
            padding: "1rem 0rem",
            margin: "0 0rem",
            backgroundColor: "#FDFFFF",
            minHeight: "100vh",
            height: "100vh", 
            overflowY: "hidden",
            overflowX: "hidden",
        }}
    >
        <center><h3 className='mt-2' style={{ color: 'var(--color-texto-oscuro)', fontWeight: '500' }}>Importar calificaciones Sumativas</h3></center>
        <Row className="justify-content-center">
            <Col md={8}>
                {!resultNotas && (
                    <>
                        <Row className='mt-3 mb-3 justify-content-center'>
                            <Col md={3}>
                                <DesplegableConBusqueda
                                    elementos={cursos}
                                    placeholder="Curso"
                                    onSelect={curso => changeCurso(curso.cursoId)}
                                    displayProperty="cursoNombre"
                                    idProperty="cursoId"
                                />
                            </Col>
                            <Col md={3}>
                                <DesplegableConBusqueda
                                    elementos={asignaturas}
                                    placeholder="Asignatura"
                                    onSelect={asignatura => changeAsignatura(asignatura.asignaturaId)}
                                    displayProperty="asignaturaNombre"
                                    idProperty="asignaturaId"
                                />
                            </Col>
                            <Col md={3}>
                                <DesplegableConBusqueda
                                    elementos={periodos}
                                    placeholder="Período"
                                    onSelect={periodo => setSelectedPeriodo(periodo.periodoAcademicoId)}
                                    displayProperty="periodoAcademicoNombre"
                                    idProperty="periodoAcademicoId"
                                />
                            </Col>
                        </Row>
                        {selectedPeriodo && selectedCurso && selectedAsignatura && (
                            <>
                                <Button variant="outline-primary" className="me-2 mb-2" onClick={downloadPlantillaCalificaciones}>Descargar plantilla</Button>
                                <Dropzone
                                    accept=".xlsx"
                                    onChangeStatus={handleChangeStatus}
                                    onSubmit={handleSubmitProceso}
                                    maxFiles={1}
                                    inputContent={<><FontAwesomeIcon 
                                    icon={faUpload} 
                                    className='me-2' /> Arrastra y suelta aquí el archivo para subir notas</>}
                                    submitButtonContent="Subir Archivo"
                                    disabled={isLoading}
                                />
                            </>
                        )}
                    </>
                )}
            </Col>
        </Row>

        <Row className="justify-content-center">
        {isLoading ? (
            <Col xs="auto" className="text-center" style={{ padding: 70 }}>
            <Spinner />
            </Col>
        ) : resultNotas ? (
            <>
            <Col xs={12} className="text-center">
                <Row className="justify-content-between mb-0">
                <Col xs="auto">
                    <p
                    className="text-left mb-0"
                    style={{ color: "var(--color-apoyo)", fontSize: 16, fontWeight: 500 }}
                    >
                    Previsualización de Datos
                    </p>
                </Col>
                <Col style={{marginLeft:"-15%"}}  className="d-flex justify-content-center align-items-center">
                    <p className="me-3 mb-0">Curso: {selectedCurso.cursoNombre}</p>
                    <p className="mb-0">Asignatura: {selectedAsignatura.asignaturaNombre}</p>
                </Col>
                </Row>
                    <DynamicTable
                        data={formattedData}
                        headers={headers}
                        options={{
                        stickyHeaders: true,
                        stickyOffset: "0px",
                        showPagination: true,
                        showItemsPerPageSelector: true,
                        copyClipboard: true,
                        searchInput: true,
                        downloadXLS: false,
                        resumeTable: resumeTable,
                        }}
                    />
                </Col>
                <Row className="justify-content-end mt-0">
                    <Col xs="auto">
                    <Button
                        variant="outline-primary"
                        className="me-2"
                        disabled={isSaving || !enableSave}
                        onClick={saveProceso}
                    >
                        {isSaving ? "Guardando..." : "Guardar"}
                    </Button>
                    <Button variant="outline-primary" onClick={cancelProcess}>
                        Cancelar
                    </Button>
                    </Col>
                </Row>
                </>
            ) : resultSaved && (
                <Col xs={12} className="text-center">
                {/* <h3 className="text-center">Resultado del Proceso</h3> */}
                </Col>
            )}
            </Row>

        {errorCursos && (
            <Row className="justify-content-center">
                <Col xs="auto" className="text-center">
                    <p style={{ color: 'red' }}>{errorCursos}</p>
                </Col>
            </Row>
        )}
        {errorAsignaturas && (
            <Row className="justify-content-center">
                <Col xs="auto" className="text-center">
                    <p style={{ color: 'red' }}>{errorAsignaturas}</p>
                </Col>
            </Row>
        )}
    </div>
    );
}
