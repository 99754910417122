import Swal from "sweetalert2";
import axiosInstance from '../services/AxiosInstance';

const Report = {
  read: (endpoint, body, headers, fileName, evt) => {
    Swal.fire({
      title: "Procesando la descarga",
      // text: "Este proceso puede tardar unos minutos.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    return axiosInstance.post(endpoint, body, {
        headers,
        responseType: 'blob'
    }).then((response) => {
      if(response.status === 200) {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', fileName); //or any other extension
       document.body.appendChild(link);
       link.click();
       Swal.fire({
        icon: 'info',
        title: 'Info',
        text: 'Descarga correcta',
        customClass:{
          confirmButton:'custom-swal-button'
        }
       })
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al descargar el archivo',
          customClass:{
            confirmButton:'custom-swal-buttonRed'
          }
        })
      }
    }).catch(error => {
      debugger
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message,
        customClass:{
          confirmButton:'custom-swal-buttonRed'
        }
      })
    });
  },
}

export default Report