import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { components } from "react-select"; 
import CreatableSelect from "react-select/creatable";

const CustomMultiSelect = ({
  label = "Seleccione una opción",
  options = [],
  placeholder = "Seleccione...",
  noOptionsMessage = "No se encontraron opciones",
  onChange,
  isMulti = true,
  formatCreateLabel = (inputValue) => `Agregar "${inputValue}"`,
  disabled = false,
}) => {
  const [localOptions, setLocalOptions] = useState(options);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCreateOption = (inputValue) => {
    const capitalizedLabel = inputValue
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());

    const newOption = {
      value: capitalizedLabel.toLowerCase().replace(/\s+/g, "_"),
      label: capitalizedLabel,
    };

    setLocalOptions((prevOptions) => [...prevOptions, newOption]);
    setSelectedOptions((prevSelected) => [...prevSelected, newOption]);

    if (onChange) {
      onChange([...selectedOptions, newOption]);
    }
  };

  const handleChange = (newValue) => {
    setSelectedOptions(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const CustomMultiValue = (props) => (
    <components.MultiValue {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px", fontWeight: "bold", color: "white" }}>●</span>
        <span>{props.data.label}</span>
      </div>
    </components.MultiValue>
  );

  const CustomMultiValueRemove = (props) => (
    <components.MultiValueRemove {...props}>
      <span style={{ fontWeight: "bold", cursor: "pointer" }}>✖</span>
    </components.MultiValueRemove>
  );

  const CustomOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "8px", color: props.isFocused ? "white" : "var(--color-texto-oscuro)" }}>●</span>
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  );

  return (
    <div>
      <Form.Label>{label}</Form.Label>
      <CreatableSelect
        options={localOptions}
        isMulti={isMulti}
        onCreateOption={handleCreateOption}
        onChange={handleChange}
        value={selectedOptions}
        formatCreateLabel={formatCreateLabel}
        components={{
          MultiValue: CustomMultiValue,
          MultiValueRemove: CustomMultiValueRemove,
          Option: CustomOption,
        }}
        menuPlacement="auto"
        isDisabled={disabled}
        styles={{
          control: (base, state) => ({
            ...base,
            backgroundColor: "white",
            borderColor: state.isFocused ? "var(--color-acento)" : "#ccc",
            color: "#2c3e50",
            transition: "border-color 0.3s ease",
            ":hover": {
              borderColor: "var(--color-acento)",
            },
          }),
          multiValue: (base) => ({
            ...base,
            backgroundColor: "var(--color-acento)",
            color: "white",
            transition: "background-color 0.3s ease",
            borderRadius: "10px",
          }),
          multiValueLabel: (base) => ({
            ...base,
            color: "white",
            padding: "5px 8px",
          }),
          multiValueRemove: (base) => ({
            ...base,
            color: "white",
            paddingRight: "8px",
            borderRadius: "10px",
            transition: "background-color 0.3s ease, color 0.3s ease",
            ":hover": {
              color: "var(--color-eliminar)",
            },
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? "var(--color-acento)" : "white",
            color: state.isFocused ? "white" : "var(--color-texto-oscuro)",
            transition: "background-color 0.3s ease, color 0.3s ease",
            ":active": {
              backgroundColor: "var(--color-morado)",
              color: "white",
            },
          }),
        }}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
      />
    </div>
  );
};

export default CustomMultiSelect;
