import { SET_FUNCIONARIOS } from '../actions/FuncionariosActions';

const initialState = {
    funcionarios: [],
};

export function FuncionariosReducer(state = initialState, action) {
    if(action.type === SET_FUNCIONARIOS){
        return {
            ...state,
            funcionarios: action.payload,
        };
    }

    return state;
}