import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";

const DesplegableConBusquedaMultiple = ({ elementos = [], placeholder, onSelect, selectedElements = [] }) => {
  const [filtro, setFiltro] = useState("");
  const [seleccionado, setSeleccionado] = useState([]);

  useEffect(() => {
    if (selectedElements.length === 0) {
      setSeleccionado([]);
    } else {
      setSeleccionado(selectedElements.map(elementoId => {
        const elemento = elementos.find(elemento => elemento.cursoId === elementoId);
        return elemento ? elemento.cursoNombre : '';
      }));
    }
  }, [selectedElements, elementos]);

  const estilos = {
    boton: {
      border: "1px solid var(--color-acento)",
      color: "black",
      backgroundColor: "white",
      width: "180px",
      margin: "0",
    },
    menuDesplegable: {
      maxHeight: "300px", // Aumentar la altura del menú desplegable
      overflowY: "auto",
      width: "200px",
      backgroundColor: "white",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      padding: "0",
      position: "absolute",
    },
    controlFormulario: {
      margin: "5px auto",
      padding: "5px",
      width: "90%",
      display: "block",
      boxSizing: "border-box",
      border: "1px solid #ced4da",
      borderRadius: "4px",
    },
    stickyContainer: {
      position: "sticky",
      top: 0,
      backgroundColor: "white",
      padding: "5px 0",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column", // Permitir que el enlace y el campo de búsqueda estén en una columna
      alignItems: "center",
      zIndex: 1050,
    },
    enlaceDesmarcarTodo: {
      fontSize: "0.9em", // Tamaño de fuente más pequeño para ser más sutil
      color: "#007bff", // Color de enlace típico
      cursor: "pointer",
      textDecoration: "underline",
      margin: "5px 0",
    },
    itemSeleccionado: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  const elementosFiltrados = elementos.filter((elemento) =>
    elemento && elemento.cursoNombre && elemento.cursoNombre.toLowerCase().includes(filtro.toLowerCase())
  );

  const handleSelect = (elemento) => {
    if (selectedElements.includes(elemento.cursoId)) {
      onSelect(selectedElements.filter((id) => id !== elemento.cursoId));
    } else {
      onSelect([...selectedElements, elemento.cursoId]);
    }
  };

  const handleDeselectAll = () => {
    onSelect([]);
  };

  return (
    <Dropdown className="dropdown-elemento m-0 p-0">
      <Dropdown.Toggle style={estilos.boton}>
        {selectedElements.length ? `${selectedElements.length} curso(s)` : placeholder}
      </Dropdown.Toggle>
      <Dropdown.Menu style={estilos.menuDesplegable}>
        <div style={estilos.stickyContainer}>
          {selectedElements.length > 1 ? (
            <span 
              style={estilos.enlaceDesmarcarTodo} 
              onClick={handleDeselectAll}
            >
              Deseleccionar todos
            </span>
          ) : (
            <FormControl
              autoFocus
              placeholder={`Buscar ${placeholder.toLowerCase()}`}
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
              style={estilos.controlFormulario}
            />
          )}
        </div>
        {elementosFiltrados.map((elemento, index) => (
          <Dropdown.Item
            key={index}
            active={selectedElements.includes(elemento.cursoId)}
            onClick={() => handleSelect(elemento)}
            style={selectedElements.includes(elemento.cursoId) ? { color: "var(--color-acento)" } : {}}
          >
            <div style={estilos.itemSeleccionado}>
              {elemento.cursoNombre}
              {selectedElements.includes(elemento.cursoId) && (
                <FontAwesomeIcon icon={faCheck} style={{ color: "currentColor" }} />
              )}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DesplegableConBusquedaMultiple;
