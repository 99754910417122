// import React, { Component } from "react";
// import { Col, Row, Card } from "react-bootstrap";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
// import Alert from "sweetalert2";

// class CalendarBlog extends Component{
//     state = {
//         calendarEvents: [
//            {
//               title: "Atlanta Monster",
//               start: new Date("2024-01-07 00:00"),
//               id: "99999991",
//            },
//            {
//               title: "My Favorite Movie",
//               start: new Date("2024-01-11 00:00"),
//               id: "99999992",
//            },
//            {
//               title: "Enggement Function",
//               start: new Date("2024-01-17 00:00"),
//               id: "99999993",
//            },
//            {
//               title: "Marrige Function",
//               start: new Date("2024-01-23 00:00"),
//               id: "99999994",
//            },
//            {
//               title: "Party With Friends",
//               start: new Date("2024-01-26 00:00"),
//               id: "99999995",
//            },
//            {
//               title: "Atlanta Monster",
//               start: new Date("2024-02-07 00:00"),
//               id: "99999991",
//            },
//            {
//               title: "My Favorite Movie",
//               start: new Date("2024-02-11 00:00"),
//               id: "99999992",
//            },
//            {
//               title: "Enggement Function",
//               start: new Date("2024-02-17 00:00"),
//               id: "99999993",
//            },
//            {
//               title: "Marrige Function",
//               start: new Date("2024-02-23 00:00"),
//               id: "99999994",
//            },
//            {
//               title: "Party With Friends",
//               start: new Date("2024-02-26 00:00"),
//               id: "99999995",
//            },
//         ],
//           events: [
//              { title: "New Theme Release", id: "1", style:'primary' },
//              { title: "My Event", id: "2", style:'warning' },
//              { title: "Meet Manager", id: "3", style:'danger' },
//              { title: "Create New Theme", id: "4", style:'info' },
//              { title: "Project Launch ", id: "5", style:'dark' },
//              { title: "Meeting", id: "6", style:'secondary' },       
//           ],      
//        };


//        componentDidMount() {
//           let draggableEl = document.getElementById("external-events");
//           new Draggable(draggableEl, {
//              itemSelector: ".fc-event",
//              eventData: function (eventEl) {
//                 let title = eventEl.getAttribute("title");
//                 let id = eventEl.getAttribute("data");
//                 return {
//                    title: title,
//                    id: id,
//                 };
//              },
//           });
//        }


//        eventClick = (eventClick) => {
//           Alert.fire({
//              title: eventClick.event.title,
//              html:
//                 `<div className="table-responsive">
//                     <table className="table">
//                        <tbody>
//                           <tr >
//                              <td>Title</td>
//                              <td><strong>` +
//                                 eventClick.event.title +
//                                 `</strong></td>
//                           </tr>
//                           <tr >
//                              <td>Start Time</td>
//                              <td><strong>
//                              ` +
//                                    eventClick.event.start +
//                                    `
//                              </strong></td>
//                           </tr>
//                        </tbody>
//                     </table>
//                  </div>`,

//              showCancelButton: true,
//              confirmButtonColor: "#d33",
//              cancelButtonColor: "#3085d6",
//              confirmButtonText: "Remove Event",
//              cancelButtonText: "Close",
//           }).then((result) => {
//              if (result.value) {
//                 eventClick.event.remove(); 
//                 Alert.fire("Deleted!", "Your Event has been deleted.", "success");
//              }
//           });
//       };
//       render(){
//           return (
//               <>            
//                   <Row>
//                       <Col xl={"8"}>
//                           <Card>
//                               <Card.Body>
//                                   <div className="demo-app-calendar" id="mycalendartest">
//                                       <FullCalendar
//                                           defaultView="dayGridMonth"
//                                           headerToolbar={{
//                                               start: "prev,next today",
//                                               center: "title",
//                                               end:"dayGridMonth,timeGridWeek,timeGridDay",
//                                           }}
//                                           rerenderDelay={10}
//                                           eventDurationEditable={false}
//                                           editable={true}
//                                           droppable={true}
//                                           plugins={[
//                                               dayGridPlugin,
//                                               timeGridPlugin,
//                                               interactionPlugin,
//                                           ]}
//                                           ref={this.calendarComponentRef}
//                                           weekends={this.state.calendarWeekends}
//                                           events={this.state.calendarEvents}
//                                           eventDrop={this.drop}                                    
//                                           eventReceive={this.eventReceive}
//                                           eventClick={this.eventClick}                                    
//                                       />
//                                   </div>
//                               </Card.Body>
//                           </Card>
//                       </Col>
//                       <Col xl={"4"}>
//                           <Card>                     
//                               <Card.Body>
//                                   <h4 className="card-intro-title mb-0">Calendar</h4>
//                                   <div id="external-events" className="my-3">
//                                       <p>Drag and drop your event or click in the calendar</p>
//                                       {this.state.events.map((event) => (
//                                           <div
//                                               className={`fc-event external-event light btn-${event.style}`} data-class={`bg-${event.style}`}                                 
//                                               title={event.title}
//                                               data={event.id}
//                                               key={event.id}
//                                           >
//                                               <i className="fa fa-move" /><span>{event.title}</span>                                 
//                                           </div>
//                                       ))}
//                                   </div>
//                               </Card.Body>
//                           </Card>
//                       </Col>

//                   </Row>   
//               </>
//           );
//       }
// };

// export default CalendarBlog;

// CalendarBlog.
import React, {useEffect, useRef, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import {useNavigate} from 'react-router-dom';

// Datos simulados para los eventos del calendario, todos usando 'start' y 'end' uniformemente
const simulatedData = [
    {
        num_bloque: "1",
        dia: "Lunes",
        fecha: "2024-04-22",
        curso: "4° Medio A",
        asignatura: "Matemáticas",
        start: '2024-04-22T08:00:00',
        end: '2024-04-22T12:00:00',
        sala: "101"
    },
    {
        num_bloque: "2",
        dia: "Lunes",
        fecha: "2024-04-22",
        curso: "4° Medio A",
        asignatura: "Matemáticas",
        start: '2024-04-22T09:00:00',
        end: '2024-04-22T09:45:00',
        sala: "101"
    },
    {
        num_bloque: "3",
        dia: "Lunes",
        fecha: "2024-04-22",
        curso: "3° Medio B",
        asignatura: "Educación Física",
        start: '2024-04-22T10:00:00',
        end: '2024-04-22T10:45:00',
        sala: "Gimnasio"
    },
    {
        num_bloque: "4",
        dia: "Lunes",
        fecha: "2024-04-22",
        curso: "3° Medio B",
        asignatura: "Educación Física",
        start: '2024-04-22T10:45:00',
        end: '2024-04-22T12:00:00',
        sala: "Gimnasio"
    },
    {
        num_bloque: "7",
        dia: "Martes",
        fecha: "2024-04-24",
        curso: "2° Basico C",
        asignatura: "Educación Física",
        start: '2024-04-24T11:45:00',
        end: '2024-04-24T12:30:00',
        sala: "Gimnasio"
    },
    {
        num_bloque: "1",
        dia: "martes",
        fecha: "2024-04-24",
        curso: "4° Medio A",
        asignatura: "Matemáticas",
        start: '2024-04-24T08:00:00',
        end: '2024-04-24T09:00:00',
        sala: "101"
    },
    {
        num_bloque: "2",
        dia: "miercoles",
        fecha: "2024-04-25",
        curso: "4° Medio A",
        asignatura: "Matemáticas",
        start: '2024-04-25T09:00:00',
        end: '2024-04-25T12:45:00',
        sala: "101"
    },
    {
        num_bloque: "2",
        dia: "jueves",
        fecha: "2024-04-26",
        curso: "4° Medio A",
        asignatura: "Matemáticas",
        start: '2024-04-26T09:00:00',
        end: '2024-04-26T09:10:00',
        sala: "101"
    },
];

const CalendarBlog = () => {
    const calendarRef = useRef(null); // Ref para acceder a la instancia de FullCalendar
    const [events, setEvents] = useState([]); // Estado para almacenar los eventos del calendario
    const navigate = useNavigate(); // Hook de react-router-dom para gestionar la navegación

    // Aplica estilos personalizados a los botones de navegación del calendario
    useEffect(() => {
        const applyButtonStyles = () => {
            const prevButton = document.querySelector('.fc-prev-button');
            const nextButton = document.querySelector('.fc-next-button');
            [prevButton, nextButton].forEach(button => {
                button.style.background = 'var(--primary)';
                button.style.color = '#fff';
                button.style.border = 'none';
                button.style.opacity = '1';
                button.style.textTransform = 'capitalize';
            });
        };

        const calendarApi = calendarRef.current?.getApi();
        if (calendarApi) {
            calendarApi.on('eventsSet', applyButtonStyles);
            applyButtonStyles();
        }
    }, []);

    // Convierte los datos simulados en eventos del calendario
    useEffect(() => {
        const colorMapping = {
            "Matemáticas": "#007bff",
            "Educación Física": "#28a745",
        };

        const events = simulatedData.map(event => ({
            title: `${event.asignatura} - ${event.curso}`,
            start: event.start,
            end: event.end,
            color: colorMapping[event.asignatura] || '#ffc107', // Asigna color según la asignatura
            extendedProps: {
                sala: event.sala,
                num_bloque: event.num_bloque
            }
        }));

        setEvents(events);
    }, []);

    // Maneja clics en fechas y en eventos
    const handleDateClick = (arg) => {
        if (calendarRef.current.getApi().view.type === 'dayGridMonth' || calendarRef.current.getApi().view.type === 'timeGridWeek') {
            calendarRef.current.getApi().changeView('timeGridDay', arg.dateStr);
        }
    };

    const handleEventClick = (clickInfo) => {
        navigate('/asistencia-diaria-asignatura-profesor');
    };

    // Define el contenido personalizado para los eventos en el calendario
    const renderEventContent = (eventInfo) => {
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: '0.75em'}}>
                <div style={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    border: '1px solid var(--primary)',
                    flexShrink: 0,
                    marginRight: '10px',
                    color: 'var(--primary)',
                    fontWeight: 'bold'
                }}>
                    {`B${eventInfo.event.extendedProps.num_bloque}`}
                </div>
                <div style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}>
                    <strong>{`${eventInfo.event.extendedProps.curso} | Sala ${eventInfo.event.extendedProps.sala}`}</strong>
                    <div>{`${eventInfo.event.title}`}</div>
                    <small>{`${eventInfo.timeText}`}</small>
                </div>
            </div>
        );
    };

    return (
        <div className="container-fluid p-0 overflow-auto">
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                headerToolbar={{
                    left: "",
                    center: "prev title next",
                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                }}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                events={events}
                // eventOverlap={true}
                eventContent={renderEventContent}
                ref={calendarRef}
                locales={[esLocale]}
                locale="es"
                height="auto"
                hiddenDays={[0]}
                slotMinTime="08:00:00"
                slotMaxTime="21:00:00"
                slotEventOverlap={true}
                slotDuration="00:15:00"
                slotLabelInterval="01:00:00"
                eventTimeFormat={{hour: '2-digit', minute: '2-digit', hour12: false}}
                eventOrder="start"
            />
        </div>
    );
};

export default CalendarBlog;
