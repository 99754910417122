import './MatriculaSige.scss';
import React, { useState } from "react";
import { Modal, Button, Row, Col, Spinner, Form, Table } from "react-bootstrap";
import Dropzone from 'react-dropzone-uploader';
import Swal from "sweetalert2";
import { uploadProcesoMatricula, saveProcesoMatricula, uploadAsignacionMatricula, saveAsignacionMatricula } from '../../../services/MatriculaService';
import { ReportService } from '../../../services';
import apiConfig from '../../../config/apiConfig';
import { Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons'; 

const MatriculaSigeModal = ({ show, onHide }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [resultMatricula, setResultMatricula] = useState(null);
    const [resultAsignacion, setResultAsignacion] = useState(null);
    const [resultGroupedMatricula, setResultGroupedMatricula] = useState(null);
    const [enableSave, setEnableSave] = useState(false);
  
    const handleChangeStatus = ({ meta }, status) => {
      console.log(status, meta);
    };
  
    const handleSubmitProceso = (files, allFiles) => {
      setIsLoading(true);
      setEnableSave(false);
      setResultMatricula(null);
      const formData = new FormData();
      formData.append('file', allFiles[0].file);
      uploadProcesoMatricula(formData).then(r => {
        setResultMatricula(r.data);
      })
      .catch(e => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: e.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
      allFiles.forEach(f => f.remove());
    };
  
    const handleSubmitAsignacion = (files, allFiles) => {
      setIsLoading(true);
      setEnableSave(false);
      setResultMatricula(null);
      const formData = new FormData();
      formData.append('file', allFiles[0].file);
      uploadAsignacionMatricula(formData).then(r => {
        setResultMatricula(r.data);
        setEnableSave(r.data.filter(d => d.status === "OK").length > 0);
      })
      .catch(e => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: e.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
      allFiles.forEach(f => f.remove());
    };
  
    const cancelProcess = () => {
      setResultMatricula(null);
      setResultAsignacion(null);
      setResultGroupedMatricula(null);
    };

    const handleClose = () => {
      setIsLoading(false);
      setIsSaving(false);
      setResultMatricula(null);
      setResultAsignacion(null);
      setResultGroupedMatricula(null);
      setEnableSave(false);
      onHide(); 
    };
  
    const saveProceso = () => {
      setIsSaving(true);
      setResultGroupedMatricula(null);
      setResultAsignacion(null);
      saveAsignacionMatricula(resultMatricula.filter(d => d.status === "OK").map(r => r.record)).then(r => {
        setResultAsignacion(r.data);
        Swal.fire({
          icon: 'info',
          title: 'Asignación de Nro de Matrículas',
          text: 'Datos guardados correctamente',
        });
      })
      .catch(e => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: e.message,
        });
      })
      .finally(() => {
        setResultMatricula(null);
        setIsSaving(false);
      });
    };
  
    const changeCheckAutorize = () => {
      setEnableSave(!enableSave);
    };
  
    // const downloadMatricula = () => {
    //   ReportService.read('matricula/exportListaMatricula', null, null, 'Asigna_Matrícula.xlsx');
    // };
  
    const downloadPlantillaMatricula = () => {
      window.open(apiConfig.url + '/static/Matricula_SIGE.xlsx');
    };
  
    return (
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>Asignar Matrículas</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflowX:"hidden"}}>
          <div id="proceso-matricula-sige" className="pt-0">
            <div>
              <center>
                { !resultMatricula && !resultAsignacion && !resultGroupedMatricula && (
                  <h3>Asignar números de Matrícula</h3>
                )}
              </center>
              { !resultMatricula && !resultAsignacion && !resultGroupedMatricula && (
              <Row>
                <Col>
                  <Dropzone
                      accept=".xlsx"
                      onChangeStatus={handleChangeStatus}
                      onSubmit={handleSubmitAsignacion}
                      maxFiles={1}
                      inputContent={<><FontAwesomeIcon icon={faUpload} className='me-2'/> Arrastra y suelta aquí para subir archivos</>}
                      submitButtonContent="Subir Archivo"
                      disabled={isLoading}
                  />
                </Col>
              </Row>
              )}
              <Row style={{ paddingTop: 20 }}>
                { isLoading ? (
                  <Col className="text-left" style={{ padding: 70 }}><Spinner /></Col>
                ) : resultMatricula ? (
                  <Col className="table-responsive">
                    <Row>
                      <p className="text-left" style={{fontSize:'larger'}}>Previsualización de Datos</p>
                      <div className="text-right btn-actions">
                        <Button variant="outline-primary" size="sm" disabled={isSaving || !enableSave} onClick={saveProceso}>
                          { isSaving ? "Guardando..." : "Asignar Matrícula" }
                        </Button>
                        <Button variant="outline-primary" size="sm" onClick={cancelProcess}>Cancelar</Button>
                      </div>
                    </Row>
                      <Table className="table-striped table-hover table-sm">
                        <thead className="thead-light">
                          <tr>
                            <th className="col-1">#</th>
                            <th className="col-2">Curso</th>
                            <th className="col-1">Rut estudiante</th>
                            <th className="col-2">Nombre</th>
                            <th className="col-1">Apellido Paterno</th>
                            <th className="col-1">Apellido Materno</th>
                            <th className="col-1">Nro Matrícula</th>
                            <th className="col-1 text-center">Estado</th>
                            <th className="col-2">Mensaje</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resultMatricula.map((e, index) => (
                            <tr className={"status-proceso-matricula-" + e.status}>
                              <td className="col-1">{index + 1}</td>
                              <td className="col-2">
                                {e.record?.curso ? e.record.curso.cursoNombre : e.data && `${e.data.curso} ${e.data.letraCurso}`}
                              </td>
                              <td className="col-1">{e.data?.rut}-{e.data?.dv}</td>
                              {e.record?.persona ? (
                                <>
                                  <td className="col-2">{e.record.persona.nombrePrimario}</td>
                                  <td className="col-1">{e.record.persona.apellidoPrimario}</td>
                                  <td className="col-1">{e.record.persona.apellidoSecundario}</td>
                                </>
                              ) : e.data && (
                                <>
                                  <td className="col-2">{e.data.nombre}</td>
                                  <td className="col-1">{e.data.apellidoPrimario}</td>
                                  <td className="col-1">{e.data.apellidoSecundario}</td>
                                </>
                              )}
                              <td className="col-1">{e.data?.nroMatricula}</td>
                              <td className="col-1 text-center line-status">
                                {e.status === 'OK' ? <i className="la la-check" /> : e.status === 'ERROR' && <i className="la la-times" />}
                              </td>
                              <td className="col-3">{e.message}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                  </Col>
                ) : resultAsignacion ? (
                  <Col className="table-responsive">
                    <h3 className="text-center">Resultado del Proceso</h3>
                    <Table className="table-striped table-hover table-sm">
                      <thead className="thead-light">
                        <tr>
                          <th className="col-1">#</th>
                          <th className="col-2">Curso</th>
                          <th className="col-1">Rut estudiante</th>
                          <th className="col-2">Nombre</th>
                          <th className="col-2">Apellido Paterno</th>
                          <th className="col-2">Apellido Materno</th>
                          <th className="col-1">Nro Matrícula</th>
                          <th className="col-1">Nro Lista</th>
                        </tr>
                      </thead>
                      <tbody>
                        {resultAsignacion.map((e, index) => (
                          <tr>
                            <td className="col-1">{index + 1}</td>
                            <td className="col-2">{e.curso.cursoNombre}</td>
                            <td className="col-2">{e.persona.rut}-{e.persona.rutDv}</td>
                            <td className="col-2">{e.persona.nombrePrimario}</td>
                            <td className="col-2">{e.persona.apellidoPrimario}</td>
                            <td className="col-2">{e.persona.apellidoSecundario}</td>
                            <td className="col-1">{e.estudianteNumMatricula}</td>
                            <td className="col-1">{e.estudianteNumLista}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                ) : resultGroupedMatricula && (
                  <Col className="table-responsive">
                    <h3 className="text-center">Resultado del Proceso</h3>
                    <Table className="table-striped table-hover table-sm">
                      <thead className="thead-light">
                        <tr>
                          <th className="col-3">Curso</th>
                          <th className="col-6">Profesor Jefe</th>
                          <th className="col-3">Estudiantes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.values(resultGroupedMatricula).map(g => (
                          <tr key={g.curso?.id}>
                            <td className="col-3">{g.curso ? g.curso.cursoNombre : "NA"}</td>
                            <td className="col-6">
                              {g.curso?.docenteJefe?.persona ? (
                                <>
                                  {g.curso.docenteJefe.persona.nombrePrimario} {g.curso.docenteJefe.persona.apellidoPrimario}
                                </>
                              ) : "NA"}
                            </td>
                            <td className="col-3">
                              Inscritos {g.students.filter(e => e.status === 'SAVED').length} de {g.students.length}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  
  export default MatriculaSigeModal;
