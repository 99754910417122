import React from "react";

const MegaMenu = (user) => {
  if (!user) return [];

  let menu = [{
    title: "Main Menu",
    classsChange: "menu-title",
    extraclass: "first"
  }];
  // const roles = useSelector((state) => state.auth.user.roles);
  const currentSchool = JSON.parse(localStorage.getItem("currentSchool"));
  const isDocente = user.roles.filter(r => r.rol.id === 1 && r.colegio.id === currentSchool?.id).length > 0;
  const isDirectivo = user.roles.filter(r => r.rol.id === 5 && r.colegio.id === currentSchool?.id).length > 0;

  // Menú de calificaciones para docentes y directivos
  const calificacionesMenu = [
    {
      title: 'Ingresar Calificaciones',
      to: 'calificaciones-curso',
    },
    {
     title: 'Importar Calificaciones',
     to: "importar-calificaciones",
     iconStyle: <i className="la la-edit" />,
   },
    {
      title: 'Generar Informes',
      to: 'informe-calificaciones-estudiante-curso',
    },
  ];

  const docenteAsistenciaMenu = [
    {
      sectionTitle: 'Por Bloque',
      items: [
        { title: 'Diaria', to: 'asistencia-diaria-asignatura-profesor' },
        { title: 'Mensual', to: 'asistencia-mes-asignatura-profesor' }
      ]
    }
  ];

  const directivoAsistenciaMenu = [
    {
      sectionTitle: 'Subvención',
      items: [
        { title: 'Mensual Estudiante', to: 'asistencia-mensual-subvenciones-estudiantes' },
        { title: 'Mensual Curso', to: 'asistencia-mensual-subvenciones-cursos' }
      ]
    },
    {
      sectionTitle: '% Subvención',
      items: [
        { title: 'Anual Estudiante', to: 'porcentaje-asistencia-estudiantes' },
        { title: 'Anual Curso', to: 'porcentaje-asistencia-cursos' }
      ]
    }
  ];

  const combinedAsistenciaMenu = (isDocente || isDirectivo)
  ? [...docenteAsistenciaMenu, ...directivoAsistenciaMenu]
  : [];

  if (isDocente) {
    menu = menu.concat([
      {
        title: 'Docente',
        classsChange: 'menu-title',
        extraclass: 'docente-title'
      },
      {
        title: 'Agenda',
        to: "horario-vista",
        iconStyle: <i className="la la-calendar" />,
      },
      
      {
        title: 'Planificación',
        classsChange: 'mm-collapse',
        iconStyle: <i className="la la-book" />,
      },
      {
        title: 'Curso',  
        to: 'listado-cursos', 
        iconStyle: <i className="la la-graduation-cap" />,
      },
      {
        title: 'Asistencia',
        classsChange: 'mm-collapse',
        iconStyle: <i className="la la-users" />,
        content: combinedAsistenciaMenu,
      },
      {
        title: 'Calificaciones',
        classsChange: 'mm-collapse',
        iconStyle: <i className="la la-signal" />,
        content: calificacionesMenu,
      },
     
    
      {
        title: 'Evaluaciones',
        classsChange: 'mm-collapse',
        iconStyle: <i className="la la-plus-square-o" />,
      },
      {
        title: 'Estudiantes',
        iconStyle: <i className="la la-book" />,
        to: 'matricula-vista',
      },
      {
        title: 'Funcionarios',
        iconStyle: <i className="fa-solid fa-users" />,
        to: 'funcionarios'
      },
      {
        title: 'Asignaturas',
        to: 'lista-asignaturas',
        iconStyle: <i className="la la-list-ol" />,
      }
    ]);
  }

  if (isDirectivo) {
    if (!isDocente) {
      menu = menu.concat([
        {
          title: 'Home',
          iconStyle: <i className="la la-home" />,
          to: 'colegio-admin'
        },
        {
          title: 'Curso',  // Curso sin desplegable
          to: 'ficha-curso',  // Redirige directamente a ficha-curso
          iconStyle: <i className="la la-graduation-cap" />,
        },
        
      ]);
    }
    // menu = menu.concat([
   
    //   {
    //     title: 'Asignaturas',
    //     to: 'lista-asignaturas',
    //     iconStyle: <i className="la la-list-ol" />,
    //   }
    // ]);
    if (!isDocente) {
      menu = menu.concat([
        {
          title: 'Calificaciones',
          iconStyle: <i className="la la-book" />,
          content: calificacionesMenu,
        },
        {
          title: 'Estudiantes',
          iconStyle: <i className="la la-book" />,
          to: 'matricula-vista',
        },
        {
          title: 'Funcionarios',
          iconStyle: <i className="fa-solid fa-users" />,
          to: 'funcionarios'
        },
        {
          title: 'Asistencia',
          iconStyle: <i className="la la-book" />,
          content: directivoAsistenciaMenu,
        },
        {
          title: 'Asignaturas',
          to: 'lista-asignaturas',
          iconStyle: <i className="la la-list-ol" />,
        }
      ]);
    }
    menu = menu.concat([
      {
        title: 'Registro de actividad',
        iconStyle: <i className="la la-history" />,
        to: 'registro-log'
      },
     
    ])
  }

  return menu.map((item) => {
    return {
      ...item,
      hasSections: item.content && item.content.some(sub => sub.sectionTitle)
    };
  });
};

export default MegaMenu;