import React, { useState, useRef, useEffect, useCallback } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import AvatarEditor from "react-avatar-editor";
import InitialAvatar from "../../../assets/avatar/avatarAlumno.png";
import Swal from "sweetalert2";
import { getAvatar, uploadAvatar } from "../../../services/PostsService";
import { useSelector, useDispatch } from "react-redux";
import { setAvatar } from "../../../store/actions/AvatarActions";
import CustomSpinner from "../spinner/Spinner";

// Función para convertir base64 a Blob
const dataURLToBlob = (dataURL) => {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

const Avatar = ({
  enableUpload = false,
  width,
  height,
  run,
  isReadyToUpload = false,
  allowPreview = false,
}) => {
  const [loading, setLoading] = useState(false); // Estado para controlar el spinner
  const [file, setFile] = useState(null); // Guardar el archivo de la imagen seleccionada
  const [modalIsOpen, setModalIsOpen] = useState(false); // Estado para controlar la apertura del modal
  const [scale, setScale] = useState(1); // Estado para el nivel de zoom/escala
  const [imageURL, setImageURL] = useState(null); // Guardar la URL de la imagen ajustada
  const [tempFile, setTempFile] = useState(null); // Guardar la imagen temporalmente en el modal
  const [previewImage, setPreviewImage] = useState(null); // Guardar la imagen en modo preview
  const editorRef = useRef(null); // Referencia al editor de Avatar
  const [isImageVisible, setIsImageVisible] = useState(false); // Controlar si el componente es visible en el viewport
  const avatarRef = useRef(null); // Referencia al contenedor del avatar
  const [overlayVisible, setOverlayVisible] = useState(false); // Estado para mostrar/ocultar el overlay
  const [isMounted, setIsMounted] = useState(false); // Controla cuándo el overlay está montado en el DOM
  const [inFlightRequests, setInFlightRequests] = useState({});
  const dispatch = useDispatch();
  const avatarState = useSelector((state) => state.avatar); // Obtener los avatares desde Redux

  // Función para convertir el ArrayBuffer a base64
  const arrayBufferToBase64 = (buffer) => {
    const binary = new Uint8Array(buffer).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    );
    return window.btoa(binary); // Convertir a base64
  };

  // Cancelar la operación
  const handleCancel = () => {
    setTempFile(null);
    setModalIsOpen(false);
  };

  // Subida de la imagen y apertura del modal
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const validFormats = ["image/jpeg", "image/png", "image/jpg"];
      const maxSizeKB = 100; // Tamaño máximo en KB

      // Validar el formato y el tamaño del archivo
      if (!validFormats.includes(selectedFile.type)) {
        Swal.fire({
          icon: "error",
          title: "Formato no permitido",
          text: "Formato de archivo no válido. Solo se permiten archivos JPG, JPEG y PNG.",
          confirmButtonColor: 'var(--color-acento)'
        });
        return;
      }

      const fileSizeKB = selectedFile.size / 1024;
      if (fileSizeKB > maxSizeKB) {
        Swal.fire({
          icon: "error",
          title: "Archivo muy grande",
          text: `El archivo seleccionado excede el tamaño máximo permitido (${maxSizeKB} KB).`,
          confirmButtonColor: 'var(--color-acento)'
        });
        return;
      }

      setTempFile(selectedFile); // Guardar temporalmente la imagen seleccionada
      setModalIsOpen(true); // Abrir el modal
    }
  };

  // Guardar la imagen ajustada en modo preview
  const handleAccept = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const dataURL = canvas.toDataURL("image/jpeg", 0.7); // Calidad al 70%
      setPreviewImage(dataURL); // Cambiar la imagen a modo preview
      setModalIsOpen(false); // Cerrar el modal
    }
  };

  // Manejar la visibilidad del overlay con fade
  const handleOverlayToggle = useCallback(() => {
    if (!overlayVisible) {
      setOverlayVisible(true); // Montar el overlay
      setTimeout(() => setIsMounted(true), 10); // Aplicar la clase para el fade in después de montarse
    } else {
      setIsMounted(false); // Cambia la clase para el fade out
      setTimeout(() => setOverlayVisible(false), 500); // Esperar el tiempo de la transición antes de desmontar
    }
  }, [overlayVisible]);

  // Función que verifica si el componente está visible o cercano a estarlo
  const handleScroll = useCallback(() => {
    if (avatarRef.current) {
      const rect = avatarRef.current.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom > 0) {
        setIsImageVisible(true);
      }
    }
  }, []);

    // Función que verifica si el componente está visible o cercano a estarlo
    const checkVisibility = useCallback(() => {
      if (avatarRef.current) {
        const rect = avatarRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          setIsImageVisible(true);
        }
      }
    }, []);


  // Cargar la imagen desde el backend
  const fetchAvatar = useCallback(() => {
    if (run && run.toString().length >= 7) {
      setLoading(true);
      if (avatarState[run] === "no-avatar") {
        // setFile(InitialAvatar); // Usa la imagen por defecto si no existe avatar
        // setLoading(false);
      } else if (avatarState[run]) {
        setFile(avatarState[run]); // Usa la imagen desde Redux si está disponible
        setLoading(false);
      } else if (!inFlightRequests[run]) {
        setInFlightRequests((prev) => ({ ...prev, [run]: true })); // Marca el run como "en curso"
        const fetchAvatar = async () => {
          try {
            const res = await getAvatar(run);
            if (res && res.data) {
              const base64String = arrayBufferToBase64(res.data);
              const base64Image = `data:image/jpeg;base64,${base64String}`;
              setFile(base64Image); // Establecer la imagen obtenida del backend
              dispatch(setAvatar(run, base64Image)); // Guardar la imagen en Redux
            } else {
              // setFile(InitialAvatar); // Establecer la imagen por defecto si no hay avatar
              dispatch(setAvatar(run, null)); // Guardar en Redux que no tiene avatar
            }
          } catch (error) {
            console.error(`Error al cargar el avatar de ${run}`, error);
          } finally {
            setLoading(false);
            setInFlightRequests((prev) => {
              const newRequests = { ...prev };
              delete newRequests[run]; // Elimina el RUN del estado en curso
              return newRequests;
            });
          }
        };
        fetchAvatar();
      }
    }
  }, [run, avatarState, inFlightRequests, dispatch]);

  // Forzar la verificación de visibilidad tras reordenar
  useEffect(() => {
    let avatarRefCurrent = avatarRef.current;
    const observer = new MutationObserver(handleScroll);
    if (avatarRefCurrent) {
      observer.observe(avatarRef.current, { childList: true, subtree: true });
    }

    return () => {
      if (avatarRefCurrent) {
        observer.disconnect();
      }
    };
  }, [handleScroll]);

  // Efecto para hacer fetch de la imagen cuando es visible
  useEffect(() => {
    if (isImageVisible) {
      fetchAvatar();
      window.removeEventListener("scroll", handleScroll); // Eliminar el evento después de la carga
    }
  }, [isImageVisible, fetchAvatar, handleScroll]);

  // Añadir y eliminar el evento de scroll
  useEffect(() => {
    const handleScroll = () => {
      checkVisibility(); // Verificar visibilidad al hacer scroll
    };

    window.addEventListener("scroll", handleScroll);
    checkVisibility(); // Verificar visibilidad cuando el componente se monta

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [checkVisibility]);

  // Subir la imagen solo cuando isReadyToUpload sea true
  useEffect(() => {
    if (isReadyToUpload) {
      if (previewImage) {
        const blob = dataURLToBlob(previewImage);

        // Crear un FormData para la subida
        const formData = new FormData();
        formData.append("file", blob, "avatar.jpg"); // Agregar el archivo Blob
        formData.append("run", run); // Agregar el RUN

        uploadAvatar(formData)
          .then((res) => {
            setImageURL(previewImage); // Establecer la imagen ajustada en el estado
            setFile(blob); // Confirmar el archivo cargado
            dispatch(setAvatar(run, previewImage)); // Actualizar Redux con el nuevo avatar
          })
          .catch((error) => {
            console.error("Error al subir la imagen", error);
          });
      } else {
        // console.log("No preview image available to upload");
      }
    }
  }, [isReadyToUpload, previewImage, run, dispatch]);

    // Observador de intersección para cargar la imagen al estar visible
    useEffect(() => {
      let avatarRefCurrent = avatarRef.current;
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              fetchAvatar(); // Cargar la imagen cuando el avatar sea visible
              observer.unobserve(entry.target); // Dejar de observar después de cargar
            }
          });
        },
        { threshold: 0.1 } // Cargar cuando el 10% del componente es visible
      );
  
      if (avatarRef.current) {
        observer.observe(avatarRef.current); // Observar el componente avatar
      }
  
      return () => {
        if (avatarRefCurrent) {
          observer.unobserve(avatarRefCurrent); // Dejar de observar al desmontar
        }
      };
    }, [fetchAvatar]);

  return (
    <>
      {loading ? (
        <CustomSpinner width={width} height={height} />
      ) : (
        <div ref={avatarRef}>
          {/* Mostrar la imagen */}
          {overlayVisible && (
            <div
              className={`overlay ${
                isMounted ? "overlay-visible" : "overlay-hidden"
              }`} // Aplicar la clase dinámica
              onClick={handleOverlayToggle} // Evento de clic en el fondo para cerrar el overlay
            >
              <div
                className="overlay-content"
                onClick={(e) => e.stopPropagation()}
              >
                {/* Evitar que el clic en la imagen cierre el overlay */}
                <img
                  src={previewImage ? previewImage : imageURL ? imageURL : file}
                  alt=""
                  className="overlay-image"
                 
                />
              </div>
              <span className="close-overlay" onClick={handleOverlayToggle}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
          )}

          <div
            style={{
              width: width ? width : undefined,
              height: height ? height : undefined,
            }}
            className={`avatar-container`}
            onClick={(e) => {
              if (allowPreview) {
                e.stopPropagation(); // Evita que el clic en el label lo prevenga
                handleOverlayToggle();
              }
            }}
          >
            {enableUpload && <div className="translucent-overlay"></div>}
            <label className={`camera-icon ${enableUpload ? "" : "disabled"}`}>
              <img
                src={
                  previewImage // Si hay imagen en preview, mostrarla
                    ? previewImage
                    : imageURL // Si hay imagen ajustada, mostrarla
                    ? imageURL
                    : file // Mostrar la imagen obtenida del backend
                    ? file
                    : InitialAvatar // Cargar la imagen si no hay nada
                }
                alt="avatar"
                className="avatar-image"
              />

              {/* Mostrar el ícono de la cámara si enableUpload está activado */}
              {enableUpload && (
                <div className="wrapper-camera-container">
                  <div className="camera-icon-container">
                    <FontAwesomeIcon icon={faCamera} />
                  </div>
                  <input
                    type="file"
                    className="upload-input"
                    accept="image/jpeg, image/png, image/jpg"
                    onChange={handleFileChange}
                  />
                </div>
              )}
            </label>
          </div>

          {/* Modal para ajustar la imagen */}
          {enableUpload && (
            <Modal show={modalIsOpen} onHide={handleCancel} centered>
              <Modal.Header closeButton>
                <Modal.Title>Ajustar Imagen</Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Editor de avatar para ajustar la imagen */}
                {tempFile && (
                  <AvatarEditor
                    ref={editorRef}
                    image={tempFile}
                    width={250}
                    height={250}
                    border={50}
                    borderRadius={125}
                    scale={scale}
                    rotate={0}
                  />
                )}
                {/* Control para ajustar el zoom */}
                <input
                  type="range"
                  min="1"
                  max="2"
                  step="0.01"
                  value={scale}
                  onChange={(e) => setScale(parseFloat(e.target.value))}
                  style={{ marginTop: "15px", width: "80%" }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="color-rojo" onClick={handleCancel}>
                  Cancelar
                </Button>
                <Button variant="color-acento" onClick={handleAccept}>
                  Aceptar
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default Avatar;
