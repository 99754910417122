import { useState } from 'react';
import { saveAsistenciaClase } from '../services/PostsService';

const useSaveAsistenciaClase = () => {
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(null);

  const guardarAsistencia = async (claseId, estudianteId, estadoAsistenciaId) => {
    setCargando(true);
    setError(null);
    try {
      const response = await saveAsistenciaClase(claseId, estudianteId, estadoAsistenciaId);
      return response.data; // Devolver los nuevos datos
    } catch (error) {
      setError(error.message || 'Error al guardar la asistencia');
      throw error;
    } finally {
      setCargando(false);
    }
  };

  return { guardarAsistencia, cargando, error };
};

export default useSaveAsistenciaClase;
