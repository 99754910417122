import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import estilosEstado from "../../../jsx/objetoEstilos/estiloEstados";
import { getFirma, getLogo } from '../../../services/PostsService';

const estilosInforme = {
    container: {
        fontFamily: 'Poppins, sans-serif',
        color: "var(--color-texto-oscuro)",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '10px',
        marginTop: '5px',
        position: 'relative',
        width: '100%',
    },
    logoContainer: {
        position: 'absolute',
        left: '10px',
        top: '-5px',
        width: '130px', 
        height: 'auto',
    },
    logo: {
        width: '100%', 
        height: 'auto',
    },
    tituloContainer: {
        flex: 1,
        textAlign: 'center',
        marginTop: '-25px',
        width: '100%',
    },
    titulo: {
        fontSize: '24px',
        fontWeight: 600,
        marginTop: '30px',
        textAlign: 'center',
    },
    subtitulo: {
        fontSize: '24px',
        fontWeight: 500,
        marginTop: '5px',
        textAlign: 'center',
        backgroundColor: ""
    },
    textoEstudiante: {
        textAlign: 'left',
        fontSize: '16px',
        fontWeight: 400,
        color: "var(--color-texto-oscuro)",
        marginBottom: '5px',
    },
    textoEstudianteNegrita: {
        textAlign: 'left',
        fontSize: '16px',
        fontWeight: 600,
        color: "var(--color-texto-oscuro)",
        marginBottom: '5px',
    },
    tabla: {
        marginTop: '20px',
        borderCollapse: 'collapse',
    },
    th: {
        border: '1px solid var(--color-texto-oscuro)',
        textAlign: 'center',
        padding: '2px 0px', // Ajustado para reducir el padding lateral
        fontSize: '19px',
        color: "var(--color-texto-oscuro)",
        height: '28px', // Altura reducida
    },
    thColored: (color) => ({
        border: '1px solid var(--color-texto-oscuro)',
        textAlign: 'center',
        padding: '2px 0px', // Ajustado para reducir el padding lateral
        fontSize: '19px',
        color: "var(--color-texto-oscuro)",
        height: '28px', // Altura reducida
        backgroundColor: color,
    }),
    td: {
        border: '1px solid var(--color-texto-oscuro)',
        textAlign: 'center',
        padding: '2px 0px', // Ajustado para reducir más el padding lateral
        fontSize: '19px',
        color: "var(--color-texto-oscuro)",
        height: '28px', // Altura reducida
    },
    lineaObservacion: {
        width: '100%',
        height: '1px',
        backgroundColor: 'var(--color-texto-oscuro)',
        margin: '45px 0', 
    },
    
    observaciones: {
        fontSize: '169px',
    },
    lineaFirma: {
        width: '80%',
        height: '1px',
        backgroundColor: 'var(--color-texto-oscuro)',
        margin: '30px auto 10px',
    },
};

// const formatearSemestre = (semestre) => {
//     if (semestre.includes('1er')) {
//         return semestre.replace('1er', 'Primer');
//     } else if (semestre.includes('2do')) {
//         return semestre.replace('2do', 'Segundo');
//     }
//     return semestre;
// };

const formatearRUT = (estudiante) => {
    let run, runDv;
  
    // Verificamos si el run y runDv son válidos
    if (estudiante.run && estudiante.runDv) {
      run = estudiante.run;
      runDv = estudiante.runDv;
    } 
    // Si el run es null o runDv está vacío, usamos los valores provisorios
    else if (estudiante.runProvisorio && estudiante.runProvisorioDv) {
      run = estudiante.runProvisorio;
      runDv = estudiante.runProvisorioDv;
    } 
    // Si no tenemos ninguno de los valores, retornamos el valor por defecto
    else {
      return '-';
    }
  
    // Formateamos el RUT
    const rutString = run.toString();
    return rutString.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "-" + runDv;
  };
  
  
  

const formatearFecha = (date) => {
    const opciones = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('es-ES', opciones).replace(/^\w/, (c) => c.toUpperCase());
};

const formatearNota = (nota, cantidadDecimales) => {
    if (nota === -1) {
        return (
            <i
                className={estilosEstado.pendiente.icon}
                style={{ color: estilosEstado.pendiente.iconColor }}
            ></i>
        );
    }
    if (nota === 0 || nota === '') return '-';
    if (typeof nota !== 'number') return nota; // Devuelve el valor original si no es un número
    return nota.toFixed(cantidadDecimales).replace('.', ',');
};

const renderizarEncabezadoColegio = (dataColegio) => {
    return (
        <div style={{ textAlign: 'center', backgroundColor: "", marginTop:"60px"}}>
            <p style={{ fontSize: '16px', fontWeight: '600', margin: '0' }}>
                {dataColegio.sostenedorInforme.toUpperCase()}
            </p>
            <p style={{ fontSize: '20px', fontWeight: 'bold', margin: '0' }}>
                {dataColegio.colegioNombre.toUpperCase()} {dataColegio.numeroInforme}
            </p>
            <p style={{ fontSize: '14px', fontWeight: '600', margin: '0' }}>
                {dataColegio.colegioDireccion.toUpperCase()} - FONO {dataColegio.colegioFono}
            </p>
        </div>
    );
};


const renderizarEncabezadoTabla = (columnasUnidades) => {
    const headers = [
        { label: "ASIGNATURA", width: '150px' },
        ...columnasUnidades.map((unidad, index) => ({
            label: (
                <>
                    <div>PROMEDIO</div>
                    <div>UNIDAD {unidad}</div>
                </>
            ),
            width: '50px'
        })),
        { 
            label: (
                <>
                    <div>PROMEDIO</div>
                    <div>FINAL</div>
                </>
            ), 
            color: '#F7CAAC', 
            width: '50px' 
        }
    ];

    return (
        <thead>
            <tr>
                {headers.map((header, index) => (
                    <th 
                        key={index} 
                        style={{ 
                            ...header.color ? estilosInforme.thColored(header.color) : estilosInforme.th, 
                            width: header.width || 'auto', 
                            whiteSpace: 'pre-line',  
                            verticalAlign: 'middle',  
                            textAlign: 'center' 
                        }}
                    >
                        {header.label}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

const renderizarFilasTablaCompleta = (notasAsignatura, columnasUnidades, promedioGeneralParcial, notasAsignaturaNoInfluye, cantidadDecimales) => {
    const asignaturasQueInfluyen = notasAsignatura.filter((asignatura, index, self) =>
        index === self.findIndex(a => a.asignatura.asignaturaId === asignatura.asignatura.asignaturaId)
    );

    const asignaturasSinInfluencia = notasAsignaturaNoInfluye.filter((asignatura, index, self) =>
        index === self.findIndex(a => a.asignatura.asignaturaId === asignatura.asignatura.asignaturaId)
    );

    return (
        <>
            {/* Asignaturas que influyen en el promedio */}
            {asignaturasQueInfluyen.map((asignatura, index) => (
                <tr key={`influye-${index}`}>
                    <td style={{ ...estilosInforme.td, textAlign: 'left', verticalAlign: 'middle', paddingLeft: '10px', wordBreak: 'break-word' }}>
                        {asignatura.asignatura.asignaturaNombre}
                    </td>
                    {columnasUnidades.map((unidadNumero, i) => {
                        const notaUnidad = asignatura.notasUnidades?.find(nota => nota.columna === unidadNumero);
                        const valorNota = asignatura.asignatura.usaConcepto 
                            ? notaUnidad?.unidadConcepto || '-' 
                            : formatearNota(notaUnidad?.unidadNota || 0, cantidadDecimales);

                        const estiloNota = !asignatura.asignatura.usaConcepto && 
                                           typeof valorNota === 'string' &&
                                           parseFloat(valorNota.replace(",", ".")) < 4 
                                           ? { color: 'red' } 
                                           : {};

                        return (
                            <td key={i} style={{ ...estilosInforme.td, ...estiloNota }}>
                                {notaUnidad?.unidadNota === -1 ? (
                                    <i className={estilosEstado.pendiente.icon} style={{ color: estilosEstado.pendiente.iconColor }}></i>
                                ) : (
                                    valorNota
                                )}
                            </td>
                        );
                    })}
                    <td style={estilosInforme.td}>
                        {asignatura.asignatura.usaConcepto 
                            ? asignatura.notaFinalAsignatura?.conceptoFinal || '-' 
                            : formatearNota(asignatura.notaFinalAsignatura?.notaFinal || 0, cantidadDecimales)}
                    </td>
                </tr>
            ))}

            {/* Fila de Promedio Parcial General */}
            <tr>
                <td 
                    colSpan={columnasUnidades.length + 1} 
                    style={{ 
                        ...estilosInforme.th, 
                        fontWeight: '500', 
                        whiteSpace: 'nowrap', 
                        backgroundColor: '#F7CAAC', 
                        textAlign: 'start', 
                        padding: "0 3% ",
                    }}
                >
                    PROMEDIO PARCIAL GENERAL
                </td>
                <td 
                    style={{ 
                        ...estilosInforme.td, 
                        fontWeight: 'bold', 
                        backgroundColor: '#F7CAAC', 
                        textAlign: 'center',
                        color: parseFloat(promedioGeneralParcial) < 4 ? 'red' : 'inherit',
                    }}
                >
                    {promedioGeneralParcial === -1 ? (
                        <i className={estilosEstado.pendiente.icon} style={{ color: estilosEstado.pendiente.iconColor }}></i>
                    ) : (
                        typeof promedioGeneralParcial === 'number' 
                            ? formatearNota(promedioGeneralParcial, cantidadDecimales) 
                            : promedioGeneralParcial
                    )}
                </td>
            </tr>

            {/* Fila transparente para separación */}
            <tr>
                <td 
                    colSpan={columnasUnidades.length + 2} 
                    style={{ 
                        backgroundColor: 'transparent', 
                        height: '10px', 
                        borderLeft: '1px solid white', 
                        borderRight: '1px solid white',
                        borderTop: 'none',
                        borderBottom: 'none'
                    }}
                ></td>
            </tr>

            {/* Asignaturas que no influyen en el promedio */}
            {asignaturasSinInfluencia.map((asignatura, index) => (
                <tr key={`no-influye-${index}`}>
                    <td style={{ ...estilosInforme.td, width: '155px', textAlign: 'left', fontWeight: '500', paddingLeft: '10px' }}>
                        {asignatura.asignatura.usaConcepto && asignatura.asignatura.escalaCnt 
                            ? `(${asignatura.asignatura.escalaCnt}) ` 
                            : ''}{asignatura.asignatura.asignaturaNombre}
                    </td>
                    {columnasUnidades.map((unidad, i) => {
                        const notaUnidad = asignatura.notasUnidades?.find(nota => nota.columna === unidad);
                        const valor = asignatura.asignatura.usaConcepto 
                            ? notaUnidad?.unidadConcepto || '-' 
                            : formatearNota(notaUnidad?.unidadNota || 0, cantidadDecimales);
                        return (
                            <td key={i} style={{ ...estilosInforme.td, width: '150px' }}>
                                {notaUnidad?.unidadNota === -1 ? (
                                    <i className={estilosEstado.pendiente.icon} style={{ color: estilosEstado.pendiente.iconColor }}></i>
                                ) : (
                                    valor
                                )}
                            </td>
                        );
                    })}
                    <td style={{ ...estilosInforme.td, width: '50px', fontWeight: "bold" }}>
                        {asignatura.asignatura.usaConcepto 
                            ? asignatura.notaFinalAsignatura?.conceptoFinal || '-' 
                            : formatearNota(asignatura.notaFinalAsignatura?.notaFinal || 0, cantidadDecimales)}
                    </td>
                </tr>
            ))}
        </>
    );
};





const renderizarEscalas = (listadoEscalas) => {
    if (!listadoEscalas || listadoEscalas.length === 0) return null;

    return (
        <div style={{ marginTop: '0px', marginLeft: '0px', backgroundColor: "", width: "100%"}}>
            {listadoEscalas.map((escala, escalaIndex) => (
                <p key={escalaIndex} style={{ fontSize: '19px', marginTop: '10px' }}>
                    <strong>({escala.cntEscalas}):</strong>{" "}
                    {escala.detalle.map((rango, index) => (
                        <span key={index}>
                            <strong>{rango.concepto}</strong> {rango.descripcion}
                            {index < escala.detalle.length - 1 && "; "}
                        </span>
                    ))}
                </p>
            ))}
            <p style={{ fontSize: '19px' }}>
                <strong>LETRA P:</strong> El/la estudiante <strong>NO</strong> ha entregado evidencias pedagógicas
            </p>
        </div>
    );
};

const InformeCalificacionesGaspar = ({ dataColegio, dataCurso, listadoNotas, columnasUnidades, dataDirector, listadoEscalas, notasAsignaturaNoInfluye, cantidadDecimales }) => {
    const [logo, setLogo] = useState(null);
    const [firmaDocente, setFirmaDocente] = useState(null);
    const [firmaDirector, setFirmaDirector] = useState(null);

    console.log("notas no influyen:", notasAsignaturaNoInfluye);
    console.log("DECIMALES", cantidadDecimales);

    useEffect(() => {
        // Obtener el logo del colegio
        getLogo()
            .then(r => {
                if (r?.data) {
                    const blob = new Blob([r.data], { type: "image/png" });
                    const url = URL.createObjectURL(blob);
                    setLogo(url);
                } else {
                    console.error('Error: No se recibieron datos del logo');
                }
            })
            .catch(error => console.error('Error al obtener el logo:', error));

        // Obtener la firma del docente
        getFirma(dataCurso.profJefeRun)
            .then(r => {
                if (r?.data) {
                    const blob = new Blob([r.data], { type: "image/png" });
                    const url = URL.createObjectURL(blob);
                    setFirmaDocente(url);
                } else {
                    console.error('Error: No se recibió la firma del docente');
                }
            })
            .catch(error => console.error('Error al obtener la firma del docente:', error));

        // Obtener la firma del director
        getFirma(dataDirector.directorRun)
            .then(r => {
                if (r?.data) {
                    const blob = new Blob([r.data], { type: "image/png" });
                    const url = URL.createObjectURL(blob);
                    setFirmaDirector(url);
                } else {
                    console.error('Error: No se recibió la firma del director');
                }
            })
            .catch(error => console.error('Error al obtener la firma del director:', error));
    }, [dataCurso.docenteRun, dataDirector.directorRun]);

    const renderizarInfoEstudiante = (estudiante) => (
        <div style={{ width: '48%', marginBottom: '0px', marginTop: "2%", padding: '0px', display: 'flex', justifyContent: 'space-between', backgroundColor: "" }}>
            <div style={{ width: '100%' }}>
                {/* Fila para el nombre y el RUN */}
                <Row style={{ marginBottom: '0', alignItems: 'center' }}>
                    <Col xs={9} style={{ ...estilosInforme.textoEstudiante, fontWeight: '600', fontSize: "19px", textAlign: 'left' }}>
                        Nombre: {`${estudiante.nombrePrimario} ${estudiante.nombreSecundario} ${estudiante.apellidoPrimario} ${estudiante.apellidoSecundario}`}
                    </Col>
                    <Col xs={3} style={{ ...estilosInforme.textoEstudiante, fontWeight: '600', fontSize: "19px", textAlign: 'left' }}>
                        RUN: {formatearRUT(estudiante)}
                    </Col>
                </Row>
                {/* Fila para el curso y el número de lista */}
                <Row style={{ marginTop: '0', alignItems: 'center' }}>
                    <Col xs={9} style={{ ...estilosInforme.textoEstudiante, fontWeight: '600', fontSize: "19px", textAlign: 'left' }}>
                        Curso: {dataCurso.cursoNombre}
                    </Col>
                    <Col xs={3} style={{ ...estilosInforme.textoEstudiante, fontWeight: '600', fontSize: "19px", textAlign: 'left' }}>
                        Número de lista: {estudiante.numLista}
                    </Col>
                </Row>
            </div>
        </div>
    );
    
    const promedioGeneralParcial = listadoNotas[0]?.notaGlobal?.notaGlobal
        ? formatearNota(listadoNotas[0].notaGlobal.notaGlobal, cantidadDecimales)
        : listadoNotas[0]?.notaGlobal?.conceptoGlobal || '-';

    return (
        <Container className="mt-0 mb-1 d-flex flex-column align-items-center" style={{ ...estilosInforme.container, maxWidth: '100%' }}>
            <div style={estilosInforme.header}>
                <div style={{ ...estilosInforme.logoContainer }}>
                    <img src={logo} width={100} alt="Logo" style={estilosInforme.logo} />
                </div>
                <div style={estilosInforme.tituloContainer}>
                    {dataColegio && renderizarEncabezadoColegio(dataColegio)}
                    <div style={estilosInforme.subtitulo}>Informe Parcial de Notas</div>
                </div>
                {listadoNotas.length > 0 && renderizarInfoEstudiante(listadoNotas[0].estudiante)}
            </div>
            <div className="w-100 d-flex justify-content-center">
                <Table bordered style={{ ...estilosInforme.tabla, width: '100%' }}>
                    {renderizarEncabezadoTabla(columnasUnidades)}
                    <tbody>
                        {renderizarFilasTablaCompleta(
                            listadoNotas[0].notasAsignatura,
                            columnasUnidades,
                            promedioGeneralParcial,
                            notasAsignaturaNoInfluye,
                            cantidadDecimales 
                        )}
                    </tbody>
                </Table>
            </div>
            {renderizarEscalas(listadoEscalas)}
            <Row className="mt-0 w-100">
                <Col className="text-left" style={estilosInforme.observaciones}>
                    <h5 style={{ fontSize: '18px' }}>Observaciones:</h5>
                    <div style={estilosInforme.lineaObservacion}></div>
                    <div style={estilosInforme.lineaObservacion}></div>
                    <div style={estilosInforme.lineaObservacion}></div>
                    <div style={estilosInforme.lineaObservacion}></div>
                </Col>
            </Row>
            <Row className="mt-0 w-100">
                <Col xs={6} className="text-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* Firma del Docente */}
                        {firmaDocente && (
                            <img src={firmaDocente} alt="Firma Docente" style={{ maxWidth: '100%', maxHeight: '120px', objectFit: 'contain', marginBottom: '5px', marginTop:"80px" }} />
                        )}
                        <p style={{ fontSize: '16px', margin: '0' }}>
                            {dataCurso.profJefeGeneroNombre === "Femenino" ? "Profesora Jefe:" : "Profesor Jefe:"} {dataCurso.profJefeNombre}
                        </p>
                    </div>
                </Col>

                <Col xs={6} className="text-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* Firma del Director */}
                        {firmaDirector && (
                            <img src={firmaDirector} alt="Firma Director" style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'contain', marginBottom: '5px' }} />
                        )}
                        <p style={{ fontSize: '16px', margin: '0' }}>
                            {dataDirector.directorGeneroNombre === "Femenino" ? "Directora:" : "Director:"} {`${dataDirector.directorNombrePrimario} ${dataDirector.directorApellidoPrimario}`}
                        </p>
                    </div>
                </Col> 
            </Row>

            <Row className="mt-4 text-center w-100">
                <Col>
                    <p style={{ fontSize: '16px' }}>Fecha de emisión: {formatearFecha(new Date())}</p>
                </Col>
            </Row>
        </Container>
    );
};


export default InformeCalificacionesGaspar;
