import { useEffect, useState } from 'react';
import { getDocenteDataRol } from '../services/PostsService';

const useGetDataRol = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDocenteDataRol();
        const rolData = response.data;
        // console.log('Datos obtenidos de la API:', rolData);

        if (rolData) {
          setData(rolData);
          // Guardar los datos en localStorage
          localStorage.setItem('currentRolData', JSON.stringify({
            defaultCursoId: rolData.defaultCursoId || null,
            defaultCurso: rolData.defaultCurso || null,
            defaultAsignaturaId: rolData.defaultAsignaturaId || null,
            defaultAsignatura: rolData.defaultAsignatura || null,
            docenteId: rolData.docenteId || null,
            tieneClases: rolData.tieneClases 
          }));
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  return { data, loading, error };
};

export default useGetDataRol;
