import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import estilosEstado from "../../../jsx/objetoEstilos/estiloEstados";
import {
  saveConceptoAcuEstudiante,
  saveConceptoSumEstudiante,
  saveNotaAcuEstudiante,
  saveNotaSumEstudiante,
} from "../../../services/PostsService";
import { estilostabla } from "../../../util/estilosTablaCalificaciones";
import Avatar from "../../components/avatar/Avatar";
import NotificacionToast from "../../components/notificacion/NotificacionToast";
import { estilosEstadoAdicionales } from "../../objetoEstilos/estiloEstudiante";
import CustomTooltip from "../tooltip/Tooltip";
import EncabezadoTablaCalificaciones from "./EncabezadoTablaCalificaciones";
import ModalNotas from "./ModalNotas";

const TablaCalificaciones = ({
  data,
  tablaId,
  isHeatmapActive,
  mostrarAcumulativas,
  cursoId,
  asignaturaId,
  periodoEscolarId,
  filtroBusqueda,
  mostrarDosDecimalesNotas,
  mostrarDosDecimalesPromedios,
  blockInputs,
  abrirModalAutenticacion,
  hasPermission,
  promedioGeneralCurso,
  conceptosPermitidos,
}) => {
  // console.log("Conceptos permitidos recibidos:", conceptosPermitidos);
  // console.log("Promedio general curso recibido:", promedioGeneralCurso);

  const [datosOrdenados, setDatosOrdenados] = useState([]);
  const [celdaEdicion, setCeldaEdicion] = useState({
    id: null,
    field: null,
    valor: "",
  });
  const [mostrarModal, setMostrarModal] = useState(false);
  const [mostrarModalAviso, setMostrarModalAviso] = useState(false);
  const [modalMensaje, setModalMensaje] = useState("");
  const [notaTemporal, setNotaTemporal] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [mostrarNotificacion, setMostrarNotificacion] = useState(false);
  const [notaPendiente, setNotaPendiente] = useState({
    id: null,
    campo: null,
    valor: null,
  });
  const [promediosColumnas, setPromediosColumnas] = useState({});
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [inputsBloqueados, setInputsBloqueados] = useState(false);
  const [columnasExpandidas, setColumnasExpandidas] = useState({});
  const [promedioCurso, setPromedioCurso] = useState("");
  const [promedioGneralCursoActualizado, setPromedioGneralCursoActualizado] =
    useState(promedioGeneralCurso);
  const ultimoInputEnfocado = useRef(null);
  const posicionAnterior = useRef({ fila: null, columna: null });
  const manejarOcultarModal = useCallback(() => {
    setInputsBloqueados(false);
    setMostrarModal(false);
  }, []);

const [estiloNotificacion, setEstiloNotificacion] = useState({ backgroundColor: 'green' });


  const estilos = estilostabla(blockInputs, isHeatmapActive);

  //============================================================================
  //========================FUNCIONES FILTRADO Y ORDENAMIENTO===================
  //============================================================================

  const datosFiltrados = useMemo(() => {
    return datosOrdenados.filter((estudiante) =>
      estudiante.estudiante.nombre
        .toLowerCase()
        .includes(filtroBusqueda.toLowerCase())
    );
  }, [datosOrdenados, filtroBusqueda]);

  const filtrarYOrdenarEstudiantes = useMemo(() => {
    return datosFiltrados.sort((a, b) => {
      if (a.estudiante.numLista !== null && b.estudiante.numLista === null)
        return -1;
      if (a.estudiante.numLista === null && b.estudiante.numLista !== null)
        return 1;
      if (a.estudiante.numLista !== null && b.estudiante.numLista !== null)
        return a.estudiante.numLista - b.estudiante.numLista;
      if (
        a.estudiante.numMatricula !== null &&
        b.estudiante.numMatricula === null
      )
        return -1;
      if (
        a.estudiante.numMatricula === null &&
        b.estudiante.numMatricula !== null
      )
        return 1;
      return 0;
    });
  }, [datosFiltrados]);

  //============================================================================
  //========================FUNCIONES DE FORMATEO===============================
  //============================================================================

  const formatearValorNota = (valor) => {
    if (typeof valor === "number") {
      return mostrarDosDecimalesNotas
        ? valor.toFixed(2).replace(".", ",")
        : valor.toFixed(1).replace(".", ","); // Muestra uno o dos decimales con coma segÃºn la prop
    }
    if (typeof valor === "string") {
      const valorNumerico = parseFloat(valor.replace(",", "."));
      return Number.isNaN(valorNumerico)
        ? valor
        : mostrarDosDecimalesNotas
        ? valorNumerico.toFixed(2).replace(".", ",")
        : valorNumerico.toFixed(1).replace(".", ","); // Convierte punto a coma y muestra uno o dos decimales segÃºn la prop
    }
    return valor;
  };

  const formatearValorPromedio = (valor) => {
    // Si el valor es -1, "P" o "p", mostrar el ícono de pendiente
    if (valor === -1 || valor === "P" || valor === "p") {
      return (
        <i
          className={estilosEstado.pendiente.icon}
          style={{ color: estilosEstado.pendiente.iconColor }}
        ></i>
      );
    }
  
    if (typeof valor === "number") {
      return mostrarDosDecimalesPromedios
        ? valor.toFixed(2).replace(".", ",")
        : valor.toFixed(1).replace(".", ",");
    }
  
    if (typeof valor === "string") {
      return mostrarDosDecimalesPromedios
        ? parseFloat(valor.replace(",", ".")).toFixed(2).replace(".", ",")
        : valor.replace(".", ",");
    }
  
    return valor;
  };
  

  const formatearNotaParaGuardar = useCallback(
    (valor) => {
      if (typeof valor === "string" && valor.toLowerCase() === "p") {
        return "-1";
      }
      const notaNumero = parseFloat(String(valor).replace(",", "."));
      if (isNaN(notaNumero)) {
        return mostrarDosDecimalesNotas ? "0.00" : "0.0";
      }
      if (notaNumero > 7) {
        return mostrarDosDecimalesNotas ? "7.00" : "7.0";
      }
      if (Number.isInteger(notaNumero)) {
        return mostrarDosDecimalesNotas
          ? notaNumero.toFixed(2)
          : notaNumero.toFixed(1);
      }
      return mostrarDosDecimalesNotas
        ? notaNumero.toFixed(2)
        : notaNumero.toFixed(1);
    },
    [mostrarDosDecimalesNotas]
  );

  const formatearNotaParaMensaje = (nota) => {
    // Asegurarse de que nota sea una cadena
    const notaStr = String(nota);

    // Verificar si el valor es un concepto permitido y retornarlo directamente
    if (conceptosPermitidos.includes(notaStr.trim().toUpperCase())) {
      return notaStr.toUpperCase(); // Mostrar el concepto tal cual
    }

    if (
      notaStr === "0" ||
      notaStr === "0,0" ||
      notaStr === "-1,0" ||
      notaStr === "-1" ||
      notaStr === "0"
    ) {
      return ""; // Si la nota es 0, 0.0, -1 o -1.0, no mostrar nada
    }

    const valorNumerico = parseFloat(notaStr.replace(",", "."));

    if (!isNaN(valorNumerico)) {
      return valorNumerico.toFixed(1).replace(".", ",");
    }

    return notaStr; // Devolver la nota original si no es un número válido
  };

  const formatearPromedio = (valor) => {
    // Si el promedio es -1, "P" o "p", mostrar el ícono de pendiente
    if (valor === -1 || valor === "P" || valor === "p" || valor === "-1" ) {
      console.log("NotaPromedioCurso",valor)
      return (
        <i
          className={estilosEstado.pendiente.icon}
          style={{ color: estilosEstado.pendiente.iconColor }}
        ></i>
      );
    }
  
    // Verificar si el valor es un concepto (cadena de texto)
    if (typeof valor === "string" && isNaN(valor)) {
      return valor; // Retornar el concepto tal cual
    }
  
    // No mostrar nada si el promedio es 0
    if (valor === "0" || valor === 0 || valor === "0,0") {
      return "";
    }
  
    // Formatear valores numéricos
    if (typeof valor === "number") {
      return mostrarDosDecimalesPromedios
        ? valor.toFixed(2).replace(".", ",")
        : valor.toFixed(1).replace(".", ",");
    }
  
    if (typeof valor === "string") {
      const valorNumerico = parseFloat(valor.replace(",", "."));
      return isNaN(valorNumerico)
        ? "" // No mostrar nada si no es un número válido
        : mostrarDosDecimalesPromedios
        ? valorNumerico.toFixed(2).replace(".", ",")
        : valorNumerico.toFixed(1).replace(".", ",");
    }
  
    return mostrarDosDecimalesPromedios ? "0,00" : "0,0";
  };
  

  //============================================================================
  //======================FUNCIONES GESTION NOTAS===============================
  //============================================================================

  const manejarCambioNota = (valor) => {
    if (blockInputs) return;
    setNotaTemporal(valor);
  };

  const manejarGuardarNota = (id, campo) => {
    if (blockInputs) return;
  
    let valor = String(notaTemporal).trim();
  
    if (/^\d{2}$/.test(valor)) {
      valor = `${valor.charAt(0)},${valor.charAt(1)}`;
    }
  
    if (valor === "" || valor === "0" || valor === "-1" || valor === null) {
      setCeldaEdicion({ id: null, field: null, valor: "" });
      setNotaTemporal("");
      return;
    }
  
    const estudiante = datosOrdenados.find(
      (est) => est.estudiante.estudianteId === id
    );
  
    let notaActual, conceptoActual;
  
    // Verificar si es una nota acumulativa
    if (typeof campo === "string" && campo.includes("-")) {
      const [casillaSumativa, casillaAcumulativa] = campo.split("-");
      const nota = estudiante.notas.find(
        (nota) => nota.notaSumCasilla === parseInt(casillaSumativa)
      );
      notaActual = nota?.notasAcu?.find(
        (n) => n.notaAcuCasilla === parseInt(casillaAcumulativa)
      )?.notaAcu;
      conceptoActual = nota?.notasAcu?.find(
        (n) => n.notaAcuCasilla === parseInt(casillaAcumulativa)
      )?.notaAcuConcepto;
    } else {
      const nota = estudiante.notas.find(
        (nota) => nota.notaSumCasilla === campo
      );
      notaActual = nota?.notaSum;
      conceptoActual = nota?.notaSumConcepto;
    }
  
    const notaActualNormalizada = notaActual
      ? String(notaActual).replace(",0", "").trim().toUpperCase()
      : null;
    const valorNormalizado = valor.replace(",0", "").trim().toUpperCase();
  
    const esConcepto = conceptosPermitidos.includes(valorNormalizado);
    const esNotaActualConcepto =
      conceptoActual &&
      conceptosPermitidos.includes(conceptoActual.trim().toUpperCase());
  
    if (valorNormalizado === "P") {
      actualizarNota(id, campo, valorNormalizado);
      setCeldaEdicion({ id: null, field: null, valor: "" });
      setNotaTemporal("");
      return;
    }
  
    if (
      (notaActualNormalizada === valorNormalizado && !esNotaActualConcepto) ||
      (esNotaActualConcepto && esConcepto && conceptoActual === valorNormalizado)
    ) {
      setCeldaEdicion({ id: null, field: null, valor: "" });
      setNotaTemporal("");
      return;
    }
  
    if (conceptosPermitidos && conceptosPermitidos.length > 0) {
      if (!esConcepto && valor !== "P" && valor !== "-1") {
        setMensaje(
          "Esta asignatura se evalúa por concepto. Ingrese un concepto permitido."
        );
        setMostrarNotificacion(true);
        return;
      }
  
      if (esNotaActualConcepto && conceptoActual !== valorNormalizado) {
        setNotaPendiente({
          id,
          campo,
          valor: valor.toUpperCase(),
          conceptoActual,
        });
        setMostrarModal(true);
      } else {
        actualizarNota(id, campo, valor.toUpperCase());
      }
      return;
    }
  
    const notaValida = /^([1-6](,|\.)\d{1,2}|7(,|\.)0|[1-7])$/i.test(valor);
  
    if (notaValida) {
      if (
        notaActualNormalizada !== null &&
        valorNormalizado !== notaActualNormalizada &&
        valor.toUpperCase() !== "P"
      ) {
        setNotaPendiente({ id, campo, valor: valor.toUpperCase() });
        setMostrarModal(true); // Aquí se muestra el modal para notas acumulativas también
      } else {
        actualizarNota(id, campo, valor.toUpperCase());
      }
    } else {
      setNotaTemporal(valor);
      setMensaje("Nota inválida: " + valor);
      setMostrarNotificacion(true);
    }
  };
  

  const actualizarNota = useCallback(
    (id, campo, valor) => {
      // Normalizar el valor si se ingresa como dos dígitos (e.g., "23" se convierte en "2,3")
      if (/^\d{2}$/.test(valor)) {
        valor = `${valor.charAt(0)},${valor.charAt(1)}`;
      }
  
      // Formatear el valor para que esté listo para ser guardado
      const notaParaGuardar = formatearNotaParaGuardar(valor);
      const esCampoAcumulativo = typeof campo === "string" && campo.includes("-");
      const esConcepto = conceptosPermitidos.includes(valor.toUpperCase());
  
      const afterSaveFocus = () => {
        setTimeout(() => {
          if (ultimoInputEnfocado.current) {
            ultimoInputEnfocado.current.focus();
            ultimoInputEnfocado.current.select();
          }
        }, 0);
      };
  
      const handlePromedioCurso = (promedioCurso) => {
        if (promedioCurso) {
          const { cursoPromedioNota, cursoPromedioConcepto } = promedioCurso;
          setPromedioGneralCursoActualizado(
            cursoPromedioConcepto || cursoPromedioNota || ""
          );
        } else {
          setPromedioGneralCursoActualizado("");
        }
      };
  
      // Verificar si hay conexión a internet
      if (!navigator.onLine) {
        setMensaje("No hay conexión a internet. Revise su conexión y vuelva a intentarlo.");
        setMostrarNotificacion(true);
        setEstiloNotificacion({ backgroundColor: 'red' }); 
        afterSaveFocus();
        return;
      }
  
      // Actualización temporal en UI para acumulativas
      if (esCampoAcumulativo) {
        const [casillaSumativa, casilla] = campo.split("-");
        const notaFormateada = esConcepto
          ? valor.toUpperCase()
          : parseFloat(notaParaGuardar.replace(",", "."));
  
        // Actualización temporal de la nota en la interfaz de usuario
        setDatosOrdenados((prevDatos) =>
          prevDatos.map((estudiante) => {
            if (estudiante.estudiante.estudianteId === id) {
              const notasActualizadas = estudiante.notas.map((nota) => {
                if (nota.notaSumCasilla === Number(casillaSumativa)) {
                  const notasAcuActualizadas = nota.notasAcu.map((notaAcu) =>
                    notaAcu.notaAcuCasilla === Number(casilla)
                      ? {
                          ...notaAcu,
                          notaAcu: notaFormateada,
                          notaAcuConcepto: esConcepto ? valor.toUpperCase() : null,
                        }
                      : notaAcu
                  );
                  return {
                    ...nota,
                    notasAcu: notasAcuActualizadas,
                  };
                }
                return nota;
              });
  
              return {
                ...estudiante,
                notas: notasActualizadas,
              };
            }
            return estudiante;
          })
        );
  
        const datosAEnviar = {
          cursoId: Number(cursoId),
          asignaturaId: Number(asignaturaId),
          periodoEscolarId: Number(periodoEscolarId),
          id: Number(id),
          casilla: Number(casilla),
          casillaSumativa: Number(casillaSumativa),
          valor: notaFormateada,
        };
  
        const saveService = esConcepto
          ? saveConceptoAcuEstudiante
          : saveNotaAcuEstudiante;
  
        saveService(
          datosAEnviar.cursoId,
          datosAEnviar.asignaturaId,
          datosAEnviar.periodoEscolarId,
          datosAEnviar.id,
          datosAEnviar.casilla,
          datosAEnviar.casillaSumativa,
          datosAEnviar.valor
        )
          .then((response) => {
            if (response.data) {
              // setMensaje("Nota acumulativa guardada con éxito");
              // setMostrarNotificacion(true);
  
              // Actualización con los datos reales de la respuesta del servidor
              const { notasSumDetalle } = response.data;
              const notaSumativaActualizada = notasSumDetalle.notasSum.notaSum;
              const notaSumativaConceptoActualizado =
                notasSumDetalle.notasSum.notaSumConcepto || null;
              const promedioFilaActualizado =
                notasSumDetalle.notaFinal.notaFinalSum;
              const promedioFilaConceptoActualizado =
                notasSumDetalle.notaFinal.notaFinalSumConcepto || null;
              const promedioColumnaActualizado =
                notasSumDetalle.notaColumna.promedioColumnaNota;
              const promedioColumnaConceptoActualizado =
                notasSumDetalle.notaColumna.promedioColumnaConcepto || null;
  
              actualizarPromedios(
                id,
                {
                  notaSumCasilla: Number(casillaSumativa),
                  notaSum: notaSumativaActualizada,
                  notaSumConcepto: notaSumativaConceptoActualizado || null,
                },
                promedioFilaActualizado,
                promedioFilaConceptoActualizado || null
              );
  
              setPromediosColumnas((prev) => ({
                ...prev,
                [casillaSumativa]:
                  promedioColumnaConceptoActualizado ||
                  promedioColumnaActualizado,
              }));
  
              setDatosOrdenados((prevDatos) =>
                prevDatos.map((estudiante) => {
                  if (estudiante.estudiante.estudianteId === id) {
                    const notasActualizadas = estudiante.notas.map((nota) => {
                      if (nota.notaSumCasilla === Number(casillaSumativa)) {
                        const notasAcuActualizadas = nota.notasAcu.map(
                          (notaAcu) =>
                            notaAcu.notaAcuCasilla === Number(casilla)
                              ? {
                                  ...notaAcu,
                                  notaAcu: response.data.notaAcu,
                                  notaAcuConcepto:
                                    response.data.notaAcuConcepto ||
                                    notaAcu.notaAcuConcepto,
                                }
                              : notaAcu
                        );
                        return {
                          ...nota,
                          notasAcu: notasAcuActualizadas,
                          notaSum: notaSumativaActualizada,
                          notaSumConcepto:
                            notaSumativaConceptoActualizado ||
                            nota.notaSumConcepto,
                        };
                      }
                      return nota;
                    });
  
                    return {
                      ...estudiante,
                      notas: notasActualizadas,
                      notaFinal: {
                        ...estudiante.notaFinal,
                        notaFinalSum: promedioFilaActualizado,
                        notaFinalSumConcepto:
                          notasSumDetalle.notaFinal?.notaFinalSumConcepto ||
                          estudiante.notaFinal.notaFinalSumConcepto,
                      },
                    };
                  }
                  return estudiante;
                })
              );
  
              handlePromedioCurso(notasSumDetalle.promedioCurso);
              afterSaveFocus();
            } else {
              console.error("La respuesta no contiene datos:", response);
            }
          })
          .catch((error) => {
            // console.error("Error al guardar nota acumulativa:", error);
            // setMensaje(
            //   // "Error al guardar la nota acumulativa: " +
            //     (error.response ? error.response.data.message : error.message)
            // );
            setMostrarNotificacion(false);
            afterSaveFocus();
          });
      } else {
        // Actualización temporal en UI para sumativas
        const notaFormateada = esConcepto
          ? valor.toUpperCase()
          : parseFloat(notaParaGuardar.replace(",", "."));
  
        setDatosOrdenados((prevDatos) =>
          prevDatos.map((estudiante) => {
            if (estudiante.estudiante.estudianteId === id) {
              const notasActualizadas = estudiante.notas.map((nota) => {
                if (nota.notaSumCasilla === campo) {
                  return {
                    ...nota,
                    notaSum: notaFormateada,
                    notaSumConcepto: esConcepto ? valor.toUpperCase() : null,
                  };
                }
                return nota;
              });
              return {
                ...estudiante,
                notas: notasActualizadas,
              };
            }
            return estudiante;
          })
        );
  
        const datosAEnviar = {
          cursoId: Number(cursoId),
          asignaturaId: Number(asignaturaId),
          periodoEscolarId: Number(periodoEscolarId),
          id: Number(id),
          campo: Number(campo),
          valor: notaFormateada,
        };
  
        const saveService = esConcepto
          ? saveConceptoSumEstudiante
          : saveNotaSumEstudiante;
  
        saveService(
          datosAEnviar.cursoId,
          datosAEnviar.asignaturaId,
          datosAEnviar.periodoEscolarId,
          datosAEnviar.id,
          datosAEnviar.campo,
          datosAEnviar.valor
        )
          .then((response) => {
            // setMensaje("Nota guardada con éxito");
            // setMostrarNotificacion(true);
  
            handlePromedioCurso(response.data.promedioCurso);
  
            // Actualización definitiva con la respuesta del servidor
            setDatosOrdenados((prevDatos) =>
              prevDatos.map((estudiante) => {
                if (estudiante.estudiante.estudianteId === id) {
                  const notasActualizadas = estudiante.notas.map((nota) => {
                    if (nota.notaSumCasilla === campo) {
                      return {
                        ...nota,
                        notaSum: response.data.notasSum.notaSum,
                        notaSumConcepto: response.data.notasSum.notaSumConcepto || null,
                      };
                    }
                    return nota;
                  });
                  return {
                    ...estudiante,
                    notas: notasActualizadas,
                    notaFinal: {
                      ...estudiante.notaFinal,
                      notaFinalSum: response.data.notaFinal.notaFinalSum,
                      notaFinalSumConcepto: response.data.notaFinal.notaFinalSumConcepto || null,
                    },
                  };
                }
                return estudiante;
              })
            );
  
            afterSaveFocus();
          })
          .catch((error) => {
            // console.error("Error al guardar nota sumativa:", error);
            // setMensaje(
            //   "Error al guardar la nota sumativa: " +
            //     (error.response ? error.response.data.message : error.message)
            // );
            setMostrarNotificacion(false);
            afterSaveFocus();
          });
      }
    },
    [formatearNotaParaGuardar, cursoId, asignaturaId, periodoEscolarId, conceptosPermitidos]
  );
  
  
  const afterSaveFocus = () => {
    setTimeout(() => {
      if (ultimoInputEnfocado.current) {
        ultimoInputEnfocado.current.focus();
        ultimoInputEnfocado.current.select();
      }
    }, 0);
  };
  


  // Función para eliminar notas sumativas (concepto o numérica)
  const eliminarSumConcepto = useCallback((id, campo) => {
    // Verificar si hay conexión a internet antes de intentar la eliminación
    if (!navigator.onLine) {
      setMensaje("No hay conexión a internet. Revise su conexión y vuelva a intentarlo.");
      setMostrarNotificacion(true);
      setEstiloNotificacion({ backgroundColor: 'red' });
      afterSaveFocus();
      return;
    }
  
    // Encontrar el estudiante y obtener el concepto actual de la nota
    const estudiante = datosOrdenados.find(
      (est) => est.estudiante.estudianteId === id
    );
  
    const conceptoActual = estudiante.notas.find(
      (nota) => nota.notaSumCasilla === parseInt(campo)
    )?.notaSumConcepto;
  
    // Verificar si la nota es un concepto permitido
    const esConcepto =
      conceptoActual &&
      conceptosPermitidos.includes(conceptoActual.trim().toUpperCase());
  
    // Determinar el servicio adecuado según si es concepto o nota numérica
    const saveService = esConcepto
      ? saveConceptoSumEstudiante
      : saveNotaSumEstudiante;
  
    // Definir el valor que se enviará (vacío para concepto, "0" para numérico)
    const valorAEnviar = esConcepto ? "" : "0";
  
    // Llamar al servicio de eliminación
    saveService(cursoId, asignaturaId, periodoEscolarId, id, campo, valorAEnviar)
      .then((response) => {
        const {
          notasSum,
          notaFinal,
          notaColumna,
          promedioCurso
        } = response.data;
  
        // Actualizar datos con la respuesta del servidor
        setDatosOrdenados((prevDatos) => {
          return prevDatos.map((estudiante) => {
            if (estudiante.estudiante.estudianteId === id) {
              return {
                ...estudiante,
                notas: estudiante.notas.map((nota) =>
                  nota.notaSumCasilla === parseInt(campo)
                    ? {
                        ...nota,
                        notaSum: notasSum.notaSum,
                        notaSumConcepto: notasSum.notaSumConcepto,
                      }
                    : nota
                ),
                notaFinal: {
                  ...estudiante.notaFinal,
                  notaFinalSum: notaFinal.notaFinalSum,
                  notaFinalSumConcepto: notaFinal.notaFinalSumConcepto,
                },
              };
            }
            return estudiante;
          });
        });
  
        // Actualizar promedios de columna y curso
        setPromediosColumnas((prev) => ({
          ...prev,
          [campo]: notaColumna.promedioColumnaConcepto || notaColumna.promedioColumnaNota,
        }));
  
        setPromedioGneralCursoActualizado(
          promedioCurso.cursoPromedioConcepto || promedioCurso.cursoPromedioNota
        );
  
        // Limpiar la celda en edición y el valor temporal
        setCeldaEdicion({ id: null, field: null, valor: "" });
        setNotaTemporal("");
  
      })
      .catch((error) => {
        // Notificar al usuario en caso de error o pérdida de conexión
        if (!navigator.onLine) {
          setMensaje("No hay conexión a internet. Revise su conexión y vuelva a intentarlo.");
          setMostrarNotificacion(true);
          setEstiloNotificacion({ backgroundColor: 'red' });
        } else {
          setMensaje(
            "Error al eliminar la nota: " +
            (error.response ? error.response.data.message : error.message)
          );
          setMostrarNotificacion(true);
        }
      });
  }, [cursoId, asignaturaId, periodoEscolarId, datosOrdenados, conceptosPermitidos]);
  
  const eliminarNota = useCallback((id, campo) => {
    // Verificar conexión a internet antes de intentar la eliminación
    if (!navigator.onLine) {
      setMensaje("No hay conexión a internet. Revise su conexión y vuelva a intentarlo.");
      setMostrarNotificacion(true);
      setEstiloNotificacion({ backgroundColor: 'red' });
      afterSaveFocus();
      return;
    }
  
    const esCampoAcumulativo = typeof campo === "string" && campo.includes("-");
    
    // Si no es acumulativo, eliminar como sumativa
    if (!esCampoAcumulativo) {
      return eliminarSumConcepto(id, campo);
    }
  
    const [casillaSumativa, casillaAcumulativa] = campo.split("-").map(Number);
  
    const estudiante = datosOrdenados.find(
      (est) => est.estudiante.estudianteId === id
    );
  
    const notaAcumulativa = estudiante.notas
      .find((nota) => nota.notaSumCasilla === casillaSumativa)
      ?.notasAcu?.find(
        (notaAcu) => notaAcu.notaAcuCasilla === casillaAcumulativa
      );
    const conceptoActual = notaAcumulativa?.notaAcuConcepto;
  
    const esConcepto = 
      conceptoActual && 
      conceptosPermitidos.includes(conceptoActual.trim().toUpperCase());
  
    const saveService = esConcepto
      ? saveConceptoAcuEstudiante
      : saveNotaAcuEstudiante;
  
    const valorAEnviar = esConcepto ? "" : "0";
  
    saveService(
      cursoId,
      asignaturaId,
      periodoEscolarId,
      id,
      casillaAcumulativa,
      casillaSumativa,
      valorAEnviar
    )
      .then((response) => {
        const {
          notaAcu,
          notaAcuConcepto,
          notasSumDetalle
        } = response.data;
  
        const notaAcuValue = typeof notaAcu === 'object' ? notaAcu.notaAcu : notaAcu;
        const notaAcuConceptoValue = typeof notaAcuConcepto === 'object' ? notaAcuConcepto.notaAcuConcepto : notaAcuConcepto;
        
        const notaSumValue = notasSumDetalle?.notasSum?.notaSum || "";
        const notaSumConceptoValue = notasSumDetalle?.notasSum?.notaSumConcepto || null;
        const notaFinalSumValue = notasSumDetalle?.notaFinal?.notaFinalSum || "";
        const notaFinalSumConceptoValue = notasSumDetalle?.notaFinal?.notaFinalSumConcepto || null;
        const promedioColumnaNota = notasSumDetalle?.notaColumna?.promedioColumnaNota || "";
        const promedioColumnaConcepto = notasSumDetalle?.notaColumna?.promedioColumnaConcepto || null;
        const promedioCursoNota = notasSumDetalle?.promedioCurso?.cursoPromedioNota || "";
        const promedioCursoConcepto = notasSumDetalle?.promedioCurso?.cursoPromedioConcepto || null;
  
        setDatosOrdenados((prevDatos) => {
          return prevDatos.map((estudiante) => {
            if (estudiante.estudiante.estudianteId === id) {
              return {
                ...estudiante,
                notas: estudiante.notas.map((nota) =>
                  nota.notaSumCasilla === casillaSumativa
                    ? {
                        ...nota,
                        notasAcu: nota.notasAcu.map((notaAcu) =>
                          notaAcu.notaAcuCasilla === casillaAcumulativa
                            ? {
                                ...notaAcu,
                                notaAcu: notaAcuValue,
                                notaAcuConcepto: notaAcuConceptoValue,
                              }
                            : notaAcu
                        ),
                        notaSum: notaSumValue,
                        notaSumConcepto: notaSumConceptoValue,
                      }
                    : nota
                ),
                notaFinal: {
                  ...estudiante.notaFinal,
                  notaFinalSum: notaFinalSumValue,
                  notaFinalSumConcepto: notaFinalSumConceptoValue,
                },
              };
            }
            return estudiante;
          });
        });
  
        setPromediosColumnas((prev) => ({
          ...prev,
          [casillaSumativa]: promedioColumnaConcepto || promedioColumnaNota,
        }));
  
        setPromedioGneralCursoActualizado(
          promedioCursoConcepto || promedioCursoNota
        );
  
        setCeldaEdicion({ id: null, field: null, valor: "" });
        setNotaTemporal("");
  
      })
      .catch((error) => {
        if (!navigator.onLine) {
          setMensaje("No hay conexión a internet. Revise su conexión y vuelva a intentarlo.");
          setMostrarNotificacion(true);
          setEstiloNotificacion({ backgroundColor: 'red' });
        } else {
          setMensaje(
            "Error al eliminar la nota: " +
            (error.response ? error.response.data.message : error.message)
          );
          setMostrarNotificacion(true);
        }
      });
  }, [cursoId, asignaturaId, periodoEscolarId, datosOrdenados, conceptosPermitidos]);
  
  



  const manejarGuardarNotaYContinuar = (e, id, campo, valor) => {
    // console.log(
    //   `Inicio de manejarGuardarNotaYContinuar para ${id}, campo: ${campo}, valor inicial: ${valor}`
    // );

    // Guardar la nota actual sin perder el foco si es concepto permitido o numérico
    manejarGuardarNota(id, campo, valor);

    const { fila: filaAnterior, columna: columnaAnterior } =
      posicionAnterior.current;
    const filaActual = datosOrdenados.findIndex(
      (estudiante) => estudiante.estudiante.estudianteId === id
    );
    const columnaActual = columnasNotas.indexOf(campo);

    let nuevaId = id;
    let nuevoCampo = campo;

    // Lógica de navegación según la tecla presionada
    if (
      e.key === "ArrowDown" ||
      (e.key === "Enter" && filaAnterior < filaActual)
    ) {
      const siguienteIndice = (filaActual + 1) % datosOrdenados.length;
      nuevaId = datosOrdenados[siguienteIndice].estudiante.estudianteId;
    } else if (
      e.key === "ArrowUp" ||
      (e.key === "Enter" && filaAnterior > filaActual)
    ) {
      const indiceAnterior =
        (filaActual - 1 + datosOrdenados.length) % datosOrdenados.length;
      nuevaId = datosOrdenados[indiceAnterior].estudiante.estudianteId;
    } else if (
      e.key === "ArrowRight" ||
      (e.key === "Enter" && columnaAnterior < columnaActual)
    ) {
      const siguienteColumna = (columnaActual + 1) % columnasNotas.length;
      nuevoCampo = columnasNotas[siguienteColumna];
    } else if (
      e.key === "ArrowLeft" ||
      (e.key === "Enter" && columnaAnterior > columnaActual)
    ) {
      const columnaAnterior =
        (columnaActual - 1 + columnasNotas.length) % columnasNotas.length;
      nuevoCampo = columnasNotas[columnaAnterior];
    }

    const nuevoEstudiante = datosOrdenados.find(
      (est) => est.estudiante.estudianteId === nuevaId
    );
    const nuevaNotaObj = nuevoEstudiante?.notas.find(
      (nota) => nota.notaSumCasilla === nuevoCampo
    );
    const nuevaNota =
      nuevaNotaObj?.notaSumConcepto || nuevaNotaObj?.notaSum || "";

    // console.log(
    //   `Moviendo a nueva celda. ID: ${nuevaId}, Campo: ${nuevoCampo}, Valor: ${nuevaNota}`
    // );

    // Navegar a la siguiente celda
    setTimeout(() => {
      setCeldaEdicionConFormato(nuevaId, nuevoCampo, nuevaNota);

      // Volver a enfocar el input después de navegar
      const inputElement = document.getElementById(
        `input-${nuevaId}-${nuevoCampo}`
      );
      if (inputElement) {
        inputElement.focus();
        inputElement.select(); // Seleccionar el texto para facilitar la edición
      }
    }, 0);

    // Actualizar la posición anterior con la actual
    posicionAnterior.current = { fila: filaActual, columna: columnaActual };

    // console.log("Fin de manejarGuardarNotaYContinuar");
  };

  const confirmarCambioNota = useCallback(() => {
    const { id, campo, valor } = notaPendiente;

    if (valor === "0") {
      eliminarNota(id, campo);
    } else {
      actualizarNota(id, campo, valor);
    }

    setCeldaEdicion({ id: null, field: null, valor: "" });
    setNotaTemporal("");
    manejarOcultarModal();

    reenfocarInput(); // Reenfocar el input después de cerrar el modal
  }, [notaPendiente, manejarOcultarModal, actualizarNota, eliminarNota]);

  //============================================================================
  //======================FUNCIONES NAVEGACION CELDAS===========================
  //============================================================================


  // const moverALaCeldaHorizontal = (id, campo, direccion) => {
  //   const indiceActual = datosOrdenados.findIndex(
  //     (estudiante) => estudiante.estudiante.estudianteId === id
  //   );
  //   const indiceColumnaActual = columnasNotas.indexOf(campo);
  //   const nuevoIndiceColumna = indiceColumnaActual + direccion;

  //   if (nuevoIndiceColumna >= 0 && nuevoIndiceColumna < columnasNotas.length) {
  //     const nuevoCampo = columnasNotas[nuevoIndiceColumna];
  //     let nuevaNota =
  //       datosOrdenados[indiceActual].notas.find(
  //         (nota) => nota.notaSumCasilla === nuevoCampo
  //       )?.notaSum || "";
  //     nuevaNota = nuevaNota === "0" ? "" : nuevaNota;

  //     setCeldaEdicionConFormato(id, nuevoCampo, nuevaNota);

  //     // Mover el foco al nuevo input
  //     setTimeout(() => {
  //       const input = document.getElementById(`input-${id}-${nuevoCampo}`);
  //       if (input) {
  //         input.focus();
  //       }
  //     }, 0);
  //   }
  // };

 

  const moverAlaSiguienteCelda = (id, campo) => {
    const indiceActual = datosOrdenados.findIndex(
      (estudiante) => estudiante.estudiante.estudianteId === id
    );
    const siguienteIndice = (indiceActual + 1) % datosOrdenados.length;
  
    const esAcumulativa = typeof campo === "string" && campo.includes("-");
    let siguienteValor;
  
    if (esAcumulativa) {
      const [casillaSumativa, casillaAcumulativa] = campo.split("-");
      const notaAcu = datosOrdenados[siguienteIndice].notas
        .find((n) => n.notaSumCasilla === parseInt(casillaSumativa))
        ?.notasAcu?.find((n) => n.notaAcuCasilla === parseInt(casillaAcumulativa));
        
      // Mostrar el concepto o el valor de la nota
      siguienteValor = notaAcu?.notaAcuConcepto || notaAcu?.notaAcu || "";
    } else {
      const notaSum = datosOrdenados[siguienteIndice].notas
        .find((nota) => nota.notaSumCasilla === campo);
        
      // Mostrar el concepto o el valor de la nota
      siguienteValor = notaSum?.notaSumConcepto || notaSum?.notaSum || "";
    }
  
    siguienteValor = siguienteValor === "0" ? "" : siguienteValor;
  
    console.log(`Celda actual: ${id} - ${campo}`);
    console.log(`Siguiente celda: ${datosOrdenados[siguienteIndice].estudiante.estudianteId} - ${campo}`);
    console.log(`Es acumulativa: ${esAcumulativa}`);
    console.log(`Valor de la siguiente nota: ${siguienteValor}`);
  
    if (
      String(notaTemporal).trim() !==
      datosOrdenados[indiceActual].notas.find(
        (nota) => nota.notaSumCasilla === campo
      )?.notaSum
    ) {
      manejarGuardarNota(id, campo);
    }
  
    setCeldaEdicionConFormato(
      datosOrdenados[siguienteIndice].estudiante.estudianteId,
      campo,
      siguienteValor
    );
  
    setTimeout(() => {
      const inputId = `input-${datosOrdenados[siguienteIndice].estudiante.estudianteId}-${campo}`;
      const input = document.getElementById(inputId);
      
      if (input) {
        console.log(`Enfocando input: ${inputId}`);
        input.focus();
        input.select();
      } else {
        console.log(`No se encontró el input con ID: ${inputId} para reenfocar, reintentando...`);
        setTimeout(() => {
          const retryInput = document.getElementById(inputId);
          if (retryInput) {
            console.log(`Reintentando enfoque: ${inputId}`);
            retryInput.focus();
            retryInput.select();
          } else {
            console.log(`Input ${inputId} aún no disponible.`);
          }
        }, 50);
      }
    }, 50);
  };
  
  const moverAlaCeldaAnterior = (id, campo) => {
    const indiceActual = datosOrdenados.findIndex(
      (estudiante) => estudiante.estudiante.estudianteId === id
    );
    const indicePrevio = (indiceActual - 1 + datosOrdenados.length) % datosOrdenados.length;
  
    const esAcumulativa = typeof campo === "string" && campo.includes("-");
    let valorAnterior;
  
    if (esAcumulativa) {
      const [casillaSumativa, casillaAcumulativa] = campo.split("-");
      const notaAcu = datosOrdenados[indicePrevio].notas
        .find((n) => n.notaSumCasilla === parseInt(casillaSumativa))
        ?.notasAcu?.find((n) => n.notaAcuCasilla === parseInt(casillaAcumulativa));
        
      valorAnterior = notaAcu?.notaAcuConcepto || notaAcu?.notaAcu || "";
    } else {
      const notaSum = datosOrdenados[indicePrevio].notas
        .find((nota) => nota.notaSumCasilla === campo);
        
      valorAnterior = notaSum?.notaSumConcepto || notaSum?.notaSum || "";
    }
  
    valorAnterior = valorAnterior === "0" ? "" : valorAnterior;
  
    console.log(`Celda actual: ${id} - ${campo}`);
    console.log(`Celda anterior: ${datosOrdenados[indicePrevio].estudiante.estudianteId} - ${campo}`);
    console.log(`Es acumulativa: ${esAcumulativa}`);
    console.log(`Valor de la nota anterior: ${valorAnterior}`);
  
    if (
      String(notaTemporal).trim() !==
      datosOrdenados[indiceActual].notas.find(
        (nota) => nota.notaSumCasilla === campo
      )?.notaSum
    ) {
      manejarGuardarNota(id, campo);
    }
  
    setCeldaEdicionConFormato(
      datosOrdenados[indicePrevio].estudiante.estudianteId,
      campo,
      valorAnterior
    );
  
    setTimeout(() => {
      const input = document.getElementById(
        `input-${datosOrdenados[indicePrevio].estudiante.estudianteId}-${campo}`
      );
      if (input) {
        console.log(`Enfocando input: input-${datosOrdenados[indicePrevio].estudiante.estudianteId}-${campo}`);
        input.focus();
        input.select();
      } else {
        console.log("No se encontró el input esperado para enfocar.");
      }
    }, 0);
  };
  
  const moverALaSiguienteOAnteriorCelda = (e, id, campo) => {
    const indiceActual = datosOrdenados.findIndex(
      (estudiante) => estudiante.estudiante.estudianteId === id
    );
    const indiceColumna = columnasNotas.indexOf(campo);
    let nuevoId, nuevoCampo, nuevoValor;
  
    if (e.key === "ArrowLeft") {
      if (indiceColumna === 0) {
        const indicePrevio = (indiceActual - 1 + datosOrdenados.length) % datosOrdenados.length;
        nuevoId = datosOrdenados[indicePrevio].estudiante.estudianteId;
        nuevoCampo = columnasNotas[columnasNotas.length - 1];
      } else {
        nuevoId = id;
        nuevoCampo = columnasNotas[(indiceColumna - 1 + columnasNotas.length) % columnasNotas.length];
      }
    } else if (e.key === "ArrowRight") {
      if (indiceColumna === columnasNotas.length - 1) {
        const siguienteIndice = (indiceActual + 1) % datosOrdenados.length;
        nuevoId = datosOrdenados[siguienteIndice].estudiante.estudianteId;
        nuevoCampo = columnasNotas[0];
      } else {
        nuevoId = id;
        nuevoCampo = columnasNotas[(indiceColumna + 1) % columnasNotas.length];
      }
    }
  
    const esAcumulativa = typeof nuevoCampo === "string" && nuevoCampo.includes("-");
    if (esAcumulativa) {
      const [casillaSumativa, casillaAcumulativa] = nuevoCampo.split("-");
      const notaAcu = datosOrdenados.find((est) => est.estudiante.estudianteId === nuevoId)
        .notas.find((n) => n.notaSumCasilla === parseInt(casillaSumativa))
        ?.notasAcu?.find((n) => n.notaAcuCasilla === parseInt(casillaAcumulativa));
        
      nuevoValor = notaAcu?.notaAcuConcepto || notaAcu?.notaAcu || "";
    } else {
      const notaSum = datosOrdenados.find((est) => est.estudiante.estudianteId === nuevoId)
        .notas.find((n) => n.notaSumCasilla === nuevoCampo);
        
      nuevoValor = notaSum?.notaSumConcepto || notaSum?.notaSum || "";
    }
  
    nuevoValor = nuevoValor === "0" ? "" : nuevoValor;
  
    console.log(`Celda actual: ${id} - ${campo}`);
    console.log(`Nueva celda: ${nuevoId} - ${nuevoCampo}`);
    console.log(`Es acumulativa: ${esAcumulativa}`);
    console.log(`Valor de la nueva nota: ${nuevoValor}`);
  
    if (
      String(notaTemporal).trim() !==
      datosOrdenados[indiceActual].notas.find((nota) => nota.notaSumCasilla === campo)?.notaSum
    ) {
      manejarGuardarNota(id, campo);
    }
  
    setCeldaEdicionConFormato(nuevoId, nuevoCampo, nuevoValor);
  
    setTimeout(() => {
      const input = document.getElementById(`input-${nuevoId}-${nuevoCampo}`);
      if (input) {
        console.log(`Enfocando input: input-${nuevoId}-${nuevoCampo}`);
        input.focus();
        input.select();
      } else {
        console.log("No se encontró el input esperado para enfocar.");
      }
    }, 0);
  };
  

  //============================================================================
  //======================FUNCIONES MANEJO TECLADO==============================
  //============================================================================

  const manejarKeyPress = (e, id, campo) => {
    const valorActual = e.target.value.trim();
    let notaActual;
  
    if (typeof campo === "string" && campo.includes("-")) {
      // Es una nota acumulativa
      const [casillaSumativa, casillaAcumulativa] = campo.split("-");
      notaActual = datosOrdenados
        .find((est) => est.estudiante.estudianteId === id)
        .notas.find((n) => n.notaSumCasilla === parseInt(casillaSumativa))
        ?.notasAcu?.find(
          (n) => n.notaAcuCasilla === parseInt(casillaAcumulativa)
        )?.notaAcu;
    } else {
      // Es una nota sumativa
      notaActual = datosOrdenados
        .find((est) => est.estudiante.estudianteId === id)
        .notas.find((nota) => nota.notaSumCasilla === campo)?.notaSum;
    }
  
    // Si el modal está abierto y se presiona Enter, confirmar cambio
    if (mostrarModal && e.key === "Enter") {
      confirmarCambioNota(); // función que maneja la confirmación en el modal
      return;
    }
  
    // Manejo de teclas normales (Delete, Enter, navegación)
    if (e.key === "Delete" || (e.key === "Backspace" && valorActual === "")) {
      if (notaActual && notaActual !== "0" && notaActual !== "0,0") {
        e.preventDefault();
        setNotaPendiente({ id, campo, valor: "0" });
        setMostrarModal(true);
      }
    } else if (
      e.key === "Enter" ||
      e.key === "ArrowDown" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight"
    ) {
      // Guardado de la nota y navegación con teclas
      if (valorActual.length === 1 && /^[0-7]$/.test(valorActual)) {
        const valorFormateado = `${valorActual},0`;
        manejarGuardarNota(id, campo, valorFormateado);
      } else if (typeof valorActual === "string" && valorActual.toLowerCase() === "p") {
        manejarGuardarNota(id, campo, "P");
      } else if (/^[0-7],[0-9]$/.test(valorActual)) {
        manejarGuardarNota(id, campo, valorActual);
      }
  
      if (e.key === "Enter" || e.key === "ArrowDown") {
        moverAlaSiguienteCelda(id, campo);
      } else if (e.key === "ArrowUp") {
        moverAlaCeldaAnterior(id, campo);
      } else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
        moverALaSiguienteOAnteriorCelda(e, id, campo);
      }
    }
  };
  

  // const manejarKeyDown = (e, id, campo) => {
  //   const valorActual = e.target.value.trim();
  //   const notaActual = datosOrdenados
  //     .find((est) => est.estudiante.estudianteId === id)
  //     .notas.find((nota) => nota.notaSumCasilla === campo)?.notaSum;

  //   if (e.key === "Delete" || (e.key === "Backspace" && valorActual === "")) {
  //     // Si se presiona Supr o Backspace cuando el input está vacío
  //     if (notaActual && notaActual !== "0" && notaActual !== "0,0") {
  //       setNotaPendiente({ id, campo, valor: "0" });
  //       setMostrarModal(true);
  //     }
  //   } else if (
  //     e.key === "Enter" ||
  //     e.key === "ArrowDown" ||
  //     e.key === "ArrowUp" ||
  //     e.key === "ArrowLeft" ||
  //     e.key === "ArrowRight"
  //   ) {
  //     manejarGuardarNotaYContinuar(e, id, campo);
  //   }
  // };

  //============================================================================
  //======================FUNCIONES RENDER CONDICIONAL==========================
  //============================================================================

  const renderizarContenido = (nota, concepto) => {
    if (concepto && concepto !== "") {
      return <span>{concepto}</span>; 
    }

    // Verificar si es pendiente
    if (nota === -1 || nota === "P" || nota === "p") {
      return (
        <i
          className={estilosEstado.pendiente.icon}
          style={{ color: estilosEstado.pendiente.iconColor }}
        ></i>
      );
    } else if (nota === null || nota === "0,0" || nota === 0 || nota === "0") {
      return ""; 
    } else {
      const notaNumero = parseFloat(String(nota).replace(",", "."));
      const color = notaNumero < 4 ? "red" : "black";
      return <span style={{ color }}>{formatearValorNota(nota)}</span>;
    }
  };

  const deberiaMostrarInputInactivo = (nota) => {
    return nota === null || nota === "" || nota === 0 || nota === "0" || nota === "0,0";
  };
  

  //============================================================================
  //======================FUNCIONES ACTUALIZAR PROMEDIO=========================
  //============================================================================

  const actualizarPromedios = (
    id,
    nuevaNotaSum,
    nuevoPromedioFila,
    nuevoPromedioConcepto
  ) => {
    setDatosOrdenados((prevDatos) => {
      return prevDatos.map((estudiante) => {
        if (estudiante.estudiante.estudianteId === id) {
          return {
            ...estudiante,
            notas: estudiante.notas.map((nota) =>
              nota.notaSumCasilla === nuevaNotaSum.notaSumCasilla
                ? {
                    ...nota,
                    notaSum: nuevaNotaSum.notaSum,
                    notaSumConcepto: nuevaNotaSum.notaSumConcepto, // Guardar concepto si existe
                  }
                : nota
            ),
            notaFinal: {
              ...estudiante.notaFinal,
              notaFinalSum: nuevoPromedioFila,
              notaFinalSumConcepto:
              nuevoPromedioConcepto === ""
              ? nuevoPromedioFila 
              : nuevoPromedioConcepto || null,
},
          };
        }
        return estudiante;
      });
    });
  };

  //============================================================================
  //======================FUNCIONES GESTION DE FOCO=============================
  //============================================================================

  const reenfocarInput = () => {
    // console.log(ultimoInputEnfocado.current);
    requestAnimationFrame(() => {
      if (ultimoInputEnfocado.current) {
        ultimoInputEnfocado.current.focus();
        ultimoInputEnfocado.current.select();
      }
      // console.log("REENFOCANDO INPUT:",ultimoInputEnfocado.current);
    });
  };

  const setCeldaEdicionConFormato = (id, field, valor) => {
    // Si el valor es "0" o está vacío, preparar el input para estar listo para edición
    if (valor === -1 || valor === "0" || valor === "") {
      valor = ""; // Mantener el campo vacío pero listo para editar
    } else if (typeof valor === "number" && Number.isInteger(valor)) {
      valor = valor.toFixed(1).replace(".", ",");
    } else if (typeof valor === "string" && /^[1-7]$/.test(valor)) {
      valor = `${valor},0`;
    }

    setCeldaEdicion({ id, field, valor });
    setNotaTemporal(valor); // Mantener el valor temporal para el input

    // Guardar la posición anterior
    const filaActual = datosOrdenados.findIndex(
      (estudiante) => estudiante.estudianteId === id
    );
    const columnaActual = columnasNotas.indexOf(field);
    posicionAnterior.current = { fila: filaActual, columna: columnaActual };

    // Asegurarnos de que el input tenga el foco inmediatamente
    setTimeout(() => {
      const input = document.getElementById(`input-${id}-${field}`);
      if (input) {
        input.focus();
        input.select();
      }
    }, 0);
  };

  //============================================================================
  //======================FUNCIONES MANEJAR EXPANSION COLUMNAS==================
  //============================================================================

  const manejarClickEncabezado = (casillaSumativa) => {
    if (hasPermission) {
      if (!blockInputs) {
        setColumnasExpandidas((prevExpandidas) => ({
          ...prevExpandidas,
          [casillaSumativa]: !prevExpandidas[casillaSumativa],
        }));
      } else {
        setModalMensaje(
          "Para cambiar esta calificación, debe modificar las notas acumulativas que la componen."
        );
        setMostrarModalAviso(true);
      }
    } else {
      setModalMensaje("No tienes permiso para expandir las calificaciones.");
      setMostrarModalAviso(true);
    }
  };

  //============================================================================
  //======================VARIABLES IMPORTANTES=================================
  //============================================================================

  const columnasNotas =
    data && data.confNotasSum
      ? data.confNotasSum.flatMap((nota) => {
          if (nota.confSumAcumulativa && nota.confNotaAcu) {
            return nota.confNotaAcu
              .map(
                (notaAcu) => `${nota.confSumCasilla}-${notaAcu.confAcuCasilla}`
              )
              .concat(nota.confSumCasilla);
          }
          return nota.confSumCasilla;
        })
      : [];

  //============================================================================
  //======================USE EFFECT============================================
  //============================================================================

  useEffect(() => {
    if (data && data.listadoNotas) {
      // console.log("Data cargada:", data.listadoNotas);

      const dataOrdenada = [...data.listadoNotas].sort(
        (a, b) => a.estudiante.numLista - b.estudiante.numLista
      );
      // console.log("Datos ordenados:", dataOrdenada);
      setDatosOrdenados(dataOrdenada);
      const promedios = {};
      data.cursoColumna.forEach((col) => {
        promedios[col.columna] = col.promedioColumna;
      });
      setPromediosColumnas(promedios);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.cursoPromedio) {
      setPromedioCurso(data.cursoPromedio);
    }
  }, [data]);

  // useEffect(() => {
  //   const handleKeyDown = (e) => {
  //     if (e.key === "Enter" && mostrarModal) {
  //       e.preventDefault();
  //       confirmarCambioNota();
  //     }
  //   };

  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [confirmarCambioNota, mostrarModal]);

  useEffect(() => {
    // Asegurarnos de que el input tenga el foco inmediatamente después del render
    if (celdaEdicion.id && celdaEdicion.field) {
      const input = document.getElementById(`input-${celdaEdicion.id}-${celdaEdicion.field}`);
      if (input) {
        setTimeout(() => {
          input.focus();
          input.select(); // Seleccionar el texto para facilitar la edición
        }, 0);
      }
    }
  }, [celdaEdicion]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === " " && mostrarModal) { 
        e.preventDefault(); 
        confirmarCambioNota(); 
      }
    };
  
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [mostrarModal, confirmarCambioNota]);


  //============================================================================
  //======================COMPONENTE PRINCIPAL==================================
  //============================================================================

  return (
    <div className="container-fluid px-3 pt-0 bg-white">
      <div className="table-responsive" style={estilos.container}>
        <Table id={tablaId}>
          <thead>
            {/* SECCIÓN: Encabezado de la tabla */}

            <EncabezadoTablaCalificaciones
              data={data}
              mostrarAcumulativas={mostrarAcumulativas}
              columnasExpandidas={columnasExpandidas}
              estilos={estilos}
              hasPermission={hasPermission}
              manejarClickEncabezado={manejarClickEncabezado}
              blockInputs={blockInputs}
              setModalMensaje={setModalMensaje}
              setMostrarModalAviso={setMostrarModalAviso}
            />
          </thead>

          <tbody>
            {filtrarYOrdenarEstudiantes.map((estudiante, rowIndex) => (
              <tr
                key={estudiante.estudiante.estudianteId}
                onMouseEnter={() => !isHeatmapActive && setHoveredRow(rowIndex)}
                onMouseLeave={() => !isHeatmapActive && setHoveredRow(null)}
                style={
                  estudiante.estudiante.retirado ? estilos.celdaRetirado : {}
                }
              >
                {/* SECCIÓN: Número de lista del estudiante */}

                <td
                  className="text-center"
                  style={{
                    ...estilos.td(false, null, hoveredRow === rowIndex, false),
                    ...estilos.textoTabla,
                    position: "relative",
                  }}
                >
                  {estudiante.estudiante.numLista !== null ? (
                    <span
                      style={
                        estudiante.estudiante.retirado
                          ? { textDecoration: "line-through" }
                          : {}
                      }
                    >
                      {estudiante.estudiante.numLista}
                    </span>
                  ) : estudiante.estudiante.numMatricula !== null ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id={`tooltip-${estudiante.estudiante.estudianteId}`}
                        >
                          {
                            estilosEstadoAdicionales.matriculadoSinNumeroLista
                              .description
                          }
                        </Tooltip>
                      }
                    >
                      <i
                        className={`${estilosEstadoAdicionales.matriculadoSinNumeroLista.icon}`}
                        style={{
                          color:
                            estilosEstadoAdicionales.matriculadoSinNumeroLista
                              .iconColor,
                          fontSize: "20px",
                        }}
                      ></i>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id={`tooltip-${estudiante.estudiante.estudianteId}`}
                        >
                          {
                            estilosEstadoAdicionales.sinNumeroMatriculaYLista
                              .description
                          }
                        </Tooltip>
                      }
                    >
                      <i
                        className={`${estilosEstadoAdicionales.sinNumeroMatriculaYLista.icon}`}
                        style={{
                          color:
                            estilosEstadoAdicionales.sinNumeroMatriculaYLista
                              .iconColor,
                          fontSize: "20px",
                        }}
                      ></i>
                    </OverlayTrigger>
                  )}
                  {estudiante.estudiante.retirado && (
                    <div
                      style={{
                        ...estilos.lineaRoja,
                        position: "absolute",
                        top: "50%",
                        left: 0,
                        right: 0,
                      }}
                    ></div>
                  )}
                </td>

                {/* SECCIÓN: Nombre del estudiante */}

                {/* ) : <></>
                }
              > */}
                <td
                  className="d-flex align-items-center"
                  style={{
                    ...estilos.td(false, null, hoveredRow === rowIndex, false),
                    ...(estudiante.estudiante.retirado
                      ? estilos.textoEstudianteCeldaRetirado
                      : estilos.colEstudiante),
                    ...estilos.textoTabla,
                    borderRight: "1px solid lightgrey",
                    position: "relative",
                  }}
                >
                  {/* <img
                  src={defaultAvatar}
                  alt="avatar"
                  style={estilos.avatar}
                /> */}
                  
                   {/* { console.log("este es el run del estudiante",estudiante.estudiante.rut)} */}
                    <div className="d-flex justify-content-center align-items-center gap-2">
                      <Avatar
                        width={"30px"}
                        height={"30px"}
                        run={
                          estudiante.estudiante.rut || estudiante.estudiante.ipe
                        }
                        allowPreview
                      /><CustomTooltip
                          tooltipMsg={
                            estudiante.estudiante.retirado
                               ? `Estudiante retirado: ${estudiante.estudiante.nombre}` 
                              : `${estudiante.estudiante.nombre}`
                          }
                        >
                      <span
                        style={
                          estudiante.estudiante.retirado
                            ? { textDecoration: "line-through" }
                            : {}
                        }
                      >
                        {`${estudiante.estudiante.nombre}`}
                      </span>
                      </CustomTooltip>
                    </div>
                    {estudiante.estudiante.retirado && (
                      <div
                        style={{
                          ...estilos.lineaRoja,
                          position: "absolute",
                          top: "50%",
                          left: 0,
                          right: 0,
                        }}
                      ></div>
                    )}
                  
                </td>

                {/* SECCIÓN: Notas Acumulativas */}

                {data.confNotasSum.flatMap((calificacion, colIndex) => {
  if (
    calificacion.confSumAcumulativa &&
    (mostrarAcumulativas || columnasExpandidas[calificacion.confSumCasilla])
  ) {
    return calificacion.confNotaAcu.map((notaAcu, acuIndex) => {
      const estudianteNotaAcu = estudiante.notas
        .find((n) => n.notaSumCasilla === calificacion.confSumCasilla)
        ?.notasAcu?.find((n) => n.notaAcuCasilla === notaAcu.confAcuCasilla);

      return (
        <td
          key={`${calificacion.confSumCasilla}-${notaAcu.confAcuCasilla}`}
          id={`celda-${estudiante.estudiante.estudianteId}-${calificacion.confSumCasilla}-${notaAcu.confAcuCasilla}`}
          className="text-center"
          style={{
            ...estilos.tdHeatmap(
              isHeatmapActive,
              estudianteNotaAcu
                ? estudianteNotaAcu.notaAcuConcepto || estudianteNotaAcu.notaAcu
                : "",
              false,
              hoveredRow === rowIndex,
              hoveredColumn === `${colIndex}-${acuIndex}`
            ),
            // Aplica el borde izquierdo solo si no es la primera sumativa expandida
            borderLeft: acuIndex === 0 && colIndex !== 0 ? "1px solid #ccc" : "none",
          }}
          onMouseEnter={() => !isHeatmapActive && setHoveredColumn(`${colIndex}-${acuIndex}`)}
          onMouseLeave={() => !isHeatmapActive && setHoveredColumn(null)}
          onClick={() => {
            if (blockInputs && hasPermission) {
              setModalMensaje(
                "Para ingresar calificaciones, presiona el botón 'Desbloquear' y luego ingresa tu código de Doble Autenticación."
              );
              setMostrarModalAviso(true);
            } else if (!blockInputs && hasPermission) {
              let notaActual = estudianteNotaAcu?.notaAcu || "";
              if (estudianteNotaAcu?.notaAcuConcepto) {
                notaActual = estudianteNotaAcu.notaAcuConcepto;
              }
              if (notaActual === "P" || notaActual === "p" || notaActual === -1) {
                notaActual = "";
              }
              setCeldaEdicionConFormato(
                estudiante.estudiante.estudianteId,
                `${calificacion.confSumCasilla}-${notaAcu.confAcuCasilla}`,
                notaActual
              );
            }
          }}
        >
          {celdaEdicion.id === estudiante.estudiante.estudianteId &&
          celdaEdicion.field === `${calificacion.confSumCasilla}-${notaAcu.confAcuCasilla}` ? (
            <input
              id={`input-${estudiante.estudiante.estudianteId}-${calificacion.confSumCasilla}-${notaAcu.confAcuCasilla}`}
              ref={ultimoInputEnfocado}
              type="text"
              value={String(notaTemporal).replace(".", ",")}
              onChange={(e) => setNotaTemporal(e.target.value)}
              onBlur={() =>
                manejarGuardarNota(
                  estudiante.estudiante.estudianteId,
                  `${calificacion.confSumCasilla}-${notaAcu.confAcuCasilla}`
                )
              }
              onKeyDown={(e) =>
                manejarKeyPress(
                  e,
                  estudiante.estudiante.estudianteId,
                  `${calificacion.confSumCasilla}-${notaAcu.confAcuCasilla}`
                )
              }
              style={estilos.inputActive}
              autoFocus
              autoComplete="off"
              readOnly={blockInputs || !hasPermission}
            />
          ) : estudianteNotaAcu && (estudianteNotaAcu.notaAcuConcepto || estudianteNotaAcu.notaAcu) ? (
            <span>{renderizarContenido(estudianteNotaAcu.notaAcuConcepto || estudianteNotaAcu.notaAcu)}</span>
          ) : (
            <input type="text" readOnly value="" style={estilos.inputInactive} />
          )}
        </td>
      );
    }).concat(
      <td
        key={calificacion.confSumCasilla}
        id={`celda-${estudiante.estudiante.estudianteId}-${calificacion.confSumCasilla}`}
        className="text-center"
        style={{
          ...estilos.tdHeatmapSinBordeDerecho(
            isHeatmapActive,
            estudiante.notas.find((n) => n.notaSumCasilla === calificacion.confSumCasilla)
              ?.notaSumConcepto ||
              estudiante.notas.find((n) => n.notaSumCasilla === calificacion.confSumCasilla)
              ?.notaSum ||
              "",
            true,
            hoveredRow === rowIndex,
            hoveredColumn === `${colIndex}`
          ),
          borderRight: "1px solid #ccc", // Borde derecho para la última celda del conjunto de acumulativas
        }}
        onMouseEnter={() => !isHeatmapActive && setHoveredColumn(`${colIndex}`)}
        onMouseLeave={() => !isHeatmapActive && setHoveredColumn(null)}
        onClick={() => {
          if (!blockInputs) {
            manejarClickEncabezado(calificacion.confSumCasilla);
          } else if (hasPermission) {
            setModalMensaje(
              "Para cambiar esta calificación, debe modificar las notas acumulativas que la componen."
            );
            setMostrarModalAviso(true);
          }
        }}
      >
        {renderizarContenido(
          estudiante.notas.find((n) => n.notaSumCasilla === calificacion.confSumCasilla)
            ?.notaSumConcepto ||
            estudiante.notas.find((n) => n.notaSumCasilla === calificacion.confSumCasilla)
            ?.notaSum ||
            ""
        )}
      </td>
    );
  } else {
                                    
                    /* SECCIÓN: Notas Sumativas */

                    return (
                      <td
                        key={calificacion.confSumCasilla}
                        id={`celda-${estudiante.estudiante.estudianteId}-${calificacion.confSumCasilla}`}
                        className="text-center"
                        style={{
                          ...estilos.tdHeatmap(
                            isHeatmapActive,
                            estudiante.notas.find(
                              (n) =>
                                n.notaSumCasilla === calificacion.confSumCasilla
                            )?.notaSumConcepto ||
                              estudiante.notas.find(
                                (n) =>
                                  n.notaSumCasilla ===
                                  calificacion.confSumCasilla
                              )?.notaSum ||
                              "",
                            false,
                            hoveredRow === rowIndex,
                            hoveredColumn === colIndex
                          ),
                        }}
                        onMouseEnter={() =>
                          !isHeatmapActive && setHoveredColumn(colIndex)
                        }
                        onMouseLeave={() =>
                          !isHeatmapActive && setHoveredColumn(null)
                        }
                        onClick={() => {
                          if (hasPermission) {
                            if (
                              !mostrarAcumulativas &&
                              data.confNotasSum.some(
                                (nota) =>
                                  nota.confSumCasilla ===
                                    calificacion.confSumCasilla &&
                                  nota.confSumAcumulativa &&
                                  nota.confNotaAcu &&
                                  nota.confNotaAcu.length > 0
                              )
                            ) {
                              if (!blockInputs) {
                                // Si la tabla está desbloqueada, expandir las acumulativas
                                manejarClickEncabezado(
                                  calificacion.confSumCasilla
                                );
                              } else {
                                // Si la tabla está bloqueada y tiene permisos, mostrar el modal
                                setModalMensaje(
                                  "Para cambiar esta calificación, debe modificar las notas acumulativas que la componen."
                                );
                                setMostrarModalAviso(true);
                              }
                            } else {
                              let notaActual =
                                estudiante.notas.find(
                                  (n) =>
                                    n.notaSumCasilla ===
                                    calificacion.confSumCasilla
                                )?.notaSumConcepto ||
                                estudiante.notas.find(
                                  (n) =>
                                    n.notaSumCasilla ===
                                    calificacion.confSumCasilla
                                )?.notaSum ||
                                "";
                              if (
                                notaActual === "P" ||
                                notaActual === "p" ||
                                notaActual === -1 ||
                                notaActual === "0" ||
                                notaActual === 0
                              ) {
                                notaActual = "";
                              }
                              setCeldaEdicionConFormato(
                                estudiante.estudiante.estudianteId,
                                calificacion.confSumCasilla,
                                notaActual
                              );
                            }
                          }
                        }}
                      >
                        {/* {console.log(
                        `Nota sumativa para el estudiante con ID: ${estudiante.estudiante.estudianteId}, Casilla: ${calificacion.confSumCasilla}, Valor: `,
                        estudiante.notas.find(
                            (n) => n.notaSumCasilla === calificacion.confSumCasilla
                        )?.notaSum
                        )} */}
                        {celdaEdicion.id ===
                          estudiante.estudiante.estudianteId &&
                          celdaEdicion.field === calificacion.confSumCasilla ? (
                          <input
                            id={`input-${estudiante.estudiante.estudianteId}-${calificacion.confSumCasilla}`}
                            type="text"
                            value={String(notaTemporal).replace(".", ",")}
                            onChange={(e) => setNotaTemporal(e.target.value)}
                            onBlur={() =>
                              manejarGuardarNota(
                                estudiante.estudiante.estudianteId,
                                calificacion.confSumCasilla
                              )
                            }
                            // onKeyDown={(e) =>
                            //   manejarKeyDown(
                            //     e,
                            //     estudiante.estudiante.estudianteId,
                            //     calificacion.confSumCasilla
                            //   )
                            // }
                            onKeyDown={(e) =>
                              manejarKeyPress(
                                e,
                                    estudiante.estudiante.estudianteId,
                                    calificacion.confSumCasilla
                              )
                            }
                            onFocus={(e) => {
                              if (!inputsBloqueados) {
                                ultimoInputEnfocado.current = e.target;
                              }
                            }}
                            autoFocus
                            autoComplete="off"
                            readOnly={
                              blockInputs ||
                              !hasPermission ||
                              calificacion.confSumAcumulativa
                            }
                            style={
                              blockInputs ||
                              !hasPermission ||
                              calificacion.confSumAcumulativa
                                ? estilos.inputInactive // Estilo inactivo cuando no es editable
                                : estilos.inputActive // Estilo activo cuando es editable
                            }
                            onClick={() => {
                              if (blockInputs && hasPermission) {
                                setModalMensaje(
                                  "Para ingresar calificaciones, presiona el botón 'Desbloquear' y luego ingresa tu código de Doble Autenticación."
                                );
                                setMostrarModalAviso(true);
                              }
                            }}
                          />
                        ) : deberiaMostrarInputInactivo(
                            estudiante.notas.find(
                              (n) =>
                                n.notaSumCasilla === calificacion.confSumCasilla
                            )?.notaSum
                          ) ? (
                          <input
                            type="text"
                            readOnly
                            value=""
                            style={estilos.inputInactive}
                            onClick={() => {
                              if (blockInputs && hasPermission) {
                                setModalMensaje(
                                  "Para ingresar calificaciones, presiona el botón 'Desbloquear' y luego ingresa tu código de Doble Autenticación."
                                );
                                setMostrarModalAviso(true);
                              } else if (hasPermission) {
                                setCeldaEdicionConFormato(
                                  estudiante.estudiante.estudianteId,
                                  calificacion.confSumCasilla,
                                  ""
                                );
                              }
                            }}
                          />
                        ) : (
                          <span
                            onClick={() => {
                              if (blockInputs && hasPermission) {
                                setModalMensaje(
                                  "Para ingresar calificaciones, presiona el botón 'Desbloquear' y luego ingresa tu código de Doble Autenticación."
                                );
                                setMostrarModalAviso(true);
                              } else if (hasPermission) {
                                setCeldaEdicionConFormato(
                                  estudiante.estudiante.estudianteId,
                                  calificacion.confSumCasilla,
                                  estudiante.notas.find(
                                    (n) =>
                                      n.notaSumCasilla ===
                                      calificacion.confSumCasilla
                                  )?.notaSum || ""
                                );
                              }
                            }}
                          >
                            {renderizarContenido(
                              estudiante.notas.find(
                                (n) =>
                                  n.notaSumCasilla ===
                                  calificacion.confSumCasilla
                              )?.notaSumConcepto ||
                                estudiante.notas.find(
                                  (n) =>
                                    n.notaSumCasilla ===
                                    calificacion.confSumCasilla
                                )?.notaSum ||
                                ""
                            )}
                          </span>
                        )}
                      </td>
                    );
                  }
                })}

                {/* SECCIÓN: Promedio Final */}
                <td
                  className="text-center"
                  style={{
                    ...estilos.tdHeatmap(
                      isHeatmapActive,
                      estudiante.notaFinal.notaFinalSum,
                      true,
                      hoveredRow === rowIndex,
                      false
                    ),
                  }}
                >
                  {/* Mostrar el concepto en lugar del valor numérico si existe */}
                  {estudiante.notaFinal.notaFinalSumConcepto
                    ? estudiante.notaFinal.notaFinalSumConcepto
                    : estudiante.notaFinal.notaFinalSum &&
                      estudiante.notaFinal.notaFinalSum !== "0,0" &&
                      estudiante.notaFinal.notaFinalSum !== 0
                    ? formatearValorPromedio(estudiante.notaFinal.notaFinalSum)
                    : ""}
                </td>
              </tr>
            ))}

            {/* SECCIÓN DEL PROMEDIO GENERAL */}
            <tr style={{...estilos.trSticky, zIndex:10}}>
              <td
                colSpan="2"
                className="text-center"
                style={{
                  ...estilos.resumenTd(isHeatmapActive, true),
                  ...estilos.boldText,
                  borderLeft: "1px solid white",
                  borderRight: "1px solid lightgrey",
                }}
              >
                Promedio
              </td>

              {/* Iterar sobre las columnas de notas y verificar si hay conceptos */}
              {data.confNotasSum.flatMap((calificacion, colIndex) => {
                if (
                  calificacion.confSumAcumulativa &&
                  (mostrarAcumulativas ||
                    columnasExpandidas[calificacion.confSumCasilla])
                ) {
                  return calificacion.confNotaAcu
                    .map((nota, acuIndex) => (
                      <td
                        key={`${calificacion.confSumCasilla}-${nota.confAcuCasilla}`}
                        className="text-center"
                        style={estilos.resumenTd(isHeatmapActive)}
                      ></td>
                    ))
                    .concat(
                      <td
                        key={calificacion.confSumCasilla}
                        className="text-center"
                        style={estilos.resumenTd(
                          isHeatmapActive,
                          promediosColumnas[calificacion.confSumCasilla]
                        )}
                      >
                        {/* Mostrar el concepto si existe, si no, mostrar el promedio */}
                        {promediosColumnas[calificacion.confSumCasilla]
                          ?.concepto
                          ? promediosColumnas[calificacion.confSumCasilla]
                              .concepto
                          : formatearPromedio(
                              promediosColumnas[calificacion.confSumCasilla]
                            )}
                      </td>
                    );
                } else {
                  return (
                    <td
                      key={calificacion.confSumCasilla}
                      className="text-center"
                      style={estilos.resumenTd(
                        isHeatmapActive,
                        promediosColumnas[calificacion.confSumCasilla]
                      )}
                    >
                      {/* Mostrar el concepto si existe, si no, mostrar el promedio */}
                      {promediosColumnas[calificacion.confSumCasilla]?.concepto
                        ? promediosColumnas[calificacion.confSumCasilla]
                            .concepto
                        : formatearPromedio(
                            promediosColumnas[calificacion.confSumCasilla]
                          )}
                    </td>
                  );
                }
              })}

              {/* Mostrar el promedio final del curso, o el concepto si aplica */}
              <td
                className="text-center"
                style={{
                  ...estilos.resumenAnualTd(isHeatmapActive),
                  ...estilos.boldText,
                  color: parseFloat(promedioCurso) < 4 ? "red" : "black", 
                  // Colorea en rojo si es menor que 4
                }}
                
              >
                {/* Mostrar el concepto si existe, si no, mostrar el promedio */}
                {/* {promedioGeneralCursoConcepto 
              ? promedioGeneralCursoConcepto 
              : formatearPromedio(promedioGeneralCurso)} */}
                {/* </td>
                 */}
                  {console.log("promedioCurso",promedioGneralCursoActualizado)}
                {formatearPromedio(promedioGneralCursoActualizado)}

                {/* {console.log("Este es el promedio del curso", promedioGneralCursoActualizado)} */}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <ModalNotas
        mostrarModal={mostrarModal}
        setMostrarModal={setMostrarModal}
        mostrarModalAviso={mostrarModalAviso}
        confirmarCambioNota={confirmarCambioNota}
        manejarOcultarModal={manejarOcultarModal}
        notaPendiente={notaPendiente}
        datosOrdenados={datosOrdenados}
        modalMensaje={modalMensaje}
        formatearNotaParaMensaje={formatearNotaParaMensaje}
        blockInputs={blockInputs}
        setMostrarModalAviso={setMostrarModalAviso}
        abrirModalAutenticacion={abrirModalAutenticacion}
        conceptosPermitidos={conceptosPermitidos}
      />

      <NotificacionToast
        mensaje={mensaje}
        mostrar={mostrarNotificacion}
        setMostrar={setMostrarNotificacion}
        estiloPersonalizado={estiloNotificacion} 
        
      />
    </div>
  );
};

export default TablaCalificaciones;
