import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Title from "../../../components/titles/Title";
import DynamicTable from "../../../components/tables/DynamicTable/Table";
import CustomTooltip from "../../../components/tooltip/Tooltip";
import {
  faEye,
  faFilter,
  faPenToSquare,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { formatearRUN } from "../../../../util/util";
import SelectMultiple from "../../../components/inputs/SelectMultiple";
import { useSelector, useDispatch } from "react-redux";
import {
  cargarListaCargos,
  cargarListafuncionarios,
  getFichaFuncionario,
} from "../../../../services/PostsService";
import { setCargos } from "../../../../store/actions/refDataActions";
import Avatar from "../../../components/avatar/Avatar";
import CustomSpinner from "../../../components/spinner/Spinner";
import Swal from "sweetalert2";
import useCheckRoles from "../../../../hooks/useCheckRoles";

function ListaFuncionarios() {
  const {isDirectivo, isDocente} = useCheckRoles();

  const [isLoading, setIsLoading] = useState(true);
  const isMobile = window.innerWidth < 768;
  const [showFilters, setShowfilters] = useState(false);
  const [selectedCargos, setSelectedCargos] = useState([]);
  const [listaCargos, setListaCargos] = useState([]);
  const [listaFuncionarios, setListaFuncionarios] = useState([]);



  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cargosState = useSelector((state) => state.refDataReducer.cargos);
  const funcionariosState = useSelector(
    (state) => state.funcionariosReducer.funcionarios
  );



  const headers = [
    {
      name: "Nro.",
      width: "80px",
      textAlign: "center",
      sortable: true,
      sortType: "number",
    },
    {
      name: "RUN",
      width: "150px",
      textAlign: "center",
      sortable: true,
      sortType: "number",
    },
    {
      name: "Nombres",
      width: "300px",
      textAlign: "center",
      sortable: true,
      sortType: "object",
    },
    {
      name: "Apellido Paterno",
      width: "200px",
      textAlign: "center",
      sortable: true,
      sortType: "string",
      sortByDefault: true,
    },
    {
      name: "Apellido Materno",
      width: "200px",
      textAlign: "center",
      sortable: true,
      sortType: "string",
      sortByDefault: true,
    },
    {
      name: "Cargo",
      width: "150px",
      textAlign: "center",
      sortable: false,
      sortType: "list",
    },
    {
      name: "Email",
      width: "400px",
      textAlign: "center",
      sortable: true,
      sortType: "string",
    },
    { name: "Teléfono", width: "150px", textAlign: "center", sortable: false },
    // { name: "Región", width: "auto", textAlign: "center", sortable: true, sortType: "string" },
    // { name: "Comuna", width: "auto", textAlign: "center", sortable: true, sortType: "string" },
    { name: "Acciones", width: "140px", textAlign: "center", sortable: false },
  ];

  const filteredData = listaFuncionarios
    .filter((funcionario) => {
      // Si hay cargos seleccionados, verifica si alguno de los cargos del funcionario está en la selección
      if (
        selectedCargos.length > 0 &&
        !selectedCargos.some((c) => funcionario.cargo.includes(c.id))
      ) {
        return false;
      }
      return true;
    })
    .map((funcionario, index) => {
      // console.log(funcionario);
      const mappedData = {
        id: index,
        value: [
          funcionario.personaId,
          formatearRUN(funcionario.run + "-" + funcionario.runDv),
          <div className="d-flex gap-3 justify-content-start align-items-center ml-4">
            <Avatar
              width={"30px"}
              height={"30px"}
              run={funcionario.run}
              allowPreview
            />
            {funcionario.nombres}
          </div>,
          funcionario.apellidoPaterno,
          funcionario.apellidoMaterno,
          // Ahora mostramos todos los cargos separados por coma
          funcionario.cargo
            .map(
              (id) =>
                listaCargos &&
                listaCargos.find((cargo) => cargo.id === id)?.rolNombre
            )
            .map((cargo, index) => (
              <ul key={index}>
                <li>{cargo}</li>
              </ul>
            )),
          funcionario.email,
          funcionario.telefono,
          // funcionario.region,
          // funcionario.comuna,
          <div
            style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}
          >
            <CustomTooltip tooltipMsg={isDirectivo ? "Editar" : "Ver"}>
              <Button
                variant={!isDirectivo ? "color-acento-outline" : "color-amarillo-outline"}
                onClick={() => handleGetFicha(funcionario)}
              >
                <FontAwesomeIcon icon={isDirectivo ? faPenToSquare : faEye} />
              </Button>
            </CustomTooltip>
            <CustomTooltip tooltipMsg={"Eliminar"}>
              <Button
                style={{ display: !isDirectivo ? "none" : "block" }}
                variant="color-eliminar-outline"
                onClick={() => handleDeleteFuncionario(funcionario)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </CustomTooltip>
          </div>,
        ],
      };
      return mappedData;
    });

  const resumeTable = [
    { label: "Funcionarios", value: filteredData.length },
    {
      label: "Directivos",
      value: listaFuncionarios.filter((f) => f.cargo.includes(1)).length,
    },
    {
      label: "Docentes",
      value: listaFuncionarios.filter((f) => f.cargo.includes(3)).length,
    },
    {
      label: "Administrativos",
      value: listaFuncionarios.filter((f) => f.cargo.includes(2)).length,
    },
  ];

  const handleGetFicha = useCallback(
    (funcionario) => {
      getFichaFuncionario(funcionario.personaId)
        .then((response) => {
          navigate("/ficha-funcionario", { state: { ficha: response } });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [navigate]
  );

  const handleDeleteFuncionario = useCallback((funcionario) => {
    console.log(funcionario);
    Swal.fire({
      title: "¿Estás seguro?",
      html: `Estás a punto de eliminar a <br /><strong>${formatearRUN(
        funcionario.run + "-" + funcionario.runDv
      )}<br /> ${funcionario.nombres} ${funcionario.apellidoPaterno} ${
        funcionario.apellidoMaterno
      }</strong>.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--color-eliminar)",
      cancelButtonColor: "var(--color-acento)",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("Eliminar");
      }
    });
  }, []);

  useEffect(() => {
    Promise.all([cargarListaCargos(), cargarListafuncionarios()])
      .then((responses) => {
        setIsLoading(true);
        if (cargosState.length === 0) {
          const filteredList = responses[0].filter((cargo) => {
            console.log(cargo);
            if (cargo.id === 3 || cargo.id === 4) return false;
            return true;
          });

          const listaCargos = filteredList.map((cargo) => ({
            id: cargo.id,
            rolNombre: cargo.rolNombre,
            rolOrden: cargo.rolOrden,
          }));

          dispatch(setCargos(listaCargos));
          setListaCargos(listaCargos);
        } else {
          setListaCargos(cargosState);
        }

        if (funcionariosState.length === 0) {
          setListaFuncionarios(responses[1]);
        } else {
          setListaFuncionarios(funcionariosState);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [cargosState, dispatch, funcionariosState, listaCargos]);

  // useEffect(() => {
  //   console.log("isDirectivo", isDirectivo);
  //   console.log("isDocente", isDocente);
  // },[isDirectivo, isDocente])

  return (
    <Container
      fluid
      className="bg-white p-3"
      style={{ height: "90dvh", overflow: "hidden" }}
    >
      <Row style={{ position: "relative", padding: ".6rem" }}>
        <Title text={"Lista Funcionarios"} />

        {!isMobile && isDirectivo && (
          <Button
            variant="color-acento"
            className="w-auto w-sm-100"
            onClick={() => navigate("/ficha-funcionario")}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              position: "absolute",
              right: 15,
              top: 10,
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Agregar
          </Button>
        )}

        <Button
          variant={!showFilters ? "color-acento-outline" : "color-acento"}
          className="w-auto w-sm-100"
          onClick={() => setShowfilters(!showFilters)}
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            position: "absolute",
            left: 15,
            top: 10,
          }}
        >
          <FontAwesomeIcon icon={faFilter} /> Filtros
        </Button>
      </Row>

      <Row className="px-2" style={{ display: showFilters ? "flex" : "none" }}>
        {isMobile && isDirectivo && (
          <Col xs={12} md={1} className="p-1">
            <Button
              variant="color-acento"
              className="w-auto w-sm-100"
              onClick={() => navigate("/ficha-funcionario")}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                position: "absolute",
                right: 15,
                top: 10,
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Agregar
            </Button>
          </Col>
        )}
        <Col xs={12} md={1} className="p-1">
          <SelectMultiple
            placeholder="Cargo"
            idKey="id"
            labelKey="name"
            elements={listaCargos.map((p) => ({
              id: p.id,
              name: p.rolNombre,
            }))}
            onSelect={(selected) => setSelectedCargos(selected)}
            selectedElements={selectedCargos}
            style={{
              border: "1px solid var(--color-acento)",
              color: "var(--color-texto-oscuro)",
            }}
            hideLabel
          />
        </Col>
      </Row>

      {filteredData.length === 0 && !isLoading ? (
        <div
          className="text-center d-flex justify-content-center align-items-center"
          style={{ height: "70dvh" }}
        >
          <p>No se encontraron resultados para los filtros aplicados.</p>
        </div>
      ) : (
        <>
          {isLoading ? (
            <div
              className="text-center d-flex justify-content-center align-items-center"
              style={{ height: "70dvh" }}
            >
              <CustomSpinner />
            </div>
          ) : (
            <DynamicTable
              data={filteredData}
              headers={headers}
              options={{
                pageSize: 50,
                // resumeTable: resumeTable,
                searchInput: true,
                searchInputPlaceholder: "Buscar...",
                showItemsPerPageSelector: true,
                showPagination: true,
                copyClipboard: true,
                stickyHeaders: true,
                stickyOffset: "0px",
              }}
            />
          )}
        </>
      )}
    </Container>
  );
}

export default ListaFuncionarios;
