import React from 'react';
import { Col, Row } from 'react-bootstrap';
import useGetListadoCursos from '../../../hooks/useGetListadoCursos';
import ButtonFlechaAdelante from "../buttonpersonalizado/ButtonFlechaAdelante";
import ButtonFlechaAtras from "../buttonpersonalizado/ButtonFlechaAtras";
import ButtonMultifuncional from '../buttonpersonalizado/ButtonMultifuncional';
import DesplegableBusquedaMultiple from '../desplegableConBusqueda/DesplegableBusquedaMultiple';

const NavAsistenciaMensualSubvencionCursos = ({ fecha, onPrev, onNext, onCursoSelect, cursosSeleccionados, mes, setMensajeToast, setMostrarToast }) => {
  const { cursos, loading, error } = useGetListadoCursos();

  const formatDate = (date) => {
    const options = { month: 'long' };
    const formattedDate = new Date(date).toLocaleDateString('es-ES', options);
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1, 3).toLowerCase(); // Formato de tres letras y primera letra en mayúscula
  };

  const estilos = {
    rowContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
      backgroundColor: '#fff',
    },
    fecha: {
      width: '50px', // Mantener un tamaño constante
      textAlign: 'center',
      fontSize: '1.1rem',
      color: '#1c2833',
      fontFamily: 'poppins, sans-serif',
      fontWeight: 500,
    },
    titulo: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '34px',
      color: 'rgb(28, 40, 51)',
      marginBottom: 0,
    },
  };

  if (loading) {
    return <div>Cargando cursos...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Row style={estilos.rowContainer} className='m-0 p-0'>
        <Col xs="" className="d-flex align-items-center justify-content-start p-0">
          <DesplegableBusquedaMultiple
            elementos={cursos}
            placeholder="Curso"
            selectedElements={cursosSeleccionados} // Mostrar cursos seleccionados
            onSelect={onCursoSelect}
          />
        </Col>
        <Col xs="auto" style={estilos.colCentrado}>
          <h5 style={estilos.titulo}>Asistencia Subvención Mensual Curso </h5>
        </Col>
        <Col xs="" className="d-flex align-items-center justify-content-end p-0">
          <ButtonFlechaAtras onClick={onPrev} />
          <span className="h4 mx-2 mb-0" style={estilos.fecha}>
            {formatDate(fecha)}
          </span>
          <ButtonFlechaAdelante onClick={onNext} />
          <ButtonMultifuncional vista="asistencia-mensual-subvenciones-cursos" mes={mes} setMensajeToast={setMensajeToast} setMostrarToast={setMostrarToast} />
        </Col>
      </Row>
    </>
  );
};

export default NavAsistenciaMensualSubvencionCursos;