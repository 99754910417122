import { useEffect, useState } from 'react';
import { getAsistenciaClaseEstudiante } from '../services/PostsService';

const parseEstado = (estadoId) => {
  switch (estadoId) {
    case 1:
      return "presente";
    case 2:
      return "ausente";
    case 3:
      return "atrasado";
    case 4:
      return "retirado";
    case 5:
      return "fueraDeAula";  
    default:
      return "";
  }
};

const useGetAsistenciaClaseEstudiante = (claseId, estudianteId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAsistencia = async () => {
      try {
        const response = await getAsistenciaClaseEstudiante({ clase_id: claseId, estudiante_id: estudianteId });
        const asistenciaData = response.data;
        asistenciaData.asistenciaEstadoClase = parseEstado(asistenciaData.asistencia.asistenciaEstadoClaseId);
        setData(asistenciaData);
        // console.log('Datos de asistencia obtenidos:', asistenciaData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (claseId && estudianteId) {
      fetchAsistencia();
    }
  }, [claseId, estudianteId]);

  return { data, loading, error };
};

export default useGetAsistenciaClaseEstudiante;
