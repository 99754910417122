import { useEffect, useState } from 'react';
import { getSubvencionAnualCursos } from '../services/PostsService';

const useGetSubvencionAnualCursos = () => {
    const [cursos, setCursos] = useState([]);
    const [meses, setMeses] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDatos = async () => {
            setCargando(true);
            setError(null);

            try {
                const response = await getSubvencionAnualCursos();
                const data = response.data;

                if (!data || !data.asistenciaColegio || !data.asistenciaCurso) {
                    throw new Error('Estructura de datos inválida');
                }

                // Obtener meses desde asistenciaColegio
                const mesesDisponibles = data.asistenciaColegio[0].asistenciaColegio.map(asistencia => asistencia.mesNombre).filter(mes => mes !== null);
                setMeses(mesesDisponibles);

                // Formatear datos de cursos
                const cursosFormateados = data.asistenciaCurso.map(curso => {
                    const asistencias = curso.asistenciaCurso.reduce((acc, asistencia) => {
                        acc[asistencia.mesNombre.toLowerCase()] = asistencia.asistenciaPorcentaje;
                        return acc;
                    }, {});
                    asistencias.resumenAnual = curso.asistenciaPorcentajeCursoAnual;
                    return {
                        id: curso.curso.cursoId,
                        nombre: curso.curso.cursoNombre,
                        asistencias
                    };
                });

                setCursos(cursosFormateados);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
                setError(error.response ? error.response.data.message : error.message);
            } finally {
                setCargando(false);
            }
        };

        fetchDatos();
    }, []);

    return { cursos, meses, cargando, error };
};

export default useGetSubvencionAnualCursos;
