import axiosInstance from '../services/AxiosInstance';

export function uploadProcesoMatricula(formData) {
    return axiosInstance.post(`matricula/uploadProceso`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
}
export function saveProcesoMatricula(id) {
    return axiosInstance.post(`matricula/saveProceso`, {id} );
}

export function uploadAsignacionMatricula(formData) {
    return axiosInstance.post(`matricula/uploadAsignacion`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
}
export function saveAsignacionMatricula(estudiantes) {
    return axiosInstance.post(`matricula/saveAsignacion`, {estudiantes} );
}

export function getPeriodosByColegio() {
    return axiosInstance.get(`matricula/getPeriodosByColegio` );
}

export function getListaMatricula(params) {
    return axiosInstance.post(`matricula/getListaMatricula`, params );
}

export function getListadoEstudiante() {
    return axiosInstance.get(`matricula/getListadoEstudiantes`);
}


export function getListadoEnsenanzas() {
    return axiosInstance.get(`curriculum/getCurriculumEnsenanzas`);
} 


export function getNextNumMatricula() {
    return axiosInstance.get(`estudiante/nextNumMatricula`);
}