import { useState } from 'react';
import { saveConfNotas } from '../services/PostsService'; // Asegúrate de importar el servicio correctamente

const useSaveConfNotas = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const saveNotas = async (data) => {
        setLoading(true);
        setError(null); // Resetea cualquier error previo

        try {
            const response = await saveConfNotas(data);
            setLoading(false);
            return response;
        } catch (err) {
            setLoading(false);
            setError(err);
            throw err; // Rethrow error for further handling if needed
        }
    };

    return { saveNotas, loading, error };
};

export default useSaveConfNotas;
