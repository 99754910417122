import { useCallback, useEffect, useState } from 'react';
import { getListadoAsignaturas } from '../services/PostsService';

const useGetListadoAsignaturas = (cursoId) => {
    const [asignaturas, setAsignaturas] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [error, setError] = useState(null);

    const fetchAsignaturas = useCallback(async () => {
        if (!cursoId) return;

        setCargando(true);
        setError(null);

        try {
            const response = await getListadoAsignaturas({ curso_id: cursoId });

            if (response.data && Array.isArray(response.data)) {
                const asignaturasFormateadas = response.data.map(asign => ({
                    id: asign.asignaturaId,
                    nombre: asign.asignaturaNombre,
                    colorFondo: asign.asignaturaColorFondo,
                    codigoMineduc: asign.asignaturaCodigoMineduc,
                    docenteId: asign.docenteId,
                    docenteNombre: asign.docenteNombre
                }));
                setAsignaturas(asignaturasFormateadas);
            } else {
                throw new Error('Datos de respuesta no válidos');
            }
        } catch (error) {
            console.error('Error al cargar las asignaturas:', error);
            setError(error.toString());
        } finally {
            setCargando(false);
        }
    }, [cursoId]);

    useEffect(() => {
        fetchAsignaturas();
    }, [cursoId, fetchAsignaturas]);

    return { asignaturas, cargando, error, refetch: fetchAsignaturas };
};

export default useGetListadoAsignaturas;
