import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import useGetAsistenciaClaseEstudiante from '../../../hooks/useGetAsistenciaClaseEstudiante';
import estilosEstado from '../../objetoEstilos/estiloEstados';
import ButtonPersonalizado from "../buttonpersonalizado/ButtonPersonalizado";
import EstadoClaseIcono from '../guiaestados/EstadoClaseIcon';

const ModalCambioEstado = ({
  show,
  handleClose,
  estudianteNomPri,
  estudianteApePri,
  numLista, 
  fecha,
  bloque,
  estadoActual,
  handleSave,
  estudianteId,
  claseId,
  asistenciaId
}) => {
  const { data, loading } = useGetAsistenciaClaseEstudiante(claseId, estudianteId);
  const [nuevoEstado, setNuevoEstado] = useState(estadoActual);
  const [listoParaMostrar, setListoParaMostrar] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (data && !loading) {
      setNuevoEstado(data.asistenciaEstadoClase);
      setListoParaMostrar(true);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!show) {
      setListoParaMostrar(false);
    } else if (modalRef.current) {
      modalRef.current.focus();
    }
  }, [show]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const estados = Object.keys(estilosEstado).filter(tipoEstado => tipoEstado !== 'pendiente');
      const indexActual = estados.indexOf(nuevoEstado);
      let nuevoIndex = indexActual;

      if (event.key === 'Enter' || event.key === ' ') {
        handleGuardar();
      } else if (event.key === 'ArrowLeft' || event.key === 'a' || event.key === 'A') {
        nuevoIndex = (indexActual - 1 + estados.length) % estados.length;
      } else if (event.key === 'ArrowRight' || event.key === 'd' || event.key === 'D') {
        nuevoIndex = (indexActual + 1) % estados.length;
      }

      setNuevoEstado(estados[nuevoIndex]);
    };

    if (show) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show, nuevoEstado]);

  const handleChange = (estado) => {
    setNuevoEstado(estado);
  };

  const handleGuardar = () => {
    console.log('Guardando nuevo estado:', { nuevoEstado, estudianteId, claseId, asistenciaId });
    handleSave(nuevoEstado, estudianteId, claseId, asistenciaId);
    handleClose();
};

  const estadoActualEstilo = estilosEstado[estadoActual] || { icon: 'bi bi-question-circle', iconColor: 'grey', description: 'Sin registro' };

  const estiloTitulo = {
    color: "var(--color-texto-oscuro)",
    fontSize: "1rem",
    fontWeight: "500",
    margin: 0,
    padding: 0
  };

  const estiloTexto = {
    color: "var(--color-texto-oscuro)",
    fontSize: "1rem",
    fontWeight: "normal",
    margin: 0,
    padding: 0
  };

  const formatearFecha = (fecha) => {
    const date = new Date(fecha);
    const dia = date.getUTCDate().toString().padStart(2, '0');
    const mes = (date.getMonth() + 1).toString().padStart(2, '0');
    const año = date.getFullYear();
    return `${dia}-${mes}-${año}`;
  };

  const customModalStyle = {
    maxWidth: '700px', 
    margin: 'auto'
  };

  return (
    <Modal show={show && listoParaMostrar} onHide={handleClose} centered>
      <div style={customModalStyle} ref={modalRef} tabIndex="0">
        <Modal.Header closeButton>
          <Modal.Title className='w-100 text-center'>Cambiar Estado de Asistencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                <p style={estiloTitulo}>Estado Actual:</p>
                <p style={{ ...estiloTexto, marginLeft: '5px' }}>
                  <i className={`${estadoActualEstilo.icon}`} style={{ color: estadoActualEstilo.iconColor, fontSize: "20px", verticalAlign: "middle" }}></i>
                  {estadoActualEstilo.description === 'Sin registro' ? ' Pendiente ' : ''}
                </p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                <p style={estiloTitulo}>Fecha:</p>
                <p style={{ ...estiloTexto, marginLeft: '5px' }}>{formatearFecha(fecha)}</p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                <EstadoClaseIcono estadoClaseId={data?.asistencia.claseEstadoId}/>
                <p style={estiloTitulo}>Bloque:</p>
                <p style={{ ...estiloTexto, marginLeft: '5px', marginRight: '5px' }}>{bloque}</p>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <p style={estiloTitulo}>Estudiante:</p>
              <p style={{ ...estiloTexto, marginLeft: '5px' }}>
                <OverlayTrigger placement="top" overlay={<Tooltip>Número de lista</Tooltip>}>
                  <span>N° {`${numLista}`}.- </span>
                </OverlayTrigger> {`${estudianteNomPri} `}
              </p>
            </div>
          </div>
          <Row className="justify-content-around">
            {Object.keys(estilosEstado).filter(tipoEstado => tipoEstado !== 'pendiente').map((tipoEstado) => (
              <Col key={tipoEstado} className="d-flex flex-column align-items-center" style={{ paddingRight: tipoEstado === 'fuera_de_sala' ? '20px' : '0px' }}>
                <div
                  className="status-square d-flex justify-content-center align-items-center"
                  style={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: 'transparent',
                    border: `2px solid ${nuevoEstado === tipoEstado ? estilosEstado[tipoEstado].iconColor : 'lightgrey'}`,
                    cursor: "pointer",
                    margin: "5px auto",
                  }}
                  onClick={() => handleChange(tipoEstado)}
                >
                  {nuevoEstado === tipoEstado && <i className={`${estilosEstado[tipoEstado].icon}`} style={{ color: estilosEstado[tipoEstado].iconColor, fontSize: "20px" }}></i>}
                </div>
                <p style={{ ...estiloTexto, marginTop: "5px", textAlign: "center", whiteSpace: 'nowrap' }}>{estilosEstado[tipoEstado].description}</p>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer className='px-0'>
          <ButtonPersonalizado variant="color-acento" onClick={handleGuardar}>
            Firmar
          </ButtonPersonalizado>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ModalCambioEstado;
