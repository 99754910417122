import {
  SET_REGIONES,
  SET_COMUNAS,
  SET_NACIONALIDADES,
  SET_ETNIAS,
  SET_GENEROS,
  SET_NIVELES_ENSENANZA,
  SET_SECTORES,
  SET_IDENTIDADES_GENERO,
  SET_GRADOS,
  SET_CARGOS,
  SET_ANIOS,
  SET_VINCULOS_PARENTESCOS,
  SET_LOCAL_ESCOLAR,
  SET_NIVELES_ACADEMICOS,
  SET_ASIGNATURAS,
  SET_CURSOS,
  SET_FUNCIONES_DOCENTE,
  SET_ASIGNATURAS_MINEDUC,
  SET_DICTIONARY_COLORS,
} from "../actions/refDataActions";

const initialState = {
  regiones: [],
  comunas: [],
  nacionalidades: [],
  etnias: [],
  generos: [],
  nivelesEnsenanza: [],
  sectores: [],
  identidadesGenero: [],
  grados: [],
  cargos: [],
  anios: [],
  vinculosParentescos: [],
  localEscolar: [],
  nivelesAcademicos: [],
  asignaturas: [],	
  cursos: [],
  funcionesDocente: [],
  asignaturasMineduc: [],
  diccionarioColoresAsignaturas: {},
};
export function refDataReducer(state = initialState, action) {
  if (action.type === SET_REGIONES) {
    return {
      ...state,
      regiones: action.payload,
    };
  }

  if (action.type === SET_COMUNAS) {
    return {
      ...state,
      comunas: action.payload,
    };
  }

  if (action.type === SET_NACIONALIDADES) {
    return {
      ...state,
      nacionalidades: action.payload,
    };
  }

  if (action.type === SET_ETNIAS) {
    return {
      ...state,
      etnias: action.payload,
    };
  }

  if (action.type === SET_GENEROS) {
    return {
      ...state,
      generos: action.payload,
    };
  }

  if (action.type === SET_NIVELES_ENSENANZA) {
    return {
      ...state,
      nivelesEnsenanza: action.payload,
    };
  }

  if (action.type === SET_SECTORES) {
    return {
      ...state,
      sectores: action.payload,
    };
  }

  if (action.type === SET_GRADOS) {
    return {
      ...state,
      grados: action.payload,
    };
  }

  if (action.type === SET_CARGOS) {
    return {
      ...state,
      cargos: action.payload,
    };
  }

    if(action.type === SET_IDENTIDADES_GENERO){
        return {
            ...state,
            identidadesGenero: action.payload,
        };
    }

    if(action.type === SET_ANIOS){
        return {
            ...state,
            anios: action.payload,
        };
    }

    if(action.type === SET_VINCULOS_PARENTESCOS){
        return {
            ...state,
            vinculosParentescos: action.payload,
        };
    }

    if(action.type === SET_LOCAL_ESCOLAR){
        return {
            ...state,
            localEscolar: action.payload,
        };
    }

    if(action.type === SET_NIVELES_ACADEMICOS){
        return {
            ...state,
            nivelesAcademicos: action.payload,
        };
    }

    if(action.type === SET_ASIGNATURAS){
        return {
            ...state,
            asignaturas: action.payload,
        };
    }

    if(action.type === SET_CURSOS){
        return {
            ...state,
            cursos: action.payload,
        };
    }

    if(action.type === SET_FUNCIONES_DOCENTE){
        return {
            ...state,
            funcionesDocente: action.payload,
        };
    }

    if(action.type === SET_ASIGNATURAS_MINEDUC){
        return {
            ...state,
            asignaturasMineduc: action.payload,
        };
    } 

    if(action.type === SET_DICTIONARY_COLORS){
        return {
            ...state,
            diccionarioColoresAsignaturas: action.payload,
        };
    }
    
    return state;
}
