export const SET_REGIONES = 'SET_REGIONES';
export const SET_COMUNAS = 'SET_COMUNAS';
export const SET_NACIONALIDADES = 'SET_NACIONALIDADES';
export const SET_ETNIAS = 'SET_ETNIAS';
export const SET_GENEROS = 'SET_GENEROS';
export const SET_NIVELES_ENSENANZA = 'SET_NIVELES_ENSENANZA';
export const SET_SECTORES = 'SET_SECTORES';
export const SET_GRADOS = 'SET_GRADOS'; 
export const SET_CARGOS = 'SET_CARGOS';
export const SET_IDENTIDADES_GENERO = 'SET_IDENTIDADES_GENERO';
export const SET_ANIOS = 'SET_ANIOS';
export const SET_VINCULOS_PARENTESCOS = 'SET_VINCULOS_PARENTESCOS';
export const SET_LOCAL_ESCOLAR = 'SET_LOCAL_ESCOLAR';
export const SET_NIVELES_ACADEMICOS = 'SET_NIVELES_ACADEMICOS';
export const SET_ASIGNATURAS = 'SET_ASIGNATURAS';
export const SET_CURSOS = 'SET_CURSOS';
export const SET_FUNCIONES_DOCENTE = 'SET_FUNCIONES_DOCENTE';
export const SET_ASIGNATURAS_MINEDUC = 'SET_ASIGNATURAS_MINEDUC';
export const SET_DICTIONARY_COLORS = 'SET_DICTIONARY_COLORS';

export const setRegiones = (regiones) => {
    return {
        type: 'SET_REGIONES',
        payload: regiones,
    };
};

export const setComunas = (comunas) => {
    return {
        type: 'SET_COMUNAS',
        payload: comunas,
    };
};

export const setNacionalidades = (nacionalidades) => {
    return {
        type: 'SET_NACIONALIDADES',
        payload: nacionalidades,
    };
};

export const setEtnias = (etnias) => {
    return {
        type: 'SET_ETNIAS',
        payload: etnias,
    };
};

export const setGeneros = (generos) => {
    return {
        type: 'SET_GENEROS',
        payload: generos,
    };
}

export const setNivelesEnsenanza = (nivelesEnsenanza) => {
    return {
        type: 'SET_NIVELES_ENSENANZA',
        payload: nivelesEnsenanza,
    };
}

export const setSectores = (sectores) => {
    return {
        type: 'SET_SECTORES',
        payload: sectores,
    };
}

export const setGrados = (grados) => {
    return {
        type: 'SET_GRADOS',
        payload: grados,
    };
}

export const setCargos = (cargos) => {
    return {
        type: 'SET_CARGOS',
        payload: cargos,
    };
}

export const setIdentidadesGenero = (identidadesGenero) => {
    return {
        type: 'SET_IDENTIDADES_GENERO',
        payload: identidadesGenero,
    };
}

export const setAnios = (anios) => {
    return {
        type: 'SET_ANIOS',
        payload: anios,
    };
};

export const setVinculosParentescos = (vinculosParentescos) => {
    return {
        type: 'SET_VINCULOS_PARENTESCOS',
        payload: vinculosParentescos,
    };
}

export const setLocalEscolar = (localEscolar) => {
    return {
        type: 'SET_LOCAL_ESCOLAR',
        payload: localEscolar,
    };
}

export const setNivelesAcademicos = (nivelesAcademicos) => {
    return {
        type: 'SET_NIVELES_ACADEMICOS',
        payload: nivelesAcademicos,
    };
}

export const setAsignaturas = (asignaturas) => {
    return {
        type: 'SET_ASIGNATURAS',
        payload: asignaturas,
    };
}

export const setCursos = (cursos) => {
    return {
        type: 'SET_CURSOS',
        payload: cursos,
    };
}

export const setFuncionesDocente = (funcionesDocente) => {
    return {
        type: 'SET_FUNCIONES_DOCENTE',
        payload: funcionesDocente,
    };
};

export const setAsignaturasMineduc = (asignaturasMineduc) => {
    return {
        type: 'SET_ASIGNATURAS_MINEDUC',
        payload: asignaturasMineduc,
    };
};

export const setDictionaryColors = (dictionaryColors) => { 
    return {
        type: 'SET_DICTIONARY_COLORS',
        payload: dictionaryColors,
    };
}