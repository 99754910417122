import React from "react";
import Img from "../../../assets/images/logo-blue-transparent.png";

const Logo = () => {
  return (
    <>
      <img src={Img} width={100} alt="Logo Cambar" />
      <svg
        className="brand-title1"
        xmlns="http://www.w3.org/2000/svg"
        width="180"
        height="100"
      >
        <text
          x="20"
          y="70"
          fontFamily="Poppins, sans-serif"
          fontWeight="700"
          fontSize="70px"
          fill="var(--color-acento)"
          lineheight="27px"
        >
          LCD
        </text>
        <text
          x="33"
          y="90"
          fontFamily="Poppins, sans-serif"
          fontWeight="400"
          fontSize="20px"
          fill="var(--color-acento)"
          lineheight="18px"
        >
          Cambar
        </text>
      </svg>
    </>
  );
};

export default Logo;
