import React from "react";
import { Form } from "react-bootstrap";
import CustomTooltip from "../tooltip/Tooltip";

function CheckboxInput({
  label,
  name,
  checked,
  onChange,
  required,
  disabled,
  tooltipMessage,
  controlId,
  style,
  defaultChecked,
}) {
  return (
    <Form.Group controlId={controlId} className="d-flex align-items-center">
      {required ? (
        <CustomTooltip
          tooltipMsg={
            tooltipMessage ? (
              <span>{tooltipMessage}</span>
            ) : (
              <span>Dato Requerido</span>
            )
          }
        >
          <div className="d-flex align-items-center gap-2">
            <span
              className="text-center"
              style={{ color: "var(--color-rojo)" }}
            >
              *
            </span>
            <Form.Check
              type="checkbox"
              name={name}
              checked={checked}
              onChange={onChange}
              required={required}
              disabled={disabled}
              label={label}
              id={`${controlId}-${label}`}
              style={style}
              className="me-2"
              defaultChecked={defaultChecked}
            />
          </div>
        </CustomTooltip>
      ) : (
        <Form.Check
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          label={label}
          className="me-2"
          id={`${controlId}-${label}`}
          style={style}
          defaultChecked={defaultChecked}
        />
      )}
    </Form.Group>
  );
}

export default CheckboxInput;
