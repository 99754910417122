import es from "date-fns/locale/es";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaInfoCircle } from "react-icons/fa";
import useSaveConfNotas from '../../../hooks/useSaveConfNotas';
import ButtonPersonalizado from '../buttonpersonalizado/ButtonPersonalizado';

// Registrar el locale en español
registerLocale('es', es);

const ConfigTablaNotas = ({ mostrar, cerrar, confNotasSum = [], confGlobal = {} }) => {
  const [cantidadNotas, setCantidadNotas] = useState(3);
  const [notas, setNotas] = useState([]);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false); 
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false); 
  const [notaParaEliminar, setNotaParaEliminar] = useState(null); 
  const { saveNotas, loading, error } = useSaveConfNotas(); 
  const [cantidadInicialNotas, setCantidadInicialNotas] = useState(0);
  const [estadoPonderadoOriginal, setEstadoPonderadoOriginal] = useState([]);
  const [errorCantidadNotas, setErrorCantidadNotas] = useState(null); 


  const actualizarCantidadNotas = (e) => {
    let nuevaCantidad = e.target.value === '' ? '' : parseInt(e.target.value, 10);
  
    if (nuevaCantidad > 20) {
      nuevaCantidad = 20; // Fuerza el valor a 20 si es mayor
      setErrorCantidadNotas('No se pueden agregar más de 20 notas principales.');
      
      // Limpia el error después de 5 segundos (5000 ms)
      setTimeout(() => {
        setErrorCantidadNotas('');
      }, 3000);
      
    } else {
      setErrorCantidadNotas(''); // Limpia el mensaje de error si es válido
    }
  
    setCantidadNotas(nuevaCantidad);
  
    if (nuevaCantidad === 0) {
      setNotas([]);
    } else if (nuevaCantidad < estadoPonderadoOriginal.length) {
      const nuevasNotas = estadoPonderadoOriginal.slice(0, nuevaCantidad);
      setNotas(nuevasNotas);
    } else {
      const nuevasNotas = Array.from({ length: nuevaCantidad - estadoPonderadoOriginal.length }, (_, i) => ({
        id: estadoPonderadoOriginal.length + i + 1,
        titulo: '',
        fecha: null,
        tipo: 'sumativa',
        ponderado: estadoPonderadoOriginal.some(nota => nota.ponderado && nota.tipo === 'sumativa'),
        valor: estadoPonderadoOriginal.some(nota => nota.ponderado && nota.tipo === 'sumativa') ? 0 : '',
        subNotas: [],
        errorPonderado: null,
      }));
  
      const todasLasNotas = [...estadoPonderadoOriginal, ...nuevasNotas];
      setNotas(todasLasNotas);
    }
  };
  


  

  // const actualizarNota = (id, campo, valor) => {
  //   setNotas(notas.map(nota => {
  //     if (nota.id === id) {
  //       if (campo === 'valor') {
  //         if (valor === '' || valor <= 100) {
  //           return { ...nota, [campo]: valor, errorPonderado: null };
  //         } else {
  //           return { ...nota, [campo]: '', errorPonderado: 'El valor ponderado no puede exceder el 100%' };
  //         }
  //       }
  //       return { ...nota, [campo]: valor };
  //     }
  //     return nota;
  //   }));
  // };

  const handleTipoChange = (id, tipo) => {
    if (tipo === 'formativa') {
      setAlertMessage(`La funcionalidad para notas de tipo "${tipo}" está en proceso.`);
      setShowAlertModal(true);
    }
    setNotas(notas.map(nota => {
      if (nota.id === id) {
        const nuevaNota = { ...nota, tipo, ponderado: tipo !== 'formativa' && nota.ponderado };
        if (tipo === 'acumulativa') {
          nuevaNota.subNotas = nuevaNota.subNotas.length ? nuevaNota.subNotas : [{ titulo: '', fecha: null, ponderado: false, valor: '', errorPonderado: null }];
        } else {
          nuevaNota.subNotas = [];
        }
        return nuevaNota;
      }
      return nota;
    }));
  };

  // const agregarSubNota = (id) => {
  //   setNotas(notas.map(nota => (
  //     nota.id === id ? { ...nota, subNotas: [...nota.subNotas, { titulo: '', fecha: null, ponderado: false, valor: '', errorPonderado: null }] } : nota
  //   )));
  // };

  // const eliminarSubNota = (id, indice) => {
  //   setNotas(notas.map(nota => (
  //     nota.id === id ? { ...nota, subNotas: nota.subNotas.filter((_, i) => i !== indice) } : nota
  //   )));
  // };

  // const solicitarEliminarNota = (id) => {
  //   setNotaParaEliminar(id);
  //   setShowDeleteConfirmModal(true);
  // };

  const confirmarEliminarNota = () => {
    if (notaParaEliminar !== null) {
      setNotas(notas.filter(nota => nota.id !== notaParaEliminar));
      setCantidadNotas(prev => prev - 1);
      setShowDeleteConfirmModal(false);
      setNotaParaEliminar(null);
    }
  };

  const actualizarCantidadSubNotas = (id, e) => {
    const nuevaCantidad = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
  
    setNotas(notas.map(nota => {
      if (nota.id === id) {
        let nuevasSubNotas = [];
  
        for (let i = 0; i < nuevaCantidad && i < 10; i++) {
          nuevasSubNotas[i] = nota.subNotas[i] || {
            titulo: '',
            fecha: null,
            ponderado: nota.subNotas.length > 0 && nota.subNotas[0].ponderado, // Mantén el estado de ponderado de las subnotas ya existentes
            valor: nota.subNotas.length > 0 && nota.subNotas[0].ponderado ? '0' : '', // Inicia el valor si está ponderado
            errorPonderado: null
          };
        }
  
        const errorSubNotas = nuevaCantidad > 10 ? 'No se pueden agregar más de 10 notas acumulativas por cada nota sumativa asociada.' : null;
  
        // Si hay un error, borra el error después de 5 segundos
        if (errorSubNotas) {
          setTimeout(() => {
            setNotas(prevNotas => prevNotas.map(n => {
              if (n.id === id) {
                return { ...n, errorSubNotas: null };
              }
              return n;
            }));
          }, 3000); // Tiempo de espera de 5 segundos (5000 ms)
        }
  
        return { 
          ...nota, 
          subNotas: nuevasSubNotas,
          errorSubNotas
        };
      }
      return nota;
    }));
  };
  

  


  const handlePonderadoChange = (id) => {
    const notaSeleccionada = notas.find(nota => nota.id === id);
    const nuevoPonderado = !notaSeleccionada.ponderado;

    const nuevaListaNotas = notas.map(nota => ({
        ...nota,
        ponderado: nuevoPonderado,
        valor: nuevoPonderado ? (nota.valor === '' ? '0' : nota.valor) : '',
    }));

    setNotas(nuevaListaNotas);
};;
  
const handleSubNotaPonderadoChange = (notaId) => {
  const notaSeleccionada = notas.find(nota => nota.id === notaId);
  const nuevoPonderado = !notaSeleccionada.subNotas.every(subNota => subNota.ponderado);

  const nuevaListaNotas = notas.map(nota => {
      if (nota.id === notaId) {
          const nuevasSubNotas = nota.subNotas.map(subNota => ({
              ...subNota,
              ponderado: nuevoPonderado,
              valor: nuevoPonderado ? (subNota.valor === '' ? '0' : subNota.valor) : '',
          }));

          return {
              ...nota,
              subNotas: nuevasSubNotas,
          };
      }
      return nota;
  });

  setNotas(nuevaListaNotas);
};
  

  const handleGuardar = async () => {
    // Filtrar las notas principales que están ponderadas
    const notasPrincipalesPonderadas = notas.filter(nota => nota.tipo !== 'formativa' && nota.ponderado);
    const totalPonderadoPrincipal = notasPrincipalesPonderadas.reduce((total, nota) => total + parseFloat(nota.valor || 0), 0);

    const tieneTipoEnProgreso = notas.some(nota => nota.tipo === 'formativa');

    if (tieneTipoEnProgreso) {
        setAlertMessage('No se puede guardar mientras existan notas con tipo de calificación en proceso (formativa).');
        setShowAlertModal(true);
        return;
    }

    let errorMessage = '';
    let erroresAcumulativas = [];

    // Verificar si las notas principales ponderadas suman 100%
    if (notasPrincipalesPonderadas.length > 0 && Math.abs(totalPonderadoPrincipal - 100) > 0.01) {
        const diferencia = (100 - totalPonderadoPrincipal).toFixed(2);
        errorMessage += `El total de los valores ponderados de las notas principales debe ser 100%. Actualmente ${diferencia > 0 ? 'falta' : 'sobra'} ${Math.abs(diferencia)}%.\n`;
    }

    // Verificar los errores en las notas acumulativas
    notas.forEach((nota, indice) => {
        if (nota.tipo === 'acumulativa') {
            const subNotasPonderadas = nota.subNotas.filter(subNota => subNota.ponderado);
            if (subNotasPonderadas.length > 0) {
                const totalSubPonderado = subNotasPonderadas.reduce((total, subNota) => total + parseFloat(subNota.valor || 0), 0);
                if (Math.abs(totalSubPonderado - 100) > 0.01) {
                    const diferencia = (100 - totalSubPonderado).toFixed(2);
                    erroresAcumulativas.push(`Nota S${indice + 1}: ${diferencia > 0 ? `Faltan ${Math.abs(diferencia)}%` : `Sobran ${Math.abs(diferencia)}%`}`);
                }
            }
        }
    });

    if (erroresAcumulativas.length > 0) {
        errorMessage += `Las siguientes notas Acumulativas tienen valores ponderados que no suman 100%:\n`;
        erroresAcumulativas.forEach(error => {
            errorMessage += `${error}\n`;
        });
        errorMessage += 'Ajuste los porcentajes para que el total en cada caso sea 100%.';
    }

    // Mostrar mensaje de error si lo hay
    if (errorMessage) {
        setAlertMessage(errorMessage);
        setShowAlertModal(true);
        return;
    }

    // Si no hay errores, proceder con la lógica de guardado
    if (cantidadNotas < cantidadInicialNotas) {
        const notasEliminadas = cantidadInicialNotas - cantidadNotas;
        const casillasEliminadas = Array.from({ length: notasEliminadas }, (_, i) => `S${cantidadInicialNotas - i}`).reverse();
        const mensajeCasillas = casillasEliminadas.join(', ');
        const mensajeConfirmacion = `Se eliminar${notasEliminadas === 1 ? 'á' : 'án'} la${notasEliminadas === 1 ? '' : 's'} siguiente${notasEliminadas === 1 ? '' : 's'} casilla${notasEliminadas === 1 ? '' : 's'}: ${mensajeCasillas}. ¿Estás seguro de que deseas guardar estos cambios?`;
        setAlertMessage(mensajeConfirmacion);
        setShowConfirmModal(true);
    } else {
        confirmarGuardar();
    }
};

  

const confirmarGuardar = async () => {
  const data = {
    confGlobal: {
      ...confGlobal,
      cantidadCasillasSum: cantidadNotas 
    },
    confNotasSum: notas.map((nota, index) => ({
      confSumCasilla: index + 1, 
      confSumTitulo: nota.titulo,
      confSumFecha: nota.fecha ? nota.fecha.toISOString().split('T')[0] : null, 
      confSumPonderacion: nota.ponderado ? parseFloat(nota.valor) : 0,
      confSumAcumulativa: nota.tipo === 'acumulativa',
      cantidadCasillasAcu: nota.tipo === 'acumulativa' ? nota.subNotas.length : null,
      confNotaAcu: nota.subNotas.map((subNota, subIndex) => ({
        confAcuCasilla: subIndex + 1,
        confAcuTitulo: subNota.titulo,
        confAcuFecha: subNota.fecha ? subNota.fecha.toISOString().split('T')[0] : null,
        confAcuPonderacion: subNota.ponderado ? parseFloat(subNota.valor) : 0
      }))
    })),
    confNotaFinalSum: null 
  };

  try {
    const response = await saveNotas(data);
    
    // Asegúrate de guardar unidadId como un número simple
    localStorage.setItem('configTablaNotas', JSON.stringify({
      cursoId: confGlobal.cursoId,
      cursoNombre: confGlobal.cursoNombre,
      asignaturaId: confGlobal.asignaturaId,
      asignaturaNombre: confGlobal.asignaturaNombre,
      periodoEscolarId: confGlobal.periodoEscolarId,
      periodoEscolarNombre: confGlobal.periodoEscolarNombre,
      unidadId: confGlobal.unidadId, // Esto debe ser un valor simple (número)
      unidadNombre: confGlobal.unidadNombre // Nombre de la unidad
    }));

    window.location.reload(); 
    cerrar(); 
  } catch (err) {
    console.error("Error al guardar las notas:", err);
  }
  
  setShowConfirmModal(false);
};


  const handleCerrar = () => {
    cerrar();
  };

  useEffect(() => {
    if (confNotasSum.length) {
        setCantidadNotas(confNotasSum.length);
        setCantidadInicialNotas(confNotasSum.length);

        // Verificar si alguna nota principal tiene valor > 0
        const algunaNotaPrincipalPonderada = confNotasSum.some(nota => parseFloat(nota.confSumPonderacion) > 0);

        const notasIniciales = confNotasSum.map((nota) => {
            // Procesar las subnotas y verificar si alguna tiene valor > 0
            const subNotas = nota.confNotaAcu ? nota.confNotaAcu.map((subNota) => {
                const valorSubNota = parseFloat(subNota.confAcuPonderacion);
                return {
                    titulo: subNota.confAcuTitulo || '',
                    fecha: subNota.confAcuFecha ? new Date(subNota.confAcuFecha) : null,
                    ponderado: valorSubNota > 0, // Activa el checkbox si el valor es mayor a 0
                    valor: valorSubNota >= 0 ? subNota.confAcuPonderacion.toString() : '',
                    errorPonderado: null
                };
            }) : [];

            // Verificar si alguna subnota tiene un valor > 0
            const algunaSubNotaPonderada = subNotas.some(subNota => parseFloat(subNota.valor) > 0);

            // Determinar si activar toda la sección de subnotas
            const activarSubNotas = algunaSubNotaPonderada;

            return {
                id: nota.confSumCasilla,
                titulo: nota.confSumTitulo || '',
                fecha: nota.confSumFecha ? new Date(nota.confSumFecha) : null,
                tipo: nota.confSumAcumulativa ? 'acumulativa' : 'sumativa',
                ponderado: algunaNotaPrincipalPonderada, // Si alguna nota principal tiene valor > 0, todas se activan
                valor: nota.confSumPonderacion >= 0 ? nota.confSumPonderacion.toString() : '',
                subNotas: subNotas.map(subNota => ({
                    ...subNota,
                    ponderado: activarSubNotas // Todas las subnotas se activan si alguna tiene valor > 0
                })),
                errorPonderado: null
            };
        });

        setNotas(notasIniciales);
        setEstadoPonderadoOriginal(notasIniciales);
    } else {
        setCantidadNotas(0);
        setCantidadInicialNotas(0);
    }
}, [confNotasSum, mostrar]);




  const actualizarNota = (id, campo, valor) => {
    setNotas(notas.map(nota => {
      if (nota.id === id) {
        if (campo === 'valor') {
          if (valor === '' || parseFloat(valor) <= 100) {
            return { ...nota, [campo]: valor, errorPonderado: null };
          } else {
            return { ...nota, [campo]: '', errorPonderado: 'El valor ponderado no puede exceder el 100%' };
          }
        }
        return { ...nota, [campo]: valor };
      }
      return nota;
    }));
  };

  const actualizarSubNota = (notaId, subNotaIndex, campo, valor) => {
    setNotas(notas.map(nota => {
      if (nota.id === notaId) {
        const nuevasSubNotas = nota.subNotas.map((subNota, index) => {
          if (index === subNotaIndex) {
            if (campo === 'valor') {
              if (valor === '' || parseFloat(valor) <= 100) {
                return { ...subNota, [campo]: valor, errorPonderado: null };
              } else {
                return { ...subNota, [campo]: '', errorPonderado: 'El valor ponderado no puede exceder el 100%' };
              }
            }
            return { ...subNota, [campo]: valor };
          }
          return subNota;
        });
        return { ...nota, subNotas: nuevasSubNotas };
      }
      return nota;
    }));
  };

  const handleGlobalPonderadoChange = () => {
    const nuevoPonderado = !notas.every(nota => nota.ponderado); // Verificamos si todas las notas están ponderadas
  
    // Actualizamos el estado de todas las notas principales con el valor del checkbox global
    const nuevasNotas = notas.map(nota => ({
      ...nota,
      ponderado: nuevoPonderado, 
      valor: nuevoPonderado ? (nota.valor === '' ? '0' : nota.valor) : '', // Asigna valor si está ponderado
    }));
  
    setNotas(nuevasNotas); // Actualizamos el estado
  };
  

  return (
    <>
      <Modal show={mostrar} onHide={handleCerrar} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title className='w-100 text-center'>Configuración Tabla de Notas</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '75dvh', overflowY: 'scroll', overflowX: 'auto' }}>
            {/* Selector Global de Ponderado */}
            <Row className="align-items-center mb-3 pt-2 justify-content-center" style={{ position: 'sticky', top: -14, backgroundColor: '#fff', zIndex: 2 }}>
              {/* <Col sm={2}>
                <Form.Label>Cantidad de Notas</Form.Label>
              </Col> */}
              {/* <Col sm={1}>
                <Form.Control
                  type="text"
                  inputMode="numeric"
                  value={cantidadNotas}
                  onChange={actualizarCantidadNotas}
                  onFocus={(e) => e.target.select()}
                  max={20}
                />
              </Col> */}
              <Col sm={2}>
                <Form.Label>Curso: {confGlobal.cursoNombre}</Form.Label>
              </Col>
              <Col sm={3}>
                <Form.Label>Asignatura: {confGlobal.asignaturaNombre}</Form.Label>
              </Col>
              <Col sm={3}>
                <Form.Label>{confGlobal.periodoEscolarNombre}</Form.Label>
              </Col>
              {/* Mostrar la unidad si está disponible */}
              {confGlobal.unidadNombre && (
                <Col sm={3}>
                  <Form.Label>Unidad: {confGlobal.unidadNombre}</Form.Label>
                </Col>
              )}
              <Row>
                <Col sm={2}>
                <Form.Label className="flex align-items-center mb-3 pt-2">Cantidad de Notas</Form.Label>
              </Col>
              <Col sm={1}>
                <Form.Control
                  type="text"
                  inputMode="numeric"
                  value={cantidadNotas}
                  onChange={actualizarCantidadNotas}
                  onFocus={(e) => e.target.select()}
                  max={20}
                />
              </Col>
              <Col sm={3} className="d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  id="globalPonderadoSwitch"
                  label="Ponderado Global"
                  checked={notas.every(nota => nota.ponderado)}
                  onChange={handleGlobalPonderadoChange}
                />
                {/* Icono de información */}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-ponderado-global">
                      Si el Ponderado Global está activado, todas las calificaciones principales serán ponderadas
                      y la suma total de las ponderaciones debe ser igual a 100%.
                    </Tooltip>
                  }
                >
                  <span className="ml-2">
                    <FaInfoCircle style={{ cursor: 'pointer', color: 'var(--color-icon)' }} />
                  </span>
                </OverlayTrigger>
              </Col>

              {errorCantidadNotas && (
                <Row className="align-items-center mb-3">
                  <Col sm={12}>
                    <div className="text-danger text-start" style={{ fontSize: '0.8rem' }}>
                      {errorCantidadNotas}
                    </div>
                  </Col>
                </Row>
              )}
              </Row>
            </Row>



          {notas.map((nota, indice) => (
            <div key={nota.id} className="mb-3">
              <Row className="align-items-center mb-2">
                <Col sm={1}>
                  <h5>{`Nota S${indice + 1}`}</h5>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId={`notaTitulo${nota.id}`}>
                    <Row className="align-items-center">
                      <Col sm={2} style={{ marginLeft: '-20px' }}>
                        <Form.Label>Título</Form.Label>
                      </Col>
                      <Col sm={10} style={{ marginLeft: '-10px', marginRight: '0px' }}>
                        <Form.Control
                          type="text"
                          placeholder="Título de la evaluación"
                          value={nota.titulo}
                          onChange={(e) => actualizarNota(nota.id, 'titulo', e.target.value)}
                          style={{ width: '110%' }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group controlId={`notaTipo${nota.id}`}>
                    <Row className="align-items-center">
                      <Col sm={3} style={{ marginLeft: '-15px', marginRight: '-25px' }}>
                        <Form.Label>Tipo</Form.Label>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          as="select"
                          value={nota.tipo}
                          onChange={(e) => handleTipoChange(nota.id, e.target.value)}
                          style={{ appearance: 'auto', width: '100%' }}
                        >
                          <option value="sumativa">Sumativa</option>
                          <option value="acumulativa">Acumulativa</option>
                          <option value="formativa">Formativa</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col sm={3} className="d-flex align-items-center">
                  <Form.Group controlId={`notaFecha${nota.id}`} className="flex-grow-1">
                    <Row className="align-items-center">
                      <Col sm={4} style={{ marginLeft: '-55px', marginRight: '-20px' }}>
                        <Form.Label>Fecha</Form.Label>
                      </Col>
                      <Col sm={8} className="pl-0">
                        <DatePicker
                          selected={nota.fecha}
                          onChange={(date) => actualizarNota(nota.id, 'fecha', date)}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          placeholderText="Seleccione fecha"
                          locale="es" 
                          style={{ width: '100%' }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  {/* <FontAwesomeIcon
                    icon={faTrash}
                    className=""
                    style={{ cursor: 'pointer', color: 'var(--color-eliminar)' }}
                    onClick={() => solicitarEliminarNota(nota.id)}
                  /> */}
                </Col>
              </Row>
              <Row className="align-items-center mb-2">
                <Col sm={4}>
                  {nota.tipo !== 'formativa' && (
                    <Row className="align-items-center">
                      <Col sm={4}>
                       
                      </Col>
                      <Col sm={8}>
                        {nota.ponderado && (
                          <div className="d-flex align-items-center flex-column">
                            {nota.errorPonderado && (
                              <div className="text-danger" style={{ fontSize: '0.8rem', whiteSpace: 'nowrap' }}>
                                {nota.errorPonderado}
                              </div>
                            )}
                            <div className="d-flex align-items-center">
                              <Form.Control
                                type="text"
                                inputMode="numeric"
                                placeholder="Valor"
                                value={nota.valor}
                                onChange={(e) => actualizarNota(nota.id, 'valor', e.target.value)}
                                style={{ width: '30%' }}
                              />
                              <span style={{ marginLeft: '0.5rem' }}>% Ponderado</span>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>

              {nota.tipo === 'acumulativa' && (
                <>
                  <Row className="align-items-center mb-3">
                    <Col sm={3} style={{ width: '200px' }}>
                      <Form.Label>Cantidad de Notas Acumulativas</Form.Label>
                    </Col>
                    <Col sm={1} style={{ marginLeft: '-10px' }}>
                      <Form.Control
                        type="text"
                        inputMode="numeric"
                        value={nota.subNotas.length}
                        onChange={(e) => actualizarCantidadSubNotas(nota.id, e)}
                        onFocus={(e) => e.target.select()}
                      />
                    </Col>
                    <Col sm={2} style={{ marginLeft: '-10px' }}>
                      <Form.Check
                        type="checkbox"
                        id={`subNotaPonderadoSwitch${nota.id}`}
                        label="Ponderado"
                        checked={nota.subNotas.every(subNota => subNota.ponderado)}
                        onChange={() => handleSubNotaPonderadoChange(nota.id)}
                      />
                    </Col>
                    <Col sm={6}>
                      {nota.errorSubNotas && (
                        <div className="text-danger" style={{ fontSize: '0.8rem', marginLeft: '10px' }}>
                          {nota.errorSubNotas}
                        </div>
                      )}
                    </Col>
                  </Row>
                  {nota.subNotas.map((subNota, subIndice) => (
                    <div key={subIndice} className="ml-3 mt-2">
                      <Row className="align-items-center">
                        <Col sm={1} style={{ width: '150px' }}>
                          <h6>Nota A{subIndice + 1}</h6>
                        </Col>
                        <Col sm={4} style={{ marginLeft: '-50px' }}>
                          <Form.Group controlId={`subNotaTitulo${nota.id}-${subIndice}`}>
                            <Row className="align-items-center">
                              <Col sm={2}>
                                <Form.Label>Título</Form.Label>
                              </Col>
                              <Col sm={10}>
                                <Form.Control
                                  type="text"
                                  placeholder="Título de la evaluación"
                                  value={subNota.titulo}
                                  onChange={(e) => actualizarSubNota(nota.id, subIndice, 'titulo', e.target.value)}
                                  style={{ width: '100%' }}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </Col>
                        <Col sm={3} className="d-flex align-items-center">
                          <Form.Group controlId={`subNotaFecha${nota.id}-${subIndice}`} className="flex-grow-1">
                            <Row className="align-items-center">
                              <Col sm={3}>
                                <Form.Label>Fecha</Form.Label>
                              </Col>
                              <Col sm={9}>
                                <DatePicker
                                  selected={subNota.fecha}
                                  onChange={(date) => actualizarSubNota(nota.id, subIndice, 'fecha', date)}
                                  dateFormat="dd-MM-yyyy"
                                  className="form-control"
                                  placeholderText="Seleccione fecha"
                                  locale="es"
                                  style={{ width: '100%' }}
                                />
                              </Col>
                            </Row>
                          </Form.Group>
                        </Col>
                        <Col sm={3} className="d-flex flex-column align-items-start">
                          {subNota.errorPonderado && (
                            <div className="text-danger" style={{ fontSize: '0.8rem', whiteSpace: 'nowrap', marginBottom: '0.2rem' }}>
                              {subNota.errorPonderado}
                            </div>
                          )}
                          {subNota.ponderado && (
                            <div className="d-flex align-items-center ml-2">
                              <Form.Control
                                type="text"
                                inputMode="numeric"
                                placeholder="Valor"
                                value={subNota.valor}
                                onChange={(e) => actualizarSubNota(nota.id, subIndice, 'valor', e.target.value)}
                                style={{ width: '30%' }}
                              />
                              <span style={{ marginLeft: '0.5rem' }}>% Ponderado</span>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              )}
              {indice < notas.length - 1 && <hr style={{ border: '1px solid #000' }} />}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <ButtonPersonalizado 
            variant="color-acento" 
            onClick={handleGuardar} 
            disabled={loading}
          >
            {loading ? 'Guardando...' : 'Guardar'}
          </ButtonPersonalizado>
          {error && <div>Error al guardar las notas: {error.message}</div>}
        </Modal.Footer>
      </Modal>
  


      {/* Confirm Modal */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className='w-100 text-center'>AVISO</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <p>
            {alertMessage}
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="outline-primary" onClick={() => setShowConfirmModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={confirmarGuardar}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Alert Modal */}
      <Modal show={showAlertModal} onHide={() => setShowAlertModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className='w-100 text-center'>AVISO</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <p>{alertMessage}</p>
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className='w-100 text-center'>AVISO</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <p>¿Estás seguro de que deseas eliminar esta calificación? Esta acción podría afectar las calificaciones ya ingresadas.</p>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'space-between' }}>
          <ButtonPersonalizado variant="outline-primary" onClick={() => setShowDeleteConfirmModal(false)}>
            Cancelar
          </ButtonPersonalizado>
          <Button style={{ backgroundColor: 'var(--color-eliminar)', border: '1px solid var(--color-eliminar)' }} onClick={confirmarEliminarNota}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfigTablaNotas;