import React from 'react';
import { Modal } from 'react-bootstrap';

const NotificacionError = ({ visible, onClose }) => (
    <Modal show={visible} onHide={onClose} centered>
        <Modal.Header  closeButton>
            <Modal.Title className="w-100 text-center">AVISO</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
            <p>No se puede acceder a asistencias futuras. 
                <br></br>
                Verifique hora y fecha, luego vuelva a intentarlo.</p>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal>
);

export default NotificacionError;
