// 



export const handleMouseEnter = (ref) => {
  const hoverColor = 'var(--color-secundario)'; // Color de hover para todos los eventos
  if (ref.current) {
    ref.current.setAttribute('data-original-bg', ref.current.style.backgroundColor);
    ref.current.style.backgroundColor = hoverColor;
  }
};

export const handleMouseLeave = (ref) => {
  if (ref.current) {
    const originalBg = ref.current.getAttribute('data-original-bg');
    ref.current.style.backgroundColor = originalBg;
  }
};
