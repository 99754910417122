import { faEye, faFilter, faPenToSquare, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useCheckRoles from "../../../../hooks/useCheckRoles";
import useGetListadoCursos from "../../../../hooks/useGetListadoCursos";
import CustomSpinner from "../../../components/spinner/Spinner";
import DynamicTable from "../../../components/tables/DynamicTable/Table";
import Title from "../../../components/titles/Title";
import CustomTooltip from "../../../components/tooltip/Tooltip";

const ListadoCursos = () => {
  const { cursos, isLoading } = useGetListadoCursos();
  const [filteredData, setFilteredData] = useState([]);
  const { isDirectivo } = useCheckRoles(); 
  const navigate = useNavigate();

  const headers = [
    { name: "Nro.", width: "80px", textAlign: "center" },
    { name: "Tipo de Enseñanza", width: "150px", textAlign: "center" },
    { name: "Grado", width: "100px", textAlign: "center" },
    { name: "Curso", width: "100px", textAlign: "center" },
    { name: "Profesor Jefe", width: "150px", textAlign: "center" },
    { name: "Co Docente", width: "150px", textAlign: "center" },
    { name: "Estudiantes", width: "100px", textAlign: "center" },
    { name: "Acciones", width: "120px", textAlign: "center" },
  ];

  useEffect(() => {
    setFilteredData(cursos || []);
  }, [cursos]);

  const handleEdit = (curso) => {
    navigate("/ficha-curso", { state: { curso } });
  };

  const handleDelete = (courseId) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción eliminará el curso.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        // Lógica para eliminar curso aquí
      }
    });
  };

  // Calcular el resumen de la tabla
  const resumeTable = [
    { label: "Total de Cursos", value: filteredData.length },
    { label: "Profesores Jefes", value: filteredData.filter((curso) => curso.profJefeNombre).length },
    { label: "Co Docentes", value: filteredData.filter((curso) => curso.educDifNombre).length },
    { label: "Estudiantes", value: filteredData.reduce((acc, curso) => acc + (curso.cursoConteoActual || 0), 0) },
  ];

  return (
    <Container fluid className="px-4" style={{ height: "91.5dvh" }}>
      <Row style={{ position: "relative", padding: ".6rem" }}>
        <Title text={"Listado Cursos"} />

        <Button
          variant="color-acento-outline"
          className="w-auto w-sm-100"
          onClick={() => {}}
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            position: "absolute",
            left: 15,
            top: 10,
          }}
        >
          <FontAwesomeIcon icon={faFilter} /> Filtros
        </Button>

        {isDirectivo && (
          <Button
            variant="color-acento"
            className="w-auto w-sm-100"
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              position: "absolute",
              right: 15,
              top: 10,
            }}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Agregar
          </Button>
        )}
      </Row>

      {isLoading ? (
        <CustomSpinner />
      ) : (
        <DynamicTable
          data={filteredData.map((curso, index) => ({
            id: curso.cursoId,
            value: [
              index + 1,
              curso.ensenanzaNombre || "-",
              curso.gradoNombre || "-",
              curso.cursoNombre || "-",
              curso.profJefeNombre || "-",
              curso.educDifNombre || "-",
              curso.cursoConteoActual || "-",
              <div style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
                <CustomTooltip tooltipMsg={isDirectivo ? "Editar" : "Ver"}>
                  <Button
                  variant={!isDirectivo ? "color-acento-outline" : "color-amarillo-outline"}
                    className="me-1"
                    onClick={() => handleEdit(curso)}
                  >
                     <FontAwesomeIcon icon={isDirectivo ? faPenToSquare : faEye} />
                  </Button>
                </CustomTooltip>
                <CustomTooltip tooltipMsg={!isDirectivo ? "No tienes permisos" : "Eliminar curso"}>
                  <Button
                   style={{ display: !isDirectivo ? "none" : "block" }}
                    variant="color-rojo-outline"
                    onClick={() => handleDelete(curso.cursoId)}
                    disabled={!isDirectivo}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </CustomTooltip>
              </div>,
            ],
          }))}
          headers={headers}
          options={{
            searchInput: true,
            showPagination: true,
            showItemsPerPageSelector: true,
            stickyHeaders: true,
            stickyOffset: "-10px",
            copyClipboard: true,
            resumeTable: resumeTable,
          }}
        />
      )}
    </Container>
  );
};

export default ListadoCursos;
