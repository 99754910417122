import React from 'react';

function SwitchInput({ value, onToggle, label, option1, option2 }) {
  const handleToggle = () => {
    const newValue = !value;
    onToggle(newValue); // Call the function passed from the parent
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        fontFamily: 'Arial, sans-serif',
        paddingTop: '5px', 
      }}
    >
      <span style={{fontWeight: 'semibold', marginBottom: '8px', color: 'var(--color-texto-oscuro)' }}>
       {label}
      </span>
      <div
        onClick={handleToggle}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span style={{fontWeight: 500, marginRight: '8px', color: value ? 'var(--color-gris)' : 'var(--color-acento)' }}>
          {option1}
        </span>
        <div
          style={{
            width: '50px',
            height: '25px',
            borderRadius: '15px',
            backgroundColor: value ? 'var(--color-acento)' : 'var(--color-gris)',
            position: 'relative',
            transition: 'background-color 0.3s',
          }}
        >
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: '#fff',
              position: 'absolute',
              top: '2.5px',
              left: value ? '27px' : '3px',
              transition: 'left 0.3s',
            }}
          />
        </div>
        <span style={{fontWeight: 500, marginLeft: '8px', color: value ? 'var(--color-acento)' : 'var(--color-gris)' }}>
          {option2}
        </span>
      </div>
    </div>
  );
}

export default SwitchInput;
