import React, { useEffect } from "react";
import TextInput from "../../../../components/inputs/TextInput";
import SelectSingle from "../../../../components/inputs/SelectSingle";
import { Col, Container, Row } from "react-bootstrap";
import SwitchInput from "../../../../components/inputs/SwitchInput";
import TextAreaInput from "../../../../components/inputs/TextAreaInput";
import MultiValueInput from "../../../../components/inputs/MultiValueInput";

const separarTextoPorComas = (texto) => {
  // Array inicial
  let array = [];

  // Texto a agregar, separado por comas
  // let texto = "opcion3, opcion4, opcion5";

  // Separar el texto por comas y eliminar espacios adicionales
  let nuevosElementos = texto.split(",").map((elemento) => elemento.trim());

  // Agregar los nuevos elementos al array
  return (array = array.concat(nuevosElementos));
};

function TabGeneral({}) {
  return (
    <Container
      //   className="width-40 px-3 d-flex flex-row flex-wrap justify-content-center gap-3"
      style={{
        height: "75dvh",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingTop: "1rem",
      }}
    >
      <Row className="w-100">
        <Col className="col-12 col-md-3">
          <TextInput
            placeholder="Código"
            value={""}
            onChange={(e) => () => {}}
            label={"Código MINEDUC"}
            disabled
            className="no-darken-when-disabled"
          />
        </Col>
        <Col className="col-12 col-md-6">
          <TextInput
            placeholder="Nombre Asignatura"
            value={""}
            onChange={(e) => () => {}}
            label={"Nombre Asignatura"}
            disabled
            className="no-darken-when-disabled"
          />
        </Col>
        <Col className="col-12 col-md-3">
          {/* <SelectSingle
            placeholder="Tipo Asignatura"
            elements={[]}
            selectedElement={null}
            onChange={(e) => () => {}}
            labelKey="name"
            idKey="id"
            dropDirection="down"
     
          /> */}
          <TextInput
            placeholder="Tipo Asignatura"
            value={""}
            onChange={(e) => () => {}}
            label={"Tipo Asignatura"}
            disabled={true}
            className="no-darken-when-disabled"
          />
        </Col>
      </Row>
      <Row className="w-100">
        <Col className="col-12 col-md-3">
          <SelectSingle
            placeholder="Nivel Enseñanza"
            elements={[]}
            selectedElement={null}
            onChange={(e) => () => {}}
            labelKey="name"
            idKey="id"
            dropDirection="down"
            disabled
          />
        </Col>
        <Col className="col-12 col-md-3">
          <SelectSingle
            placeholder="Grado"
            elements={[]}
            selectedElement={null}
            onChange={(e) => () => {}}
            labelKey="name"
            idKey="id"
            dropDirection="down"
            disabled
          />
        </Col>
        <Col className="col-12 col-md-3">
          <SelectSingle
            placeholder="Sector"
            elements={[]}
            selectedElement={null}
            onChange={(e) => () => {}}
            labelKey="name"
            idKey="id"
            dropDirection="down"
            disabled
          />
        </Col>
        <Col className="col-12 col-md-3"></Col>
      </Row>
      <Row className="w-100">
        <Col className="col-12 col-md-3">
          <TextInput
            value={""}
            onChange={(e) => () => {}}
            label={"Horas Semanales"}
            disabled
            className="no-darken-when-disabled"
          />
        </Col>
        <Col className="col-12 col-md-3">
          <TextInput
            value={""}
            onChange={(e) => () => {}}
            label={"Horas Semestrales"}
            disabled
            className="no-darken-when-disabled"
          />
        </Col>
        <Col className="col-12 col-md-3">
          <TextInput
            value={""}
            onChange={(e) => () => {}}
            label={"Horas Anuales"}
            disabled
            className="no-darken-when-disabled"
          />
        </Col>
        <Col className="col-12 col-md-3"></Col>
      </Row>

      <Row className="w-100">
        <Col className="col-12 col-md-3">
          <SelectSingle
            placeholder="Tipo Calificación"
            elements={[]}
            selectedElement={null}
            onChange={(e) => () => {}}
            labelKey="name"
            idKey="id"
            dropDirection="down"
            disabled
          />
        </Col>
        <Col className="col-12 col-md-3">
          <SelectSingle
            placeholder="Afecta el promedio"
            elements={[]}
            selectedElement={null}
            onChange={(e) => () => {}}
            labelKey="name"
            idKey="id"
            dropDirection="down"
            disabled
          />
        </Col>
        <Col className="col-12 col-md-3">
          <SelectSingle
            placeholder="Tipo Aproximación"
            elements={[]}
            selectedElement={null}
            onChange={(e) => () => {}}
            labelKey="name"
            idKey="id"
            dropDirection="down"
            disabled
          />
        </Col>
        <Col className="col-12 col-md-3"></Col>
      </Row>

      <Row className="w-100">
        <Col className="col-12">
          <TextAreaInput
            value={""}
            onInput={() => {}}
            label={"Propósito"}
            disabled
            className="no-darken-when-disabled"
          />
        </Col>
      </Row>
      <Row className="w-100">
        <Col className="col-12">
          <TextAreaInput
            value={""}
            onInput={() => {}}
            label={"Conocimientos Prévios"}
            disabled
            className="no-darken-when-disabled"

          />
        </Col>
      </Row>
      <Row className="w-100">
        <Col className="col-12">
          <MultiValueInput
            label="Palabras Clave"
            options={separarTextoPorComas(
              "opcion1, opcion2, skjghaskljf, asdkljfhsdkjfhskdjfashf.,m 12342354345345"
            ).map((elemento) => ({
              value: elemento,
              label: elemento,
            }))}
            placeholder="Elige etiquetas..."
            noOptionsMessage="No hay opciones disponibles"
            onChange={(selectedOptions) => console.log(selectedOptions)}
            isMulti={true}
            formatCreateLabel={(inputValue) => `Añadir "${inputValue}"`}
            disabled
          />
        </Col>
      </Row>
    </Container>
  );
}

export default TabGeneral;
