import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { normalizeString } from "../../../util/util";

const PhoneNumberInput = ({
  onChange,
  maxLength,
  countries,
  disabled,
  value,
  style,
}) => {
  const [selectedCode, setSelectedCode] = useState("+56");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (value) {

      if (value.startsWith('+')) {
        // Intentar identificar el código de área en el valor inicial
        const foundCountry = countries.find((country) =>
          value.startsWith(country.areaCode)
        );

        if (foundCountry) {
          // Si se encuentra un código, actualizar el código seleccionado y el número
          setSelectedCode(foundCountry.areaCode);
          setPhoneNumber(value.slice(foundCountry.areaCode.length));
        } else {
          // Si no se encuentra, mantener el número como está
          setPhoneNumber(value);
        }
      } else {
        // Si no hay un +, asumimos que es solo el número local
        setPhoneNumber(value);
      }
    }
  }, [value, countries]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, ""); // Filtrar caracteres no numéricos
    setPhoneNumber(numericValue);
    onChange(`${selectedCode}${numericValue}`); // Actualiza el valor combinado de código y número
  };

  const handleCodeChange = (code) => {
    setSelectedCode(code);
    onChange(`${code}${phoneNumber}`); // Actualiza el valor combinado de código y número
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCountryCodes = countries.filter((country) =>
    normalizeString(country.countryName).includes(normalizeString(searchTerm))
  );

  return (
    <Form.Group as={Row} controlId="phoneNumber">
      <Form.Label>Teléfono Móvil</Form.Label>

      <InputGroup style={{display:' flex',width:'100%'}}>
        <DropdownButton
          title={selectedCode}
          id="input-group-dropdown-1"
          drop="down"
          align="start"
          disabled={disabled}
          variant="color-lightgray-outline"
          style={{width: '20%'}}
        >
          <div style={{ padding: "10px" }}>
            <FormControl
              autoFocus
              placeholder="Buscar país..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            {filteredCountryCodes.map((country) => (
              <Dropdown.Item
                key={country.alpha2Code}
                onClick={() => handleCodeChange(country.areaCode)}
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <span
                  className={`flag-icon flag-icon-${country.alpha2Code.toLowerCase()}`}
                ></span>
                {country.countryName} ({country.areaCode})
              </Dropdown.Item>
            ))}
          </div>
        </DropdownButton>
        <Form.Control
          aria-label="Text input with dropdown button"
          value={phoneNumber} // Muestra solo el número sin el código de área
          onChange={handleInputChange}
          maxLength={maxLength}
          disabled={disabled}
          style={{style }}
        />
      </InputGroup>
    </Form.Group>
  );
};

export default PhoneNumberInput;
