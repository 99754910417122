import React, { useEffect, useRef, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import useGetInformeUnidadesGlobal from '../../../hooks/useGetInformeUnidadesGlobal';
import { generarPDF } from '../../../util/util';
import InformeCalificacionesGaspar from '../../components/informeCalificacionEstudianteCurso/InformeCalificacionesGaspar';

const InformeCalificacionesEstudiante = () => {
    const [datos, setDatos] = useState(null);
    const informeRef = useRef();

    // Recuperar datos desde localStorage, incluyendo cursoId y estudianteId
    const { estudianteId, cursoId, periodoEscolar, semestre, columnasUnidades } = datos || {};
    console.log("Datos de localStorage:", { estudianteId, cursoId, periodoEscolar, semestre, columnasUnidades });

    // Usar el hook para obtener los datos del informe global de unidades
    const {
        dataColegio,
        dataDirector,
        dataCurso,
        notasEstudiantes,
        notasAsignaturaNoInfluye,
        asignaturasConConcepto,
        cargando,
        error,
        columnaUnidades,
        listadoEscalas,
        cantidadDecimales // Extraemos cantidadDecimales aquí
    } = useGetInformeUnidadesGlobal(cursoId, estudianteId);

    // Log para verificar los datos del hook
    console.log("Datos del hook:", {
        dataColegio,
        dataDirector,
        dataCurso,
        notasEstudiantes,
        columnaUnidades,
        listadoEscalas,
        notasAsignaturaNoInfluye,
        cantidadDecimales // Verificar que cantidadDecimales se recibe correctamente
    });
    console.log(cantidadDecimales)

    const convertirAbreviaturaANombreCompleto = (nombre) => {
        switch (nombre) {
            case "1er Semestre":
                return "Primer Semestre";
            case "2do Semestre":
                return "Segundo Semestre";
            default:
                return nombre;
        }
    };

    const handleGenerarPDF = () => {
        if (notasEstudiantes?.length > 0) {
            const estudianteNombre = `${notasEstudiantes[0]?.estudiante?.nombrePrimario}_${notasEstudiantes[0]?.estudiante?.apellidoPrimario}_${notasEstudiantes[0]?.estudiante?.apellidoSecundario}`;
            const cursoNombre = dataCurso?.cursoNombre || 'curso';
            generarPDF(informeRef, `InformeCalificaciones_${estudianteNombre}_${cursoNombre}`);
        } else {
            console.warn("No se puede generar el PDF porque no hay datos de estudiantes.");
        }
    };

    useEffect(() => {
        const datosGuardados = JSON.parse(localStorage.getItem('datosInforme'));
        console.log('Datos recuperados del localStorage:', datosGuardados);
        if (datosGuardados?.cursoId && datosGuardados?.estudianteId) {
            setDatos(datosGuardados);  // Asegurar que tanto cursoId como estudianteId se recuperen
        } else {
            console.error("cursoId o estudianteId no encontrados en los datos guardados.");
        }
    }, []);

    return (
        <Container className="mt-2 bg-white p-4" style={{ paddingTop: '20px', maxWidth: '100%', margin: '0 auto', overflow: 'hidden' }}>
            <div className="d-flex justify-content-end mb-3">
                <Button variant="outline-primary" onClick={handleGenerarPDF}>
                    Descargar PDF
                </Button>
            </div>
            {cargando ? (
                <p>Cargando...</p>
            ) : error ? (
                <p>Error al cargar los datos del informe: {error}</p>
            ) : (
                notasEstudiantes?.length > 0 ? (
                    <div ref={informeRef}>
                        <InformeCalificacionesGaspar
                            columnasUnidades={columnaUnidades || columnasUnidades || []}
                            dataColegio={dataColegio}
                            dataDirector={dataDirector}
                            dataCurso={dataCurso}
                            listadoNotas={notasEstudiantes}
                            notasAsignaturaNoInfluye={notasAsignaturaNoInfluye}
                            listadoEscalas={listadoEscalas}  
                            cantidadDecimales={cantidadDecimales} // Pasar cantidadDecimales al componente
                        />
                    </div>
                ) : (
                    <p>No se encontraron datos para los estudiantes.</p>
                )
            )}
        </Container>
    );
};

export default InformeCalificacionesEstudiante;
