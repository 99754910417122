import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ModalNotas = ({
  mostrarModal,
  setMostrarModal,
  mostrarModalAviso,
  confirmarCambioNota,
  manejarOcultarModal,
  notaPendiente,
  datosOrdenados,
  modalMensaje,
  formatearNotaParaMensaje,
  conceptosPermitidos,
  blockInputs,
  setMostrarModalAviso,
  abrirModalAutenticacion,
}) => {
  // Función para obtener el valor actual (ya sea concepto o nota)
  const obtenerValorActual = () => {
    let notaActual, conceptoActual;

    if (
      typeof notaPendiente.campo === "string" &&
      notaPendiente.campo.includes("-")
    ) {
      const [casillaSumativa, casillaAcumulativa] =
        notaPendiente.campo.split("-");
      const nota = datosOrdenados
        .find((est) => est.estudiante.estudianteId === notaPendiente.id)
        ?.notas.find(
          (n) => n.notaSumCasilla === parseInt(casillaSumativa)
        );
      notaActual = nota?.notasAcu?.find(
        (n) => n.notaAcuCasilla === parseInt(casillaAcumulativa)
      )?.notaAcu;
      conceptoActual = nota?.notasAcu?.find(
        (n) => n.notaAcuCasilla === parseInt(casillaAcumulativa)
      )?.notaAcuConcepto;
    } else {
      const nota = datosOrdenados
        .find((est) => est.estudiante.estudianteId === notaPendiente.id)
        ?.notas.find((nota) => nota.notaSumCasilla === notaPendiente.campo);
      notaActual = nota?.notaSum;
      conceptoActual = nota?.notaSumConcepto;
    }

    // Retornamos el concepto si existe, si no, retornamos la nota
    return conceptoActual || notaActual;
  };

  const esConceptoActual = conceptosPermitidos.includes(
    String(obtenerValorActual()).toUpperCase()
  );
  const esConceptoNuevo = conceptosPermitidos.includes(
    String(notaPendiente.valor).toUpperCase()
  );

  // useEffect para controlar el estado del modal
  // useEffect(() => {
  //   if (mostrarModal) {
  //     console.log("Mostrando Modal de Confirmación");
  //     setTimeout(() => {
  //       setMostrarModal(true);
  //     }, 200);  // Forzar la visualización después de un pequeño retraso
  //   }

  //   if (mostrarModalAviso) {
  //     console.log("Mostrando Modal de Aviso");
  //   }

  //   // Confirmar con "Enter"
  //   const handleKeyDown = (e) => {
  //     if (e.key === "Enter" && mostrarModal) {
  //       confirmarCambioNota();
  //     }
  //   };
    
  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [mostrarModal, mostrarModalAviso, confirmarCambioNota, setMostrarModal]);

  console.log('Estado de mostrarModal:', mostrarModal);

  return (
    <>
      {/* Modal de confirmación */}
      <Modal
        centered
        show={mostrarModal}
        onHide={manejarOcultarModal}
        autoFocus={false}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            confirmarCambioNota();
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">
            {notaPendiente.valor === "0"
              ? "Confirmar Eliminar Nota"
              : "Confirmar Cambio de Nota"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {notaPendiente.valor === "0" ? (
            <>
              ¿Desea eliminar la calificación actual{" "}
              {formatearNotaParaMensaje(obtenerValorActual())}?
              {typeof notaPendiente.campo === "string" &&
                notaPendiente.campo.includes("-") && (
                  <p>
                    (Nota Acumulativa casilla N°{
                      notaPendiente.campo.split("-")[1]
                    } de la casilla Sumativa N°{
                      notaPendiente.campo.split("-")[0]
                    })
                  </p>
                )}
            </>
          ) : (
            <>
              {esConceptoActual || esConceptoNuevo ? (
                <>
                  ¿Desea cambiar el concepto actual{" "}
                  {formatearNotaParaMensaje(obtenerValorActual())} por{" "}
                  {formatearNotaParaMensaje(notaPendiente.valor)}?
                </>
              ) : (
                <>
                  ¿Desea cambiar la nota actual{" "}
                  {formatearNotaParaMensaje(obtenerValorActual())} por{" "}
                  {formatearNotaParaMensaje(notaPendiente.valor)}?
                </>
              )}

              {typeof notaPendiente.campo === "string" &&
                notaPendiente.campo.includes("-") && (
                  <p>
                    (Nota acumulativa {notaPendiente.campo.split("-")[1]} de la
                    sumativa {notaPendiente.campo.split("-")[0]})
                  </p>
                )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="color-acento"
            onClick={() => {
              confirmarCambioNota();
              document.querySelector(".modal-backdrop").style.display = "block"; // Muestra el fondo del modal si es necesario
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de aviso */}
      <Modal
        centered
        show={mostrarModalAviso}
        onHide={() => setMostrarModalAviso(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">AVISO</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{modalMensaje}</Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
          {blockInputs &&
            modalMensaje !== "No tienes permiso para expandir las calificaciones." && (
              <>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setMostrarModalAviso(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="color-acento"
                  onClick={() => {
                    setMostrarModalAviso(false);
                    setTimeout(() => {
                      abrirModalAutenticacion();
                    }, 300);
                  }}
                >
                  Desbloquear
                </Button>
              </>
            )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(ModalNotas);



     {/* <Modal
  centered
  show={mostrarModal}
  onHide={manejarOcultarModal}
  autoFocus={false}
  onKeyDown={(e) => {
    if (e.key === "Enter") {
      confirmarCambioNota();
    }
  }}
>
  <Modal.Header closeButton>
    <Modal.Title className="w-100 text-center">
      {notaPendiente.valor === "0"
        ? "Confirmar Eliminar Nota"
        : "Confirmar Cambio de Nota"}
    </Modal.Title>
  </Modal.Header>
  <Modal.Body className="text-center">
    {notaPendiente.valor === "0" ? (
      <>
        ¿Desea eliminar la calificación actual{" "}
        {formatearNotaParaMensaje(
          (() => {
            if (
              typeof notaPendiente.campo === "string" &&
              notaPendiente.campo.includes("-")
            ) {
              const [casillaSumativa, casillaAcumulativa] =
                notaPendiente.campo.split("-");
              return (
                datosOrdenados
                  .find(
                    (est) =>
                      est.estudiante.estudianteId === notaPendiente.id
                  )
                  ?.notas.find(
                    (n) =>
                      n.notaSumCasilla === parseInt(casillaSumativa)
                  )
                  ?.notasAcu?.find(
                    (n) =>
                      n.notaAcuCasilla === parseInt(casillaAcumulativa)
                  )?.notaAcu || ""
              );
            } else {
              return (
                datosOrdenados
                  .find(
                    (est) =>
                      est.estudiante.estudianteId === notaPendiente.id
                  )
                  ?.notas.find(
                    (nota) =>
                      nota.notaSumCasilla === notaPendiente.campo
                  )?.notaSum || ""
              );
            }
          })()
        )}
        ?
        {typeof notaPendiente.campo === "string" &&
          notaPendiente.campo.includes("-") && (
            <p>
              (Nota Acumulativa casilla N°{
                notaPendiente.campo.split("-")[1]
              } de la casilla Sumativa N°{
                notaPendiente.campo.split("-")[0]
              })
            </p>
          )}
      </>
    ) : (
      <>
        {(() => {
          let notaActual;
          if (
            typeof notaPendiente.campo === "string" &&
            notaPendiente.campo.includes("-")
          ) {
            const [casillaSumativa, casillaAcumulativa] =
              notaPendiente.campo.split("-");
            notaActual = datosOrdenados
              .find((est) => est.estudiante.estudianteId === notaPendiente.id)
              ?.notas.find(
                (n) => n.notaSumCasilla === parseInt(casillaSumativa)
              )
              ?.notasAcu?.find(
                (n) => n.notaAcuCasilla === parseInt(casillaAcumulativa)
              )?.notaAcu;
          } else {
            notaActual = datosOrdenados
              .find((est) => est.estudiante.estudianteId === notaPendiente.id)
              ?.notas.find(
                (nota) =>
                  nota.notaSumCasilla === notaPendiente.campo
              )?.notaSum;
          }

          if (notaActual) {
            return `¿Desea cambiar la nota actual ${formatearNotaParaMensaje(
              notaActual
            )} por ${formatearNotaParaMensaje(notaPendiente.valor)}?`;
          } else {
            return `¿Desea guardar la calificación ${formatearNotaParaMensaje(
              notaPendiente.valor
            )}?`;
          }
        })()}
        {typeof notaPendiente.campo === "string" &&
          notaPendiente.campo.includes("-") && (
            <p>
              (Nota acumulativa {notaPendiente.campo.split("-")[1]} de la
              sumativa {notaPendiente.campo.split("-")[0]})
            </p>
          )}
      </>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="color-acento" onClick={confirmarCambioNota}>
      Confirmar
    </Button>
  </Modal.Footer>
</Modal>

<Modal
  centered
  show={mostrarModalAviso}
  onHide={() => setMostrarModalAviso(false)}
>
  <Modal.Header closeButton>
    <Modal.Title className="w-100 text-center">AVISO</Modal.Title>
  </Modal.Header>
  <Modal.Body className="text-center">{modalMensaje}</Modal.Body>
  <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
    {blockInputs && modalMensaje !== "No tienes permiso para expandir las calificaciones." && (
      <>
        <Button
          variant="outline-primary"
          onClick={() => {
            setMostrarModalAviso(false); // Cierra el modal de aviso
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="color-acento"
          onClick={() => {
            setMostrarModalAviso(false); // Cierra el modal de aviso
            setTimeout(() => {
              abrirModalAutenticacion(); // Abre el modal de autenticación
            }, 300); // Añade un pequeño retraso para asegurarse de que los modales no se superpongan visualmente
          }}
        >
          Desbloquear
        </Button>
      </>
    )}
  </Modal.Footer>
</Modal> */}