import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, DropdownDivider, Form, Row } from "react-bootstrap";
import PhoneNumberInput from "../../../../components/inputs/PhoneNumberInput";
import TextInput from "../../../../components/inputs/TextInput";
import RunInput from "../../../../components/inputs/RunInput";
import {
  faCirclePlus,
  faPlusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import EmailInput from "../../../../components/inputs/EmailInput";
import SelectSingle from "../../../../components/inputs/SelectSingle";
import IpeInput from "../../../../components/inputs/IpeInput";
import { formatearIPEIPA, formatearRUN } from "../../../../../util/util";
import {
  deleteApoderado,
  deleteAutorizadoRetiro,
  verificarApoderado,
} from "../../../../../services/PostsService";
import Swal from "sweetalert2";
import CustomDivider from "../../../../components/divider/CustomDivider";

function TabApoderado({
  valoresEstudiante,
  apoderadoTitular,
  setApoderadoTitular,
  apoderadosSuplentes,
  setApoderadosSuplentes,
  listaVinculos,
  listaNacionalidades,
  manejarCambioTexto,
  setErrors,
  errors,
  autorizadosRetirar,
  setAutorizadosRetirar,
}) {
  const autorizadosRetirarWrapRef = useRef([]);
  const apoderadosSuplentesWrapRef = useRef([]);

  const actualizarCampo = (index, field, value) => {
    setAutorizadosRetirar((prev) =>
      prev.map((autorizado, i) => {
        if (i === index) {
          // Crear un nuevo objeto con la estructura esperada
          const updatedAutorizado = {
            id: autorizado.id || null,
            run: autorizado.run || "",
            runDv: autorizado.runDv || "",
            runProvisorio: autorizado.runProvisorio || null,
            runProvisorioDv: autorizado.runProvisorioDv || null,
            nombreCompleto: autorizado.nombreCompleto || "",
            parentesco: autorizado.parentesco || "",
            fonoContacto: autorizado.fonoContacto || "",
          };

          // Actualizar el campo específico
          if (field === "run") {
            // Separar el RUN del dígito verificador
            updatedAutorizado.run = value.slice(0, -1);
            updatedAutorizado.runDv = value.slice(-1);
          } else if (field === "ipa") {
            // Separar el IPA del dígito verificador
            updatedAutorizado.runProvisorio = value.slice(0, -1);
            updatedAutorizado.runProvisorioDv = value.slice(-1);
          } else {
            updatedAutorizado[field] = value;
          }
          return updatedAutorizado;
        }
        return autorizado;
      })
    );
  };

  const handleBlur = useCallback(
    (identificacion, isTitular = true, index = null) => {
      // Función para cargar datos en el titular o en un suplente específico
      const cargarApoderado = (response, index = null) => {
        const apoderadoData = {
          // run: response.run,
          // ipaApoderado: response.ipaApoderado,
          apellidoPaternoApo: response.apellidoPaternoApo,
          apellidoMaternoApo: response.apellidoMaternoApo,
          nombresApo: response.nombresApo,
          nombresSecundariosApo: response.nombresSecundariosApo,
          vinculo: response.vinculo,
          telefonoMovil: response.telefonoMovil,
          emailApoderado: response.emailApoderado,
          tutorLegal: response.tutorLegal,
        };

        if (index === null) {
          // Cargar datos en el apoderado titular
          setApoderadoTitular((prevValues) => ({
            ...prevValues,
            ...apoderadoData,
          }));
        } else {
          // Cargar datos en un apoderado suplente específico
          setApoderadosSuplentes((prevValues) => {
            const nuevosApoderados = [...prevValues];
            nuevosApoderados[index] = {
              ...nuevosApoderados[index],
              ...apoderadoData,
            };
            return nuevosApoderados;
          });
        }
      };

      verificarApoderado(identificacion)
        .then((response) => {
          if (response) {
            Swal.fire({
              icon: "info",
              title: "Apoderado encontrado",
              html: `
              <section>
                <span>El apoderado</span>
                <span><strong>${response.nombresApo} ${response.apellidoPaternoApo}</strong></span>
                <span>ya se encuentra registrado en el sistema</span>
              </section>`,
              showCancelButton: true,
              confirmButtonText: "Cargar",
              confirmButtonColor: "var(--color-acento)",
              cancelButtonColor: "var(--color-eliminar)",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                cargarApoderado(response, isTitular ? null : index);
              }
            });
          }
        })
        .catch((error) => {
          console.error("Error al verificar el apoderado:", error);
        });
    },
    [setApoderadoTitular, setApoderadosSuplentes]
  );

  const agregarApoderadoSuplente = () => {
    if (apoderadosSuplentes) {
      setApoderadosSuplentes((prevApoderadosSuplentes) => [
        ...prevApoderadosSuplentes,
        {
          apoderadiId: null,
          run: "",
          runDv: "",
          apellidoPaternoApo: "",
          apellidoMaternoApo: "",
          nombresApo: "",
          nombresSecundariosApo: "",
          vinculo: "",
          telefonoMovil: "",
          emailApoderado: "",
          direccion: "",
          tutorLegal: false,
          ipaApoderado: "",
          ipaDv: "",
        },
      ]);
    }
  };

  const eliminarApoderadoSuplente = (index) => {
    const nuevosApoderados = apoderadosSuplentes.filter((_, i) => i !== index);
    // console.log(apoderadosSuplentes[index].apoderadoId)
    if (apoderadosSuplentes[index].apoderadoId) {
      deleteApoderado(apoderadosSuplentes[index].apoderadoId)
        .then((response) => {
          Swal.fire({
            icon: "warning",
            title: "Eliminar Apoderado",
            html: `
        <section>
        <span>Estás a punto de eliminar a</span>
        <p><strong>${apoderadosSuplentes[index].nombresApo} ${apoderadosSuplentes[index].apellidoPaternoApo}</strong></p>
        <span>como apoderado de:</span>
        <p><strong>${valoresEstudiante.nombres} ${valoresEstudiante.apellidoPaterno}</strong></p>
        <span>¿Estás seguro de que deseas eliminar a este apoderado?</span>
        </section>`,
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            confirmButtonColor: "var(--color-eliminar)",
            cancelButtonColor: "var(--color-acento)",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              setApoderadosSuplentes(nuevosApoderados);
              Swal.fire({
                toast: true,
                icon: "success",
                title: "Apoderado eliminado",
                text: "El apoderado ha sido eliminado correctamente",
                // confirmButtonColor: "var(--color-aceptar)",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
        })
        .catch((error) => {
          setApoderadosSuplentes(nuevosApoderados);
        });
    } else {
      setApoderadosSuplentes(nuevosApoderados);
    }
  };

  const agregarAutorizadoRetirar = () => {
    setAutorizadosRetirar((prev) => [
      ...prev,
      {
        id: null,
        run: "",
        runDv: "",
        runProvisorio: null,
        runProvisorioDv: null,
        nombreCompleto: "",
        parentesco: "",
        fonoContacto: "",
      },
    ]);
  };

  const eliminarAutorizadoRetirar = (index) => {
    // setAutorizadosRetirar((prev) => prev.filter((_, i) => i !== index));
    console.log(autorizadosRetirar[index].id);
    if (autorizadosRetirar[index] && autorizadosRetirar[index].id) {
      deleteAutorizadoRetiro(autorizadosRetirar[index].id)
        .then((response) => {
          Swal.fire({
            icon: "warning",
            title: "Eliminar Autorizado",
            html: `
          <section>
          <span>Estás a punto de eliminar a</span>
          <p><strong>${autorizadosRetirar[index].nombreCompleto}</strong></p>
          <span>como autorizado de:</span>
          <p><strong>${valoresEstudiante.nombres} ${valoresEstudiante.apellidoPaterno}</strong></p>
          <span>¿Estás seguro de que deseas eliminar a este autorizado?</span>
          </section>`,
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            confirmButtonColor: "var(--color-eliminar)",
            cancelButtonColor: "var(--color-acento)",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              setAutorizadosRetirar((prev) =>
                prev.filter((_, i) => i !== index)
              );
              Swal.fire({
                toast: true,
                icon: "success",
                title: "Autorizado eliminado",
                text: "El autorizado ha sido eliminado correctamente",
                // confirmButtonColor: "var(--color-aceptar)",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
        })
        .catch((error) => {
          // setAutorizadosRetirar((prev) => prev.filter((_, i) => i !== index));
        });
    }
  };

  const actualizarCampoAutorizado = (index, field, value) => {
    setAutorizadosRetirar((prev) =>
      prev.map((autorizado, i) => {
        if (i === index) {
          const updatedAutorizado = { ...autorizado, [field]: value };
          return updatedAutorizado;
        }
        return autorizado;
      })
    );
  };

  // // Función para actualizar el RUN y su DV en el estado
  // const actualizarCampoRun = (index, valor) => {
  //   // Elimina cualquier formato y divide el RUN y el DV
  //   const runSinFormato = valor.replace(/\D/g, ""); // Elimina caracteres no numéricos
  //   const run = runSinFormato.slice(0, -1); // Todo excepto el último dígito
  //   const runDv = runSinFormato.slice(-1); // Último dígito como DV

  //   setAutorizadosRetirar((prev) => {
  //     const nuevosAutorizados = [...prev];
  //     nuevosAutorizados[index] = {
  //       ...nuevosAutorizados[index],
  //       run,
  //       runDv,
  //     };
  //     return nuevosAutorizados;
  //   });
  // };

  // const actualizarCampoIpe = (index, valor) => {
  //   const ipeSinFormato = valor.replace(/\D/g, ""); // Elimina caracteres no numéricos
  //   const runProvisorio = ipeSinFormato.slice(0, -1); // Todo excepto el último dígito
  //   const runProvisorioDv = ipeSinFormato.slice(-1); // Último dígito como DV

  //   setAutorizadosRetirar((prev) => {
  //     const nuevosAutorizados = [...prev];
  //     nuevosAutorizados[index] = {
  //       ...nuevosAutorizados[index],
  //       runProvisorio,
  //       runProvisorioDv,
  //     };
  //     return nuevosAutorizados;
  //   });
  // };

  // const eliminarAutorizadoRetirar = (index) => {
  //   const nuevosAutorizados = autorizadosRetirar.filter((_, i) => i !== index);
  //   setAutorizadosRetirar(nuevosAutorizados);
  // };
  const manejarCambioTextoSuplente = useCallback(
    (id, value, setValue, index) => {
      if (
        [
          "apellidoPaternoApo",
          "apellidoMaternoApo",
          "nombresApo",
          "nombresSecundariosApo",
        ].includes(id)
      ) {
        value = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s'-]/g, "");
      }

      setValue((prevValues) => {
        const nuevosApoderados = [...prevValues];
        if (nuevosApoderados[index]) {
          if (["ipaApoderado", "ipaApoderadoSuplente"].includes(id)) {
            value = value.replace(/[^0-9Kk]/g, "");
            if (value.length > 1) {
              const ipaWithoutDv = value.slice(0, -1);
              const dv = value.slice(-1).toUpperCase();
              value = `${ipaWithoutDv}-${dv}`;
            }
          }
          nuevosApoderados[index] = {
            ...nuevosApoderados[index],
            [id]: value,
          };
        }
        return nuevosApoderados;
      });

      // Validar cuando se ingresa el RUN y el vinculo está vacío
      if (id === "run" && value) {
        const apoderado = apoderadosSuplentes[index];
        if (!apoderado.vinculo) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [`vinculo-suplente-${index}`]: `El vínculo del apoderado suplente Nro. ${
              index + 1
            } es obligatorio`,
          }));
        } else {
          // Remover el error si ya existe un vínculo seleccionado
          setErrors((prevErrors) => {
            const { [`vinculo-suplente-${index}`]: _, ...rest } = prevErrors;
            return rest;
          });
        }
      }
    },
    [setErrors, apoderadosSuplentes]
  );

  const validarVinculoTitular = useCallback(() => {
    if (!apoderadoTitular.vinculo && apoderadoTitular.run) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        vinculoTitular: "El vínculo del apoderado titular es obligatorio",
      }));
    } else {
      setErrors((prevErrors) => {
        const { vinculoTitular, ...rest } = prevErrors;
        return rest;
      });
    }

    if (apoderadoTitular.run !== "") handleBlur(apoderadoTitular.run);
    // if(apoderadoTitular.ipaApoderado !== '') handleBlur(apoderadoTitular.ipaApoderado);
  }, [apoderadoTitular.run, apoderadoTitular.vinculo, handleBlur, setErrors]);

  // Efecto para hacer scroll al último autorizado después de que se agregue uno nuevo
  useEffect(() => {
    if (autorizadosRetirar.length > 0) {
      const lastIndex = autorizadosRetirar.length - 1;
      if (autorizadosRetirarWrapRef.current[lastIndex]) {
        autorizadosRetirarWrapRef.current[lastIndex].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }

    if (apoderadosSuplentes.length > 0) {
      const lastIndex = apoderadosSuplentes.length - 1;
      if (apoderadosSuplentesWrapRef.current[lastIndex]) {
        apoderadosSuplentesWrapRef.current[lastIndex].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [apoderadosSuplentes.length, autorizadosRetirar]);

  return (
    <Form
      className="d-flex flex-row"
      style={{ height: "75dvh", width: "100%" }}
    >
      <article
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <section
          style={{
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            scrollbarWidth: "thin",
            position: "relative",
            padding: "0 1rem",
            height: "100%",
          }}
        >
          <h5 style={{ color: "gray" }}>Titular</h5>

          {/* Formulario del Apoderado Titular */}
          <Row className="mb-3 pt-4">
            <Col md={2}>
              <RunInput
                controlId="runTitular"
                value={formatearRUN(apoderadoTitular.run) || ""}
                placeholder="XX.XXX.XXX-X"
                errors={errors}
                setErrors={setErrors}
                setValores={setApoderadoTitular}
                maxLength={12}
                style={{ width: "100%" }}
                required
                label={"RUN"}
                statePath="run" // Actualiza directamente el objeto anidado
                onBlur={validarVinculoTitular}
              />
            </Col>
            <Col md={2}>
              <IpeInput
                controlId="ipaTitular"
                value={formatearIPEIPA(apoderadoTitular.ipaApoderado)}
                placeholder="200.XXX.XXX-X"
                errors={errors}
                setErrors={setErrors}
                setValores={setApoderadoTitular}
                maxLength={13}
                style={{ width: "100%" }}
                required
                label={"IPA"}
                statePath="ipaApoderado" // Actualiza directamente el objeto anidado
                disabled={apoderadoTitular.run ? true : false}
                onBlur={() => {
                  if (apoderadoTitular.ipaApoderado !== "")
                    handleBlur(apoderadoTitular.ipaApoderado);
                }}
              />
            </Col>
            <Col md={2}>
              <TextInput
                controlId="apellidoPaternoApo"
                type="text"
                value={apoderadoTitular.apellidoPaternoApo || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "apellidoPaternoApo",
                    e.target.value,
                    setApoderadoTitular,
                    setErrors
                  )
                }
                maxLength={25}
                label="Apellido Paterno"
              />
            </Col>
            <Col md={2}>
              <TextInput
                controlId="apellidoMaternoApo"
                type="text"
                value={apoderadoTitular.apellidoMaternoApo || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "apellidoMaternoApo",
                    e.target.value,
                    setApoderadoTitular,
                    setErrors
                  )
                }
                maxLength={25}
                label="Apellido Materno"
              />
            </Col>
            <Col md={2}>
              <TextInput
                controlId="nombresApo"
                type="text"
                value={apoderadoTitular.nombresApo || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "nombresApo",
                    e.target.value,
                    setApoderadoTitular,
                    setErrors
                  )
                }
                maxLength={25}
                label="Nombre Primario"
              />
            </Col>
            <Col md={2}>
              <TextInput
                controlId="nombresSecundariosApo"
                type="text"
                value={apoderadoTitular.nombresSecundariosApo || ""}
                onChange={(e) =>
                  manejarCambioTexto(
                    "nombresSecundariosApo",
                    e.target.value,
                    setApoderadoTitular,
                    setErrors
                  )
                }
                maxLength={25}
                label="Nombre Secundario"
              />
            </Col>
          </Row>

          <Row className="mb-2">
            <Col md={2}>
              <SelectSingle
                controlId="vinculoTitular"
                placeholder="Vínculo"
                elements={listaVinculos}
                idKey="id"
                labelKey="name"
                onSelect={(vinculo) =>
                  setApoderadoTitular((prevValues) => ({
                    ...prevValues,
                    vinculo: vinculo ? vinculo.id : null,
                  }))
                }
                selectedElement={apoderadoTitular.vinculo}
                required={!!apoderadoTitular.run}
              />
            </Col>
            <Col md={3}>
              <PhoneNumberInput
                controlId="telefonoMovil"
                onChange={(value) =>
                  setApoderadoTitular((prevValues) => ({
                    ...prevValues,
                    telefonoMovil: value,
                  }))
                }
                maxLength={15}
                countries={listaNacionalidades || []}
                disabled={false}
                value={apoderadoTitular.telefonoMovil || ""}
              />
            </Col>
            <Col md={4}>
              <EmailInput
                controlId="emailApoderado"
                value={apoderadoTitular.emailApoderado}
                onChange={(e) =>
                  manejarCambioTexto(
                    "emailApoderado",
                    e.target.value,
                    setApoderadoTitular,
                    setErrors
                  )
                }
                maxLength={50}
                label="Email"
              />
            </Col>
          </Row>
          {apoderadosSuplentes.length > 0 ? <CustomDivider /> : <br />}
          <span
            onClick={(e) => {
              e.preventDefault();
              agregarApoderadoSuplente();
            }}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: apoderadosSuplentes.length < 3 ? "block" : "none",
              cursor: "pointer",
              color: "var(--color-acento)",
              padding: ".5rem 1rem",
              // backgroundColor: 'red',
              width: "fit-content",
            }}
            // disabled
            // variant="color-acento-outline"
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Apoderado Suplente
          </span>
          {apoderadosSuplentes.length > 0 && (
            <section>
              <div className="d-flex gap-5 align-items-center">
                <h5 style={{ marginTop: ".5rem", color: "gray" }}>Suplentes</h5>
              </div>
              {apoderadosSuplentes.map((apoderado, index) => (
                <div
                  key={index}
                  ref={(el) => (apoderadosSuplentesWrapRef.current[index] = el)}
                  style={{ position: "relative" }}
                >
                  <span
                    variant="color-eliminar-outline"
                    onClick={() => eliminarApoderadoSuplente(index)}
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "25px",
                      cursor: "pointer",
                      color: "var(--color-eliminar)",
                    }}
                  >
                    <FontAwesomeIcon size="2xl" icon={faTrashAlt} />
                  </span>

                  <Row className="mb-3 pt-4">
                    <Col md={2}>
                      <RunInput
                        controlId={`runApoderado-${index}`}
                        value={formatearRUN(apoderado.run) || ""}
                        placeholder="XX.XXX.XXX-X"
                        errors={errors}
                        setErrors={setErrors}
                        setValores={setApoderadosSuplentes}
                        maxLength={12}
                        required
                        label="RUN"
                        statePath="run"
                        index={index}
                        onBlur={() => {
                          // Verificar si el vinculo es obligatorio
                          if (!apoderado.vinculo) {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              [`vinculo-suplente-${index}`]: `El vínculo del apoderado suplente Nro. ${
                                index + 1
                              } es obligatorio`,
                            }));
                          }

                          // if (apoderado.run !== "") handleBlur(apoderado.run);
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <IpeInput
                        controlId={`ipaApoderado-${index}`}
                        value={formatearIPEIPA(apoderado.ipaApoderado) || ""}
                        placeholder="200.XXX.XXX-X"
                        errors={errors}
                        setErrors={setErrors}
                        setValores={setApoderadosSuplentes}
                        maxLength={13}
                        required
                        label="IPA"
                        statePath="ipaApoderado"
                        disabled={!!apoderado.run}
                        index={index}
                        onBlur={() => {
                          // Verificar si el vinculo es obligatorio
                          // if (apoderado.ipaApoderado !== "")
                          //   handleBlur(apoderado.ipaApoderado);
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <TextInput
                        controlId={`apellidoPaternoApo-${index}`}
                        type="text"
                        value={apoderado.apellidoPaternoApo || ""}
                        onChange={(e) =>
                          manejarCambioTextoSuplente(
                            "apellidoPaternoApo",
                            e.target.value,
                            setApoderadosSuplentes,
                            index
                          )
                        }
                        maxLength={25}
                        label="Apellido Paterno"
                      />
                    </Col>
                    <Col md={2}>
                      <TextInput
                        controlId={`apellidoMaternoApo-${index}`}
                        type="text"
                        value={apoderado.apellidoMaternoApo || ""}
                        onChange={(e) =>
                          manejarCambioTextoSuplente(
                            "apellidoMaternoApo",
                            e.target.value,
                            setApoderadosSuplentes,
                            index
                          )
                        }
                        maxLength={25}
                        label="Apellido Materno"
                      />
                    </Col>
                    <Col md={2}>
                      <TextInput
                        controlId={`nombresApo-${index}`}
                        type="text"
                        value={apoderado.nombresApo || ""}
                        onChange={(e) =>
                          manejarCambioTextoSuplente(
                            "nombresApo",
                            e.target.value,
                            setApoderadosSuplentes,
                            index
                          )
                        }
                        maxLength={25}
                        label="Nombre Primario"
                      />
                    </Col>
                    <Col md={2}>
                      <TextInput
                        controlId={`nombresSecundariosApo-${index}`}
                        type="text"
                        value={apoderado.nombresSecundariosApo || ""}
                        onChange={(e) =>
                          manejarCambioTextoSuplente(
                            "nombresSecundariosApo",
                            e.target.value,
                            setApoderadosSuplentes,
                            index
                          )
                        }
                        maxLength={25}
                        label="Nombre Secundario"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col md={2}>
                      <SelectSingle
                        controlId={`vinculo-${index}`}
                        placeholder="Vínculo"
                        elements={listaVinculos}
                        idKey="id"
                        labelKey="name"
                        onSelect={(vinculo) =>
                          setApoderadosSuplentes((prevValues) => {
                            const nuevosApoderados = [...prevValues];
                            nuevosApoderados[index].vinculo = vinculo
                              ? vinculo.id
                              : null;

                            if (!vinculo) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                [`vinculo-suplente-${index}`]: `El vínculo del apoderado suplente Nro. ${
                                  index + 1
                                } es obligatorio`,
                              }));
                            } else {
                              setErrors((prevErrors) => {
                                const {
                                  [`vinculo-suplente-${index}`]: _,
                                  ...rest
                                } = prevErrors;
                                return rest;
                              });
                            }
                            return nuevosApoderados;
                          })
                        }
                        selectedElement={apoderado.vinculo}
                        isInvalid={!!errors[`vinculo-suplente-${index}`]}
                        required={!!apoderado.run || !!apoderado.ipaApoderado}
                      />
                    </Col>
                    <Col md={3}>
                      <PhoneNumberInput
                        controlId={`telefonoFijo-${index}`}
                        onChange={(value) =>
                          setApoderadosSuplentes((prevValues) => {
                            const nuevosApoderados = [...prevValues];
                            nuevosApoderados[index].telefonoMovil = value;
                            return nuevosApoderados;
                          })
                        }
                        value={apoderado.telefonoMovil || ""}
                        maxLength={15}
                        countries={listaNacionalidades || []}
                        selectedValue={apoderado.telefonoMovil || ""}
                        disabled={false}
                      />
                    </Col>
                    <Col md={4}>
                      <EmailInput
                        controlId={`emailApoderado-${index}`}
                        value={apoderado.emailApoderado || ""}
                        onChange={(e) =>
                          manejarCambioTextoSuplente(
                            "emailApoderado",
                            e.target.value,
                            setApoderadosSuplentes,
                            index
                          )
                        }
                        maxLength={50}
                        label="Email"
                      />
                    </Col>
                    {/* <Col
                      md={1}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "3rem",
                        marginTop: "1.7rem",
                      }}
                    >
                      
                    </Col> */}
                  </Row>
                </div>
              ))}
            </section>
          )}

          <CustomDivider />
          <section
            style={{
              display: "flex",
              width: "100%",
              // backgroundColor: "red",
              // padding: "0 0rem",
              flexDirection: "column",
              gap: ".5rem",
              scrollbarWidth: "thin",
              // borderLeft: "1px solid lightgray",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                // justifyContent: "space-between",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <h5 style={{ color: "gray" }}>
                Adultos autorizados para retirar
              </h5>
              <span
                // disabled
                // variant="color-acento-outline"
                onClick={agregarAutorizadoRetirar}
                style={{
                  width: "20%",
                  display: autorizadosRetirar.length < 3 ? "block" : "none",
                  // padding: '1rem',
                  cursor: "pointer",
                  color: "var(--color-acento)",
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faCirclePlus} /> Adulto Autorizado{" "}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                gap: "1rem",
              }}
            >
              {autorizadosRetirar.map((autorizado, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    width: "33%",
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    padding: "1rem 1rem",
                  }}
                >
                  <span
                    onClick={() => eliminarAutorizadoRetirar(index)}
                    style={{
                      position: "absolute",
                      bottom: "45px",
                      right: "45px",
                      cursor: "pointer",
                      color: "var(--color-eliminar)",
                    }}
                  >
                    <FontAwesomeIcon size="xl" icon={faTrashAlt} />
                  </span>

                  <Row className="w-100 gap-1">
                    <Col>
                      <RunInput
                        controlId={`runAutorizado-${index}`}
                        tooltipMessage="RUN del adulto autorizado"
                        label="RUN"
                        name="run"
                        value={autorizado.run || ""}
                        setValores={setAutorizadosRetirar}
                        placeholder="XX.XXX.XXX-X"
                        errors={errors}
                        setErrors={setErrors}
                        index={index} // Pasa el índice para manejar el array
                        statePath="run" // El campo específico a actualizar dentro del objeto autorizado
                        noValidate
                      />
                    </Col>
                    <Col>
                      <IpeInput
                        controlId={`ipa-${index}`}
                        tooltipMessage="IPA del adulto autorizado"
                        label="IPA"
                        name="runProvisorio"
                        value={autorizado.runProvisorio || ""}
                        setValores={setAutorizadosRetirar}
                        placeholder="200.XXX.XXX-X"
                        errors={errors}
                        setErrors={setErrors}
                        index={index} // Pasa el índice para manejar el array
                        statePath="runProvisorio" // El campo específico a actualizar dentro del objeto autorizado
                        noValidate
                      />
                    </Col>
                  </Row>

                  <Row
                    className="w-100"
                    style={{ display: "flex", gap: "1rem" }}
                  >
                    <Col md={12}>
                      <TextInput
                        controlId={`nombre-${index}`}
                        type="text"
                        value={autorizado.nombreCompleto || ""}
                        placeholder="Nombre"
                        label="Nombre Completo"
                        onChange={(e) =>
                          actualizarCampoAutorizado(
                            index,
                            "nombreCompleto",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col md={12}>
                      <TextInput
                        controlId={`parentesco-${index}`}
                        type="text"
                        value={autorizado.parentesco || ""}
                        placeholder="Parentesco"
                        label="Parentesco"
                        onChange={(e) =>
                          actualizarCampoAutorizado(
                            index,
                            "parentesco",
                            e.target.value
                          )
                        }
                      />
                    </Col>
                    <Col md={8}>
                      <PhoneNumberInput
                        controlId={`telefono-${index}`}
                        onChange={(value) =>
                          actualizarCampoAutorizado(
                            index,
                            "fonoContacto",
                            value
                          )
                        }
                        maxLength={15}
                        countries={listaNacionalidades || []}
                        disabled={false}
                        value={autorizado.fonoContacto || ""}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </section>
        </section>
      </article>
    </Form>
  );
}

export default TabApoderado;
