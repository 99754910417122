import React from 'react';
import { Button } from 'react-bootstrap';
import { IoIosArrowForward } from 'react-icons/io';
import "../../../assets/css/buttonsFlechas.css";

const ButtonFlechaAdelante = ({ onClick, className, variant, ...props }) => {
  return (
    <Button
      onClick={onClick}
      className={`boton-flecha btn-color-acento ${className}`}
      {...props}
    >
      <IoIosArrowForward />
    </Button>
  );
};

export default ButtonFlechaAdelante;
