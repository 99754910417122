import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import { Button } from "react-bootstrap";


const Error404 = () => {
    const navigate = useNavigate();
  return (
    <div className="fix-wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="form-input-content text-center error-page ">
              <section className="d-flex justify-content-center py-3 " >
                <Logo />
              </section>

              <h5 style={{ fontSize: '2rem'}}>
                <i className="fa fa-exclamation-triangle text-warning" /> No se encontró la página <i className="fa fa-exclamation-triangle text-warning" />
              </h5>
              <p>Es posible que hayas escrito mal la dirección</p>
              <div>
                <Button variant="color-acento" onClick={()=> navigate('/horario-vista') }>
                  Volver
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
