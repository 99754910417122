import React, { useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";

function TextInputMultiple({
  label,
  name,
  onChange,
  required,
  disabled,
  placeholder,
  onBlur,
  onInput,
  isInvalid,
  maxLength,
  onPaste,
  type,
  tooltipMessage,
  controlId,
  withHours // Nueva prop para controlar la renderización de inputs de horas
}) {
  const [inputs, setInputs] = useState([]);

  const handleAddInput = () => {
    const newInput = { id: Date.now(), value: "" };
    if (withHours) {
      newInput.horas = ["", "", "", ""];
    }
    if (inputs.length < 5) {
      setInputs([...inputs, newInput]);
    }
  };

  const handleRemoveInput = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
    if (onChange) {
      onChange(newInputs.map((input) => input.value));
    }
  };

  const handleInputChange = (index, event) => {
    const newInputs = [...inputs];
    newInputs[index].value = event.target.value;
    setInputs(newInputs);
    if (onChange) {
      onChange(newInputs.map((input) => input.value));
    }
  };

  const handleHourChange = (inputIndex, hourIndex, event) => {
    const newInputs = [...inputs];
    if (!newInputs[inputIndex].horas) {
      newInputs[inputIndex].horas = ["", "", "", ""]; // Asegúrate de que `horas` esté inicializado
    }
    newInputs[inputIndex].horas[hourIndex] = event.target.value;
    setInputs(newInputs);
  };

  return (
    <Form.Group controlId={controlId}>
      <Form.Label>
        {label}{" "}
        {required && !tooltipMessage && <span className="text-danger">*</span>}
      </Form.Label>

      <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: '1rem' }}>
        <Button
          variant="outline-primary"
          onClick={handleAddInput}
          disabled={ disabled|| inputs.length >= 5}
        >
          + Agregar
        </Button>
      </div>

      <div style={{ width: '70%' }}>
        {inputs.map((input, index) => (
          <InputGroup key={input.id} className="mb-2 align-items-center">
            <Button
              variant="outline-warning"
              onClick={() => handleRemoveInput(index)}
              style={{
                marginRight: '10px', 
                borderRadius: '50%', 
                width: '35px', 
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0'
              }}
            >
              &times;
            </Button>
            <Form.Control
              type={type}
              name={`${name}-${index}`}
              value={input.value || ""}
              onChange={(e) => handleInputChange(index, e)}
              required={required}
              disabled={disabled}
              placeholder={placeholder}
              onBlur={onBlur}
              onInput={onInput}
              isInvalid={isInvalid}
              maxLength={maxLength}
              onPaste={onPaste}
              className="custom-input-width"
              style={{ borderRadius: '5px', height: '35px' }}
            />
            {withHours && (
              <div style={{ display: 'flex', gap: '5px', marginTop: '10px' }}>
                {[...Array(4)].map((_, hourIndex) => (
                  <Form.Control
                    key={hourIndex}
                    type="time"
                    value={input.horas ? input.horas[hourIndex] : ""} // Asegúrate de que `horas` esté definido
                    onChange={(e) => handleHourChange(index, hourIndex, e)}
                    style={{ width: '90px', height: '25px', fontSize: '12px' }}
                  />
                ))}
              </div>
            )}
          </InputGroup>
        ))}
      </div>
    </Form.Group>
  );
}

export default TextInputMultiple;
