import React, { useEffect, useState } from 'react';
import { Button, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import useSaveAsistenciaSubvencion from '../../../hooks/useSaveAsistenciaSubvencion';
import obtenerColorMapaCalor from '../../../util/utilidadesMapaCalor';
import estilosEstado from '../../objetoEstilos/estiloEstados';
import { estilosEstadoAdicionales } from "../../objetoEstilos/estiloEstudiante";
import Avatar from '../avatar/Avatar';
import ModalAutenticacion from '../confirmarFirma/ModalAutenticacion';
import NotificacionToast from '../notificacion/NotificacionToast';
import ModalCambioEstadoSubvencion from './ModalCambioEstadoSubvencion';

const estilos = {
  encabezado: {
    backgroundColor: "var(--color-header)",
    color: "var(--color-texto-oscuro)",
    fontWeight: 500,
    position: 'sticky',
    top: -2,
    zIndex: 10,
    textAlign: 'center',
    verticalAlign: 'middle',
    borderBottom: '1px solid #e6e6e6',
    borderTop: '1px solid #e6e6e6',
    padding: 0,
    margin: 0,
  },
  encabezadoFinDeSemana: {
    color: 'red',
    position: 'sticky',
    top: -2,
    zIndex: 10,
    textAlign: 'center',
    verticalAlign: 'middle',
    borderBottom: '1px solid #e6e6e6',
    borderTop: '1px solid #e6e6e6',
    padding: "0px",
    margin: "0px",
  },
  encabezadoFinDeSemanaIzquierdo: {
    borderLeft: '1px solid var(--color-secundario)',
  },
  encabezadoFinDeSemanaDerecho: {
    borderRight: '1px solid #e6e6e6',
  },
  encabezadoFinDeSemanaCentro: {
    borderRight: '1px solid var(--color-secundario)',
  },
  contenedorTabla: {
    maxHeight: '74dvh',
    overflowY: 'auto',
    backgroundColor: 'white',
    marginTop: '10px',
  },
  celda: {
    height: '40px',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: 0,
    margin: 0,
    borderTop: '1px solid #e6e6e6',
    borderBottom: '1px solid #e6e6e6',
    borderLeft: '1px solid #e6e6e6',
    borderRight: '1px solid #e6e6e6',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '12px',
    cursor: 'pointer',
    position: 'relative',
  },
  celdaSinBordeDerecho: {
    borderRight: 'none',
  },
  celdaSinBordeIzquierdo: {
    borderLeft: 'none',
  },
  celdaFinDeSemana: {
    backgroundColor: 'var(--color-secundario)',
    borderTop: '1px solid var(--color-secundario)',
    borderBottom: '1px solid var(--color-secundario)',
    cursor: 'default',
  },
  celdaFinDeSemanaIzquierdo: {
    borderLeft: '1px solid var(--color-secundario)',
  },
  celdaFinDeSemanaDerecho: {
    borderRight: '1px solid #e6e6e6',
  },
  celdaFinDeSemanaCentro: {
    borderRight: '1px solid var(--color-secundario)',
  },
  textoEstudiante: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: 'rgb(136, 136, 136)',
  },
  textoEstudianteCelda: {
    display: 'inline-block',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    color: 'rgb(136, 136, 136)',
    height: '100%',
    whiteSpace: 'nowrap',        
    overflow: 'hidden',           
    textOverflow: 'ellipsis',     
    maxWidth: '170px',            
  },
  avatar: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  bordeIzquierdoBlanco: {
    borderLeft: '1px solid white',
  },
  bordeDerechoBlanco: {
    borderRight: '1px solid white',
  },
  filaHover: {
    backgroundColor: "var(--color-doble-hover)",
  },
  celdaEstadoHover: {
    cursor: 'pointer',
    backgroundColor: 'var(--color-doble-hover)',
  },
  icono: {
    fontSize: '1.4rem',
  },
  lineaRoja: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    borderBottom: '2px solid red',
    zIndex: 9,
  },
  celdaInvalida: {
    backgroundColor: 'var(--color-doble-hover)',
    cursor: 'not-allowed',
  },
  conteoDiarioFila: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  celdaFinDeSemanaSinNumero: {
    backgroundColor: 'var(--color-secundario)',
    borderTop: '1px solid var(--color-secundario)',
    borderBottom: '1px solid var(--color-secundario)',
    cursor: 'default',
    color: 'transparent', 
  },
};

const formatearFecha = (dia, mes, año) => {
  const fecha = new Date(año, mes - 1, dia);
  const opcionesDia = { day: '2-digit' };
  const opcionesDiaSemana = { weekday: 'short' };
  const formattedDay = fecha.toLocaleDateString('es-ES', opcionesDia).replace('.', '').toUpperCase();
  let formattedDayOfWeek = fecha.toLocaleDateString('es-ES', opcionesDiaSemana).replace('.', '').toUpperCase().slice(0, 2);
  if (formattedDayOfWeek === 'SÁ') {
    formattedDayOfWeek = 'SA';
  }
  return {
    day: formattedDay,
    dayOfWeek: formattedDayOfWeek
  };
};

const esFinDeSemana = (dia, mes, año) => {
  const fecha = new Date(año, mes - 1, dia);
  const diaSemana = fecha.getDay();
  return diaSemana === 0 || diaSemana === 6;
};

const parseEstado = (estadoId) => {
  switch (estadoId) {
    case 0:
      return 'pendiente';
    case 1:
      return "presente";
    case 2:
      return "ausente";
    case 3:
      return "atrasado";
    case 4:
      return "retirado";
    default:
      return "";
  }
};

const estadosMap = {
  presente: 1,
  ausente: 2,
  atrasado: 3,
  retirado: 4,
  pendiente: 0
};

const esAntesDeFechaMatricula = (dia, mes, año, fechaMatricula) => {
  const fechaClase = new Date(año, mes - 1, dia);
  return fechaClase < new Date(fechaMatricula);
};

const TablaAsistenciaMensualSubvencion = ({ datosAsistencia, mes, conteoDiario: conteoDiarioProp, isHeatmapActive, isSoloDocente }) => {
  const [filaHover, setFilaHover] = useState(null);
  const [columnaHover, setColumnaHover] = useState(null);
  const [modalCambioEstadoVisible, setModalCambioEstadoVisible] = useState(false);
  const [modalAvisoVisible, setModalAvisoVisible] = useState(false);
  const [modalAutenticacionVisible, setModalAutenticacionVisible] = useState(false);
  const [estudianteSeleccionado, setEstudianteSeleccionado] = useState(null);
  const [diaSeleccionado, setDiaSeleccionado] = useState(null);
  const [estadoActual, setEstadoActual] = useState(null);
  const [nuevoEstado, setNuevoEstado] = useState(null);
  const [notificacion, setNotificacion] = useState({ mostrar: false, mensaje: '' });
  const [asistenciaActualizada, setAsistenciaActualizada] = useState([...datosAsistencia]);
  const [conteoDiario, setConteoDiario] = useState([...conteoDiarioProp]); // Cambiamos el nombre de la prop aquí
  const [mensajeToast, setMensajeToast] = useState('');
  const [mostrarToast, setMostrarToast] = useState(false);
  const [mostrarTooltipGlobal, setMostrarTooltipGlobal] = useState(false);
  const [razonesError, setRazonesError] = useState([]);
  const [countdown, setCountdown] = useState(10);


  const año = new Date().getFullYear();
  const diasEnElMes = new Date(año, mes, 0).getDate();
  const diasDelMes = Array.from({ length: diasEnElMes }, (_, i) => i + 1);

  const { saveAsistencia } = useSaveAsistenciaSubvencion();

// console.log("es solo docente?:", isDocente)


  const mostrarNotificacion = (mensaje) => {
    setMensajeToast(mensaje);
    setMostrarToast(true);
    setTimeout(() => setMostrarToast(false), 3000);
  };

  const manejarMouseEnter = (filaIndex, columnaIndex, esAntesDeMatricula) => {
    setFilaHover(filaIndex);
    setColumnaHover(columnaIndex);
    if (esAntesDeMatricula) {
      setMostrarTooltipGlobal(true);
    }
  };

  const manejarMouseLeave = () => {
    setFilaHover(null);
    setColumnaHover(null);
    setMostrarTooltipGlobal(false);
  };

  const validarEstudiante = (estudiante) => {
    let razones = [];
    if (!estudiante.numLista) razones.push("Sin número de lista");
    if (!estudiante.numMatricula) razones.push("Sin número de matrícula");
    if (!estudiante.fechaMatricula || estudiante.fechaMatricula === 'null') razones.push("Sin fecha de matrícula");
    return razones;
  };

  const manejarClick = (estudiante, dia, estado) => {
    if (!isSoloDocente) { 
      setEstudianteSeleccionado(estudiante);
      setDiaSeleccionado(dia);
      setEstadoActual(parseEstado(estado));
      setModalCambioEstadoVisible(true);
    }
  };

  const manejarGuardarCambioEstado = (nuevoEstadoSeleccionado) => {
    setNuevoEstado(nuevoEstadoSeleccionado);
    const razones = validarEstudiante(estudianteSeleccionado);
    if (razones.length > 0) {
      setRazonesError(razones);
      setModalCambioEstadoVisible(false);
      setModalAvisoVisible(true);
      setCountdown(10);
    } else {
      setModalCambioEstadoVisible(false);
      setModalAutenticacionVisible(true);
    }
  };

  const manejarCierreModalAviso = () => {
    setModalAvisoVisible(false);
    setModalAutenticacionVisible(true);
  };

  const manejarConfirmacionAutenticacion = async () => {
    const estadoId = estadosMap[nuevoEstado];
    try {
      const response = await saveAsistencia(
        estadoId,
        estudianteSeleccionado.cursoId,
        estudianteSeleccionado.id,
        mes,
        diaSeleccionado
      );

      mostrarNotificacion('Asistencia guardada correctamente');
      setModalAutenticacionVisible(false);

      // Actualizar la asistencia del estudiante seleccionado
      const datosAsistenciaActualizados = asistenciaActualizada.map((est) => {
        if (est.id === estudianteSeleccionado.id) {
          // Actualizar las asistencias del estudiante para los días del mes
          const asistenciasActualizadas = {
            ...est.asistencias,
            ...response.asistencia.asistencias, // Actualizar los días con el nuevo estado
          };

          return {
            ...est,
            asistencias: asistenciasActualizadas,
            conteoTotalMensual: response.asistencia.conteoTotalMensual, // Actualiza el conteo total del mes
            porcentajeTotalMensual: response.asistencia.porcentajeTotalMensual, // Actualiza el porcentaje del mes
          };
        }
        return est;
      });

      // Actualizar el conteo diario
      const conteoDiarioActualizado = conteoDiario.map((diaConteo) => {
        if (diaConteo.dia === response.conteoDiario.dia && response.conteoDiario.mes === mes) {
          return {
            ...diaConteo,
            conteoDiario: response.conteoDiario.conteoDiario, // Actualiza el conteo diario
          };
        }
        return diaConteo;
      });

      setAsistenciaActualizada(datosAsistenciaActualizados);
      setConteoDiario(conteoDiarioActualizado); // Actualiza el estado del conteo diario

    } catch (error) {
      console.error('Error al guardar la asistencia:', error);
    }
  };
  
  useEffect(() => {
    let timer;
    if (countdown > 0 && modalAvisoVisible) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      manejarCierreModalAviso();
    }
    return () => clearTimeout(timer);
  }, [countdown, modalAvisoVisible]);

  const columnasFinDeSemana = diasDelMes.map(dia => {
    return datosAsistencia.some(estudiante => estudiante.asistencias[`asistenciaEstado${String(dia).padStart(2, '0')}`] === -2);
  });

  const estudiantesOrdenados = [...asistenciaActualizada].sort((a, b) => {
    if (a.numLista !== null && b.numLista === null) return -1;
    if (a.numLista === null && b.numLista !== null) return 1;
    if (a.numLista !== null && b.numLista !== null) return a.numLista - b.numLista;
    if (a.numMatricula !== null && b.numMatricula === null) return -1;
    if (a.numMatricula === null && b.numMatricula !== null) return 1;
    return 0;
  });

  return (
    <div className="table-responsive pb-0" style={estilos.contenedorTabla}>
    <Table bordered className="pb-0 mb-0" id="tablaAsistencia">
      <thead>
        <tr>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-numero-lista">Número de lista</Tooltip>}
          >
            <th
              style={{
                ...estilos.encabezado,
                ...estilos.bordeIzquierdoBlanco,
                ...estilos.celdaSinBordeDerecho,
                width: '30px',
              }}
            >
              N°
            </th>
          </OverlayTrigger>
          <th
            style={{
              ...estilos.encabezado,
              ...estilos.celdaSinBordeIzquierdo,
              width: '150px',
            }}
          >
            Estudiante
          </th>
          {diasDelMes.map((dia, colIndex) => {
            const { day, dayOfWeek } = formatearFecha(dia, mes, año);
            const esFinDeSemanaDia = esFinDeSemana(dia, mes, año);
            const esSabado = new Date(año, mes - 1, dia).getDay() === 6;
            const esDomingo = new Date(año, mes - 1, dia).getDay() === 0;
  
            return (
              <th
                key={dia}
                style={
                  esFinDeSemanaDia
                    ? esSabado
                      ? {
                          ...estilos.encabezado,
                          ...estilos.encabezadoFinDeSemana,
                          ...estilos.encabezadoFinDeSemanaIzquierdo,
                          ...estilos.encabezadoFinDeSemanaCentro,
                          width: '20px',
                        }
                      : esDomingo
                      ? {
                          ...estilos.encabezado,
                          ...estilos.encabezadoFinDeSemana,
                          ...estilos.encabezadoFinDeSemanaDerecho,
                          width: '20px',
                        }
                      : { ...estilos.encabezado, ...estilos.encabezadoFinDeSemana, width: '20px' }
                    : { ...estilos.encabezado, width: '30px' }
                }
              >
                <div>{dayOfWeek}</div>
                <div>{day}</div>
              </th>
            );
          })}
          {/* <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-conteo-mes">Conteo total los días de clase asistidos por alumno en el mes</Tooltip>}
          >
            <th style={{ ...estilos.encabezado, width: '50px' }}>Conteo Mes</th>
          </OverlayTrigger> */}
          {/* <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-porcentaje">
                <math display="block" className="math-font">
                  <mrow>
                    <mfrac>
                      <mn>(Total de días presentes)</mn>
                      <ms>(Total de días de clases incurridos<br />
                        en el mes)</ms>
                    </mfrac>
                  </mrow>
                </math>
              </Tooltip>
            }
          > */}
         <th style={{ ...estilos.encabezado, width: '125px', padding: 0 }}>
  <div style={{ borderBottom: '1px solid #e6e6e6' }}>Asistencia</div>
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="tooltip-dias">Total de días de clases en el que el estudiante asistio</Tooltip>}
    >
      <div style={{ width: '50%', borderRight: '1px solid #e6e6e6' }}>Días</div>
    </OverlayTrigger>
    
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="tooltip-porcentaje"><math display="block" className="math-font">
        <mrow>
          <mfrac>
            <mn>(Total de días presentes)</mn>
            <ms>(Total de días de clases incurridos<br />
              en el mes)</ms>
          </mfrac>
        </mrow>
      </math></Tooltip>}
    >
      <div style={{ width: '50%' }}>%</div>
    </OverlayTrigger>

  </div>
</th>

          {/* </OverlayTrigger> */}
        </tr>
      </thead>
      <tbody>
        {estudiantesOrdenados.map((estudiante, rowIndex) => {
          const porcentajePresente = estudiante.porcentajeTotalMensual;
          const conteoMensual = estudiante.conteoTotalMensual;
          const filaEstilo = estudiante.retirado ? { cursor: 'not-allowed' } : {};
  
          const fechaRetiro = estudiante.fechaRetiro ? new Date(estudiante.fechaRetiro) : null;

          return (
            <tr
              key={estudiante.id}
              onMouseEnter={() => manejarMouseEnter(rowIndex, null, false)}
              onMouseLeave={manejarMouseLeave}
              style={filaEstilo}
            >
              <td
                style={{
                  ...estilos.celda,
                  ...estilos.bordeIzquierdoBlanco,
                  ...estilos.celdaSinBordeDerecho,
                  ...(filaHover === rowIndex ? estilos.filaHover : {}),
                }}
              >
                {estudiante.numLista !== null ? (
                  estudiante.numLista
                ) : estudiante.numMatricula !== null ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${estudiante.id}`}>
                        {estilosEstadoAdicionales.matriculadoSinNumeroLista.description}
                      </Tooltip>
                    }
                  >
                    <i
                      className={`${estilosEstadoAdicionales.matriculadoSinNumeroLista.icon}`}
                      style={{
                        color: estilosEstadoAdicionales.matriculadoSinNumeroLista.iconColor,
                        fontSize: '20px',
                      }}
                    ></i>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${estudiante.id}`}>
                        {estilosEstadoAdicionales.sinNumeroMatriculaYLista.description}
                      </Tooltip>
                    }
                  >
                    <i
                      className={`${estilosEstadoAdicionales.sinNumeroMatriculaYLista.icon}`}
                      style={{
                        color: estilosEstadoAdicionales.sinNumeroMatriculaYLista.iconColor,
                        fontSize: '20px',
                      }}
                    ></i>
                  </OverlayTrigger>
                )}
                {estudiante.retirado && fechaRetiro && fechaRetiro <= new Date(año, mes - 1, 1) && (
                  <div style={estilos.lineaRoja}></div>
                )}
              </td>
  
              <OverlayTrigger
                placement="top"
                overlay={
                  estudiante.retirado ? (
                    <Tooltip id={`tooltip-retirado-${estudiante.id}`}>
                      Estudiante retirado
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <td
                  style={{
                    ...estilos.celda,
                    ...estilos.celdaSinBordeIzquierdo,
                    ...(filaHover === rowIndex ? estilos.filaHover : {}),
                    position: 'relative',
                    maxWidth: '100px',
                  }}
                >
                  <div className="d-flex align-items-center gap-3">
                    {/* <img src={defaultAvatar} alt="Avatar" style={estilos.avatar} /> */}
                    <Avatar run={estudiante.run || estudiante.ipe} width={'30px'} height={'30px'} allowPreview />
                    <span style={estilos.textoEstudianteCelda}>{estudiante.nombre}</span>
                  </div>
                  {estudiante.retirado && fechaRetiro && fechaRetiro <= new Date(año, mes - 1, 1) && (
                    <div style={estilos.lineaRoja}></div>
                  )}
                </td>
              </OverlayTrigger>
  
              {diasDelMes.map((dia, colIndex) => {
                const estadoId = estudiante.asistencias[`asistenciaEstado${String(dia).padStart(2, '0')}`];
                const estado = parseEstado(estadoId);
                const esFinDeSemanaDia = esFinDeSemana(dia, mes, año) || columnasFinDeSemana[colIndex];
                const esSabado = new Date(año, mes - 1, dia).getDay() === 6;
                const esDomingo = new Date(año, mes - 1, dia).getDay() === 0;
                const esHover = filaHover === rowIndex || columnaHover === colIndex;
                const esExactoHover = filaHover === rowIndex && columnaHover === colIndex;
                const esAntesDeMatricula = esAntesDeFechaMatricula(dia, mes, año, estudiante.fechaMatricula);
  
                const fechaActual = new Date(año, mes - 1, dia);
                const mostrarLineaRoja = fechaRetiro && fechaActual >= fechaRetiro;
  
                return (
                  <td
                  key={`${dia}-${estudiante.id}`}
                  style={
                    esFinDeSemanaDia
                      ? esSabado
                        ? {
                            ...estilos.celda,
                            ...estilos.celdaFinDeSemana,
                            ...estilos.celdaFinDeSemanaIzquierdo,
                            ...estilos.celdaFinDeSemanaCentro,
                          }
                        : esDomingo
                        ? {
                            ...estilos.celda,
                            ...estilos.celdaFinDeSemana,
                            ...estilos.celdaFinDeSemanaDerecho,
                          }
                        : { ...estilos.celda, ...estilos.celdaFinDeSemana }
                      : {
                          ...estilos.celda,
                          ...(esHover && estado !== 'futuro' ? estilos.celdaEstadoHover : {}),
                          ...(esExactoHover && estado !== 'futuro'
                            ? {
                                backgroundColor:
                                  estilosEstado[estado]?.backgroundColor || 'transparent',
                              }
                            : {}),
                          ...(esAntesDeMatricula ? estilos.celdaInvalida : {}),
                          ...(isHeatmapActive
                            ? { backgroundColor: obtenerColorMapaCalor(estadoId) }
                            : {}),
                          position: 'relative',
                          cursor: isSoloDocente || mostrarLineaRoja ? 'not-allowed' : 'pointer', // Cambia el cursor si es docente o línea roja
                        }
                  }
                  onMouseEnter={() =>
                    !isSoloDocente && manejarMouseEnter(rowIndex, colIndex, esAntesDeMatricula)
                  }
                  onMouseLeave={() => !isSoloDocente && manejarMouseLeave()}
                  onClick={
                    !isSoloDocente &&
                    estadoId !== -1 &&
                    estadoId !== undefined &&
                    !esFinDeSemanaDia &&
                    !mostrarLineaRoja &&
                    !esAntesDeMatricula
                      ? () => manejarClick(estudiante, dia, estadoId)
                      : undefined
                  }
                >
                  {estadoId !== -1 && estadoId !== undefined && !esDomingo && (
                    <i
                      className={estilosEstado[estado]?.icon || ''}
                      style={{
                        ...estilos.icono,
                        color: estilosEstado[estado]?.iconColor || 'transparent',
                      }}
                    ></i>
                  )}
                  {mostrarLineaRoja && <div style={estilos.lineaRoja}></div>}
                </td>
                
                );
              })}
  
              {/* <td
                style={{
                  ...estilos.celda,
                  ...(filaHover === rowIndex ? estilos.filaHover : {}),
                  position: 'relative',
                  textAlign: 'center',
                }}
              >
                {conteoMensual}
                {fechaRetiro && fechaRetiro <= new Date(año, mes - 1, 31) && (
                  <div style={estilos.lineaRoja}></div>
                )}
              </td> */}
  
                <td
                style={{
                  ...estilos.celda,
                  padding: 0,
                  width: '90px',
                  position: 'relative',
                  cursor: fechaRetiro && fechaRetiro <= new Date(año, mes - 1, 31) ? 'not-allowed' : 'default',
                }}
              >
                <div style={{ display: 'flex', height: '100%' }}>
                  <div
                    style={{
                      width: '50%',
                      borderRight: '1px solid #e6e6e6',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {conteoMensual}
                  </div>
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {porcentajePresente.toFixed(2)}%
                  </div>
                </div>
                {fechaRetiro && fechaRetiro <= new Date(año, mes - 1, 31) && (
                  <div
                    style={{
                      ...estilos.lineaRoja,
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      right: 0,
                      height: '2px',
                    }}
                  ></div>
                )}
              </td>
            </tr>
          );
        })}
  
        <tr style={estilos.conteoDiarioFila}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-conteo-dia">Conteo total de alumnos presentes por día de clases</Tooltip>}
          >
            <td style={{ ...estilos.celda, ...estilos.encabezado, fontSize: '1rem', fontWeight: '500', textAlign: 'center' }} colSpan={2}>
              Conteo Día
            </td>
          </OverlayTrigger>
  
          {diasDelMes.map((dia, colIndex) => {
            const conteoDiarioData = conteoDiario.find(
              (conteo) => conteo.dia === dia
            )?.conteoDiario;
  
            let contenidoCelda;
            if (conteoDiarioData === -2) {
              contenidoCelda = '';
            } else if (conteoDiarioData === -1) {
              contenidoCelda = '';
            } else {
              contenidoCelda = conteoDiarioData;
            }
  
            return (
              <td
                key={`conteo-${dia}`}
                style={{
                  ...(conteoDiarioData === -2 ? estilos.celdaFinDeSemanaSinNumero : estilos.celda),
                  fontWeight: 'normal',
                }}
              >
                {contenidoCelda}
              </td>
            );
          })}
          <td style={{ ...estilos.celda, ...estilos.encabezado }}></td>
          {/* <td style={{ ...estilos.celda, ...estilos.encabezado, width: '125px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '50%', borderRight: '1px solid #e6e6e6' }}></div>
              <div style={{ width: '50%' }}></div>
            </div>
          </td> */}
        </tr>
      </tbody>
    </Table>


      <ModalCambioEstadoSubvencion
        show={modalCambioEstadoVisible}
        handleClose={() => setModalCambioEstadoVisible(false)}
        estudianteNomPri={estudianteSeleccionado?.nombre?.split(' ')[0]}
      estudianteApePri={estudianteSeleccionado?.nombre?.split(' ')[1]}
        fecha={`${String(diaSeleccionado).padStart(2, '0')}-${String(mes).padStart(2, '0')}-${año}`}
        bloque={null}
        estadoActual={estadoActual}
        handleSave={manejarGuardarCambioEstado}
        estudianteId={estudianteSeleccionado?.id}
        claseId={estudianteSeleccionado?.cursoId}
        asistenciaId={estudianteSeleccionado?.asistencias?.[`asistenciaEstado${String(diaSeleccionado).padStart(2, '0')}`]}
        cursoId={estudianteSeleccionado?.cursoId}
      />

      <Modal centered show={modalAvisoVisible} onHide={manejarCierreModalAviso} size="lg">
        <Modal.Header closeButton className="p-4">
          <Modal.Title style={{ width: "100%", textAlign: "center" }}>
            AVISO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1">
          <p className="text-center p-3">
            El/La estudiante, {estudianteSeleccionado?.nombre || 'desconocido'}, no cuenta con toda la información necesaria
           para el correcto funcionamiento en la plataforma.<br/>
           No obstante, su asistencia será registrada.
          Le recomendamos comunicarse con UTP<br/> para corregir esta situación.
            <br />
            <b>Requerimientos no cumplidos:</b> {razonesError.join(',  ')}
            <br />
            Por favor comuníquese con UTP.
            <br />
            El proceso continuará en {countdown} segundos.
          </p>
          <div className="d-flex justify-content-center">
            <Button variant="primary" onClick={manejarCierreModalAviso}>Firmar</Button>
          </div>
        </Modal.Body>
      </Modal>

      <ModalAutenticacion
        show={modalAutenticacionVisible}
        onHide={() => setModalAutenticacionVisible(false)}
        onConfirm={manejarConfirmacionAutenticacion}
      />

      <NotificacionToast 
        mensaje={mensajeToast} 
        mostrar={mostrarToast} 
        setMostrar={setMostrarToast}
      />
    </div>
  );
};

export default TablaAsistenciaMensualSubvencion;